import React from 'react';
import { toast } from 'react-toastify';
import PageTitle from '@components/pageTitle';
import VariousLanguages from './notificationVariousLanguagesForm';
import translate from '@data/translations';
import { NotificationTemplateModel } from '@common/modelDefinition';
import { getNotificationTemplates, saveFormData, saveLanguagesFormData } from './api';
import KeyWords from './keyWords';
import FormHeader from '@components/formHeader';
import RoomsOnFailure from "./roomsOnFailure"

interface TemplateFormProps {
    selectedTemplate: NotificationTemplateModel | null;
    keywords: string[];
    setSelectedTemplate: Function;
}

interface SettingsProps {
    history: any;
}


export enum BodyEnum {
    selfServiceCheckInHardware = `Dear {receiver_first_name} {receiver_last_name}, 
    Your room is {room_number}. Enter Your pin: {pin_code} on entrance. Take the card and proceed to Your room. 
    
    This self check in is valid from {check_in_valid_from} to {check_in_valid_to}

    It is our pleasure to wish you welcome and pleasant stay in our hotel.`,

    selfServiceCheckInSoftware = `Dear {receiver_first_name} {receiver_last_name}, 
    Your room is {room_number}. Your pin code is {pin_code}. 
    Visit this page {qr-reader-page} to scan the QR code outside of the room. 
    If the qr code check passes successfully, you will be able to enter Your room. 
    
    This self check in is valid from {check_in_valid_from} to {check_in_valid_to}

    It is our pleasure to wish you welcome and pleasant stay in our hotel.`,

    reservationConfirmation = `Dear {receiver_first_name} {receiver_last_name}, Your reservation (#{reservation_id}) is confirmed.  
    Check in: {check_in_time}. 
    Check out: {check_out_time}.
    Room: {room_number}.

    It is our pleasure to wish you welcome and pleasant stay in our hotel.`,
    reservationCancellation = `Dear {receiver_first_name} {receiver_last_name},
    Your reservation (#{reservation_id}) for period {check_in_time} - {check_out_time} for Room: {room_number} at hotel {hotel_name} is cancelled.`,

    roomFailure = `Room {room_number} is offline.`
}

export enum TitleEnum {
    selfServiceCheckInHardware = '{hotel_name} - Check in',
    selfServiceCheckInSoftware = '{hotel_name} - Check in',
    reservationConfirmation = '{hotel_name} - Reservation Confirmation',
    reservationCancellation = '{hotel_name} - Reservation Cancellation',
    roomFailure = '{hotel_name} - Room failure',
}


const NotificationTemplateSettings = (props: SettingsProps) => {
    const [uniqueName, setUniqueName] = React.useState<string>('selfServiceCheckInHardware');
    const [selectedTemplate, setSelectedTemplate] = React.useState<NotificationTemplateModel | null>(null);
    const [selectedTemplateTranslations, setSelectedTemplateTranslations] = React.useState<any | null>(null);
    const [showOtherLanguages, setShowOtherLanguages] = React.useState<boolean>(false);
    const [showRoomsToSelect, setShowRoomsToSelect] = React.useState<boolean>(false);
    const [showButtonOtherLanguages, setShowButtonOtherLanguages] = React.useState<boolean>(false);


    React.useEffect(() => {
        const loadData = async () => {
            const _templates = await getNotificationTemplates();
            const targetTemplate: NotificationTemplateModel | undefined = _templates.find(
                temp => temp.uniqueName === uniqueName
            );
            if(targetTemplate){
                setShowButtonOtherLanguages(true)
            }else{
                setShowButtonOtherLanguages(false)
            }

            const _selectedTemplate = targetTemplate ? targetTemplate : getDefaultTemplate(uniqueName);
            setSelectedTemplate(_selectedTemplate);

            if (_selectedTemplate && _selectedTemplate.params) {
                setSelectedTemplateTranslations(JSON.parse(_selectedTemplate.params));
            } else {
                setSelectedTemplateTranslations(null);
            }
        };
        loadData().catch(err => {
            console.log(err);
        });
    }, [uniqueName]);

    const keywords: string[] = getKeywords(uniqueName);

    const _saveFormData = async () => {
        await saveFormData({ ...selectedTemplate });
        setShowButtonOtherLanguages(true)
        toast("UPDATED '" + uniqueName + "'", { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
    };
    const onLanguageSelect = (value: string, templateType: string) => {
        const titleBodyTranslations = { ...selectedTemplateTranslations };
        titleBodyTranslations[value] = {
            title: TitleEnum[templateType as keyof typeof TitleEnum],
            body: BodyEnum[templateType as keyof typeof BodyEnum],
        };
        setSelectedTemplateTranslations(titleBodyTranslations);
    };

    const onLanguageFieldChange = (language: string, type: string, e: any) => {
        const titleBodyTranslations = { ...selectedTemplateTranslations };
        titleBodyTranslations[language][type] = e.target.value;
        setSelectedTemplateTranslations(titleBodyTranslations);
    };

    const removeLanguageFromSelection = (lang: string) => {
        const titleBodyTranslations = { ...selectedTemplateTranslations };
        delete titleBodyTranslations[lang];
        setSelectedTemplateTranslations(titleBodyTranslations);
    };

    const _saveLanguagesFormData = async () => {
        let template: any = { ...selectedTemplate };
        let templateTranslations:any = {...selectedTemplateTranslations}

        await saveLanguagesFormData(template, templateTranslations )
        setShowOtherLanguages(false);

        toast("UPDATED '" + uniqueName + "'", { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
    };

    return (
        <div>
            <PageTitle />
            {!showOtherLanguages && !showRoomsToSelect ? (
                <div>
                    <button
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className="btn btn-sm btn-secondary mb-2"
                    >
                        {translate('GO BACK')}
                    </button>
                    <div className="btn-group w-100 pb-2 text-primary">
                        {[
                            'selfServiceCheckInHardware',
                            'selfServiceCheckInSoftware',
                            'reservationConfirmation',
                            'reservationCancellation',
                            'roomFailure'
                        ].map((un: string) => {
                            return (
                                <button
                                    key={un}
                                    className={
                                        un === uniqueName ? 'btn btn-white btn-sm active' : 'btn btn-white btn-sm'
                                    }
                                    onClick={() => {
                                        setUniqueName(un);
                                    }}
                                >
                                    {translate(un.toUpperCase())}
                                </button>
                            );
                        })}
                    </div>
                    <FormHeader classNameSufix="">{translate(uniqueName.toUpperCase())}</FormHeader>
                    <NotificationTemplateForm
                        selectedTemplate={selectedTemplate}
                        keywords={keywords}
                        setSelectedTemplate={setSelectedTemplate}
                    />
                    {showButtonOtherLanguages && uniqueName !== "roomFailure" ? <div className="text-left p-2">
                        <div
                            onClick={() => {
                                setShowOtherLanguages(true);
                            }}
                            className="btn btn-info pointer"
                        >
                            {translate('Other languages')}
                        </div>
                    </div> : null}
                    {uniqueName === "roomFailure" ? <div className="text-left p-2">
                        <div  
                            onClick={() => {
                                setShowRoomsToSelect(true);
                            }}
                            className="btn btn-info pointer"
                         >
                            {translate('Select rooms')}
                        </div>
                    </div> : null}
                    <div className="text-center mt-3">
                        <div
                            onClick={async () => {
                                await _saveFormData();
                            }}
                            className="btn btn-outline-primary mb-4 pointer"
                        >
                            {translate('Save')}
                        </div>
                    </div>
                </div>
            ) : (showOtherLanguages ? 
                    <VariousLanguages
                        onLanguageFieldChange={onLanguageFieldChange}
                        onLanguageSelect={onLanguageSelect}
                        titleBodyTranslations={selectedTemplateTranslations}
                        keyWords={keywords}
                        removeLanguageFromSelection={removeLanguageFromSelection}
                        saveLanguagesFormData={_saveLanguagesFormData}
                        uniqueName={uniqueName}
                        history={props.history}
                        setShowOtherLanguages={setShowOtherLanguages}
                    ></VariousLanguages> 
             : (showRoomsToSelect ? 
                    <RoomsOnFailure
                        uniqueName={uniqueName}
                        history={props.history}
                        setShowRoomsToSelect={setShowRoomsToSelect}
                    ></RoomsOnFailure>
                : null))
            }
        </div>
    );
};

const NotificationTemplateForm = (props: TemplateFormProps) => {
    const { selectedTemplate, keywords } = props;

    if (!selectedTemplate) {
        return null;
    }

    return (
        <div className="d-flex bg-white p-2">
            <div className="col-md-6">
                <NotificationTemplateType {...props} />
                <NotificationTemplateTitle {...props} />
                <NotificationTemplateBody {...props} />
                <NotificationTemplateIsEnabled {...props} />
                <NotificationTemplateUseHtmlTemplate {...props} />
            </div>
            <KeyWords keyWords={keywords} />
        </div>
    );
};

const NotificationTemplateBody = (props: TemplateFormProps) => {
    const { selectedTemplate, setSelectedTemplate } = props;

    if (!selectedTemplate) {
        return null;
    }

    const { body } = selectedTemplate;

    return (
        <div className="row p-2" key={'body'}>
            <div className="col-md-5">
                <label className="my-1 mr-2 ">
                    <label>{translate("Body")}</label>:
                </label>
            </div>
            <div className="col-md-6">
                <textarea
                    rows={5}
                    className="form-control"
                    value={body ? body : ''}
                    onChange={e => {
                        const newTemplate = { ...selectedTemplate };
                        newTemplate.body = e.target.value;
                        setSelectedTemplate(newTemplate);
                    }}
                ></textarea>
            </div>
        </div>
    );
};

const NotificationTemplateTitle = (props: TemplateFormProps) => {
    const { selectedTemplate, setSelectedTemplate } = props;

    if (!selectedTemplate) {
        return null;
    }

    const { title } = selectedTemplate;

    return (
        <div className="row p-2" key={'title'}>
            <div className="col-md-5">
                <label className="my-1 mr-2 " htmlFor="">
                    <label>{translate("Title")}</label>:
                </label>
            </div>
            <div className="col-md-6">
                <input
                    type="text"
                    className="form-control"
                    value={title ? title : ''}
                    onChange={e => {
                        const newTemplate = { ...selectedTemplate };
                        newTemplate.title = e.target.value;
                        setSelectedTemplate(newTemplate);
                    }}
                />
            </div>
        </div>
    );
};

const NotificationTemplateIsEnabled = (props: TemplateFormProps) => {
    const { selectedTemplate, setSelectedTemplate } = props;

    if (!selectedTemplate) {
        return null;
    }

    const { isEnabled } = selectedTemplate;
    return (
        <div className="row p-2" key={'isEnabled'}>
            <div className="col-md-5">
                <label className="my-1 mr-2 " htmlFor="defaultCurrency">
                    <label>{translate("Is enabled")}</label>:
                </label>
                <div><small style={{color:"gray"}}>{translate("If not checked, body and title use system defined values")}</small></div>
                
            </div>
            <div className="col-md-6">
                <input
                    type="checkbox"
                    name="isEnabled"
                    className="form-control"
                    checked={isEnabled}
                    onChange={e => {
                        const newTemplate = { ...selectedTemplate };
                        newTemplate.isEnabled = e.target.checked;
                        setSelectedTemplate(newTemplate);
                    }}
                />
            </div>
        </div>
    );
};

const NotificationTemplateType = (props: TemplateFormProps) => {
    const { selectedTemplate, setSelectedTemplate } = props;

    if (!selectedTemplate) {
        return null;
    }

    const { type } = selectedTemplate;
    return (
        <div className="row p-2" key={'type'}>
            <div className="col-md-5">
                <label className="my-1 mr-2 " htmlFor="">
                    <label>{translate("Type")}</label>:
                </label>
            </div>
            <div className="col-md-6">
                <input
                    type="text"
                    className="form-control"
                    value={type ? type : ''}
                    onChange={e => {
                        const newTemplate = { ...selectedTemplate };
                        newTemplate.type = e.target.value;
                        setSelectedTemplate(newTemplate);
                    }}
                />
            </div>
        </div>
    );
};

const NotificationTemplateUseHtmlTemplate = (props: TemplateFormProps) => {
    const { selectedTemplate, setSelectedTemplate } = props;

    if (!selectedTemplate) {
        return null;
    }

    const { useHtmlTemplate } = selectedTemplate;
    return (
        <div className="row p-2" key={'useHtmlTemplate'}>
            <div className="col-md-5">
                <label className="my-1 mr-2 " htmlFor="defaultCurrency">
                    <label>{translate("Use html template")}</label>:
                </label>
                <div><small style={{color:"gray"}}>{translate("If checked, email is sent like html page")}</small></div>
            </div>
            <div className="col-md-6">
                <input
                    type="checkbox"
                    className="form-control"
                    checked={useHtmlTemplate}
                    onChange={e => {
                        const newTemplate = { ...selectedTemplate };
                        newTemplate.useHtmlTemplate = e.target.checked;
                        setSelectedTemplate(newTemplate);
                    }}
                />
            </div>
        </div>
    );
};

const getKeywords = (keyname: string) => {
    let keyWords = ['{receiver_first_name}', 
    '{receiver_last_name}', 
    '{pin_code}', 
    '{room_number}', 
    '{hotel_name}', 
    '{reservation_id}',
    '{check_in_time}',
    '{check_out_time}',]; //hw key words

    if (keyname === 'selfServiceCheckInSoftware') {
        keyWords = [
            '{receiver_first_name}',
            '{receiver_last_name}',
            '{pin_code}',
            '{room_number}',
            '{hotel_name}',
            '{qr_reader_page}',
            '{reservation_id}',
            '{check_in_time}',
            '{check_out_time}',
        ];
    } else if (keyname === 'reservationConfirmation') {
        keyWords = [
            '{receiver_first_name}',
            '{receiver_last_name}',
            '{check_in_time}',
            '{check_out_time}',
            '{room_number}',
            '{hotel_name}',
            '{reservation_id}'
        ];
    } else if (keyname === 'reservationCancellation') {
        keyWords = [
            '{receiver_first_name}',
            '{receiver_last_name}',
            '{hotel_name}',
            '{reservation_id}'
        ];
    } else if (keyname === 'roomFailure') {
        keyWords = ['{room_number}', '{hotel_name}'];
    }
    return keyWords;
};

const getDefaultTemplate = (uniqueName: string) => {
    const body: string = BodyEnum[uniqueName as keyof typeof BodyEnum];

    const title: string = TitleEnum[uniqueName as keyof typeof TitleEnum];

    const type: string = '';
    const isEnabled: boolean = false;
    const useHtmlTemplate: boolean = false;

    const defaultTemplate: NotificationTemplateModel = {
        type,
        title,
        body,
        isEnabled,
        useHtmlTemplate,
        uniqueName
    };

    return defaultTemplate;
};

export default NotificationTemplateSettings;
