import * as React from 'react';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
//@ts-ignore
import { useBranch } from 'baobab-react/hooks';
import { GroupReservation } from './groupReservationsList';
import { translate } from '@data/translations';
import { GroupReservationController } from './groupController';
import { ReservationModel } from '@common/modelDefinition';

interface GroupReservationInfoInterface {
    g: GroupReservation;
    history: any;
    index: number;
    reservation?: ReservationModel | null; // koristi se samo za selektiranje grupe
    setGroupReservations: any;
    reservationId: number | null;
}

function SingleGroupReservation(props: GroupReservationInfoInterface) {
    const { g, history, reservation, setGroupReservations, reservationId } = props;
    const { roomInfoMap } = useBranch({
        roomInfoMap: ['map', 'RoomInfo'],
    });

    const ctrl = new GroupReservationController();

    const firstName: string = g?.Customer?.firstName ? g.Customer.firstName : '';
    const lastName: string = g?.Customer?.lastName ? g.Customer.lastName : '';
    const companyName: string = g?.Company?.name ? g.Company.name : '';
    const reservationHolder: string = g?.Customer ? `${firstName} ${lastName}` : g?.Company ? `${companyName}` : '';

    const { Reservations } = g;

    const hasValidReservations: boolean =
        Reservations && Reservations.length > 0 && Reservations.filter((r) => r.statusEnum !== 3).length > 0
            ? true
            : false;

    return (
        <div className=" row">
            {(!reservation || reservation===null) && g && hasValidReservations && Reservations && Reservations[0] ? (
                <div className="col-md-2">
                    <button
                        className="btn btn-sm btn-primary uppercase mb-1 mt-1 mr-2"
                        onClick={() => {
                            history.push('/issueGroupInvoice/' + g?.uuid);
                        }}
                    >
                        <i className="fa fa-print" /> {translate('Invoices')}
                    </button>
                    <button
                        onClick={() => {
                            history.push(`/reservations/${Reservations[0].id}/edit?uuid=${g?.uuid}`);
                        }}
                        className="btn btn-sm btn-secondary mr-2 uppercase mb-1 mt-1"
                    >
                        {translate('Edit')}
                    </button>
                </div>
            ) : (!reservation || reservation===null) && hasValidReservations===false && g?.status === 1 ? (
                <div className="col-md-2">
                    <button
                        onClick={async () => {
                            await ctrl.cancelGroupReservation(g);
                            const groupReservations: GroupReservation[] = await ctrl.getGroupReservations(null);
                            groupReservations.sort((a, b) =>
                                a.createdAt && b.createdAt && a.createdAt < b?.createdAt ? 1 : -1
                            );
                            setGroupReservations(groupReservations);
                        }}
                        className="btn btn-sm btn-warning mr-2 uppercase mb-1 mt-1"
                    >
                        {translate('Close')}
                    </button>
                </div>
            ) : g && g.status === 1 && g.genuineInvoiceIssued !==1 && reservationId ? (
                <div className="col-md-2">
                    <button
                        onClick={async () => {
                            if (reservation && reservation.id) {
                                await ctrl.setGroupReservations(g, reservation.id);
                                history.push(`/reservations/${reservation.id}/edit`);
                            }
                        }}
                        className="btn btn-sm btn-primary mr-2 uppercase mb-1 mt-1"
                    >
                        {translate('SELECT GROUP')}
                    </button>
                </div>
            ) : (
                <div className="col-md-2"></div>
            )}
            <div className="col-md-3">
                <small>
                    {reservationHolder}
                    <br />
                    {translate('Created at')}: {moment(g?.createdAt).format('ll')}
                    <br />
                    {translate('Group name')}: {g.groupName ? g.groupName : ''}
                    <br />#{g?.uuid}
                </small>
            </div>
            <div className="col-md-2 text-center">
                <small>
                    {g?.Reservations && g?.Reservations.length > 0
                        ? moment(g?.checkInTimestamp).format('ll') + ' -> ' + moment(g?.checkOutTimestamp).format('ll')
                        : translate('No reservations')}{' '}
                    <br /> (<i className="fa fa-info mr-1" aria-hidden="true"></i>
                    {g?.status === 1 ? translate('Active') : translate('Closed')})
                    <br /> (<i className="fa fa-money mr-1" aria-hidden="true"></i>
                    {g?.invoicedStatus === 2
                        ? translate('Paid:Partialy')
                        : g?.invoicedStatus === 1
                        ? translate(' Fully')
                        : '-'}
                    )
                </small>
            </div>
            <div className="col-md-5">
                {g?.Reservations &&
                    g.Reservations.map((res: ReservationModel, key: number) => {
                        const room = roomInfoMap[res.roomInfoId]; //roomInfo.find((r) => r.id === res.roomInfoId);
                        return (
                            <button
                                style={{ width: '100px' }}
                                disabled={res.statusEnum === 4 ? true : false}
                                key={key}
                                className="btn btn-sm btn-light border mr-1 mb-1 elipsis"
                                onClick={() => {
                                    history.push('/reservations/' + res.id + '/edit');
                                }}
                            >
                                {room ? room.name : ''}
                                <i className="fa fa-calendar text-info ml-2 " />
                            </button>
                        );
                    })}
            </div>
        </div>
    );
}

export default SingleGroupReservation;
