import React from 'react';
import baobab from '../../data/state';

const CondoUserRoomSelection = ({ onCondoRoomSelectionUpdated, user }) => {
    const initialState = user.condoRoomInfoIds ? user.condoRoomInfoIds : '';
    const [condoRoomInfoIds, setCondoRoomInfoIds] = React.useState(initialState);
    const [isEntryValid, setIsEntryValid] = React.useState(true);

    const roomInfos = baobab.root.select(['model', 'RoomInfo']).get();
    const roomInfoIds = roomInfos.map((r) => r.id);

    const handleInputChange = (e) => {
        let isValid = true;
        setCondoRoomInfoIds(e.target.value);
        let enteredIds = e.target.value.split(',').map((id) => Number(id)); //parse tu number
        enteredIds = enteredIds.filter((id) => id !== 0); //filter zero, comma

        for (const targetRoomInfoId of enteredIds) {
            if (!roomInfoIds.includes(targetRoomInfoId)) {
                isValid = false;
                break;
            }
        }
        if (isValid) {
            if (enteredIds.length === 0) {
                onCondoRoomSelectionUpdated(null);
            } else {
                onCondoRoomSelectionUpdated(enteredIds.join(','));
            }
        }
        setIsEntryValid(isValid);
    };

    const className = isEntryValid ? 'form-control' : 'is-invalid form-control';

    return (
        <div>
            <input onChange={handleInputChange} type="text" value={condoRoomInfoIds} className={className} />
            <small>{window.translate('Enter room IDs separated with comma. Example: 1,2,12')}</small>
        </div>
    );
};

export default CondoUserRoomSelection;
