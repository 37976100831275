import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order'; //DONT use baobab here

import rest from '../../data/restWrapper';
import PageLoader from '../../components/loaders/loader1';
import PresentReservationCard from './components/presentReservationCard';
import RoomAttributes from '../../components/attributes';
import RoomToolbar from './components/roomToolbar';

import RoomReservationsCalendar from './components/roomReservationsCalendar';
import PageTitle from '../../components/pageTitle';
import RoomEventHistory from './components/eventsHistory';

import FeatureFlag, {isFeatureEnabled} from '../../components/FeatureFlag';
import RoomReservations from './components/roomReservations';
import './style.css';

class RoomInfo extends Component {
    constructor() {
        super();
        this.state = {
            roomInfo: null,
            roomReservations: [],
        };
    }

    async asyncLoadRoomEvents(roomInfoId) {   }

    async componentDidMount() {
        this.loadRoomData(this.props.match.params.roomInfoId);
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.match.params.roomInfoId !== this.props.match.params.roomInfoId) {
            this.loadRoomData(this.props.match.params.roomInfoId);
        }
    }

    async loadRoomData(roomInfoId) {
        let roomReservations = await rest('/getReservationsForRoom/' + roomInfoId);
        let roomInfo = await rest('/RoomInfo/' + roomInfoId);
        this.setState({
            roomInfo,
            roomReservations,
        });
    }

    goToReservation(reservationId) {
        let roomInfoId = this.props.match.params.roomInfoId;
        this.props.history.push('/room/' + roomInfoId + '/reservation/' + reservationId);
    }
    addNewReservation(id) {
        let roomInfoId = this.props.match.params.roomInfoId;
        this.props.history.push('/room/' + roomInfoId + '/reservation/createNew');
    }
    render() {
        if (this.state.roomInfo === null) {
            return <PageLoader />;
        }

        const roomInfoId = Number(this.props.match.params.roomInfoId);

        const checkedInReservation = this.props.reservations.find(r=>{return r.roomInfoId===roomInfoId && r.statusEnum===2})

        return (
            <div className="">
                <PageTitle alternate={false} title={this.state.roomInfo.name} />

                <div className="row">
                    <div className={isFeatureEnabled("hardware") ? "col-md-6" : "col-md-12"}>
                        <RoomToolbar
                            reservation={checkedInReservation}
                            history={this.props.history}
                            roomInfo={this.state.roomInfo}
                        />


                        <RoomAttributes room={this.state.roomInfo} attributes={this.props.attributes} />
                        

                        <PresentReservationCard
                            history={this.props.history}
                            roomInfo={this.state.roomInfo}
                            reservation={checkedInReservation}
                        />

                        

                        <div className="text-center bg-white rounded mt-2">
                            <RoomReservationsCalendar
                                roomReservations={this.state.roomReservations}
                                history={this.props.history}
                                roomInfoId={this.state.roomInfo.id}
                            />
                        </div>

                        <div className=" bg-white rounded mt-2">
                            <RoomReservations
                                roomReservations={this.state.roomReservations}
                                {...this.props}
                                roomInfoId={this.state.roomInfo.id}
                            />
                        </div>
                    </div>
                    <FeatureFlag flag="hardware">
                    <div className="col-md-6 ">
                        <RoomEventHistory
                            reservation={checkedInReservation}
                            roomInfo={this.state.roomInfo}
                            activeEventTickets = {this.props.activeEventTickets}
                        />
                    </div>
                    </FeatureFlag>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        reservations: ['model', 'Reservation'],
        attributes: ['model', 'RoomAttribute'],
        activeEventTickets: ['model', 'RoomEventTicket'],
    },
    RoomInfo
);
