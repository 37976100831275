import React from 'react';
import _ from 'lodash';
import { setModalContent } from '@components/modal';
import Delete from '../../../components/confirmModal';
import PageTitle from '../../../components/pageTitle';
import { branch } from 'baobab-react/higher-order';
import Form from './form';
import rest from '../../../data/restWrapper';
import { toast } from 'react-toastify';

const AccommodationPriceList = (props) => {
    const [dataChange, setDataChange] = React.useState(false);

    React.useEffect(() => {}, [dataChange, props.filteredAccommodationPriceLists.length]);

    const deleteItem = async (itemId) => {
        if (!_.isUndefined(itemId)) {
            try {
                await rest('/AccommodationPriceList/' + itemId, 'PUT', {
                    isDeleted: true,
                });
                toast(window.translate("Accomodation price list deleted!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
                setDataChange(!dataChange);
            } catch (err) {
                //TODO: tostificiraj ovo
                console.log(err);
            }
        }
    };

    const showDeleteModal = (itemId) => {
        setModalContent(
            <Delete actionForYes={deleteItem.bind(this, itemId)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
        setDataChange(!dataChange);
    };

    const showFormModal = (accommodationPriceListId) => {
        setModalContent(
            <Form accommodationPriceListId={accommodationPriceListId} />,
            accommodationPriceListId ? window.translate('Edit price list') : window.translate('New price list'),
            false,
            'modal-lg'
        );
    };
    const goToAccommodationPriceListStack = (accommodationPriceListId) => {
        props.history.push('/priceLists/accommodation/stack/' + accommodationPriceListId);
    };

    const accommodationPriceLists =
        props.filteredAccommodationPriceLists &&
        props.filteredAccommodationPriceLists.filter((pl) => Number(pl.weight) === 0 && !pl.isDeleted);

    return (
        <div className="">
            <PageTitle />
            <button
                onClick={() => {
                    props.history.push('/menu/system');
                }}
                className="btn btn-sm btn-secondary  "
            >
                {window.translate('GO BACK')}
            </button>
            <button onClick={showFormModal.bind(this, null)} className="btn btn-sm btn-primary ml-2">
                {window.translate('ADD NEW')}
            </button>
            <button
                onClick={() => {
                    props.history.push('/roomsAndPriceLists');
                }}
                className="btn btn-sm btn-primary ml-2  "
            >
                <i className="fa fa-link" /> {window.translate('Rooms linking')}
            </button>

            <div className="alert alert-secondary text-left w-100 mt-2 mb-0">
                {window.translate(
                    'NOTE: For every room type, you should create a seperate accommodation price list. You can add sessional and promotional tarrifs by stacking them visualy on infinite timeline - so you can easily track and change pricing and promotion policies.'
                )}
                <br />
                <br />
                {window.translate('*Any modification of pricing policies, have no impact on already saved offers.')}
            </div>
            
            <div className="list-group mt-3 mx-3">
                <small>
                    <div className="px-1 py-2 row shadow-sm bg-white">
                        <div className="col-2">{window.translate('Name')}</div>
                        <div className="col-3">{window.translate('Description')}</div>
                        <div className="col-2">{window.translate('Acc. price rules')}</div>
                        <div className="col-2">{window.translate('Tags')}</div>
                        <div className="col-3"></div>
                    </div>
                </small>
            </div>

            <ul className="list-group mt-3 mx-3">
                {accommodationPriceLists.map((pl) => {
                    let accomodationPriceRules = pl.accomodationPriceRules ? JSON.parse(pl.accomodationPriceRules) : [];
                    let accomodationPriceRulesElement = [];
                    if (accomodationPriceRules === null) {
                        accomodationPriceRulesElement = (
                            <div>
                                <i className="fa fa-user" />
                                {window.translate('Price rules not defined')}
                            </div>
                        );
                    } else {
                        if (accomodationPriceRules.length <= 1) {
                            accomodationPriceRulesElement = <div>{window.translate('Fixed price')}</div>;
                        } else {
                            accomodationPriceRulesElement = (
                                <div>
                                    {window.translate('Up to')} {accomodationPriceRules.length}{' '}
                                    <i className="fa fa-users" />
                                </div>
                            );
                        }
                    }
                    let tags = pl.tags ? pl.tags.split(';') : [];
                    tags = tags.map((t, key) => {
                        return (
                            <span key={pl.id + t + key} className="badge badge-primary mr-1">
                                {t}
                            </span>
                        );
                    });

                    return (
                        <li key={pl.id} className={'px-2 py-2 row shadow-sm bg-white my-1 rounded'}>
                            <div className="col-md-2">{pl.name}</div>
                            <div className="col-md-3">
                                <small>{pl.description}</small>
                            </div>
                            <div className="col-md-2">{accomodationPriceRulesElement}</div>
                            <div className="col-md-2">{tags}</div>
                            <div className="col-md-3">
                                <button
                                    onClick={goToAccommodationPriceListStack.bind(this, pl.id)}
                                    className="btn btn-sm btn-outline-dark pull-right ml-1"
                                >
                                    <i className="fa fa-cubes" aria-hidden="true"></i>
                                </button>
                                <button
                                    onClick={showFormModal.bind(this, pl.id)}
                                    className="btn btn-sm btn-outline-dark pull-right ml-1"
                                >
                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                </button>

                                <button
                                    onClick={showDeleteModal.bind(this, pl.id)}
                                    className="btn btn-sm btn-outline-danger pull-right ml-1"
                                >
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default branch(
    {
        filteredAccommodationPriceLists: ['monkeys', 'filteredAccommodationPriceLists'],
    },
    AccommodationPriceList
);
