import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash"

class ArrayStringInput extends Component {
	onChangeHandler(inputIndex, e){
		let propValueClone = _.clone(this.props.value);
		if(propValueClone){
			if(inputIndex===propValueClone.length){
				propValueClone.push(e.target.value);
			} else {
				propValueClone[inputIndex] = e.target.value;
			}
			this.props.onValueChanged(propValueClone, this.props.inputName); //propagate updated value
		} else {
			this.props.onValueChanged([e.target.value], this.props.inputName); //propagate updated value
		}
		
	}
	render(){
		var index = -1;
		let inputs = _.map(this.props.value, value=>{
			index++;
			return <input onChange={this.onChangeHandler.bind(this, index)} required type="text" className="form-control" value={value} placeholder=". . . " key={"input"+index} />
		});
		index++
		inputs.push(<input onChange={this.onChangeHandler.bind(this, index)} required type="text" className="form-control" value={""} placeholder="new value . . ." key={"input"+index} />);

		return(<div className="row">
				<label className="control-label col-sm-4">{this.props.label}</label>
				<div className="col-sm-8 text-left">
				{inputs}

				</div>			
				<div className=" clear"></div>
		</div>);
	}
}

ArrayStringInput.propTypes = {
	onValueChanged: PropTypes.func.isRequired,
	value: PropTypes.array.isRequired,
	label: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
}

export default ArrayStringInput;