import React from 'react';
import InvoiceItems from './InvoiceItems';
import InvoiceItemsSummary from './InvoiceItemsSummary';

import { SingleReservationInvoiceHeader } from './InvoiceHeaderView';
import { translations, dictionaryTranslate } from '../../../data/translations';

import { clearInvoicePreview } from '../checkout/controler';
import { getInvoicePropertiesNeededForInvoicePreview } from './utils';
import { InvoiceModelWithInvoiceItems, InvoicePreview } from '@common/modelDefinition';

interface SingleReservationPreviewProps{
    invoicePreview:InvoicePreview
    groupInvoice:InvoiceModelWithInvoiceItems
}
const SingleReservationPreview = (props: SingleReservationPreviewProps) => {
    const { invoicePreview, groupInvoice } = props; //grup invojs koristim samo zbog live konverzije valuta i jezika

    if (!invoicePreview || !groupInvoice) {
        return null;
    }

    const { invoice, invoiceItems } = invoicePreview;

    const { locale } = groupInvoice;

    const fullInvoice = getInvoicePropertiesNeededForInvoicePreview(invoice, groupInvoice, invoiceItems); // jer je invoice iz invoicePreview s backenda jako okrnjen



    const translate = (text: string) => {
        return dictionaryTranslate(text, translations[locale as keyof typeof translations]);
    };

    return (
        <div className="bg-white p-3">
            <SingleReservationInvoiceHeader invoice={fullInvoice} invoiceItems={invoiceItems} translate={translate} />

            <InvoiceItems translate={translate} invoiceItems={invoiceItems} invoice={groupInvoice} />
            <InvoiceItemsSummary translate={translate} invoice={fullInvoice} invoiceItems={invoiceItems} invoicePreview={invoicePreview}/>
            <div className="text-center mt-4 mb-4">
                <button
                    className="btn btn-sm btn-secondary d-print-none"
                    onClick={() => {
                        clearInvoicePreview();
                    }}
                >
                    {translate('Close')}
                </button>
            </div>
        </div>
    );
};

export default SingleReservationPreview;
