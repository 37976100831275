import React, { useState, useEffect } from 'react';
import ReportTable from '../components/ReportTable';
import PageTitle from '../components/pageTitle';
import { getReportData, getExcelExport } from '../data/reports';

function ReservationsReportList(props) {
    const [items, setItems] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [_page, setPage] = React.useState(0);

    useEffect(() => {
        const loadData = async () => {
            reloadData(year);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const reloadData = async (year, page = _page) => {
        const _items = await getReportData('audit', { year, page });
        setItems([..._items]);
        setPage(page);
    };

    const _setYear = async (e) => {
        setYear(Number(e.target.value));
        await reloadData(Number(e.target.value));
    };

    const headers = [
        {
            filterKey: 'model',
            headerTitle: window.translate('DB model'),
            label: 'model',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'createdAt',
            headerTitle: window.translate('Created at'),
            label: 'createdAt',
            type: 'datetime',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'modelIdRef',
            headerTitle: window.translate('Model Id'),
            label: 'modelIdRef',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '60px',
        },
        {
            filterKey: 'method',
            headerTitle: window.translate('Method'),
            label: 'method',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '60px',
        },
        {
            filterKey: 'path',
            headerTitle: window.translate('Path'),
            label: 'path',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '350px',
        },
        {
            filterKey: 'statusCode',
            headerTitle: window.translate('Status'),
            label: 'statusCode',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '60px',
        },
        {
            filterKey: 'name',
            headerTitle: window.translate('First name'),
            label: 'name',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'surname',
            headerTitle: window.translate('Last name'),
            label: 'surname',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'role',
            headerTitle: window.translate('Role'),
            label: 'role',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'email',
            headerTitle: window.translate('Email'),
            label: 'email',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '140px',
        },
    ];

    return (
        <div className=" ">
            <PageTitle />

            <ReportTable
                year={year}
                setYear={_setYear.bind(this)}
                onYearMonthChange={(filter) => {
                    /*var {year, month} = filter
                        setState({year, month});
                        reloadData(year, month);*/
                }}
                itemsArray={items}
                headers={headers}
                monthFilter={true}
                history={props.history}
                targetedColumnForMonthFilter={'createdAt'}
                onRowClickHandler={(row) => {
                    //console.log(row, "clicked");
                }}
                targetedColumnForDateFilter={'createdAt'}
                showDateFilter={true}
                xlsExportHandler={(params) => {
                    getExcelExport('audit', params);
                }}
                loadData={(page) => {
                    reloadData(year, page);
                }}
                page={_page}
                listOfItems={items}
            ></ReportTable>
        </div>
    );
}

export default ReservationsReportList;
