

const getUrlParameterByName = (paramname, url) => {
	if (!url) url = window.location.href;
	paramname = paramname.replace(/[[\]]/g, '\\$&');
	var regex = new RegExp('[?&]' + paramname + '(=([^&#]*)|&|#|$)'),
		results = regex.exec(url);
	if (!results) return null;
	if (!results[2]) return '';
	return decodeURIComponent(results[2].replace(/\+/g, ' '));
};



export {getUrlParameterByName}