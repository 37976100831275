import rest from '@data/restWrapper';
import {NotificationTemplateModel} from '@common/modelDefinition';

export const getNotificationTemplates = async ():Promise<NotificationTemplateModel[]> => {
    const notificationsTemplates:NotificationTemplateModel[] = await rest('/NotificationTemplate');
    return notificationsTemplates
  };

  export const saveFormData = async (template:any):Promise<any> => {
        template['key'] = template.uniqueName;
        template['params'] = { ...template };
        await rest('/saveNotificationTemplate', 'POST', template);
  };

  export const saveLanguagesFormData = async (template:any, translations:any):Promise<any> => {
    template['key'] = template.uniqueName;
    template['params'] = { ...template };
    template['params']['params'] = JSON.stringify(translations);
    await rest('/saveNotificationTemplate', 'POST', template);
};

  