import rest from '@data/restWrapper';
import { ReservationModelWithModelsIncluded, MinibarLogWithIncludedModels, UserInfo } from '@common/modelDefinition';
// import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';
// import { closeModal } from '@components/modal';

export async function loadActiveReservation(roomInfoId: number): Promise<ReservationModelWithModelsIncluded | null> {
    const roomReservations = await rest('/getReservationsForRoom/' + roomInfoId + '?statusEnum=2');
    const activeReservation = roomReservations.length > 0 ? roomReservations[0] : null;
    return activeReservation;
}

export async function loadMinibarItemsForRoom(roomInfoId: number): Promise<MinibarLogWithIncludedModels[]> {
    const enabledRoomMinibarItems = await rest('/getMinibarStatesForRoom/' + roomInfoId);
    const sortedMiniBarItems = enabledRoomMinibarItems.sort(
        (a: MinibarLogWithIncludedModels, b: MinibarLogWithIncludedModels) => (a.posItemId > b.posItemId ? 1 : -1)
    );
    return sortedMiniBarItems;
}

export async function attachMinibarLogsToReservation(consumedMinibarItems: MinibarLogWithIncludedModels[]) {
    for (const consumedMiniBarItem of consumedMinibarItems) {
        if (consumedMiniBarItem.newState !== consumedMiniBarItem.previousState) {
            const { roomInfoId, posItemId, previousState, newState, userUUID, reservationId } = consumedMiniBarItem;
            const newMinibarRecord = {
                roomInfoId,
                posItemId,
                previousState,
                newState,
                userUUID,
                reservationId,
                logType: 2,
                recordedAt: new Date().getTime(),
            };
            await rest('/saveMinibarLog', 'POST', newMinibarRecord);
        }
    }
}

export async function getMinibarConsumption(reservationId: number): Promise<MinibarLogWithIncludedModels[]> {
    const todaysRoomMinibarLogs = await rest('/getMinibarConsumption/' + reservationId, 'POST', {
        from: new Date(),
        to: new Date(),
    });
    return todaysRoomMinibarLogs;
}

export async function delMinibarData(minibarLog: MinibarLogWithIncludedModels, loggedUser: UserInfo) {
    const setToDoleteLog = { ...minibarLog };
    await rest('/saveMinibarLog', 'POST', setToDoleteLog); //stari promijeni logType

    const lastMinibarLog = await rest(
        '/getLastMinibarLogForRoom/' + minibarLog.roomInfoId + '/' + minibarLog.posItemId
    );
    const { roomInfoId, posItemId, previousState, newState, reservationId, id } = minibarLog;
    const newMinibarRecord = {
        roomInfoId,
        posItemId,
        previousState: lastMinibarLog[0].newState,
        newState: lastMinibarLog[0].newState + (previousState - newState),
        userUUID: loggedUser.uuid,
        reservationId,
        logType: 0,
        parentId: id,
        recordedAt: new Date().getTime(),
    };
    await rest('/saveMinibarLog', 'POST', newMinibarRecord); //novi log
}
