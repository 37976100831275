import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import rest from '../../data/restWrapper';

import pubsub from '../../data/pubsub';

/*
UPUTE:
use pubsub emit to start operation tracking... for example: pubsub.emit("broadcastFiscalOperation", "/uploadPricelistLookupIntoPOS", {})
and show this component for example in modal window

*/

function AsyncOperationItem(props) {
    const { error = null, pending = false, name = 'FISCAL PRINTER PROXY TITLE' } = props;
    return (
        <div className="row text-center">
            <div className="col-12 ">{name}</div>
            {error ? (
                <div className="text-danger col-12">{error.message}</div>
            ) : (
                <div className="col-12">
                    {' '}
                    {pending ? (
                        <i className="fa fa-spinner fa-pulse fa-fw"></i>
                    ) : (
                        <i className="fa fa-check" aria-hidden="true"></i>
                    )}{' '}
                </div>
            )}
        </div>
    );
}

class FiscalBroadcastStatusMonitor extends Component {
    constructor(props) {
        super(props);
        this.state = {
            fiscalSRCProxies: [],
            operationPendingMap: {},
            errorMap: {},
        };
    }

    componentDidMount() {
        this.redraw();
        pubsub.on('broadcastFiscalOperation', this.broadcastAsyncOperation.bind(this));
    }

    componentWillUnmount() {
        pubsub.removeListener('broadcastFiscalOperation', this.broadcastAsyncOperation.bind(this));
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.SRCProxies && prevProps.SRCProxies.length !== this.props.SRCProxies.length) {
            this.redraw();
        }
    }

    broadcastAsyncOperation(method, payload = {}) {
        let operationPendingMap = _.clone(this.state.operationPendingMap);
        for (let i = 0; i < Object.keys(operationPendingMap).length; i++) {
            let keys = Object.keys(operationPendingMap);
            let key = keys[i];
            operationPendingMap[key] = true;
        }
        this.setState({ operationPendingMap });
        //ok async magic
        _.forEach(this.state.fiscalSRCProxies, (fiscal) => {
            rest(method + '/' + fiscal.SRCProxyUUID, 'POST', payload)
                .then(() => {
                    let _operationPendingMap = _.clone(this.state.operationPendingMap);
                    _operationPendingMap[fiscal.id] = false;
                    this.setState({ operationPendingMap: _operationPendingMap });
                })
                .catch((err) => {
                    let _operationPendingMap = _.clone(this.state.operationPendingMap);
                    let errorMap = _.clone(this.state.errorMap);
                    _operationPendingMap[fiscal.id] = false;
                    errorMap[fiscal.id] = err;
                    this.setState({ operationPendingMap: _operationPendingMap, errorMap });
                });
        });
    }

    redraw() {
        const { SRCProxies = [] } = this.props;
        let fiscalSRCProxies = _.filter(SRCProxies, (src) => {
            return src.fiscalProxy;
        });
        let errorMap = {};
        let operationPendingMap = {};
        _.forEach(fiscalSRCProxies, (fiscal) => {
            operationPendingMap[fiscal.id] = false;
            errorMap[fiscal.id] = null;
        });
        this.setState({ fiscalSRCProxies, operationPendingMap });
    }
    render() {
        let proxiesMonitor = [];
        _.forEach(this.state.fiscalSRCProxies, (fiscal) => {
            proxiesMonitor.push(
                <AsyncOperationItem
                    key={fiscal.id}
                    pending={this.state.operationPendingMap[fiscal.id]}
                    error={this.state.errorMap[fiscal.id]}
                    name={fiscal.name}
                />
            );
        });

        return <div>{proxiesMonitor}</div>;
    }
}

export default branch(
    {
        SRCProxies: ['model', 'SRCProxy'],
    },
    FiscalBroadcastStatusMonitor
);
