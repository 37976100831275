import React, { Component } from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import Timeline from '../../../components/timeline/src';

import { buildMonthsTimebar, buildDaysTimebar } from '../../../components/timeline/pimaticoHotelUtils';
import {
    getOverlappingPriceListsOnStack /*, getHeaviestPricesForDates*/,
} from '../../../data/controllers/accommodationPriceLists';

const MIN_ZOOM = 2;
const MAX_ZOOM = 100;

let monthsTimebar = buildMonthsTimebar(new Date().getFullYear(), 2, 'level');
let daysTimebar = buildDaysTimebar(new Date().getFullYear(), 2, 'Weight');
const timebar = [daysTimebar, monthsTimebar];

const weightColors = [
    'rgb(0,132,163)',
    'rgb(251,200,106)',
    'rgb(114,78,123)',
    'rgb(151,216,111)',
    'rgb(211,72,54)',
    'rgb(255,161,95)',
    'rgb(129,221,208)',
];

const getPriceListColorMark = (weight, indexInSameWeight = 0) => {
    if (weight + indexInSameWeight < weightColors.length) {
        return weightColors[weight + indexInSameWeight];
    } else if (indexInSameWeight < weightColors.length) {
        return weightColors[indexInSameWeight];
    } else {
        return 'black';
    }
};

class StackedTimeline extends Component {
    constructor(props) {
        super(props);
        this.state = {
            open: true, //rooms
            zoom: 30,
        };
    }

    buildTracksFromAccommodationPriceLists(
        priceListStack,
        startFromYear = new Date().getFullYear(),
        timelineYearsCount = 1
    ) {
        let plByWeight = _.groupBy(priceListStack, (pl) => pl.weight);
        let tracks = [];
        let idCounter = 0;
        _.forEach(plByWeight, (val, key) => {
            let indexMap = _.uniq(_.map(val, 'id'));
            let rowElement = {
                id: key,
                weight: Number(key),
                title: <span>{key}</span>,
                elements: _.map(val, (v) => {
                    idCounter++;
                    let temp = {};
                    if (key === '0') {
                        //baseline
                        temp = {
                            id: 'pl-' + idCounter,
                            accommodationPriceList: v,
                            title: v.name,
                            start: new Date(`${startFromYear}`), //new Date(`${new Date().getFullYear()}`),
                            end: new Date(`${startFromYear + timelineYearsCount}`),
                            tooltip: this.props.getTooltipFromPriceList(v, false, this.props.defaultCurrency), //<div>BASELINE</div>,
                            style: {
                                backgroundColor: weightColors[v.weight],
                                //boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)"
                            },
                        };
                    } else {
                        temp = {
                            id: 'pl-' + idCounter,
                            accommodationPriceList: v,
                            title: v.name,
                            start: new Date(v.validFrom), //new Date(moment(v.validFrom).startOf("day")),
                            end: new Date(v.validTo), //new Date(moment(v.validTo).endOf("day")),
                            tooltip: this.props.getTooltipFromPriceList(v, false, this.props.defaultCurrency), //<div>BASELINE</div>,
                            style: {
                                backgroundColor: getPriceListColorMark(v.weight, _.indexOf(indexMap, v.id)), //weightColors[v.weight],
                                //boxShadow: "1px 1px 0px rgba(0, 0, 0, 0.25)"
                            },
                        };
                    }
                    return temp;
                }),
            };
            tracks.push(rowElement);
        });
        return _.reverse(tracks);
    }

    priceListClickHandler(element) {
        this.props.showFormModal(element.accommodationPriceList.id);
        //        window.$("#mainModal").modal("show")
    }

    priceListClickHandlerById(id) {
        this.props.showFormModal(id);
        //     window.$("#mainModal").modal("show")
    }

    render() {
        let timelineYearsCount = this.props.timelineYearsCount ? this.props.timelineYearsCount : 1;
        let startFromYear = this.props.startFromYear ? this.props.startFromYear : new Date().getFullYear();

        let parentId = Number(this.props.match.params.accommodationPriceListId);
        let filteredPriceLists = parentId
            ? _.filter(this.props.parsedAccommodationPriceLists, (f) => f.parentId === parentId || f.id === parentId)
            : [];

        const { open, zoom } = this.state;
        const start = new Date(`${startFromYear}`);
        const end = new Date(`${startFromYear + timelineYearsCount}`);
        const nowMarker = new Date();
        const tracks = this.buildTracksFromAccommodationPriceLists(
            filteredPriceLists,
            startFromYear,
            timelineYearsCount
        );
        let scale = {
            start,
            end,
            zoom,
            zoomMin: MIN_ZOOM,
            zoomMax: MAX_ZOOM,
        };

        let overlapps = _.map(getOverlappingPriceListsOnStack(filteredPriceLists), (pl) => {
            let intersects = _.map(pl.intersects, (i) => {
                return (
                    <span key={i} className="mr-2">
                        <small>{i}</small>
                    </span>
                );
            });
            return (
                <li className="list-group-item list-group-item-warning" key={pl.id}>
                    #{pl.id} {pl.name}
                    <span className="pull-right">
                        {window.translate("Weight")}: {pl.weight}
                        <button
                            onClick={this.priceListClickHandlerById.bind(this, pl.id)}
                            className="btn btn-sm btn-outline-danger ml-2"
                        >
                            <i className="fa fa-edit" />
                        </button>
                    </span>
                    <div>
                        {window.translate("Conflicting dates")}: {intersects}
                    </div>
                </li>
            );
        });

        return (
            <div>
                {overlapps.length === 0 ? null : (
                    <div className="alert alert-danger mt-2 ">
                        <h5>{window.translate("Conflict detected")}</h5>
                        <ul className="list-group mb-2">{overlapps}</ul>

                        <span>{window.translate("Resolve confilcts by shifting the weight slots on your price lists or by adjusting dates to avoid conflicting price lists. Conflicts affects the automated decision making on accommodation prices")}.</span>
                    </div>
                )}

                <h4>{window.translate("Stacked price lists on timeline")}</h4>
                <Timeline
                    scale={scale}
                    // enableSticky
                    clickElement={this.priceListClickHandler.bind(this)}
                    scrollToNow
                    isOpen={open}
                    timebar={timebar}
                    tracks={tracks}
                    now={nowMarker}
                />

                <div className=" mt-2">
                    <small>* {window.translate("Price lists with a higher weight on the same day, will have a greater importance")}.</small>
                </div>
            </div>
        );
    }
}

export default React.memo(
    branch(
        {
            parsedAccommodationPriceLists: ['monkeys', 'parsedAccommodationPriceLists'],
            timelineYearsCount: ['uiStates', 'accommodationPriceListStack', 'timelineYearsCount'],
            startFromYear: ['uiStates', 'accommodationPriceListStack', 'startFromYear'],
        },
        StackedTimeline
    )
);
