import React from 'react';
import { attributesEnum, attributesIconsEnum } from './enum';
import Tooltip from '../../../components/tooltip/tooltip';

const SingeRoomAttributes = ({ nextStepCallback, rooms, setRooms, singleAttrArr, setSingleAttrArr }) => {
    const [dataChange, setDataChange] = React.useState(false);
    React.useEffect(() => {}, [rooms, dataChange]);

    return (
        <div>
            <div className="p-3 text-center">
                {window.translate('Mark other attributes for singe room')}
                <hr />
            </div>
            <div className="row mt-2 p-3 align-items-center justify-content-center">
                {Object.keys(rooms).map((floor) => {
                    return rooms[floor].map((r, index) => {
                        return (
                            <div key={index} className="col-md-2 m-3 d-inline-block border shadow-sm">
                                <div className="mb-1">
                                    <b>{'- ' + r.name + ' -'}</b>
                                </div>
                                {Object.keys(attributesIconsEnum).map((a, key) => {
                                    const atribute = Number(a);
                                    const className = r.roomAttributes.includes(atribute)
                                        ? 'border border-info border-3 rounded bg-light'
                                        : '';

                                    return (
                                        <div className={'pointer d-inline-block col-2 m-2  '} key={key}>
                                            <Tooltip message={window.translate([attributesEnum[a]])}>
                                                <img
                                                    onClick={() => {
                                                        if (rooms[floor][index]['roomAttributes'].includes(atribute)) {
                                                            rooms[floor][index]['roomAttributes'] = rooms[floor][index][
                                                                'roomAttributes'
                                                            ].filter((r) => r !== atribute);
                                                        } else {
                                                            rooms[floor][index]['roomAttributes'] = rooms[floor][index][
                                                                'roomAttributes'
                                                            ].concat([atribute]);
                                                        }

                                                        const _singleAttrArr = singleAttrArr;
                                                        if (!_singleAttrArr.includes(atribute)) {
                                                            _singleAttrArr.push(atribute);
                                                        }

                                                        setSingleAttrArr(_singleAttrArr);
                                                        setRooms(rooms);
                                                        setDataChange(!dataChange);
                                                    }}
                                                    style={{ height: '35px', width: '35px' }}
                                                    alt={"Img"}
                                                    src={attributesIconsEnum[a]}
                                                    className={'p-1 ' + className}
                                                />
                                            </Tooltip>
                                        </div>
                                    );
                                })}
                            </div>
                        );
                    });
                })}
            </div>
            <div className="text-center p-5">
                <div
                    onClick={async (e) => {
                        e.preventDefault();
                        window.location = '/#/firstTimeSetup?step4';
                        nextStepCallback();
                    }}
                    className="btn btn-outline-primary mb-4 pointer"
                >
                    {window.translate('SAVE AND NEXT')}
                </div>
            </div>
        </div>
    );
};

export default SingeRoomAttributes;
