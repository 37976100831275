import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import rest from '../../../data/restWrapper';
import { closeModal } from '@components/modal';
import { toast } from 'react-toastify';

const emptyModel = {
    id: null,
    parentId: null,
    name: null, ////
    percentageAmount: null,
    fixedAmount: null,
    plu: null,
    useFixedAmount: false, //NOT PART OF THE DB MODEL
};

class TaxForm extends Component {
    constructor(props) {
        super(props);
        this.state = _.clone(emptyModel);
        this.name = React.createRef();
        this.percentageAmount = React.createRef();
        this.fixedAmount = React.createRef();
        this.plu = React.createRef();
    }

    async componentDidMount() {
        let { taxId } = this.props;
        if (taxId) {
            //editing
            this.redrawComponent(taxId);
        } else {
            emptyModel.plu = this.renderFirstAvailablePlu();
            this.setState(emptyModel);
        }
        this.checkValidationInputs({name:this.state.name})
    }

    componentDidUpdate(prevProps, prevState) {
        let { taxId } = this.props;
        if (taxId) {
            //editing
            if (prevProps.taxId !== taxId) {
                this.redrawComponent(taxId);
            } else if (prevState.id !== taxId) {
                this.redrawComponent(taxId);
            }
        } else {
            if (prevState.id !== null) {
                emptyModel.plu = this.renderFirstAvailablePlu();
                this.setState(emptyModel);
            }
        }
        if (prevState.useFixedAmount !== this.state.useFixedAmount) {
            this.clearValidationRefs();
        }
    }

    async redrawComponent(taxId) {
        if (!_.isUndefined(taxId) && taxId !== null && _.isNaN(taxId) === false) {
            let currentTax = _.find(this.props.taxes, (t) => {
                return t.id === Number(taxId);
            });
            this.clearValidationRefs();
            this.setState({
                id: currentTax.id,
                name: currentTax.name,
                plu: Number(currentTax.plu),
                fixedAmount: Number(currentTax.fixedAmount),
                percentageAmount: Number(currentTax.percentageAmount),
                useFixedAmount: currentTax.fixedAmount !== null,
            });
        }
    }

    /*isTaxIncludedInPrice() {
        let taxIncludedInPrice = { ...this.state.taxIncludedInPrice };
        taxIncludedInPrice = !this.state.taxIncludedInPrice;
        this.setState({ taxIncludedInPrice });
    }*/

    handleInputChange(type, stateField, e) {
        if (type === 'object') {
            let objData = _.clone(this.state[stateField]);
            objData[e.target.name] = e.target.value;
            this.setState({ [stateField]: objData });
        } else if (type === 'string') {
            this.setState({
                [stateField]: e.target.value,
            });
        } else if (type === 'number') {
            this.setState({
                [stateField]: Number(e.target.value),
            });
        }
    }

    inputHandler(e) {
        let state = _.clone(this.state);
        switch (e.target.type) {
            default:
            case 'text':
                state[e.target.name] = e.target.value;
                break;
            case 'select-one':
            case 'number':
                if (_.isNumber(Number(e.target.value))) {
                    state[e.target.name] = Number(e.target.value);
                } else {
                    return null;
                }
                break;
            case 'checkbox':
                state[e.target.name] = !e.target.value;
                break;
        }
        this.setState(state);
        if(e.target.name==="name"){
            this.checkValidationInputs(state)
        }
    }

    checkValidationInputs(objToValidate){
        let validationResults = this.getValidationInputs(objToValidate);
        this.markInvalidInputRefs(validationResults);
    }

    clearValidationRefs() {
        if (this.name && this.name.current) {
            this.name.current.className = this.name.current.className.replace('is-invalid', '');
        }

        if (this.percentageAmount && this.percentageAmount.current) {
            this.percentageAmount.current.className = this.percentageAmount.current.className.replace('is-invalid', '');
        }
        if (this.fixedAmount && this.fixedAmount.current) {
            this.fixedAmount.current.className = this.fixedAmount.current.className.replace('is-invalid', '');
        }
        if (this.plu && this.plu.current) {
            this.plu.current.className = this.plu.current.className.replace('is-invalid', '');
        }
    }

    markInvalidInputRefs(validationResults) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        _.forEach(invalidInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        _.forEach(validInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }
    getValidationInputs(data) {
        let invalidInputs = [];
        let validInputs = [];
        // this.clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(this.name);
        } else {
            validInputs.push(this.name);
        }
        if (!this.state.useFixedAmount) {
            if (data.percentageAmount === null) {
                data.percentageAmount = 0;
            }
            if (data.percentageAmount < 0 || !_.isNumber(data.percentageAmount)) {
                invalidInputs.push(this.percentageAmount);
            } else {
                validInputs.push(this.percentageAmount);
            }
        } else {
            if (data.fixedAmount <= 0 || !_.isNumber(data.fixedAmount)) {
                invalidInputs.push(this.fixedAmount);
            } else {
                validInputs.push(this.fixedAmount);
            }
            if (data.plu <= 0 || !_.isNumber(data.plu) || this.checkIsPluTaken(data.plu, data.id)) {
                invalidInputs.push(this.plu);
            } else {
                validInputs.push(this.plu);
            }
        }
        return { invalidInputs, validInputs };
    }

    async onSave() {
        try {
            let data = _.clone(this.state);
            if (this.state.useFixedAmount) {
                data['percentageAmount'] = null;
            } else {
                data['fixedAmount'] = null;
                data['plu'] = null;
            }
            let method = 'PUT';
            let path = '/Tax/' + data.id;
            let validationResults = this.getValidationInputs(data);
            this.markInvalidInputRefs(validationResults);
            if (validationResults.invalidInputs.length === 0) {
                if (data.id === null) {
                    delete data.id;
                    method = 'POST';
                    path = '/Tax';
                }
                await rest(path, method, data);
                if(method==="POST"){
                    toast(window.translate("Tax added!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
                }
                // emptyModel.plu = this.renderFirstAvailablePlu()
                this.setState(emptyModel);
                closeModal();
            }
        } catch (err) {
            console.warn(err);
        }
    }

    cancelHandler() {
        this.clearValidationRefs();
        emptyModel.plu = this.renderFirstAvailablePlu();
        this.setState(emptyModel);
        closeModal();
    }

    renderInputField(_name, label, type = 'text', value) {
        return (
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label " htmlFor={'pl-' + _name}>
                    {label}:
                </label>
                <div className="col-md-8">
                    <input
                        ref={this[_name]}
                        id={'pl-' + _name}
                        name={_name}
                        placeholder={label}
                        autoComplete="off"
                        value={value ? value : ''}
                        onChange={this.inputHandler.bind(this)}
                        type={type}
                        className="form-control"
                    />
                </div>
            </div>
        );
    }

    getCheckboxInput(_name, label, value) {
        return (
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-4 col-form-label" htmlFor={'PL-' + _name}>
                    {label}:
                </label>
                <div className="col-8 text-left">
                    <i
                        onClick={this.checkboxHandler.bind(this, _name)}
                        name={_name}
                        value={value}
                        className={value ? 'fa fa-check-square fa-lg ml-2' : 'fa fa-square fa-lg ml-2'}
                    ></i>
                </div>
            </div>
        );
    }

    checkboxHandler(name, e) {
        let obj = _.clone(this.state);
        obj[name] = !obj[name];

        if (name === 'useFixedAmount') {
            if (obj[name]) {
                obj['percentageAmount'] = null;
                obj['plu'] = this.renderFirstAvailablePlu();
            } else {
                obj['fixedAmount'] = null;
                obj['plu'] = null;
            }
        }

        this.setState(obj);
    }

    checkIsPluTaken(_plu, id) {
        let plu = Number(_plu);
        let isTaken = false;
        // let accommodationPriceLists = this.props.accommodationPriceLists
        let taxes = this.props.taxes;
        if (
            _.includes(
                _.map(taxes, (ta) => {
                    if (ta.id !== id && ta.plu) {
                        return ta.plu;
                    }
                }),
                plu
            )
        ) {
            // _.includes(_.map(accommodationPriceLists, acc => { return acc.pluBase ? acc.pluBase.toString().substring(0, 3) : "" }), plu) ||
            isTaken = true;
        }
        return isTaken;
    }

    renderFirstAvailablePlu() {
        //  let accommodationPriceLists = this.props.accommodationPriceLists
        let taxes = this.props.taxes;
        // let pluAccommodationArray = []
        // let pluTaxesArray = []
        let pluArray = [];
        let min = 99;
        if (!_.isEmpty(taxes)) {
            _.map(taxes, (ta) => {
                if (ta.plu) {
                    pluArray.push(Number(ta.plu.toString().substring(0, 3)));
                    //  pluArray.push(Number(ta.plu))
                }
            });
        }
        // pluArray = pluAccommodationArray.concat(pluTaxesArray)
        if (!_.includes(pluArray, min)) {
            pluArray.push(min);
        }
        pluArray = _.sortBy(pluArray);
        let lowest = -1;
        let offset = pluArray[0];
        if (!_.isEmpty(pluArray)) {
            for (let i = 0; i < pluArray.length; ++i) {
                if (pluArray[i] !== offset) {
                    lowest = offset;
                    break;
                }
                ++offset;
            }
            if (lowest === -1) {
                lowest = pluArray[pluArray.length - 1] + 1;
            }
        } else {
            lowest = 1000;
        }

        lowest = lowest * 10;
        return Number(lowest);
    }

    render() {
        //this.renderFirstAvailablePlu()
        let variableInputsBody =
            this.state.useFixedAmount === false ? (
                <span>
                    {this.renderInputField(
                        'percentageAmount',
                        window.translate('Percentage'),
                        'number',
                        Number(this.state.percentageAmount)
                    )}
                </span>
            ) : (
                <span>
                    {this.renderInputField(
                        'fixedAmount',
                        window.translate('Amount'),
                        'number',
                        Number(this.state.fixedAmount)
                    )}
                </span>
            );
        return (
            <div className="container-fluid">
                <div className="container bg-white">
                    <div className="row justify-content-center">
                        <div className="col-md-10 pl-0 pr-0  ">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                className="pt-4 pb-4 pr-2 pl-2 bg-white text-md-right"
                            >
                                {this.renderInputField('name', window.translate('Name'), 'text', this.state.name)}

                                {this.getCheckboxInput(
                                    'useFixedAmount',
                                    window.translate('Fixed tax'),
                                    this.state.useFixedAmount
                                )}

                                {variableInputsBody}

                                <div className="text-center">
                                    <button onClick={this.onSave.bind(this)} className="btn btn-outline-primary">
                                        {window.translate('Save')}
                                    </button>
                                    <div
                                        onClick={this.cancelHandler.bind(this)}
                                        className="btn btn-outline-secondary ml-2 pointer"
                                    >
                                        {window.translate('Cancel')}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        taxes: ['model', 'Tax'],
        accommodationPriceLists: ['model', 'AccommodationPriceList'],
    },
    TaxForm
);
