import React, { Component } from 'react';
import InlineRangeInput from '../components/inlineRangeInput';

class ReportingToolbar extends Component {
    handleDateSelection(type, date) {
        //type="from" or type="to", date is date object
        if (this.props.handleDateSelection) {
            this.props.handleDateSelection(type, date);
        } else {
            console.log('UNHANDLED: handleDateSelection', type, date);
        }
    }
    onSearch(e) {
        if (this.props.onSearch) {
            this.props.onSearch(e);
        } else {
            console.log('UNHANDLED: onSearch', e.target.value);
        }
    }
    render() {
        let actionButtons = this.props.actionsComponent ? (
            <div className="col-md-4 pt-1">{this.props.actionsComponent}</div>
        ) : null;

        return (
            <div
                className={
                    'bg-white border shadow-sm rounded p-2 ' +
                    (this.props.classNameSufix !== undefined
                        ? this.props.classNameSufix
                        : ' animated faster slideInDown')
                }
            >
                <div className="row">
                    {actionButtons}

                    <div className={actionButtons ? 'col-md-5' : 'col-md-9'}>
                        <InlineRangeInput
                            from={this.props.from}
                            to={this.props.to}
                            onChange={this.handleDateSelection.bind(this)}
                        />
                    </div>
                    {this.props.onSearch ? (
                        <div className="col-md-3">
                            <input
                                onChange={this.onSearch.bind(this)}
                                placeholder={window.translate("Search")}
                                type="text"
                                className="form-control form-control-sm"
                                aria-label="Example text with button addon"
                                aria-describedby="button-addon1"
                            />
                        </div>
                    ) : null}
                </div>
            </div>
        );
    }
}

export default ReportingToolbar;
