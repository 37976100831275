import React from 'react';
import PageTitle from '../../components/pageTitle';
import DepositForm from './depositForm';
import SelectPosTerminalWrapper from '../checkoutAndInvoice/checkout/SelectPosTerminalWrapper';

const DepositRegister = (props) => {
    return (
        <div>
            <PageTitle />
            <div className="">
                <SelectPosTerminalWrapper>
                    <div className="row">
                        <DepositForm />
                    </div>
                </SelectPosTerminalWrapper>
            </div>
        </div>
    );
};

export default DepositRegister;
