import React from 'react';

const RoomSelector = (props) => {
    const {
        rooms,
        roomInfoIds,
        allRoomInfoIds,
        masterRoomInfoIds,
        allMasterIds,
        onRoomSelected,
        onFinish,
        selectMode,
    } = props;

    const roomsSelect = rooms.map((r) => {
        if (allRoomInfoIds.includes(r.id) && !roomInfoIds.includes(r.id)) {
            return null;
        }

        if (allMasterIds.includes(r.id) && !masterRoomInfoIds.includes(r.id)) {
        //    return null;
        }

        return (
            <li
                className="list-group-item hover-background pointer p-1 elipsis"
                onClick={() => {
                    onRoomSelected(r);
                }}
                key={r.id}
            >
                {roomInfoIds.includes(r.id) || masterRoomInfoIds.includes(r.id) ? (
                    <i className="fa fa-check-square-o" />
                ) : (
                    <i className="fa fa-square-o" />
                )}{' '}
                {r.name}
                <div className="pull-right">
                    {roomInfoIds.includes(r.id) ? <i className="fa fa-bell text-info" /> : null}
                    {masterRoomInfoIds.includes(r.id) ? <i className="fa fa-medkit text-danger" /> : null}
                </div>
            </li>
        );
    });

    return (
        <div>
            {' '}
            {window.translate("Select mode")}:{' '}
            {selectMode === 'sos' ? <i className="fa fa-bell text-info" /> : <i className="fa fa-medkit text-danger" />}
            <div
                style={{ width: '200px' /*position: 'absolute', left: '25px', top: '25px', zIndex: 100 */ }}
                className="border rounded bg-white mr-2 mb-2"
            >
                <div className="border-bottom" style={{ overflowY: 'auto', maxHeight: '300px' }}>
                    <ul className="list-group list-group-flush">{roomsSelect}</ul>
                </div>

                <div className="text-center">
                    <button
                        onClick={() => {
                            onFinish(true);
                        }}
                        className="btn btn-sm btn-info m-2 mt-3"
                    >
                        {window.translate('Save')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default RoomSelector;