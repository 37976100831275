import baobab from '@data/state/index';
import { loadHouseholdPlan } from './api';
import {
    CleaningStatusHeaderExtendedWithCleaningStatusModel,
    CleaningStatusExtendedWithIncludedModels,
} from '@common/modelDefinition';
import { MaidCleaningSchedule } from './interfaces';
import { closeModal } from '@components/modal';

//@ts-ignore
import _ from 'lodash';

const setHouseholdPlan = async () => {
    const householdPlan = await loadHouseholdPlan();
    let cleaningStatuses: CleaningStatusExtendedWithIncludedModels[] = [];
    for (const cleaningStatusHeader of householdPlan) {
        cleaningStatuses = cleaningStatuses.concat(cleaningStatusHeader.CleaningStatuses);
    }
    baobab.root.select(['model', 'CleaningStatusHeader']).set(householdPlan);
    baobab.root.select(['model', 'CleaningStatus']).set(cleaningStatuses);
};

const setHouseholdPlanForSelectedMaid = (selectedMaid: string) => {
    const selectedMaidHouseholdPlan: MaidCleaningSchedule = getHouseholdPlanForSelectedMaid(selectedMaid);
    baobab.root.select('state', 'householdPlanForSelectedMaid').set(selectedMaidHouseholdPlan);
};

const getHouseholdPlanForSelectedMaid = (selectedMaid: string) => {
    const cleaningStatusHeaders: CleaningStatusHeaderExtendedWithCleaningStatusModel[] = baobab.root
        .select(['monkeys', 'cleaningStatusHeadersWithCleaningStatuses'])
        .get();
    let cleaningStatusesForScheduleDates: CleaningStatusExtendedWithIncludedModels[] = [];
    cleaningStatusHeaders.forEach((csh: CleaningStatusHeaderExtendedWithCleaningStatusModel, key: number) => {
        cleaningStatusesForScheduleDates = cleaningStatusesForScheduleDates.concat(csh.CleaningStatuses);
    });
    const groupCleaningStatusesForScheduleDates = _.groupBy(cleaningStatusesForScheduleDates, 'roomInfoId');
    const selectedMaidHouseholdPlan: MaidCleaningSchedule = {};
    Object.keys(groupCleaningStatusesForScheduleDates).forEach((key: string) => {
        const maidIsAssignedToRoom = groupCleaningStatusesForScheduleDates[key].find(
            (cs: CleaningStatusExtendedWithIncludedModels) =>
                cs.cleanedByUserUuid === selectedMaid || cs.cleanedByUserUuid2 === selectedMaid
        );
        if (maidIsAssignedToRoom) {
            selectedMaidHouseholdPlan[key] = groupCleaningStatusesForScheduleDates[key];
        }
    });
    return selectedMaidHouseholdPlan;
};

const setCleaningStatus = (updatedCleaningStatus: CleaningStatusExtendedWithIncludedModels) => {
    // setamo objekt CleaningStatus
    baobab.root.select(['model', 'CleaningStatus', { id: updatedCleaningStatus.id }]).set({ ...updatedCleaningStatus });
    closeModal();
};

export { setHouseholdPlan, setHouseholdPlanForSelectedMaid, setCleaningStatus, getHouseholdPlanForSelectedMaid };
