import React from 'react';
import CountryCodeIndexed from '../../data/countryCodesIndexed';

const Companies = (props) => {
    const { companies, history } = props;
    if (companies.length === 0) {
        return null;
    }
    return (
        <div>
            <h5>{window.translate('Companies')}</h5>
            <ul className="list-group mt-3 mx-3">
                {companies.map((c, index) => {
                    const countryName = CountryCodeIndexed[c.country] ? CountryCodeIndexed[c.country].name : '';
                    const townName = c.town ? c.town : '';
                    const townAndCountry = [];
                    if (countryName) {
                        townAndCountry.push(countryName);
                    }
                    if (townName) {
                        townAndCountry.push(townName);
                    }

                    return (
                        <li
                            className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}
                            onClick={() => {
                                history.push(`/companies/${c.id}/edit`);
                            }}
                            key={index}
                        >
                            <div className="col-6">
                                {c.name + ', ' + c.idNumber}
                                <br />
                                {townAndCountry.join(', ') + ', ' + c.address + ', ' + c.poNumber}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default Companies;
