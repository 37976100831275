import React from 'react';
import _ from 'lodash';
import baobabTree from '../state';

/*
const statusDesc = {
	0: "not configured",
	1: "online",
	2: "fading",
	3: "disconnected"
}
*/

const getRoomSRCStatusIconByLastSeenTimestamp = (lastSeenTimestamp) => {
	var status = 0;
	let result = null;
	let lastSeenMilisecondsFromNow = Date.now() - lastSeenTimestamp;
	status = 1;
	if (lastSeenMilisecondsFromNow > 11 * 60 * 1000) {
		status = 2;
	}
	if (lastSeenMilisecondsFromNow > 21 * 60 * 1000) {
		status = 3;
	}
	let srcChipStyleColorClass = null;
	switch (status) {
		case 1:
			srcChipStyleColorClass = 'text-success';
			break;
		case 2:
			srcChipStyleColorClass = 'text-warning';
			break;
		case 3:
			srcChipStyleColorClass = 'text-danger';
			break;
		case 0:
		default:
			srcChipStyleColorClass = null;
			break;
	}

	if (srcChipStyleColorClass) {
		result = <i className={'fa fa-microchip ' + srcChipStyleColorClass} />;
	}
	return result;
};

const getRoomSRCStatusIcon = (roomInfo) => {
	let result = null;
	let status = getRoomSRCStatus(roomInfo);
	let srcChipStyleColorClass = null;

	switch (status) {
		case 1:
			srcChipStyleColorClass = 'text-success';
			break;
		case 2:
			srcChipStyleColorClass = 'text-warning';
			break;
		case 3:
			srcChipStyleColorClass = 'text-danger';
			break;
		case 0:
		default:
			srcChipStyleColorClass = null;
			break;
	}

	if (srcChipStyleColorClass) {
		result = <i className={'fa fa-microchip ' + srcChipStyleColorClass} />;
	}

	return result;
};

const getRoomSRCStatus = (roomInfo) => {
	if (roomInfo === undefined || roomInfo === null) {
		console.warn('getRoomSRCStatus: roomInfo is undefined');
	}

	var status = 0;
	let smartRoomControllers = baobabTree.root.select(['model', 'SmartRoomController']).get();
	let srcInfo = _.find(smartRoomControllers, (src) => {
		return src && src.remoteDeviceId === roomInfo.remoteDeviceId;
	});

	if (srcInfo) {
		let lastSeenTimestamp = srcInfo ? srcInfo.lastSeenTimestamp : 0;
		let lastSeenMilisecondsFromNow = Date.now() - lastSeenTimestamp;
		status = 1;

		if (lastSeenMilisecondsFromNow > 11 * 60 * 1000) {
			status = 2;
		}
		if (lastSeenMilisecondsFromNow > 21 * 60 * 1000) {
			status = 3;
		}
	}

	return status;
};
const checkIfRoomHasSettings = (roomInfo) => {
	if (roomInfo === undefined || roomInfo === null) {
		console.warn('checkIfRoomHasSettings: roomInfo is undefined');
	}

	let result = true;
	let smartRoomControllers = baobabTree.root.select(['model', 'SmartRoomController']).get();
	let srcInfo = _.find(smartRoomControllers, (src) => {
		return src && src.remoteDeviceId === roomInfo.remoteDeviceId;
	});
	if (srcInfo) {
		if (srcInfo.srcType === 'SRC_SOS') {
			result = false;
		} else {
			result = true;
		}
	} else {
		result = false;
	}

	return result;
};

export default getRoomSRCStatusIcon;
export { checkIfRoomHasSettings, getRoomSRCStatus , getRoomSRCStatusIconByLastSeenTimestamp};