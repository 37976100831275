import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/pageTitle';
import { branch } from 'baobab-react/higher-order';
import rest from '../../data/restWrapper';
import SearchField from './searchField';

import Reservations from './Reservations';
import Companies from './Companies';
import Customers from './Customers';
import CardCodeResults from './CardCodeResults';
import GroupHolderResResults from './GroupReservations';

const Search = (props) => {
    const { roomInfos = [] } = props;
    const [data, setResults] = useState([]);

    const fetchData = async () => {
        try {
            let val = props.history.location.search.replace('?', '');
            getData(val);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.history.location.search]);

    const getData = async (value) => {
        let path = '/searchData?search=' + value;
        let resultsFromSearchBox = await rest(path);
        setResults(resultsFromSearchBox);
    };

    const {
        reservations = [],
        // customersOnReservations = [],
        companies = [],
        customers = [],
        invoices = [],
        reservationGuests = [],
        reservationCardCode = [],
        groupReservations = [],
    } = data;

    const roomInfoById = {}; //index for faster access
    const reservationCustomersById = {}; //index for faster access
    const companiesById = {}; //index for faster access
    const customersByReservationId = {};

    roomInfos.forEach((room) => {
        roomInfoById[room.id] = room;
    });

    customers.forEach((c) => {
        reservationCustomersById[c.id] = c;
    });

    companies.forEach((c) => {
        companiesById[c.id] = c;
    });

    reservationGuests.forEach((c) => {
        if (customersByReservationId[c.reservationId] === undefined) {
            customersByReservationId[c.reservationId] = [];
        }
        customersByReservationId[c.reservationId].push(c.customerId);
    });

    const params = {
        reservations,
        customers,
        companies,
        invoices,
        reservationGuests,
        roomInfoById,
        reservationCustomersById,
        companiesById,
        customersByReservationId,
        reservationCardCode,
        groupReservations,
    };

    return (
        <div>
            <PageTitle />

            <div className="row justify-content-center">
                <div className="col-12">
                    <button
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className="btn btn-sm btn-secondary mr-2"
                    >
                        {window.translate('GO BACK')}
                    </button>
                </div>

                <div className="col-4 text-center">
                    <SearchField history={props.history} />
                </div>
            </div>

            <div className="mb-3">
                <Companies history={props.history} companies={companies} />
            </div>

            <div className="mb-3">
                <Customers history={props.history} customers={customers} />
            </div>

            <div className="mb-3">
                <Reservations history={props.history} {...params} />
            </div>
            <div className="mb-3">
                <GroupHolderResResults history={props.history} {...params} />
            </div>
            <div className="mb-3">
                <CardCodeResults history={props.history} {...params} />
            </div>
        </div>
    );
};

export default branch(
    {
        roomInfos: ['model', 'RoomInfo'],
    },
    Search
);
