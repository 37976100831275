import rest from '../../../data/restWrapper';
import baobab from '../../../data/state/index';

const getActivePosTerminalConsumption = () => {
	const posTerminalConsumption = [];
	const items = baobab.root.select('state', 'posTerminal', 'activeItemsSelection').get();
	Object.keys(items).forEach((posItemId) => {
		posTerminalConsumption.push({ posItemId, quantity: items[posItemId] });
	});
	return posTerminalConsumption;
};

const performBackendCalculation = async () => {
	const posTerminalId = baobab.root.select('state', 'posTerminal', 'invoice', 'posTerminalId').get();
	const posTerminalConsumption = getActivePosTerminalConsumption();
	const { invoice, invoiceItems } = await rest('/getPendingInvoicePosTerminal', 'POST', {
		posTerminalId: posTerminalId,
		posTerminalConsumption,
	});

	baobab.root.select('state', 'posTerminal', 'invoice').set(invoice);
	baobab.root.select('state', 'posTerminal', 'invoiceItems').set(invoiceItems);
};

const loadPendingInvoice = async (posTerminalId) => {
	voidInvoice()
	//poziva konstruktor i ostalo
	const { invoice, invoiceItems, previousInvoices } = await rest('/getPendingInvoicePosTerminal', 'POST', {
		posTerminalId: posTerminalId,
		posTerminalConsumption: [],
	});
	baobab.root.select('state', 'posTerminal', 'invoice').set(invoice);
	baobab.root.select('state', 'posTerminal', 'invoiceItems').set(invoiceItems);
	baobab.root.select('state', 'posTerminal', 'previousInvoices').set(previousInvoices);
	//invoiceItems
};

const addInvoiceItem = async (posItemId) => {
	const quantity = baobab.root.select('state', 'posTerminal', 'activeItemsSelection', posItemId).get();
	if (quantity) {
		const newQuantity = Number(quantity) + 1;
		baobab.root.select('state', 'posTerminal', 'activeItemsSelection', posItemId).set(newQuantity);
	} else {
		baobab.root.select('state', 'posTerminal', 'activeItemsSelection', posItemId).set(1);
	}
	await performBackendCalculation();
};

const substractInvoiceItem = async (posItemId) => {
	const quantity = baobab.root.select('state', 'posTerminal', 'activeItemsSelection', posItemId).get();
	if (quantity) {
		const newQuantity = Number(quantity) - 1;
		if (newQuantity === 0) {
			baobab.root.select('state', 'posTerminal', 'activeItemsSelection', posItemId).unset();
		} else {
			baobab.root.select('state', 'posTerminal', 'activeItemsSelection', posItemId).set(newQuantity);
		}
	}
	//refreshaj preko backend
	await performBackendCalculation();
};

const setPointOfSaleInputs = async (value, type) => {
	const invoiceCursor = baobab.root.select('state', 'posTerminal', 'invoice');
	invoiceCursor.set([type], value);
	const invoiceId = invoiceCursor.get('id');
	const posTerminalId = baobab.root.select('state', 'posTerminal', 'invoice', 'posTerminalId').get();
	const posTerminalConsumption = getActivePosTerminalConsumption();
	const { invoice } = await rest('/setTerminalInvoiceProperties', 'POST', {
		invoiceId,
		properties: { [type]: value },
		posTerminalConsumption: posTerminalConsumption,
		posTerminalId
	});
	invoiceCursor.set(invoice);
};

const issueInvoice = async () => {
	const posTerminalId = baobab.root.select('state', 'posTerminal', 'invoice', 'posTerminalId').get();
	const posTerminalConsumption = getActivePosTerminalConsumption();
	await rest('/issuePosTerminalInvoice', 'POST', {
		posTerminalId: posTerminalId,
		posTerminalConsumption: posTerminalConsumption,
	});
	voidInvoice()
};

const voidInvoice = async () => {
	//izbriši sve sa //activeItemsSelection:{} što i obnovi rekalkulaciju računa, što će za rezultat imati praznu desnu stranu
	baobab.root.select('state', 'posTerminal', 'activeItemsSelection').set({});
	baobab.root.select('state', 'posTerminal', 'filteredItems').set(null);
	baobab.root.select('state', 'posTerminal', 'invoiceItems').set([]);
	baobab.root.select('state', 'posTerminal', 'invoice').set({});
};

const setCategory = (category = null) => {
	if (category === null) {
		baobab.root.select('state', 'posTerminal', 'filteredItems').set(null);
	} else {
		const posItems = baobab.root.select('model', 'PosItem').get();
		const filtered = [];
		posItems.forEach((p) => {
			const tag = p.category ? p.category.split(';') : [];
			tag.forEach((t) => {
				if (t === category) {
					filtered.push(p);
				}
			});
		});
		baobab.root.select('state', 'posTerminal', 'filteredItems').set(filtered);
	}
};

const attachItemsToRes = async (reservationId) => {
	const posTerminalId = baobab.root.select('state', 'posTerminal', 'invoice', 'posTerminalId').get();
	const posTerminalConsumption = getActivePosTerminalConsumption();
	await rest('/issuePosTerminalInvoice', 'POST', {
		posTerminalId: posTerminalId,
		posTerminalConsumption: posTerminalConsumption,
		reservationId,
	});
	voidInvoice()
}

export {
	loadPendingInvoice,
	addInvoiceItem,
	substractInvoiceItem,
	setPointOfSaleInputs,
	issueInvoice,
	setCategory,
	voidInvoice,
	attachItemsToRes
};