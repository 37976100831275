import React, { useState, useEffect } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import rest from '../../../data/restWrapper';
import moment from 'moment';
import baobab from '../../../data/state';

const ExchangeRateOnDate = (props) => {
    const [exchangeRates, setExchangeRates] = useState([]);

    useEffect(() => {
        const load = async () => {
            const latestExchangeRates = await rest('/getExchangeRatesOnDate/' + props.date);
            let _exchangeRates = [];
            _exchangeRates = latestExchangeRates.map((e) => {
                if (e.shortName) {
                    return {
                        id: e.id,
                        exchangeRate: e.exchangeRate,
                        validFromTimestamp: e.validFromTimestamp,
                        confirmed: e.confirmed,
                        currencyId: e.currencyId,
                        shortName: e.shortName,
                    };
                }
                return null
            });
            _exchangeRates = _exchangeRates.filter((e) => e !== undefined);
            setExchangeRates(_exchangeRates);
        };

        load().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const renderInputField = (_name, key, type = 'text', value) => {
        return (
            <input
                readOnly
                onChange={() => null}
                id={'pl-' + _name}
                name={_name}
                placeholder={_name}
                autoComplete="off"
                value={value ? value : ''}
                type={type}
                className="form-control"
            />
        );
    };

    return (
        <div className="container-fluid">
            <div className="row justify-content-center">
                {!_.isEmpty(exchangeRates) ? (
                    <div className="col-sm-12 col-md-11 col-lg-9 col-xl-8">
                        <ul className="list-group mt-2">
                            <li className={'list-group-item '}>
                                <div className="row align-items-center">
                                    <div className="col-md-5 font-weight">
                                        <small>
                                            <b>{window.translate('Valid from')}</b>
                                        </small>
                                    </div>
                                    <div className="col-md-3 font-weight">
                                        <small>
                                            <b>{window.translate('Default currency')}</b>
                                        </small>
                                    </div>
                                    <div className="col-md-4 font-weight">
                                        <small>
                                            <b>{window.translate('Exchange rate')}</b>
                                        </small>
                                    </div>
                                </div>
                            </li>
                        </ul>
                        <ul className="list-group">
                            {exchangeRates.map((cu, key) => {
                                if(cu?.shortName){
                                    return (
                                        <li key={key} className={'list-group-item '}>
                                            <div className="row align-items-center">
                                                <div className="col-md-5">
                                                    {moment(Number(cu.validFromTimestamp)).format('ll')}
                                                </div>
                                                <div className="col-md-3">{cu.shortName}</div>
                                                <div className="col-md-4">
                                                    {renderInputField('exchangeRate', key, 'number', cu.exchangeRate)}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                }else{
                                    return null
                                }
                            })}
                        </ul>
                    </div>
                ) : (
                    <h6>{'No currencies defined on ' + moment(Number(props.date)).format('ll')}</h6>
                )}
            </div>
            <div className="row justify-content-center mt-2">
                <button
                    onClick={() => baobab.root.select(['modalData', 'display']).set(false)}
                    className="btn btn-sm btn-outline-secondary ml-2"
                >
                    {window.translate('Close')}
                </button>
            </div>
        </div>
    );
};

export default branch(
    {
        currency: ['model', 'Currency'],
    },
    ExchangeRateOnDate
);
