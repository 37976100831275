import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import rest from '../../data/restWrapper';

import { saveModelData } from '../../data/actions/modelActions';
import PageTitle from '../../components/pageTitle';
import FormHeader from '../../components/formHeader';

import { closeModal } from '@components/modal';

class SRCProxyForm extends Component {
    constructor() {
        super();
        this.state = {
            srcProxy: {
                name: '',
                description: '',
                // address: "",
                apiKey: '',
                fiscalProxy: false,
            },
        };
    }

    async componentDidMount() {
        let srcProxyId = this.props.match.params.srcProxyId;
        if (srcProxyId) {
            let srcProxy = await rest('/SRCProxy/' + srcProxyId);
            this.setState({ srcProxy });
        }
    }

    handleManageClick = (id) => {
        this.props.history.push('/group/' + id);
    };

    handleInputChange(e) {
        let srcProxy = _.clone(this.state.srcProxy);
        _.forIn(srcProxy, (value, key) => {
            if (key === e.target.name) {
                srcProxy[key] = e.target.value;
            }
        });

        this.setState({
            srcProxy: srcProxy,
        });
    }
    handleDescriptionChange(e) {
        this.setState({
            description: e.target.value,
        });
    }
    async onSRCProxySave() {
        try {
            let res = await saveModelData('SRCProxy', this.state.srcProxy);
            //this.props.history.goBack()
            if (this.props.callback) {
                this.props.callback(res);
            } else {
                this.props.history.push('/srcProxies');
            }
        } catch (error) {
            console.log(error);
        }
        closeModal();
    }

    cancelHandler() {
        if (this.props.cancelHandler) {
            this.props.cancelHandler();
        } else {
            this.props.history.push('/srcProxies');
        }
        closeModal();
    }

    checkboxHandler() {
        let srcProxy = _.clone(this.state.srcProxy);
        srcProxy['fiscalProxy'] = _.clone(this.state.srcProxy['fiscalProxy']);
        if (!srcProxy['fiscalProxy']) {
            srcProxy['fiscalProxy'] = false;
        }
        srcProxy['fiscalProxy'] = !srcProxy['fiscalProxy'];
        this.setState({ srcProxy });
    }

    render() {
        let srcProxyId = Number(this.props.match.params.srcProxyId);

        return (
            <div className="container-fluid">
                <PageTitle />

                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10   ">
                            <FormHeader>
                                {srcProxyId ? window.translate('Edit SRC proxy') : window.translate('Add SRC proxy')}
                            </FormHeader>

                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                className="pt-4 pb-4 text-right  bg-white "
                            >
                                <div className="form-group row justify-content-center">
                                    <label className="col-md-2 col-form-label" htmlFor="">
                                        {window.translate('Name')}:
                                    </label>
                                    <div className="col-md-8">
                                        <input
                                            autoComplete="off"
                                            name="name"
                                            placeholder={window.translate('Name')}
                                            value={this.state.srcProxy.name ? this.state.srcProxy.name : ''}
                                            onChange={this.handleInputChange.bind(this)}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="form-group row justify-content-center">
                                    <label className="col-md-2 col-form-label" htmlFor="">
                                        {window.translate('Description')}:
                                    </label>
                                    <div className="col-md-8">
                                        <textarea
                                            name="description"
                                            placeholder={window.translate('Description')}
                                            value={
                                                this.state.srcProxy.description ? this.state.srcProxy.description : ''
                                            }
                                            onChange={this.handleInputChange.bind(this)}
                                            rows="4"
                                            cols="50"
                                            className="form-control"
                                        >
                                            {' '}
                                        </textarea>
                                    </div>
                                </div>

                                <div className="form-group row justify-content-center">
                                    <label className="col-md-2 col-form-label" htmlFor="">
                                        {window.translate('API key')}:
                                    </label>
                                    <div className="col-md-8">
                                        <textarea
                                            autoComplete="off"
                                            name="apiKey"
                                            placeholder={window.translate('API key')}
                                            value={this.state.srcProxy.apiKey ? this.state.srcProxy.apiKey : ''}
                                            onChange={this.handleInputChange.bind(this)}
                                            type="text"
                                            className="form-control"
                                        ></textarea>
                                    </div>
                                </div>

                                <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-md-2 col-form-label" htmlFor="">
                                        {window.translate('Connected to fiscal')}:
                                    </label>
                                    <div className="col-md-8 text-left">
                                        <i
                                            onClick={this.checkboxHandler.bind(this)}
                                            name={'fiscalProxy'}
                                            value={
                                                this.state.srcProxy.fiscalProxy
                                                    ? this.state.srcProxy.fiscalProxy
                                                    : false
                                            }
                                            className={
                                                this.state.srcProxy.fiscalProxy
                                                    ? 'fa fa-check-square fa-lg ml-2'
                                                    : 'fa fa-square fa-lg ml-2'
                                            }
                                        ></i>
                                    </div>
                                </div>

                                <div className="text-center">
                                    <div onClick={this.onSRCProxySave.bind(this)} className="btn btn-outline-primary pointer">
                                        {window.translate('Save')}
                                    </div>
                                    <div
                                        onClick={this.cancelHandler.bind(this)}
                                        className="btn btn-outline-secondary ml-2 pointer"
                                    >
                                        {window.translate('Cancel')}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default branch({}, SRCProxyForm);
