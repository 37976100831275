import React, { Component } from 'react';
import rest from '../../data/restWrapper';
import UserRegistration from '../userRegistration';
import { branch } from 'baobab-react/higher-order';
// import baobabTree from "../../data/state";
import baobabTree, { saveCachedBaobabData } from '../../data/state/index';

import pubsub from '../../data/pubsub';

async function authenticate(email, password) {
    return await rest('/authenticateUser', 'POST', { email, password });
}

const getUrlParameterByName = (paramname, url) => {
    if (!url) url = window.location.href;
    paramname = paramname.replace(/[[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + paramname + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
};

class LoginPage extends Component {
    constructor(props) {
        super(props);
        this.state = {
            registerUser: this.props.showRegistration !== undefined ? this.props.showRegistration : false,
            email: '',
            password: '',
            errorMessage: '',
        };
    }
    onChangeHandler(e) {
        let obj = {};
        obj[e.target.name] = e.target.value;
        this.setState(obj);
    }

    async submitForm(e) {
        try {
            e.preventDefault(); //if comming from a form
        } catch (err) {}

        try {
            const {email, password} = this.state
            const tokenInfo = await authenticate(email, password);
            //console.log(tokenInfo);
            baobabTree.root.select('authTokenInfo').set(tokenInfo);
            saveCachedBaobabData(baobabTree);
            pubsub.emit('loginSuccess', email);
        } catch (err) {
            this.setState({ errorMessage: err.message });
        }
    }
    showRegister(e) {
        e.preventDefault();
        this.setState({ registerUser: true });
    }
    render() {
        if (this.state.registerUser) {
            return (
                <UserRegistration
                    onCancel={() => {
                        this.setState({ registerUser: false });
                    }}
                />
            );
        }

        let registerButton =
            getUrlParameterByName('showRegisterButton') === 'true' ? (
                <button onClick={this.showRegister.bind(this)} className="btn btn-primary ml-2">
                    {window.translate("Register")}
                </button>
            ) : null;
        //registerButton = <button onClick={this.showRegister.bind(this)} className="btn btn-primary ml-2">Register</button> //if you ever change your mind, just coment it

        return (
            <div className="keys-background text-center" style={{ top: '0px' }}>
                <div
                    className="bg-light rounded  p-4"
                    style={{ maxWidth: '30rem', margin: '6rem auto', opacity: '0.9' }}
                >
                    <h1 className="text-center">{window.translate("LOGIN")}</h1>
                    <form onSubmit={this.submitForm.bind(this)}>
                        <input type="hidden" value="chrome autocomplete hack" />
                        <div className="form-group">
                            <input
                                value={this.state.email}
                                autoComplete="off"
                                type="email"
                                name="email"
                                placeholder={window.translate("Email")}
                                className="form-control"
                                onChange={this.onChangeHandler.bind(this)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                value={this.state.password}
                                type="password"
                                autoComplete="off"
                                name="password"
                                placeholder={window.translate("Password")}
                                className="form-control"
                                onChange={this.onChangeHandler.bind(this)}
                            />
                        </div>

                        <div className="text-center mt-1">
                            <button onClick={this.submitForm.bind(this)} className="btn btn-primary">
                                {window.translate("Log in")}
                            </button>
                            {registerButton}
                        </div>

                        {this.state.errorMessage !== '' ? (
                            <div className="alert alert-danger mt-2" role="alert">
                                {this.state.errorMessage}
                            </div>
                        ) : null}
                    </form>
                </div>
            </div>
        );
    }
}

export default branch({}, LoginPage);
