import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import { getSpecificModelRow } from '../../data/actions/modelActions';
import rest from '../../data/restWrapper';
import { closeModal } from '@components/modal';

let paramsModel = [{}];
const emptyModel = {
    id: null,
    name: null,
    params: JSON.stringify(_.clone(paramsModel)),
    description: null,
    icon: null,
};

const toBase64 = (file) =>
    new Promise((resolve, reject) => {
        const reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = () => resolve(reader.result);
        reader.onerror = (error) => reject(error);
    });

class AttributeForm extends Component {
    constructor() {
        super();

        this.name = React.createRef();
        this.state = _.clone(emptyModel);
        this.onImageChange = this.onImageChange.bind(this);
    }

    async componentDidMount() {
        let { attributeId } = this.props;
        if (attributeId) {
            //editing
            this.redrawComponent(attributeId);
        } else {
            this.setState(emptyModel);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { attributeId } = this.props;
        if (attributeId) {
            //editing
            if (prevProps.attributeId !== attributeId) {
                this.redrawComponent(attributeId);
            } else if (prevState.id !== attributeId) {
                this.redrawComponent(attributeId);
            }
        } else {
            if (prevState.id !== null) {
                this.setState(emptyModel);
            }
        }
    }

    async redrawComponent(attributeId) {
        if (!_.isUndefined(attributeId) && attributeId !== null && _.isNaN(attributeId) === false) {
            let currentAttribute = await getSpecificModelRow('RoomAttribute', attributeId);
            this.setState({
                id: currentAttribute.id,
                name: currentAttribute.name,
                description: currentAttribute.description,
                icon: currentAttribute.icon,
                params: JSON.parse(currentAttribute.params),
            });
        }
    }

    inputHandler(e) {
        let state = _.clone(this.state);
        switch (e.target.type) {
            default:
            case 'text':
                state[e.target.name] = e.target.value;
                break;
            case 'number':
                if (e.target.value !== '') {
                    state[e.target.name] = Number(e.target.value);
                } else {
                    state[e.target.name] = '';
                }
                break;
            case 'checkbox':
                state[e.target.name] = !e.target.value;
                break;
        }
        this.setState(state);
    }
    clearValidationRefs() {
        if (this.name && this.name.current) {
            this.name.current.className = this.name.current.className.replace('is-invalid', '');
        }
    }

    getValidationInputs(data) {
        let invalidInputs = [];
        let validInputs = [];
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(this.name);
        } else {
            validInputs.push(this.name);
        }

        return { invalidInputs, validInputs };
    }

    markInvalidInputRefs(validationResults) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        _.forEach(invalidInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        _.forEach(validInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }

    async onSave() {
        try {
            let data = _.clone(this.state);
            let params = JSON.stringify(data['params']);
            data['params'] = params;
            let validationResults = this.getValidationInputs(data);
            this.markInvalidInputRefs(validationResults);
            if (validationResults.invalidInputs.length === 0) {
                if (data.id !== null) {
                    let id = data.id;
                    delete data.id;
                    await rest('/RoomAttribute/' + id, 'PUT', data);
                } else {
                    await rest('/RoomAttribute', 'POST', data);
                }
                this.setState(_.clone(emptyModel));
                closeModal();
            }
        } catch (err) {
            console.warn(err);
        }
    }

    cancelHandler() {
        this.setState(emptyModel);
        closeModal();
    }

    renderInputField(_name, label, type = 'text', value) {
        return (
            <div className="form-group row hidden-lg hidden-md visible-xs visible-xs justify-content-center align-items-center">
                <label className="col-4  col-form-label" htmlFor={'pl-' + _name}>
                    {label}:
                </label>
                <div className="col-8 ">
                    <input
                        ref={this[_name]}
                        id={'pl-' + _name}
                        name={_name}
                        placeholder={label}
                        autoComplete="off"
                        value={value ? value : ''}
                        onChange={this.inputHandler.bind(this)}
                        type={type}
                        className="form-control"
                    />
                </div>
            </div>
        );
    }

    onImageChange = async (event) => {
        if (event.target.files && event.target.files[0]) {
            let icon = event.target.files[0];
            let iconBase64 = await toBase64(icon);
            this.setState({
                icon: iconBase64,
            });
        }
    };

    render() {
        return (
            <div className="container-fluid">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 pl-0 pr-0  ">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                className="pt-4 pb-4 text-md-right bg-white"
                            >
                                {this.renderInputField(
                                    'name',
                                    window.translate('Name'),
                                    'text',
                                    window.translate(this.state.name)
                                )}
                                {this.renderInputField(
                                    'description',
                                    window.translate('Description'),
                                    'text',
                                    this.state.description
                                )}
                                <div className="form-group row hidden-lg hidden-md visible-xs visible-xs justify-content-center align-items-center">
                                    <div className="col-4">
                                        <label className="btn btn-sm btn-primary mb-0 pointer" htmlFor={'pl-icon'}>
                                            {window.translate('Select Icon')}
                                        </label>
                                    </div>
                                    <div className="col-8 text-left">
                                        <div className="row align-items-center">
                                            {this.state.icon !== null ? (
                                                <div className="col-4">
                                                    <img
                                                        className=""
                                                        src={this.state.icon}
                                                        alt=""
                                                        style={{ width: '50px', height: '50px' }}
                                                    />
                                                </div>
                                            ) : (
                                                ''
                                            )}
                                            <div className="col-4">
                                                <input
                                                    id={'pl-icon'}
                                                    name={'icon'}
                                                    autoComplete="off"
                                                    onChange={this.onImageChange}
                                                    type="file"
                                                    className="form-control"
                                                    style={{ visibility: 'hidden' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="text-center mt-5">
                                    <button onClick={this.onSave.bind(this)} className="btn btn-outline-primary">
                                        {window.translate('Save')}
                                    </button>
                                    <div
                                        onClick={this.cancelHandler.bind(this)}
                                        className="btn btn-outline-secondary ml-2 pointer"
                                    >
                                        {window.translate('Cancel')}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        //roomInfo: ["monkeys", "roomsFilteredBySearchTerm"],
    },
    AttributeForm
);
