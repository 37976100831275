import React from 'react';
import { branch } from 'baobab-react/higher-order';
import Select from 'react-select'
import _ from "lodash"
import { attachItemsToRes, loadPendingInvoice } from "../terminalViewActions"
import { setModalContent, closeModal } from '@components/modal';
import ConfirmButtons from '@components/buttons/confirmButtons';

const AttachToReservation = ({ posTerminalId, setAttachToRes, reservations, rooms }) => {
    const [roomOptions, setRoomOptions] = React.useState([]);
    const [room, setRoom] = React.useState({});

    React.useEffect(() => {
        const loadData = async () => {
            if (posTerminalId) {
                let options = []
                reservations.forEach((r) => {
                    if (r.statusEnum === 2 /* && r.invoicedStatus !== 1*/) {
                        let roomInfo = rooms.find((room) => room.id === r.roomInfoId);
                        let object = {
                            value: roomInfo.name + "; " + r.guest1CodeCopy,
                            label: roomInfo.name,  //+ (r.guest1CodeCopy ? "; card code --> " + r.guest1CodeCopy : ""), //card code ne smije biti na "izvoli" operaterima, znači ako uneseš kod, onda nađe po tom kodu i za sva tri card code-a u rezervacijama
                            id: roomInfo.id,
                            reservationId: r.id
                        };
                        options.push(object);
                    }
                });
                options = _.orderBy(options, ['value'], ['asc']);
                setRoomOptions(options);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
        //ne želimo da nam se refresha na svaku izmjenu rezervacije
        // eslint-disable-next-line
    }, [posTerminalId]);

    return (<>
        <div className="row p-2 mt-2 mb-2">
            <Select
                className="col-10 m-auto"
                placeholder={window.translate('Search reservation by room or guest card code...')}
                options={roomOptions}
                value={room && room.label ? room : window.translate('Search reservation by room or guest card code...')}
                onChange={async (opt) => {
                    setModalContent(
                        <div className="container-fluid">
                            <div className="text-center">
                                {window.translate("Attach proforma invoice to room") + ` ${opt.label }?`}

                                <hr />

                            </div>
                            <div className="text-center">
                                <ConfirmButtons
                                    onConfirm={async () => {
                                        setRoom(opt);
                                        await attachItemsToRes(opt.reservationId)
                                        await loadPendingInvoice(posTerminalId)
                                        setAttachToRes(false)
                                        closeModal();
                                    }}
                                    onCancel={() => { closeModal() }}
                                />
                            </div>
                        </div>,
                        window.translate('Update reservation')+` #${opt.reservationId}`, // header
                        false, // showCloseButton
                        'modal-md'
                    )
                }}
            ></Select>
        </div>
        <div className="row">
            <div className="btn btn-sm btn-outline-secondary m-auto pointer" onClick={() => setAttachToRes(false)} >
                {window.translate("Cancel")}
            </div>
        </div>
    </>);
};

export default branch({
    reservations: ['model', 'Reservation'],
    rooms: ['model', 'RoomInfo']
}, AttachToReservation);