import React, { useEffect, useState } from 'react';
import rest from '../../data/restWrapper';
import { closeModal } from '@components/modal';

async function authenticate(email, password) {
    return await rest('/authenticateUserForPasswordChange', 'POST', { email, password });
}

const SetResetPasswordForm = (props) => {
    const [passwordUpdated, setPasswordUpdated] = useState(false);
    const [validationMessage, setValidationMessage] = useState('');
    const [currentPassword, setCurrentPassword] = useState('');
    const [password, setPassword] = useState('');
    const [repeatPassword, setRepeatPassword] = useState('');

    useEffect(() => {
        setValidationMessage('');
        setPassword('');
        setRepeatPassword('');
        setCurrentPassword('');
        setPasswordUpdated(false);
    }, [props.user.uuid]);

    const handleInputChange = (e) => {
        if (e.target.name === 'currentPassword') {
            setCurrentPassword(e.target.value);
        } else if (e.target.name === 'password') {
            setPassword(e.target.value);
        } else if (e.target.name === 'repeatPassword') {
            setRepeatPassword(e.target.value);
        }
    };

    const authenticateUser = async (email, password) => {
        let authenticateUser = null;
        try {
            await authenticate(email, password);
            authenticateUser = true;
        } catch (err) {
            authenticateUser = false;
            setValidationMessage(err.message);
        }
        return authenticateUser;
    };

    const submitNewPassword = async () => {
        const userEmail = props.user && props.user.email ? props.user.email : '';
        let _authenticateUser = null;
        if (props.type === 'admin') {
            _authenticateUser = true;
        } else if (props.type === 'userProfile') {
            _authenticateUser = await authenticateUser(userEmail, currentPassword);
        }
        if (_authenticateUser) {
            if (password.length < 6) {
                setValidationMessage(window.translate('Minimum 6 character is required for password'));
            } else if (password !== repeatPassword) {
                setValidationMessage(window.translate('Passwords does not match! Please try again.'));
            } else {
                await rest('/setResetUserPassword', 'PUT', {
                    uuid: props.user.uuid,
                    password: password,
                    repeatPassword: repeatPassword,
                });
                setValidationMessage('');
                setPassword('');
                setRepeatPassword('');
                setPasswordUpdated(true);
            }
        }
    };

    const cancelHandler = () => {
        setValidationMessage('');
        setPassword('');
        setRepeatPassword('');
        setPasswordUpdated(false);
        closeModal();
    };

    if (passwordUpdated) {
        return (
            <div className="container-fluid">
                <h5>{window.translate('Password changed')}!</h5>
                {window.translate('New password for this user would be required on next login!')}
                <div className="text-center mb-5 pt-2">
                    <div onClick={closeModal.bind(this)} className="btn btn-success ml-2 pointer">
                        {window.translate('CANCEL')}
                    </div>
                </div>
            </div>
        );
    } else {
        let oldPasswordInput = null;
        if (props.type === 'userProfile') {
            oldPasswordInput = (
                <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                    <label className="col-md-4 col-form-label" htmlFor="">
                        {window.translate('Current password')}
                    </label>
                    <div className="col-md-8">
                        <input
                            autoFocus={true}
                            autoComplete="off"
                            name="currentPassword"
                            placeholder={window.translate('Current password')}
                            type="password"
                            className="form-control"
                            value={currentPassword ? currentPassword : ''}
                            onChange={handleInputChange.bind(this)}
                        />
                    </div>
                </div>
            );
        }
        return (
            <div className="container-fluid">
                <div className="row justify-content-center">
                    <form
                        onSubmit={(e) => {
                            e.preventDefault();
                        }}
                        className="pt-4 pb-4 pr-2 pl-2 text-md-right"
                    >
                        <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                            <label className="col-md-4 col-form-label" htmlFor="">
                                {window.translate('Email')}
                            </label>
                            <div className="col-md-8 text-left elipsis">{props.user.email}</div>
                        </div>
                        {oldPasswordInput}
                        <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                            <label className="col-md-4 col-form-label" htmlFor="">
                                {window.translate('Password')}
                            </label>
                            <div className="col-md-8">
                                <input
                                    autoFocus={true}
                                    autoComplete="off"
                                    name="password"
                                    placeholder={window.translate('Password')}
                                    type="password"
                                    className="form-control"
                                    value={password ? password : ''}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                        </div>

                        <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                            <label className="col-md-4 col-form-label" htmlFor="">
                                {window.translate('Repeat password')}
                            </label>
                            <div className="col-md-8">
                                <input
                                    autoComplete="off"
                                    name="repeatPassword"
                                    placeholder={window.translate('Repeat password')}
                                    type="password"
                                    className="form-control"
                                    value={repeatPassword ? repeatPassword : ''}
                                    onChange={handleInputChange.bind(this)}
                                />
                            </div>
                        </div>

                        {validationMessage === '' ? null : (
                            <div className="alert alert-danger mt-3 text-center" role="alert">
                                {validationMessage}
                            </div>
                        )}

                        <div className="text-center mb-3 pt-2">
                            <div onClick={submitNewPassword.bind(this)} className="btn btn-outline-primary mr-2 pointer">
                                {window.translate('CONFIRM')}
                            </div>
                            <div onClick={cancelHandler.bind(this)} className="btn btn-outline-secondary pointer">
                                {window.translate('CANCEL')}
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        );
    }
};

export default SetResetPasswordForm;
