import * as React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import DateTimeSelector from './DateTimeSelector';
import { reservationEnums } from '@data/reservationStatusEnums';
import { /* ReservationController, */ Reservation } from '../resController';
import { translate } from '@data/translations';

interface ReservationDateTimeSelectorProps {
    reservation: Reservation;
    onReservationUpdate: (res: Reservation) => void;
    reservations: Reservation[];
    isReadOnly?: boolean;
}

const getDaysArray = function (start: Date, end: Date) {
    for (var arr = [], dt = new Date(start); dt <= end; dt.setDate(dt.getDate() + 1)) {
        arr.push(new Date(dt));
    }
    return arr;
};

const getISODate = (ts: number) => {
    return new Date(ts).toISOString().substr(0, 10);
};

function ReservationDateTimeSelector(props: ReservationDateTimeSelectorProps) {
    const { reservation, onReservationUpdate, reservations, isReadOnly = false } = props;
    const { bedCount = 1, spareBedCount = 0 } = reservation.RoomInfo;
    const { genuineInvoiceIssued } = reservation;
    const disableCheckIn = reservation.statusEnum === reservationEnums.checkedIn; //bojan je disableCheckIn vezao za earlyInvoice???  ne razumijem zašto
    const getReservedDates = (includeFirst = true, includeLast = true) => {
        const result: any[] = [];
        let roomReservations = reservations.filter((r: Reservation) => {
            if (r.id === reservation.id) {
                return false; //remove currently edited reservation...
            }
            return (
                (Number(r.statusEnum) === reservationEnums.earlyBird ||
                    Number(r.statusEnum) === reservationEnums.autoBlocked ||
                    Number(r.statusEnum) === reservationEnums.confirmed ||
                    Number(r.statusEnum) === reservationEnums.checkedIn) &&
                r.roomInfoId === reservation.roomInfoId
            );
        });

        roomReservations.forEach((r: Reservation) => {
            const temp = getDaysArray(new Date(r.checkInTimestamp), new Date(r.checkOutTimestamp));

            for (let i = 0; i < temp.length; i++) {
                const date = temp[i];
                if (i === 0) {
                    if (includeFirst) {
                        result.push(date);
                    }
                } else if (i === temp.length - 1) {
                    if (includeLast) {
                        result.push(date);
                    }
                } else {
                    result.push(date);
                }
            }
        });
        return result;
    };

    const reservationsNumberInputHandler = (e: any) => {
        const res = { ...reservation };
        (res as any)[e.target.name] = Number(e.target.value);
        onReservationUpdate(res);
    };

    const minMaxConstraintsFix = (e: any) => {
        if (e.target.max < Number(e.target.value) || e.target.min > Number(e.target.value)) {
            const res = { ...reservation };
            if (e.target.max < Number(e.target.value)) {
                (res as any)[e.target.name] = Number(e.target.max);
                onReservationUpdate(res);
            } else {
                (res as any)[e.target.name] = Number(e.target.min);
                onReservationUpdate(res);
            }
        }
    };

    const dateTimePickHandler = (type: string, timestamp: number) => {
        const res = { ...reservation };
        (res as any)[type] = timestamp;

        if (type === 'checkOutTimestamp') {
            //for daily stayover
            if (getISODate(res.checkInTimestamp) === getISODate(res.checkOutTimestamp)) {
                if (res.checkInTimestamp >= res.checkOutTimestamp) {
                    res.checkOutTimestamp = res.checkInTimestamp + 1 * 60 * 60 * 1000;
                }
            } else if (res.checkInTimestamp >= res.checkOutTimestamp) {
                res.checkOutTimestamp = res.checkInTimestamp + 24 * 60 * 60 * 1000;
            }
        } else {
            if (res.checkInTimestamp >= res.checkOutTimestamp) {
                res.checkOutTimestamp = res.checkInTimestamp + 24 * 60 * 60 * 1000;
            }
        }
        onReservationUpdate(res);
    };

    const applyCurrentCheckInTime = () => {
        const res = { ...reservation };
        res.checkInTimestamp = Date.now();
        onReservationUpdate(res);
    };

    return (
        <div className="">
            <div className="row">
                <div className="col-md-6">
                    <b className="uppercase">{translate('Check in')}:&ensp;</b>
                    <DateTimeSelector
                        readOnly={disableCheckIn || isReadOnly || genuineInvoiceIssued === 1}
                        disabledDays={getReservedDates(true, false).concat([
                            {
                                before: new Date(),
                            },
                        ])}
                        dateTimePickHandler={(timestamp: number) => {
                            dateTimePickHandler('checkInTimestamp', timestamp);
                        }}
                        value={reservation.checkInTimestamp}
                    />
                </div>
                <div className="col-md-6">
                    <b className="uppercase">{translate('Check out')}:&ensp;</b>
                    <DateTimeSelector
                        readOnly={isReadOnly || genuineInvoiceIssued === 1}
                        disabledDays={getReservedDates(false, true).concat([
                            {
                                before: new Date(reservation.checkInTimestamp),
                            },
                        ])}
                        dateTimePickHandler={(timestamp: number) => {
                            dateTimePickHandler('checkOutTimestamp', timestamp);
                        }}
                        value={reservation.checkOutTimestamp}
                    />
                </div>

                <div className="col-12 mt-2">
                    <div className="form-row align-items-center">
                        <div className="col-auto">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text uppercase">{translate('Adults')}:</span>
                                </div>

                                <input
                                    disabled={isReadOnly || genuineInvoiceIssued === 1}
                                    name="adultsNumber"
                                    min={1}
                                    max={20}
                                    onChange={reservationsNumberInputHandler}
                                    value={reservation.adultsNumber ? reservation.adultsNumber : 1}
                                    onBlur={minMaxConstraintsFix}
                                    type="number"
                                    className="form-control form-control-sm"
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <i className="fa fa-bed" />
                                        &ensp;{bedCount}
                                    </span>
                                </div>
                            </div>
                        </div>
                        <div className="col-auto">
                            <div className="input-group input-group-sm mb-3">
                                <div className="input-group-prepend">
                                    <span className="input-group-text uppercase">{translate('Children')}:</span>
                                </div>
                                <input
                                    disabled={isReadOnly || genuineInvoiceIssued === 1}
                                    name="childrenNumber"
                                    min={0}
                                    max={20}
                                    onChange={reservationsNumberInputHandler}
                                    value={reservation.childrenNumber ? reservation.childrenNumber : 0}
                                    onBlur={minMaxConstraintsFix}
                                    type="number"
                                    className="form-control form-control-sm"
                                />
                                <div className="input-group-append">
                                    <span className="input-group-text">
                                        <small>
                                            <i className="fa fa-bed" />
                                            &ensp;{spareBedCount}
                                        </small>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="col-sm-12">
                    {Number(reservation.statusEnum) === reservationEnums.checkedIn &&
                    reservation.statusEnum !== reservationEnums.checkedIn ? (
                        <button
                            onClick={applyCurrentCheckInTime}
                            className="mt-2 w-100 btn btn-sm btn-warning text-dark d-block"
                        >
                            {translate('APPLY CURRENT TIME & DATE')}
                        </button>
                    ) : null}
                </div>
            </div>
        </div>
    );
}

export default branch(
    {
        reservations: ['model', 'Reservation'],
    },
    ReservationDateTimeSelector
);
