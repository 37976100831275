import React from 'react';
import { translate } from '@data/translations';
import { toast } from 'react-toastify';
import { createCashRegPurpose } from './api';
import { CashRegistarPaymentPurposeModel } from '@common/modelDefinition';
import { closeModal } from '@components/modal';

function PaymentPurposes() {
    const [paymentPurpose, setPaymentPurpose] = React.useState({} as CashRegistarPaymentPurposeModel);

    const handleInputChange = (e: any) => {
        const payPurp: any = { ...paymentPurpose };
        if (e.target.name === 'name') {
            payPurp[e.target.name] = e.target.value;
        } else if (Number(e.target.value) === 0) {
            payPurp[e.target.name] = 0;
        } else if (Number(e.target.value) === 1) {
            payPurp[e.target.name] = 1;
        }
        setPaymentPurpose(payPurp);
    };

    const saveFormData = async () => {
        try {
            if (paymentPurpose && paymentPurpose.name) {
                await createCashRegPurpose(paymentPurpose);
                toast('Payment purpose added!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.INFO,
                });
            }
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
        closeModal();
        setPaymentPurpose({} as CashRegistarPaymentPurposeModel);
    };

    return (
        <div>
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    * {translate('Name')}:
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        placeholder={translate('Name')}
                        name="name"
                        value={paymentPurpose?.name ? translate(paymentPurpose.name) : ''}
                        onChange={(e) => {
                            handleInputChange(e);
                        }}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    * {translate('Type of purpose')}:
                </label>
                <div className="col-md-8">
                    <select
                        name="type"
                        className="form-control"
                        value={paymentPurpose?.type === 0 || paymentPurpose?.type === 1 ? paymentPurpose.type : ''}
                        onChange={(e) => {
                            handleInputChange(e);
                        }}
                    >
                        <option value={'no select'}>{translate('Nothing selected')}</option>
                        <option value={0}>{translate('Pay IN')}</option>
                        <option value={1}>{translate('Pay OUT')}</option>
                    </select>
                </div>
            </div>

            <div className="text-center">
                {paymentPurpose?.name && (paymentPurpose?.type === 0 || paymentPurpose?.type === 1) ? (
                    <div
                        onClick={async () => {
                            await saveFormData();
                        }}
                        className="btn btn-outline-primary pointer"
                    >
                        {translate('Save')}
                    </div>
                ) : null}

                <div onClick={() => closeModal()} className="btn btn-outline-secondary ml-2 pointer">
                    {translate('Cancel')}
                </div>
            </div>
        </div>
    );
}

export default PaymentPurposes;
