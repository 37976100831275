import React, { useState, useEffect } from 'react';
import ReportTable from '../components/ReportTable';
import PageTitle from '../components/pageTitle';
import { getReportData, getExcelExport } from '../data/reports';
import translate from '@data/translations';

const cleaningStatusEnums = {
    0: 'No action taken',
    1: 'Cleaning requested',
    2: 'Staged for cleaning',
    3: 'Cleaning started',
    4: 'Cleaning finished',
    5: 'Cleaning postponed',
    6: 'Cleaning skipped',
};
const reservationStatusEnums = {
    0: translate('Unknown'),
    1: translate('No reservations'),
    2: translate('Guest arrival'),
    3: translate('Due out'),
    4: translate('Stay'),
};

function HouseholdReport(props) {
    const [monthlyHouseholdReport, setMonthlyHouseholdReport] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(0);

    useEffect(() => {
        const loadData = async () => {
            reloadData(year);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const reloadData = async (year, month) => {
        const _monthlyHouseholdReport = await getReportData('household', { year, month });
        setMonthlyHouseholdReport([..._monthlyHouseholdReport]);
    };

    const _setYear = async (e) => {
        setYear(Number(e.target.value));
        await reloadData(Number(e.target.value));
    };
    const headers = [
        {
            filterKey: 'id',
            headerTitle: 'Id',
            label: 'id',
            type: 'num',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '50px',
        },
        {
            filterKey: 'roomName',
            headerTitle: window.translate('Room'),
            label: 'roomName',
            type: 'string',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'cleaningStartedTimestamp',
            headerTitle: window.translate('Cleaning Started'),
            label: 'cleaningStartedTimestamp',
            type: 'datetime',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '180px',
        },
        {
            filterKey: 'cleaningFinishedTimestamp',
            headerTitle: window.translate('Cleaning Finished'),
            label: 'cleaningFinishedTimestamp',
            type: 'datetime',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '180px',
        },
        {
            filterKey: 'duration',
            headerTitle: window.translate('Duration (m)'),
            label: 'duration',
            type: 'time',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'cleaningStatus',
            headerTitle: window.translate('Cleaning Status'),
            label: 'cleaningStatus',
            type: 'enum',
            filterType: 'select',
            enumDescription: cleaningStatusEnums,
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'cleaningNote',
            headerTitle: window.translate('Note'),
            label: 'cleaningNote',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '180px',
        },
        {
            filterKey: 'reservationId',
            headerTitle: window.translate('Reservation'),
            label: 'reservationId',
            type: 'num',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'reservationStatus',
            headerTitle: window.translate('Res. Status'),
            label: 'reservationStatus',
            type: 'enum',
            filterType: 'select',
            enumDescription: reservationStatusEnums,
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'user1',
            headerTitle: window.translate('Cleaned by'),
            label: 'user1',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '150px',
        },
        {
            filterKey: 'user2',
            headerTitle: window.translate('Cleaned by'),
            label: 'user2',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '150px',
        },
        {
            filterKey: 'user3',
            headerTitle: window.translate('Cleaned by'),
            label: 'user3',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '150px',
        },
    ];

    return (
        <div className="">
            <PageTitle />

            <ReportTable
                year={year}
                month={month}
                setYear={_setYear.bind(this)}
                onYearMonthChange={async (filter) => {
                    setYear(filter.year);
                    setMonth(filter.month);
                    await reloadData(filter.year, filter.month);
                }}
                itemsArray={monthlyHouseholdReport}
                headers={headers}
                monthFilter={true}
                history={props.history}
                targetedColumnForMonthFilter={'validOnTimestamp'}
                targetedColumnForDateFilter={'validOnTimestamp'}
                showDateFilter={true}
                xlsExportHandler={(params) => {
                    getExcelExport('household', params);
                }}
                loadData={() => {}}
                page={0}
                listOfItems={monthlyHouseholdReport}
            ></ReportTable>
        </div>
    );
}

export default HouseholdReport;
