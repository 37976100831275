import React, { useEffect } from 'react';
import rest from '../../../data/restWrapper';
import baobabTree from '../../../data/state/index';
import moment from 'moment';
import { useBranch } from 'baobab-react/hooks';
import ReportPrintView from './printView';
import SelectPosTerminalWrapper from '../../../pages/checkoutAndInvoice/checkout/SelectPosTerminalWrapper';

const FinancialReport = (props) => {
    const [financialReport, setFinancialReport] = React.useState(null);
    const [companyInfo, setCompanyInfo] = React.useState(null);

    const [year, setYear] = React.useState(new Date().getFullYear());
    const [month, setMonth] = React.useState(new Date().getMonth());
    const [dateFilterActive, setDateFilterActive] = React.useState(true);
    const [selectedDate, setSelectedDate] = React.useState(new Date()); // izbrisati

    const { from, to, selectedPosTerminalId,PosTerminal } = useBranch({
        from: ['uiStates', 'financialReport', 'from'],
        to: ['uiStates', 'financialReport', 'to'],
        selectedPosTerminalId: ['state', 'selectedPosTerminalId'],
        PosTerminal: ['model', 'PosTerminal'],
    });

   

    const selectedPosTerminal =
        PosTerminal.filter((p) => p.id === selectedPosTerminalId).length > 0 &&
        PosTerminal.filter((p) => p.id === selectedPosTerminalId)[0];

    useEffect(() => {
        async function fetchData() {
            // ako je u developmentu racuni su unazad mjesec dana a ako nije onda su samo za danas, na početnom pageu
            const fromDate = from;
            const toDate = to;
            setYear(fromDate.getFullYear());
            setMonth(toDate.getMonth());
            selectedDate ? setSelectedDate(fromDate) : new Date();
        }
        fetchData();
        // eslint-disable-next-line
    }, [from, to]);

    const toggleDaySelection = (type, date) => {
        setDateFilterActive(!dateFilterActive);
        if (!dateFilterActive) {
            setFromToInBaobab(moment().startOf('day'), moment().endOf('day'));
        } else {
            setFromToInBaobab(
                moment().set('year', moment().year()).set('month', moment().month()).startOf('month'),
                moment().set('year', moment().year()).set('month', moment().month()).endOf('month')
            );
        }
    };

    const getFinancialReport = async () => {
        if (selectedPosTerminalId) {
            const payload = { from: from.getTime(), to: to.getTime(), posTerminalId: selectedPosTerminalId };
            const { financialReport, companyInfo } = await rest('/getFinancialReport', 'POST', payload);

            //24 hours stronger... :) until you figure out how to handle UTC offset
            setFinancialReport(financialReport);
            setCompanyInfo(companyInfo);
        }
    };

    const setFromToInBaobab = (from, to) => {
        baobabTree.root.select('uiStates', 'financialReport', 'from').set(new Date(from));
        baobabTree.root.select('uiStates', 'financialReport', 'to').set(new Date(to));
    };

    const setDateRange = (type, e) => {
        switch (type) {
            case 'year':
                setFromToInBaobab(
                    moment().set('year', Number(e.target.value)).set('month', month).startOf('month'),
                    moment().set('year', Number(e.target.value)).set('month', month).endOf('month')
                );
                break;
            case 'month':
                setFromToInBaobab(
                    moment().set('year', Number(year)).set('month', Number(e.target.value)).startOf('month'),
                    moment().set('year', Number(year)).set('month', Number(e.target.value)).endOf('month')
                );
                break;
            case 'date':
                setFromToInBaobab(moment(e.target.value).startOf('day'), moment(e.target.value).endOf('day'));
                break;

            default:
                break;
        }
    };

    const YearSelect = (props) => {
        let yearOptions = [];
        for (let i = 2020; i <= new Date().getFullYear(); i++) {
            yearOptions.push(
                <option key={i} value={i}>
                    {i}
                </option>
            );
        }

        return (
            <select
                className="form-control form-control-sm px-1 w-auto  bg-white"
                value={year}
                onChange={setDateRange.bind(this, 'year')}
                disabled={dateFilterActive}
            >
                {yearOptions}
            </select>
        );
    };

    const MonthSelect = (props) => {
        let monthOptions = [];
        for (let i = 0; i <= 11; i++) {
            monthOptions.push(
                <option key={i} value={i}>
                    {moment().month(i).format('MMMM')}
                </option>
            );
        }
        return (
            <select
                className="form-control form-control-sm px-1 w-auto bg-white"
                value={month}
                onChange={setDateRange.bind(this, 'month')}
                disabled={dateFilterActive}
            >
                <option key={'hotornot'} value="">
                    {window.translate('Not selected')}
                </option>
                {monthOptions}
            </select>
        );
    };

    const reportType = dateFilterActive ? window.translate('Daily report') : window.translate('Periodic report');

    return (
        <div>
            <SelectPosTerminalWrapper>
                {!financialReport ? (
                    <div className="">
                        <div className="mt-2 ml-1">
                            <button
                                className="btn btn-sm btn-secondary"
                                onClick={() => {
                                    props.history.goBack();
                                }}
                            >
                                {window.translate('GO BACK')}
                            </button>
                        </div>

                        <div className="col-12 col-md-6 p-0 m-0 mb-2 pull-left">
                            <div className="m-1 card bg-white h-100">
                                <div className="card-body">
                                    <h4 className="card-title">{selectedPosTerminal && selectedPosTerminal.name}</h4>
                                    <h5 className="card-title">{window.translate('Fiscal memory report by date')}</h5>
                                    <p className="card-text">
                                        <small>
                                            {window.translate('Start date – defines the starting date for the report')}
                                        </small>
                                    </p>
                                    <p className="card-text">
                                        <small>
                                            {window.translate('End date – defines the ending date for the report')}
                                        </small>
                                    </p>

                                    <div className="row">
                                        <label className="col-4 col-form-label" htmlFor="">
                                            {window.translate('Period')}:
                                        </label>
                                        <div className="col-8 text-left">
                                            <button
                                                className={
                                                    dateFilterActive
                                                        ? 'btn btn-sm btn-secondary  mb-1 mb-md-0 mr-2'
                                                        : 'btn btn-sm btn-outline-secondary  mb-1 mb-md-0 mr-2'
                                                }
                                                onClick={toggleDaySelection.bind(this)}
                                            >
                                                {window.translate('Daily')}
                                            </button>
                                            <button
                                                className={
                                                    dateFilterActive
                                                        ? 'btn btn-sm btn-outline-secondary  mb-1 mb-md-0 mr-2'
                                                        : 'btn btn-sm btn-secondary  mb-1 mb-md-0 mr-2'
                                                }
                                                onClick={toggleDaySelection.bind(this)}
                                            >
                                                {window.translate('Monthly')}
                                            </button>
                                        </div>
                                    </div>
                                    <div className="row justify-content-center align-items-center">
                                        <label className="col-4 col-form-label" htmlFor="">
                                            {window.translate('Date')}:
                                        </label>
                                        <div className="col-8 text-left">
                                            {dateFilterActive ? (
                                                <div>
                                                    <input
                                                        disabled={!dateFilterActive}
                                                        // min={moment([props.year]).startOf('year').format('YYYY-MM-DD')}
                                                        // max={moment([props.year]).endOf('year').format('YYYY-MM-DD')}
                                                        value={moment(selectedDate).format('YYYY-MM-DD')}
                                                        onChange={setDateRange.bind(this, 'date')}
                                                        className="form-control d-inline form-control-sm px-1 w-auto bg-white mb-1 mb-md-0 mr-2"
                                                        type="date"
                                                    ></input>
                                                </div>
                                            ) : (
                                                <div className="d-flex">
                                                    <div className="mb-1 mb-md-0 mr-2">
                                                        <YearSelect />
                                                    </div>
                                                    <div className="mb-1 mb-md-0 mr-2">
                                                        <MonthSelect />
                                                    </div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="text-center mt-3">
                                        <button
                                            className="btn btn-sm btn-warning"
                                            onClick={() => {
                                                getFinancialReport();
                                            }}
                                        >
                                            {window.translate('Apply')}
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : (
                    <div>
                        <ReportPrintView
                            reportType={reportType}
                            financialReport={financialReport}
                            companyInfo={companyInfo}
                            setFinancialReport={setFinancialReport}
                        />
                    </div>
                )}
            </SelectPosTerminalWrapper>
        </div>
    );
};

export default FinancialReport;
