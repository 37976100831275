import React from 'react';

import numbro from 'numbro';
import IssuedInvoicesList from './IssuedInvoicesList';
import ReservationActions from './ReservationActions';
import { getLanguagesInArray } from '@data/translations';
import { setModalContent, closeModal } from '@components/modal';
import LoadingButton from '@components/buttons/loadingButton';
import ConfirmButtons from '@components/buttons/confirmButtons';

import { CustomersAndCompanies } from '../../reservations2/parts/CustomersAndCompanies';
import CurrentOffer from './CurrentOffer';
import GroupReservationActions from './GroupReservationActions';
import { condoStatusCheck } from '@pages/reservations2/utils';

import {
    stornoInvoice,
    stayOverActions,
    setInvoiceType,
    issueInvoice,
    setInvoiceCompany,
    applyCurrentCheckoutDate,
    getIssuedStornoInvoice,
    openInNewTab,
    ignoreCheckoutDateMismatch,
    setInvoiceProperties,
    setInvoiceStornoPreview,
    setReclamationReason,
    setAccommodationInvoice,
    loadCheckoutInvoice,
} from './controler';

import { CheckoutOptionsProps, InvoiceTypeEnum, PaymentType } from './interfaces';
import {
    CompanyModel,
    CustomerModel,
    InvoiceModelWithInvoiceItems,
    PosTerminalModel,
    ReservationEditModel,
} from '@common/modelDefinition';

//@ts-ignore
import moment from 'moment/min/moment-with-locales';
//@ts-ignore
import baobab from '@baobab';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';

const CheckoutOptions = (props: CheckoutOptionsProps) => {
    return (
        <div>
            <SelectedPosTerminal {...props} />
            <CheckoutOptionsToolbar {...props} />

            <SelectInvoiceType {...props} />
            <div className="row pt-2">
                <div className="col-md-6">
                    <CurrencySelection setCurrencyViaPropsAction={setInvoiceProperties} invoice={props.invoice} />
                </div>
                <div className="col-md-3">
                    <SelectLocale
                        setLocaleViaPropsAction={setInvoiceProperties}
                        invoice={props.invoice}
                        systemLocale={props.systemLocale}
                    />
                </div>
                <div className="col-md-3"></div>
            </div>

            <div className="mt-2">
                <InvoiceNote {...props} />
            </div>

            <div className="mt-2">
                <GeneralDiscount {...props} />
            </div>

            <TypeOfPayment {...props} />
            <PaymentAmount {...props} />

            <div className="mt-2 pt-2 row">
                <div className="col-md-12">
                    <InvoiceActions {...props} />
                </div>
                <div className="col-md-12">
                    <StornoInvoice {...props} />
                </div>
            </div>

            <AccommodationInvoice {...props} />

            <div className="mt-2 ">
                {props?.reservationGroupUuid ? (
                    <GroupReservationActions {...props} />
                ) : (
                    <CheckoutDateMissmatch {...props}>
                        <ReservationActions {...props} />
                    </CheckoutDateMissmatch>
                )}
            </div>

            <div className="mt-2 ">
                <CurrentOffer {...props} />
            </div>

            <div className="mt-2 ">
                <IssuedInvoicesList {...props} />
            </div>
        </div>
    );
};

const StornoInvoice = (props: CheckoutOptionsProps) => {
    const { invoiceStornoPreview, translate, reservationId, reservationGroupUuid } = props; //stornoInvoice - jer pozivam drugačiju akciju na PrintView-u
    // const [stornoStep, setStornoStep] = React.useState<number>(0);

    if (!invoiceStornoPreview) {
        return null;
    }
    const { currency, defaultCurrency, reclamationReason, id } = invoiceStornoPreview; //defaultCurrency u invojsu je valuta u trenutku izdavanja računa, dakle nije jednaka onom iz frontedna i baobaba

    const disabled: boolean = defaultCurrency !== currency; //ako odabrana valuta (currency) nije jedanka valuti u vremenu izdavanja računa (defaultCurrency)

    return (
        <div>
            <ReclamationReason {...props} />
            <div className=" d-flex justify-content-center ">
                <LoadingButton
                    disabled={disabled}
                    onClick={async () => {
                        try {
                            if (id) {
                                await stornoInvoice(reclamationReason, reservationId, reservationGroupUuid);
                                const issuedStornoInvoice = getIssuedStornoInvoice(id);
                                openInNewTab(
                                    window.location.origin + `/#/invoiceView/${issuedStornoInvoice.id}?noActions`
                                );
                                setInvoiceStornoPreview(null);
                            }
                        } catch (err: any) {
                            alert(err.message);
                        }
                    }}
                    className={'btn btn-warning uppercase'}
                >
                    {translate('Storno invoice')}
                </LoadingButton>

                <button
                    onClick={async () => {
                        setReclamationReason(null);
                        setInvoiceStornoPreview(null);
                    }}
                    className="ml-2 btn btn-sm btn-secondary"
                >
                    {translate('Cancel')}
                </button>
            </div>
        </div>
    );
};

const ReclamationReason = (props: CheckoutOptionsProps) => {
    const { invoiceStornoPreview } = props;
    if (!invoiceStornoPreview) {
        return null;
    }

    const { reclamationReason = '', pendingInvoice } = invoiceStornoPreview;
    const disabled: boolean = pendingInvoice ? true : false;

    return (
        <div className="d-flex justify-content-center mb-2 ">
            <textarea
                disabled={disabled}
                onChange={(e) => {
                    if (setReclamationReason) {
                        setReclamationReason(e.target.value);
                    }
                }}
                // onBlur={() => setReclamationReason(localReclamationReason)}
                value={reclamationReason ? reclamationReason : ''}
                className="form-control"
                placeholder="Reclamation reason"
            ></textarea>
        </div>
    );
};

interface SelectLocaleProps {
    invoice: InvoiceModelWithInvoiceItems;
    setLocaleViaPropsAction: Function;
    systemLocale: string;
}

const SelectLocale = (props: SelectLocaleProps) => {
    const { invoice, setLocaleViaPropsAction, systemLocale } = props;
    const { locale = 'eng', pendingInvoice } = invoice;
    const languages: string[] = getLanguagesInArray();

    return (
        <div>
            <div className="btn-group  mb-2 mb-md-0" role="group" aria-label="Select locale">
                {languages.map((l: string, index) => {
                    let className = '';
                    if (l === systemLocale) {
                        if (l === locale) {
                            //odabrana je primarna valuta
                            className = 'btn btn-sm btn-info';
                        } else {
                            className = 'btn btn-sm btn-outline-info  ';
                        }
                    } else {
                        if (l === locale) {
                            className = 'btn btn-sm btn-secondary ';
                        } else {
                            className = 'btn btn-sm btn-outline-info ';
                        }
                    }
                    return (
                        <button
                            disabled={!invoice.id || !pendingInvoice ? true : false}
                            onClick={() => {
                                setLocaleViaPropsAction('locale', l);
                            }}
                            className={className}
                            key={index}
                        >
                            {l}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

interface CurrencySelectionProps {
    invoice: InvoiceModelWithInvoiceItems;
    setCurrencyViaPropsAction: Function;
}

const CurrencySelection = (props: CurrencySelectionProps) => {
    const { invoice, setCurrencyViaPropsAction } = props;

    const { exchangeRates = null, defaultCurrency, currency, invoiceType ,pendingInvoice} = invoice;
    if (exchangeRates === null) {
        return null;
    }

    const invoiceCurrencies: any = JSON.parse(exchangeRates);
    const currencies: string[] = [];

    Object.keys(invoiceCurrencies).forEach((currShortName: string) => {
        currencies.push(currShortName);
    });
    return (
        <div>
            <div className="btn-group  mb-2 mb-md-0" role="group" aria-label="Select locale">
                {currencies.map((c: string, index: number) => {
                    let className = '';
                    if (c === defaultCurrency) {
                        if (c === currency) {
                            //odabrana je primarna valuta
                            className = 'btn btn-sm btn-info ';
                        } else {
                            className = 'btn btn-sm btn-outline-info ';
                        }
                    } else {
                        if (c === currency) {
                            className = 'btn btn-sm btn-secondary ';
                        } else {
                            className = 'btn btn-sm btn-outline-info';
                        }
                    }
                    return (
                        <button
                            disabled={invoiceType === InvoiceTypeEnum.advance || !invoice.id || !pendingInvoice}
                            onClick={() => {
                                setCurrencyViaPropsAction('currency', c);
                            }}
                            className={className}
                            key={index}
                        >
                            {c}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

//ovo je primjer kada ne želim odmah upucati akciju i slati sve na backend da se prekalkulira čitav račun
//umjesto toga, keširam note u stanje komponente i pucam akciju tek kada skinem fokus sa inpute
const InvoiceNote = (props: CheckoutOptionsProps) => {
    const { invoice, translate } = props;
    const { note = '', pendingInvoice } = invoice;
    const disabled: boolean = !pendingInvoice;
    const [localNote, setLocalNote] = React.useState<string>(note ? note : '');
    React.useEffect(() => {
        if (note) {
            setLocalNote(note); //kada dobijem osvježenu porku iz propsa, ažuriram i lokalno stanje
        } else {
            setLocalNote('');
        }
    }, [note]);

    if (disabled) {
        return null;
    }

    return (
        <div className="d-flex justify-content-center ">
            <textarea
                disabled={disabled || !invoice.id ? true : false}
                onChange={(e) => {
                    setLocalNote(e.target.value);
                }}
                onBlur={() => setInvoiceProperties('note', localNote)}
                value={localNote}
                className="form-control"
                placeholder={translate('Note')}
            ></textarea>
        </div>
    );
};

const InvoiceActions = (props: CheckoutOptionsProps) => {
    const {
        invoice,
        advanceInvoices,
        checkoutDateMismatchs,
        reservation,
        availableForAdvancePayment,
        translate,
        reservationId,
        reservationGroupUuid,
        invoiceStornoPreview,
        invoicePreview,
    } = props;

    const { invoiceType = InvoiceTypeEnum.geniue, pendingInvoice, totalValue, partialPayment, terminalType } = invoice;

    const disableWhenAdvance: boolean = // Izdajemo avans a vrijednost je 0
        invoiceType === InvoiceTypeEnum.advance &&
        (totalValue === 0 || (advanceInvoices.length > 0 && !partialPayment) || availableForAdvancePayment === 0);
    const disableWhenGenuine: boolean = // Izdajemo genuine a rezervacija je blokirana ili cancelirana
        invoiceType === InvoiceTypeEnum.geniue &&
        reservation &&
        reservation.statusEnum !== 2 &&
        reservation.statusEnum !== 1 &&
        reservation.statusEnum !== 4
            ? true
            : false; // šta ako storniramo račun nakon checkouta

    const disableWhenTotal: boolean = advanceInvoices.length === 0 && (totalValue === 0 || totalValue === null); // Nije bilo avansnih uplata a rijednost računa je 0

    const isCondoReservation: boolean = reservation && condoStatusCheck(reservation).isCondoReservation ? true : false;

    const disableIfCheckoutDateMismatch: boolean =
        checkoutDateMismatchs.length > 0 && !isCondoReservation && terminalType === 'reservation';

    const disableOnInvoicePreview: boolean =
        invoicePreview && Object.keys(invoicePreview.invoice).length > 0 ? true : false;

    const disabled: boolean =
        disableWhenAdvance ||
        disableWhenGenuine ||
        disableWhenTotal ||
        disableIfCheckoutDateMismatch ||
        !invoice.id ||
        disableOnInvoicePreview;
    let issueInvoiceActionText: string | null = null;
    switch (invoiceType) {
        case InvoiceTypeEnum.geniue:
            issueInvoiceActionText = translate('Issue invoice');
            break;
        case InvoiceTypeEnum.advance:
            issueInvoiceActionText = translate('Issue advance invoice');
            break;
        default:
            issueInvoiceActionText = translate('Issue proforma');
            break;
    }
    if (!pendingInvoice || invoiceStornoPreview) {
        return null;
    }
    return (
        <div className=" text-center">
            <button
                disabled={disabled}
                onClick={() =>
                    setModalContent(
                        <div className="container-fluid">
                            <div className="text-center">
                                {translate('Please confirm?')}
                                <hr />
                            </div>

                            <div className="text-center">
                                <ConfirmButtons
                                    onConfirm={async () => {
                                        await issueInvoice(reservationId, reservationGroupUuid);
                                        closeModal();
                                        openInNewTab(window.location.origin + `/#/invoiceView/${invoice.id}?noActions`);
                                    }}
                                    onCancel={() => {
                                        closeModal();
                                    }}
                                />
                            </div>
                        </div>,
                        translate('Issue invoice'), // header
                        false, // showCloseButton
                        'modal-md'
                    )
                }
                className="btn btn-primary uppercase mb-2"
            >
                {issueInvoiceActionText}
            </button>
        </div>
    );
};

const SelectInvoiceType = (props: CheckoutOptionsProps) => {
    const { invoice /*invoiceItems*/, advanceInvoices, translate } = props;
    const { invoiceType = 0, pendingInvoice /*reclamation*/, terminalType, connectedInvoices } = invoice;

    const regularSelection: string = invoiceType === 0 ? 'btn-info' : 'btn-outline-info';
    const avansSelection: string = invoiceType === 1 ? 'btn-info' : 'btn-outline-info';
    const proformaSelection: string = invoiceType === 2 ? 'btn-info' : 'btn-outline-info';
    const disableAdvanceInvoice: boolean =
        terminalType !== 'reservation' || connectedInvoices.length > 0 || !invoice.id;
    const disabledProformaInvoice: boolean = advanceInvoices.length > 0 || !invoice.id;
    if (!pendingInvoice) {
        return null;
    }

    return (
        <div>
            <div className="btn-group" role="group" aria-label="Invoce type">
                <button
                    disabled={!invoice.id ? true : false}
                    onClick={() => setInvoiceType(0)}
                    className={`btn btn-sm ${regularSelection} `}
                >
                    {translate('REGULAR INVOICE')}
                </button>

                <button
                    disabled={disableAdvanceInvoice}
                    onClick={() => setInvoiceType(1)}
                    className={`btn btn-sm ${avansSelection} `}
                >
                    {translate('ADVANCE')}
                </button>

                <button
                    disabled={disabledProformaInvoice}
                    onClick={() => setInvoiceType(2)}
                    className={`btn btn-sm ${proformaSelection} `}
                >
                    {translate('PROFORMA')}
                </button>
            </div>
        </div>
    );
};

const GeneralDiscount = (props: CheckoutOptionsProps) => {
    const { invoice, translate, proformaInvoices } = props;
    const { pendingInvoice, invoiceType, id } = invoice;

    const generalDiscountPercentage = invoice.generalDiscountPercentage ? invoice.generalDiscountPercentage : 0;
    const [localDiscountPercentage, setLocalDiscountPercentage] = React.useState<number>(0);

    const latestGivenGeneralDiscountProforma: number =
        proformaInvoices.length > 0 ? proformaInvoices[proformaInvoices.length - 1].generalDiscountPercentage : 0;

    React.useEffect(() => {
        if (id && generalDiscountPercentage !== null) {
            setLocalDiscountPercentage(generalDiscountPercentage);
        }
    }, [id, generalDiscountPercentage]);

    const handleDiscount = (e: any) => {
        let percentage = e.target.value ? Number(e.target.value) : 0; // ne radi ovaj max, vjerovatno ima veze sa gazenjem sa backenda
        if (Number(e.target.value) >= 100) {
            percentage = 100;
        }
        setLocalDiscountPercentage(percentage);
    };

    if (!pendingInvoice || invoiceType === InvoiceTypeEnum.advance || !invoice.id ) {
        return null;
    }
    return (
        <div className="row justify-content-center align-items-center ">
            <label className="col-4" htmlFor="">
                <small>{translate('Invoice discount')}:</small>
            </label>
            <div className="col-8 text-left">
                <div style={{ width: '200px' }} className="input-group input-group-sm">
                    <input
                        className="form-control form-control-sm text-center"
                        id={'pl-discount'}
                        name="discount"
                        placeholder={translate('Discount')}
                        autoComplete="off"
                        type="number"
                        min={0}
                        max={100}
                        value={localDiscountPercentage ? localDiscountPercentage : ''}
                        onChange={handleDiscount}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text" id="%">
                            %
                        </span>
                        <span className="input-group-text">
                            {'Min'} - {latestGivenGeneralDiscountProforma}
                            {'%'}
                        </span>
                    </div>
                </div>
            </div>

            {generalDiscountPercentage !== localDiscountPercentage ? (
                <button
                    onClick={() => {
                        setInvoiceProperties('generalDiscountPercentage', localDiscountPercentage);
                        if (localDiscountPercentage < latestGivenGeneralDiscountProforma) {
                            setLocalDiscountPercentage(latestGivenGeneralDiscountProforma);
                            setInvoiceProperties('generalDiscountPercentage', latestGivenGeneralDiscountProforma); // Inače bi u slučaju storna predračuna primjenuo zadnji unesen manji iznos
                        }
                    }}
                    className="btn btn-warning btn-sm mt-2 uppercase"
                >
                    {translate('Update')}
                </button>
            ) : null}
        </div>
    );
};

/*
avansna uplata, tj. paymentAmount mora biti spremljena u bazu u lokalnoj valuti! To je mandatorno od backenda!
Sve vezane transformacije za valutne konverzije moraju biti na jendom mjestu, u ovom slučaju to je ova frontend komponente.
u najgorem slučaju, avans se treba omogućiti samo u domaćoj valuti da ne bude problema sa zaokruživanjem - ili napraviti neku konzolu za konverziju!
*/
const PaymentAmount = (props: CheckoutOptionsProps) => {
    const { invoice, translate, availableForAdvancePayment = 0 } = props;
    const {
        currency,
        pendingInvoice,
        invoiceType,
        partialPayment = false,
        paymentAmount = 0,
        currencyExchangeRate,
    } = invoice;

    const availablePaymentAmount: number = availableForAdvancePayment * currencyExchangeRate; //exchange rateo dabrane valute
    const [localPaymentAmount, setLocalPaymentAmount] = React.useState<number | null>(paymentAmount);

    React.useEffect(() => {
        if (paymentAmount) {
            setLocalPaymentAmount(paymentAmount);
        }
    }, [paymentAmount]);

    const convertPaymentAmountToDefaultCurrency = () => {
        if (localPaymentAmount) {
            setInvoiceProperties('paymentAmount', localPaymentAmount); //
        }
    };

    const setComponentPaymentAmount = (e: any) => {
        const val = Number(e.target.value);
        if (!isNaN(val) && val >= e.target.min) {
            if (val <= e.target.max) {
                setLocalPaymentAmount(val);
            } else if (val > e.target.max) {
                setLocalPaymentAmount(Number(e.target.max));
            }
        }
    };

    if (invoiceType !== InvoiceTypeEnum.advance) {
        return null;
    }
    return (
        <div>
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-4 col-form-label" htmlFor="">
                    <small>{translate('Partial payment')}:</small>
                </label>
                <div className="col-8 text-left">
                    <i
                        onClick={() => {
                            setInvoiceProperties('partialPayment', !partialPayment);
                        }}
                        title={'partialPayment'}
                        className={partialPayment ? 'fa fa-check-square fa-lg ml-2' : 'fa fa-square fa-lg ml-2'}
                    ></i>
                </div>
            </div>
            {partialPayment ? (
                <div className="form-group row justify-content-center align-items-center">
                    <label className="col-4 col-form-label" htmlFor="">
                        <small>{translate('Amount')}:</small>
                    </label>
                    <div className="col-8 text-left">
                        <div className="input-group input-group-sm">
                            <input
                                disabled={!pendingInvoice}
                                name="paymentAmount"
                                autoComplete="off"
                                value={
                                    localPaymentAmount
                                        ? numbro(localPaymentAmount).format({
                                              mantissa: 2,
                                              trimMantissa: true,
                                          })
                                        : ''
                                }
                                onChange={setComponentPaymentAmount}
                                type="number"
                                className="form-control"
                                max={availablePaymentAmount ? availablePaymentAmount : ''}
                                min={0}
                            />
                            <div className="input-group-append d-block">
                                <span className="input-group-text" id="currency">
                                    {translate('of')}{' '}
                                    {availablePaymentAmount
                                        ? numbro(availablePaymentAmount).format({
                                              mantissa: 2,
                                              trimMantissa: true,
                                          })
                                        : ''}{' '}
                                    {currency}
                                </span>
                            </div>
                        </div>

                        {paymentAmount !== localPaymentAmount && localPaymentAmount !== 0 ? (
                            <button
                                onClick={convertPaymentAmountToDefaultCurrency}
                                className="btn btn-warning btn-sm mt-2 uppercase"
                            >
                                {translate('Update')}
                            </button>
                        ) : null}
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const CheckoutDateMissmatch = (props: CheckoutOptionsProps) => {
    const {
        invoice = null,
        checkoutDateMismatchs,
        reservation,
        stayoverOverlap,
        translate,
        location,
        reservationId,
        reservationGroupUuid,
    } = props;
    const overlapReservations: ReservationEditModel[] = stayoverOverlap.overlapReservations;

    if (!reservation || !invoice) {
        return null;
    }

    const { id, roomInfoId } = reservation;

    const optionCheck: number | null =
        overlapReservations.length > 0 && stayoverOverlap.type === 'applyCurrentDate' ? stayoverOverlap.option : null;
    const reservationCheckoutDateMismatch: boolean =
        checkoutDateMismatchs?.length > 0
            ? checkoutDateMismatchs.filter((r) => r === id).length > 0
                ? true
                : false
            : false;

    const type: string | null = stayoverOverlap ? stayoverOverlap.type : null;

    const targetOverlapSelection: ReservationEditModel | undefined = overlapReservations?.find(
        (r: ReservationEditModel) => Number(r.roomInfoId) === Number(roomInfoId)
    );

    const { isCondoReservation } = condoStatusCheck(reservation);

    const { pendingInvoice, terminalType } = invoice;
    const showMissmatch: boolean =
        reservationCheckoutDateMismatch &&
        pendingInvoice &&
        !isCondoReservation &&
        terminalType === 'reservation' &&
        invoice.id
            ? true
            : false;

    return (
        <div className="pt-2 ">
            <div style={{ flexWrap: 'wrap' }}>
                {showMissmatch ? (
                    <div className="text-center">
                        <div className="text-danger ">
                            <small>
                                {translate('checkoutDateMissmatchNote')
                                    .replace('{X}', moment(reservation.checkOutTimestamp).format('ll'))
                                    .replace('{Y}', reservation.id)
                                    .replace('{Z}', moment().format('ll'))}
                            </small>
                        </div>
                        <div>
                            <button
                                disabled={
                                    overlapReservations.length > 1 || stayoverOverlap.option !== null ? true : false
                                }
                                onClick={() =>
                                    setModalContent(
                                        <div className="container-fluid">
                                            <div className="text-center">
                                                {translate('Please confirm?')}
                                                <hr />
                                            </div>

                                            <div className="text-center">
                                                <ConfirmButtons
                                                    onConfirm={async () => {
                                                        await applyCurrentCheckoutDate(
                                                            reservation,
                                                            location,
                                                            reservationId,
                                                            reservationGroupUuid
                                                        );
                                                        closeModal();
                                                    }}
                                                    onCancel={() => {
                                                        closeModal();
                                                    }}
                                                />
                                            </div>
                                        </div>,

                                        translate('Apply new checkout date'), // header
                                        false, // showCloseButton
                                        'modal-md'
                                    )
                                }
                                className={'btn btn-sm btn-danger mt-2 mb-2'}
                            >
                                {translate('Apply')}
                            </button>
                            <button
                                className="btn btn-sm btn-secondary mt-2 mb-2 ml-2"
                                onClick={() => {
                                    ignoreCheckoutDateMismatch(reservation);
                                }}
                            >
                                {translate('Ignore')}
                            </button>
                        </div>
                    </div>
                ) : (
                    props.children
                )}
            </div>

            {targetOverlapSelection && type === 'applyCurrentDate' && (
                <div className="row h-100 ">
                    <div className="col-md-12">
                        <div className="list-group m-1">
                            <div className="list-group-item p-2 pointer  d-flex align-items-center justify-content-between">
                                <div>
                                    {translate('Confirm the current date and move the next reservation.')}

                                    {overlapReservations.length > 1 ? (
                                        <p>
                                            <small className="text-danger">
                                                <small>
                                                    {translate(
                                                        'More than one reservation in conflict, please adjust them manualy!'
                                                    )}
                                                </small>
                                            </small>
                                        </p>
                                    ) : null}
                                </div>

                                {optionCheck === 1 ? (
                                    <div className="d-flex">
                                        <i
                                            className="fa fa-2x fa-check-square-o  mr-2"
                                            onClick={() => {
                                                stayOverActions(overlapReservations, 1, 'applyCurrentDate');
                                            }}
                                        />
                                        <button
                                            onClick={() => {
                                                applyCurrentCheckoutDate(
                                                    reservation,
                                                    location,
                                                    reservationId,
                                                    reservationGroupUuid
                                                );
                                            }}
                                            className={'btn btn-sm btn-danger'}
                                        >
                                            {translate('Apply')}
                                        </button>
                                    </div>
                                ) : overlapReservations.length > 1 ? (
                                    <div className="d-flex">
                                        <i
                                            className="fa fa-2x fa-check-square-o  mr-2"
                                            onClick={() => {
                                                return null;
                                            }}
                                        />
                                        <button
                                            onClick={() => {
                                                baobab.root
                                                    .select('state', 'checkoutInvoice', 'stayoverOverlap')
                                                    .set({ overlapReservations: [], option: null, type: null });
                                            }}
                                            className={'btn btn-sm btn-outline-secondary'}
                                        >
                                            {translate('Cancel')}
                                        </button>
                                    </div>
                                ) : (
                                    <i
                                        className="fa fa-2x fa-square-o pull-right mr-2"
                                        onClick={() => {
                                            stayOverActions(overlapReservations, 1, 'applyCurrentDate');
                                        }}
                                    />
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </div>
    );
};

const CheckoutOptionsToolbar = (props: CheckoutOptionsProps) => {
    const { invoice, history, translate, reservationId, reservationGroupUuid } = props;
    const { customerInfo, companyInfo, companyId, pendingInvoice, id } = invoice;
    const [hideOnSelected, setHideOnSelected] = React.useState<boolean>(true);

    const parsedCustomerInfo: CustomerModel = customerInfo ? JSON.parse(customerInfo) : null;
    const parsedCompanyInfo: CompanyModel = companyInfo ? JSON.parse(companyInfo) : null;

    return (
        <div className="">
            <div style={{ flexWrap: 'wrap' }}>
                <button
                    onClick={() => {
                        history.goBack();
                    }}
                    className="btn btn-sm btn-secondary mb-2 mr-2"
                >
                    {translate('GO BACK')}
                </button>
                <button
                    disabled={!pendingInvoice || !id}
                    onClick={() => {
                        setHideOnSelected(!hideOnSelected);
                    }}
                    className={
                        companyId ? 'btn btn-sm btn-primary mb-2 mr-2' : 'btn btn-sm btn-outline-primary mb-2 mr-2'
                    }
                >
                    + {translate('Company')}
                </button>
            </div>

            {invoice.id ? (
                <CustomersAndCompanies
                    hideOnSelected={hideOnSelected}
                    history={history}
                    company={parsedCompanyInfo}
                    customer={parsedCustomerInfo}
                    companySelect={(c) => {
                        if (c.id) {
                            setInvoiceCompany(c.id, reservationId, reservationGroupUuid);
                            setHideOnSelected(true);
                        }
                    }}
                    deleteCompany={() => {
                        setInvoiceCompany(null, reservationId, reservationGroupUuid);
                        setHideOnSelected(true);
                    }}
                    customerSelect={() => {
                        return null;
                    }}
                />
            ) : null}
        </div>
    );
};

const SelectedPosTerminal = (props: CheckoutOptionsProps) => {
    const { selectedPosTerminalId, PosTerminals } = props;

    if (PosTerminals.length === 0 || !selectedPosTerminalId) {
        return null;
    }

    const selectedPosTerminal = PosTerminals.find((p: PosTerminalModel) => p.id === selectedPosTerminalId);
    return (
        <div className="p-2 text-center bg-white mb-2">
            <h4 className="mb-0">{selectedPosTerminal && selectedPosTerminal.name}</h4>
        </div>
    );
};

const TypeOfPayment = (props: CheckoutOptionsProps) => {
    const { invoice, translate, fiscalGeneralSettings } = props;
    const { /* enableFiscalisation = false,*/ country = '' } = fiscalGeneralSettings
        ? fiscalGeneralSettings
        : { /*enableFiscalisation: false,*/ country: '' };

    const paymentTypes: PaymentType[] =
        country === 'BIH'
            ? [
                  { name: 'Gotovina', icon: 'fa fa-money mr-2', value: 'G' },
                  { name: 'Kartica', icon: 'fa fa-credit-card mr-2', value: 'K' },
                  { name: 'Ček', icon: 'fa fa-pencil mr-2', value: 'C' },
                  { name: 'Virman', icon: 'fa fa-google-wallet mr-2', value: 'V' },
              ]
            : country === 'CRO'
            ? [
                  { name: 'Gotovina', icon: 'fa fa-money mr-2', value: 'G' },
                  { name: 'Kartica', icon: 'fa fa-credit-card mr-2', value: 'K' },
                  { name: 'Ček', icon: 'fa fa-pencil mr-2', value: 'C' },
                  { name: 'Transakcija', icon: 'fa fa-google-wallet mr-2', value: 'T' },
                  { name: 'Ostalo', icon: 'fa fa-money mr-2', value: 'O' },
              ]
            : [
                  { name: 'G', icon: 'fa fa-money mr-2', value: 'G' },
                  { name: 'K', icon: 'fa fa-credit-card mr-2', value: 'K' },
                  { name: 'C', icon: 'fa fa-pencil mr-2', value: 'C' },
                  { name: 'T', icon: 'fa fa-google-wallet mr-2', value: 'T' },
                  { name: 'O', icon: 'fa fa-money mr-2', value: 'O' },
              ];

    const { pendingInvoice, typeOfPayment, invoiceType } = invoice;

    return (
        <div className="form-group row justify-content-center align-items-center">
            <label className="col-3 col-form-label" htmlFor="">
                <small>{translate('Type of payment')}:</small>
            </label>
            <div className="col-9 text-center">
                {paymentTypes.map((type, key) => {
                    return (
                        <button
                            disabled={!pendingInvoice || !invoice.id || invoiceType ===InvoiceTypeEnum.proforma }
                            key={key}
                            onClick={() => {
                                const { value } = type;
                                setInvoiceProperties('typeOfPayment', value);
                            }}
                            className={
                                typeOfPayment === type.value
                                    ? 'btn btn-sm btn-secondary m-1 '
                                    : 'btn btn-sm btn-light border m-1'
                            }
                        >
                            <i className={type.icon}></i>
                            {type.name}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

const AccommodationInvoice = (props: CheckoutOptionsProps) => {
    const { invoice, accommodationInvoice, translate, genuineInvoices, reservationId, reservationGroupUuid } = props;

    const { terminalType } = invoice;
    if (!accommodationInvoice) {
        return null;
    }
    const isAlreadyIssued: InvoiceModelWithInvoiceItems | undefined = genuineInvoices.find(
        (i) => i.id === accommodationInvoice.id
    );

    if (isAlreadyIssued) {
        return null;
    }

    let showPrintInvoice = false;
    let showCancelButton = false;
    if (invoice.id) {
        if (terminalType === 'reservationConsumption') {
            showPrintInvoice = true;
        } else {
            showCancelButton = true;
        }
    } else {
        showPrintInvoice = true;
    }
    return (
        <ul className="list-group mt-2">
            <li className="list-group-item p-1 text-center">
                <b>{translate('Accommodation invoice')}</b>
            </li>
            <li className="list-group-item p-2">
                <div className="row">
                    <div className="col-2">#{accommodationInvoice?.id}</div>
                    <div className="col-4">{moment().format('LL')}</div>

                    <div className="col-3">
                        {showPrintInvoice ? (
                            <button
                                onClick={async () => {
                                    await loadCheckoutInvoice({ reservationId, reservationGroupUuid });
                                    setAccommodationInvoice();
                                }}
                                className="btn btn-sm btn-outline-primary mr-1"
                            >
                                <i className="fa fa-print"></i>
                            </button>
                        ) : null}
                        {showCancelButton ? (
                            <button
                                onClick={async () => {
                                    if (accommodationInvoice.id) {
                                        if (showCancelButton) {
                                            await loadCheckoutInvoice({ reservationId, reservationGroupUuid });
                                        }
                                    }
                                }}
                                className={'btn btn-sm btn-danger mr-1'}
                            >
                                {translate('Cancel')}
                            </button>
                        ) : null}
                    </div>
                    <div className="col-3 text-right">
                        {numbro(
                            Number(accommodationInvoice.totalValue) * Number(accommodationInvoice.currencyExchangeRate)
                        ).format({
                            mantissa: 2,
                            trimMantissa: false,
                            thousandSeparated: true,
                        })}{' '}
                        {accommodationInvoice.currency}
                    </div>
                </div>
            </li>
        </ul>
    );
};

export default branch(
    {
        selectedPosTerminalId: ['state', 'selectedPosTerminalId'],
        PosTerminals: ['model', 'PosTerminal'],
        systemLocale: ['locale'],
        fiscalGeneralSettings: ['fiscal', 'fiscalGeneralSettings'],
    },
    CheckoutOptions
);

export { StornoInvoice, CurrencySelection, SelectLocale, CheckoutDateMissmatch, SelectedPosTerminal, TypeOfPayment };
