import * as React from 'react';
import rest from '../../data/restWrapper';
import PageLoader from '../../components/loaders/loader1';
import PageTitle from '../../components/pageTitle';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
//@ts-ignore
import _ from 'lodash';
import {
    reservationEnums,
    reservationStatusEnumsDesc,
    reservationStatusColors,
} from '../../data/reservationStatusEnums';
import ReservationGuestReference from './parts/ReservationGuestReference';
import RoomEventHistory from '../roomInfo/components/eventsHistory';
import { toast } from 'react-toastify';
import FolderBox from '../../components/documentTools/folderBox';
import FeatureFlag, { isFeatureEnabled } from '../../components/FeatureFlag';
import DateTimeSelector from './parts/DateTimeSelector';
import {
    CompanyModel,
    CustomerModel,
    DoorAccessPointModel,
    ReservationModel,
    ReservationStatus,
    RoomInfoModel,
    UserInfo,
} from '@common/modelDefinition';
import translate from '@data/translations';
import { ReservationController, Reservation } from './resController';

interface DoorAccessLogsInterface {
    reservationId: number | undefined;
    doors: DoorAccessPointModel[];
}

const DoorAccessLogs = (props: DoorAccessLogsInterface) => {
    const [doorAccessLogs, setDoorAccessLogs] = React.useState([]);

    const { reservationId, doors } = props;

    const ctrl = new ReservationController();

    React.useEffect(() => {
        const loadData = async () => {
            if (reservationId) {
                const _doorAccessLogs = await ctrl.getDoorAccessLogsForReservation(reservationId);
                setDoorAccessLogs(_doorAccessLogs);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [reservationId]);

    const logs = doorAccessLogs.map((log: any) => {
        const doorAccesssPointInfo = doors.find((d: DoorAccessPointModel) => {
            return d.id === log.doorAccessPointId;
        });
        return (
            <li key={log.id} className="list-group-item p-1">
                <div className="row">
                    <div className="col-md-4">
                        <small>{doorAccesssPointInfo ? doorAccesssPointInfo.name : translate('unknown?')}</small>
                    </div>
                    <div className="col-md-4">
                        <small> {log.cardSerialNumber} </small>
                    </div>
                    <div className="col-md-4 text-right">
                        <small>{moment(log.timestamp).format('lll')}</small>
                    </div>
                </div>
            </li>
        );
    });

    return (
        <div>
            <h3 className="mt-3 text-secondary">
                <i className="fa fa-address-card-o pr-1" />
                {translate('Access control history')}
            </h3>
            <ul className="list-group list-group-flush bg-white ">
                {logs.length === 0 ? <li className="list-group-item p-1 ">{translate('Nothing to show')}</li> : logs}
            </ul>
        </div>
    );
};

interface ReservationNotesInterface {
    saveNotesUpdate: any;
    reservation: Reservation;
}

const ReservationNotes = (props: ReservationNotesInterface) => {
    const [editMode, setEditMode] = React.useState(false);
    const [editingNotes, setEditingNotes] = React.useState('' as string | null | undefined);

    const { saveNotesUpdate, reservation } = props;

    const noteChangeHandler = (e: any) => {
        setEditingNotes(e.target.value);
    };

    const _saveNotesUpdate = () => {
        const val = _.clone(editingNotes);
        saveNotesUpdate(val);
        setEditMode(false);
        setEditingNotes('');
    };

    return (
        <div>
            {editMode ? null : (
                <button
                    onClick={() => {
                        setEditMode(true);
                        setEditingNotes(reservation.notes);
                    }}
                    className="btn btn-sm btn-info pull-right"
                >
                    <i className="fa fa-edit" />
                </button>
            )}
            <i className="fa fa-sticky-note-o mr-1" aria-hidden="true"></i>
            {translate('Notes')}:
            <br />
            {editMode ? (
                <div>
                    <textarea
                        onChange={noteChangeHandler.bind(this)}
                        className="form-control"
                        value={editingNotes ? editingNotes : ''}
                    ></textarea>
                    <button
                        onClick={_saveNotesUpdate.bind(this)}
                        disabled={editingNotes === reservation.notes}
                        className="btn btn-info btn-sm mt-2 mb-2 pull-right"
                    >
                        {translate('SAVE')}
                    </button>
                </div>
            ) : (
                <i>
                    <small>{reservation.notes}</small>
                </i>
            )}
        </div>
    );
};

interface ReservationDetailsInterface {
    match: any;
    rooms: RoomInfoModel;
    history: any;
    loggedUser: UserInfo;
    reservations: ReservationModel[];
    doors: DoorAccessPointModel[];
}

function ReservationDetails(props: ReservationDetailsInterface) {
    const [newCheckInProposal, setNewCheckInProposal] = React.useState(0);
    const [newCheckOutProposal, setNewCheckOutProposal] = React.useState(0);
    const [reservations, setReservations] = React.useState([]);
    const [reservation, setReservation] = React.useState({} as Reservation);
    const [customer, setCustomer] = React.useState({} as CustomerModel);
    const [company, setCompany] = React.useState({} as CompanyModel);
    const [err, setErr] = React.useState(null);
    const [groupRes, setGroupReservations] = React.useState([] as Reservation[]);

    const { match, rooms, history, loggedUser, doors } = props;

    const ctrl = new ReservationController();

    React.useEffect(() => {
        const loadData = async () => {
            try {
                const reservationId = Number(match.params.reservationId);
                const _groupUuid = history.location.search.split('=')[1];

                if (reservationId) {
                    await ctrl.loadReservation(Number(match.params.reservationId));
                } else if (_groupUuid && _groupUuid !== 'null') {
                    await ctrl.loadGroup(_groupUuid);
                    const _groupRes = await ctrl.getReservations();
                    setGroupReservations(_groupRes);
                }

                const _reservation = await ctrl.getActiveReservation();
                await getActiveResData(_reservation);
            } catch (err: any) {
                setErr(err);
                toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const getActiveResData = async (_reservation: any) => {
        const _reservationsForRoom = reservations.filter(
            (r: ReservationModel) => r.roomInfoId === _reservation.roomInfoId
        );

        let _customer = null;
        let _company = null;
        if (_reservation.customerId && _reservation.customerId > -1 && _reservation.customerId !== null) {
            _customer = await rest('/Customer/' + _reservation.customerId);
        }
        if (_reservation.companyId && _reservation.companyId > -1 && _reservation.companyId !== null) {
            _company = await rest('/Company/' + _reservation.companyId);
        }

        setReservation(_reservation);
        setCustomer(_customer);
        setCompany(_company);
        setReservations(_reservationsForRoom);
    };

    const handleChangeCheckInDatePick = (timestamp: any) => {
        try {
            setNewCheckInProposal(timestamp);
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
    };

    const handleExtendCheckOutDatePick = (timestamp: any) => {
        try {
            setNewCheckOutProposal(timestamp);
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
    };

    const saveNotesUpdate = async (reservationNotes: string) => {
        try {
            let payload = {
                id: reservation.id,
                roomInfoId: reservation.roomInfoId,
                notes: reservationNotes,
                checkInTimestamp: reservation.checkInTimestamp,
                checkOutTimestamp: reservation.checkOutTimestamp,
                accommodationPriceListId: reservation.accommodationPriceListId,
                adultsNumber: reservation.adultsNumber,
                statusEnum: reservation.statusEnum,
                childrenNumber: reservation.childrenNumber,
            };
            await rest('/safeSaveOrUpdateReservationModel', 'POST', payload);
            const _reservation = await ctrl.getActiveReservation();
            setReservation(_reservation);
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
    };

    const submitNewCheckinDate = async () => {
        try {
            let payload = {
                id: reservation.id,
                roomInfoId: reservation.roomInfoId,
                checkInTimestamp: newCheckInProposal !== 0 ? newCheckInProposal : reservation.checkInTimestamp,
                checkOutTimestamp: reservation.checkOutTimestamp,
                accommodationPriceListId: reservation.accommodationPriceListId,
                adultsNumber: reservation.adultsNumber,
                statusEnum: reservation.statusEnum,
                childrenNumber: reservation.childrenNumber,
            };
            await rest('/safeSaveOrUpdateReservationModel', 'POST', payload);
            const _reservation = await ctrl.getActiveReservation();
            setReservation(_reservation);
            setNewCheckInProposal(0);
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
    };

    const submitNewCheckoutDate = async () => {
        try {
            let payload = {
                id: reservation.id,
                roomInfoId: reservation.roomInfoId,
                checkInTimestamp: reservation.checkInTimestamp,
                checkOutTimestamp: newCheckOutProposal !== 0 ? newCheckOutProposal : reservation.checkOutTimestamp,
                accommodationPriceListId: reservation.accommodationPriceListId,
                adultsNumber: reservation.adultsNumber,
                statusEnum: reservation.statusEnum,
                childrenNumber: reservation.childrenNumber,
            };
            await rest('/safeSaveOrUpdateReservationModel', 'POST', payload);
            const _reservation = await ctrl.getActiveReservation();
            setReservation(_reservation);
            setNewCheckOutProposal(0);
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
    };

    if (err) {
        return 'There was an error loading reservation details! Permissions may be the case!';
    }

    if (!reservation) {
        return translate('Loading ...');
    }

    let customerName = null;
    let companyName = null;
    let reservationCheckIn = null;
    let reservationCheckOut = null;
    let roomInfo = null;

    if (reservation) {
        roomInfo = rooms && _.find(rooms, { id: Number(reservation.roomInfoId) });
        if (customer && customer.firstName) {
            customerName = customer.firstName + ' ' + customer.lastName;
        } else {
            customerName = '';
        }
        if (company && company.name) {
            companyName = company.name;
        } else {
            companyName = '';
        }

        reservationCheckIn = moment(reservation.checkInTimestamp).format('lll');
        if (Number(reservation.statusEnum) === reservationEnums.checkedIn) {
            const previousReservations = reservations.filter((r: Reservation) => {
                return Number(r.statusEnum) === 4 && r.checkOutTimestamp < reservation.checkInTimestamp;
            });
            const limitFromReservations = _.max(_.map(previousReservations, 'checkOutTimestamp'));
            let beforeReservationDate = new Date(limitFromReservations);
            if (beforeReservationDate === undefined) {
                beforeReservationDate = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
            }

            reservationCheckIn = (
                <DateTimeSelector
                    readOnly={true}
                    disabledDays={[
                        {
                            before: beforeReservationDate,
                            after: new Date(reservation.checkOutTimestamp),
                        },
                    ]}
                    dateTimePickHandler={handleChangeCheckInDatePick}
                    value={newCheckInProposal !== 0 ? newCheckInProposal : reservation.checkInTimestamp}
                />
            );
        }

        reservationCheckOut = moment(reservation.checkOutTimestamp).format('lll');
        if (Number(reservation.statusEnum) === reservationEnums.checkedIn) {
            let incommingReservations = reservations.filter((r: Reservation) => {
                return Number(r.statusEnum) < 2 && r.checkInTimestamp > Date.now();
            });
            const firstNextReservation = _.min(_.map(incommingReservations, 'checkInTimestamp'));
            let nextReservationDate = new Date(firstNextReservation);

            if (firstNextReservation === undefined) {
                nextReservationDate = new Date(Date.now() + 3 * 30 * 24 * 60 * 60 * 1000);
            }

            reservationCheckOut = (
                <DateTimeSelector
                    readOnly={true}
                    disabledDays={[
                        {
                            before: new Date(),
                            after: nextReservationDate,
                        },
                    ]}
                    dateTimePickHandler={handleExtendCheckOutDatePick}
                    value={newCheckOutProposal !== 0 ? newCheckOutProposal : reservation.checkOutTimestamp}
                />
            );
        }
    } else {
        return <div>{translate('Loading ...')} ...</div>;
    }

    if (!reservation.id || reservation === null) {
        return <PageLoader />;
    }

    if (!roomInfo) {
        return <PageLoader />;
    }

    return (
        <div className="container">
            <PageTitle />
            <button
                onClick={() => {
                    history.goBack();
                }}
                className="btn btn-sm btn-secondary animated faster slideInDown mr-2"
            >
                {translate('GO BACK')}
            </button>
            <div className="d-inline-flex">
                {groupRes.length > 0
                    ? groupRes.map((g: any, key: number) => {
                          return (
                              <div key={key}>
                                  <button
                                      onClick={async () => {
                                          await getActiveResData(g);
                                      }}
                                      className="btn btn-sm btn-outline-info mr-2"
                                  >
                                      {g.id}
                                  </button>
                              </div>
                          );
                      })
                    : null}
            </div>

            <div className="row animated fadeIn mt-2">
                <div className={isFeatureEnabled('hardware') ? 'col-md-6' : 'col-md-12'}>
                    <div className="card bg-white mb-2">
                        <div className="card-body">
                            <div className="col">
                                <h5 className="card-title">
                                    #{reservation.id} - {translate('Reservation Details')}
                                </h5>
                            </div>

                            <div className="row m-2 border-bottom">
                                <div className="col-md-6">
                                    <i className="fa fa-hotel mr-1" aria-hidden="true"></i>
                                    {translate('Room')}:
                                </div>
                                <div className="col-md-6">{roomInfo.name}</div>
                            </div>
                            <div className="row m-2 border-bottom">
                                <div className="col-md-6">
                                    <i className="fa fa-user mr-1" aria-hidden="true"></i>
                                    {translate('Customer Name')}:
                                </div>
                                <div className="col-md-6">{customerName}</div>
                            </div>
                            <div className="row m-2 border-bottom">
                                <div className="col-md-6">
                                    <i className="fa fa-building mr-1" aria-hidden="true"></i>
                                    {translate('Company Name')}:
                                </div>
                                <div className="col-md-6">{companyName}</div>
                            </div>

                            <div className="row m-2 border-bottom">
                                <div className="col-md-6">
                                    <i className="fa fa-calendar-check-o mr-1" aria-hidden="true"></i>
                                    {translate('Check in')}:
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">{reservationCheckIn}</div>
                                    {newCheckInProposal ? (
                                        <span>
                                            <button
                                                onClick={submitNewCheckinDate}
                                                className="btn btn-sm btn-info ml-2 mb-2"
                                            >
                                                {translate('ACCEPT')}
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setNewCheckInProposal(0);
                                                }}
                                                className="btn btn-sm btn-secondary ml-2 mb-2"
                                            >
                                                {translate('DISMISS')}
                                            </button>
                                        </span>
                                    ) : null}
                                </div>
                            </div>

                            <div className="row m-2 border-bottom">
                                <div className="col-md-6">
                                    <i className="fa fa-sign-out mr-1" aria-hidden="true"></i>
                                    {translate('Check out')}:
                                </div>
                                <div className="col-md-6">
                                    <div className="mb-2">{reservationCheckOut}</div>
                                    {newCheckOutProposal ? (
                                        <span>
                                            <button
                                                onClick={submitNewCheckoutDate}
                                                className="btn btn-sm btn-info ml-2 mb-2"
                                            >
                                                {translate('ACCEPT')}
                                            </button>
                                            <button
                                                onClick={() => {
                                                    setNewCheckOutProposal(0);
                                                }}
                                                className="btn btn-sm btn-secondary ml-2 mb-2"
                                            >
                                                {translate('DISMISS')}
                                            </button>
                                        </span>
                                    ) : null}
                                </div>
                            </div>

                            <div className="row m-2 border-bottom">
                                <div className="col-md-6">
                                    <i className="fa fa-bed mr-1" aria-hidden="true"></i> {translate('Beds/Spare beds')}
                                    :
                                </div>
                                <div className="col-md-6">
                                    {reservation?.RoomInfo?.bedCount} / {reservation?.RoomInfo?.spareBedCount}
                                </div>
                            </div>

                            <div className="row m-2 border-bottom">
                                <div className="col-md-6">
                                    <i className="fa fa-users mr-1" aria-hidden="true"></i>{' '}
                                    {translate('Adults/Children')}:
                                </div>
                                <div className="col-md-6">
                                    {reservation.adultsNumber} / {reservation.childrenNumber}
                                </div>
                            </div>

                            <div className="row m-2 border-bottom">
                                <div className="col-md-6">
                                    <i className="fa fa-info mr-1" aria-hidden="true"></i>
                                    {translate('Reservation status')}:
                                </div>
                                <div className={'col-md-4 ' + reservationStatusColors[reservation.statusEnum]}>
                                    <b>{reservationStatusEnumsDesc[reservation.statusEnum]}</b>
                                </div>
                                <div className="col-md-2 text-right">
                                    <button
                                        className="btn btn-sm btn-light border"
                                        onClick={() => {
                                            props.history.push('/reservations/' + reservation.id + '/edit');
                                        }}
                                    >
                                        <i className="fa fa-calendar text-info" />
                                    </button>
                                </div>
                            </div>

                            <div className="row m-2 border-bottom">
                                <div className="col-md-12">
                                    <ReservationNotes saveNotesUpdate={saveNotesUpdate} reservation={reservation} />
                                </div>
                            </div>

                            <FolderBox
                                showDeleteButton={loggedUser.role === 'admin'}
                                showFileUploadButton={false}
                                {...reservation}
                            />
                            {reservation.statusEnum === ReservationStatus.checkedIn ? (
                                <button
                                    onClick={() => {
                                        history.push('/checkoutAndInvoice/' + reservation.id);
                                    }}
                                    className="btn btn-secondary btn-sm pull-right m-2"
                                >
                                    {translate('Check out')}
                                </button>
                            ) : null}
                        </div>
                    </div>

                    <ReservationGuestReference history={history} reservation={reservation} />
                </div>

                <FeatureFlag flag="hardware">
                    <div className="col-md-6">
                        <RoomEventHistory
                            reservation={reservation}
                            filterEventsByReservation={true}
                            showActiveRoomEvents={false}
                            roomInfo={roomInfo}
                        />

                        <FeatureFlag flag="accessControl">
                            <DoorAccessLogs reservationId={reservation.id} doors={doors} />
                        </FeatureFlag>
                    </div>
                </FeatureFlag>
            </div>
        </div>
    );
}

export default branch(
    {
        //do not use baobab here...
        rooms: ['model', 'RoomInfo'],
        doors: ['model', 'DoorAccessPoint'],
        loggedUser: ['authTokenInfo', 'user'],
        reservations: ['model', 'Reservation'],
    },
    ReservationDetails
);
