import React, { Component } from 'react';
import _ from 'lodash';

class InputAndOutputOfCash extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const { selectedFiscalPrinter, inputOrOutputAmount, inputOrOutputToFiscalPrinter } = this.props;
        return (
            <div className="col-12 col-md-6 p-0 m-0 mb-2 pull-left ">
                <div className="m-1 card bg-white h-100 ">
                    <div className="card-body">
                        <h5 className="card-title">{window.translate("Input and output of cash")}</h5>
                        <div className="form-group row justify-content-center align-items-center">
                            <label className="col-md-4 col-form-label" htmlFor="">
                                {window.translate("Type")}:
                            </label>
                            <div className="col-md-8">
                                {_.map(['Input', 'Output'], (type) => {
                                    return (
                                        <div className="form-check text-left" key={type}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name={type}
                                                id={type}
                                                value={type}
                                                checked={
                                                    this.props.inputOrOutputToFiscalPrinter &&
                                                    this.props.inputOrOutputToFiscalPrinter === type
                                                        ? true
                                                        : false
                                                }
                                                onChange={this.props.handleInputOrOutputToFiscalPrinter.bind(this)}
                                            />
                                            <label className="form-check-label" htmlFor={type}>
                                                {window.translate(type)}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="form-group row justify-content-left align-items-center mt-2 mb-2">
                            <label className="col-md-4 col-form-label" htmlFor="">
                                {window.translate("Amount")}:
                            </label>
                            <div className="col-md-8">
                                <input
                                    id="input-cash"
                                    name="input-cash"
                                    placeholder=""
                                    autoComplete="off"
                                    value={inputOrOutputAmount ? inputOrOutputAmount : ''}
                                    onChange={this.props.inputHandler.bind(this)}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                        </div>

                        <button
                            className="btn btn-sm btn-primary mr-2 mt-2"
                            onClick={this.props.showConfirmModal.bind(this, {
                                option: 'internalInputAndOutputOfCash',
                                fiscalPrinter: selectedFiscalPrinter,
                                type: inputOrOutputToFiscalPrinter,
                            })}
                        >
                            {window.translate("Apply to fiscal printer")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default InputAndOutputOfCash;
