import * as React from 'react';
import { translate } from '@data/translations';
import { ReservationController, Reservation } from '../resController';
import { CustomersAndCompanies } from './CustomersAndCompanies';
import CustomerForm from '../../customers/form';
import { setModalContent, closeModal } from '@components/modal';
import { CustomerModel, ReservationGuestReferenceModel, TaxModel } from '@common/modelDefinition';
import VisaDataModal from '../../customers/visaDataModal';
import Delete from '../../../components/confirmModal';
//@ts-ignore
import countryCodes from '@common/countryCodes';
//@ts-ignore
import { useBranch } from 'baobab-react/hooks';

const ctrl = new ReservationController();

interface VisaInfoProps {
    reservation: Reservation;
    history: any;
}

interface CustomerInfoProps {
    editCustomer: any;
    visaDataModal: any;
    showDeleteModal: any;
    customer: CustomerModel;
    invalidVisaIds: number[];
    editable: boolean;
    index: Number;
    reservationHolderId: Number;
    reservation: Reservation;
    location?: any;
}

const VisaInfo = (props: VisaInfoProps) => {
    const { reservation, history } = props;
    const [isExpanded, setIsExpanded] = React.useState(false);
    const { taxes } = useBranch({
        taxes: ['model', 'Tax'],
    });
    if ((reservation.customerId === null || reservation.companyId === null) && reservation.id === null) {
        return null;
    }
    const { location } = history;

    const editCustomer = (customer: CustomerModel) => {
        setModalContent(
            <CustomerForm
                customerId={customer.id}
                callback={async (updatedCustomerData: CustomerModel) => {
                    if (reservation && reservation.id) {
                        const guestReference = reservation.VisaInfo.find((v) => v.customerId === customer.id);
                        if (guestReference && updatedCustomerData.id) {
                            const ref = { ...guestReference };
                            ref.customerId = updatedCustomerData.id;
                            await ctrl.updateReservationGuestReference(ref);
                            await ctrl.loadReservation(reservation.id);
                        }
                    }
                }}
                reservation={reservation}
            />,
            translate('Edit customer'),
            false,
            'modal-xl'
        );
    };

    const visaDataModal = (customer: CustomerModel) => {
        let person = `${customer.firstName ? customer.firstName : ''} ${customer.lastName ? customer.lastName : ''}`;
        person = person.toUpperCase();
        const visaData = reservation.VisaInfo.find((v) => v.customerId === customer.id); //this will hold the reference to object

        setModalContent(
            <VisaDataModal
                customer={customer}
                reservation={reservation}
                visaData={visaData}
                saveHandler={async (updatedGuestReference: ReservationGuestReferenceModel) => {
                    await ctrl.updateReservationGuestReference(updatedGuestReference);
                    closeModal();
                }}
                // cancelHandler={closeModal}
            />,
            translate('VISA DATA FOR  ') + person,
            false,
            'modal-lg'
        );
    };

    const showDeleteModal = (customer: CustomerModel) => {
        setModalContent(
            <Delete
                actionForYes={async () => {
                    const resGuestRef = reservation.VisaInfo.find((r) => r.customerId === customer.id);
                    if (reservation.customerId !== customer.id && resGuestRef) {
                        if (resGuestRef.id) {
                            await ctrl.removeGuestFromReservation(resGuestRef.id);
                        }
                    } else {
                        console.log('You cant remove reservation holder!!!!');
                    }
                }}
            />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };

    let list = null;

    if (reservation.Guests && reservation.Guests.length === 0) {
        list = (
            <div className="col-md-3">
                <div className="p-3 bg-white border rounded text-center">{translate('List is empty')}</div>
            </div>
        );
    } else {
        list =
            reservation.Guests &&
            reservation.Guests.map((customer, index) => {
                return (
                    <CustomerInfo
                        key={index}
                        index={index}
                        customer={customer}
                        editCustomer={editCustomer}
                        visaDataModal={visaDataModal}
                        showDeleteModal={showDeleteModal}
                        invalidVisaIds={ctrl.getCustomersWithInvalidVisaIds()}
                        editable={true}
                        reservationHolderId={reservation.customerId ? reservation.customerId : -1}
                        reservation={reservation}
                        location={location}
                    />
                );
            });
    }

    const headerClass = isExpanded
        ? 'pointer text-center border-bottom border-top uppercase text-primary  p-2'
        : 'pointer text-center border-top uppercase text-primary  p-2'; //no undeline

    if (!reservation.id || reservation.id === null) {
        return null;
    }

    const accoommodationFixedTaxes = taxes
        ? taxes
              .filter((t: TaxModel) => t.fixedAmount)
              .sort((a: TaxModel, b: TaxModel) => (Number(b.fixedAmount) > Number(a.fixedAmount) ? 1 : -1))
        : [];
    const fixedTaxId = accoommodationFixedTaxes.length > 0 ? accoommodationFixedTaxes[0].id : 0;

    return (
        <div className="mt-2">
            <h5
                onClick={() => {
                    setIsExpanded(!isExpanded);
                }}
                className={headerClass}
            >
                <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                &ensp;{translate('Guests informations')}&ensp;({reservation?.Guests?.length})&ensp;
                <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
            </h5>

            {isExpanded ? (
                <div className="">
                    <CustomersAndCompanies
                        history={history}
                        customer={null}
                        company={null}
                        showCompany={false}
                        customerSelect={async (c) => {
                            await ctrl.addGuestToReservation(c, fixedTaxId);
                        }}
                        companySelect={(c) => {}}
                        hideOnSelected={false}
                        reservation={reservation}
                        docScanner={true}
                    />
                </div>
            ) : null}

            {isExpanded ? <div className="row">{list}</div> : null}
        </div>
    );
};

const CustomerInfo = (CustomerInfoProps: CustomerInfoProps) => {
    const {
        customer,
        editable,
        index,
        editCustomer,
        visaDataModal,
        reservationHolderId,
        showDeleteModal,
        invalidVisaIds,
        reservation,
        location,
    } = CustomerInfoProps;
    let country = countryCodes.find((cInfo: any) => {
        return customer.country === cInfo['country-code'];
    });
    const disableAccommodationTaxCheckbox: boolean =
        location && location.pathname.lastIndexOf('reservationDetails') !== -1 ? true : false;
    const visaData: ReservationGuestReferenceModel | undefined = reservation.VisaInfo.find(
        (v) => v.customerId === customer.id
    ); //this will hold the reference to object
    const { taxes } = useBranch({
        taxes: ['model', 'Tax'],
    });
    const accoommodationFixedTaxes = taxes
        .filter((t: TaxModel) => t.fixedAmount)
        .sort((a: TaxModel, b: TaxModel) => (Number(b.fixedAmount) > Number(a.fixedAmount) ? 1 : -1));
    return (
        <div key={'customer' + index} className="col-sm-6 col-md-6 col-lg-4 mb-2">
            <div style={{ height: '200px' }} className="p-3 bg-white border rounded text-center">
                {editable ? (
                    <div className="btn-group rounded  mr-2  text-center" role="group">
                        <button
                            onClick={() => {
                                editCustomer(customer);
                            }}
                            disabled={reservation.customerId === customer.id ? true : false}
                            className="btn btn-sm btn-light border"
                        >
                            <i className="fa fa-edit" />
                        </button>
                        <button
                            onClick={() => {
                                visaDataModal(customer);
                            }}
                            className="btn btn-sm btn-light border"
                        >
                            {translate('VISA DATA')}
                        </button>
                        <button
                            disabled={reservationHolderId === customer.id}
                            onClick={() => {
                                showDeleteModal(customer);
                            }}
                            className="btn btn-sm btn-light border"
                        >
                            <i className="fa fa-close" />
                        </button>
                    </div>
                ) : null}
                <div>
                    <div className="text-primary mt-2">
                        <small>
                            <b>{`${customer.firstName ? customer.firstName : ''} ${
                                customer.lastName ? customer.lastName : ''
                            }`}</b>
                        </small>
                    </div>
                    <div>
                        <small>
                            <small>{` ${customer.email ? customer.email : ''}`}</small>
                        </small>
                    </div>
                    <div>
                        <small>
                            <small>{`${customer.phoneOrMobile ? customer.phoneOrMobile : ''}`}</small>
                        </small>
                    </div>
                    <div>
                        <small>
                            <small>
                                {customer.town ? customer.town : ''} {customer.address ? customer.address : ''}{' '}
                                {country ? country['alpha-3'] : null}
                            </small>
                        </small>
                    </div>
                </div>

                {customer && customer.id && invalidVisaIds.includes(customer.id) ? (
                    <div className="text-right p-2" style={{ position: 'absolute', top: 2 }}>
                        <i className="fa fa-exclamation-circle text-danger float-right" aria-hidden="true"></i>
                    </div>
                ) : null}
                
                    <div className="container w-75 p-2">
                        <small>
                            <select
                                onChange={async (e) => {
                                    if (visaData) {
                                        const editVisaData = { ...visaData };
                                        editVisaData.fixedTaxId = Number(e.target.value);
                                        await ctrl.updateReservationGuestReference(editVisaData);
                                    }
                                }}
                                name="fixedTaxId"
                                value={visaData?.fixedTaxId}
                                className="form-control form-control-sm"
                                disabled={disableAccommodationTaxCheckbox}
                            >
                                <React.Fragment>
                                    {accoommodationFixedTaxes.map((t: TaxModel, key: number) => {
                                        return (
                                            <option key={key} value={t.id}>
                                                {translate(t.name)}
                                            </option>
                                        );
                                    })}
                                    <option value={0}>{translate('No charge')}</option>
                                </React.Fragment>
                            </select>
                        </small>
                    </div>
               
            </div>
        </div>
    );
};

export default VisaInfo;
