import React from 'react';
import { handleChange, handleTimeChange, saveFormData } from '../settingsActions';
import TimePicker from 'rc-time-picker';
import Select from 'react-select';
import moment from 'moment';
import countryCodes from '@data/countryCodes.json';

const OtherSettings = (settings) => {
    const { otherSettings } = settings;

    const getTimestampFromTimeString = (timeString) => {
        let timeArr = timeString.split(':');
        let hour = timeArr[0];
        let minute = timeArr[1];
        let timestamp = moment().set('hour', hour).set('minute', minute).valueOf();
        return timestamp;
    };

    const timeOptions = [];
    const time = [
        '01:00',
        '02:00',
        '03:00',
        '04:00',
        '05:00',
        '06:00',
        '07:00',
        '08:00',
        '09:00',
        '10:00',
        '11:00',
        '12:00',
        '13:00',
        '14:00',
        '15:00',
        '16:00',
        '17:00',
        '18:00',
        '19:00',
        '20:00',
        '21:00',
        '22:00',
        '23:00',
    ];
    time.forEach((val, key) => {
        timeOptions.push(
            <option key={key} value={val}>
                {val}
            </option>
        );
    });

    const checkInTimestamp = otherSettings?.checkInTime ? getTimestampFromTimeString(otherSettings.checkInTime) : "";
    const checkOutTimestamp = otherSettings?.checkOutTime ? getTimestampFromTimeString(otherSettings.checkOutTime) : ""

    const options = [
        { label: 'Identity card', value: 'Identity card' },
        { label: 'Driving license', value: 'Driving license' },
        { label: 'Passport', value: 'Passport' },
        { label: 'Visa', value: 'Visa' },
        { label: 'Other documents', value: 'Other documents' },
    ];

    const valuesRequiringVisa = otherSettings?.validDocumentsForCountriesRequiringVisa
        ? otherSettings.validDocumentsForCountriesRequiringVisa.map((val) => {
              return {
                  value: val,
                  label: val,
              };
          })
        : '';

    const valuesDontNeedVisa = otherSettings?.validDocumentsForCountriesWhoDontNeedVisa
        ? otherSettings.validDocumentsForCountriesWhoDontNeedVisa.map((val) => {
              return {
                  value: val,
                  label: val,
              };
          })
        : '';

    let countryOptions = countryCodes.map((c) => {
        return (
            <option value={c['country-code']} key={c['country-code']}>
                {c.name}
            </option>
        );
    });

    countryOptions.unshift(<option key="none" value="">{' '}-{' '}</option>);

    return (
        <div className=" bg-white p-3">
            <b>{window.translate('Other settings')}</b>
            <hr />
            
            <div className="row  mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="country">{window.translate("gsettings_country")}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name={"country"}
                        className="form-control"
                        value={otherSettings?.country}
                        onChange={handleChange.bind(this, 'otherSettings', 'object')}
                    >
                        {countryOptions}
                    </select>
                </div>
            </div>

            <div className="row mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="checkInTime">{window.translate('Check in time')}:</label>
                </div>
                <div className="col-md-2">
                    <TimePicker
                        id="checkInTime"
                        allowEmpty={false}
                        showSecond={false}
                        minuteStep={15}
                        value={moment(checkInTimestamp)}
                        onChange={handleTimeChange.bind(this, 'otherSettings', 'checkInTime')}
                    />
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="checkOutTime">{window.translate('Check out time')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <TimePicker
                        id="checkOutTime"
                        allowEmpty={false}
                        showSecond={false}
                        minuteStep={15}
                        value={moment(checkOutTimestamp)}
                        onChange={handleTimeChange.bind(this, 'otherSettings', 'checkOutTime')}
                    />
                </div>
            </div>
            <hr />
            <div className="row  mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="enableHouseholdAutoGeneration">
                        {window.translate('Enable household warent generation')}:
                    </label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="enableHouseholdAutoGeneration"
                        value={otherSettings?.enableHouseholdAutoGeneration}
                        onChange={handleChange.bind(this, 'otherSettings', 'object')}
                        className="custom-select my-1 mr-sm-2 "
                        id="enableHouseholdAutoGeneration"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        <option value={true}>{window.translate('Enable')}</option>
                        <option value={false}>{window.translate('Disable')}</option>
                    </select>
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="householdAutogenerationTime">
                        {window.translate('Autogenerate household warrants')}:
                    </label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="householdAutogenerationTime"
                        value={otherSettings?.householdAutogenerationTime}
                        onChange={handleChange.bind(this, 'otherSettings', 'object')}
                        className="custom-select my-1 mr-sm-2 "
                        id="houseHoldAutogenerationTime"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        {timeOptions}
                    </select>
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="dailyCleaningStartHour">
                        {window.translate('Daily cleaning start hour')}:
                    </label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="dailyCleaningStartHour"
                        value={otherSettings?.dailyCleaningStartHour}
                        onChange={handleChange.bind(this, 'otherSettings', 'number')}
                        className="custom-select my-1 mr-sm-2 "
                        id="dailyCleaningStartHour"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map(
                            (hour) => {
                                return (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                );
                            }
                        )}
                    </select>
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="dailyCleaningEndHour">{window.translate('Daily cleaning end hour')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="dailyCleaningEndHour"
                        value={otherSettings?.dailyCleaningEndHour}
                        onChange={handleChange.bind(this, 'otherSettings', 'number')}
                        className="custom-select my-1 mr-sm-2 "
                        id="dailyCleaningEndHour"
                    >
                        <option value={0}>{window.translate('Nothing selected')}</option>
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24].map(
                            (hour) => {
                                return (
                                    <option key={hour} value={hour}>
                                        {hour}
                                    </option>
                                );
                            }
                        )}
                    </select>
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="householdScheduleDays">{window.translate('Number of schedule days')}:</label>{' '}
                </div>
                <div className="col-md-2">
                <input
                        autoComplete="off"
                        name="householdScheduleDays"
                        value={
                            otherSettings && otherSettings.householdScheduleDays
                                ? otherSettings.householdScheduleDays
                                : ''
                        }
                        onChange={handleChange.bind(this, 'otherSettings', 'number')}
                        type="number"
                        min={1}
                        max={5}
                        className="form-control"
                    />
                </div>
            </div>
            <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="householdInspectionRequired">{window.translate('Require household inspection after cleaning is complete')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="householdInspectionRequired"
                        value={otherSettings?.householdInspectionRequired}
                        onChange={handleChange.bind(this, 'otherSettings', 'boolean')}
                        className="custom-select my-1 mr-sm-2 "
                        id="householdInspectionRequired"
                    >
                        <option value={true}>{window.translate('Enable')}</option>
                        <option value={false}>{window.translate('Disable')}</option>
                    </select>
                </div>
            </div>
            <hr />
            <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="invalidateConfirmedReservationsHoursAfterPlannedCheckIn">
                        {window.translate(
                            'Auto invalidate CONFIRMED reservation after planned CHECK IN (in hours, 0 = disabled)'
                        )}
                        :
                    </label>{' '}
                </div>
                <div className="col-md-2">
                    <input
                        autoComplete="off"
                        name="invalidateConfirmedReservationsHoursAfterPlannedCheckIn"
                        value={
                            otherSettings && otherSettings.invalidateConfirmedReservationsHoursAfterPlannedCheckIn
                                ? otherSettings.invalidateConfirmedReservationsHoursAfterPlannedCheckIn
                                : ''
                        }
                        onChange={handleChange.bind(this, 'otherSettings', 'number')}
                        type="number"
                        min={0}
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="moveInvalidatedConfirmedReservationToStatusEnum">
                        {window.translate('Move invalidate reservation to state')}:
                    </label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="moveInvalidatedConfirmedReservationToStatusEnum"
                        value={otherSettings?.moveInvalidatedConfirmedReservationToStatusEnum}
                        onChange={handleChange.bind(this, 'otherSettings', 'object')}
                        className="custom-select my-1 mr-sm-2 "
                        id="moveInvalidatedConfirmedReservationToStatusEnum"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        <option value={3}>{window.translate('CANCEL')}</option>
                        <option value={5}>{window.translate('AUTO BLOCK')}</option>
                    </select>
                </div>
            </div>
            <hr />
            <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="invalidateEarlyBirdsHoursBeforeCheckIn">
                        {window.translate('Auto BLOCK EARLY BIRD reservations before planned CHECK IN (in hours)')}:
                    </label>{' '}
                </div>
                <div className="col-md-2">
                    <input
                        autoComplete="off"
                        name="invalidateEarlyBirdsHoursBeforeCheckIn"
                        value={
                            otherSettings && otherSettings.invalidateEarlyBirdsHoursBeforeCheckIn
                                ? otherSettings.invalidateEarlyBirdsHoursBeforeCheckIn
                                : ''
                        }
                        onChange={handleChange.bind(this, 'otherSettings', 'number')}
                        type="number"
                        min={0}
                        className="form-control"
                    />
                </div>
            </div>
            <hr />
            <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="documentHeader">{window.translate('Document header (HTML allowed)')}:</label>{' '}
                </div>
                <div className="col-md-8">
                    <textarea
                        autoComplete="off"
                        name="documentHeader"
                        value={otherSettings?.documentHeader}
                        onChange={handleChange.bind(this, 'otherSettings', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="documentFooter">{window.translate('Document footer (HTML allowed)')}:</label>{' '}
                </div>
                <div className="col-md-8">
                    <textarea
                        autoComplete="off"
                        name="documentFooter"
                        value={otherSettings?.documentFooter}
                        onChange={handleChange.bind(this, 'otherSettings', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="invoiceNotes">{window.translate('Invoice notes (HTML allowed)')}:</label>{' '}
                </div>
                <div className="col-md-8">
                    <textarea
                        autoComplete="off"
                        name="invoiceNotes"
                        value={otherSettings?.invoiceNotes}
                        onChange={handleChange.bind(this, 'otherSettings', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <hr />
            <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="taxIncludedInPrice">{window.translate('Is Tax included in price')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="taxIncludedInPrice"
                        value={otherSettings?.taxIncludedInPrice}
                        onChange={handleChange.bind(this, 'otherSettings', 'boolean')}
                        className="custom-select my-1 mr-sm-2 "
                        id="taxIncludedInPrice"
                    >
                        <option value={true}>{window.translate('Tax is included in price')}</option>
                        <option value={false}>{window.translate('Tax not included in price')}</option>
                    </select>
                </div>
            </div>

            <div className="row mt-2 align-items-center">
                    <div className="col-md-4">
                        {' '}
                        <label htmlFor="vatPayer">{window.translate('VAT payer')}:</label>{' '}
                    </div>
                    <div className="col-md-2">
                        <select
                            name="vatPayer"
                            value={otherSettings?.vatPayer}
                            onChange={handleChange.bind(this, 'otherSettings', 'boolean')}
                            className="custom-select my-1 mr-sm-2 "
                            id="vatPayer"
                        >
                            <option value={true}>{window.translate('Yes')}</option>
                            <option value={false}>{window.translate('No')}</option>
                        </select>
                    </div>
                </div>
            
                <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="taxFreeNote">{window.translate('Invoice notes (Tax free)')}:</label>{' '}
                </div>
                <div className="col-md-8">
                    <textarea
                        autoComplete="off"
                        name="taxFreeNote"
                        value={otherSettings?.taxFreeNote}
                        onChange={handleChange.bind(this, 'otherSettings', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

                <div className="row mt-2 align-items-center">
                    <div className="col-md-4">
                        {' '}
                        <label htmlFor="includeAccommodationTax">{window.translate('Include accommodation tax on proforma and advance invoice')}:</label>{' '}
                    </div>
                    <div className="col-md-2">
                        <select
                            name="includeAccommodationTax"
                            value={otherSettings?.includeAccommodationTax}
                            onChange={handleChange.bind(this, 'otherSettings', 'boolean')}
                            className="custom-select my-1 mr-sm-2 "
                            id="includeAccommodationTax"
                        >
                            <option value={true}>{window.translate('Yes')}</option>
                            <option value={false}>{window.translate('No')}</option>
                        </select>
                    </div>
                </div>
            <hr /> 
            <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="condoUserPersonalDaysPerYear">{window.translate('Personal days for CONDO user per year')}:</label>{' '}
                </div>
                <div className="col-md-2">
                <input
                        autoComplete="off"
                        name="condoUserPersonalDaysPerYear"
                        value={otherSettings?.condoUserPersonalDaysPerYear}
                        onChange={handleChange.bind(this, 'otherSettings', "number")}
                        type="number"
                        min={0}
                        className="form-control"
                    />
                </div>
            </div>
            <hr />
            <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="relayServer">{window.translate('Relaying server (Remote control)')}:</label>{' '}
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="relayServer"
                        value={otherSettings && otherSettings.relayServer ? otherSettings.relayServer : ''}
                        onChange={handleChange.bind(this, 'otherSettings', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row ">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="QRCodeValidTimeAfterFirstConsumption">
                        {window.translate('QR code validation time after first consumption')}:
                    </label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="QRCodeValidTimeAfterFirstConsumption"
                        value={otherSettings?.QRCodeValidTimeAfterFirstConsumption}
                        onChange={handleChange.bind(this, 'otherSettings', 'object')}
                        className="custom-select my-1 mr-sm-2 "
                        id="QRCodeValidTimeAfterFirstConsumption"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        {[5, 10, 15, 20, 25, 30, 40, 50, 60, 120].map((hour) => {
                            return (
                                <option key={hour} value={hour}>
                                    {hour}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>

            <div className="row ">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="enableDocumentScannerForQrSelfCheckIn">
                        {window.translate('Enable document scanner for QR Self Check In')}:
                    </label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="enableDocumentScannerForQrSelfCheckIn"
                        value={otherSettings?.enableDocumentScannerForQrSelfCheckIn}
                        onChange={handleChange.bind(this, 'otherSettings', 'boolean')}
                        className="custom-select my-1 mr-sm-2 "
                        id="enableDocumentScannerForQrSelfCheckIn"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        <option value={true}>{window.translate('Yes')}</option>
                        <option value={false}>{window.translate('No')}</option>
                    </select>
                </div>
            </div>
            <div className="row mt-2">
                    <div className="col-md-4">
                        {' '}
                        <label htmlFor="sendReservationConfirmationToGuest">
                            {window.translate('Send reservation confirmation to guest')}:
                        </label>{' '}
                    </div>
                    <div className="col-md-2">
                        <select
                            name="sendReservationConfirmationToGuest"
                            value={otherSettings?.sendReservationConfirmationToGuest}
                            onChange={handleChange.bind(this, 'otherSettings', 'boolean')}
                            className="custom-select my-1 mr-sm-2 "
                            id="sendReservationConfirmationToGuest"
                        >
                            <option value={false}>{window.translate('False')}</option>
                            <option value={true}>{window.translate('True')}</option>
                        </select>
                    </div>
                </div>

                <div className="row mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="roomFailureReceiverEmails">
                        {window.translate('Room failure receiver email')}:
                    </label>{' '}
                </div>
                <div className="col-md-6">
                        <input
                            autoComplete="off"
                            name="roomFailureReceiverEmail"
                            value={
                                otherSettings && otherSettings.roomFailureReceiverEmail
                                    ? otherSettings.roomFailureReceiverEmail
                                    : ''
                            }
                            onChange={handleChange.bind(this, 'otherSettings', 'object')}
                            type="text"
                            // min={0}
                            id="roomFailureReceiverEmail"
                            className="form-control"
                        />
                    
                </div>
            </div>
                
            <hr />
            <div className="row ">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="validDocumentsForCountriesWhoDontNeedVisa">
                        {window.translate("Valid documents for countries who don't need visa")}:
                    </label>{' '}
                </div>
                <div className="col-md-6">
                    <Select
                        isMulti
                        className="mt-2"
                        placeholder={window.translate('Select options...')}
                        options={options}
                        name="validDocumentsForCountriesWhoDontNeedVisa"
                        onChange={handleChange.bind(this, 'otherSettings', 'array')}
                        value={valuesDontNeedVisa}
                    ></Select>
                </div>
            </div>

            <div className="row ">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="validDocumentsForCountriesRequiringVisa">
                        {window.translate('Valid documents for countries requiring visa:')}
                    </label>{' '}
                </div>
                <div className="col-md-6">
                    <Select
                        isMulti
                        className="mt-2"
                        placeholder={window.translate('Select options...')}
                        options={options}
                        name="validDocumentsForCountriesRequiringVisa"
                        onChange={handleChange.bind(this, 'otherSettings', 'array')}
                        value={valuesRequiringVisa}
                    ></Select>
                </div>
            </div>
            <hr />
            <div className="row   mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="numberOfDaysForEncriptingGuests">{window.translate('Number of days for encripting guests')}:</label>{' '}
                </div>
                <div className="col-md-6">
                <input
                        autoComplete="off"
                        name="numberOfDaysForEncriptingGuests"
                        value={
                            otherSettings && otherSettings.numberOfDaysForEncriptingGuests
                                ? otherSettings.numberOfDaysForEncriptingGuests
                                : ''
                        }
                        onChange={handleChange.bind(this, 'otherSettings', 'number')}
                        type="number"
                        min={0}
                        className="form-control"
                    />
                </div>
            </div>

            <div className="text-center p-5">
                {' '}
                <div
                    onClick={() => saveFormData('otherSettings', otherSettings)}
                    className="btn btn-outline-primary mb-4 pointer"
                >
                    {window.translate('Save')}
                </div>{' '}
            </div>
        </div>
    );
};

export default OtherSettings;
