import React, { Component } from 'react';
import _ from 'lodash';
import rest from '../../data/restWrapper';
import NotificationTemplateForm from './forms/notificationTemplateForm';
import { toast } from 'react-toastify';
import PageTitle from '../../components/pageTitle';

class NotificationTemplateSettings extends Component {
    constructor() {
        super();
        this.state = {
            keyWords: [],
            emailTemplate: {
                isEnabled: false,
                apiPath: '',
                apiObject: `{"api_key":"","domain":"","host":""}`,
                sender: 'HOTEL NAME',
            },
            smsTemplate: {
                isEnabled: false,
                apiPath: '',
                apiObject: `{"api_key":"", "api_secret":""}`,
                sender: 'HOTEL NAME',
            },
            slackSettings: {
                isEnabled: false,
                apiPath: '',
                apiObject: '',
                sender: '',
            },
            validationErrorMessage: '',
        };
    }

    async componentDidMount() {
        let emailTemplateDb = await rest('/getGeneralSettingByKey/emailTemplate');
        let smsTemplateDb = await rest('/getGeneralSettingByKey/smsTemplate');
        let slackTemplateDb = await rest('/getGeneralSettingByKey/slackSettings');

        let emailTemplate =
            !_.isUndefined(emailTemplateDb) && emailTemplateDb !== null
                ? JSON.parse(emailTemplateDb.params)
                : this.state.emailTemplate;
        let smsTemplate =
            !_.isUndefined(smsTemplateDb) && smsTemplateDb !== null
                ? JSON.parse(smsTemplateDb.params)
                : this.state.smsTemplate;
        let slackSettings =
                !_.isUndefined(slackTemplateDb) && slackTemplateDb !== null
                    ? JSON.parse(slackTemplateDb.params)
                    : this.state.slackSettings;

        let stateEmailTemplate = _.merge(this.state.emailTemplate, emailTemplate);
        let stateSmsTemplate = _.merge(this.state.smsTemplate, smsTemplate);
        let stateSlackTemplate = _.merge(this.state.slackSettings, slackSettings);

        this.setState({
            emailTemplate: stateEmailTemplate,
            smsTemplate: stateSmsTemplate,
            slackSettings: stateSlackTemplate,
        });
    }
    async saveFormData(type) {
        let obj = _.clone(this.state[type]);

        obj['key'] = type;
        obj['params'] = JSON.stringify(this.state[type]);

        if (this.state[type]['apiObject'] === '' || this.isValidJSONString(this.state[type]['apiObject'])) {
            try {
                await rest('/saveGeneralSetting', 'POST', obj);
                this.setState({
                    validationErrorMessage: '',
                });
                toast("UPDATED '" + type + "'", { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
            } catch (error) {
                console.warn('---', error);
            }
        } else {
            this.setState({
                validationErrorMessage: 'Invalid JSON',
            });
        }
    }

    onFieldChange(type, e) {
        let stateObject = _.clone(this.state[type]);

        if (e.target.name === 'isEnabled') {
            stateObject[e.target.name] = !this.state[type][e.target.name];

            this.setState({
                [type]: stateObject,
            });
        } else {
            stateObject[e.target.name] = e.target.value;
            this.setState({
                [type]: stateObject,
            });
        }
    }

    isValidJSONString(str) {
        try {
            JSON.parse(str);
        } catch (e) {
            return false;
        }
        return true;
    }

    render() {
        return (
            <div>
                <PageTitle />
                <div>
                    <button
                        onClick={() => {
                            this.props.location.search.indexOf('admin') !== -1
                                ? this.props.history.push('/selfCheckInAdministrative')
                                : this.props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary mb-2"
                    >
                        {window.translate('GO BACK')}
                    </button>
                </div>
                {this.props.location.search.indexOf('admin') !== -1 ? null : (
                    <div className="text-center">
                        <div
                            className="btn btn-sm btn-info pointer"
                            onClick={() => {
                                this.props.history.push('/notificationTemplates');
                            }}
                        >
                            <i className="fa fa-list mx-1"></i>
                            {window.translate('Notification Templates')}
                        </div>
                    </div>
                )}

                <nav className="bg-light">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        <a
                            className="nav-item nav-link active"
                            id="nav-home-tab"
                            data-toggle="tab"
                            href="#nav-home"
                            role="tab"
                            aria-controls="nav-home"
                            aria-selected="true"
                        >
                            {window.translate('Email service settings')}
                        </a>
                        <a
                            className="nav-item nav-link"
                            id="nav-company-tab"
                            data-toggle="tab"
                            href="#nav-company"
                            role="tab"
                            aria-controls="nav-company"
                            aria-selected="false"
                        >
                            {window.translate('SMS service settings')}
                        </a>
                        <a
                            className="nav-item nav-link"
                            id="nav-slack-tab"
                            data-toggle="tab"
                            href="#nav-slack"
                            role="tab"
                            aria-controls="nav-slack"
                            aria-selected="false"
                        >
                            {window.translate('Slack service settings')}
                        </a>
                    </div>
                </nav>

                <div className="tab-content animated faster fadeIn" id="nav-tabContent">
                    <div
                        className="tab-pane fade show active"
                        id="nav-home"
                        role="tabpanel"
                        aria-labelledby="nav-home-tab"
                    >
                        <NotificationTemplateForm
                            notificationPageTitle={window.translate('EMAIL SERVICE SETTINGS')}
                            validationErrorMessage={this.state.validationErrorMessage}
                            saveFormData={this.saveFormData.bind(this, 'emailTemplate')}
                            template={this.state.emailTemplate}
                            onFieldChange={this.onFieldChange.bind(this, 'emailTemplate')}
                            keyWords={this.state.keyWords}
                        />
                    </div>
                    <div className="tab-pane fade" id="nav-company" role="tabpanel" aria-labelledby="nav-company-tab">
                        <NotificationTemplateForm
                            notificationPageTitle={window.translate('SMS SERVICE SETTINGS')}
                            validationErrorMessage={this.state.validationErrorMessage}
                            saveFormData={this.saveFormData.bind(this, 'smsTemplate')}
                            template={this.state.smsTemplate}
                            onFieldChange={this.onFieldChange.bind(this, 'smsTemplate')}
                            keyWords={this.state.keyWords}
                        />
                    </div>
                    <div className="tab-pane fade" id="nav-slack" role="tabpanel" aria-labelledby="nav-slack-tab">
                        <NotificationTemplateForm
                            notificationPageTitle={window.translate('SLACK SERVICE SETTINGS')}
                            validationErrorMessage={this.state.validationErrorMessage}
                            saveFormData={this.saveFormData.bind(this, 'slackSettings')}
                            template={this.state.slackSettings}
                            onFieldChange={this.onFieldChange.bind(this, 'slackSettings')}
                            keyWords={this.state.keyWords}
                        />
                    </div>
                </div>
            </div>
        );
    }
}

export default NotificationTemplateSettings;
