import rest from '../../data/restWrapper';

async function broadcastCardCodeWithValidToTime(code, validToTimestamp, cardCodeType) {
	const payload = {
		code,
		validToTimestamp,
		cardCodeType,
	};
	await rest('/src/broadcastCardCodeWithValidToTime', 'POST', payload);
}

async function broadcastGeneralSettings(generalSettingsParamEnum, value) {
	return await rest('/src/broadcastGeneralSettings', 'POST', { generalSettingsParamEnum, value });
}

export { broadcastCardCodeWithValidToTime, broadcastGeneralSettings };