import React from 'react';

const ConfirmButtons = ({ onConfirm, onCancel, confirmText = 'Confirm', cancelText = 'Cancel'}) => {
    const [actionPending, setActionPending] = React.useState(false);
    return (
        <React.Fragment>
            <button
                disabled={actionPending}
                onClick={async (e) => {
                    setActionPending(true);
                    await onConfirm(e);
                    setActionPending(false);
                }}
                className="btn btn-sm btn-outline-primary mr-2"
            >
                {actionPending ? (
                    <React.Fragment>
                        <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>{' '}
                        {window.translate('Processing ...')}
                    </React.Fragment>
                ) : (
                    window.translate(confirmText) 
                )}
            </button>
            <button
                disabled={actionPending}
                onClick={async (e) => {
                    setActionPending(true);
                    await onCancel(e);
                    setActionPending(false);
                }}
                className="btn btn-sm btn-outline-secondary"
            >
                {window.translate(cancelText) }
            </button>
        </React.Fragment>
    );
};

export default ConfirmButtons;
