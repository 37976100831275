import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import BasicSRCSettings from './basicSRCSettingsModal';
import { setModalContent } from '@components/modal';
import _ from 'lodash';
import baobabTree from '../../../data/state';

import getRoomSRCStatusIcon from '../../../data/controllers/SRCStatus';
import PermissionCheck from '../../../components/PermissionCheck';
import rest from '@data/restWrapper';

class RoomToolbar extends Component {
    handleActionLink(target, e) {
        this.props.history.push(target);
    }
    handleShowBasicOptions() {
        let roomName = this.props.roomInfo.name;
        let basicSRCSettings = (
            <BasicSRCSettings
                roomInfoId={this.props.roomInfo.id}
                key={_.uniqueId('asd')}
                reservation={this.props.reservation}
                remoteDeviceId={this.props.roomInfo.remoteDeviceId}
            />
        );
        setModalContent(
            basicSRCSettings,
            <span>
                {window.translate('Room settings') + ' - '} <b>{roomName}</b>
            </span>,
            true,
            'modal-xl'
        );
    }
    async toggleSendNotificationOnFailure(id, currentValue, roomInfo) {
        if (id) {
            try {
                let targetedSrc = await rest("/SmartRoomController", "POST", { id: id, sendNotificationAboutFailure: currentValue ? 0 : 1 })
                baobabTree.root.select(['model', 'SmartRoomController', { remoteDeviceId: roomInfo.remoteDeviceId }]).set(targetedSrc); 
            } catch (error) {
                console.log("Cannot toggle notification.", error)
            }
        }
    }
    getSmartRoomController(roomInfo) {
        let { smartRoomControllers } = this.props
        let srcInfo = _.find(smartRoomControllers, (src) => {
            return src && src.remoteDeviceId === roomInfo.remoteDeviceId;
        });
        return srcInfo
    }
    render() {
        let { roomInfo } = this.props;

        const srcInfo = this.getSmartRoomController(roomInfo)

        let showHWRelatedElements = roomInfo.remoteDeviceId ? true : false;

        let disableButtonIfNoSRCClass =
            roomInfo.remoteDeviceId === null ? 'btn btn-primary btn-sm disabled' : 'btn btn-primary btn-sm';

        let roomSRCStatusIcon = getRoomSRCStatusIcon(roomInfo);

        let basicSettings =
            this.props.roomInfo.remoteDeviceId === null ? (
                <button title={window.translate('Room settings')} className={disableButtonIfNoSRCClass + ' mr-2'}>
                    {roomSRCStatusIcon} {window.translate('Settings')}
                </button>
            ) : (
                <button
                    title={window.translate('Room settings')}
                    onClick={this.handleShowBasicOptions.bind(this)}
                    className={disableButtonIfNoSRCClass + ' mr-2'}
                >
                    {roomSRCStatusIcon} {window.translate('Settings')}
                </button>
            );

        // if(this.props.reservation)

        return (
            <div className="mb-2">
                <button
                    onClick={() => {
                        this.props.history.push('/');
                    }}
                    className="btn btn-sm btn-secondary mr-2 "
                >
                    {window.translate('GO BACK')}
                </button>

                <button
                    title={window.translate('Reservations')}
                    onClick={this.handleActionLink.bind(this, '/reservations/' + roomInfo.id + '/new')}
                    type="button"
                    className="btn btn-primary mr-2 btn-sm"
                >
                    <i className="fa fa-calendar" aria-hidden="true"></i>{' '}
                </button>

                {showHWRelatedElements ? (
                    <button
                        title={window.translate('Detailed room events')}
                        onClick={this.handleActionLink.bind(this, '/roomEventHistory/' + roomInfo.id)}
                        type="button"
                        className="btn btn-primary mr-2 btn-sm"
                    >
                        <i className="fa fa-bar-chart" aria-hidden="true"></i>{' '}
                    </button>
                ) : null}



                {showHWRelatedElements ? (
                    <PermissionCheck name="roomInfo_roomSettings">{basicSettings}</PermissionCheck>
                ) : null}

                <PermissionCheck name="roomInfo_roomSettings">
                    <div onClick={this.toggleSendNotificationOnFailure.bind(this, srcInfo?.id, srcInfo?.sendNotificationAboutFailure, roomInfo)} className='btn btn-sm btn-light border '>
                        {window.translate("Notification on failure")}
                        <i className={srcInfo?.sendNotificationAboutFailure ? "p-1 fa fa-check-square-o" : "p-1 fa fa-square-o"}  ></i>
                    </div>
                </PermissionCheck>
              
            </div>
        );
    }
}

export default branch(
    {
        // buttonPermissions: ['authTokenInfo', 'permissions', 'buttons', 'roomInfo'],
        smartRoomControllers: ['model', 'SmartRoomController'],
    },
    RoomToolbar
);