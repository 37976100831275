import React from 'react';
import rest from '../../data/restWrapper';
import { saveModelData } from '../../data/actions/modelActions';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import { setModalContent } from '@components/modal';
import Delete from '../../components/confirmModal';
import SRCProxyDevices from './srcProxyDevices';
import PageTitle from '../../components/pageTitle';
import baobabTree from '@data/state';


async function deleteSRC(smartRoomControllerId){
    await rest(`/SmartRoomController/${smartRoomControllerId}`, 'DELETE', {});
    baobabTree.root.select(['model', 'SmartRoomController',{id: smartRoomControllerId}]).unset();
}

function SRCRoomBinder(props){

    const {allRoomInfos, srcs, licenceInfo} = props;
    const [state, setState] = React.useState({
            page: 0,
            rows: 20,
            srcProxies: [],
            selectedSRCProxy: {},
            remoteSRCForSelectedProxy: [],
            selectedRooms: {},
            selectedAll: false,
        });



    const reloadData = async () =>{
        const srcProxies = await rest('/getSrcProxies', 'GET');
        const selectedSRCProxy = _.find(srcProxies, (p) => p.id === parseInt(props.match.params.srcProxyId, 10));
        const tempState = {...state, srcProxies, selectedSRCProxy};
        setState(tempState);
    }

     React.useEffect(()=>{
        reloadData();
    // eslint-disable-next-line
    },[])

    const bindSRCToRoomInfo = async (remoteDeviceId, roomInfo)=> {
        let _roomInfo = _.clone(roomInfo);
        _roomInfo['remoteDeviceId'] = remoteDeviceId; //this.state.selectedRoomAndRemoteDeviceToBindId.remoteDeviceId
        await saveModelData('RoomInfo', _roomInfo)
        await reloadData();
    }

    const unbindSRCFromRoomInfo = async (roomInfo)=> {
        let roomInfoShell = {
            id: roomInfo.id,
            remoteDeviceId: null,
            // srcProxyId: null
        };
        await saveModelData('RoomInfo', roomInfoShell);
        await reloadData();
    }

    const removeSRCFromRoomInfo = async ()=> {
        let roomInfoShell = {
            id: null,
            remoteDeviceId: null,
            srcProxyId: null,
        };
        await saveModelData('RoomInfo', roomInfoShell)
        await reloadData();
    }

    const attachRoomInfo2SRCProxy = async (roomInfo, SRCProxy)=> {
        let roomInfoShell = {
            id: roomInfo.id,
            srcProxyId: Number(SRCProxy.id),
        };
        await saveModelData('RoomInfo', roomInfoShell);
        await reloadData();
    }

    const unselectSRCProxy = ()=>  {
        setState({ ...state, selectedSRCProxy: {} });
    }

    const selectAndAttachRooms = async (SRCProxy)=> {
        const selectedRoomsForAttachHelp = _.keys(_.pickBy(state.selectedRooms));
        const selectedRoomsForAttachIds = selectedRoomsForAttachHelp.map(Number);
        const roomsToAttach = [];

        allRoomInfos.forEach(roomInfo=>{
            if (state.selectedAll) {
                if (roomInfo.srcProxyId === null || roomInfo.srcProxyId === undefined) {
                    roomsToAttach.push(roomInfo);
                }
            } else {
                if (selectedRoomsForAttachIds.includes(roomInfo.id)){
                    roomsToAttach.push(roomInfo);
                }
            }
        })

        for(let i=0; i<roomsToAttach.length; i++){
            const room = roomsToAttach[0];
            const roomInfoShell = {
                id: room.id,
                srcProxyId: Number(SRCProxy.id),
            };
            await saveModelData('RoomInfo', roomInfoShell);
        }
         await reloadData();
    }

    const selectRooms = (roomId) => {
        let selectedRooms = state.selectedRooms;
        if (_.isUndefined(selectedRooms[roomId])) {
            selectedRooms[roomId] = true;
        } else {
            selectedRooms[roomId] = !selectedRooms[roomId];
        }

        setState({
            ...state,
            selectedRooms: selectedRooms,
            selectedAll: false,
        });
    };
    const selectAllRooms = () => {
        let selectedAll = !state.selectedAll;
        setState({
            ...state,
            selectedAll: selectedAll,
            selectedRooms: [],
        });
    };

     const detachRoomInfo = async (roomInfo) =>{
        let roomInfoShell = {
            id: roomInfo.id,
            srcProxyId: null,
        };
        await saveModelData('RoomInfo', roomInfoShell)
        await reloadData();
    }

    const deleteSmartRoomController = async (smartRoomControllerId) => {
        //await deleteModelData('SmartRoomController', smartRoomControllerId);
        await deleteSRC(smartRoomControllerId);
    }

    const showDeleteModal = async (smartRoomControllerId) =>{
        const message = window.translate("Are you sure");
        setModalContent(
            <Delete
                content={message}
                actionForYes={()=>{deleteSmartRoomController(smartRoomControllerId)}}
            />,
            window.translate("Confirm"),
            false,
            'modal-md'
        );
    }

    return (
            <div className="container-fluid">
                <PageTitle />
                <div className="container">
                    <SRCProxyDevices
                        srcProxies={state.srcProxies}
                        history={props.history}
                        licenceInfo={licenceInfo}
                        cancelCallback={unselectSRCProxy}
                        attachRoomInfo2SRCProxy={attachRoomInfo2SRCProxy}
                        allRoomInfos={props.allRoomInfos}
                        unbindSRCFromRoomInfo={unbindSRCFromRoomInfo}
                        bindSRCToRoomInfo={bindSRCToRoomInfo}
                        remoteSRC={srcs} ///this.state.remoteSRCForSelectedProxy}
                        srcProxy={state.selectedSRCProxy}
                        selectAndAttachRooms={selectAndAttachRooms}
                        selectAllRooms={selectAllRooms}
                        selectRooms={selectRooms}
                        selectedAll={state.selectedAll}
                        selectedRooms={state.selectedRooms}
                        detachRoomInfo={detachRoomInfo}
                        removeSRCFromRoomInfo={removeSRCFromRoomInfo}
                        showDeleteModal={showDeleteModal}
                        // deleteSmartRoomController={this.deleteSmartRoomController.bind(this)}
                    />
                </div>
            </div>
        );
}

export default branch(
    {
        allRoomInfos: ['model', 'RoomInfo'],
        srcs: ['model', 'SmartRoomController'],
        licenceInfo: ["licence"]
    },
    SRCRoomBinder
);
