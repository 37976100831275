import * as React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import Select from 'react-select';
import { Reservation, ReservationController } from '../resController';
import { translate } from '@data/translations';
import OpenPrice from './OpenPrice';

const parseTags = (tagsString: string) => {
    return tagsString
        ? tagsString
              .trim()
              .split(';')
              .filter((t) => {
                  return t !== '';
              })
        : [];
};

interface AssignPriceListsProps {
    reservation: Reservation;
    acp: any[];
    acpTags: any[];
}

function AssignPriceLists(props: AssignPriceListsProps) {
    const { reservation, acp, acpTags } = props;

    const ctrl = new ReservationController();

    const room = reservation?.RoomInfo;
    const roomTags = room?.tags ? parseTags(room.tags) : [];
    //prođi preko ssvih tagova na cjenicima i izdvoji one koji se preklapaju
    const commonTags: string[] = [];
    acpTags.forEach((plTag: string) => {
        if (roomTags.includes(plTag)) {
            commonTags.push(plTag);
        }
    });

    const _reservation = { ...reservation };
    if (!reservation.tags) {
        _reservation.tags = commonTags.join(';');
    } else {
        _reservation.tags = reservation.tags;
    }

    // const accPriceListSubset = [];
    const reservationTags = _reservation.tags ? parseTags(_reservation.tags) : [];

    let pricelist: any[] = [];
    let tagsOptions: any[] = [];

    acp.forEach((a) => {
        if (a.tags) {
            const tags = a.tags.split(';');
            let hitCount = 0;
            tags.forEach((t: string) => {
                if (reservationTags.includes(t)) {
                    hitCount++;
                }
            });
            if (hitCount === reservationTags.length) {
                pricelist.push(a);
                tags.forEach((t: string) => {
                    if (t !== 'condo' && !tagsOptions.includes(t) && !reservationTags.includes(t)) {
                        tagsOptions.push(t);
                    }
                });
            }
        }
    });

    if (reservationTags.length === 0) {
        pricelist = [];
    }

    const priceListOptions = pricelist.map((pl: any) => {
        return {
            value: pl.id,
            label: pl.name,
        };
    });

    const acpTagOptions = tagsOptions.map((tag: string) => {
        return {
            value: tag,
            label: tag,
        };
    });

    const reservationTagsOptions = reservationTags.map((tag: string) => {
        return {
            value: tag,
            label: tag,
        };
    });

    return (
        <React.Fragment>
            {reservation.canceledReservationInvoicingStatus === 0 ? (
                <div className="row">
                    <div className="col-md-6">
                        <Select
                            isMulti
                            className="mt-2"
                            placeholder={translate('Slice pricelist by tags')}
                            options={acpTagOptions}
                            onChange={(values: any) => {
                                const res = { ...reservation };
                                const input = values ? values : [];
                                //uvijek ostavi tagove sobe tako da se ne mogu izbrisati
                                const tagsAfterUpdate = commonTags;
                                input.forEach((t: any) => {
                                    if (!tagsAfterUpdate.includes(t.value)) {
                                        tagsAfterUpdate.push(t.value);
                                    }
                                });
                                res.tags = tagsAfterUpdate.map((t) => t).join(';');
                                ctrl.updateActiveReservation(res);
                            }}
                            value={reservationTagsOptions}
                        ></Select>
                    </div>
                    <div className="col-md-6">
                        <div className="d-flex">
                            <div className="col-md-8">
                                <Select
                                    className="mt-2"
                                    placeholder={translate('Select accommodation pricelist')}
                                    options={priceListOptions}
                                    onChange={(selected: any) => {
                                        const value = selected.value;
                                        const res = { ...reservation };
                                        res.accommodationPriceListId = value ? value : null;
                                        ctrl.updateActiveReservation(res);
                                    }}
                                    selectValue={reservation.accommodationPriceListId}
                                ></Select>
                            </div>
                            <div className="col-md-4 align-self-end">
                                <OpenPrice reservation={reservation} />
                            </div>
                        </div>
                    </div>
                </div>
            ) : null}
        </React.Fragment>
    );
}

export default branch(
    {
        acp: ['model', 'AccommodationPriceList'],
        acpTags: ['tag', 'AccommodationPriceList'],
    },
    AssignPriceLists
);
