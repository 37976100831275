import rest from '../../data/restWrapper';
import baobab from '../../data/state/index';
import _ from 'lodash';
import baobabTree from '../../data/state/index';
import { toast } from 'react-toastify';
import moment from 'moment';

const settingsTemplate = {
    locale: '',
    companyInfo: '',
    defaultCurrency: '',
    otherSettings: '',
    IOTatewayIntegration: '',
    smtp: '',
    fiscalGeneralSettings: '',
    fiscalPOS: '',
    fiscalCertificate: '',
    backupAndRestore: '',
    //  registerENU: '',
};

const loadSettings = async () => {
    baobab.root.select('state', 'generalSettings').set({});
    for (const key in settingsTemplate) {
        const data = await rest('/getGeneralSettingByKey/' + key);
        if (data !== null) {
            if (key === 'locale' || key === 'defaultCurrency') {
                baobab.root.select('state', 'generalSettings', key).set(data.params);
            } else {
                baobab.root.select('state', 'generalSettings', key).set(_.defaults(JSON.parse(data.params)));
            }
        } else {
            baobab.root.select('state', 'generalSettings', key).set(data);
        }
    }
};

const loadAlarmInterpreter = async (key) => {
    const data = await rest('/getGeneralSettingByKey/' + key);
    if (data !== null) {
        baobab.root.select('state', 'generalSettings', key).set(_.defaults(JSON.parse(data.params)));
        baobab.root.select('state', 'alarmInterpreter').set(JSON.parse(data.params));
    }
    return JSON.parse(data.params);
};

const handleAlarmInterpreterChange = (key, type, val, index, e, checkBox = false) => {
    const sCursor = baobab.root.select('state', 'generalSettings', key).get();
    let settingsCursor = {
        ...sCursor,
    };
    const inputInterpreter = settingsCursor.inputInterpreter.map((i) => {
        return { ...i };
    });
    const roomModeInterpreter = { ...settingsCursor.roomModeInterpreter };

    if (val === 'inputInterpreter') {
        const target = inputInterpreter.find((r, key) => key === index);
        if (type === 'object') {
            target[e.target.name] = e.target.value;
        } else if (type === 'boolean') {
            if (checkBox) {
                target['enabled'] = !target['enabled'];
            } else {
                target[e.target.name] = e.target.value === 'true' ? 'true' : 'false';
            }
        }
        baobab.root.select('state', 'generalSettings', key, val).set(inputInterpreter);
    } else if (val === 'roomModeInterpreter') {
        if (type === 'object') {
            roomModeInterpreter[e.target.name] = e.target.value;
        }
        baobab.root.select('state', 'generalSettings', key, val).set(roomModeInterpreter);
    }
};

const handleChange = (key, type, e, val = null) => {
    const sCursor = baobab.root.select('state', 'generalSettings', key).get();
    let settingsCursor = {
        ...sCursor,
    };

    if (type === 'object') {
        settingsCursor[e.target.name] = e.target.value;
    } else if (type === 'string' || type === 'password') {
        settingsCursor = e.target.value;
    } else if (type === 'boolean') {
        settingsCursor[e.target.name] = e.target.value === 'true';
    } else if (type === 'number') {
        settingsCursor[e.target.name] = Number(e.target.value);
    } else if (type === 'checkbox') {
        settingsCursor[e.target.name] = settingsCursor[e.target.name] === false ? true : false;
    } else if (type === 'array') {
        let arrayValues = settingsCursor[val.name] ? [...settingsCursor[val.name]] : [];
        if (val.option) {
            //add
            if (!arrayValues.includes(val.option.value)) {
                arrayValues.push(val.option.value);
            }
        } else if (val.removedValue) {
            //delete
            arrayValues = arrayValues.filter((v) => v !== val.removedValue.value);
        }
        settingsCursor[val.name] = arrayValues;
    }

    baobab.root.select('state', 'generalSettings', key).set(settingsCursor);
};

const handleTimeChange = (key, type, e) => {
    const sCursor = baobab.root.select('state', 'generalSettings', key).get();
    let settingsCursor = {
        ...sCursor,
    };
    let time = moment(e.valueOf()).format('HH:mm');
    let timeArr = time.split(':');
    let hour = Number(timeArr[0]);
    let minute = Number(timeArr[1]);
    let stringifyTime = `${hour}:${minute}`;
    settingsCursor[type] = stringifyTime;
    baobab.root.select('state', 'generalSettings', key).set(settingsCursor);
};

// const getArrayFromString = (obj) => {
//     const parsedObj = JSON.parse(obj);
//     const businessSpaceLabelArray =
//         typeof parsedObj.sfBusinessSpaceLabels === 'string'
//             ? parsedObj.sfBusinessSpaceLabels.split(',')
//             : parsedObj.sfBusinessSpaceLabels;
//     parsedObj.sfBusinessSpaceLabels = businessSpaceLabelArray;
//     const tollDeviceLabelArray =
//         typeof parsedObj.sfTollDeviceLabels === 'string'
//             ? parsedObj.sfTollDeviceLabels.split(',')
//             : parsedObj.sfTollDeviceLabels;
//     parsedObj.sfTollDeviceLabels = tollDeviceLabelArray;
//     const stringifyObj = JSON.stringify(parsedObj);
//     return stringifyObj;
// };

const saveFormData = async (key, value, firstTimeInstall = false) => {
    let obj = {};
    if (
        key === 'companyInfo' ||
        key === 'otherSettings' ||
        key === 'IOTatewayIntegration' ||
        key === 'smtp' ||
        key === 'fiscalPOS' ||
        key === 'fiscalCertificate' ||
        key === 'backupAndRestore' ||
        key === 'fiscalGeneralSettings' ||
        key === 'alarmInterpreter'
    ) {
        obj['key'] = key;
        obj['params'] = JSON.stringify(value);
    } else {
        obj['key'] = key;
        obj['params'] = value;
    }
    try {
        await rest('/saveGeneralSetting', 'POST', obj);
        if (obj.key === 'defaultCurrency') {
            baobabTree.root.select(['defaultCurrency']).set(obj.params);
        }

        toast("UPDATED '" + key + "'", { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });

        if (window.location.hash.indexOf('firstTimeSetup') === -1) {
            setTimeout(() => {
                window.location.reload();
                // window.location = '/#/generalSettings';
            }, 1000);
        }
    } catch (error) {
        console.warn('err', error);
    }
};

export {
    loadSettings,
    handleChange,
    handleTimeChange,
    handleAlarmInterpreterChange,
    saveFormData,
    loadAlarmInterpreter,
};
