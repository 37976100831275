import React, { Component } from 'react';
import RangePicker from '../../../components/reservationRangePicker';
//import rest from '../../../data/restWrapper';

class RoomReservationsCalendar extends Component {
    render() {
        let year = new Date().getFullYear();
        let month = new Date().getMonth();
        let prevYear = month - 1 >= 0 ? year : year - 1;
        let prevMonth = month - 1 >= 0 ? month - 1 : 11;
        let nextYear = month + 2 === 11 ? year + 1 : year;
        let nextMonth = month + 2 === 11 ? 0 : month + 2;
        return (
            <div>
                <RangePicker
                    showLegend={false}
                    selectionLabel={'-'}
                    disableNavigation={false}
                    preselectedDate={{
                        from: new Date(),
                        to: new Date(),
                    }}
                    minimumDate={new Date(prevYear, prevMonth)}
                    maximumDate={new Date(nextYear, nextMonth)}
                    numberOfCalendars={2}
                    disableSelection={true}
                    editingReservation={{ id: null }} //this means there are no editing reservations
                    reservations={this.props.roomReservations}
                />

                <div className="clearfix">
                    <button
                        onClick={() => {
                            this.props.history.push('/reservations/' + this.props.roomInfoId + '/new');
                        }}
                        className="btn btn-sm btn-primary mb-3 ml-2"
                    >
                        {window.translate("Make a reservation")}
                    </button>
                </div>
            </div>
        );
    }
}

export default RoomReservationsCalendar;
