import React, { useEffect, useState } from 'react';
import ReportTable from '../../components/ReportTable';
import PageTitle from '../../components/pageTitle';
import { getReportData, getExcelExport } from '../../data/reports';

function CashRegistarPaymentsReport(props) {
    const [yearlyCashRegistarPayments, setYearlyCashRegistarPayments] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(0);
    const [_page, setPage] = React.useState(0);

    useEffect(() => {
        const loadData = async () => {
            reloadData(year);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const reloadData = async (year, page = _page) => {
        const _yearlyCashRegistarPayments = await getReportData('cashRegistarPayments', { year, page });
        setYearlyCashRegistarPayments([..._yearlyCashRegistarPayments]);
        setPage(page)
    };

    const _setYear = async (e) => {
        setYear(Number(e.target.value));
        await reloadData(Number(e.target.value));
    };

    const headers = [
        {
            filterKey: 'paymentNumber',
            headerTitle: window.translate('Payment Num.'),
            label: 'paymentNumber',
            type: 'num',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '90px',
        },
        {
            filterKey: 'date',
            headerTitle: window.translate('Date'),
            label: 'date',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'amount',
            headerTitle: window.translate('Amount'),
            label: 'amount',
            type: 'price',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'currency',
            headerTitle: window.translate('Currency'),
            label: 'currency',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'description',
            headerTitle: window.translate('Description'),
            label: 'description',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '180px',
        },
        {
            filterKey: 'customerId',
            headerTitle: window.translate('Guest'),
            label: 'firstName,lastName',
            type: 'number',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '180px',
        },
        {
            filterKey: 'company',
            headerTitle: window.translate('Company'),
            label: 'company',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '180px',
        },
        {
            filterKey: 'name',
            headerTitle: window.translate('Purpose'),
            label: 'name',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '180px',
        },
        {
            filterKey: 'type',
            headerTitle: window.translate('Purpose type'),
            label: 'type',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '180px',
        },
        {
            filterKey: 'invoiceId',
            headerTitle: window.translate('Invoice'),
            label: 'invoiceId',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '80px',
        },
        {
            filterKey: 'createdAt',
            headerTitle: window.translate('Created At'),
            label: 'createdAt',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
    ];

    return (
        <div className="">
            <PageTitle />

            <ReportTable
                year={year}
                month={month}
                setYear={_setYear.bind(this)}
                onYearMonthChange={async (filter) => {
                    setYear(filter.year);
                    setMonth(filter.month);
                    await reloadData(filter.year);
                }}
                itemsArray={yearlyCashRegistarPayments}
                headers={headers}
                monthFilter={true}
                history={props.history}
                targetedColumnForMonthFilter={'createdAt'}
                targetedColumnForDateFilter={'createdAt'}
                showDateFilter={true}
                xlsExportHandler={(params) => {
                    getExcelExport('cashRegistarPayments', params);
                }}
                loadData={(page) => {
                    reloadData(year, page);
                }}
                page={_page}
                listOfItems={yearlyCashRegistarPayments}
            ></ReportTable>
        </div>
    );
}

export default CashRegistarPaymentsReport;
