import * as React from 'react';
import { translate } from '@data/translations';
import moment, { Moment } from 'moment';
import { setModalContent } from '@components/modal';
import { UpdateCleaningStatus } from '../updateCleaningStatus';

import {
    UserInfo,
    RoomInfoEditModel,
    // ReservationEditModel,
    CleaningStatusHeaderExtendedWithCleaningStatusModel,
    CleaningStatusExtendedWithIncludedModels,
    // CleaningStatusModel,
} from '@common/modelDefinition';
import { getMaids, updateCleaningPlanOnSelectedRoom, updateCleaningPlansOnSelectedRoom } from './api';

import {
    setHouseholdPlan,
    setHouseholdPlanForSelectedMaid,
    setCleaningStatus,
    getHouseholdPlanForSelectedMaid,
} from './actions';
import {
    HouseholdPlannerProps,
    // MaidCleaningSchedule,
    RoomsSelectionAndFilterProps,
    MaidSelectionProps,
    MainAreaProps,
    RoomScheduleTilePropsForMaid,
    RoomScheduleTileProps,
    SelectedMaidHouseholdPlanProps,
} from './interfaces';

import {
    CleaningStatusEnum,
    CleaningStatusColors,
    CleaningStatusIcons,
    CleaningStatusTexts,
    ReservationStatusText,
    ReservationStatusEnum,
    CleaningInspectionEnum,
    MaidExpirence,
} from '../interfaces';

// import { setModalContent } from '@components/modal';

// import ConfirmModal from '@components/confirmModal';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { updateCleaningStatus } from '../api';

const verticalHeight = '86vh';
const verticalHeightInner = '76vh';

function HouseholdPlanner(props: HouseholdPlannerProps) {
    const [maids, setMaids] = React.useState<UserInfo[]>([]);
    const [selectedMaids, setSelectedMaids] = React.useState<string[]>([]);
    const [selectedRooms, setSelectedRooms] = React.useState<number[]>([]);
    const [tagsFilter, setTagsFilter] = React.useState<string[]>([]);
    const [filteredRooms, setFilteredRooms] = React.useState<RoomInfoEditModel[]>([]);
    const [statusFilters, setStatusFilters] = React.useState<string[]>([]);
    const [priorityFilter, setPriorityFilter] = React.useState<boolean>(false);

    const { rooms, householdPlan, cleaningStatuses ,loggedUser, history} = props;
    React.useEffect(() => {
        const loadMaids = async () => {
            const _maids = await getMaids();
            setMaids(_maids);
        };
        loadMaids();
    }, []);

    React.useEffect(() => {
        if (rooms.length > 0) {
            let _filteredRooms = rooms;
            if (!priorityFilter) {
                if (tagsFilter.length > 0) {
                    _filteredRooms = _filteredRooms.filter((room: RoomInfoEditModel) => {
                        const { tags } = room;
                        const parsedTags = tags ? tags.split(';') : [];
                        const found = parsedTags.some((r) => tagsFilter.includes(r));
                        if (found) {
                            return room;
                        } else {
                            return null;
                        }
                    });
                }
                if (statusFilters.length > 0) {
                    let roomInfoIds: number[] = [];
                    for (const csHeader of householdPlan) {
                        const cleaningStatuses = csHeader.CleaningStatuses;
                        const filteredCleaningStatuses = cleaningStatuses.filter((cs) =>
                            statusFilters.includes(CleaningStatusEnum[cs.cleaningStatus])
                        );
                        for (const cleaningStatus of filteredCleaningStatuses) {
                            if (cleaningStatus.roomInfoId && !roomInfoIds.includes(cleaningStatus.roomInfoId)) {
                                roomInfoIds.push(cleaningStatus.roomInfoId);
                            }
                        }
                    }
                    _filteredRooms = _filteredRooms.filter((r) => roomInfoIds.includes(r.id));
                }
            } else {
                setTagsFilter([]);
                setStatusFilters([]);
                const notAssignedPriorityCleaningStatuses = cleaningStatuses.filter(
                    (cs) =>
                        cs.cleaningStatus === CleaningStatusEnum.stagedForCleaning &&
                        !cs.cleanedByUserUuid &&
                        !cs.cleanedByUserUuid2 &&
                        !cs.cleanedByUserUuid3
                );
                let roomInfoIds: number[] = [];
                for (const cleaningStatus of notAssignedPriorityCleaningStatuses) {
                    if (cleaningStatus.roomInfoId && !roomInfoIds.includes(cleaningStatus.roomInfoId)) {
                        roomInfoIds.push(cleaningStatus.roomInfoId);
                    }
                }
                _filteredRooms = _filteredRooms.filter((r) => roomInfoIds.includes(r.id));
            }
            setFilteredRooms(_filteredRooms);
        }
    }, [tagsFilter, rooms, statusFilters, householdPlan, priorityFilter, cleaningStatuses]);

    return (
        <div>
            <div className="row">
                <div className="col-md-12 p-2 ">
                    <button
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className="btn btn-sm btn-secondary mr-2 uppercase"
                    >
                        {translate('Go Back')}
                    </button>
                </div>
            </div>
            <div className="row">
                <div style={{ padding: '0px' }} className="col-md-2 border-right">
                    <RoomsSelectionAndFilter
                        {...props}
                        filteredRooms={filteredRooms}
                        selectedRooms={selectedRooms}
                        setSelectedRooms={setSelectedRooms}
                        tagsFilter={tagsFilter}
                        setTagsFilter={setTagsFilter}
                        statusFilters={statusFilters}
                        setStatusFilters={setStatusFilters}
                        priorityFilter={priorityFilter}
                        setPriorityFilter={setPriorityFilter}
                    />
                </div>

                <div style={{ padding: '0px' }} className="col-md-2 border-right">
                    <MaidSelection
                        maids={maids}
                        selectedMaids={selectedMaids}
                        setSelectedMaids={setSelectedMaids}
                        selectedRooms={selectedRooms}
                    />
                </div>
                <div style={{ padding: '0px' }} className={'col-md-8'}>
                    <MainArea
                        maids={maids}
                        filteredRooms={filteredRooms}
                        {...props}
                        selectedRooms={selectedRooms}
                        selectedMaids={selectedMaids}
                        loggedUser={loggedUser}
                        history={history}
                    />
                </div>
            </div>
        </div>
    );
}

function RoomsSelectionAndFilter(props: RoomsSelectionAndFilterProps) {
    const {
        filteredRooms,
        selectedRooms,
        setSelectedRooms,
        roomTags,
        tagsFilter,
        setTagsFilter,
        setStatusFilters,
        statusFilters,
        //  cleaningStatuses,
        priorityFilter,
        setPriorityFilter,
    } = props;

    const selectOrDeselectRoom = (room: RoomInfoEditModel) => {
        let ids = [...selectedRooms];
        if (selectedRooms.includes(room.id)) {
            ids = selectedRooms.filter((id) => id !== room.id);
        } else {
            ids.push(room.id);
        }
        setSelectedRooms(ids);
    };

    const handleStatusFilters = (cleaningStatusString: string) => {
        let _statusFilters = [...statusFilters];
        if (!_statusFilters.includes(cleaningStatusString)) {
            _statusFilters.push(cleaningStatusString);
        } else {
            _statusFilters = _statusFilters.filter((f) => f !== cleaningStatusString);
        }
        setStatusFilters(_statusFilters);
        setPriorityFilter(false);
    };

    const selectedCss = ' bg-light text-dark';
    const unselectedCss = ' bg-white ';
    const baseCss = ' ml-2 mt-2 p-1 text-left border pointer elipsis';

    const roomsSelection = filteredRooms.map((room: RoomInfoEditModel) => {
        return (
            <div
                key={room.id}
                onClick={() => {
                    selectOrDeselectRoom(room);
                }}
                style={{ width: '45%', minWidth: '90px' }}
                className={
                    selectedRooms.includes(room.id)
                        ? [baseCss, selectedCss].join(' ')
                        : [baseCss, unselectedCss].join(' ')
                }
            >
                {room.name}
            </div>
        );
    });

    return (
        <div style={{ height: verticalHeight, overflowY: 'auto' }} className="bg-white p-3">
            <small>{translate('Select rooms')}</small>
            <div className="d-flex justify-content-between align-items-center">
                <div
                    title={selectedRooms.length === 0 ? 'Select all' : 'Deselect all'}
                    className="d-flex align-items-center"
                    onClick={() => {
                        selectedRooms.length === 0
                            ? setSelectedRooms(filteredRooms.map((r: RoomInfoEditModel) => r.id))
                            : setSelectedRooms([]);
                    }}
                >
                    <i className={selectedRooms.length === 0 ? 'fa fa-toggle-off fa-2x ' : 'fa fa-toggle-on fa-2x'} />
                </div>
                <div
                    style={priorityFilter ? { color: '#FF6201' } : { color: '#6c757d' }}
                    title="Rooms that require a maid assignment"
                    className="d-flex align-items-center"
                    onClick={() => {
                        setPriorityFilter(!priorityFilter);
                    }}
                >
                    <i className={'fa fa-thumbs-up fa-2x'} />
                </div>

                <button
                    className="btn btn-secondary btn-sm dropdown-toggle mt-1 mb-1"
                    type="button"
                    id="dropdownMenuButton"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                >
                    {translate('TAGS')}
                </button>
                <div className="dropdown-menu pt-0 pb-0" aria-labelledby="dropdownMenuButton">
                    <div className="btn-group-vertical w-100 bg-light">
                        <button
                            className="btn btn-sm btn-white  mb-1"
                            onClick={() => {
                                const _tagsFilter: string[] = [];
                                setTagsFilter(_tagsFilter);
                                setPriorityFilter(false);
                            }}
                        >
                            <i className="fa fa-filter" />
                        </button>
                        {roomTags.map((rt: string) => {
                            const buttonClassName: string = tagsFilter.includes(rt)
                                ? 'btn btn-sm btn-light mb-1'
                                : 'btn btn-sm btn-white  mb-1';
                            return (
                                <button
                                    key={rt}
                                    className={buttonClassName}
                                    onClick={() => {
                                        let _tagsFilter = [...tagsFilter];
                                        if (_tagsFilter.includes(rt)) {
                                            _tagsFilter = _tagsFilter.filter((t) => t !== rt);
                                        } else {
                                            _tagsFilter.push(rt);
                                        }
                                        setTagsFilter(_tagsFilter);
                                        setPriorityFilter(false);
                                    }}
                                >
                                    {rt}
                                </button>
                            );
                        })}
                    </div>
                </div>
            </div>
            <div className="btn-group d-flex flex-wrap mt-1" role="group" aria-label="Filter buttons">
                <button
                    style={{ flex: '1 0 25%' }}
                    key="clear"
                    onClick={() => {
                        setStatusFilters([]);
                        setPriorityFilter(false);
                    }}
                    type="button"
                    className="btn border"
                >
                    <i className="fa fa-filter" />
                </button>
                {(Object.keys(CleaningStatusEnum) as Array<string>).map((cleaningStatusString) => {
                    if (isNaN(Number(cleaningStatusString))) {
                        const tileStyle = statusFilters.includes(cleaningStatusString)
                            ? CleaningStatusColors[cleaningStatusString as keyof typeof CleaningStatusColors]
                            : { color: '#6c757d', backgroundColor: '#f2f7f8' };
                        const newStyle = { ...tileStyle, flex: '1 0 25%' };
                        // const info: number = cleaningStatuses.filter(
                        //     (cs) =>
                        //         cs.cleaningStatus ===
                        //         CleaningStatusEnum[cleaningStatusString as keyof typeof CleaningStatusEnum]
                        // ).length;
                        return (
                            <button
                                style={newStyle}
                                key={cleaningStatusString}
                                onClick={() => {
                                    handleStatusFilters(cleaningStatusString);
                                }}
                                type="button"
                                className="btn border"
                            >
                                <i
                                    className={
                                        CleaningStatusIcons[cleaningStatusString as keyof typeof CleaningStatusIcons]
                                    }
                                />
                                {/* <span className="badge" style={tileStyle}>
                                    {info}
                                </span> */}
                            </button>
                        );
                    } else {
                        return null;
                    }
                })}
                {/* {checkRoleAccess('managerHousehold', 'managerFull', 'admin') ? (
                    <FeatureFlag flag="householdManagement">
                        <button
                            className="btn btn-info"
                            onClick={() => {
                                openInNewTab(
                                    window.location.origin + `/#/household/manage/printPreview/${selectedHeaderId}`
                                );
                            }}
                        >
                            <i className="fa fa-print" />
                        </button>
                    </FeatureFlag>
                ) : null} */}
            </div>

            <hr />
            <div
                style={{
                    height: verticalHeight,
                    display: 'flex',
                    alignContent: 'start',
                    justifyContent: 'space-around',
                    flexWrap: 'wrap',
                }}
            >
                {roomsSelection}
            </div>
        </div>
    );
}

function MaidSelection(props: MaidSelectionProps) {
    const { maids, selectedMaids, setSelectedMaids /* selectedRooms */ } = props;

    const selectOrDeselectMaid = (user: UserInfo) => {
        let uuids = [...selectedMaids];
        if (selectedMaids.includes(user.uuid)) {
            uuids = selectedMaids.filter((uuid: string) => uuid !== user.uuid);
        } else {
            uuids.push(user.uuid);
        }
        setSelectedMaids(uuids);
    };

    const selectedCss = ' bg-light text-dark';
    const unselectedCss = ' bg-white ';
    const baseCss = 'p-1  mt-2  text-left border pointer elipsis';

    const maidsList = maids.map((m: UserInfo) => {
        let titleLevel = '';
        const maidRoomsAssignedCount = Object.keys(getHouseholdPlanForSelectedMaid(m.uuid)).length;

        if (m.level === MaidExpirence.Rookie) {
            titleLevel = 'Rookie maid';
        } else if (m.level === MaidExpirence.Intermediate) {
            titleLevel = 'Intermediate maid';
        } else if (m.level === MaidExpirence.Experienced) {
            titleLevel = 'Experienced maid';
        }
        return (
            <div
                style={{ position: 'relative' }}
                onClick={() => {
                    selectOrDeselectMaid(m);
                }}
                key={m.uuid}
                className={
                    selectedMaids.includes(m.uuid)
                        ? [baseCss, selectedCss].join(' ')
                        : [baseCss, unselectedCss].join(' ')
                }
            >
                <i className="fa fa-address-card-o mr-1" /> {m.name} {m.surname}
                <i
                    className="fa fa-info-circle"
                    style={{ position: 'absolute', top: '3px', right: '3px' }}
                    title={titleLevel + ' , arranged in ' + maidRoomsAssignedCount + ' rooms'}
                />
            </div>
        );
    });

    return (
        <div style={{ height: verticalHeight, overflowY: 'auto' }} className="bg-white p-3">
            <small>{translate('Select personel')}</small>
            <div className="d-flex justify-content-between align-items-center m-1">
                <div
                    title={selectedMaids.length === 0 ? 'Select all' : 'Deselect all'}
                    onClick={() => {
                        selectedMaids.length === 0
                            ? setSelectedMaids(maids.map((m: UserInfo) => m.uuid))
                            : setSelectedMaids([]);
                    }}
                    className="d-flex align-items-center"
                >
                    <i className={selectedMaids.length === 0 ? 'fa fa-toggle-off fa-2x' : 'fa fa-toggle-on fa-2x'} />
                </div>
            </div>
            {maidsList}
        </div>
    );
}

function MainArea(props: MainAreaProps) {
    const { filteredRooms, selectedRooms, selectedMaids, maids, householdPlan, selectedMaidHouseholdPlan, loggedUser, history } = props;
    const [selectedTabIndex, setSelectedTabIndex] = React.useState<number>(0);
    const scheduleDates: Moment[] = getScheduleDates(props);

    React.useEffect(() => {
        // setSelectedTabIndex(0); //reset on maid selection update
        setSelectedTabIndex(selectedMaids.length - 1);
    }, [selectedMaids]);

    React.useEffect(() => {
        const loadData = async () => {
            try {
                if (householdPlan.length === 1) {
                    await setHouseholdPlan();
                }
            } catch (err) {
                console.warn(err);
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });
    }, [householdPlan.length]);
    React.useEffect(() => {
        const loadData = () => {
            if (selectedMaids.length > 0 && householdPlan.length === scheduleDates.length) {
                const selectedMaid: string = selectedMaids[selectedTabIndex];
                if (selectedMaid && selectedRooms.length === 0) {
                    setHouseholdPlanForSelectedMaid(selectedMaid);
                }
            }
        };
        loadData();
    }, [selectedTabIndex, selectedRooms, selectedMaids, householdPlan.length, scheduleDates.length]);

    let body = null;
    let style: any = { height: verticalHeight, display: 'flex', alignItems: 'center', justifyContent: 'center' };
    const schedulingMaids: UserInfo[] = [];
    for (const maidUuid of selectedMaids) {
        const maid: UserInfo | undefined = maids.find((m) => m.uuid === maidUuid);
        if (maid) {
            schedulingMaids.push(maid);
        }
    }

    const selectedTabMaid = schedulingMaids[selectedTabIndex];
    const schedulingRooms: RoomInfoEditModel[] = filteredRooms.filter((room) => {
        return selectedRooms.includes(room.id);
    });

    if (selectedRooms.length === 0 && selectedMaids.length === 0) {
        body = (
            <div className="text-dark text-center">
                {translate(
                    'Please select one or more rooms to make a cleaning schedule, or select personel to view their assigned cleaning schedule.'
                )}
            </div>
        );
    } else if (selectedRooms.length > 0 && selectedMaids.length === 0) {
        body = <div>{translate('Now select a cleaning personel to make a schedule for selected rooms.')} </div>;
    } else if (selectedMaids.length > 0 && selectedRooms.length === 0) {
        style = { height: verticalHeight };
        body = (
            <SelectedMaidHouseholdPlan
                selectedMaidHouseholdPlan={selectedMaidHouseholdPlan}
                scheduleDates={scheduleDates}
                schedulingMaids={schedulingMaids}
                setSelectedTabIndex={setSelectedTabIndex}
                selectedTabIndex={selectedTabIndex}
                reservations={props.reservations}
            />
        );
    } else {
        style = { height: verticalHeight };
        body = (
            <div>
                <ul className="nav nav-tabs">
                    {schedulingMaids.map((m: UserInfo, index: number) => {
                        return (
                            <li className="nav-item" key={index}>
                                <a
                                    onClick={(e) => {
                                        e.preventDefault();
                                        setSelectedTabIndex(index);
                                    }}
                                    className={selectedTabIndex === index ? 'nav-link active bg-light' : 'nav-link'}
                                    href="/#"
                                >
                                    {m.name} {m.surname}
                                </a>
                            </li>
                        );
                    })}
                </ul>

                {selectedTabMaid && (
                    <h5 className="bg-light border-left border-right p-2">
                        Cleaning schedule for: {`${selectedTabMaid.name} ${selectedTabMaid.surname}`}
                    </h5>
                )}

                <div className=" mt-2" style={{ overflowY: 'auto', height: verticalHeightInner }}>
                    <div className="d-flex border-bottom justify-content-between">
                        <div className="elipsis col-md-2">{translate('Room name')}</div>
                        {scheduleDates.map((d: Moment, index: number) => {
                            return (
                                <div className="col-md-2 text-center" key={index}>
                                    {moment(d).format('ll')}
                                </div>
                            );
                        })}
                    </div>

                    {schedulingRooms.map((room: RoomInfoEditModel, index: number) => {
                        return (
                            <div className="d-flex justify-content-between" key={room.id}>
                                <div className="text-bold elipsis col-md-2 text-center border-bottom mt-5">
                                    <h3 className="mt-4">{room.name}</h3>
                                    <span
                                        onClick={async () => {
                                            const cleaningStatusHeaderIds: number[] = [];
                                            for (const cleanignStatusHeader of householdPlan) {
                                                cleaningStatusHeaderIds.push(cleanignStatusHeader.id);
                                            }
                                            let allowedMaids = [];
                                            if (selectedMaids.length <= 3) {
                                                allowedMaids = [...selectedMaids];
                                            } else {
                                                for (let i = 0; i < 2; i++) {
                                                    const maid = selectedMaids[i];
                                                    allowedMaids.push(maid);
                                                }
                                            }
                                            if (allowedMaids.length > 0) {
                                                await updateCleaningPlansOnSelectedRoom(
                                                    cleaningStatusHeaderIds,
                                                    allowedMaids,
                                                    room.id
                                                );
                                            }
                                        }}
                                    >
                                        {selectedMaids.length > 0 ? (
                                            <small className="text-secondary pointer">(allocate maids)</small>
                                        ) : null}
                                    </span>
                                </div>

                                {householdPlan.map(
                                    (hp: CleaningStatusHeaderExtendedWithCleaningStatusModel, key: number) => {
                                        const householdPlanOnDateForRoom:
                                            | CleaningStatusExtendedWithIncludedModels
                                            | undefined = hp.CleaningStatuses.find(
                                            (cs: CleaningStatusExtendedWithIncludedModels) => cs.roomInfoId === room.id
                                        );

                                        const selectedMaid: string = selectedMaids[selectedTabIndex];

                                        if (householdPlanOnDateForRoom) {
                                            return (
                                                <div className="border-bottom border-left col-md-2 p-2" key={key}>
                                                    <RoomScheduleTile
                                                        cleaningStatus={householdPlanOnDateForRoom}
                                                        cleaningStatusHeaderId={hp.id}
                                                        selectedMaid={selectedMaid}
                                                        roomInfoId={room.id}
                                                        loggedUser={loggedUser}
                                                        history={history}
                                                    />
                                                </div>
                                            );
                                        } else {
                                            return null;
                                        }
                                    }
                                )}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }

    return (
        <div style={style} className="bg-white p-3">
            {body}
        </div>
    );
}

function RoomScheduleTile(props: RoomScheduleTileProps) {
    const { cleaningStatus, roomInfoId, cleaningStatusHeaderId, selectedMaid, loggedUser , history} = props;

    const showCleaningOptions = (cleaningStatus: CleaningStatusExtendedWithIncludedModels) => {
        const roomInfoId = cleaningStatus.roomInfoId;
        if(loggedUser){
            setModalContent(
                <UpdateCleaningStatus
                    cleaningStatus={cleaningStatus}
                    onMinibarManagementClick={() => {
                        history.push('/roomsAdministration/minibar/' + roomInfoId); //modal will break the router in production. this is work around
                    }}
                    onMinibarClick={() => {
                        history.push('/household/minibar/' + roomInfoId); //modal will break the router in production. this is work around
                    }}
                    loggedUser={loggedUser}
                />,
                translate('Change cleaning status'),
                true
                // 'modal-md'
            );
        }   
    };

    const cleaningInspectionStatus =
        cleaningStatus.inspectionStatus !== null
            ? cleaningStatus.inspectionStatus
            : CleaningInspectionEnum.noInspectionTaken;
    const cleaningStatusEnum = cleaningStatus.cleaningStatus;
    const cleaningStatusName: string = CleaningStatusEnum[cleaningStatusEnum];
    const iconName: string = CleaningStatusIcons[cleaningStatusName as keyof typeof CleaningStatusIcons];
    const cleaningStatusText: string = CleaningStatusTexts[cleaningStatusName as keyof typeof CleaningStatusTexts];
    const maids: (UserInfo | undefined)[] = [cleaningStatus.Maid1, cleaningStatus.Maid2, cleaningStatus.Maid3];
    const encodedStatuses: (boolean | null)[] = [
        cleaningStatus.user1SrcEncoded,
        cleaningStatus.user2SrcEncoded,
        cleaningStatus.user3SrcEncoded,
    ];

    const reservationStatus: string = ReservationStatusEnum[cleaningStatus.reservationStatus];
    const reservationStatusText: string =
        ReservationStatusText[reservationStatus as keyof typeof ReservationStatusText];

    const tileStyle =
        cleaningStatus.reservationStatus === ReservationStatusEnum.stay &&
        cleaningStatus.cleaningStatus === CleaningStatusEnum.stagedForCleaning
            ? {
                  color: 'white',
                  backgroundColor: '#c9af2e',
              }
            : CleaningStatusColors[cleaningStatusName as keyof typeof CleaningStatusColors];

    return (
        <div className="">
            <div style={{ ...tileStyle, position: 'relative' }} className={`text-center elipsis`}>
                <span     onClick={() => {
                showCleaningOptions(cleaningStatus);
            }}
            className={'pointer'}>
                <i className={iconName + ' fa-2x m-2'} />
                <small>{translate(cleaningStatusText)}</small></span>
                
                {cleaningStatusEnum !== CleaningStatusEnum.noActionTaken ? (
                    <small
                        style={{ position: 'absolute', top: '0', right: '0' }}
                        onClick={async () => {
                            const updatetedCleaningStatus = { ...cleaningStatus };
                            if (
                                updatetedCleaningStatus.inspectionStatus !==
                                CleaningInspectionEnum.cleaningInspectionRequired
                            ) {
                                updatetedCleaningStatus.inspectionStatus =
                                    CleaningInspectionEnum.cleaningInspectionRequired;
                            } else if (
                                updatetedCleaningStatus.inspectionStatus ===
                                CleaningInspectionEnum.cleaningInspectionRequired
                            ) {
                                updatetedCleaningStatus.inspectionStatus = CleaningInspectionEnum.noInspectionTaken;
                            }
                            await updateCleaningStatus(updatetedCleaningStatus);
                        }}
                    >
                        <span
                            title={
                                cleaningInspectionStatus !== CleaningInspectionEnum.cleaningInspectionRequired
                                    ? 'Cleaning inspection is not required, click to change'
                                    : 'Cleaning inspection required, click to change'
                            }
                            className="fa-stack"
                        >
                            <i className="fa fa-bell fa-stack-1x" style={{ color: '#2a78f5' }}></i>
                            {cleaningInspectionStatus !== CleaningInspectionEnum.cleaningInspectionRequired ? (
                                <i className="fa fa-ban fa-stack-2x"></i>
                            ) : null}
                        </span>
                    </small>
                ) : null}
            </div>

            <div className="d-flex justify-content-around bg-light">
                <div className="text-center">
                    <small>{translate('Res.')}:</small>
                </div>
                <div className="text-center">
                    <small>{translate(reservationStatusText)}</small>
                </div>
            </div>

            {maids.map((maid: UserInfo | undefined, index: number) => {
                const position = index + 1;

                return (
                    <div key={index} className="pointer border-bottom m-2">
                        {maid ? (
                            <span>
                                <i className="fa fa-address-card-o mr-1" />
                                <small className={encodedStatuses[index] ? 'text-danger' : 'text-primary'}>
                                    {' '}
                                    {maid.name + ' ' + maid.surname}
                                </small>

                                <i
                                    className="fa fa-trash text-danger ml-2"
                                    onClick={async () => {
                                        const updatedCleaningStatus = await updateCleaningPlanOnSelectedRoom(
                                            cleaningStatusHeaderId,
                                            selectedMaid,
                                            roomInfoId,
                                            position,
                                            true
                                        );
                                        setCleaningStatus(updatedCleaningStatus);
                                    }}
                                ></i>
                            </span>
                        ) : (
                            <span
                                onClick={async () => {
                                    const updatedCleaningStatus = await updateCleaningPlanOnSelectedRoom(
                                        cleaningStatusHeaderId,
                                        selectedMaid,
                                        roomInfoId,
                                        position
                                    );
                                    setCleaningStatus(updatedCleaningStatus);
                                }}
                            >
                                <i className="fa fa-address-card-o mr-1" />{' '}
                                <small className="text-secondary">(click to add)</small>{' '}
                            </span>
                        )}
                    </div>
                );
            })}
        </div>
    );
}

function RoomScheduleTileForMaid(props: RoomScheduleTilePropsForMaid) {
    const { cleaningStatus } = props;
    const cleaningStatusEnum = cleaningStatus.cleaningStatus;
    const cleaningStatusName: string = CleaningStatusEnum[cleaningStatusEnum];
    const iconName: string = CleaningStatusIcons[cleaningStatusName as keyof typeof CleaningStatusIcons];
    const cleaningStatusText: string = CleaningStatusTexts[cleaningStatusName as keyof typeof CleaningStatusTexts];
    const maids: (UserInfo | undefined)[] = [cleaningStatus.Maid1, cleaningStatus.Maid2, cleaningStatus.Maid3];
    const encodedStatuses: (boolean | null)[] = [
        cleaningStatus.user1SrcEncoded,
        cleaningStatus.user2SrcEncoded,
        cleaningStatus.user3SrcEncoded,
    ];

    const tileStyle =
        cleaningStatus.reservationStatus === ReservationStatusEnum.stay &&
        cleaningStatus.cleaningStatus === CleaningStatusEnum.stagedForCleaning
            ? {
                  color: 'white',
                  backgroundColor: '#c9af2e',
              }
            : CleaningStatusColors[cleaningStatusName as keyof typeof CleaningStatusColors];

    const reservationStatus: string = ReservationStatusEnum[cleaningStatus.reservationStatus];
    const reservationStatusText: string =
        ReservationStatusText[reservationStatus as keyof typeof ReservationStatusText];

    return (
        <div className="pointer">
            <div style={tileStyle} className={`text-center elipsis`}>
                <i className={iconName + ' fa-2x m-2'} />
                <small>{translate(cleaningStatusText)}</small>
            </div>

            <div className="d-flex justify-content-around bg-light">
                <div className="text-center">
                    <small>{translate('Res.')}:</small>
                </div>
                <div className="text-center">
                    <small>{translate(reservationStatusText)}</small>
                </div>
            </div>

            {maids.map((maid: UserInfo | undefined, index: number) => {
                return (
                    <div key={index} className="border-bottom m-2">
                        {maid ? (
                            <span>
                                <i className="fa fa-address-card-o mr-1" />
                                <small className={encodedStatuses[index] ? 'text-danger' : 'text-primary'}>
                                    {' '}
                                    {maid.name + ' ' + maid.surname}
                                </small>
                            </span>
                        ) : (
                            <i className="fa fa-address-card-o mr-1" />
                        )}
                    </div>
                );
            })}
        </div>
    );
}

const SelectedMaidHouseholdPlan = (props: SelectedMaidHouseholdPlanProps) => {
    const { selectedMaidHouseholdPlan, scheduleDates, schedulingMaids, setSelectedTabIndex, selectedTabIndex } = props;
    const selectedTabMaid = schedulingMaids[selectedTabIndex];
    return (
        <div>
            <ul className="nav nav-tabs">
                {schedulingMaids.map((m: UserInfo, index: number) => {
                    return (
                        <li className="nav-item" key={index}>
                            <a
                                onClick={(e) => {
                                    e.preventDefault();
                                    setSelectedTabIndex(index);
                                }}
                                className={selectedTabIndex === index ? 'nav-link bg-light active' : 'nav-link '}
                                href="/#"
                            >
                                {m.name} {m.surname}
                            </a>
                        </li>
                    );
                })}
            </ul>

            {selectedTabMaid && (
                <h5 className="bg-light border-left border-right p-2">
                    Cleaning schedule for: {`${selectedTabMaid.name} ${selectedTabMaid.surname}`}
                </h5>
            )}

            <div className="mt-2" style={{ overflowY: 'auto', height: verticalHeightInner }}>
                <div className="d-flex border-bottom justify content-between">
                    <div className="elipsis col-md-2 ">Room name</div>
                    {scheduleDates.map((d: Moment, index: number) => {
                        return (
                            <div key={index} className="col-md-2 text-center">
                                {moment(d).format('ll')}
                            </div>
                        );
                    })}
                </div>

                {Object.keys(selectedMaidHouseholdPlan).map((key: string, index: number) => {
                    const room: RoomInfoEditModel | undefined = selectedMaidHouseholdPlan[key][0].RoomInfo;
                    return (
                        <div className="d-flex justify-content-between" key={key}>
                            <div className="text-bold elipsis col-md-2 text-center border-bottom mt-5">
                                <h3 className="mt-4">{room ? room.name : key}</h3>
                            </div>
                            {selectedMaidHouseholdPlan[key].map(
                                (cs: CleaningStatusExtendedWithIncludedModels, index: number) => {
                                    const styleTile: any =
                                        schedulingMaids[selectedTabIndex] &&
                                        (cs.cleanedByUserUuid === schedulingMaids[selectedTabIndex].uuid ||
                                            cs.cleanedByUserUuid2 === schedulingMaids[selectedTabIndex].uuid)
                                            ? { color: 'black' }
                                            : { color: 'grey', opacity: '0.5' };

                                    return (
                                        <div
                                            className={'border-bottom border-left col-md-2 p-2'}
                                            style={styleTile}
                                            key={index}
                                        >
                                            <RoomScheduleTileForMaid cleaningStatus={cs} />
                                        </div>
                                    );
                                }
                            )}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

const getScheduleDates = (props: MainAreaProps): Moment[] => {
    const { otherSettings } = props;
    const schedulleDays: number = otherSettings.schedulleDays ? otherSettings.schedulleDays : 5;
    const scheduleDates: Moment[] = [];
    for (let i = 0; i < schedulleDays; i++) {
        const date: Moment = moment().add(i, 'days');
        scheduleDates.push(date);
    }
    return scheduleDates;
};

export default branch(
    {
        rooms: ['model', 'RoomInfo'],
        reservations: ['model', 'Reservation'],
        otherSettings: ['otherSettings'],
        roomTags: ['monkeys', 'roomTags'],
        selectedMaidHouseholdPlan: ['state', 'householdPlanForSelectedMaid'],
        householdPlan: ['monkeys', 'cleaningStatusHeadersWithCleaningStatuses'],
        cleaningStatuses: ['model', 'CleaningStatus'],
        loggedUser: ['authTokenInfo', 'user'],
    },
    HouseholdPlanner
);
