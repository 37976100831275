import React from "react"
import { handleChange, saveFormData } from "../settingsActions"
import { useBranch } from 'baobab-react/hooks';
import InlineModal from '../../../components/modalInline';
import pubsub from '../../../data/pubsub';
import FiscalBroadcastStatusMonitor from '../../fiscalPrinterTools/broadcastStatusMonitor';
import rest from '../../../data/restWrapper';
import _ from "lodash"

const FiscalPOS = (settings) => {
    const { fiscalPOS } = settings
    const { SRCProxies } = useBranch({
        SRCProxies: ['model', 'SRCProxy'],
    });

    const [showModal, setShowModal] = React.useState(false)
    const [fiscalConfig, setFiscalConfig] = React.useState(null)

    React.useEffect(() => {
        const loadData = async () => {
            try {
                let srcProxiUuid = null;
                const fiscalSrcProxies = SRCProxies.filter((s) => {
                    return s.fiscalProxy;
                });
                const hasFiscalPrinters = fiscalSrcProxies.length > 0;
                if (hasFiscalPrinters) {
                    srcProxiUuid = fiscalSrcProxies[0].SRCProxyUUID;
                }
                let fiscalConfig = null;
                if (srcProxiUuid !== null) {
                    fiscalConfig = await rest('/getFiscalConfig/' + srcProxiUuid, 'GET');                    
                    setFiscalConfig(fiscalConfig)
                }
            } catch (err) {
                console.warn(err);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, [SRCProxies])

    const applyPOSReceiptFooterMessage = async () => {
        const fiscalPOSData = fiscalPOS;
        let fiscalPOSArray = fiscalPOSData.receiptFooter.split('\n');
        fiscalPOSArray = fiscalPOSArray.map((fis, key) => {
            return { line: key + 2, text: fis };
        });
        setShowModal(true)
        pubsub.emit('broadcastFiscalOperation', '/applyPOSReceiptFooter', { lines: fiscalPOSArray });
    }

    const applyDaylightSavingTimeIntoPOS = async () => {
        const fiscalPOSData = fiscalPOS;
        const daylightSavingTime = fiscalPOSData.daylightSavingTime;
        setShowModal(true)
        pubsub.emit('broadcastFiscalOperation', '/applyDaylightSavingTimeIntoPOS', {
            daylightSavingTime: daylightSavingTime,
        });
    }

    const fiscalSRCProxies = SRCProxies.length > 0 ? SRCProxies.filter((src) => {
        return src.fiscalProxy;
    }) : [];

    const lineNumber = fiscalConfig && fiscalConfig.countryAndProvider === 'rs_galeb' ? ' (1 line)' : '';
    const showFbih_config = fiscalConfig && fiscalConfig.countryAndProvider === 'fbih_config' ? true : false;
    
    return (
        <div className=" bg-white p-3 ">
            <InlineModal show={showModal}>
                <div className="mt-3">
                    <FiscalBroadcastStatusMonitor />
                    <div className="text-center mt-3">
                        <button
                            onClick={() => setShowModal(false)}
                            className="btn btn-sm btn-info"
                        >
                            {window.translate('Close')}
                        </button>
                    </div>
                </div>
            </InlineModal>

            <b>{window.translate('Fiscal POS')}</b>
            <hr />

             <div className="row  mt-2">
                <div className="col-md-4">
                    <label htmlFor="daylightSavingTime">{window.translate('Daylight saving time')}:</label>
                </div>
                <div className="col-md-2">
                    <select
                        name="daylightSavingTime"
                        value={fiscalPOS?.daylightSavingTime}
                        onChange={handleChange.bind(this, 'fiscalPOS', 'object')}
                        className="custom-select my-1 mr-sm-2 "
                        id="daylightSavingTime"
                    >
                        {['winter', 'summer'].map((dts) => {
                            return (
                                <option key={dts} value={dts}>
                                    {dts}
                                </option>
                            );
                        })}
                    </select>
                </div>
                {!_.isEmpty(fiscalSRCProxies) ? (
                    <div className="col-md-2">
                        <button
                            onClick={() => applyDaylightSavingTimeIntoPOS()}
                            className="btn btn-sm btn-warning"
                        >
                            {window.translate('Apply daylight saving time')}
                        </button>
                    </div>
                ) : null}
                <div className="col-md-4">
                    <small>
                        <b>{window.translate('The command can be executed only 2 times per year!!!')}</b>
                    </small>
                </div>
            </div>

            <hr />

            <div className="row  mt-2">
                <div className="col-md-4">
                    <label htmlFor="receiptFooter">{window.translate('Receipt footer') + lineNumber}:</label>{' '}
                </div>
                <div className="col-md-6">
                    <textarea
                        rows="2"
                        autoComplete="off"
                        name="receiptFooter"
                        value={fiscalPOS?.receiptFooter}
                        onChange={handleChange.bind(this, 'fiscalPOS', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
                {showFbih_config ? (
                    <div className="col-md-2">
                        <button
                            onClick={() => applyPOSReceiptFooterMessage()}
                            className="btn btn-sm btn-warning"
                        >
                            {window.translate('Apply footer (Max 2 lines)')}
                        </button>
                    </div>
                ) : null}
            </div>

            <div className="text-center p-5">
                {' '}
                <div onClick={() => saveFormData("fiscalPOS", fiscalPOS)} className="btn btn-outline-primary mb-4 pointer">
                    {window.translate('Save')}
                </div>{' '}
            </div>
        </div>
    );
}

export default FiscalPOS;