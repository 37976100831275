import * as React from 'react';
import PageTitle from '../../components/pageTitle';
import rest from '../../data/restWrapper';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { setModalContent } from '@components/modal';
import Delete from '../../components/confirmModal';
import InlineRangeInput from '../../components/inlineRangeInput';
import { reservationStatusEnumsDesc, reservationStatusColors } from '../../data/reservationStatusEnums';
import FeatureFlag from '../../components/FeatureFlag';
import translate from '@data/translations';
import { ReservationController } from './resController';
import { toast } from 'react-toastify';
import { ReservationModel, RoomInfoModel } from '@common/modelDefinition';

interface ReservationsAdminProps {
    history: any;
    rooms: RoomInfoModel[];
}

function ReservationsAdmin(props: ReservationsAdminProps) {
    const [reservations, setReservations] = React.useState([] as ReservationModel[]);
    const [page, setPage] = React.useState(0);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [from, setFrom] = React.useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
    );
    const [to, setTo] = React.useState(new Date());
    const [reservationsFromSearchBox, setReservationsFromSearchBox] = React.useState([] as ReservationModel[]);
    const [loading, setLoading] = React.useState(false);

    const rows = 20;
    let searchDebouncer: any = null;

    const { history, rooms } = props;

    const ctrl = new ReservationController();

    const getPageData = async (_page = page) => {
        try {
            setLoading(true);
            const reservationsFromTo: ReservationModel[] = await ctrl.getAllReservationsFromTo(from, to, _page, rows);
            setReservations(reservationsFromTo);
            setPage(_page);
            setLoading(false);
        } catch (error: any) {
            console.log(error);
            toast(error.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
    };

    React.useEffect(() => {
        getPageData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const deleteReservation = async (reservationId: number) => {
        try {
            await ctrl.deleteReservation(reservationId);
            let _reservations = [...reservations];
            _reservations = _reservations.filter((r: ReservationModel) => r.id !== Number(reservationId));
            setReservations(_reservations);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = async (reservationId: number) => {
        setModalContent(
            <Delete actionForYes={() => deleteReservation(reservationId)} />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const pageDown = async () => {
        if (page > 0) {
            const _page = page - 1;
            await getPageData(_page);
        }
    };

    const pageUp = async () => {
        if (reservations.length > 0) {
            const _page = page + 1;
            await getPageData(_page);
        }
    };

    const onSearch = async (e: any) => {
        clearTimeout(searchDebouncer);
        const _searchTerm = e.target.value;
        setSearchTerm(_searchTerm);
        searchDebouncer = setTimeout(async () => {
            const path = '/reservation/' + _searchTerm; //provjeri ovo... možda je bolja klasična pretraga
            try {
                const _reservationsFromSearchBox = await rest(path);
                setReservationsFromSearchBox(
                    _reservationsFromSearchBox.id ? [_reservationsFromSearchBox] : [..._reservationsFromSearchBox]
                );
            } catch (err) {
                //console.log(err);
            }
        }, 200);
    };

    const handleDateSelection = async (type: string, date: any) => {
        setLoading(true);
        const _from = type === 'from' ? date : from;
        const _to = type === 'to' ? date : to;
        const _reservations = await ctrl.getAllReservationsFromTo(_from, _to, page, rows);
        setReservations(_reservations);
        setFrom(_from);
        setTo(_to);
        setLoading(false);
    };

    const _reservations = searchTerm.length > 0 ? reservationsFromSearchBox : reservations;

    const reservationList = _reservations.map((r: ReservationModel, index: number) => {
        return (
            <ReservationListItem key={index} r={r} showDeleteModal={showDeleteModal} history={history} rooms={rooms} />
        );
    });

    if (loading) {
        return translate('Loading ...');
    }
    //animated faster slideInDown
    return (
        <div className="">
            <PageTitle />

            <div className="row ">
                <div className="d-flex col-12">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {translate('GO BACK')}
                    </button>

                    <div className="ml-2 ">
                        <input
                            // disabled={true}
                            autoComplete="off"
                            placeholder={translate('Search by ID')}
                            name="search"
                            value={searchTerm}
                            onChange={(e) => onSearch(e)}
                            type="text"
                            className="form-control form-control-sm"
                        />
                    </div>

                    <div className="ml-auto d-none d-md-block align-self-center">
                        <InlineRangeInput from={from} to={to} onChange={handleDateSelection} />
                    </div>

                    <div className="ml-auto d-none d-md-block align-self-center">
                        <button onClick={() => pageDown()} className="btn btn-sm btn-outline-primary mr-2">
                            {translate('prev')}
                        </button>
                        <small className="mt-1">
                            {translate('page')} {page}
                        </small>
                        <button onClick={() => pageUp()} className="btn btn-sm btn-outline-primary ml-2">
                            {translate('next')}
                        </button>
                    </div>
                </div>
            </div>

            <div className="list-group mt-3 mx-3">
                <small>
                    <div className="px-1 py-2 row shadow-sm bg-white">
                        <div className="col-6 col-sm-3">{translate('Id')+", "+translate("Status")}</div>
                        <div className="col-6 col-sm-3">{translate('Room')}</div>
                        <div className="col-6 col-sm-3">{translate('Date')}</div>
                        <div className="col-6 col-sm-3"></div>
                    </div>
                </small>
            </div>

            <ul className="list-group mt-3 mx-3">{reservationList}</ul>
        </div>
    );
}

interface ResListItemPropsInterface {
    r: ReservationModel;
    showDeleteModal: Function;
    history: any;
    rooms: RoomInfoModel[];
}

const ReservationListItem = (props: ResListItemPropsInterface) => {
    const { r, showDeleteModal, history, rooms } = props;

    if (r && r.id) {
        const room = rooms.find((room: RoomInfoModel) => room.id === r.roomInfoId);
        return (
            <li key={r.id} className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                <div className="col-6 col-sm-3">
                    <small>#{r.id} / </small>

                    <small className={reservationStatusColors[r.statusEnum]}>
                        {reservationStatusEnumsDesc[r.statusEnum]}
                    </small>
                </div>
                <div className="col-6 col-sm-3">{room?.name}</div>
                <div className="col-6 col-sm-3">
                    <small className="mr-2">
                        {' '}
                        <i className="fa fa-calendar-check-o" aria-hidden="true"></i>{' '}
                        {moment(r.checkInTimestamp).format('ddd, DD. MMM')}
                    </small>
                    <small>
                        <i className="fa fa-sign-out" aria-hidden="true"></i>{' '}
                        {moment(r.checkOutTimestamp).format('ddd, DD. MMM')}
                    </small>
                </div>

                <div className="col-6 col-sm-3">
                    <button
                        onClick={() => {
                            history.push('/reservations/' + r.id + '/edit/');
                        }}
                        className="btn btn-sm btn-info ml-1 pull-right"
                    >
                        <i className="fa fa-edit" />
                    </button>
                    <FeatureFlag flag="integrityDeleteButton">
                        <button
                            disabled={true}
                            onClick={() => showDeleteModal(r.id)}
                            className="btn btn-sm btn-danger ml-1 pull-right"
                        >
                            <i className="fa fa-trash" />
                        </button>
                    </FeatureFlag>
                </div>
            </li>
        );
    }
    return null;
};

export default branch(
    {
        loggedUser: ['authTokenInfo', 'user'],
        from: ['uiStates', 'reservationAdministration', 'from'],
        to: ['uiStates', 'reservationAdministration', 'to'],
        rooms: ['model', 'RoomInfo'],
    },
    ReservationsAdmin
);
