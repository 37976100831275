import React, { Component, Fragment } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import { getSpecificModelRow } from '../../../data/actions/modelActions';
import { setModalContent } from '@components/modal';
import moment from 'moment';
import rest from '../../../data/restWrapper';
import Delete from '../../../components/confirmModal';
import SelectOnClick from './selectOnClick';
import repeatRulesEnum from './enums';
import { closeModal } from '@components/modal';
import TaxLabel from './taxLabel';

const emptyModel = {
    id: null,
    parentId: null,
    name: null,
    description: null,
    pluBase: null,
    weight: 1,
    infinite: false,
    validFrom: moment().startOf('day').valueOf(), //new Date().getTime(),
    validTo: moment().endOf('day').valueOf(),
    blockPromotions: false,
    repeatRules: {
        noRepeat: true,
    },
    accomodationPriceRules: null,
    discountRules: null,
    isActive: true,
    params: null,
    generalTaxCode: null,
    validationMessage: null,
};

class AccommodationPriceListFormStack extends Component {
    constructor(props) {
        super(props);
        this.state = _.clone(emptyModel);
        this.nameRef = React.createRef();
        this.priceRefs = [];
    }

    async componentDidMount() {
        if (this.props.accommodationPriceListId) {
            let accommodationPriceListId = this.props.accommodationPriceListId;
            this.redrawComponent(accommodationPriceListId);
        } else {
            this.firstEntryLoad();
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { accommodationPriceListId } = this.props;
        if (accommodationPriceListId === null) {
            if (prevState.parentId === null) {
                this.firstEntryLoad();
            } else if (prevState.id) {
                //force emptty values
                this.firstEntryLoad();
            }
        } else {
            if (prevProps.accommodationPriceListId !== accommodationPriceListId) {
                this.redrawComponent(accommodationPriceListId);
            } else if (prevState.id !== accommodationPriceListId) {
                this.redrawComponent(accommodationPriceListId);
            }
        }
    }

    async firstEntryLoad() {
        let { parentId } = this.props; //Number(this.props.match.params.accommodationPriceListId)
        let currentAccommodationPriceList = _.find(this.props.accommodationPriceLists, (apl) => apl.id === parentId); //await getSpecificModelRow("AccommodationPriceList", parentId)
        let accomodationPriceRules = currentAccommodationPriceList
            ? JSON.parse(currentAccommodationPriceList.accomodationPriceRules)
            : [];
        let newState = _.clone(emptyModel);
        newState.parentId = parentId;
        newState.accomodationPriceRules = accomodationPriceRules;
        newState.pluBase = this.renderFirstAvailablePluBase();
        this.priceRefs = [];
        for (let i = 0; i < accomodationPriceRules.length; i++) {
            this.priceRefs.push(React.createRef());
        }
        this.setState(newState);
    }

    async redrawComponent(accommodationPriceListId) {
        if (
            !_.isUndefined(accommodationPriceListId) &&
            accommodationPriceListId !== null &&
            _.isNaN(accommodationPriceListId) === false
        ) {
            let currentAccommodationPriceList = await getSpecificModelRow(
                'AccommodationPriceList',
                accommodationPriceListId
            );

            currentAccommodationPriceList.accomodationPriceRules = JSON.parse(
                currentAccommodationPriceList?.accomodationPriceRules
            );
            currentAccommodationPriceList.repeatRules = JSON.parse(currentAccommodationPriceList?.repeatRules);

            let { accomodationPriceRules } = currentAccommodationPriceList;
            this.clearValidationRefs();
            this.priceRefs = [];
            for (let i = 0; i < accomodationPriceRules.length; i++) {
                this.priceRefs.push(React.createRef());
            }

            this.setState(currentAccommodationPriceList);
        }
    }

    showDeleteModal(itemId, e) {
        e.preventDefault();
        setModalContent(
            <Delete actionForYes={this.props.deleteItem.bind(this, itemId)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    }

    inputHandler(e) {
        let state = _.clone(this.state);
        switch (e.target.type) {
            default:
            case 'text':
                state[e.target.name] = e.target.value;

                break;
            case 'select-one':
            case 'number':
                if (_.isNumber(Number(e.target.value))) {
                    state[e.target.name] = Number(e.target.value);
                } else {
                    return null;
                }
                break;
            case 'checkbox':
                state[e.target.name] = !e.target.value;
                break;
        }
        this.setState(state);
    }

    clearValidationRefs() {
        this.nameRef.current.className = this.nameRef.current.className.replace('is-invalid', '');
        _.forEach(this.priceRefs, (p) => {
            if (p.current) {
                p.current.className = p.current.className.replace('is-invalid', '');
            }
        });
    }

    getValidationInputs(data) {
        let invalidInputs = [];
        let validInputs = [];
        let validationMessage = [];
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(this.nameRef);
            validationMessage.push(window.translate('Name is mandatory'));
        } else {
            validInputs.push(this.nameRef);
        }
        let accomodationPriceRules = JSON.parse(data.accomodationPriceRules);
        for (let i = 0; i < accomodationPriceRules.length; i++) {
            if (
                accomodationPriceRules[i] === null ||
                accomodationPriceRules[i] === '' ||
                accomodationPriceRules[i] <= 0
            ) {
                invalidInputs.push(this.priceRefs[i]);
            } else {
                validInputs.push(this.priceRefs[i]);
            }
        }
        return { invalidInputs, validInputs, validationMessage };
    }

    markInvalidInputRefs(validationResults) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        _.forEach(invalidInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        _.forEach(validInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }

    async onSave() {
        try {
            let data = _.clone(this.state);
            let { parentId } = this.props;
            data.parentId = parentId;
            let accomodationPriceRules = JSON.stringify(data['accomodationPriceRules']);
            let repeatRules = JSON.stringify(data['repeatRules']);
            data['accomodationPriceRules'] = accomodationPriceRules;
            data['repeatRules'] = repeatRules;
            data['validFrom'] = moment(data.validFrom).startOf('day').valueOf();
            let validationResults = this.getValidationInputs(data);
            this.setState({ validationMessage: validationResults.validationMessage[0] });
            this.markInvalidInputRefs(validationResults);
            if (validationResults.invalidInputs.length === 0) {
                if (data.id !== null) {
                    let id = data.id;
                    delete data.id;
                    await rest('/AccommodationPriceList/' + id, 'PUT', data);
                } else {
                    await rest('/AccommodationPriceList', 'POST', data);
                }
                //await rest("/safeAccommodationPricelistSave", "POST", body) //don't work
                this.setState(_.clone(emptyModel));
                closeModal();
            }
        } catch (err) {
            console.warn(err);
        }
    }

    cancelHandler() {
        let newState = _.clone(emptyModel);
        this.setState(newState);
        closeModal();
    }

    renderInputField(_name, label, type = 'text', value, cfg, ref = undefined) {
        return (
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-4 col-form-label " htmlFor={'pl-' + _name}>
                    {label}:
                </label>
                <div className="col-8">
                    {type === 'number' ? (
                        <input
                            ref={ref}
                            id={'pl-' + _name}
                            min={cfg.min}
                            max={cfg.max}
                            name={_name}
                            placeholder={label}
                            autoComplete="off"
                            value={value ? value : ''}
                            onChange={this.inputHandler.bind(this)}
                            type={type}
                            className="form-control"
                        />
                    ) : (
                        <input
                            ref={ref}
                            id={'pl-' + _name}
                            name={_name}
                            placeholder={label}
                            autoComplete="off"
                            value={value ? value : ''}
                            onChange={this.inputHandler.bind(this)}
                            type={type}
                            className="form-control"
                        />
                    )}
                </div>
            </div>
        );
    }

    checkboxHandler(name, e) {
        let obj = _.clone(this.state);
        obj[name] = !obj[name];
        if (name === 'infinite') {
            //you can't have no repeat and infinite combined together
            if (this.state.repeatRules.noRepeat !== undefined) {
                obj.repeatRules = { weeklyOnDays: [] };
            }
            this.setState(obj);
        } else {
            this.setState(obj);
        }
    }

    handleDatePicker(e) {
        if (e.target.value) {
            let state = _.clone(this.state);
            switch (e.target.name) {
                default:
                case 'validFrom':
                    state[e.target.name] = moment(e.target.value).endOf('day').valueOf(); //new Date(moment(e.target.value).add(2, "hours")).getTime() // Date picker prikazuje jučerašnji datum ako nije 02:00:00
                    break;
                case 'validTo':
                    state[e.target.name] = moment(e.target.value).endOf('day').valueOf(); //new Date(moment(e.target.value).endOf("day")).getTime()
                    break;
                case 'from':
                    state.repeatRules.yearly[e.target.name] = moment(e.target.value).startOf('day').valueOf(); //new Date(e.target.value).getTime() // 02:00:00
                    break;
                case 'to':
                    state.repeatRules.yearly[e.target.name] = moment(e.target.value).endOf('day').valueOf(); //new Date(moment(e.target.value).endOf("day")).getTime()
                    break;
            }
            this.setState(state);
        }
    }
    renderAccomodationPriceRulesInputs() {
        let accomodationPriceRules = _.clone(this.state.accomodationPriceRules);
        let inputs = null;
        if (accomodationPriceRules !== null) {
            inputs = _.map(accomodationPriceRules, (value, index) => {
                let personCount = Number(index) + 1;
                // let labelFinish = personCount === 1 ? " person" : " persons"
                return (
                    <div key={index} className="form-group row justify-content-center align-items-center">
                        <label className="col-4 col-form-label " htmlFor={'pl-pr' + personCount}>
                            {window.translate('Person count')}: {personCount}
                        </label>
                        <div className="col-8">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">{this.props.defaultCurrency}</span>
                                </div>
                                <input
                                    ref={this.priceRefs[Number(index)]}
                                    id={'pl-pr' + personCount}
                                    name="accomodationPriceRules"
                                    placeholder={window.translate('Price')}
                                    autoComplete="off"
                                    value={accomodationPriceRules !== null && value !== null ? value : ''}
                                    onChange={this.accomodationPriceRulesHandler.bind(this, index)}
                                    type="number"
                                    className="form-control"
                                />
                            </div>

                            <TaxLabel
                                parentId={this.state.parentId}
                                value={value}
                                generalTaxCode={this.state.generalTaxCode}
                            />
                        </div>
                    </div>
                );
            });
            return <div>{inputs}</div>;
        } else {
            return (
                <div className="text-center text-danger">
                    {window.translate('No prices . You should delete this price list and create a new one.')}
                </div>
            );
        }
    }

    accomodationPriceRulesHandler(index, e) {
        // e.preventDefault()
        let accomodationPriceRules = _.clone(this.state.accomodationPriceRules);
        if (accomodationPriceRules !== null) {
            if (e.target.value !== '') {
                accomodationPriceRules[index] = Number(e.target.value);
                this.setState({ accomodationPriceRules: accomodationPriceRules });
            } else {
                accomodationPriceRules[index] = null;
                this.setState({ accomodationPriceRules: accomodationPriceRules });
            }
        } else {
            accomodationPriceRules = [];
            accomodationPriceRules[index] = e.target.value === '' ? null : Number(e.target.value);
            this.setState({ accomodationPriceRules: accomodationPriceRules });
        }
    }

    getDatesPicker(yearlyRepeat = undefined) {
        let from = '';
        let to = '';
        let valueFrom = null;
        let valueTo = null;
        let titleFrom = '';
        let titleTo = '';
        if (yearlyRepeat === undefined) {
            from = 'validFrom';
            to = 'validTo';
            titleFrom = window.translate('Valid from');
            titleTo = window.translate('Valid to');
            valueFrom = moment(this.state.validFrom).toISOString(true).substr(0, 10);
            valueTo = moment(this.state.validTo).toISOString(true).substr(0, 10);
        } else {
            from = 'from';
            to = 'to';
            titleFrom = window.translate('From');
            titleTo = window.translate('To');
            valueFrom = this.state.repeatRules
                ? moment(this.state.repeatRules.yearly.from).toISOString(true).substr(0, 10)
                : moment().toISOString(true).substr(0, 10);

            valueTo = this.state.repeatRules
                ? moment(this.state.repeatRules.yearly.to).toISOString(true).substr(0, 10)
                : moment().toISOString(true).substr(0, 10);
        }

        let fromDiv = (
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-4 col-form-label " htmlFor={'PL-' + from + '-input'}>
                    {titleFrom}:
                </label>
                <div className="col-8 ">
                    <input
                        autoComplete="off"
                        onChange={this.handleDatePicker.bind(this)}
                        value={valueFrom}
                        type="date"
                        required="required"
                        pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"
                        className="form-control col-md-6"
                        name={from}
                        placeholder={from}
                    />
                </div>
            </div>
        );
        let toDiv = (
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-4 col-form-label " htmlFor={'PL-' + to + '-input'}>
                    {titleTo}:
                </label>
                <div className="col-8">
                    <input
                        autoComplete="off"
                        onChange={this.handleDatePicker.bind(this)}
                        value={valueTo}
                        type="date"
                        required="required"
                        pattern="(?:19|20)[0-9]{2}-(?:(?:0[1-9]|1[0-2])-(?:0[1-9]|1[0-9]|2[0-9])|(?:(?!02)(?:0[1-9]|1[0-2])-(?:30))|(?:(?:0[13578]|1[02])-31))"
                        className="form-control col-md-6"
                        name={to}
                        placeholder={to}
                    />
                </div>
            </div>
        );

        return yearlyRepeat === undefined ? (
            <div>
                {fromDiv}
                {toDiv}
            </div>
        ) : (
            <div>
                {fromDiv}
                {toDiv}
            </div>
        );
    }

    getCheckboxInput(_name, label, value) {
        return (
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-4 col-form-label" htmlFor="PL-isActive-input">
                    {label}:
                </label>
                <div className="col-8 text-left">
                    <i
                        onClick={this.checkboxHandler.bind(this, _name)}
                        name={_name}
                        value={value}
                        className={value ? 'fa fa-check-square fa-lg ml-2' : 'fa fa-square fa-lg ml-2'}
                    ></i>
                </div>
            </div>
        );
    }

    handleRepeatPeriodRadioSelect = (event) => {
        let repeatPeriod = event.target.value;
        let repeatRules = _.clone(this.state.repeatRules);
        let infinite = _.clone(this.state.infinite);
        if (repeatRules === null) {
            repeatRules = {};
        }
        if (repeatRules[repeatPeriod] === undefined) {
            switch (repeatPeriod) {
                case 'noRepeat':
                    repeatRules[repeatPeriod] = true;
                    infinite = false;
                    break;
                case 'weeklyOnDays':
                    repeatRules[repeatPeriod] = [];
                    break;
                case 'monthly':
                    repeatRules[repeatPeriod] = [];
                    break;
                case 'yearly':
                    repeatRules[repeatPeriod] = { from: Date.now(), to: Date.now() };
                    infinite = true;
                    break;
                default:
                    break;
            }
        }
        //remove excesive keys....
        _.forEach(Object.keys(repeatRules), (val) => {
            if (repeatPeriod !== val) {
                delete repeatRules[val];
            }
        });

        this.setState({ repeatRules, infinite });
    };

    handleDaysOfWeekSelect(event) {
        let repeatRules = _.clone(this.state.repeatRules);
        let day = Number(event.target.value);
        if (event.target.checked) {
            repeatRules[repeatRulesEnum.weeklyOnDays].push(day);
        } else {
            _.remove(repeatRules[repeatRulesEnum.weeklyOnDays], (i) => i === day);
        }
        this.setState({ repeatRules });
    }

    handleDaysOMonthSelect(event) {
        let repeatRules = _.clone(this.state.repeatRules);
        let day = Number(event.target.value);
        if (event.target.checked) {
            repeatRules[repeatRulesEnum.monthly].push(day);
        } else {
            _.remove(repeatRules[repeatRulesEnum.monthly], (i) => i === day);
        }
        this.setState({ repeatRules });
    }

    renderRepeatRules() {
        let repeatRules = Object.keys(repeatRulesEnum);
        let ISOWeekdayCount = 0; //ISO week days 1- monday, 7 - sunday
        const daysOfWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'].map((day) => {
            ISOWeekdayCount++;
            return (
                <Fragment key={'week' + day}>
                    <input
                        className="weekday"
                        type="checkbox"
                        name={day}
                        value={ISOWeekdayCount}
                        id={`id${day}`}
                        checked={
                            this.state.repeatRules &&
                            this.state.repeatRules.weeklyOnDays &&
                            _.includes(this.state.repeatRules.weeklyOnDays, ISOWeekdayCount)
                        }
                        onChange={this.handleDaysOfWeekSelect.bind(this)}
                    />
                    <label htmlFor={`id${day}`}>{day}</label>
                </Fragment>
            );
        });
        let daysArray = [];
        for (let i = 1; i <= 31; i++) {
            daysArray.push(i);
        }
        const daysOfMonth = _.map(daysArray, (day) => {
            return (
                <Fragment key={'month' + day}>
                    <input
                        className="weekday"
                        type="checkbox"
                        name={day}
                        value={day}
                        id={`month_id${day}`}
                        checked={
                            this.state.repeatRules &&
                            this.state.repeatRules.monthly &&
                            _.includes(this.state.repeatRules.monthly, day)
                        }
                        onChange={this.handleDaysOMonthSelect.bind(this)}
                    />
                    <label htmlFor={`month_id${day}`}>{day}</label>
                </Fragment>
            );
        });
        let repeatRulesElenment = (
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-4 col-form-label" htmlFor="PL-repeatRules-radio">
                    {window.translate('Repeat rules')}:
                </label>

                <div className="col-8">
                    <select
                        className="form-control"
                        name="repeatRules"
                        onChange={this.handleRepeatPeriodRadioSelect.bind(this)}
                        value={this.state.repeatRules ? Object.keys(this.state.repeatRules)[0] : 'No repeat'}
                    >
                        {_.map(repeatRules, (rt, key) => {
                            return (
                                <option key={rt} value={rt}>
                                    {rt}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>
        );

        let weekDays =
            this.state.repeatRules && this.state.repeatRules[repeatRulesEnum.weeklyOnDays] ? (
                <SelectOnClick
                    title={window.translate('Valid on days')}
                    fields={daysOfWeek}
                    category={'weeklyOnDays'}
                />
            ) : null;
        let monthDays =
            this.state.repeatRules && this.state.repeatRules[repeatRulesEnum.monthly] ? (
                <SelectOnClick title={window.translate('Valid on days')} fields={daysOfMonth} category={'monthly'} />
            ) : null;
        let yearlyFromToPicker =
            this.state.repeatRules && this.state.repeatRules[repeatRulesEnum.yearly]
                ? this.getDatesPicker('yearly')
                : null;

        return (
            <div>
                {repeatRulesElenment}
                {weekDays}
                {monthDays}
                {yearlyFromToPicker}
            </div>
        );
    }

    renderWeight() {
        let weightsArray = [];
        for (let i = 1; i <= 7; i++) {
            weightsArray.push(i);
        }
        const weights = _.map(weightsArray, (weight) => {
            return (
                <Fragment key={'weight' + weight}>
                    <input
                        className="weekday"
                        type="checkbox"
                        name={weight}
                        value={weight}
                        id={`weight_id${weight}`}
                        checked={this.state.weight && this.state.weight === weight}
                        onChange={this.handleWeightSelect.bind(this)}
                    />
                    <label htmlFor={`weight_id${weight}`}>{weight}</label>
                </Fragment>
            );
        });

        let drawWeights = <SelectOnClick title={window.translate('Weight')} fields={weights} category={'weight'} />;

        return drawWeights;
    }

    handleWeightSelect(event) {
        let weight = _.clone(this.state.weight);
        let selectedWeight = Number(event.target.value);
        if (event.target.checked) {
            weight = Number(selectedWeight);
        } else {
            weight = '';
        }
        this.setState({ weight });
    }

    renderFirstAvailablePluBase() {
        let accommodationPriceLists = this.props.accommodationPriceLists;
        let pluArray = [];
        let min = 99;
        if (!_.isEmpty(accommodationPriceLists)) {
            _.map(accommodationPriceLists, (acc) => {
                pluArray.push(Number(acc.pluBase));
            });
        }
        if (!_.includes(pluArray, min)) {
            pluArray.push(min);
        }
        pluArray = _.sortBy(pluArray);
        let lowest = -1;
        let offset = pluArray[0];
        if (!_.isEmpty(pluArray)) {
            for (let i = 0; i < pluArray.length; ++i) {
                if (pluArray[i] !== offset) {
                    lowest = offset;
                    break;
                }
                ++offset;
            }
            if (lowest === -1) {
                lowest = pluArray[pluArray.length - 1] + 1;
            }
        } else {
            lowest = 100;
        }
        return Number(lowest);
    }

    renderUnavailablePLUs() {
        let parentAccommodationPricelist = _.find(this.props.accommodationPriceLists, { id: this.props.parentId });
        let parentPLU = parentAccommodationPricelist ? parentAccommodationPricelist.pluBase : '';
        return (
            <div className="form-group row hidden-lg hidden-md visible-xs visible-xs justify-content-center align-items-center">
                <label className="col-4 col-form-label">{window.translate('Taken PLUs')}:</label>
                <div className="col-8 text-left ">
                    <div className="bg-light">
                        {_.map(_.sortBy(this.props.accommodationPriceLists, 'pluBase'), (acc) => {
                            return (
                                <span key={acc.id} className="p-1">
                                    <small>{acc.pluBase},</small>
                                </span>
                            );
                        })}
                    </div>
                    <div className="text-right bg-light">
                        <small>
                            <small>
                                <b>
                                    {window.translate('Parent PLU Base')}: {parentPLU}
                                </b>
                            </small>
                        </small>
                    </div>
                </div>
            </div>
        );
    }

    handleUnavailablePLUs() {
        return null;
    }

    checkIsPluTaken(_plu, id) {
        let plu = Number(_plu);
        let isTaken = false;
        let accommodationPriceLists = this.props.accommodationPriceLists;
        if (
            _.includes(
                _.map(accommodationPriceLists, (acc) => {
                    if (id !== acc.id && acc.pluBase) {
                        return acc.pluBase;
                    }
                }),
                plu
            )
        ) {
            isTaken = true;
        }
        return isTaken;
    }

    render() {
        // let accommodationPriceLists = this.props.accommodationPriceLists
        // accommodationPriceLists = _.sortBy(accommodationPriceLists)
        return (
            <div className="container-fluid">
                <div className="container unselectable">
                    <div className="row justify-content-center">
                        <div className="col-md-10 pl-0 pr-0  ">
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                className="pt-4 pb-4 text-right bg-white "
                            >
                                {this.renderInputField(
                                    'name',
                                    window.translate('Name'),
                                    'text',
                                    this.state.name,
                                    {},
                                    this.nameRef
                                )}
                                {this.renderWeight()}

                                {this.state.weight !== 0 ? this.renderRepeatRules() : null}

                                {(this.state.repeatRules && this.state.repeatRules.noRepeat === true) ||
                                this.state.weight === 0
                                    ? null
                                    : this.getCheckboxInput(
                                          'infinite',
                                          window.translate('Infinite'),
                                          this.state.infinite
                                      )}

                                {this.state.infinite ? null : this.getDatesPicker()}

                                <hr />
                                {this.renderAccomodationPriceRulesInputs()}
                                <hr />
                                {this.getCheckboxInput('isActive', window.translate('Is active'), this.state.isActive)}
                                {/*  {this.getCheckboxInput("blockPromotions", "Block promotions?", this.state.blockPromotions)}*/}

                                {this.state.validationMessage ? (
                                    <div className="alert alert-danger text-center">{this.state.validationMessage}</div>
                                ) : (
                                    ''
                                )}
                                <div className="text-center">
                                    <button onClick={this.onSave.bind(this)} className="btn btn-outline-primary">
                                        {window.translate('Save')}
                                    </button>
                                    <button
                                        onClick={this.showDeleteModal.bind(
                                            this,
                                            this.props.accommodationPriceListId
                                        )}
                                        className="btn  btn-outline-danger ml-1"
                                    >
                                        {window.translate('Delete')}
                                    </button>
                                    <div
                                        onClick={this.cancelHandler.bind(this)}
                                        className="btn btn-outline-secondary ml-1 pointer"
                                    >
                                        {window.translate('Cancel')}
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

// {this.renderInputField("description", "Description", "text", this.state.description)}

export default branch(
    {
        defaultCurrency: ['defaultCurrency'],
        taxes: ['model', 'Tax'],
        accommodationPriceLists: ['model', 'AccommodationPriceList'],
    },
    AccommodationPriceListFormStack
);
