import React from 'react';
import { UserInfo, RoomInfoEditModel } from '@common/modelDefinition';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
//@ts-ignore
import { RouteComponentProps } from 'react-router-dom';
import { getOccupancyOverview } from './condoApi';
import { Reservation } from '../resController'
import { translate } from '@data/translations';

export interface OccupancyOverviewProps {
    loggedUser: UserInfo;
    reservations: Reservation[];
    history: RouteComponentProps['history'];
    rooms: RoomInfoEditModel[];
    translate:any
}

interface roomInfoObject {
    roomInfoId: number;
    condoUserPersonalDaysPerYear: number;
    condoUserPersonalDaysAccomplished: number | null;
    reservationCount: number | null;
}

const OccupancyOverview = (props: OccupancyOverviewProps) => {
    const { loggedUser, rooms } = props;
    const [occupancyOverview, setOccupancyOverview] = React.useState<any>([]);

    React.useEffect(() => {
        const loadData = async () => {
            try {
                if (occupancyOverview.length === 0) {
                    const overview = await getOccupancyOverview(loggedUser.uuid);
                    setOccupancyOverview(overview);
                }
            } catch (err) {
                console.warn(err);
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });
    }, [occupancyOverview.length, loggedUser.uuid]);

    if (loggedUser.role !== 'condoUser') {
        return "This view is only for 'condoUser'!";
    }
    // console.log(occupancyOverview);
    return (
        <div className="bg-white">
            <small>
                <table className="table table-responsive-md">
                    {/* <li className="list-group-item"> */}
                    <thead>
                        <tr className="">
                            <th scope="col" className="">{translate('Room')}</th>
                            <th scope="col" className="">{translate('Total days')}</th>
                            <th scope="col" className="">{translate('Used days')}</th>
                            <th scope="col" className="">{translate('Reservations total')}</th>
                        </tr>
                        </thead>
                        <tbody>
                    {/* </li> */}
                    {rooms.map((r: RoomInfoEditModel) => {
                        const target = occupancyOverview.find((o: roomInfoObject) => o.roomInfoId === r.id);
                        if (target) {
                            const {
                                condoUserPersonalDaysPerYear,
                                condoUserPersonalDaysAccomplished,
                                reservationCount,
                            } = target;
                            return (
                                // <li key={r.id} className="list-group-item">
                               
                                    <tr  key={r.id}  className="">
                                        <td className="">{r.name}</td>
                                        <td className="">{condoUserPersonalDaysPerYear}</td>
                                        <td className="">{condoUserPersonalDaysAccomplished}</td>
                                        <td className="">{reservationCount}</td>
                                    </tr>
                                   
                                // </li>
                            );
                        } else {
                            return null;
                        }
                    })}
                     </tbody>
                </table>
            </small>
        </div>
    );
};

export default branch(
    {
        rooms: ['model', 'RoomInfo'],
        loggedUser: ['authTokenInfo', 'user'],
        // licenceInfo: ["licence"]
    },
    OccupancyOverview
);