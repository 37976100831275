import React from 'react';
import { checkoutReservation, stayOverActions, restorePreviousCheckoutDate } from './controler';
import { showConfirmationModal } from '@components/modal';
import LoadingButton from '@components/buttons/loadingButton';
import moment from 'moment';
import { condoStatusCheck } from '@pages/reservations2/utils';
import { CheckoutOptionsProps, ConfirmationModalProps } from './interfaces';
import { translate } from '@data/translations';
import {
    RoomInfoEditModel,
    PriceOffer,
    ReservationStatus,
    InvoiceModelWithInvoiceItems,
} from '@common/modelDefinition';

import { SRCClearRoomCodes } from '@components/SRCClearRoomCodes';

const ReservationActions = (props: CheckoutOptionsProps) => {
    const {
        invoice,
        roomInfoMap,
        reservation,
        posTerminalInvoices,
        minibarInvoices,
        location,
        reservationId,
        reservationGroupUuid,
    } = props;
    const { pendingInvoice } = invoice;

    const activePosTerminalInvoices = posTerminalInvoices.filter(
        (i: InvoiceModelWithInvoiceItems) => i.connectedInvoiceProcessed !== 1
    );
    const activeMinibarInvoices = minibarInvoices.filter(
        (i: InvoiceModelWithInvoiceItems) => i.connectedInvoiceProcessed !== 1
    );
    const roomInfo: RoomInfoEditModel | null = reservation && roomInfoMap ? roomInfoMap[reservation.roomInfoId] : null;

    if (!reservation) {
        return null;
    }

    const { rememberedAccommodationPriceInfo } = reservation;

    const lastOffer: PriceOffer | null =
        rememberedAccommodationPriceInfo && rememberedAccommodationPriceInfo.length > 0
            ? rememberedAccommodationPriceInfo[rememberedAccommodationPriceInfo.length - 1]
            : null;

    const mismatchDateApplied: boolean =
        lastOffer && !moment(reservation.checkOutTimestamp).isSame(lastOffer.checkOutTimestamp, 'day') ? true : false;

    const { isCondoReservation } = condoStatusCheck(reservation);

    const disableButtonOnCondoReservation: boolean =
        isCondoReservation && (activePosTerminalInvoices.length > 0 || activeMinibarInvoices.length > 0);
    const disableOnRegularReservation: boolean =
        !isCondoReservation &&
        (pendingInvoice || activePosTerminalInvoices.length > 0 || activeMinibarInvoices.length > 0)
            ? true
            : false;

    return (
        <div className="text-center">
            <StayoverOverlapSelection {...props} />

            {reservationId && reservation.statusEnum === ReservationStatus.checkedIn && (
                <React.Fragment>
                    <LoadingButton
                        disabled={disableButtonOnCondoReservation || disableOnRegularReservation}
                        onClick={() => {
                            const confirmationModalProps: ConfirmationModalProps = {
                                title: 'Confirm checkout',
                                callback: () => {
                                    checkoutReservation(reservation, reservationId, reservationGroupUuid, location);
                                },
                            };
                            showConfirmationModal(confirmationModalProps);
                        }}
                        className="btn btn-info p-3 uppercase"
                    >
                        {translate('Checkout')}
                    </LoadingButton>
                    {mismatchDateApplied && (
                        <div className="mt-1">
                            <LoadingButton
                                disabled={!pendingInvoice ? true : false}
                                onClick={() => {
                                    const confirmationModalProps: ConfirmationModalProps = {
                                        title: 'Previous checkout date',
                                        callback: () => {
                                            restorePreviousCheckoutDate(
                                                reservation,
                                                location,
                                                reservationId,
                                                reservationGroupUuid
                                            );
                                        },
                                    };
                                    showConfirmationModal(confirmationModalProps);
                                }}
                                className="btn btn-light btn-sm"
                            >
                                {lastOffer ? (
                                    <small>
                                        <small className="text-danger mr-2">
                                            {translate('Previous checkout date')}
                                        </small>
                                        {moment(lastOffer.checkOutTimestamp).format('ll')}
                                    </small>
                                ) : null}
                            </LoadingButton>
                        </div>
                    )}
                </React.Fragment>
            )}

            {reservationId && reservation.statusEnum === ReservationStatus.closed && roomInfo && (
                <SRCClearRoomCodes roomInfo={roomInfo} />
            )}
        </div>
    );
};

const StayoverOverlapSelection = (props: CheckoutOptionsProps) => {
    const { stayoverOverlap, reservation } = props;
    const { overlapReservations } = stayoverOverlap;

    if (overlapReservations.length === 0 || !reservation) {
        return null;
    }

    let optionCheck: number | null =
        stayoverOverlap.overlapReservations.length > 0 && stayoverOverlap.type === 'checkOut'
            ? stayoverOverlap.option
            : null;

    const arrayOfActions = [
        translate('Check out now and move the next reservation.'),
        translate('Check out before the start of the next reservation.'),
        translate('Check out at initial checkout.'),
        translate('Set reservations manually.'),
    ];

    let listOfActions = [];
    for (let i = 0; i < arrayOfActions.length; i++) {
        listOfActions.push(
            <li
                key={i + 1}
                onClick={() => {
                    stayOverActions(stayoverOverlap.overlapReservations, i + 1, 'checkOut');
                }}
                className="list-group-item p-2 pointer"
            >
                {arrayOfActions[i]}
                {optionCheck === i + 1 ? (
                    <i className="fa fa-2x fa-check-square-o pull-right mr-2" />
                ) : (
                    <i className="fa fa-2x fa-square-o pull-right mr-2" />
                )}
            </li>
        );
    }

    if (
        stayoverOverlap.type === 'checkOut' &&
        reservation.statusEnum === ReservationStatus.checkedIn &&
        stayoverOverlap.option !== 4
    ) {
        return (
            <div className="row mb-2">
                <div className="col-md-12">
                    <ul className="list-group mt-2 text-left">{listOfActions}</ul>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

export default ReservationActions;
export { StayoverOverlapSelection };