// import * as React from 'react';

export const pageDown = async (page, loadData) => {
    if (page > 0) {
        let _page = page - 1;
        await loadData(_page);
    }
};

export const pageUp = async (page, loadData, list) => {
    if (list.length > 0) {
        let _page = page + 1;
        await loadData(_page);
    }
};
