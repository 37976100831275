import React from 'react';
import { useBranch } from 'baobab-react/hooks';

const ListRoomsOrPricelistByTags = (props) => {
    const { acp, roomInfo } = useBranch({
        acp: ['monkeys', 'filteredAccommodationPriceLists'],
        roomInfo: ['model', "RoomInfo"]
    })

    const tagsOnRoom = props.tags ? props.tags.split(";") : []
    const tagsOnAcp = props.acp ? props.acp.split(";") : []

    const priceList = [], roomList = []
    acp.forEach(a => {
        if (a.tags !== null && !a.isDeleted && a.parentId === null) {
            const tags = a.tags.split(";")
            let hitCount = 0;
            tags.forEach((t) => {
                if (tagsOnRoom.includes(t)) {
                    hitCount++;
                }
            });

            if (hitCount === tagsOnRoom.length) {
                priceList.push(a)
            }
        }
    })

    roomInfo.forEach(a => {
        if (a.tags !== null) {
            const tags = a.tags.split(";")
            let hitCount = 0;
            tags.forEach((t) => {
                if (tagsOnAcp.includes(t)) {
                    hitCount++;
                }
            });
            if (hitCount === tagsOnAcp.length) {
                roomList.push(a)
            }
        }
    })

    const listToShow = props.acp ? roomList : (props.tags ? priceList : [])

    return (
        <ul className="list-group mx-3">
            {
                listToShow.map((t, key) => {
                    let tags = t.tags ? t.tags.split(';') : [];
                    tags = tags.map((t, key) => {
                        return (
                            <span key={t.id + t + key} className="badge badge-primary mr-1">
                                {t}
                            </span>
                        );
                    });
                    return (
                        <li
                            className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}
                            key={key}
                        >
                            <div className="col-3"><small>{t.name}</small></div>
                            <div className="col-9">{tags}</div>
                        </li>
                    )
                })
            }
        </ul>
    );
};

export default ListRoomsOrPricelistByTags;
