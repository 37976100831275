import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import rest from '../../../data/restWrapper';
import baobab from '../../../data/state';
import Image from '../../../components/image';

/* var minibarLogTemplate = {
    roomInfoId: null,
    posItemId: null,
    previousState: null,
    newState: null,
    userUuid: null,
    logType: null,
}; */

function getPosItemIdsFromRoomInfo(record) {
    let result = null;
    if (record === null) {
        result = null;
    }
    if (_.isString(record)) {
        result = JSON.parse(record);
    } else if (_.isArray(record)) {
        result = _.clone(record);
    }
    return result;
}

const MinibarForm = (props) => {
    const [posItemIdsState, setPosItemIds] = useState([]);
    const [searchTermState, setSearchTerm] = useState('');
    const [servicesFromSearchBoxState, setServicesFromSearchBox] = useState([]);
    const [pageState, setPage] = useState(0);
    const [rowsState] = useState(10);
    const [posItemsState, setPosItems] = useState([]);
    // const [posItemsQuantityState, setPosItemsQuantity] = useState([]);
    // const [itemState, setItem] = useState('');

    useEffect(() => {
        const fetchData = async () => {
            let posItemIds = getPosItemIdsFromRoomInfo(props.roomInfo.posItemIds);
            setPosItemIds(posItemIds);

            let posItems = await rest('/PosItem?page=' + pageState + '&rows=' + rowsState);
            setPosItems(posItems);
        };

        fetchData();
    }, [props.roomInfo.posItemIds, pageState, rowsState]);

    const getPageData = async (page = pageState) => {
        let posItems = await rest('/PosItem?page=' + page + '&rows=' + rowsState);
        setPosItems(posItems);
        setPage(page);
    };

    const onSearch = async (e) => {
        try {
            let path = '/searchService?search=' + e.target.value;
            let searchTerm = e.target.value;
            let servicesFromSearchBox = await rest(path);
            setServicesFromSearchBox(servicesFromSearchBox);
            setSearchTerm(searchTerm);
        } catch (err) {
            console.log(err);
        }
    };

    const pageDown = async () => {
        if (pageState > 0) {
            let page = pageState - 1;
            await getPageData(page);
        }
    };

    const pageUp = async () => {
        if (posItemsState.length > 0) {
            let page = pageState + 1;
            await getPageData(page);
        }
    };

    const handleChecked = async (itemId, e) => {
        try {
            let posItemIds = posItemIdsState ? _.clone(posItemIdsState) : [];

            if (posItemIds.includes(itemId)) {
                // odznacavanje
                posItemIds = _.filter(posItemIds, (id) => {
                    return id !== itemId;
                });
            } else {
                posItemIds.push(itemId);
            }

            setPosItemIds(posItemIds);
        } catch (error) {
            console.warn(error);
        }
    };

    const cancelHandler = () => {
        baobab.root.select(['modalData', 'display']).set(false);
        baobab.root.select(['modalData', 'content']).set(null);
    };

    const saveData = async () => {
        try {
            let roomInfo = _.clone(props.roomInfo);
            let posItemIds = posItemIdsState ? posItemIdsState : [];

            if (roomInfo.id !== null) {
                let id = roomInfo.id;
                await rest('/RoomInfo/' + id, 'PUT', { posItemIds: JSON.stringify(posItemIds) });
            }

            props.fetchData();
            cancelHandler();
        } catch (err) {
            console.warn(err);
        }
    };

    const { roomInfo } = props;

    let posItems = searchTermState.length > 0 ? servicesFromSearchBoxState : posItemsState;

    let posItemsList = _.map(posItems, (s, key) => {
        if (s.enabled) {
            return (
                <li key={s.id + key} className="list-group-item">
                    <div className="row">
                        <div className="d-none d-md-block col-md-2 col-lg-2">
                            <span className="badge badge-success">{s.tags}</span>
                        </div>
                        <div className="d-none d-md-block col-md-2 col-lg-2">{s.plu}</div>
                        <div className="d-none d-lg-block col-lg-2">
                            {/* <img
                                src={s && s.id !== null ? '/getPosItemImage/' + s.id : imagePlaceholder}
                                alt="thumbnail"
                                style={{ height: '50px', width: '50px' }}
                            /> */}
                            <Image posItemId={s.id} style={{ height: '50px', width: '50px' }} />
                        </div>
                        <div className="d-md-block col-5 col-sm-5 col-md-4 col-lg-3">{s.name}</div>
                        <div className="d-none d-lg-block col-md-3 col-lg-2">
                            <span className="badge badge-success">{s.category}</span>
                        </div>
                        <div className="d-xs-block col-5 col-sm-5 col-md-2 col-lg mt-1 mb-1 align-right">
                            <input
                                key={s.id}
                                type="checkbox"
                                className="checkmark"
                                checked={roomInfo && _.includes(posItemIdsState, s.id) ? true : false}
                                onChange={handleChecked.bind(this, s.id)}
                            />
                        </div>
                    </div>
                </li>
            );
        }
    });

    return (
        <div className="container">
            <div className="row justify-content-center">
                <div className="d-flex col-sm-12 col-md-11 col-lg-9 col-xl-8 m-auto">
                    <div className="m-auto align-self-end">
                        <button onClick={pageDown.bind(this)} className="btn btn-sm btn-outline-primary mr-2">
                            {window.translate('prev')}
                        </button>
                        <small className="mt-1">
                            {window.translate('page')} {pageState + 1}
                        </small>
                        <button onClick={pageUp.bind(this)} className="btn btn-sm btn-outline-primary ml-2">
                            {window.translate('next')}
                        </button>
                    </div>

                    <div className="mr-3 align-self-end">
                        <input
                            onChange={onSearch.bind(this)}
                            placeholder={window.translate('Name or code')}
                            type="text"
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
                <div className="col-md-10 pl-0 pr-0 mt-3 ">
                    <ul className="list-group list-group-flush border-top">{posItemsList}</ul>
                </div>
                <div className="text-center mb-4 pt-3">
                    <button data-dismiss="modal" onClick={saveData.bind(this)} className="btn btn-outline-primary">
                        {window.translate('Save')}
                    </button>
                    <button
                        data-dismiss="modal"
                        onClick={cancelHandler.bind(this)}
                        className="btn btn-outline-secondary ml-2"
                    >
                        {window.translate('Cancel')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        loggedUser: ['authTokenInfo', 'user'],
    },
    MinibarForm
);
