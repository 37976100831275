import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { userFrendlyAlarmAndEventsNames, alarmAndEventCSSStyling } from '../../data/SRCAlarmAndAlertController';
import SosResponseForm from './sosResponseForm';
import { hardwareCloseRoomEventTicket, forceDiscardRoomEventTicket } from './api';
import PermissionCheck from '../../components/PermissionCheck';

const handleMuteClick = async (event, roomInfo, e) => {
    hardwareCloseRoomEventTicket(roomInfo.remoteDeviceId, event);
};

function HWTicketActions(props) {
    const { event, roomInfo } = props;

    return (
        <div className="text-center">
            <hr />

            <button
                onClick={() => {
                    handleMuteClick(event, roomInfo);
                }}
                className="btn btn-primary mr-2"
            >
                {window.translate('HW MUTE ALARM')}
            </button>
            <br />

            <small>{window.translate('NOTE: Sensor alarms can not be muted')}.</small>
        </div>
    );
}
function SWTicketActions(props) {
    const { event } = props;
    const [pending, setPending] = React.useState(false);
    const [notes, setNotes] = React.useState(window.translate('FORCE CLOSE TICKET'));

    if (pending) {
        return (
            <div className="text-center">
                {window.translate('Please enter the reason for force closing this ticket')}:
                <textarea
                    className="form-control mt-2"
                    value={notes}
                    onChange={(e) => {
                        setNotes(e.target.value);
                    }}
                ></textarea>
                <button
                    onClick={() => {
                        forceDiscardRoomEventTicket(event.id, notes);
                        setNotes(window.translate('FORCE CLOSE TICKET'));
                        setPending(false);
                    }}
                    className="btn btn-primary mt-2"
                >
                    {window.translate('Close ticket')}
                </button>
            </div>
        );
    } else {
        return (
            <div className="text-center">
                <button
                    onClick={() => {
                        setPending(true);
                    }}
                    className="btn btn-danger mr-2"
                >
                    {window.translate('SW MUTE ALARM')}
                </button>
                <br />

                <small>
                    {window.translate('NOTE: This will force close ticket. Use only if hardware is malfunctioning')}.
                </small>
            </div>
        );
    }
}

function EventRespondModal(props) {
    const { event, reservation = null, rooms = [] } = props;
    const roomInfo = rooms.find((r) => r.id === event.roomInfoId);
    const customer = reservation ? reservation.Customer : {};
    const company = reservation ? reservation.Company : {};

    if (event.ruleName === 'alarm_sos') {
        return (
            <SosResponseForm handleMuteClick={handleMuteClick} roomInfo={roomInfo} {...props}>
                <PermissionCheck name="roomRoomTicket_SWEventDismiss">
                    <SWTicketActions event={event} roomInfo={roomInfo} />
                </PermissionCheck>
            </SosResponseForm>
        );
    } else {
        return (
            <div>
                <h3 className={alarmAndEventCSSStyling[props.event.ruleName] + ' text-center'}>
                    {userFrendlyAlarmAndEventsNames[props.event.ruleName]}
                </h3>
                <h3 className="text-center">
                    {roomInfo.name} {roomInfo.roomPhone}
                </h3>
                {roomInfo.roomPhone ? (
                    <h3 className="text-center">
                        {window.translate('ROOM LOCAL')}: {roomInfo.roomPhone}{' '}
                    </h3>
                ) : null}

                {customer?.id !== undefined ? (
                    <div className="row text-center">
                        <div className="col-md-6">
                            <h5>
                                {window.translate('GUEST')}: {customer.firstName} {customer.lastName}
                            </h5>
                        </div>
                        <div className="col-md-6">
                            <h5>
                                {window.translate('GUEST PHONE')}: {customer.phoneOrMobile}
                            </h5>
                        </div>
                    </div>
                ) : null}

                {company?.id !== undefined ? (
                    <div className="row text-center">
                        <div className="col-md-6">
                            <h5>
                                {window.translate('Company')}: {company.name}
                            </h5>
                        </div>
                    </div>
                ) : null}

                <PermissionCheck name="roomRoomTicket_HWEventDismiss">
                    <HWTicketActions event={event} roomInfo={roomInfo} />
                </PermissionCheck>
                <PermissionCheck name="roomRoomTicket_SWEventDismiss">
                    <SWTicketActions event={event} roomInfo={roomInfo} />
                </PermissionCheck>
            </div>
        );
    }
}

export default branch(
    {
        //do not use baobab here...
        rooms: ['model', 'RoomInfo'],
    },
    EventRespondModal
);
