import React, { useEffect, useState } from 'react';
import { deleteModelData } from '../../data/actions/modelActions';
import { branch } from 'baobab-react/higher-order';
import { setModalContent } from '@components/modal';
import Delete from '../../components/confirmModal';
import PageTitle from '../../components/pageTitle';
import baobab from '../../data/state';
import getRoomSRCStatusIcon from '../../data/controllers/SRCStatus';
import RoomAttributes from '../../components/attributes';
import FeatureFlag from '../../components/FeatureFlag';
import PermissionCheck from '../../components/PermissionCheck';
import { toast } from 'react-toastify';

function RoomsAdministration(props) {
    const [tagFilter, setTagFilter] = useState(null);
    const [attributesFilter, setAttributesFilter] = useState([]);
    const [showTags, setShowTags] = useState(true);
    const [showAttributes, setShowAttributes] = useState(true);
    const [dataChange, setDataChange] = useState(false);
    const [searchTermState, setSearchTerm] = useState('');

    useEffect(() => {}, [dataChange]);

    const searchRoom = (e) => {
        setSearchTerm(e.target.value);
    };

    const editRoom = (roomInfo) => {
        props.history.push('/roomsAdministration/' + roomInfo.id);
    };

    const roomMinibarLog = (roomInfo) => {
        props.history.push('/roomsAdministration/minibar/' + roomInfo.id);
    };

    const deleteItem = async (itemId) => {
        try {
            await deleteModelData('RoomInfo', itemId);
            toast(window.translate('Room deleted!'), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
            const filterRooms = props.rooms.filter((r) => r.id !== itemId);
            baobab.root.select(['monkeys', 'parsedRoomInfo']).set(filterRooms);
            setDataChange(!dataChange);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = async (roomId) => {
        setModalContent(
            <Delete actionForYes={deleteItem.bind(this, roomId)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const filterRoomsByAttribute = (rooms) => {
        let filteredRooms = rooms.map((r) => {
            if (attributesFilter.length > 0) {
                let eject = false;
                attributesFilter.forEach((att) => {
                    if (
                        (r.roomAttributes && !JSON.parse(r.roomAttributes).includes(att)) ||
                        r.roomAttributes === null
                    ) {
                        eject = true;
                        return true;
                    }
                });
                if (eject) {
                    return null;
                } else {
                    return r;
                }
            } else {
                return r;
            }
        });
        filteredRooms = filteredRooms.filter((i) => {
            return i !== null;
        });
        return filteredRooms;
    };

    const handleAttributesSelect = (attributeId) => {
        if (attributeId === null) {
            setAttributesFilter([]);
        } else {
            let _attributesFilter = [...attributesFilter];
            if (_attributesFilter.includes(attributeId)) {
                _attributesFilter = _attributesFilter.filter((att) => att !== attributeId);
            } else {
                _attributesFilter.push(attributeId);
            }
            setAttributesFilter(_attributesFilter);
        }
    };

    const { licenceInfo } = props;

    const licenceLimitText = `LICENCE LIMIT ${licenceInfo.currentRoomsCount} of ${licenceInfo.licence?.decodedLicence?.roomsCount}`;

    let filteresRooms = tagFilter === null ? props.rooms : props.rooms.filter((r) => r.tags.includes(tagFilter));
    filteresRooms = filterRoomsByAttribute(filteresRooms);

    filteresRooms = filteresRooms.filter((r) => r.name.toLowerCase().includes(searchTermState.toLowerCase()));

    return (
        <div className="">
            <PageTitle />
            <div className="">
                <button
                    onClick={() => {
                        props.history.push('/menu/system');
                    }}
                    className="btn btn-sm btn-secondary  mr-2 mb-1"
                >
                    {window.translate('GO BACK')}
                </button>
                <button
                    disabled={licenceInfo.roomsCountExceeded}
                    onClick={() => {
                        props.history.push('/newRoomInfo');
                    }}
                    className="btn btn-sm btn-primary mr-2 mb-1"
                >
                    {licenceInfo.roomsCountExceeded ? licenceLimitText : window.translate('ADD NEW')}
                </button>

                <FeatureFlag flag="hardware">
                    <FeatureFlag flag="selfCheckInOptions">
                        <button
                            onClick={() => {
                                props.history.push('/qrCodeGenerator');
                            }}
                            className="btn btn-sm btn-primary mr-2  mb-1"
                        >
                            {' '}
                            <i className="fa fa-qrcode pr-1"></i>
                            {'QR generator'}
                        </button>
                    </FeatureFlag>
                </FeatureFlag>

                <button
                    onClick={() => {
                        props.history.push('/roomAttributes');
                    }}
                    className="btn btn-sm btn-primary mr-2  mb-1"
                >
                    {window.translate('Room attributes')}
                </button>

                <button
                    onClick={() => {
                        props.history.push('/roomsAndPriceLists');
                    }}
                    className="btn btn-sm btn-primary mr-2  mb-1"
                >
                    <i className="fa fa-link" /> {window.translate('Pricelist linking')}
                </button>

                <input
                    type="text"
                    style={{ width: '300px' }}
                    className="form-control form-control-sm p-2 col-3 d-inline pull-right"
                    onChange={(e) => searchRoom(e)}
                    placeholder={window.translate('Search by room name')}
                ></input>
            </div>

            <div className="row">
                <div className="col-md-3 ">
                    <div
                        className="py-3  pointer"
                        onClick={() => {
                            setShowTags(!showTags);
                        }}
                    >
                        {window.translate('Tags')}:
                        <i className={showTags ? 'pull-right fa fa-angle-up' : 'pull-right fa fa-angle-down'}></i>
                    </div>
                    <div className={showTags ? 'bg-white' : 'd-none'}>
                        {props.roomTags.length > 0 ? (
                            <button
                                onClick={() => {
                                    setTagFilter(null);
                                }}
                                className={
                                    tagFilter === null
                                        ? 'btn btn-sm btn-secondary mr-2 mt-1 mb-1'
                                        : 'btn btn-sm btn-outline-secondary mr-2 mt-1 mb-1'
                                }
                            >
                                {window.translate('No tags')}
                            </button>
                        ) : null}

                        {props.roomTags &&
                            props.roomTags.map((tag) => {
                                return (
                                    <button
                                        onClick={() => {
                                            setTagFilter(tag);
                                        }}
                                        className={
                                            tagFilter === tag
                                                ? 'btn btn-sm btn-secondary mr-2 mt-1 mb-1'
                                                : 'btn btn-sm btn-outline-secondary mr-2 mt-1 mb-1'
                                        }
                                        key={tag}
                                    >
                                        {tag}
                                    </button>
                                );
                            })}
                    </div>

                    <div
                        className="py-3  pointer"
                        onClick={() => {
                            setShowAttributes(!showAttributes);
                        }}
                    >
                        {window.translate('Attributes')}:{' '}
                        <i className={showAttributes ? 'pull-right fa fa-angle-up' : 'pull-right fa fa-angle-down'}></i>
                    </div>
                    <div className={showAttributes ? 'bg-white' : 'd-none'}>
                        {props.attributes &&
                            props.attributes.map((att, key) => {
                                return (
                                    <button
                                        key={key}
                                        onClick={() => {
                                            handleAttributesSelect(att.id);
                                        }}
                                        className={
                                            attributesFilter.includes(att.id)
                                                ? 'btn btn-sm btn-secondary mr-2 mt-1 mb-1'
                                                : 'btn btn-sm btn-outline-secondary mr-2 mt-1 mb-1'
                                        }
                                    >
                                        <img
                                            style={{ width: '14px', height: '14px', marginBottom: '3px' }}
                                            className=""
                                            src={att.icon}
                                            alt=""
                                        />{' '}
                                        {window.translate(att.name)}
                                    </button>
                                );
                            })}
                    </div>
                </div>

                <div className="col-md-9 pl-0">
                    <div className="list-group mt-3 mx-3">
                        <small>
                            <div className="px-1 py-2 row shadow-sm bg-white">
                                <div className="col-6 col-sm-3">
                                    {window.translate('Name') +
                                        ', ' +
                                        window.translate('Description') +
                                        ', ' +
                                        window.translate('Beds') +
                                        ', ' +
                                        window.translate('Attributes')}
                                </div>
                                <div className="col-6 col-sm-3 text-right">{window.translate('Room settings')}</div>
                                <div className="col-6 col-sm-3">{window.translate('Tags')}</div>
                                <div className="col-6 col-sm-3"></div>
                            </div>
                        </small>
                    </div>

                    <ul className="list-group mt-2 mx-3">
                        {filteresRooms.map((r, index) => {
                            return (
                                <RoomInfoLine
                                    history={props.history}
                                    key={index}
                                    r={r}
                                    index={index}
                                    showDeleteModal={showDeleteModal.bind(this)}
                                    editRoom={editRoom.bind(this)}
                                    match={props.match}
                                    attributes={props.attributes}
                                    // buttonPermissions={props.buttonPermissions}
                                    roomMinibarLog={roomMinibarLog.bind(this)}
                                />
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
}

const RoomInfoLine = (props) => {
    const {
        r,
        index,
        history,
        editRoom,
        match,
        attributes,
        // buttonPermissions,
        showDeleteModal,
        roomMinibarLog,
    } = props;
    const roomSRCStatusIcon = getRoomSRCStatusIcon(r);
    let showHWRelatedElements = r.remoteDeviceId ? true : false;
    let extendedRoomSettings = (
        <div>
            <button
                title={window.translate('Extended room settings')}
                onClick={() => {
                    if (r.remoteDeviceId !== null) {
                        history.push('/srcSettings/' + r.id + '/' + r.remoteDeviceId);
                    }
                }}
                type="button"
                className={'btn btn-primary ml-1 btn-sm pull-right'}
            >
                {roomSRCStatusIcon}
                <i className="fa fa-cogs ml-1" aria-hidden="true"></i>
            </button>
        </div>
    );

    if (showHWRelatedElements === false) {
        extendedRoomSettings = null;
    }

    let beds = [];
    let spareBeds = [];
    for (let i = 0; i < r.bedCount; i++) {
        beds.push(<i key={i} className="fa fa-bed mr-1" />);
    }

    for (let i = 0; i < r.spareBedCount; i++) {
        spareBeds.push(<i key={i} className="fa fa-bed mr-1 text-info" />);
    }
    let tags = r.tags.map((t, key) => {
        return (
            <span key={r.id + t + key} className="badge badge-primary mr-1">
                {t}
            </span>
        );
    });

    return (
        <li key={index} className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
            <div className="col-6 col-sm-3 elipsis">
                <span style={{ fontSize: '1.5em' }}>{r.name}</span>
                <small>
                    {r.availableForRent ? (
                        <small>&ensp;{r.description}</small>
                    ) : (
                        <span className="text-warning text-center ml-1">{window.translate('OUT OF SERVICE')}</span>
                    )}
                </small>
                <br /> {beds} {spareBeds} <RoomAttributes room={r} attributes={attributes} />
            </div>
            <div className="col-6 col-sm-3 elipsis">
                <FeatureFlag flag="hardware">
                    <PermissionCheck name="roomInfo_extendedRoomSettings">{extendedRoomSettings}</PermissionCheck>
                </FeatureFlag>
            </div>
            <div className="col-6 col-sm-3 elipsis">{tags}</div>
            <div className="col-6 col-sm-3 elipsis">
                <div className="pt-2 pr-2">
                    <button
                        onClick={editRoom.bind(this, r)}
                        className={
                            parseInt(match.params.roomInfoId, 10) === r.id
                                ? 'invisible'
                                : 'btn btn-sm btn-outline-dark pull-right ml-1 mb-1'
                        }
                    >
                        <i className="fa fa-edit"></i>
                    </button>
                    <FeatureFlag flag="integrityDeleteButton">
                        <button
                            onClick={showDeleteModal.bind(this, r.id)}
                            className={
                                parseInt(match.params.roomInfoId, 10) === r.id
                                    ? 'invisible'
                                    : 'btn btn-sm btn-outline-danger pull-right ml-1 mb-1'
                            }
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    </FeatureFlag>
                    <FeatureFlag flag="minibar">
                        <button
                            onClick={roomMinibarLog.bind(this, r)}
                            className={
                                parseInt(match.params.roomInfoId, 10) === r.id
                                    ? 'invisible'
                                    : 'btn btn-sm btn-info ml-1 pull-right mb-1'
                            }
                        >
                            {window.translate('MINIBAR')}
                        </button>
                    </FeatureFlag>
                </div>
            </div>
        </li>
    );
};

export default branch(
    {
        roomTags: ['monkeys', 'roomTags'],
        rooms: ['monkeys', 'parsedRoomInfo'],
        // buttonPermissions: ['authTokenInfo', 'permissions', 'buttons', 'roomInfo'],
        attributes: ['model', 'RoomAttribute'],
        licenceInfo: ['licence'],
    },
    RoomsAdministration
);
