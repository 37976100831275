import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import pubsub from '../../../data/pubsub';
import rest from '../../../data/restWrapper';
import PageTitle from '../../../components/pageTitle';
import { setModalContent } from '@components/modal';
import ExchangeRatesOnDate from './exchangeRatesOnDate';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import Delete from '../../../components/confirmModal';
import { deleteModelData } from '../../../data/actions/modelActions';
class ExchangeRate extends Component {
    constructor() {
        super();
        this.exchangeRateRefs = [];

        this.state = {
            //currencies: [],
            exchangeRates: null,
            selectedDate: moment().valueOf(),
        };
    }

    async componentDidMount() {
        this.redrawComponent();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.currencies !== this.props.currencies) {
            this.redrawComponent();
        }
    }

    async redrawComponent() {
        let currencies = this.props.currencies;
        let latestExchangeRates = await rest('/getLatestExchangeRates');
        let exchangeRates = [];
        if (_.isEmpty(latestExchangeRates)) {
            exchangeRates = _.map(currencies, (cu) => {
                if (cu.isEnabled) {
                    return {
                        id: null,
                        exchangeRate: null,
                        validFromTimestamp: null,
                        confirmed: true,
                        currencyId: cu.id,
                        shortName: cu.shortName,
                    };
                }
            });
            this.setState({ isEmptyData: true });
        } else {
            exchangeRates = _.map(latestExchangeRates, (e) => {
                if (e.shortName) {
                    return {
                        id: null,
                        exchangeRate: e.exchangeRate,
                        validFromTimestamp: null,
                        confirmed: true,
                        currencyId: e.currencyId,
                        shortName: e.shortName,
                    };
                }
            });
            if (exchangeRates.length !== currencies.length) {
                let missingCurrency = _.filter(currencies, (cu) => {
                    return cu.isEnabled && !_.includes(_.map(exchangeRates, 'currencyId'), cu.id);
                });
                exchangeRates = exchangeRates.concat(
                    _.map(missingCurrency, (mc) => {
                        return {
                            id: null,
                            exchangeRate: null,
                            validFromTimestamp: null,
                            confirmed: true,
                            currencyId: mc.id,
                            shortName: mc.shortName,
                        };
                    })
                );
            }
            this.setState({ isEmptyData: false });
        }
        exchangeRates = _.sortBy(
            _.filter(exchangeRates, (e) => {
                return e !== undefined;
            }),
            'currencyId'
        );
        this.clearValidationRefs();
        this.exchangeRateRefs = [];
        for (let i = 0; i < exchangeRates.length; i++) {
            this.exchangeRateRefs.push(React.createRef());
        }
        this.setState({ exchangeRates: exchangeRates });
    }

    inputHandler(key, e) {
        let exchangeRates = _.clone(this.state.exchangeRates);
        exchangeRates[key] = _.clone(this.state.exchangeRates[key]);
        switch (e.target.type) {
            default:
            case 'text':
                exchangeRates[key][e.target.name] = e.target.value;
                break;
            case 'number':
                if (_.isNumber(Number(e.target.value))) {
                    exchangeRates[key][e.target.name] = Number(e.target.value);
                } else {
                    return null;
                }
                break;
            case 'checkbox':
                exchangeRates[key][e.target.name] = !e.target.value;
                break;
        }
        this.setState({ exchangeRates: exchangeRates });
    }

    clearValidationRefs() {
        _.forEach(this.exchangeRateRefs, (e) => {
            if (e.exchangeRateRefs) {
                e.exchangeRateRefs.className = e.exchangeRateRefs.className.replace('is-invalid', '');
            }
        });
    }

    getValidationInputs(data) {
        let invalidInputs = [];
        let validInputs = [];
        let baselineCurrencyInfo = _.find(this.props.currencies, (d) => d.shortName === this.props.defaultCurrency);
        for (let i = 0; i < data.length; i++) {
            if (data[i].exchangeRate === null || !_.isNumber(data[i].exchangeRate) || data[i].exchangeRate <= 0) {
                invalidInputs.push(this.exchangeRateRefs[i]);
            }
            if (data[i].currencyId === baselineCurrencyInfo.id && Number(data[i].exchangeRate) !== 1) {
                invalidInputs.push(this.exchangeRateRefs[i]);
            } else {
                validInputs.push(this.exchangeRateRefs[i]);
            }
        }
        return { invalidInputs, validInputs };
    }

    markInvalidInputRefs(validationResults) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        _.forEach(invalidInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        _.forEach(validInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }

    async onSave() {
        try {
            let data = _.clone(this.state.exchangeRates);
            let validationResults = this.getValidationInputs(data);
            this.markInvalidInputRefs(validationResults);
            if (validationResults.invalidInputs.length === 0) {
                for (let i = 0; i < data.length; i++) {
                    data[i].validFromTimestamp = new Date().getTime();
                    if (data[i]?.id !== null) {
                        let id = data[i].id;
                        delete data[i].id;
                        await rest('/ExchangeRate/' + id, 'PUT', data[i]);
                    } else {
                        await rest('/ExchangeRate', 'POST', data[i]);
                    }
                }
                pubsub.emit('toastMessage', window.translate("Saved successfully"));
            }
        } catch (err) {
            pubsub.emit('toastMessage', err.message, 'error');
        }
    }

    renderInputField(_name, key, type = 'text', value) {
        return (
            <input
                ref={this.exchangeRateRefs[Number(key)]}
                id={'pl-' + _name}
                name={_name}
                placeholder={_name}
                autoComplete="off"
                value={value ? value : 0}
                onChange={this.inputHandler.bind(this, key)}
                type={type}
                className="form-control"
            />
        );
    }

    async onDelete(model) {
        try {
            let data = await rest('/getLatestExchangeRates');
            for (let i = 0; i < data.length; i++) {
                await deleteModelData(model, data[i].id);
            }
            pubsub.emit('toastMessage', window.translate("Deleted successfully"));
            this.redrawComponent();
        } catch (error) {
            pubsub.emit('toastMessage', error.message, 'error');
        }
    }

    showDeleteModal(model) {
        setModalContent(
            <Delete actionForYes={this.onDelete.bind(this, model)} />,
            window.translate("Confirm"),
            false,
            'modal-md'
        );
    }

    handleSelectDateChange = (selectedDate) => {
        selectedDate = new Date(
            selectedDate.getFullYear(),
            selectedDate.getMonth(),
            selectedDate.getDate(),
            0,
            0,
            0
        ).getTime();
        setModalContent(
            <ExchangeRatesOnDate date={selectedDate} />,
            window.translate("Exchange rates on ") + moment(selectedDate).format('ll'),
            false,
            'modal-lg'
        );
    };
    changeMonth = (month) => {
        this.setState({ month: month });
    };

    render() {
        const { selectDate } = this.state.selectedDate;
        const { defaultCurrency } = this.props;

        if (defaultCurrency === undefined) {
            return (
                <div className="container-fluid">
                    <div className="container">{window.translate("Please setup default currency in general settings first.")}</div>
                </div>
            );
        }

        return (
            <div className="container-fluid">
                <div className="container">
                    <PageTitle />
                    <div className="row justify-content-center  ">
                        <div className="d-flex col-sm-12 col-md-11 col-lg-9 col-xl-8 m-auto">
                            <button
                                onClick={() => {
                                    this.props.history.push('/priceLists/currencies');
                                }}
                                className="btn btn-sm btn-secondary"
                            >
                                {window.translate("GO BACK")}
                            </button>
                            <div className="InputSelectDate ml-2">
                                <label className="pr-2">
                                    <small>{window.translate("EX. RATES ON DAY")}</small>
                                </label>
                                <DayPickerInput
                                    value={selectDate}
                                    placeholder={window.translate("Select Date")}
                                    format="LLL"
                                    inputProps={{ className: 'form-control form-control-sm' }}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    onDayChange={this.handleSelectDateChange}
                                    dayPickerProps={{
                                        onMonthChange: this.changeMonth,
                                        modifiers: {
                                            disabled: this.state.disabledDates,
                                        },
                                    }}
                                />
                            </div>
                        </div>
                    </div>
                    <div className="row justify-content-center">
                        <div className="col-sm-12 col-md-11 col-lg-9 col-xl-8">
                            <h3 className="mt-2">
                                {window.translate("Default currency")}: {this.props.defaultCurrency}
                            </h3>
                            <small>{window.translate("Default currency must have exchange rate of 1 (one).")}</small>

                            <ul className="list-group mt-2">
                                {_.map(this.state.exchangeRates, (cu, key) => {
                                    return (
                                        <li key={key} className={'list-group-item '}>
                                            <div className="row align-items-center">
                                                <div className="col-md-6">{cu.shortName}</div>
                                                <div className="col-md-6">
                                                    {this.renderInputField(
                                                        'exchangeRate',
                                                        key,
                                                        'number',
                                                        cu.exchangeRate
                                                    )}
                                                </div>
                                            </div>
                                        </li>
                                    );
                                })}
                            </ul>

                            <div className="text-center mt-2">
                                <button onClick={this.onSave.bind(this)} className="btn btn-sm btn-outline-primary">
                                    {window.translate("Save")}
                                </button>
                                <button
                                    disabled={this.state.isEmptyData ? true : false}
                                    onClick={this.showDeleteModal.bind(this, 'ExchangeRate')}
                                    className="btn btn-sm btn-outline-danger ml-2"
                                >
                                    {window.translate("Delete")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        currencies: ['model', 'Currency'],
        defaultCurrency: ['defaultCurrency'],
    },
    ExchangeRate
);
