import React, { Component } from 'react';
import moment from 'moment/min/moment-with-locales';
import { FixedSizeList as List } from 'react-window';
import _ from 'lodash';
import numbro from 'numbro';
import { Link } from 'react-router-dom';
import { branch } from 'baobab-react/higher-order';
import { pageUp, pageDown } from '@components/pagination';

function getPos(el) {
    // yay readability
    for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
    return { x: lx, y: ly };
}

const SelectFilter = (props) => {
    const { headerItem, itemsArray, filters, selectItem, getLabel } = props;
    let items = [];
    let selectOptions = [];
    let index = 0;
    _.forEach(itemsArray, (resItem) => {
        index++;
        if (!_.find(items, { customerId: resItem[headerItem.filterKey] })) {
            if (resItem[headerItem.filterKey] || resItem[headerItem.filterKey] === 0) {
                items.push({ customerId: resItem[headerItem.filterKey], name: headerItem.filterKey });
                selectOptions.push(
                    <option key={`indx_${index}`} value={resItem[headerItem.filterKey]}>
                        {getLabel(headerItem, resItem)}
                    </option>
                );
            }
        }
    });
    let selectElement = (
        <select
            style={{ width: '100%', borderRadius: '5px', background: 'white' }}
            value={filters[headerItem.filterKey]}
            onChange={selectItem.bind(this, headerItem)}
        >
            <option key={'slct'} value="">
                {headerItem.headerTitle}{' '}
            </option>
            {selectOptions}
        </select>
    );

    return selectElement;
};

const YearSelect = (props) => {
    const { year = 2020, setYear } = props;
    let yearOptions = [];
    for (let i = 2020; i <= new Date().getFullYear(); i++) {
        yearOptions.push(
            <option key={i} value={i}>
                {i}
            </option>
        );
    }
    return (
        <select
            className="form-control form-control-sm px-1 w-auto  bg-white"
            value={year}
            onChange={setYear.bind(this)}
        >
            {yearOptions}
        </select>
    );
};

const MonthSelect = (props) => {
    const { month, setYearOrMonth } = props;
    let monthOptions = [];
    for (let i = 1; i <= 12; i++) {
        monthOptions.push(
            <option key={i} value={i}>
                {moment()
                    .month(i - 1)
                    .format('MMMM')}
            </option>
        );
    }
    return (
        <select
            className="form-control form-control-sm px-1 w-auto bg-white"
            value={month}
            onChange={setYearOrMonth.bind(this, 'month')}
        >
            <option key={'hotornot'} value="">
                {window.translate('Not selected')}
            </option>
            {monthOptions}
        </select>
    );
};

const TableHeader = (props) => {
    const { headers, sort, getSelectFilter, sortByColumn, firstColWidth } = props;

    let headerItems = [];
    let index = 0;
    _.forEach(headers, (headerItem) => {
        index++;
        let headerData = '';
        if (headerItem.filterType === 'select') {
            headerData = getSelectFilter(headerItem);
        } else if (headerItem.filterType === 'sort') {
            let sortIconClass = 'fa fa-sort';

            if (sort[headerItem.filterKey] && sort[headerItem.filterKey] === 'asc') {
                sortIconClass = 'fa fa-sort-up';
            }
            if (sort[headerItem.filterKey] && sort[headerItem.filterKey] === 'desc') {
                sortIconClass = 'fa fa-sort-down';
            }

            headerData = (
                <div onClick={sortByColumn.bind(this, headerItem.filterKey)} className="pointer">
                    {headerItem.headerTitle} <i className={sortIconClass}></i>
                </div>
            );
        } else {
            headerData = headerItem.headerTitle;
        }

        headerItems.push(
            // <span key={`hindex${index}`} className={headerItem.bootstrapColumnClass + ' overflow-hidden text-center'}  style={{width:headerItem.cellWidth}}>
            <span
                key={`hindex${index}`}
                className={' overflow-hidden text-center'}
                style={{ width: headerItem.cellWidth }}
            >
                {headerData}
            </span>
        );
    });

    let header = (
        <div
            id="report-table-header"
            className="row px-0 py-1  text-white small navbar-dark navbar-gradient"
            style={{ width: 'fit-content' }}
        >
            <div className=" mx-0 d-flex">
                <span
                    className=" text-center d-flex align-items-center justify-content-center"
                    style={{ width: `${firstColWidth}px` }}
                >
                    #
                </span>
                {headerItems}
            </div>
        </div>
    );
    return header;
};

const SumFoother = (props) => {
    const { headers, arrayToIterate } = props;
    let footerItems = [];
    let index = 0;
    let numberOfSumElements = 0;
    _.forEach(headers, (headerItem) => {
        index++;
        let headerData = '';
        if (headerItem.filterType === 'sum') {
            numberOfSumElements++;
            headerData = (
                <div className="text-center">
                    <span className="d-inline-block d-sm-none">{headerItem.headerTitle}:</span>
                    <span> {_.sumBy(arrayToIterate, headerItem.filterKey)}</span>{' '}
                </div>
            );
        } else {
            headerData = '';
        }

        footerItems.push(
            // <span key={`hindex${index}`} className={headerItem.bootstrapColumnClass + ' overflow-hidden text-center'}>
            <span
                key={`hindex${index}`}
                className={' overflow-hidden text-center'}
                style={{ width: headerItem.cellWidth }}
            >
                {headerData}
            </span>
        );
    });

    let footer = (
        // <div className="row px-0 py-1  text-white small navbar-dark navbar-gradient">
        //     <span className="col-md-1 text-center col-1 d-flex align-items-center justify-content-center">total</span>
        //     <div className="col-11 row mx-0"> {footerItems} </div>
        // </div>
        <div className="row px-0 py-1  text-white small navbar-dark navbar-gradient" style={{ width: 'fit-content' }}>
            <div className=" mx-0 d-flex">
                <span
                    className=" text-center d-flex align-items-center justify-content-center"
                    style={{ width: '200px' }}
                >
                    {window.translate('total')}
                </span>
                {footerItems}
            </div>
        </div>
    );

    if (numberOfSumElements === 0 || arrayToIterate.length === 0) {
        return null;
    } else {
        return footer;
    }
};

const RowTemplate = React.memo((props) => {
    const { data, index, style } = props;
    const { item, getLabel, headers, onRowClickHandler, firstColWidth } = data[index];

    return (
        <div
            key={`k${index}`}
            onClick={() => {
                if (onRowClickHandler) {
                    onRowClickHandler(item);
                }
            }}
            className={'row px-0 small ' + (index % 2 === 0 ? 'bg-white' : '')}
            style={{ width: 'fit-content', ...style }}
        >
            <div className="mx-0 d-flex">
                <span
                    className="text-center  d-flex align-items-center justify-content-center"
                    style={{ width: `${firstColWidth}px` }}
                >
                    {index + 1}
                </span>
                {_.map(headers, (val, key) => {
                    return (
                        <span
                            key={key}
                            className={'elipsis text-center overflow-hidden font-weight-light'}
                            style={{ width: val.cellWidth }}
                        >
                            <span>{getLabel(val, item)}</span>
                        </span>
                    );
                })}
            </div>
        </div>
    );
});

class ReportTable extends Component {
    constructor(props) {
        super(props);
        this.state = {
            itemsArray: props.itemsArray,
            filters: {},
            sort: {},
            month: '',
            year: null,
            selectedDate: null,
            dateFilterActive: false,
            tableContentHeight: 300,
        };
    }

    componentDidMount() {
        let el = document.getElementById('report-table-header');
        let footer = document.getElementById('main-footer');

        let positionOfTableHeaderElement = getPos(el);
        let positionOfDocumentFooterElement = getPos(footer);
        let heightOfTableHeaderElement = el.offsetHeight;

        const heightOfTheSpaceFromTableHeaderToDocumentFooter =
            positionOfDocumentFooterElement.y - (positionOfTableHeaderElement.y + heightOfTableHeaderElement);
        const tableContentHeight = heightOfTheSpaceFromTableHeaderToDocumentFooter;
        this.setState({ tableContentHeight, year: this.props.year, month: this.props.month ? this.props.month : '' });
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            prevProps.itemsArray &&
            this.props.itemsArray &&
            prevProps.itemsArray.length !== this.props.itemsArray.length
        ) {
            this.setState({ itemsArray: this.props.itemsArray });
        }

        if (prevProps.month && this.props.month && prevProps.month !== this.props.month) {
            this.setState({ month: this.props.month });
        }

        if (prevProps.year && this.props.year && prevProps.year !== this.props.year) {
            this.setState({ year: this.props.year });
        }

        if (prevProps.itemsArray !== this.props.itemsArray) {
            this.setState({ itemsArray: this.props.itemsArray });
        }
    }

    getLabel(headerItem, arrayItem) {
        let label = '';
        switch (headerItem.type) {
            case 'date':
                if (isNaN(Number(arrayItem[headerItem.label]))) {
                    label = arrayItem[headerItem.label] ? moment(arrayItem[headerItem.label]).format('L') : '-';
                } else {
                    label = arrayItem[headerItem.label] ? moment(Number(arrayItem[headerItem.label])).format('L') : '-';
                }
                break;
            case 'datetime':
                if (isNaN(Number(arrayItem[headerItem.label]))) {
                    label = arrayItem[headerItem.label] ? moment(arrayItem[headerItem.label]).format('lll') : '-';
                } else {
                    label = arrayItem[headerItem.label]
                        ? moment(Number(arrayItem[headerItem.label])).format('lll')
                        : '-';
                }
                break;
            case 'enum':
                label = headerItem.enumDescription[arrayItem[headerItem.filterKey]];
                break;
            case 'num':
                if (arrayItem[headerItem.label] === null) {
                    label = '';
                } else {
                    label = Number(arrayItem[headerItem.label]);
                }
                break;



            case 'hotelName':
                label = this.props.companyInfo.hotelName
                break;

            case 'link':
                let temp = [];
                let j = 0;
                for (let i = 0; i < headerItem.linkSchema.length; i++) {
                    let itm = headerItem.linkSchema[i];
                    if (headerItem.linkSchema[i] === '*') {
                        itm = arrayItem[headerItem.linkSubstitutions[j]] ? arrayItem[headerItem.linkSubstitutions[j]] : "null"
                        j++;
                    }
                    temp.push(itm);
                }

                if(headerItem.linkSchema[0]==="cashRegReportView"){
                }else{
                    temp.push("?groupUuid="+arrayItem[headerItem.linkSubstitutions[1]])
                }
                let linkToVisit = '/' + temp.join('/');

                label = null;

                if (headerItem.openInNewTab) {
                    label = (
                        <Link

                            to={linkToVisit}
                            className=""
                            target="_blank"
                        >
                            <div

                                className="custom-small-button-reports text-dark"
                            >
                                {headerItem.linkText}
                            </div>
                        </Link>
                    );
                } else {
                    label = <div
                        onClick={() => {
                            this.props.history.push(linkToVisit);
                        }}
                        className="custom-small-button-reports"
                    >
                        {headerItem.linkText}
                    </div>;
                }

                break;

            case 'price':
                label = numbro(arrayItem[headerItem.label] ? arrayItem[headerItem.label] : 0).format({
                    mantissa: 2,
                    trimMantissa: false,
                    thousandSeparated: true,
                });

                break;

            case 'modal':
                label = (
                    headerItem.showRowsWithIds.includes(arrayItem.id) ? <div
                        onClick={() => {
                            headerItem.action(arrayItem);
                        }}
                        className="custom-small-button-reports"
                    >
                        {headerItem.linkText}
                    </div> : null
                );
                break;

            default:
                let labelCommaString = headerItem.label;
                let labelArray = labelCommaString.split(',');
                _.forEach(labelArray, (labItm) => {
                    if (arrayItem[labItm]) {
                        label = label + arrayItem[labItm] + ' ';
                    }
                });
                break;
        }
        return label;
    }

    selectItem(headerItem, e) {
        let filters = _.cloneDeep(this.state.filters);

        if (headerItem.type === 'number' || headerItem.type === 'date' || headerItem.type === 'num') {
            filters[headerItem.filterKey] = e.target.value === '' ? '' : Number(e.target.value); //Number(e.target.value);
        } else if (headerItem.type === 'enum') {
            filters[headerItem.filterKey] =
                e.target.value === '' ? '' : isNaN(e.target.value) ? e.target.value : Number(e.target.value);
        } else {
            filters[headerItem.filterKey] = e.target.value === '' ? '' : e.target.value; //Number(e.target.value);
        }
        this.setState({ filters: filters });
    }

    getSelectFilter(headerItem) {
        const { filters, itemsArray } = this.state;
        return (
            <SelectFilter
                itemsArray={itemsArray}
                getLabel={this.getLabel.bind(this)}
                filters={filters}
                selectItem={this.selectItem.bind(this)}
                headerItem={headerItem}
            />
        );
    }

    sortByColumn(column) {
        let sortFromState = _.cloneDeep(this.state.sort);

        if (this.state.sort[column]) {
            if (this.state.sort[column] === 'asc') {
                sortFromState[column] = 'desc';
            } else if (this.state.sort[column] === 'desc') {
                sortFromState = {};
            } else {
                sortFromState[column] = 'asc';
            }
        } else {
            sortFromState = {};
            sortFromState[column] = 'asc';
        }
        this.setState({ sort: sortFromState });
    }

    async setYearOrMonth(type, e) {
        this.setState({
            [type]: e.target.value === '' ? '' : Number(e.target.value),
            dateFilterActive: false,
            selectedDate: null,
        });

        if (type === 'year' || type === 'month') {
            var filters = {
                month: this.state.month,
                year: this.state.year,
            };

            filters[type] = Number(e.target.value);
            if (this.props.onYearMonthChange) {
                this.props.onYearMonthChange(filters);
            }
        }
    }
    setDate(e) {
        this.setState({
            selectedDate: moment(e.target.value).valueOf(),
            month: '',
        });
    }

    toggleDaySelection() {
        this.setState({ dateFilterActive: !this.state.dateFilterActive, month: '' });
    }

    exportToExcel() {
        const { filters, sort, month, selectedDate } = this.state;
        const { year, xlsExportHandler, headers, filterType, filterCountry, page, rows } = this.props;

        const colHeaders = headers.map((h) => {
            return {
                title: h.headerTitle,
                width: parseInt(h.cellWidth),
                colId: h.label,
            };
        });

        const filtersAndSorts = { filters, sort, month, selectedDate, year, colHeaders, filterType, filterCountry, page, rows };
        xlsExportHandler(filtersAndSorts);
    }

    render() {
        const { itemsArray, filters, month, selectedDate, sort, dateFilterActive, tableContentHeight } = this.state;
        const {
            xlsExportHandler = null,
            year,
            targetedColumnForMonthFilter,
            targetedColumnForDateFilter,
            onRowClickHandler,
            history,
            headers,
            monthFilter,
            showDateFilter,
            setYear,
            firstColWidth = 60,
            showTableToolbar = true,
            page,
            loadData,
            listOfItems
        } = this.props;

        //const rows = [];
        let filteredArray = itemsArray;

        let rowWidth = firstColWidth;

        _.forEach(headers, (headerItem) => {
            rowWidth = rowWidth += Number(_.replace(headerItem.cellWidth, 'px', ''));
        });

        if (filters) {
            _.forIn(filters, (val, key) => {
                if (val !== '') {
                    if(key==="tags"){
                        if(val.includes(";")){
                            const tags = val.split(";")
                            tags.forEach(t=>{
                                filteredArray = filteredArray.filter((itm) => {
                                    if(itm.tags!==null){
                                        if(itm.tags.includes(t)){
                                            return itm
                                        }
                                    }
                                    return null
                                    ;
                                });
                            })
                        }else{
                            filteredArray = filteredArray.filter((itm) => {
                                if(itm.tags!==null){
                                    if(itm.tags.includes(val)){
                                        return itm
                                    }
                                }
                                return null
                            });
                        }
                        
                    }else{
                        filteredArray = filteredArray.filter((itm) => {
                            return itm[key] === val;
                        });
                    }
                }
            });
        }

        if (month !== '') {
            let dateFromSelectedMonth = moment()
                .set('year', year)
                .set('month', month - 1);
            filteredArray = _.filter(filteredArray, (itm) => {
                let targetedColumnForMonthFilter_timestamp = itm[targetedColumnForMonthFilter];
                if (isNaN(Number(itm[targetedColumnForMonthFilter]))) {
                    targetedColumnForMonthFilter_timestamp = moment(itm[targetedColumnForMonthFilter]).valueOf();
                }
                return (
                    targetedColumnForMonthFilter_timestamp >= dateFromSelectedMonth.startOf('month').valueOf() &&
                    targetedColumnForMonthFilter_timestamp <= dateFromSelectedMonth.endOf('month').valueOf()
                );
            });
        }

        if (dateFilterActive === true) {
            filteredArray = _.filter(filteredArray, (itm) => {
                let targetedColumnForDateFilter_timestamp = itm[targetedColumnForDateFilter];
                if (isNaN(Number(itm[targetedColumnForDateFilter]))) {
                    targetedColumnForDateFilter_timestamp = moment(itm[targetedColumnForDateFilter]).valueOf();
                }
                return (
                    targetedColumnForDateFilter_timestamp >= moment(selectedDate).startOf('day').valueOf() &&
                    targetedColumnForDateFilter_timestamp <= moment(selectedDate).endOf('day').valueOf()
                );
            });
        }

        let sortedArray = filteredArray;

        if (sort) {
            _.forIn(sort, (val, key) => {
                sortedArray = _.orderBy(sortedArray, [key], [val]);
            });
        }

        const items = sortedArray.map((item) => {
            return {
                getLabel: this.getLabel.bind(this),
                item,
                history,
                headers,
                onRowClickHandler,
                firstColWidth,
            };
        });

        const tableToolbar = (
            <div className="form-row">
                <div className="col-auto">
                    <button
                        className="btn btn-sm btn-secondary mb-2"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    >
                        {window.translate('GO BACK')}
                    </button>
                </div>
                <div className="col-auto">
                    <YearSelect year={year} setYear={setYear} />
                </div>
                <div className="col-auto">
                    {monthFilter === true ? (
                        <MonthSelect setYearOrMonth={this.setYearOrMonth.bind(this)} month={month} />
                    ) : null}
                </div>

                {showDateFilter === true ? (
                    <div className="col-auto">
                        <span className="pointer d-inline" onClick={this.toggleDaySelection.bind(this)}>
                            <i className={dateFilterActive ? 'fa fa-check-square-o' : 'fa fa-square-o'}></i>{' '}
                            {window.translate(' DATE')}:&ensp;
                        </span>
                        {monthFilter === true ? (
                            <input
                                disabled={!this.state.dateFilterActive}
                                min={moment([this.props.year]).startOf('year').format('YYYY-MM-DD')}
                                max={moment([this.props.year]).endOf('year').format('YYYY-MM-DD')}
                                value={moment(this.state.selectedDate).format('YYYY-MM-DD')}
                                onChange={this.setDate.bind(this)}
                                className="form-control d-inline form-control-sm px-1 w-auto bg-white mb-2"
                                type="date"
                            ></input>
                        ) : null}
                    </div>
                ) : null}

                {xlsExportHandler !== null ? (
                    <div className="col-auto">
                        <button onClick={this.exportToExcel.bind(this)} className="btn btn-sm btn-info mb-2">
                            <i className="fa fa-file-excel-o" />
                            {window.translate(' EXPORT')}
                        </button>
                    </div>
                ) : null}
                <div className="col-auto ml-auto">
                    <small>
                        <button onClick={() => pageDown(page, loadData)} className="btn btn-sm btn-outline-primary mr-2">
                            {window.translate('prev')}
                        </button>
                        <div className="btn btn-sm ">
                            {window.translate('page')} {page + 1}
                        </div>
                        <button
                            onClick={() => pageUp(page, loadData, listOfItems)}
                            className="btn btn-sm btn-outline-primary ml-2"
                        >
                            {window.translate('next')}
                        </button>
                    </small>
                </div>
            </div>
        );

        return (
            <div>
                {showTableToolbar ? <div className="">{tableToolbar}</div> : null}

                <div className="mb-3" style={{ overflowX: 'auto' }}>
                    <TableHeader
                        firstColWidth={firstColWidth}
                        headers={headers}
                        sort={sort}
                        getSelectFilter={this.getSelectFilter.bind(this)}
                        sortByColumn={this.sortByColumn.bind(this)}
                    />

                    <List
                        // className="list-group"
                        overscanCount={200}
                        height={tableContentHeight}
                        itemData={items}
                        itemCount={items.length}
                        itemSize={20}
                        width={rowWidth}
                    >
                        {RowTemplate}
                    </List>

                    <SumFoother headers={headers} arrayToIterate={sortedArray} />
                </div>
            </div>
        );
    }
}

export default branch({
    companyInfo: ['companyInfo'],
},ReportTable);
