import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';

class SelectOnClick extends Component {
    constructor(props) {
        super(props);
        this.state = {};
    }

    render() {
        const { title = 'title', fields, category = 'category' } = this.props;
        /*
        let title = this.props.title
        let fields = this.props.fields
        let category = this.props.category
        */
        return (
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor={'PL-' + category + '-input'}>
                    {title}:
                </label>
                <div className="col-md-8">
                    <div className="weekDays-selector text-justify">{fields}</div>
                </div>
            </div>
        );
    }
}

export default branch({}, SelectOnClick);
