import React from 'react'

export default function keyWords(props) {
    return (
        <div className="col-md-6" style={{ userSelect: "text" }}>
            {window.translate("KEYWORDS")}
            {props.keyWords.map((kw, key) => {
                return <li key={key}>{kw}</li>;
            })}
        </div>
    )
}
