import React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { DocumentProps } from './DocumentFooter';

const InvoiceFooter = (props:DocumentProps) => {
    let invoiceNotes = props.otherSettings && props.otherSettings.invoiceNotes ? props.otherSettings.invoiceNotes : '';

    return (
        <div className="mt-3 mb-3">
            <small dangerouslySetInnerHTML={{ __html: invoiceNotes }}></small>
        </div>
    );
};

export default branch(
    {
        otherSettings: ['otherSettings'],
    },
    InvoiceFooter
);
