import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import { saveModelData, getSpecificModelRow } from '../../data/actions/modelActions';
import PageTitle from '../../components/pageTitle';
import FormHeader from '../../components/formHeader';
import AttributeFormModal from './attributeFormModal';
import { setModalContent, closeModal } from '@components/modal';
import RoomAttributes from '../../components/attributes';
import ListRoomsOrPricelistByTags from './listByTags';
import Select from 'react-select/creatable';
import { toast } from 'react-toastify';

class RoomInfoForm extends Component {
    constructor() {
        super();
        this.name = React.createRef();
        this.bedCount = React.createRef();

        this.roomInfoTemplate = {
            name: '',
            description: '',
            availableForRent: true,
            roomPhone: '',
            accommodationPriceListId: null,
            bedCount: 0,
            spareBedCount: 0,

            remoteDeviceId: '',
            srcProxyId: '',
            tags: '',
        };
        this.state = {
            roomInfo: this.roomInfoTemplate,
            roomTags: [],
        };
    }

    async componentDidMount() {
        let roomInfoId = this.props.roomInfoId || this.props.match.params.roomInfoId;
        this.redrawComponent(roomInfoId);
    }

    async redrawComponent(roomInfoId) {
        if (roomInfoId) {
            let roomInfo = await getSpecificModelRow('RoomInfo', roomInfoId);
            this.clearValidationRefs();
            let roomTags = [];
            const tags = roomInfo.tags !== null ? roomInfo.tags.split(';').sort((a, b) => (a > b ? 1 : -1)) : [];
            tags.forEach((t) => {
                let obj = {};
                obj.value = t;
                obj.label = t;
                roomTags.push(obj);
            });
            this.setState({
                roomInfo,
                showLoadingPage: true,
                roomTags: roomTags,
            });
        }
        await this.checkValidationInputs(this.state.roomInfo)
    }

    componentDidUpdate(prevProps, prevState) {
        let roomInfoId = this.props.roomInfoId || this.props.match.params.roomInfoId;
        if (parseInt(prevState.roomInfo.id, 10) !== parseInt(roomInfoId, 10)) {
            this.redrawComponent(roomInfoId);
        }
    }

    checkValidationInputs(objToValidate){
        let validationResults = this.getValidationInputs(objToValidate);
        this.markInvalidInputRefs(validationResults);
    }

    handleInputChange(e) {
        let roomInfo = _.clone(this.state.roomInfo);
        _.forIn(roomInfo, (value, key) => {
            if (key === e.target.name) {
                if (e.target.type === 'number') {
                    roomInfo[key] = Number(e.target.value);
                } else {
                    roomInfo[key] = e.target.value;
                }
            }
        });
        if(e.target.name==="name" || e.target.name==="bedCount"){
            this.checkValidationInputs(roomInfo)
        }
        this.setState({
            roomInfo: roomInfo,
        });
    }
    handleDescriptionChange(e) {
        this.setState({
            description: e.target.value,
        });
    }
    handleTagsChange(val) {
        let roomInfo = _.clone(this.state.roomInfo);
        const tags = [];
        if (val && val.length > 0) {
            val.forEach((v) => tags.push(v.value));
        }
        roomInfo.tags = tags.join(';');
        this.setState({
            roomTags: val,
            roomInfo,
        });
    }

    clearValidationRefs() {
        if (this.name && this.name.current) {
            this.name.current.className = this.name.current.className.replace('is-invalid', '');
        }
        if (this.bedCount && this.bedCount.current) {
            this.bedCount.current.className = this.bedCount.current.className.replace('is-invalid', '');
        }
    }

    markInvalidInputRefs(validationResults) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        _.forEach(invalidInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        _.forEach(validInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }
    getValidationInputs(data) {
        let invalidInputs = [];
        let validInputs = [];
        // this.clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(this.name);
        } else {
            validInputs.push(this.name);
        }
        if (data.bedCount === null || data.bedCount <= 0 || !_.isNumber(data.bedCount)) {
            invalidInputs.push(this.bedCount);
        } else {
            validInputs.push(this.bedCount);
        }
        return { invalidInputs, validInputs };
    }

    onRoomInfoSave() {
        let roomInfoId = this.props.roomInfoId || this.props.match.params.roomInfoId;
        roomInfoId = parseInt(roomInfoId, 10);
        let roomInfoObject = _.cloneDeep(this.state.roomInfo);
        let validationResults = this.getValidationInputs(roomInfoObject);
        // console.log("room", validationResults)
        this.markInvalidInputRefs(validationResults);
        if (validationResults.invalidInputs.length === 0) {
            _.forIn(roomInfoObject, (val, key) => {
                if (val === '') {
                    roomInfoObject[key] = null; //set all empty strings to null
                }
            });

            if (_.isNumber(roomInfoId)) {
                saveModelData('RoomInfo', roomInfoObject)
                    .then((res) => {
                        if(!roomInfoObject.id){
                            toast(window.translate("Room successfully added!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
                        }
                        if (this.props.callback) {
                            this.props.callback(res);
                        } else {
                            this.props.history.push('/roomsAdministration');
                        }
                    })
                    .catch((err) => {
                        console.log(err);
                    });
            }
            closeModal();
        }
    }

    cancelHandler() {
        if (this.props.cancelHandler) {
            this.props.cancelHandler();
        } else {
            this.props.history.push('/roomsAdministration');
        }
        closeModal();
    }

    outOfServiceHandler(e) {
        let roomInfo = _.clone(this.state.roomInfo);
        roomInfo.availableForRent = !roomInfo.availableForRent;
        this.setState({ roomInfo });
    }

    assignRoomAttributes() {
        setModalContent(
            <AttributeFormModal
                onSave={(roomAttributes) => {
                    let roomInfo = _.clone(this.state.roomInfo);
                    roomInfo.roomAttributes = roomAttributes;
                    this.setState({ roomInfo });
                }}
                roomInfo={this.state.roomInfo}
            />,
            window.translate('Assign attributes to room ') + this.state.roomInfo.name,
            false,
            'modal-lg'
        );
    }
    render() {
        if (this.state.roomInfo === null) {
            return <div>{window.translate('Room does not exist')}</div>;
        }
        let roomInfoId = this.props.roomInfoId || this.props.match.params.roomInfoId;

        const tagsOptions = [];
        let tags = [...this.props.RoomInfoTags];
        tags = tags.sort((a, b) => (a > b ? 1 : -1));
        tags.forEach((t) => {
            let obj = {};
            obj.value = t;
            obj.label = t;
            tagsOptions.push(obj);
        });

        /*        let accommodationPriceListStacks = _.filter(this.props.accommodationPriceLists, (p) => p.parentId === null);
        
        
                let priceListOptions = _.map(accommodationPriceListStacks, (p) => {
                    return (
                        <option key={p.id} value={p.id}>
                            {' '}
                            {p.name}
                        </option>
                    );
                });
                priceListOptions = [
                    <option key="none" value="">
                        {window.translate("Nothing selected")}
                    </option>,
                    ...priceListOptions,
                ];
                */
        return (
            <div className="container-fluid ">
                <PageTitle />

                <div className="container pl-0 pr-0 ">
                    {this.props.header !== undefined ? (
                        this.props.header
                    ) : (
                        <FormHeader>
                            {roomInfoId ? window.translate('Edit room data') : window.translate('Add room')}
                        </FormHeader>
                    )}
                </div>
                <div className="">
                    <div className="container bg-white pt-3">
                        <div className="row">
                            <div className="col-md-6">
                                <h5>{window.translate('Basic room informations')}</h5>
                                <div className="form-group">
                                    <label htmlFor="">{window.translate('Room name')}:</label>
                                    <input
                                        ref={this.name}
                                        placeholder={window.translate('Room name')}
                                        autoComplete="off"
                                        name="name"
                                        value={this.state.roomInfo.name ? this.state.roomInfo.name : ''}
                                        onChange={this.handleInputChange.bind(this)}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>

                                <div className="form-group">
                                    <label htmlFor="">{window.translate('Description')}:</label>
                                    <textarea
                                        placeholder={window.translate('Description')}
                                        name="description"
                                        value={this.state.roomInfo.description ? this.state.roomInfo.description : ''}
                                        onChange={this.handleInputChange.bind(this)}
                                        rows="4"
                                        cols="50"
                                        className="form-control"
                                    >
                                        {' '}
                                    </textarea>
                                </div>

                                <div className="form-group">
                                    <label htmlFor="">{window.translate('Room phone/local')}:</label>
                                    <input
                                        placeholder={window.translate('Room phone/local')}
                                        autoComplete="off"
                                        name="roomPhone"
                                        value={this.state.roomInfo.roomPhone ? this.state.roomInfo.roomPhone : ''}
                                        onChange={this.handleInputChange.bind(this)}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>

                                <div className="form-group">
                                    <input
                                        onChange={this.outOfServiceHandler.bind(this)}
                                        className="form-check-input ml-2"
                                        type="checkbox"
                                        value={!this.state.roomInfo.availableForRent}
                                        checked={!this.state.roomInfo.availableForRent}
                                        id="defaultCheck1"
                                    />
                                    <label className="form-check-label text-danger ml-4" htmlFor="defaultCheck1">
                                        {window.translate('OUT OF SERVICE')}
                                    </label>
                                </div>
                            </div>
                            <div className="col-md-6">
                                {this.state.roomInfo.id ? (
                                    <div className="form-group">
                                        <label htmlFor="">{window.translate('Assign room attributes')}:</label>
                                        <button
                                            className="btn btn-secondary btn-sm ml-2"
                                            onClick={this.assignRoomAttributes.bind(this)}
                                        >
                                            {window.translate('Select atributes')}
                                        </button>
                                        <RoomAttributes room={this.state.roomInfo} attributes={this.props.attributes} />
                                    </div>
                                ) : (
                                    <div className="form-group">
                                        <label htmlFor="">{window.translate('Assign room attributes')}:</label>
                                        <span className="ml-2 bg-light p-1">
                                            <small>
                                                <b>
                                                    {window.translate(
                                                        'You can assign room attributes later on editing room'
                                                    )}
                                                </b>
                                            </small>
                                        </span>
                                    </div>
                                )}

                                <div className="row">
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">{window.translate('Bed count')}:</label>
                                            <input
                                                ref={this.bedCount}
                                                placeholder={window.translate('Bed count')}
                                                autoComplete="off"
                                                name="bedCount"
                                                value={this.state.roomInfo.bedCount ? this.state.roomInfo.bedCount : 0}
                                                onChange={this.handleInputChange.bind(this)}
                                                type="number"
                                                min={0}
                                                max={10}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                    <div className="col-md-6">
                                        <div className="form-group">
                                            <label htmlFor="">{window.translate('Spare beds')}:</label>
                                            <input
                                                placeholder={window.translate('Spare beds')}
                                                autoComplete="off"
                                                name="spareBedCount"
                                                value={
                                                    this.state.roomInfo.spareBedCount
                                                        ? this.state.roomInfo.spareBedCount
                                                        : 0
                                                }
                                                onChange={this.handleInputChange.bind(this)}
                                                type="number"
                                                min={0}
                                                max={10}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                </div>

                                <h5>{window.translate('References')}</h5>
                                <div className="form-group">
                                    <label htmlFor="">
                                        {window.translate('Hardware controller reference (configure at')}{' '}
                                        <a href="#/srcProxies">SRCProxies</a>):
                                    </label>
                                    <input
                                        placeholder={window.translate('NO ACTIVE SRC REFERENCE')}
                                        readOnly={true}
                                        autoComplete="off"
                                        name="remoteDeviceId"
                                        value={
                                            this.state.roomInfo.remoteDeviceId ? this.state.roomInfo.remoteDeviceId : ''
                                        }
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="col-6">
                                <div className="form-group">
                                    <label htmlFor="">{window.translate('Tags')}:</label>
                                    <Select
                                        isMulti
                                        className=""
                                        placeholder={window.translate('Slice pricelist by tags')}
                                        options={tagsOptions}
                                        onChange={this.handleTagsChange.bind(this)}
                                        value={this.state.roomTags}
                                    ></Select>
                                </div>
                            </div>
                            <div className="col-6">
                                <label htmlFor="">{window.translate('Pricelists by tags')}:</label>
                                <div className="form-group" style={{ maxHeight: '250px', overflowY: 'auto' }}>
                                    <ListRoomsOrPricelistByTags tags={this.state.roomInfo.tags} />
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="text-center bg-white container  pb-4 ">
                        <button onClick={this.onRoomInfoSave.bind(this)} className="btn btn-outline-primary">
                            {window.translate('Save')}
                        </button>
                        <div onClick={this.cancelHandler.bind(this)} className="btn btn-outline-secondary ml-2 pointer">
                            {window.translate('Cancel')}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        accommodationPriceLists: ['model', 'AccommodationPriceList'],
        attributes: ['model', 'RoomAttribute'],
        roomInfo: ['model', 'RoomInfo'],
        RoomInfoTags: ['tag', 'RoomInfo'],
    },
    RoomInfoForm
);
