import React, { Component, /* Suspense */ } from 'react';
import PropTypes from 'prop-types';
//import _ from "lodash"
//import AceEditor from 'react-ace';
// import '../../assets/css/code-editor.css';

//import 'brace/mode/javascript';
//import 'brace/theme/tomorrow';

// React.lazy(()=>import ("ace-builds/src-noconflict/mode-java"))
// React.lazy(()=>import ("ace-builds/src-noconflict/theme-tomorrow"))
// const AceEditor = React.lazy(() => import('react-ace'))

class CodeInput extends Component {
	onChangeHandler(e){
		if(this.props.onValueChanged){
			this.props.onValueChanged(e, this.props.inputName); //propagate updated value
		}
		
	}
	render(){
		return (<div className="">
				
			</div>);
	}
}
CodeInput.propTypes = {
	value: PropTypes.string.isRequired,
	onValueChanged: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired
}

export default CodeInput;