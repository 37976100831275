import React from 'react';
//@ts-ignore
import { Prompt } from 'react-router-dom';
import InlineModal from '@components/modalInline';
import { translate } from '@data/translations';
//@ts-ignore
import _ from 'lodash';
import { ReservationController } from '../resController';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';

interface RouteLeavingGuardprops {
    shouldBlockNavigation: any;
    navigate: any;
    when: any;
    invalidVisaIds: any;
    changesDetected: any;
}

function RouteLeavingGuard(props: RouteLeavingGuardprops) {
    const [modalVisible, setModalVisible] = React.useState(false);
    const [lastLocation, setLastLocation] = React.useState(null as any);

    const { shouldBlockNavigation, navigate, when, invalidVisaIds, changesDetected } = props;

    let confirmedNavigation = false;

    const showModal = (location: any) => {
        setModalVisible(true);
        setLastLocation(location);
    };

    const closeModal = React.useCallback((callback: any) => {
        if (typeof callback === 'function') {
            setModalVisible(false);
            callback();
        } else {
            setModalVisible(false);
        }
    }, []);

    const handleBlockedNavigation = (nextLocation: any) => {
        if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
            showModal(nextLocation);
            return false;
        }
        return true;
    };

    const handleConfirmNavigationClick = () => {
        closeModal(async () => {
            if (lastLocation) {
                confirmedNavigation = true;
                const path = lastLocation?.pathname + lastLocation?.search;
                await navigate(path);
                setModalVisible(false);
            }
        });
    };

    let message = null;
    if ((changesDetected && !_.isEmpty(invalidVisaIds)) || (changesDetected && _.isEmpty(invalidVisaIds))) {
        message = 'leave_guard_note';
    }
    if (!changesDetected && !_.isEmpty(invalidVisaIds)) {
        message = 'leave_guard_note_visa';
    }

    return (
        <React.Fragment>
            <Prompt when={when} message={handleBlockedNavigation} />
            <InlineModal show={modalVisible} header={translate('Warning')}>
                <div className="text-center">
                    <div className="p-2">{translate(message)}</div>
                    <div className="p-2">
                        <button
                            className="btn btn-sm btn-outline-primary mr-2"
                            onClick={(e) => {
                                e.preventDefault();
                                handleConfirmNavigationClick();
                            }}
                        >
                            {translate('Leave anyway')}
                        </button>
                        <button
                            className="btn btn-sm btn-outline-secondary mr-2"
                            onClick={(e) => {
                                e.preventDefault();
                                closeModal(null);
                            }}
                        >
                            {translate('Cancel')}
                        </button>
                    </div>
                </div>
            </InlineModal>
        </React.Fragment>
    );
}

interface RouteLeavingGuardWrapperProps {
    isPending: boolean;
    history: any;
}

function RouteLeavingGuardWrapper(props: RouteLeavingGuardWrapperProps){
    const {  isPending, history } = props;

    const ctrl = new ReservationController();
    const invalidVisaIds = ctrl.getCustomersWithInvalidVisaIds();

    return (
        <RouteLeavingGuard
            invalidVisaIds={invalidVisaIds}
            changesDetected={isPending}
            // When should shouldBlockNavigation be invoked,
            // simply passing a boolean
            // (same as "when" prop of Prompt of React-Router)
            when={(isPending || invalidVisaIds.length > 0)}
            // Navigate function
            navigate={(path: any) => {
                history.push(path);
                // window.location = path;
            }}
            // Use as "message" prop of Prompt of React-Router
            shouldBlockNavigation={(location: any) => {
                // This case it blocks the navigation when:
                // 1. form is edited, and
                // 2. the user is going to checkout page location - optional
                //    (Just an example, in real case you might
                //     need to block all location regarding this case)
                if (isPending || invalidVisaIds.length > 0) {
                    // if (location.pathname === 'checkout...') {
                    //     return true;
                    // }
                    return true;
                }
                return false;
            }}
        />
    );
};


export default branch(
    {
        isPending: ['state', 'reservationForm2', 'isPending'],
    },
    RouteLeavingGuardWrapper
);

