import { CleaningStatusExtendedWithIncludedModels, RoomInfoEditModel, UserInfo } from '@common/modelDefinition';
import React, { useState } from 'react';
import {
    UpdateCleaningStatusProps,
    CleaningStatusColors,
    CleaningStatusEnum,
    CleaningStatusIcons,
    ReservationStatusEnum,
    CleaningInspectionEnum,
    InspectionStatusColors,
    CleaningInspectionIcons,
} from './interfaces';
import { translate } from '@data/translations';
import { updateCleaningStatus } from './api';
import FeatureFlag from '@components/FeatureFlag';
import checkRoleAccess from '@data/checkRoleAccess';
import { closeModal } from '@components/modal';
import moment from 'moment';

const checkStatusAvailability = (
    cleaningStatus: number,
    currentCleaningStatus: number,
    inspectionStatus: number | null
): boolean => {
    let showStatusItem = false;
    let showByStatus = false;
    let showByInspectionStatus = false;
    if (cleaningStatus === CleaningStatusEnum.stagedForCleaning) {
        if (
            [
                CleaningStatusEnum.noActionTaken,
                CleaningStatusEnum.cleaningRequested,
                CleaningStatusEnum.cleaningPostponed,
                CleaningStatusEnum.cleaningSkipped,
            ].includes(currentCleaningStatus)
        ) {
            showByStatus = true;
        }
        if (
            inspectionStatus !== CleaningInspectionEnum.cleaningInspectionStarted 
        ) {
            showByInspectionStatus = true;
        }
    } else if (cleaningStatus === CleaningStatusEnum.cleaningFinished) {
        if (currentCleaningStatus === CleaningStatusEnum.cleaningStarted) {
            showByStatus = true;
        }
        showByInspectionStatus = true; // ako je startao, inspection je noActionTaken
    } else if (
        [
            CleaningStatusEnum.cleaningStarted,
            CleaningStatusEnum.cleaningPostponed,
            CleaningStatusEnum.cleaningSkipped,
        ].includes(cleaningStatus)
    ) {
        if (
            [
                CleaningStatusEnum.noActionTaken,
                CleaningStatusEnum.cleaningRequested,
                CleaningStatusEnum.stagedForCleaning,
            ].includes(currentCleaningStatus)
        ) {
            showByStatus = true;
        }
        if (
            inspectionStatus !== CleaningInspectionEnum.cleaningInspectionStarted 
        ) {
            showByInspectionStatus = true;
        }
    }

    if (showByStatus && showByInspectionStatus) {
        showStatusItem = true;
    }
    return showStatusItem;
};
interface ListItemProps {
    cleaningStatus: CleaningStatusExtendedWithIncludedModels;

    loggedUser: UserInfo;
    //    setCleaningStatuses: Function;
}

interface MinibarListItemsProps {
    roomInfo: RoomInfoEditModel;
    onMinibarManagementClick: Function;
    onMinibarClick: Function;
}

interface CleaningNotesTextArea {
    setNote: Function;
    note: string | null;
    noteType: string | null;
    loggedUserName: string;
    cleaningStatus: CleaningStatusExtendedWithIncludedModels;
}
interface CleaningNotesListProps {
    cleaningNoteArray: string[];
    inspectionNoteArray: string[];
}

interface InspectionListPossibleItemsProps extends ListItemProps {
    loggedUserName: string;
}

const UpdateCleaningStatus = (props: UpdateCleaningStatusProps) => {
    const { cleaningStatus, onMinibarManagementClick, onMinibarClick, loggedUser } = props;

    const [note, setNote] = useState<string | null>(null);
    const { inspectionNote, cleaningNote } = cleaningStatus;
    const roomInfo = cleaningStatus.RoomInfo;
    const [noteType, setNoteType] = useState<string | null>('cleaningNote');
    const cleaningNoteArray = cleaningNote ? cleaningNote.split(';') : [];
    const inspectionNoteArray = inspectionNote ? inspectionNote.split(';') : [];
    const loggedUserName = `${loggedUser.name} ${loggedUser.surname}`;
    return (
        <div className="unselectable">
            <h5 className="mb-4 text-center ">{cleaningStatus.RoomInfo?.name}</h5>
            <ul className="list-group list-group-flush bg-white mt-2 ">
                <StagedForCleaningListItem cleaningStatus={cleaningStatus} loggedUser={loggedUser} />
                <CleaningFinishedListItem cleaningStatus={cleaningStatus} loggedUser={loggedUser} />
                <CompatibleListItems cleaningStatus={cleaningStatus} loggedUser={loggedUser} />
                <RequireInspectionListItem cleaningStatus={cleaningStatus} loggedUser={loggedUser} />
                <StartInspectionListItem cleaningStatus={cleaningStatus} loggedUser={loggedUser} />
                <InspectionListPossibleItems
                    cleaningStatus={cleaningStatus}
                    loggedUser={loggedUser}
                    loggedUserName={loggedUserName}
                />
                {roomInfo ? (
                    <MiniBarListItems
                        roomInfo={roomInfo}
                        onMinibarManagementClick={onMinibarManagementClick}
                        onMinibarClick={onMinibarClick}
                    />
                ) : null}
            </ul>
                  <div className="row mt-2">
                  <div
                      className="col-md-6 text-center custom-control custom-radio"
                      onClick={() => {
                          setNoteType('cleaningNote');
                      }}
                  >
                      <b>
                          <input type="radio" id="cleaningNote" name="customRadio" className="custom-control-input" onChange={()=>{}} checked={noteType==='cleaningNote'}/>
                          <label className="custom-control-label" htmlFor="cleaningNote">
                              Cleaning note
                          </label>
                      </b>
                  </div>
                  <div
                      className="col-md-6 text-center custom-control custom-radio"
                      onClick={() => {
                          setNoteType('inspectionNote');
                      }}
                  >
                      <b>
                          <input type="radio" id="inspectionNote" name="customRadio" className="custom-control-input" onChange={()=>{}} checked={noteType==='inspectionNote'} />
                          <label className="custom-control-label" htmlFor="inspectionNote">
                              Inspection note
                          </label>
                      </b>
                  </div>
              </div>
            <CleaningNotesList cleaningNoteArray={cleaningNoteArray} inspectionNoteArray={inspectionNoteArray} />
            <CleaningNotesTextArea
                cleaningStatus={cleaningStatus}
                note={note}
                setNote={setNote}
                loggedUserName={loggedUserName}
                noteType={noteType}
            />
        </div>
    );
};

const StagedForCleaningListItem = (props: ListItemProps) => {
    const { cleaningStatus } = props;
    const { cleaningStatus: cleaningStatusEnum, inspectionStatus } = cleaningStatus;
    // const cleaningStatusName: string = CleaningStatusEnum[cleaningStatusEnum];

    const stagedForCleaning: boolean = checkStatusAvailability(
        CleaningStatusEnum.stagedForCleaning,
        cleaningStatusEnum,
        inspectionStatus
    );

    if (!stagedForCleaning) {
        return null;
    }

    const tileStyle =
        cleaningStatus.reservationStatus === ReservationStatusEnum.dueOut
            ? {
                  color: 'white',
                  backgroundColor: '#631313',
              }
            : CleaningStatusColors.stagedForCleaning;
    const iconName: string = CleaningStatusIcons.stagedForCleaning;

    return (
        <React.Fragment>
            <li
                style={tileStyle}
                onClick={async () => {
                    const newCleaningStatus = { ...cleaningStatus };
                    newCleaningStatus.cleaningStatus = CleaningStatusEnum.stagedForCleaning;
                    await updateCleaningStatus(newCleaningStatus);
                }}
                className={'list-group-item pimatico-card-link mb-2 p-3'}
            >
                <div className="row">
                    <div className={'col-md-1 col-1 '}>
                        <i className={iconName} />
                    </div>
                    <div className="col-md-11 col-11">{translate('Stage for cleaning')}</div>
                </div>
            </li>
        </React.Fragment>
    );
};

const CleaningFinishedListItem = (props: ListItemProps) => {
    const { cleaningStatus } = props;
    const { cleaningStatus: cleaningStatusEnum, inspectionStatus } = cleaningStatus;

    const cleaningFinished: boolean = checkStatusAvailability(
        CleaningStatusEnum.cleaningFinished,
        cleaningStatusEnum,
        inspectionStatus
    );

    if (!cleaningFinished) {
        return null;
    }
    const tileStyle = CleaningStatusColors.cleaningFinished;
    const iconName: string = CleaningStatusIcons.cleaningFinished;
    return (
        <li
            style={tileStyle}
            onClick={async () => {
                const newCleaningStatus = { ...cleaningStatus };
                newCleaningStatus.cleaningStatus = CleaningStatusEnum.cleaningFinished;
                newCleaningStatus.cleaningFinishedTimestamp=moment().valueOf()
                await updateCleaningStatus(newCleaningStatus);
            }}
            className={'list-group-item pimatico-card-link mb-2 p-3'}
        >
            <div className="row">
                <div className={'col-md-1 col-1 '}>
                    <i className={iconName} />
                </div>
                <div className="col-md-11 col-11">{translate('Finish cleaning')}</div>
            </div>
        </li>
    );
};

const CompatibleListItems = (props: ListItemProps) => {
    // start cleaning, postpone cleaning, skipp cleaning
    const { cleaningStatus } = props;
    const { cleaningStatus: cleaningStatusEnum, inspectionStatus } = cleaningStatus;
    const startedOrPostponedOrSkiped: boolean = checkStatusAvailability(
        CleaningStatusEnum.cleaningStarted,
        cleaningStatusEnum,
        inspectionStatus
    ); // ako vrijedi za started vrijedi za sve

    if (!startedOrPostponedOrSkiped) {
        return null;
    }
    const tileStyleCleaningStarted = CleaningStatusColors.cleaningStarted;
    const iconNameCleaningStarted: string = CleaningStatusIcons.cleaningStarted;
    const tileStyleCleaningPostponed = CleaningStatusColors.cleaningPostponed;
    const iconNameCleaningPostponed: string = CleaningStatusIcons.cleaningPostponed;
    const tileStylecleaningSkipped = CleaningStatusColors.cleaningSkipped;
    const iconNamecleaningSkipped: string = CleaningStatusIcons.cleaningSkipped;
    return (
        <React.Fragment>
            <li
                key="cleaningStarted"
                style={tileStyleCleaningStarted}
                onClick={async () => {
                    const newCleaningStatus = { ...cleaningStatus };
                    newCleaningStatus.cleaningStatus = CleaningStatusEnum.cleaningStarted;
                    await updateCleaningStatus(newCleaningStatus);
                }}
                className={'list-group-item pimatico-card-link mb-2 p-3'}
            >
                <div className="row">
                    <div className={'col-md-1 col-1 '}>
                        <i className={iconNameCleaningStarted} />
                    </div>
                    <div className="col-md-11 col-11">{translate('Start cleaning')}</div>
                </div>
            </li>
            <li
                key="cleaningPostponed"
                style={tileStyleCleaningPostponed}
                onClick={async () => {
                    const newCleaningStatus = { ...cleaningStatus };
                    newCleaningStatus.cleaningStatus = CleaningStatusEnum.cleaningPostponed;
                    await updateCleaningStatus(newCleaningStatus);
                }}
                className={'list-group-item pimatico-card-link mb-2 p-3'}
            >
                <div className="row">
                    <div className={'col-md-1 col-1 '}>
                        <i className={iconNameCleaningPostponed} />
                    </div>
                    <div className="col-md-11 col-11">{translate('Postpone cleaning')}</div>
                </div>
            </li>
            <li
                key="cleaningSkipped"
                style={tileStylecleaningSkipped}
                onClick={async () => {
                    const newCleaningStatus = { ...cleaningStatus };
                    newCleaningStatus.cleaningStatus = CleaningStatusEnum.cleaningSkipped;
                    await updateCleaningStatus(newCleaningStatus);
                }}
                className={'list-group-item pimatico-card-link mb-2 p-3'}
            >
                <div className="row">
                    <div className={'col-md-1 col-1 '}>
                        <i className={iconNamecleaningSkipped} />
                    </div>
                    <div className="col-md-11 col-11">{translate('Skipp cleaning')}</div>
                </div>
            </li>
        </React.Fragment>
    );
};

const RequireInspectionListItem = (props: ListItemProps) => {
    const { cleaningStatus } = props;
    const { cleaningStatus: cleaningStatusEnum, inspectionStatus } = cleaningStatus;
    const allowedPersonel = checkRoleAccess('managerHousehold', 'managerFull', 'admin', 'maid');

    const showRequireInspection =
        cleaningStatusEnum === CleaningStatusEnum.cleaningFinished &&
        (inspectionStatus === CleaningInspectionEnum.noInspectionTaken || inspectionStatus === null);

    if (!allowedPersonel || !showRequireInspection) {
        return null;
    }
    const tileStyle = InspectionStatusColors.cleaningInspectionRequired;

    return (
        <li
            key="cleaningInspectionRequired"
            style={tileStyle}
            onClick={async () => {
                const newCleaningStatus = { ...cleaningStatus };
                newCleaningStatus.inspectionStatus = CleaningInspectionEnum.cleaningInspectionRequired;
                await updateCleaningStatus(newCleaningStatus);
            }}
            className={'list-group-item pimatico-card-link mb-2 p-3'}
        >
            <div className="row">
                <div className={'col-md-1 col-1 '}>
                    <i className={CleaningInspectionIcons.cleaningInspectionRequired} />
                </div>
                <div className="col-md-11 col-11">{translate('Require inspection')}</div>
            </div>
        </li>
    );
};

const StartInspectionListItem = (props: ListItemProps) => {
    const { cleaningStatus } = props;
    const { cleaningStatus: cleaningStatusEnum, inspectionStatus } = cleaningStatus;
    const allowedPersonel = checkRoleAccess('managerHousehold', 'managerFull', 'admin', 'maid');
    const showStartInspection =
        cleaningStatusEnum === CleaningStatusEnum.cleaningFinished &&
        (inspectionStatus === CleaningInspectionEnum.noInspectionTaken ||
            inspectionStatus === null ||
            inspectionStatus === CleaningInspectionEnum.cleaningInspectionRequired);
    if (!allowedPersonel || !showStartInspection) {
        return null;
    }
    const tileStyle = InspectionStatusColors.cleaningInspectionStarted;

    return (
        <li
            key="inspectionStarted"
            style={tileStyle}
            onClick={async () => {
                const newCleaningStatus = { ...cleaningStatus };
                newCleaningStatus.inspectionStatus = CleaningInspectionEnum.cleaningInspectionStarted;
                await updateCleaningStatus(newCleaningStatus);
            }}
            className={'list-group-item pimatico-card-link mb-2 p-3'}
        >
            <div className="row">
                <div className={'col-md-1 col-1 '}>
                    <i className={CleaningInspectionIcons.cleaningInspectionStarted} />
                </div>
                <div className="col-md-11 col-11">{translate('Start inspection')}</div>
            </div>
        </li>
    );
};

const InspectionListPossibleItems = (props: InspectionListPossibleItemsProps) => {
    const { cleaningStatus, loggedUserName } = props;
    const inspectionStatusEnum: number | null = cleaningStatus.inspectionStatus;

    const inspectionFailedOrPassed: boolean =
        inspectionStatusEnum === CleaningInspectionEnum.cleaningInspectionStarted ? true : false;
    if (!inspectionFailedOrPassed) {
        return null;
    }
    const tileStyleFailed = InspectionStatusColors.cleaningInspectionFailed;
    const tileStylePassed = InspectionStatusColors.cleaningInspectionPassed;

    const maid1Name = cleaningStatus.Maid1 ? `${cleaningStatus.Maid1.name} ${cleaningStatus.Maid1.surname},` : '';
    const maid2Name = cleaningStatus.Maid2 ? `${cleaningStatus.Maid2.name} ${cleaningStatus.Maid2.surname},` : '';
    const maid3Name = cleaningStatus.Maid3 ? `${cleaningStatus.Maid3.name} ${cleaningStatus.Maid3.surname},` : '';

    return (
        <React.Fragment>
            <li
                key="inspectionPassed"
                style={tileStylePassed}
                onClick={async () => {
                    const newCleaningStatus = { ...cleaningStatus };
                    newCleaningStatus.inspectionStatus = CleaningInspectionEnum.cleaningInspectionPassed;
                    if (newCleaningStatus.inspectionNote) {
                        newCleaningStatus.inspectionNote = `${
                            newCleaningStatus.inspectionNote
                        }${loggedUserName}: Cleaning inspection passed at ${moment().format(
                            'lll'
                        )}, cleaned by ${maid1Name} ${maid2Name} ${maid3Name};`;
                    } else {
                        newCleaningStatus.inspectionNote = `${loggedUserName}: Cleaning inspection passed at ${moment().format(
                            'lll'
                        )}, cleaned by ${maid1Name} ${maid2Name} ${maid3Name};`;
                    }
                    await updateCleaningStatus(newCleaningStatus);
                }}
                className={'list-group-item pimatico-card-link mb-2 p-3'}
            >
                <div className="row">
                    <div className={'col-md-1 col-1 '}>
                        <i className={CleaningInspectionIcons.cleaningInspectionPassed} />
                    </div>
                    <div className="col-md-11 col-11">{translate('Inspection passed')}</div>
                </div>
            </li>
            <li
                key="inspectionFailed"
                style={tileStyleFailed}
                onClick={async () => {
                    const newCleaningStatus = { ...cleaningStatus };
                    newCleaningStatus.inspectionStatus = CleaningInspectionEnum.cleaningInspectionFailed;
                    if (newCleaningStatus.inspectionNote) {
                        newCleaningStatus.inspectionNote = `${
                            newCleaningStatus.inspectionNote
                        }${loggedUserName}: Cleaning inspection failed at ${moment().format(
                            'lll'
                        )}, cleaned by ${maid1Name} ${maid2Name} ${maid3Name};`;
                    } else {
                        newCleaningStatus.inspectionNote = `${loggedUserName}: Cleaning inspection failed at ${moment().format(
                            'lll'
                        )}, cleaned by ${maid1Name} ${maid2Name} ${maid3Name};`;
                    }
                    await updateCleaningStatus(newCleaningStatus);
                }}
                className={'list-group-item pimatico-card-link mb-2 p-3'}
            >
                <div className="row">
                    <div className={'col-md-1 col-1 '}>
                        <i className={CleaningInspectionIcons.cleaningInspectionFailed} />
                    </div>
                    <div className="col-md-11 col-11">{translate('Inspection failed')}</div>
                </div>
            </li>
        </React.Fragment>
    );
};

const MiniBarListItems = (props: MinibarListItemsProps) => {
    const { /*roomInfo,*/ onMinibarManagementClick, onMinibarClick } = props;
    const hasMinibarItems = true /*roomInfo.posItemIds && roomInfo.posItemIds.length !== 0;*/
    return (
        <FeatureFlag flag="minibar">
            {hasMinibarItems ? (
                <li
                    onClick={() => {
                        onMinibarClick();
                        closeModal();
                    }}
                    className={'list-group-item pimatico-card-link mb-2 p-3 household border'}
                >
                    <div className="row">
                        <div className={'col-md-1 col-1 '}>
                            <i className="fa fa-braille" />
                        </div>
                        <div className="col-md-11 col-11">{translate('Check minibar')}</div>
                    </div>
                </li>
            ) : null}

            {hasMinibarItems && checkRoleAccess('managerHousehold', 'managerFull', 'admin', 'maid') ? (
                <li
                    onClick={() => {
                        onMinibarManagementClick();
                        closeModal();
                    }}
                    className={'list-group-item pimatico-card-link mb-2 p-3 household border'}
                >
                    <div className="row">
                        <div className={'col-md-1 col-1 '}>
                            <i className="fa fa-braille" />
                        </div>
                        <div className="col-md-11 col-11">{translate('Minibar management')}</div>
                    </div>
                </li>
            ) : null}
        </FeatureFlag>
    );
};

const CleaningNotesTextArea = (props: CleaningNotesTextArea) => {
    const { note, setNote, loggedUserName, cleaningStatus, noteType } = props;

    return (
        <div className="form-group mt-2">
            <textarea
                id="cleaningNote"
                autoComplete="off"
                name="cleaningNote"
                placeholder={translate('Note')}
                value={note ? note : ''}
                onChange={(e) => {
                    let value = e.target.value;
                    setNote(value);
                }}
                className="form-control"
            ></textarea>
            <div className="text-center mt-2">
                <button
                    onClick={async () => {
                        const noteWithDate = `${note}, ${moment().format('lll')}`;
                        const newCleaningStatus = { ...cleaningStatus };
                        if (noteType === 'cleaningNote') {
                            if (cleaningStatus.cleaningNote) {
                                newCleaningStatus.cleaningNote = `${newCleaningStatus.cleaningNote}${loggedUserName}: ${noteWithDate};`;
                            } else {
                                newCleaningStatus.cleaningNote = `${loggedUserName}: ${noteWithDate};`;
                            }
                        } else if (noteType === 'inspectionNote') {
                            if (newCleaningStatus.inspectionNote) {
                                newCleaningStatus.inspectionNote = `${newCleaningStatus.inspectionNote}${loggedUserName}: ${noteWithDate};`;
                            } else {
                                newCleaningStatus.inspectionNote = `${loggedUserName}: ${noteWithDate};`;
                            }
                        }
                        await updateCleaningStatus(newCleaningStatus);
                    }}
                    className="btn btn-sm btn-outline-primary" disabled={!note}
                >
                    {translate('Save note')}
                </button>
            </div>
        </div>
    );
};

const CleaningNotesList = (props: CleaningNotesListProps) => {
    const { cleaningNoteArray, inspectionNoteArray } = props;
    if (cleaningNoteArray.length === 0 && inspectionNoteArray.length === 0) {
        return null;
    }
    return (
        <div className="row mt-2">
            <div className="col-md-6">
                <small>
                    <ul className="list-group">
                        {cleaningNoteArray.map((cn: string, key: number) => {
                            if (cn.length > 0) {
                                return (
                                    <li className="list-group-item p-1" key={key}>
                                        <small>{cn}</small>
                                    </li>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </ul>
                </small>
            </div>
            <div className="col-md-6">
                <small>
                    <ul className="list-group">
                        {inspectionNoteArray.map((int: string, key: number) => {
                            if (int.length > 0) {
                                return (
                                    <li className="list-group-item p-1" key={key}>
                                        <small>{int}</small>
                                    </li>
                                );
                            } else {
                                return null;
                            }
                        })}
                    </ul>
                </small>
            </div>
        </div>
    );
};
export { UpdateCleaningStatus };
