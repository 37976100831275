import React, { useEffect, useState } from 'react';
import ReportTable from '../components/ReportTable';
import PageTitle from '../components/pageTitle';
import { getReportData, getExcelExport } from '../data/reports';

function MinibarLogBook(props) {
    const [yearlyMinibarLogs, setYearlyMinibarLogs] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(0);
    const [_page, setPage] = React.useState(0);

    useEffect(() => {
        const loadData = async () => {
            reloadData(year);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const reloadData = async (year, page=_page) => {
        const _yearlyMinibarLogs = await getReportData('minibarLogs', { year, page });
        setYearlyMinibarLogs([..._yearlyMinibarLogs]);
        setPage(page)
    };

    const _setYear = async (e) => {
        setYear(Number(e.target.value));
        await reloadData(Number(e.target.value));
    };

    const headers = [
        {
            filterKey: 'minibarlogId',
            headerTitle: window.translate('MinibarLog id'),
            label: 'minibarlogId',
            type: 'num',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '90px',
        },
        {
            filterKey: 'itemName',
            headerTitle: window.translate('Item name'),
            label: 'itemName',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'previousState',
            headerTitle: window.translate('Previous state'),
            label: 'previousState',
            type: 'num',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '80px',
        },

        {
            filterKey: 'newState',
            headerTitle: window.translate('New state'),
            label: 'newState',
            type: 'num',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '80px',
        },
        {
            filterKey: 'logType',
            headerTitle: window.translate('Log type'),
            label: 'logType',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '150px',
        },
        {
            filterKey: 'roomName',
            headerTitle: window.translate('Room'),
            label: 'roomName',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'reservationId',
            headerTitle: window.translate('Reservation'),
            label: 'reservationId',
            type: 'num',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '80px',
        },
        {
            filterKey: 'createdAt',
            headerTitle: window.translate('Created At'),
            label: 'createdAt',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'userName',
            headerTitle: window.translate('User'),
            label: 'userName',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '150px',
        },
    ];

    return (
        <div className="">
            <PageTitle />

            <ReportTable
                year={year}
                month={month}
                setYear={_setYear.bind(this)}
                onYearMonthChange={async (filter) => {
                    setYear(filter.year);
                    setMonth(filter.month);
                    await reloadData(filter.year);
                }}
                itemsArray={yearlyMinibarLogs}
                headers={headers}
                monthFilter={true}
                history={props.history}
                targetedColumnForMonthFilter={'createdAt'}
                targetedColumnForDateFilter={'createdAt'}
                showDateFilter={true}
                xlsExportHandler={(params) => {
                    getExcelExport('minibarLogs', params);
                }}
                loadData={(page) => {
                    reloadData(year, page);
                }}
                page={_page}
                listOfItems={yearlyMinibarLogs}
            ></ReportTable>
        </div>
    );
}

export default MinibarLogBook;
