import React, { Component, Fragment } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import { closeModal } from '@components/modal';

class AttributeFormModal extends Component {
    constructor() {
        super();
        this.state = {
            roomInfo: null,
        };
    }

    async componentDidMount() {
        this.redrawComponent();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.roomInfo !== this.props.roomInfo) {
            this.redrawComponent();
        }
    }

    async redrawComponent() {
        let roomInfo = _.clone(this.props.roomInfo);
        let roomAttributes = roomInfo['roomAttributes']
            ? JSON.parse(roomInfo['roomAttributes'])
            : roomInfo['roomAttributes'];
        roomInfo['roomAttributes'] = roomAttributes;
        this.setState({ roomInfo });
    }

    handleAttributesSelect(event) {
        let roomInfo = _.clone(this.state.roomInfo);
        roomInfo['roomAttributes'] = this.state.roomInfo['roomAttributes']
            ? _.clone(this.state.roomInfo['roomAttributes'])
            : [];
        let option = Number(event.target.value);
        if (event.target.checked) {
            roomInfo['roomAttributes'].push(option);
        } else {
            _.remove(roomInfo['roomAttributes'], (i) => i === option);
        }
        this.setState({ roomInfo });
    }

    async onSave() {
        let roomInfo = _.cloneDeep(this.state.roomInfo);
        if (this.props.onSave) {
            this.props.onSave(JSON.stringify(roomInfo.roomAttributes));
        }
        closeModal();
    }

    render() {
        let roomInfo = this.state.roomInfo ? this.state.roomInfo : [];
        let attributesRender = _.map(this.props.attributes, (att, key) => {
            return (
                <Fragment key={'option' + att.name}>
                    <input
                        hidden={true}
                        className=""
                        type="checkbox"
                        name={window.translate(att.name)}
                        value={att && att.id ? att.id : ''}
                        id={key + `id${att.id}`}
                        checked={_.includes(roomInfo.roomAttributes, att.id)}
                        onChange={this.handleAttributesSelect.bind(this)}
                    />
                    <label className="mb-1 mt-1 unselectable" htmlFor={key + `id${att.id}`}>
                        <img className="" src={att.icon} alt="" />
                    </label>
                </Fragment>
            );
        });

        return (
            <div className="container-fluid icons">
                <div className="row justify-content-center">
                    <div className="col-9">
                        <div className="row align-items-center">{attributesRender}</div>
                    </div>
                    <div className="col-3 text-center border-left">
                        <div className="row justify-content-center">
                            <h5 className="border-bottom">{window.translate('Legend')}</h5>
                        </div>
                        {_.map(this.props.attributes, (att) => {
                            return (
                                <div className="row justify-content-center mt-1" key={att.id}>
                                    <div className="col-md-3 ">
                                        <img
                                            className=""
                                            src={att.icon}
                                            alt=""
                                            style={{ width: '20px', height: '20px' }}
                                        />
                                    </div>
                                    <div
                                        className="col-md-9 pointer"
                                        onClick={() =>
                                            this.handleAttributesSelect({
                                                target: {
                                                    value: att.id.toString(),
                                                    checked:
                                                        _.includes(roomInfo.roomAttributes, att.id) === false
                                                            ? true
                                                            : false,
                                                },
                                            })
                                        }
                                    >
                                        {window.translate(att.name)}
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="text-center mt-3">
                    <button onClick={this.onSave.bind(this)} className="btn btn-outline-primary">
                        {window.translate('Save')}
                    </button>
                    <div onClick={closeModal.bind(this)} className="btn btn-outline-secondary ml-2 pointer">
                        {window.translate('Cancel')}
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        attributes: ['model', 'RoomAttribute'],
    },
    AttributeFormModal
);
