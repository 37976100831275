import React, { Component } from 'react';
import _ from 'lodash';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';
import TimePicker from 'rc-time-picker';
import moment from 'moment';

class DuplicateDocuments extends Component {
    constructor() {
        super();
        this.state = {
            month: new Date(),
            disabledDates: [],
        };
    }
    changeMonth = (month) => {
        this.setState({ month: month });
    };
    render() {
        const { selectedFiscalPrinter } = this.props;
        let payload = {};

        if (this.props.periodOrNumber === 'Period') {
            payload = {
                option: 'duplicateDocument',
                fiscalPrinter: selectedFiscalPrinter,
                periodOrNumber: this.props.periodOrNumber,
                from: this.props.duplicateFrom,
                to: this.props.duplicateTo,
                type: this.props.type,
            };
        } else if (this.props.periodOrNumber === 'Document number') {
            payload = {
                option: 'duplicateDocument',
                fiscalPrinter: selectedFiscalPrinter,
                periodOrNumber: this.props.periodOrNumber,
                from: this.props.fromFiscalReceiptId,
                to: this.props.toFiscalReceiptId,
                type: this.props.type,
            };
        }
        let from = this.props.duplicateFrom ? new Date(this.props.duplicateFrom) : new Date();
        let to = this.props.duplicateTo ? new Date(this.props.duplicateTo) : new Date();
        return (
            <div className="col-12 col-md-6 p-0 m-0 mb-2 pull-left ">
                <div className="m-1 card bg-white h-100 ">
                    <div className="card-body">
                        <h5 className="card-title">{window.translate("Create duplicate of fiscal printer document")}</h5>
                        <p className="card-text">
                            <small>{window.translate("Select date period or document number")}</small>
                        </p>
                        <div className="form-group row justify-content-center align-items-center">
                            <label className="col-md-4 col-form-label" htmlFor="">
                                {window.translate("Query type")}:
                            </label>
                            <div className="col-md-8">
                                {_.map(['Period', 'Document number'], (periodOrNumber) => {
                                    return (
                                        <div className="form-check text-left" key={periodOrNumber}>
                                            <input
                                                className="form-check-input"
                                                type="radio"
                                                name={periodOrNumber}
                                                id={periodOrNumber}
                                                value={periodOrNumber}
                                                checked={
                                                    this.props.periodOrNumber &&
                                                    this.props.periodOrNumber === periodOrNumber
                                                        ? true
                                                        : false
                                                }
                                                onChange={this.props.handlePeriodOrDocumentNumber.bind(this)}
                                            />
                                            <label className="form-check-label" htmlFor={periodOrNumber}>
                                                {window.translate(periodOrNumber)}
                                            </label>
                                        </div>
                                    );
                                })}
                            </div>
                        </div>
                        <div className="form-group row justify-content-left align-items-center mt-2 mb-2">
                            <label className="col-md-4 col-form-label" htmlFor="">
                                {window.translate("Document type")}:
                            </label>
                            <div className="col-md-8">
                                <select
                                    value={this.props.type}
                                    onChange={this.props.handleSelectType.bind(this)}
                                    name="type"
                                    className="form-control form-control-sm"
                                >
                                    <option value="A">{window.translate("All documents")}</option>
                                    <option value="F">{window.translate("Fiscal receipts")}</option>
                                    <option value="R">{window.translate("Reclamation receipts")}</option>
                                    <option value="Z">{window.translate("Z report")}</option>
                                    <option value="X">{window.translate("X report")}</option>
                                    <option value="P">{window.translate("Periodic report")}</option>
                                </select>
                            </div>
                        </div>

                        {this.props.periodOrNumber === 'Period' ? (
                            <div className="form-group row justify-content-left align-items-center mt-2 mb-2">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {window.translate("From")}:
                                </label>
                                <div className="InputSelectDate col-md-5">
                                    <DayPickerInput
                                        value={from}
                                        placeholder="Select Date"
                                        format="LL"
                                        inputProps={{ className: 'form-control form-control-sm' }}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        onDayChange={this.props.handleSelectDateChange.bind(this, 'duplicateFrom')}
                                        dayPickerProps={{
                                            onMonthChange: this.changeMonth,
                                            modifiers: {
                                                disabled: this.state.disabledDates,
                                            },
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <TimePicker
                                        id="timeFrom"
                                        allowEmpty={false}
                                        showSecond={false}
                                        value={moment(this.props.duplicateFrom)}
                                        onChange={this.props.handleTimeChange.bind(this, 'timeFrom')}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row justify-content-left align-items-center mt-2 mb-2">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {window.translate("From")}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        name="from"
                                        value={this.props.fromFiscalReceiptId ? this.props.fromFiscalReceiptId : 0}
                                        onChange={this.props.handleSelectFiscalReceiptId.bind(this, 'from')}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        )}
                        {this.props.periodOrNumber === 'Period' ? (
                            <div className="form-group row justify-content-left align-items-center mt-2 mb-2">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {window.translate("To")}:
                                </label>
                                <div className="InputSelectDate col-md-5">
                                    <DayPickerInput
                                        value={to}
                                        placeholder="Select Date"
                                        format="LL"
                                        inputProps={{ className: 'form-control form-control-sm' }}
                                        formatDate={formatDate}
                                        parseDate={parseDate}
                                        onDayChange={this.props.handleSelectDateChange.bind(this, 'duplicateTo')}
                                        dayPickerProps={{
                                            onMonthChange: this.changeMonth,
                                            modifiers: {
                                                disabled: this.state.disabledDates,
                                            },
                                        }}
                                    />
                                </div>
                                <div className="col-md-3">
                                    <TimePicker
                                        id="timeTo"
                                        allowEmpty={false}
                                        showSecond={false}
                                        value={moment(this.props.duplicateTo)}
                                        onChange={this.props.handleTimeChange.bind(this, 'timeTo')}
                                    />
                                </div>
                            </div>
                        ) : (
                            <div className="form-group row justify-content-left align-items-center mt-2 mb-2">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {window.translate("To: (Optionally, if skipped, only a copy of the entered document will be printed)")}
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        name="to"
                                        value={this.props.toFiscalReceiptId ? this.props.toFiscalReceiptId : 0}
                                        onChange={this.props.handleSelectFiscalReceiptId.bind(this, 'to')}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        )}

                        <button
                            className="btn btn-sm btn-primary mr-2 mt-3"
                            onClick={this.props.showConfirmModal.bind(this, payload)}
                        >
                            {window.translate("Issue duplicate")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DuplicateDocuments;
