import * as React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { translate } from '@data/translations';
import { ReservationController, Reservation } from '../resController';
import { ReservationStatus, MinibarLogWithIncludedModels, RoomInfoModel } from '@common/modelDefinition';
import FeatureFlag from '@components/FeatureFlag';
import { setModalContent } from '@components/modal';
import Delete from '@components/confirmModal';
import { getMinibarData, MinibarLogsGroupByDate } from '@pages/household/minibar/utils';
import { ConsumptionInvoices } from './PosTerminalConsumption';


interface MinibarConsumptionProps {
    reservation: Reservation;
    history: any;
    rooms: RoomInfoModel[];
    defaultCurrency: string | null;
    roomInfoId?: number | null;
    match: any;
}

interface ConsumptionLogProps {
    logs: MinibarLogsGroupByDate;
    defCurrency: string | null;
    deleteMinibarData: any;
    showRes: boolean | null;
    message?: string | null;
}

function MinibarConsumption(props: MinibarConsumptionProps) {
    const { reservation, rooms, defaultCurrency, history } = props;

    const ctrl = new ReservationController();

    const [isExpanded, setIsExpanded] = React.useState(false);
    const minibarData = getMinibarData(reservation.MinibarLogs, true);
    const minibarProformaInvoices: any[] = reservation.Invoices.filter(
        (inv: any) => inv.terminalType === 'minibar'
    );
    const roomInfoId = props?.match?.params?.roomInfoId
        ? Number(props.match.params.roomInfoId)
        : Number(props.roomInfoId);

    const reservationRoomInfoId = reservation ? reservation.roomInfoId : roomInfoId;
    const room = rooms.find((r) => r.id === reservationRoomInfoId);

    const deleteMinibarData = (item: MinibarLogWithIncludedModels) => {
        setModalContent(
            <Delete actionForYes={() => ctrl.deleteMinibarDataLog(item)} />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const showCreateMinibarProformaInvoice = () => {
        setModalContent(
            <Delete
                content="Are you sure you want create proforma?"
                actionForYes={async () => {
                    if (reservation.id) {
                        await ctrl.createMinibarProformaInvoice(reservation.id);
                    }
                }}
            />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };

    if (reservation.id === null || !reservation.id) {
        return null;
    }
    const message =
        reservation.statusEnum !== ReservationStatus.checkedIn
            ? 'Reservation is not checked in.'
            : room && room.posItemIds === null
            ? 'First add items in room minibar.'
            : null;

    const headerClass = isExpanded
        ? 'pointer text-center border-bottom border-top uppercase text-primary  p-2'
        : 'pointer text-center border-top uppercase text-primary  p-2'; //no undeline

    return (
        <FeatureFlag flag="minibar">
            <div className="mt-2">
                <h5
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                    className={headerClass}
                >
                    <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                    &ensp;{translate('Minibar consumption')}&ensp;
                    <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                </h5>

                {isExpanded ? (
                    <div>
                        {room && props.history.location.pathname !== '/household/minibar/' + room.id ? (
                            <div className="btn-group">
                                <button
                                    onClick={() => {
                                        props.history.push('/household/minibar/' + room.id);
                                    }}
                                    className="btn btn-sm btn-info mr-2"
                                    disabled={
                                        reservation.statusEnum !== ReservationStatus.checkedIn ||
                                        (room && room.posItemIds === null)
                                    }
                                >
                                    <i className="fa fa-plus" /> {translate('ADD')}
                                </button>
                                <button
                                    onClick={() => {
                                        showCreateMinibarProformaInvoice();
                                    }}
                                    className="btn btn-sm btn-primary mr-2"
                                    disabled={!minibarData || Object.keys(minibarData).length === 0}
                                >
                                    <i className="fa fa-plus" /> {translate('Create proforma')}
                                </button>
                            </div>
                        ) : null}

                        <ConsumptionInvoices
                            history={history}
                            reservation={reservation}
                            consumptionInvoices={minibarProformaInvoices}
                        />
                        <ConsumptionLog
                            logs={minibarData}
                            defCurrency={defaultCurrency}
                            deleteMinibarData={deleteMinibarData}
                            showRes={false}
                            message={message}
                        />
                    </div>
                ) : null}
            </div>
        </FeatureFlag>
    );
}

const ConsumptionLog = (props: ConsumptionLogProps) => {
    const { logs, defCurrency = null, deleteMinibarData, showRes = false, message } = props;

    if (!logs || Object.keys(logs).length === 0) {
        return <small>{message ? translate(message) : translate('No recorded minibar consumption')}</small>;
    }
    return (
        <div className="mt-2">
            {(Object.keys(logs) as Array<string>).map((date: string) => {
                let dailyTotal = 0;
                logs[date].forEach((l: MinibarLogWithIncludedModels) => {
                    const count = l.previousState - l.newState;
                    const posItemPrice = l.PosItem && l.PosItem.price ? l.PosItem.price : 0;
                    const total = posItemPrice * count;
                    dailyTotal = dailyTotal + total;
                });
                return (
                    <div key={date}>
                        <h6 className="pointer pull-right">{date}</h6>
                        <ul className="list-group clear">
                            <li className="list-group-item">
                                <small>
                                    <div className="row">
                                        <div className="col-3 col-md-3">
                                            <b>{translate('ITEM NAME')}</b>
                                        </div>
                                        <div className="col-3 col-md-3">{translate('COUNT')}</div>
                                        <div className="col-2 col-md-2">{translate('PRICE')}</div>
                                        <div className="col-2 col-md-2">{translate('TOTAL')}</div>
                                        {showRes ? <div className="col-1 col-md-1">{translate('R.ID')}</div> : null}
                                        {showRes ? (
                                            <div className="col-1 col-md-1"></div>
                                        ) : (
                                            <div className="col-2 col-md-2"></div>
                                        )}
                                    </div>
                                </small>
                            </li>
                            {logs[date].map((log: MinibarLogWithIncludedModels) => {
                                const count = log.previousState - log.newState;
                                const posItemName = log.PosItem ? log.PosItem.name : '';
                                const posItemPrice = log.PosItem && log.PosItem.price ? log.PosItem.price : 0;
                                const total = posItemPrice * count;

                                const disabledStyle: any = log.InvoiceItem
                                    ? { pointerEvents: 'none', opacity: 0.7 }
                                    : {};
                                return (
                                    <li key={log.id} className="list-group-item" style={disabledStyle}>
                                        <small>
                                            <div className="row">
                                                <div className="col-3 col-md-3">
                                                    <b>{posItemName}</b>
                                                </div>
                                                <div className="col-3 col-md-3">{count}</div>
                                                <div className="col-2 col-md-2">
                                                    {posItemPrice} {defCurrency}
                                                </div>
                                                <div className="col-2 col-md-2">
                                                    {total} {defCurrency}
                                                </div>
                                                {showRes ? (
                                                    <div className="col-1 col-md-1 pointer">
                                                        {log.reservationId ? log.reservationId : ''}
                                                    </div>
                                                ) : null}
                                                {log.logType === 0 ? null : showRes ? (
                                                    <div
                                                        onClick={deleteMinibarData.bind(this, log)}
                                                        className="col-1 col-md-1 pointer"
                                                    >
                                                        <i className="fa fa-trash" />
                                                    </div>
                                                ) : (
                                                    <div
                                                        onClick={deleteMinibarData.bind(this, log)}
                                                        className="col-2 col-md-2 pointer"
                                                    >
                                                        <i className="fa fa-trash" />
                                                    </div>
                                                )}
                                            </div>
                                        </small>
                                    </li>
                                );
                            })}
                        </ul>
                        <br />
                        {showRes === false ? (
                            <h6 className="col-12 col-md-8 text-right">
                                <small>
                                    {translate('Daily total')}: {dailyTotal} {defCurrency}
                                </small>
                            </h6>
                        ) : null}

                        <br />
                    </div>
                );
            })}
        </div>
    );
};

export { ConsumptionLog };

export default branch(
    {
        defaultCurrency: ['defaultCurrency'],
        rooms: ['model', 'RoomInfo'],
    },
    MinibarConsumption
);
