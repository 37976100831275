import React, { useState, useEffect } from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import rest from '@data/restWrapper';
import { setModalContent } from '@components/modal';
import PageTitle from '@components/pageTitle';
import countryCodes from '@data/countryCodesIndexed';
import ConfirmModal from '@components/confirmModal';
import translate from '@data/translations';
import { CustomerModel, UserInfo } from '@common/modelDefinition';

interface CustomersPagedListProps {
    loggedUser: UserInfo;
    history: any;
}

function CustomersPagedList(props: CustomersPagedListProps) {
    const [customers, setCustomers] = useState([] as CustomerModel[]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rows] = useState(500);
    const [customersFromSearchBox, setCustomersFromSearchBox] = useState([] as CustomerModel[]);
    let searchDebouncer: any = null;

    useEffect(() => {
        const loadData = async () => {
            getPageData();
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const getPageData = async (_page = page) => {
        const _customers: CustomerModel[] = await rest('/Customer?page=' + _page + '&rows=' + rows);
        setCustomers(_customers);
        setPage(_page);
    };

    const onSearch = async (e: any) => {
        clearTimeout(searchDebouncer);
        const _searchTerm = e.target.value;
        searchDebouncer = setTimeout(async () => {
            if (_searchTerm.length > 1) {
                const path = '/searchCustomer?search=' + _searchTerm;
                const _customersFromSearchBox = await rest(path);
                setCustomersFromSearchBox(_customersFromSearchBox);
                setSearchTerm(_searchTerm);
            } else {
                setCustomersFromSearchBox([]);
                setSearchTerm('');
            }
        }, 400);
    };

    const pageDown = async () => {
        if (page > 0) {
            let _page = page - 1;
            await getPageData(_page);
        }
    };

    const pageUp = async () => {
        if (customers.length > 0) {
            let _page = page + 1;
            await getPageData(_page);
        }
    };

    const checkboxHandler = async (customer: CustomerModel, name: any) => { 
        try {
            const _customers = [...customers];
            const _customersFromSearchBox = [...customersFromSearchBox];

            const c: any = _customers.find((it) => it.id === customer.id);
            const c2: any = _customersFromSearchBox.find((it) => it.id === customer.id);

            c[name] = !c[name];
            if (c2) {
                c2[name] = !c[name];
            }
            const payload = { [name]: c[name], id: null };
            payload.id = c.id;
            await rest('/createOrUpdateCustomer', 'POST', payload);
            setCustomers(_customers);
            setCustomersFromSearchBox(_customersFromSearchBox);
        } catch (error: any) {
            console.log("error",error)
            await getPageData();
        }
    };

    const _customers = searchTerm.length > 0 ? customersFromSearchBox : customers;
    const { loggedUser, history } = props;

    const customersList = _customers.map((c, index) => {
        return (
            <CustomerListItem
                key={index}
                c={c}
                index={index}
                history={history}
                loggedUser={loggedUser}
                checkboxHandler={checkboxHandler}
            />
        );
    });

    return (
        <div className="">
            <PageTitle />
            <div className="row ">
                <div className="d-flex col-12 ">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {translate('GO BACK')}
                    </button>
                    <button
                        onClick={() => {
                            props.history.push('/customers/new');
                        }}
                        className="btn btn-sm btn-primary ml-2"
                        type="submit"
                    >
                        {translate('ADD NEW')}
                    </button>
                    <div className="ml-2  d-none d-sm-block">
                        <input
                            onChange={onSearch}
                            placeholder={translate('SEARCH FOR CUSTOMER (by first and last name, email, document id)')}
                            type="text"
                            className="form-control form-control-sm"
                        />
                    </div>

                    <div className="ml-auto align-self-end">
                        <button onClick={pageDown} className="btn btn-sm btn-outline-primary mr-2">
                            {translate('prev')}
                        </button>
                        <small className="mt-1">
                            {translate('page')} {page}
                        </small>
                        <button onClick={pageUp} className="btn btn-sm btn-outline-primary ml-2">
                            {translate('next')}
                        </button>
                    </div>
                </div>
            </div>

            <ul className="list-group mt-3 mx-3">
                <small>
                    <li className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                        <div className="col-6 col-sm-3  elipsis">{translate("First and last name")+", "+translate("DocumentId")}</div>
                        <div className="col-6 col-sm-3 elipsis">{translate("Contact")+", "+translate("Address")}</div>
                        <div className="col-6 col-sm-3 ">{translate("GDPR options")}</div>
                        <div className="col-6 col-sm-3"></div>
                    </li>
                </small>
            </ul>

            <ul className="list-group mt-2 mx-3">{customersList}</ul>
        </div>
    );
}

interface CustomerListItemProps {
    c: CustomerModel;
    history: any;
    loggedUser: UserInfo;
    checkboxHandler: any;
    index: number;
}

const CustomerListItem = (props: CustomerListItemProps) => {
    const { c, history, loggedUser, checkboxHandler } = props;

    const showGDPROptions = loggedUser ? loggedUser.role === 'gdprOfficer' : false;
    const country = c.country ? countryCodes[c.country] : null;

    const contacts = [];
    const address = [];

    if (c.email) {
        contacts.push(c.email);
    }

    if (c.phoneOrMobile) {
        contacts.push(c.phoneOrMobile);
    }

    if (c.town) {
        address.push(c.town);
    }

    if (c.address) {
        address.push(c.address);
    }

    if (country) {
        address.push(country['alpha-3']);
    }

    const openModal = (customer: CustomerModel, name: string) => {
        setModalContent(
            <ConfirmModal
                actionForYes={async () => {
                    try {
                        checkboxHandler(customer, name);
                    } catch (err) {
                        console.warn(err);
                    }
                }}
                content={translate('Basic GDPR withdrawal will mask every reference to this customer. Please confirm?')}
            />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };

    return (
        <li key={'c' + c.id} className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
            <div className="col-6 col-sm-3 elipsis" style={{ userSelect: "text" }}>
                {' '}
                {c.firstName} {c.lastName}
                {c.documentId ? <span>, {c.documentId}</span> : ''}
            </div>

            <div className="col-6 col-sm-3 elipsis" style={{ userSelect: "text" }}>
                {contacts.join('; ')}
                <div>{address.join('; ')}</div>
            </div>

            {showGDPROptions ? (
                <div className="col-6 col-sm-3 elipsis unselectable">
                    <span onClick={openModal.bind(this, c, 'gdprBasicConsent')} className="pointer">
                        {' '}
                        <input type="checkbox" name="gdprBasicConsent" checked={c.gdprBasicConsent} />{' '}
                        {translate('Basic consent')}
                    </span>
                    <br />
                    <span
                        onClick={() => {
                            checkboxHandler(c, 'gdprMarketing');
                        }}
                        className="pointer"
                    >
                        <input type="checkbox" name="gdprMarketing" checked={c.gdprMarketing} />{' '}
                        {translate('Marketing')}
                    </span>
                    <br />
                    <span
                        onClick={() => {
                            checkboxHandler(c, 'gdprPreferences');
                        }}
                        className="pointer"
                    >
                        {' '}
                        <input type="checkbox" name="gdprPreferences" checked={c.gdprPreferences} />{' '}
                        {translate('Preferences')}
                    </span>
                </div>
            ) : (
                <div className="col-6 col-sm-3 elipsis unselectable">{"("+translate('GDPR OPTIONS')+")"}</div>
            )}

            <div className="col-6 col-sm-3">
                {c.gdprDecoded ? (
                    <div className="pt-2 pr-2">
                        <button
                            onClick={() => {
                                history.push('/customers/' + c.id + '/edit');
                            }}
                            className="btn btn-sm btn-outline-dark pull-right ml-1 mb-1"
                        >
                            <i className="fa fa-edit"></i>
                        </button>
                    </div>
                ) : null}
            </div>
        </li>
    );
};

export default branch(
    {
        loggedUser: ['authTokenInfo', 'user'],
    },
    CustomersPagedList
);
