import rest from '../../data/restWrapper';
import { startGlobalLoading, stopGlobalLoading } from '../../components/loaders/globalLoading';
// import SosResponseForm from './sosResponseForm';
import { closeModal } from '../../components/modal';

//software backed ticket close
async function forceDiscardRoomEventTicket(id, notes) {
	startGlobalLoading();
	try {
		const ticket = await rest('/forceDiscardRoomEventTicket', 'POST', { id, notes });
		closeModal();
		stopGlobalLoading();
		return ticket;
	} catch (err) {
		stopGlobalLoading();
		console.warn(err);
	}
}

//this action will initiate hardware closure on SRC controller.
//if hardware is not connected, then closure would fail. Then you can use forceDiscardRoomEventTicket
async function hardwareCloseRoomEventTicket(remoteDeviceId, roomEvent) {
	startGlobalLoading();
	try {
		const eventClone = { ...roomEvent };
		eventClone.value = false; //turn off
		await rest('/src/receptionSetToggleStates/' + remoteDeviceId, 'POST', eventClone);
		closeModal();
		stopGlobalLoading();
	} catch (err) {
		stopGlobalLoading();
		console.warn(err);
	}

}


export {forceDiscardRoomEventTicket,hardwareCloseRoomEventTicket }