import React from 'react';
import baobab from '../../data/state';
import rest from '../../data/restWrapper';
import {userFrendlyAlarmAndEventsNames} from "../../data/SRCAlarmAndAlertController"
import baobabTree from '../../data/state/index';

const Subscriptions = (props) => {
    const [selectedEvents, setSelectedEvents] = React.useState([]);
    const [dataChange, setDataChange] = React.useState(false);

    React.useEffect(() => {
        const loadData = async () => {
            if (props.userUuid) {
                const user = await rest(`/getUser/${props.userUuid.uuid}`);
                const _selectedEvents = user.settings.notificationSettings
                    ? user.settings.notificationSettings.subscribed
                    : user.settings;
                setSelectedEvents(_selectedEvents);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, [props.userUuid]);

    React.useEffect(() => {}, [dataChange]);

    const cancelHandler = () => {
        baobab.root.select(['modalData', 'display']).set(false);
        setDataChange(!dataChange)
    };

    const saveSelectedEvents = async () => {
        try {
            const obj = {
                notificationSettings: {
                    subscribed: selectedEvents,
                },
            };
            await rest('/saveNotificationSettings/' + props.userUuid.uuid, 'POST', obj);
            baobabTree.root.select(['authTokenInfo', "user", "settings", "notificationSettings", "subscribed"]).set(selectedEvents)
        } catch (error) {
            console.log('err', error);
        }
        cancelHandler();
    };

    return (
        <div>
            <div className="row justify-content-center mb-2">
                <div>{window.translate('Select the events you want to subscribe to')}</div>
            </div>
            {Object.keys(userFrendlyAlarmAndEventsNames).map((e, index) => {
                const checkboxIcon = selectedEvents.includes(e) ? (
                    <i className="fa fa-2x fa-check-square-o pull-left" />
                ) : (
                    <i className="fa fa-2x fa-square-o pull-left" />
                );
                return (
                    <div
                        onClick={() => {
                            if (selectedEvents.includes(e)) {
                                let _selectedEvents = selectedEvents;
                                _selectedEvents = _selectedEvents.filter((sl) => sl !== e);
                                setSelectedEvents(_selectedEvents);
                                setDataChange(!dataChange);
                            } else {
                                const _selectedEvents = selectedEvents;
                                _selectedEvents.push(e);
                                setSelectedEvents(_selectedEvents);
                                setDataChange(!dataChange);
                            }
                        }}
                        key={index}
                        className="list-group-item pointer"
                    >
                        {checkboxIcon}
                        {userFrendlyAlarmAndEventsNames[e]}
                    </div>
                );
            })}
            <div className="text-center mt-3">
                <div onClick={saveSelectedEvents} className="btn btn-outline-primary mr-2 pointer">
                    {window.translate('Confirm')}
                </div>
            </div>
        </div>
    );
};

export default Subscriptions;
