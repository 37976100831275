import React, { Component } from 'react';
//import _ from 'lodash';
import { deleteModelData } from '../../data/actions/modelActions';
import { branch } from 'baobab-react/higher-order';
import { setModalContent } from '@components/modal';
import Delete from '../../components/confirmModal';
import PageTitle from '../../components/pageTitle';
import FeatureFlag from '../../components/FeatureFlag';
import moment from 'moment/min/moment-with-locales';
import TestHWModalComponent from "./testHWModalComponent";
import rest from '../../data/restWrapper';
class SRCProxyManagement extends Component {
    constructor(params) {
        super();
        this.state = {
            page: 0,
            rows: 20,
            srcProxies: [],
        };
    }

    async deleteItem(itemId) {
        try {
            await deleteModelData('SRCProxy', itemId);
        } catch (error) {
            console.log(error);
        }
    }

    async restartProxy(itemId) {
        try {
            rest("/restartSRCProxy", "POST", {srcProxyUuid:itemId})
        } catch (error) {
            console.log(error);
        }
    }

    showDeleteModal(itemId) {
        setModalContent(
            <Delete actionForYes={this.deleteItem.bind(this, itemId)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    }
    showTestHWModal(itemId) {
        setModalContent(
            <TestHWModalComponent selectedSRCProxyForTestId={itemId}/>,
            window.translate('Test'),
            false,
            'modal-md'
        );
    }
    showResetProxyModal(itemId) {
        setModalContent(
            <Delete actionForYes={this.restartProxy.bind(this, itemId)} content="Are you sure you want to restart?" />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    }

    render() {
        let SRCProxies = this.props.srcProxies.map((s, index) => {
            return (
                <li key={s.id} style={{ listStyle: 'none' }} className={'px-2 py-2 shadow-sm bg-white my-1 rounded'}>
                    {' '}
                    <div className="row">
                        <div className="col-4">
                            {s.name}
                            <br />
                            <small>{s.description}</small>
                        </div>
                        <div className="col-2">
                            {s.lastSeenTimestamp ? (
                                <span>
                                    <i className="fa fa-plug" /> {moment(s.lastSeenTimestamp).fromNow()}
                                </span>
                            ) : null}
                        </div>
                        <div className="col-6 pt-2 pl-2">
                            <button
                                onClick={() => {
                                    this.props.history.push('/srcProxy/' + s.id + '/edit');
                                }}
                                className="btn btn-sm btn-outline-dark pull-right ml-1"
                            >
                                <i className="fa fa-edit"></i>
                            </button>
                            <button
                                onClick={this.showDeleteModal.bind(this, s.id)}
                                className="btn btn-sm btn-outline-danger pull-right ml-1"
                            >
                                <i className="fa fa-trash"></i>
                            </button>
                            <FeatureFlag flag="hardware">
                                <button
                                    onClick={() => {this.props.history.push('/srcProxy/' + s.id + '/administration')}}
                                    className="btn btn-sm btn-outline-primary pull-right ml-1"
                                >
                                    <i className='fa fa-cogs'></i>
                                </button>
                            </FeatureFlag>
                            
                            <FeatureFlag flag="hardware">
                                <button
                                    onClick={() => {
                                        this.props.history.push('/srcRoomBinder/' + s.id);
                                    }}
                                    className="btn btn-sm btn-outline-primary pull-right"
                                >
                                    {window.translate('BIND SRC 2 ROOMS')}{' '}
                                </button>
                            </FeatureFlag>
                            {/* <FeatureFlag flag="hardware">
                                <button
                                    onClick={this.showTestHWModal.bind(this, s.SRCProxyUUID)}
                                    className="btn btn-sm btn-outline-primary pull-right mr-1"
                                >
                                    {window.translate('Hardware test ').toUpperCase()}{' '}
                                </button>
                            </FeatureFlag> */}
                           
                            {/* <FeatureFlag flag="hardware">
                                <button
                                    onClick={this.showResetProxyModal.bind(this, s.SRCProxyUUID)}
                                    className="btn btn-sm btn-outline-primary pull-right mr-1"
                                >
                                    {window.translate('Restart device').toUpperCase()}{' '}
                                </button>
                            </FeatureFlag> */}
                        </div>
                    </div>
                </li>
            );
        });

        return (
            <div>
                <PageTitle />
                <button
                    onClick={() => {
                        this.props.history.push('/menu/system');
                    }}
                    className="btn btn-sm btn-secondary"
                >
                    {window.translate('GO BACK')}
                </button>
                <FeatureFlag flag="hardware">
                    <button
                        onClick={() => {
                            this.props.history.push('/SRCBroadcastMulticast');
                        }}
                        className="btn btn-sm btn-info ml-2"
                    >
                        {' '}
                        <i className="fa fa-rss" /> {window.translate('BROADCAST SETTINGS')}
                    </button>
                </FeatureFlag>
                
                <div className="list-group mt-3 mx-3">
                    <small>
                        <div className="px-1 py-2 row shadow-sm bg-white">
                            <div className="col-4">{window.translate('Name')+", "+window.translate('Description')}</div>
                            <div className="col-2">{window.translate('Last seen timestamp')}</div>
                            <div className="col-6"></div>
                        </div>
                    </small>
                </div>

                <ul className="list-group mt-2">{SRCProxies}</ul>
            </div>
        );
    }
}

export default branch(
    {
        srcProxies: ['model', 'SRCProxy'],
    },
    SRCProxyManagement
);