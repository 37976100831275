import rest from '@data/restWrapper';
import {
    InvoiceControlerModel,
    InvoicePreview,
    ReservationModelWithModelsIncluded,
    GroupReservationWithReservations,
    InvoiceModel,
} from '@common/modelDefinition';

import { /*GuestInterface, */RoomCleaningRequest, ReservationTypeBody } from './interfaces';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';

export async function getPendingInvoiceForReservation(
    reservationId: number | null,
    reservationGroupUuid: string | null,
    invoiceType: number
): Promise<InvoiceControlerModel> {
    startGlobalLoading();
    const {
        invoice,
        invoiceItems,
        advanceInvoices,
        proformaInvoices,
        stornoInvoices,
        posTerminalInvoices,
        genuineInvoices,
        checkoutDateMismatchs,
        minibarInvoices,
        availableForAdvancePayment,
    } = await rest('/getPendingInvoiceForReservation', 'POST', {
        reservationId,
        reservationGroupUuid,
        invoiceType,
    });
    stopGlobalLoading();
    const invoiceControlerModel = {
        invoice,
        invoiceItems,
        advanceInvoices,
        proformaInvoices,
        stornoInvoices,
        posTerminalInvoices,
        genuineInvoices,
        checkoutDateMismatchs,
        minibarInvoices,
        availableForAdvancePayment,
    };
    return invoiceControlerModel;
}

export async function getBelongingReservation(
    reservationId: number | null
): Promise<ReservationModelWithModelsIncluded | null> {
    if (reservationId) {
        const reservation: ReservationModelWithModelsIncluded = await rest(`/Reservation/${reservationId}`);
        return reservation;
    } else return null;
}

export async function getBelongingGroupReservation(
    reservationGroupUuid: string | null
): Promise<GroupReservationWithReservations | null> {
    if (reservationGroupUuid) {
        const groupReservation = await rest(`/getGroupReservations/${reservationGroupUuid}`);
        return groupReservation;
    } else return null;
}

export async function setReservationInvoiceProperties(
    invoiceId: number,
    properties: any,
    terminalType?: string
): Promise<InvoiceControlerModel> {
    startGlobalLoading()
    const {
        invoice,
        invoiceItems,
        advanceInvoices,
        proformaInvoices,
        stornoInvoices,
        posTerminalInvoices,
        genuineInvoices,
        checkoutDateMismatchs,
        minibarInvoices,
        availableForAdvancePayment,
    } = await rest('/setReservationInvoiceProperties', 'POST', {
        invoiceId,
        properties: { ...properties },
        terminalType,
    });
    stopGlobalLoading();
    const invoiceControlerModel = {
        invoice,
        invoiceItems,
        advanceInvoices,
        proformaInvoices,
        stornoInvoices,
        posTerminalInvoices,
        genuineInvoices,
        checkoutDateMismatchs,
        minibarInvoices,
        availableForAdvancePayment,
    };
    return invoiceControlerModel;
}

export async function getReservationInvoicePreview(reservationId: number): Promise<InvoicePreview> {
    const reservationInvoicePreview: InvoicePreview = await rest(`/getReservationInvoicePreview`, 'POST', {
        reservationId,
    });
    const { invoice, invoiceItems } = reservationInvoicePreview;
    return { invoice, invoiceItems };
}


export async function receptionSetToggleStates(remoteDeviceId: string, roomCleaningRequest: RoomCleaningRequest) {
    await rest('/src/receptionSetToggleStates/' + remoteDeviceId, 'POST', roomCleaningRequest);
}

export async function createOrJoinNotConnectedConsumptionInvoices(reservationTypeBody: ReservationTypeBody) {
    startGlobalLoading();
    const invoiceData = await rest('/createOrJoinNotConnectedConsumptionInvoices', 'POST', reservationTypeBody);
    stopGlobalLoading();
    return invoiceData;
}


export async function saveGroupRes(groupCover: GroupReservationWithReservations) {
    const reservations= groupCover.Reservations
    return await rest('/transactionalSaveGroupReservations', 'POST', {
        reservations,
        groupCover,
    });
}

export async function removeReservationReferenceFromInvoice(invoice: InvoiceModel) {
    const newInvoice = {...invoice}
    newInvoice.reservationId = null
    newInvoice.reservationGroupUuid = null
    return await rest('/Invoice/'+invoice.id, 'PUT', newInvoice)
}
