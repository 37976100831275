import React from 'react';
import PageTitle from '@components/pageTitle';
import { translate } from '@data/translations';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { setModalContent } from '@components/modal';
import PaymentPurposes from './parts/paymentPurposes';
import moment from 'moment';
import {
    CashRegistarModel,
    CashRegistarPaymentsModel,
    CashRegistarReportModel,
    CashRegPaymentsEnum,
} from '@common/modelDefinition';
import { concludeCashRegistarReport, getActiveCashRegReportWithPayments, CashReportWithPayments } from './parts/api';
import CashRegistarPayment from './parts/paymentsForm';
import { toast } from 'react-toastify';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';
import { pageUp, pageDown } from '@components/pagination';

interface CashRegistarPageProps {
    match: any;
    history: any;
    cashRegistars: CashRegistarModel[];
    companyInfo: any;
}

function CashRegistarPage(props: CashRegistarPageProps) {
    const { companyInfo, history } = props;
    const cashRegistarId = props?.match?.params?.cashRegistarId;

    const [cashRegReport, setCashRegReport] = React.useState({} as CashRegistarReportModel);
    const [cashRegPayments, setCashRegPayments] = React.useState([] as CashRegistarPaymentsModel[]);
    const [reload, setReload] = React.useState(false);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [page, setPage] = React.useState(0);
    const rows = 50;

    React.useEffect(() => {
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [reload]);

    const loadData = async (_page = page) => {
        startGlobalLoading();
        const reports: CashReportWithPayments = await getActiveCashRegReportWithPayments(cashRegistarId, _page, rows);
        setCashRegReport(reports.activeReport);
        setCashRegPayments(reports.payments);
        setPage(_page);
        stopGlobalLoading();
    };

    const cashRegistar =
        cashRegistarId && props.cashRegistars
            ? props.cashRegistars.find((cr: CashRegistarModel) => cr.id === Number(cashRegistarId))
            : null;

    return (
        <div>
            <PageTitle
                title={
                    cashRegistar?.name === 'virman_cashRegistar'
                        ? translate(cashRegistar?.name)
                        : translate('Cash Registar ') + translate(cashRegistar?.name)
                }
            />

            <div className="row mb-2">
                <div className="col-12">
                    <button
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className="btn btn-sm btn-secondary m-1"
                    >
                        {translate('GO BACK')}
                    </button>

                    <button
                        onClick={() => {
                            setModalContent(
                                <CashRegistarPayment
                                    cashRegistarId={Number(cashRegistarId)}
                                    setReload={setReload}
                                    reload={reload}
                                    history={props.history}
                                />,
                                translate('New payment'),
                                false,
                                'modal-lg'
                            );
                        }}
                        className="btn btn-sm btn-info m-1"
                    >
                        {translate('ADD NEW PAYMENT')}
                    </button>
                    <button
                        onClick={() => {
                            setModalContent(<PaymentPurposes />, translate('New payment purpose'), false, 'modal-md');
                        }}
                        className="btn btn-sm btn-warning m-1"
                    >
                        {translate('ADD NEW PAYMENT PURPOSE')}
                    </button>
                </div>
            </div>

            {cashRegReport !== null ? (
                <div className="row bg-white p-3 m-1">
                    {companyInfo ? <CompanyInfo companyInfo={companyInfo} /> : null}
                    {cashRegistar?.cashMax ? (
                        <div className="col-12 border-bottom mt-2">
                            <div className="col-12 mb-2">
                                <small className="textBold">
                                    {translate('Cash registar maximum: ')} {cashRegistar.cashMax}
                                </small>
                            </div>
                        </div>
                    ) : (
                        <div className="col-12 border-bottom mt-2">
                            <div className="col-12 mb-2">
                                <small className="textBold">{translate('Cash registar maximum: ')} </small>
                            </div>
                        </div>
                    )}
                    <CashRegReport report={cashRegReport} />
                    <br />

                    <div className="col-12 mt-3">
                        <h5
                            onClick={() => {
                                setIsExpanded(!isExpanded);
                            }}
                            className="mt-3 pointer text-center uppercase p-2"
                        >
                            <i className={isExpanded ? 'fa fa-compress' : 'fa fa-expand '} />{' '}
                            {<b>{translate('Pay ins and pay outs')}</b>}{' '}
                            <i className={isExpanded ? 'fa fa-compress' : 'fa fa-expand '} />
                        </h5>
                    </div>
                    {isExpanded ? (
                        <CashRegPayments payments={cashRegPayments} loadData={loadData} page={page} history={history} />
                    ) : null}
                </div>
            ) : null}

            {cashRegReport && cashRegReport.toTimestamp === null && cashRegPayments.length > 0 ? (
                <div className="row justify-content-center m-auto">
                    <button
                        onClick={async () => {
                            startGlobalLoading();
                            await concludeCashRegistarReport(new Date(), Number(cashRegistarId));
                            stopGlobalLoading();
                            toast('Report successfully concluded!', { type: toast.TYPE.SUCCESS });
                            setReload(!reload);
                        }}
                        className="btn btn-sm btn-secondary my-5"
                    >
                        {translate('CONCLUDE CASH REGISTAR REPORT')}
                    </button>
                </div>
            ) : null}
        </div>
    );
}

export const CompanyInfo = (props: any) => {
    const { companyInfo } = props;
    return (
        <div className="col-12 border-bottom mt-2 text-left">
            <div className="col-12 uppercase mb-1 textBold">{companyInfo.hotelName}</div>
            <div className="col-12">
                <small className="textBold">
                    {companyInfo.address
                        ? companyInfo.address + ', ' + companyInfo.postOfficeNumber + ' ' + companyInfo.town
                        : ''}
                </small>
            </div>
            <div className="col-12">
                <small>{translate('Id num.')}: </small>
                <small className="textBold">{companyInfo.idNumber}</small>
            </div>
            <div className="col-12 mb-2">
                <small>{translate('Email')}: </small>
                <small className="textBold">{companyInfo.contactEmail}</small>
            </div>
        </div>
    );
};

export const CashRegReport = (props: any) => {
    const { report, print } = props;

    return (
        <div className={' my-5 text-center ' + (print ? 'col-12 ' : 'col-12 col-md-8')}>
            <label>
                <b>
                    {translate('Cash registar report num. ')}
                    {report.reportNumber}
                </b>
            </label>

            <ul className="list-group mx-3">
                <small>
                    <li className={'px-1 py-1 row border-top border-dark border-bottom'}>
                        <div className="col-1 textBold">{translate('From')}</div>
                        <div className="col-2 textBold">{translate('To')}</div>
                        <div className="col-2 textBold">{translate('Prev state')}</div>
                        <div className="col-2 textBold">{translate('Pay IN')}</div>
                        <div className="col-2 textBold">{translate('Pay OUT')}</div>
                        <div className="col-2 textBold">{translate('New state')}</div>
                        <div className="col-1 textBold">{translate('Total')}</div>
                    </li>
                </small>
            </ul>

            <ul className="list-group mx-3">
                <li className={'px-1 py-1 row border-bottom '}>
                    <div className="col-1">
                        {report.fromTimestamp ? moment(report.fromTimestamp).format('YYYY/MM/DD') : ''}
                    </div>
                    <div className="col-2">
                        {report.toTimestamp ? moment(report.toTimestamp).format('YYYY/MM/DD') : ''}
                    </div>
                    <div className="col-2">
                        {report.previousState ? parseFloat(report.previousState).toFixed(2) : ''}
                    </div>
                    <div className="col-2">{report.payIn ? parseFloat(report.payIn).toFixed(2) : ''}</div>
                    <div className="col-2">{report.payOut ? parseFloat(report.payOut).toFixed(2) : ''}</div>
                    <div className="col-2">{report.newState ? parseFloat(report.newState).toFixed(2) : ''}</div>
                    <div className="col-1">{report.total ? parseFloat(report.total).toFixed(2) : ''}</div>
                </li>
            </ul>
        </div>
    );
};

export const CashRegPayments = (props: any) => {
    const { payments, page, loadData, history } = props;

    return (
        <div className="col-12 mt-2 mb-3 text-center">
            <div className="col-12 my-3">
                {history.location.pathname.indexOf('cashRegReportView') !== -1 ? null : (
                    <small>
                        <button
                            onClick={() => pageDown(page, loadData)}
                            className="btn btn-sm btn-outline-primary mr-2"
                        >
                            {translate('prev')}
                        </button>
                        <div className="btn btn-sm ">
                            {translate('page')} {page + 1}
                        </div>
                        <button
                            onClick={() => pageUp(page, loadData, payments)}
                            className="btn btn-sm btn-outline-primary ml-2"
                        >
                            {translate('next')}
                        </button>
                    </small>
                )}
            </div>

            <ul className="list-group mx-3 mt-5">
                <small>
                    <li className={'px-1 py-1 row border-top border-dark border-bottom'}>
                        <div className="col-1 textBold">{translate('ID')}</div>
                        <div className="col-1 textBold">{translate('Num.')}</div>
                        <div className="col-1 textBold">{translate('Type')}</div>
                        <div className="col-2 textBold">{translate('Date')}</div>
                        <div className="col-1 textBold">{translate('Pay IN')}</div>
                        <div className="col-2 textBold">{translate('Pay OUT')}</div>
                        <div className="col-1 textBold">{translate('Currency')}</div>
                        <div className="col-2 textBold">{translate('Partner')}</div>
                        <div className="col-1 textBold">{translate('Purpose')}</div>
                    </li>
                </small>
            </ul>

            <ul className="list-group mx-3">
                {payments.map((p: any, key: number) => {
                    return (
                        <li key={key} className={'px-1 py-1 row border-bottom'}>
                            <div className="col-1">
                                <small>{p.id}</small>
                            </div>
                            <div className="col-1">
                                <small>{p.paymentNumber}</small>
                            </div>
                            <div className="col-1">
                                <small>{translate(CashRegPaymentsEnum[p.purposeType])}</small>
                            </div>
                            <div className="col-2">
                                <small>{moment(p.date).format('YYYY/MM/DD')}</small>
                            </div>
                            <div className="col-1">
                                <small>{p.purposeType === 0 ? p.amount : ''}</small>
                            </div>
                            <div className="col-2">
                                <small>{p.purposeType === 1 ? p.amount : ''}</small>
                            </div>
                            <div className="col-1">
                                <small>{p.currency}</small>
                            </div>
                            <div className="col-2">
                                <small>
                                    {p.firstName ? p.firstName + ' ' + p.lastName : p.companyName ? p.companyName : ''}
                                </small>
                            </div>
                            <div className="col-1">
                                <small>{translate(p.purposeName)}</small>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default branch(
    {
        cashRegistars: ['model', 'CashRegistar'],
        companyInfo: ['companyInfo'],
    },
    CashRegistarPage
);
