import React, { useState, useEffect } from 'react';
import PageTitle from '@components/pageTitle';
import rest from '@data/restWrapper';
import { setModalContent } from '@components/modal';
import Form from './form';
import baobab from '@data/state';
import Delete from '@components/confirmModal';
import { branch } from 'baobab-react/higher-order';
import { toast } from 'react-toastify';

const PosTerminal = (props) => {
    const { posTerminal } = props
    const [loading, setLoading] = useState(true);

    const fetchData = async () => {
        try {
            setLoading(true);
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading]);

    const showFormModal = (posTerminal) => {
        let header = posTerminal && posTerminal.id ? window.translate('Edit') : window.translate('ADD NEW');
        setModalContent(<Form posTerminal={posTerminal} cancelHandler={cancelHandler} />, header, false, 'modal-lg');
    };

    const cancelHandler = (e) => {
        setLoading(false);
        baobab.root.select(['modalData', 'display']).set(false);
        baobab.root.select(['modalData', 'content']).set(null);
    };

    const delPosTerminal = async (posTerminalId) => {
        try {
            await rest('/PosTerminal/' + posTerminalId, 'DELETE');
            toast(window.translate("PosTerminal deleted!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
            setLoading(false);
            const _posTerminal = props.posTerminal.filter((p) => p.id !== posTerminalId);
            baobab.root.select(['model', 'PosTerminal']).set(_posTerminal);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = (posTerminalId) => {
        setModalContent(
            <Delete actionForYes={delPosTerminal.bind(this, posTerminalId)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };
    return (
        <div className="">
            <PageTitle />
            <div className="row ">
                <div className="d-flex col-12 ">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {window.translate('GO BACK')}
                    </button>
                    <button onClick={showFormModal.bind(this, { name: "" })} className="btn btn-sm btn-primary ml-2">
                        {window.translate('ADD NEW')}
                    </button>
                </div>
            </div>

            <ul className="list-group mt-3 mx-3">
                <small>
                    <li className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                        <div className="col-6 col-sm-7  elipsis">{window.translate("Name")}</div>
                        <div className="col-6 col-sm-2 elipsis">{window.translate("Status")}</div>
                        <div className="col-6 col-sm-3"></div>
                    </li>
                </small>
            </ul>

            <ul className="list-group mt-2 mx-3">
                {posTerminal.map((p, key) => {
                    let { fiscalInfo } = p;
                    if (fiscalInfo && typeof fiscalInfo === 'string') {
                        fiscalInfo = JSON.parse(fiscalInfo);
                    }
                    const { srcProxyName, SRCProxyUUID } = fiscalInfo; // BiH

                    return (
                        <li key={key} className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                            <div className="col-6 col-sm-7 elipsis">{p.name}</div>
                            <div className="col-6 col-sm-2 elipsis">
                                {p.isEnabled ? window.translate('ENABLED Terminal!') : window.translate('DISABLED Terminal...')}
                            </div>
                            <div className="col-6 col-sm-3">
                                <div className="pt-2 pr-2">
                                    <button
                                        onClick={showFormModal.bind(this, p)}
                                        className="btn btn-sm btn-outline-dark pull-right ml-1 mb-1"
                                    >
                                        <i className="fa fa-edit"></i>
                                    </button>
                                    <button
                                        onClick={() => {
                                            showDeleteModal(p.id);
                                        }}
                                        className="btn btn-sm btn-outline-danger pull-right mb-1"
                                    >
                                        <i className="fa fa-trash"></i>
                                    </button>
                                    {p.terminalType === 1 && (
                                        <div
                                            onClick={() => props.history.push('/posTerminal/' + p.id)}
                                            className="btn btn-sm btn-info pull-right mr-1 mb-1 pointer"
                                        >
                                            {window.translate('OPEN TERMINAL')}
                                        </div>
                                    )}
                                    {srcProxyName && SRCProxyUUID ? (
                                        <button
                                            onClick={() => {
                                                props.history.push('/fiscalTools/' + srcProxyName);
                                            }}
                                            className="btn btn-sm btn-primary pull-right mr-1 mb-1"
                                        >
                                            {window.translate('Fiscal tools')}
                                        </button>
                                    ) : null}
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default branch(
    {
        posTerminal: ['model', 'PosTerminal'],
    },
    PosTerminal
);
