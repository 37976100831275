import React from 'react';
import translate from '@data/translations';
import CompanyForm from '../company/form';
import CompanySearchForm from '../company/searchForm';
import DayPickerInput from 'react-day-picker/DayPickerInput';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
import { CompanyModel } from '@common/modelDefinition';
import countryCodes from '../../data/countryCodes.json';
import citizenshipCodes from '../../data/citizenshipCode.json';
import Select from 'react-select';
import { WorldLanguages } from '@data/worldLanguages';

const FormInputs = (props: any) => {
    const {
        customer,
        firstNameRef,
        lastNameRef,
        handleInputChange,
        handleLanguageChange,
        company,
        setCustomer,
        setCompany,
        emailRef,
        checkValidationInputs,
    } = props;

    React.useEffect(()=>{
        checkValidationInputs(customer)
        // eslint-disable-next-line
    },[])

    const deleteCompanyReference = () => {
        const _customer = { ...customer };
        _customer.companyId = null;
        setCustomer({ ..._customer });
        setCompany(null);
    };

    const genders = [];
    genders.push({ key: 'M', label: translate('Male') });
    genders.push({ key: 'F', label: translate('Female') });
    genders.push({ key: 'Other', label: translate('Undefined') });

    let countryOptions = countryCodes.map((c: any) => {
        return (
            <option value={c['country-code']} key={c['country-code']}>
                {c.name}
            </option>
        );
    });
    countryOptions.unshift(
        <option key="none" value="">
            {' '}
            -{' '}
        </option>
    );
    let nationalityOptions = citizenshipCodes.map((n: any) => {
        return (
            <option value={n['num_code']} key={n['num_code']}>
                {n.nationality}
            </option>
        );
    });
    nationalityOptions.unshift(
        <option key="none" value="">
            {' '}
            -{' '}
        </option>
    );

    const languageList = Object.keys(WorldLanguages);


    const optionList = languageList.map(itm => {
        return { label: WorldLanguages[itm].name, value: itm };
    });

    const getLanguageValue = (languageShort:any) => {
        let langValue:any = { label: "", value: "" }
        optionList.forEach(itm => {
            if(itm.value === languageShort){
                langValue = itm
            }
        })
        return langValue
    };

    let selectedCompanyName = company ? company.name : null;

    return (
        <div>
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label " htmlFor="">
                    {translate('First name')}:
                </label>
                <div className="col-md-8">
                    <input
                        ref={firstNameRef}
                        autoComplete="off"
                        placeholder={translate('First name')}
                        name="firstName"
                        value={customer.firstName ? customer.firstName : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Last name')}:
                </label>
                <div className="col-md-8">
                    <input
                        ref={lastNameRef}
                        autoComplete="off"
                        name="lastName"
                        placeholder={translate('Last name')}
                        value={customer.lastName ? customer.lastName : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Company')}:
                </label>
                <div className="col-md-8">
                    {selectedCompanyName === null ? (
                        <CompanySearchForm
                            createButtonText={translate('New Company')}
                            createForm={CompanyForm}
                            showCreateButton={true}
                            callback={(selectedCompany: CompanyModel) => {
                                setCompany(selectedCompany);
                            }}
                        />
                    ) : (
                        <div className="input-group">
                            <input
                                autoComplete="off"
                                disabled
                                name="companyId"
                                value={selectedCompanyName}
                                type="text"
                                className="form-control"
                            />
                            <div className="input-group-append">
                                <button
                                    onClick={deleteCompanyReference}
                                    className="btn btn-outline-danger btn-sm"
                                    type="button"
                                >
                                    <i className="fa fa-trash" aria-hidden="true"></i>
                                </button>
                            </div>
                        </div>
                    )}
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Birth Date')}
                </label>
                <div className="col-md-8 text-left ">
                    <DayPickerInput
                        // name="dateOfBirth"
                        value={customer.dateOfBirth ? formatDate(new Date(customer.dateOfBirth)) : ''}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        placeholder={'MM/DD/YYYY'}
                        format={'MM/DD/YYYY'}
                        onDayChange={(d) => {
                            customer['dateOfBirth'] = new Date(d);
                            setCustomer({ ...customer });
                        }}
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Birth Place')}:
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        name="birthTown"
                        placeholder={translate('Birth Place')}
                        value={customer.birthTown ? customer.birthTown : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Gender')}
                </label>
                <div className="col-md-8">
                    {genders.map((g) => {
                        return (
                            <div className="form-check text-left" key={g.key}>
                                <input
                                    className="form-check-input"
                                    type="radio"
                                    name={g.key}
                                    id={g.key}
                                    value={g.key}
                                    checked={customer.gender && customer.gender === g.key ? true : false}
                                    onChange={(e) => {
                                        customer['gender'] = e.target.value;
                                        setCustomer({ ...customer });
                                    }}
                                />
                                <label className="form-check-label" htmlFor={g.key}>
                                    {g.label}
                                </label>
                            </div>
                        );
                    })}
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Email')}:
                </label>
                <div className="col-md-8">
                    <input
                        ref={emailRef}
                        autoComplete="off"
                        name="email"
                        placeholder={translate('Email')}
                        value={customer.email ? customer.email : ''}
                        onChange={handleInputChange}
                        type="email"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Mobile number')}:
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        name="phoneOrMobile"
                        placeholder={translate('Mobile number')}
                        value={customer.phoneOrMobile ? customer.phoneOrMobile : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Address')}:
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        name="address"
                        placeholder={translate('Address')}
                        value={customer.address ? customer.address : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Post office number')}:
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        name="poNumber"
                        placeholder={translate('Post office number')}
                        value={customer.poNumber ? customer.poNumber : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Town')}:
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        name="town"
                        placeholder={translate('Town')}
                        value={customer.town ? customer.town : ''}
                        onChange={handleInputChange}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Country/State')}:
                </label>
                <div className="col-md-8">
                    <select
                        name="country"
                        className="form-control"
                        value={customer.country ? customer.country : ''}
                        onChange={handleInputChange}
                    >
                        {countryOptions}
                    </select>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Citizenship')}:
                </label>
                <div className="col-md-8">
                    <select
                        name="nationality"
                        className="form-control"
                        value={customer.nationality ? customer.nationality : ''}
                        onChange={handleInputChange}
                    >
                        {nationalityOptions}
                    </select>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Preferred language')}:
                </label>
                <div className="col-md-8">
                <Select
                        // isMulti
                        className="mt-2"
                        placeholder={translate('Select options...')}
                        options={optionList}
                        name="preferredLanguageSelect"
                        onChange={handleLanguageChange}
                        value={customer.preferredLanguage ? getLanguageValue(customer.preferredLanguage) : ''}
                    ></Select>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Other language')}:
                </label>
                <div className="col-md-8">
                <Select
                        // isMulti
                        className="mt-2"
                        placeholder={translate('Select options...')}
                        options={optionList}
                        name="otherLanguageSelect"
                        onChange={handleLanguageChange}
                        value={customer.otherLanguage ? getLanguageValue(customer.otherLanguage) : ''}
                    ></Select>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Notes')}:
                </label>
                <div className="col-md-8">
                    <textarea
                        name="notes"
                        placeholder={translate('Notes')}
                        onChange={(e) => {
                            customer.params[e.target.name] = e.target.value;
                            setCustomer({ ...customer }); //spread to force refresh
                        }}
                        value={customer?.params?.notes ? customer.params.notes : ''}
                        className="form-control"
                    ></textarea>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label form-check-label" htmlFor="newsletter_id">
                    {translate('Newsletter subscriptions')}
                </label>
                <div className="col-md-8 text-left">
                    <i
                        onClick={() => {
                            customer.params['newsletter'] = !customer.params['newsletter'];
                            setCustomer({ ...customer }); //spread to force refresh
                        }}
                        // name="newsletter"
                        // value={customer.params.newsletter}
                        className={
                            customer?.params?.newsletter ? 'fa fa-check-square fa-lg ml-2' : 'fa fa-square fa-lg ml-2'
                        }
                    ></i>
                </div>
            </div>
        </div>
    );
};

export default FormInputs;
