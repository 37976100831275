import React, { useEffect, useState, useRef } from 'react';
import _ from 'lodash';
import { getSpecificModelRow } from '../../../data/actions/modelActions';
import rest from '../../../data/restWrapper';
import { closeModal } from '@components/modal';
import { toast } from 'react-toastify';

let paramsModel = [{}];
const emptyModel = {
    id: null,
    shortName: null,
    name: null,
    params: JSON.stringify(_.clone(paramsModel)),
    isEnabled: true,
};

const CurrencyForm = (props) => {
    const [data, setData] = useState(_.clone(emptyModel));
    const name = useRef();
    const shortName = useRef();

    useEffect(() => {
        const loadData = async () => {
            const { currencyId } = props;
            clearValidationRefs();
            if (currencyId) {
                //editing
                redrawComponent(currencyId);
            } else {
                setData(emptyModel);
                checkValidationInputs(emptyModel);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props?.currencyId]);

    const redrawComponent = async (currencyId) => {
        if (!_.isUndefined(currencyId) && currencyId !== null && _.isNaN(currencyId) === false) {
            const currentCurrency = await getSpecificModelRow('Currency', currencyId);
            let _data = { ...data };
            _data.id = currentCurrency.id;
            _data.shortName = currentCurrency.shortName;
            _data.name = currentCurrency.name;
            _data.params = JSON.parse(currentCurrency.params);
            _data.isEnabled = currentCurrency.isEnabled;
            setData(_data);
            checkValidationInputs(_data);
        }
    };

    const inputHandler = (e) => {
        let _data = { ...data };
        switch (e.target.type) {
            default:
            case 'text':
                _data[e.target.name] = e.target.value;
                break;
            case 'number':
                if (e.target.value !== '') {
                    _data[e.target.name] = Number(e.target.value);
                } else {
                    _data[e.target.name] = '';
                }
                break;
            case 'checkbox':
                _data[e.target.name] = !e.target.value;
                break;
        }
        setData(_data);
        if (e.target.name === 'name' || e.target.name === 'shortName') {
            checkValidationInputs(_data);
        }
    };

    const checkValidationInputs = (objToValidate) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);
    };

    const clearValidationRefs = () => {
        if (name && name.current) {
            name.current.className = name.current.className.replace('is-invalid', '');
        }
        if (shortName && shortName.current) {
            shortName.current.className = shortName.current.className.replace('is-invalid', '');
        }
    };

    const markInvalidInputRefs = (validationResults) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!classes.includes('is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (classes.includes('is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    };

    const getValidationInputs = (data) => {
        const invalidInputs = [],
            validInputs = [];
        // clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(name);
        } else {
            validInputs.push(name);
        }
        if (data.shortName === null || data.shortName.trim() === '') {
            invalidInputs.push(shortName);
        } else {
            validInputs.push(shortName);
        }

        return { invalidInputs, validInputs };
    };

    const onSave = async () => {
        try {
            let _data = { ...data };
            _data['params'] = JSON.stringify(_data['params']);
            const validationResults = getValidationInputs(_data);
            markInvalidInputRefs(validationResults);
            if (validationResults.invalidInputs.length === 0) {
                if (_data.id !== null) {
                    let id = _data.id;
                    delete _data.id;
                    await rest('/Currency/' + id, 'PUT', _data);
                } else {
                    const _savedCurrency = await rest('/Currency', 'POST', _data);
                    const exchangeRate = {
                        validFromTimestamp: new Date().getTime(),
                        exchangeRate: 1,
                        confirmed: 1,
                        currencyId: _savedCurrency.id,
                    };
                    await rest('/ExchangeRate', 'POST', exchangeRate);
                    toast(window.translate('Currency added! Please set exchange rate for created currency!'), {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        type: toast.TYPE.INFO,
                    });
                }
                setData(_.clone(emptyModel));
                closeModal();
            }
        } catch (err) {
            console.warn(err);
        }
    };

    const cancelHandler = () => {
        setData(_.clone(emptyModel));
        closeModal();
    };

    const renderInputField = (_name, label, type = 'text', value, ref) => {
        return (
            <div className="form-group row hidden-lg hidden-md visible-xs visible-xs justify-content-center align-items-center">
                <label className="col-4  col-form-label" htmlFor={'pl-' + _name}>
                    {label}:
                </label>
                <div className="col-8 ">
                    <input
                        ref={ref}
                        id={'pl-' + _name}
                        name={_name}
                        placeholder={label}
                        autoComplete="off"
                        value={value ? value : ''}
                        onChange={inputHandler.bind(this)}
                        type={type}
                        className="form-control"
                    />
                </div>
            </div>
        );
    };

    const checkboxHandler = (name, e) => {
        let obj = { ...data };
        obj[name] = !obj[name];
        setData(obj);
    };

    const getCheckboxInput = (_name, label, value) => {
        return (
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-4 col-form-label" htmlFor="PL-isActive-input">
                    {label}
                </label>
                <div className="col-8 text-left">
                    <i
                        onClick={checkboxHandler.bind(this, _name)}
                        name={_name}
                        value={value}
                        className={value ? 'fa fa-check-square fa-lg ml-2' : 'fa fa-square fa-lg ml-2'}
                    ></i>
                </div>
            </div>
        );
    };

    return (
        <div className="container-fluid">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-md-10 pl-0 pr-0  ">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            className="pt-4 pb-4 text-md-right bg-white "
                        >
                            {renderInputField('name', window.translate('Name'), 'text', data.name, name)}
                            {renderInputField(
                                'shortName',
                                window.translate('Acronym'),
                                'text',
                                data.shortName,
                                shortName
                            )}
                            {getCheckboxInput('isEnabled', window.translate('Is enabled'), data.isEnabled)}
                            <div className="text-center mt-5">
                                <button onClick={onSave.bind(this)} className="btn btn-outline-primary">
                                    {window.translate('Save')}
                                </button>
                                <div
                                    onClick={cancelHandler.bind(this)}
                                    className="btn btn-outline-secondary ml-2 pointer"
                                >
                                    {window.translate('Cancel')}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default CurrencyForm;
