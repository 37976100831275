import React from 'react';
import { useBranch } from 'baobab-react/hooks';
import { companyInfoKeyToName } from './enums';
import _ from 'lodash';
import { handleChange, saveFormData } from '../settingsActions';

const CompanyInfo = (settings) => {
    const { companyInfo } = settings;
    const { licence } = useBranch({
        licence: ['licence'],
    });

    let licencedCompanyName = null;
    let isLicenceValid = licence && licence.licence && licence.licence.isValid;
    if (isLicenceValid) {
        licencedCompanyName = licence.licence.decodedLicence.companyName;
    }

    let companyOptions =
        companyInfo &&
        _.map(companyInfo, (val, key) => {
            if (isLicenceValid && key === 'name') {
                return null;
            }
            return (
                <div key={key} className="row mt-2">
                    <div className="col-md-4">
                        <label htmlFor="">{companyInfoKeyToName[key]}:</label>
                    </div>
                    <div className="col-md-6">
                        <input
                            autoComplete="off"
                            name={key}
                            value={val}
                            onChange={handleChange.bind(this, 'companyInfo', 'object')}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            );
        });

    return (
        <div className=" bg-white p-3">
            <b>
                {window.translate('LICENCE HOLDER')} {licencedCompanyName}
            </b>
            <hr />
            <small>
                {window.translate(
                    'WARNING: Information from this form will be used to generate licence. After licence is generated, system wide company name could not be changed until new licence is reissued.'
                )}
            </small>
            <hr />
            {companyOptions}
            <div className="text-center p-5">
                <div
                    onClick={() => saveFormData('companyInfo', companyInfo)}
                    className="btn btn-outline-primary pointer"
                >
                    {window.translate('SAVE')}
                </div>
            </div>
        </div>
    );
};

export default CompanyInfo;
