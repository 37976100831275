import React, { Component } from 'react';
import _ from 'lodash';
import rest from '../../data/restWrapper';
import InputRange from 'react-input-range';
import 'react-input-range/lib/css/index.css';

class ThermostatSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            data: {
                thermostatMode_t1: props.roomSettings.generalSettings.thermostatMode_t1,
                thermostatModbusAddress_t1: props.roomSettings.generalSettings.thermostatModbusAddress_t1,
                irModbusAddress_t1: props.roomSettings.generalSettings.irModbusAddress_t1,
                defaultThermostatTemperature_t1: props.roomSettings.generalSettings.defaultThermostatTemperature_t1,
                minECO_t1: props.roomSettings.generalSettings.minECO_t1,
                maxECO_t1: props.roomSettings.generalSettings.maxECO_t1,
                minPRECOMFORT_t1: props.roomSettings.generalSettings.minPRECOMFORT_t1,
                maxPRECOMFORT_t1: props.roomSettings.generalSettings.maxPRECOMFORT_t1,
                minCOMFORT_t1: props.roomSettings.generalSettings.minCOMFORT_t1,
                maxCOMFORT_t1: props.roomSettings.generalSettings.maxCOMFORT_t1,

                thermostatMode_t2: props.roomSettings.generalSettings.thermostatMode_t2,
                thermostatModbusAddress_t2: props.roomSettings.generalSettings.thermostatModbusAddress_t2,
                irModbusAddress_t2: props.roomSettings.generalSettings.irModbusAddress_t2,
                defaultThermostatTemperature_t2: props.roomSettings.generalSettings.defaultThermostatTemperature_t2,
                minECO_t2: props.roomSettings.generalSettings.minECO_t2,
                maxECO_t2: props.roomSettings.generalSettings.maxECO_t2,
                minPRECOMFORT_t2: props.roomSettings.generalSettings.minPRECOMFORT_t2,
                maxPRECOMFORT_t2: props.roomSettings.generalSettings.maxPRECOMFORT_t2,
                minCOMFORT_t2: props.roomSettings.generalSettings.minCOMFORT_t2,
                maxCOMFORT_t2: props.roomSettings.generalSettings.maxCOMFORT_t2,

                thermostatMode_t3: props.roomSettings.generalSettings.thermostatMode_t3,
                thermostatModbusAddress_t3: props.roomSettings.generalSettings.thermostatModbusAddress_t3,
                irModbusAddress_t3: props.roomSettings.generalSettings.irModbusAddress_t3,
                defaultThermostatTemperature_t3: props.roomSettings.generalSettings.defaultThermostatTemperature_t3,
                minECO_t3: props.roomSettings.generalSettings.minECO_t3,
                maxECO_t3: props.roomSettings.generalSettings.maxECO_t3,
                minPRECOMFORT_t3: props.roomSettings.generalSettings.minPRECOMFORT_t3,
                maxPRECOMFORT_t3: props.roomSettings.generalSettings.maxPRECOMFORT_t3,
                minCOMFORT_t3: props.roomSettings.generalSettings.minCOMFORT_t3,
                maxCOMFORT_t3: props.roomSettings.generalSettings.maxCOMFORT_t3,

                thermostatFunction_t1: props.roomSettings.generalSettings.thermostatFunction_t1, ////referencirati na siemensThermostatEmuFunctionModes
                thermostatFunction_t2: props.roomSettings.generalSettings.thermostatFunction_t2, ////referencirati na siemensThermostatEmuFunctionModes
                thermostatFunction_t3: props.roomSettings.generalSettings.thermostatFunction_t3, ////referencirati na siemensThermostatEmuFunctionModes

                //SDH - heating
                SDH_switchingDifferencialHeating_t1:
                    props.roomSettings.generalSettings.SDH_switchingDifferencialHeating_t1, //(range 5 - 60 - means 0.5K to 6K)
                SDH_switchingDifferencialHeating_t2:
                    props.roomSettings.generalSettings.SDH_switchingDifferencialHeating_t2, //(range 5 - 60 - means 0.5K to 6K)
                SDH_switchingDifferencialHeating_t3:
                    props.roomSettings.generalSettings.SDH_switchingDifferencialHeating_t3, //(range 5 - 60 - means 0.5K to 6K)

                //SDC - cooling
                SDC_switchingDifferencialCooling_t1:
                    props.roomSettings.generalSettings.SDC_switchingDifferencialCooling_t1, //(range 5 - 60 - means 0.5K to 6K)
                SDC_switchingDifferencialCooling_t2:
                    props.roomSettings.generalSettings.SDC_switchingDifferencialCooling_t2, //(range 5 - 60 - means 0.5K to 6K)
                SDC_switchingDifferencialCooling_t3:
                    props.roomSettings.generalSettings.SDC_switchingDifferencialCooling_t3, //(range 5 - 60 - means 0.5K to 6K)

                floorTemperatureLimit_t1: props.roomSettings.generalSettings.floorTemperatureLimit_t1, //(26 for living rooms and 28 for bathrooms)
                floorTemperatureLimit_t2: props.roomSettings.generalSettings.floorTemperatureLimit_t2, //(26 for living rooms and 28 for bathrooms)
                floorTemperatureLimit_t3: props.roomSettings.generalSettings.floorTemperatureLimit_t3, //(26 for living rooms and 28 for bathrooms)
            },
            modified: {},
            expanded: _.isUndefined(props.expanded) ? false : props.expanded,
        };
    }

    inputHandler(e) {
        let obj = this.state;
        obj.data[e.target.name] = parseInt(e.target.value, 10); //for now, they are all numbers
        obj.modified[e.target.name] = true;
        this.setState(obj);
    }

    async saveChanges() {
        try {
            let remoteDeviceId = this.props.match.params.remoteDeviceId;
            let keys = Object.keys(this.state.modified);
            for (let i = 0; i < keys.length; i++) {
                let payload = { generalSettingsParamEnum: keys[i], value: this.state.data[keys[i]] };

                // console.log(payload);

                if (this.props.onSaveHandler) {
                    this.props.onSaveHandler('generalSettings', this.state.data);
                } else {
                    await rest('/src/setGeneralSettingsByte/' + remoteDeviceId, 'POST', payload);
                }
            }
            this.setState({ modified: {} });
        } catch (err) {
            console.warn(err); //TODO:
        }
    }

    handleRangeChange(type, value) {
        let obj = _.clone(this.state);
        switch (type) {
            case 'ECO':
                obj.data['minECO' + this.props.sufix] = value.min;
                obj.data['maxECO' + this.props.sufix] = value.max;
                obj.modified['minECO' + this.props.sufix] = true;
                obj.modified['maxECO' + this.props.sufix] = true;
                break;
            case 'COMFORT':
                obj.data['minCOMFORT' + this.props.sufix] = value.min;
                obj.data['maxCOMFORT' + this.props.sufix] = value.max;
                obj.modified['minCOMFORT' + this.props.sufix] = true;
                obj.modified['maxCOMFORT' + this.props.sufix] = true;
                break;
            case 'PRECOMFORT':
                obj.data['minPRECOMFORT' + this.props.sufix] = value.min;
                obj.data['maxPRECOMFORT' + this.props.sufix] = value.max;
                obj.modified['minPRECOMFORT' + this.props.sufix] = true;
                obj.modified['maxPRECOMFORT' + this.props.sufix] = true;
                break;
            default:
            case 'DEFAULT':
                obj.data['defaultThermostatTemperature' + this.props.sufix] = value;
                obj.modified['defaultThermostatTemperature' + this.props.sufix] = true;
                break;
        }
        this.setState(obj);
    }

    fixCorruptedSetting() {
        let obj = _.clone(this.state);
        _.forEach(this.props.invalidSettings, (s) => {
            if (s.name.lastIndexOf(this.props.sufix) !== -1) {
                if (s.currVal !== s.expectedVal) {
                    obj.data[s.name] = s.expectedVal;
                    obj.modified[s.name] = true;
                    console.log('INVALID SETTING', s);
                }
            }
        });
        this.setState(obj);
        //  console.log(this.props.invalidSettings);
    }

    render() {
        let corruptedSettings =
            this.props.invalidSettings.length > 0 ? <span>{window.translate("CORRUPTED SETTINGS - PLEASE FIX")}</span> : null;

        if (!_.includes(this.props.roomSettings.availableDevices, 'i2cModbusExtension')) {
            if (this.props.sufix !== '_t1') {
                return null;
            }
        }

        /*
enum siemensThermostatEmuFunctionModes {
  twoPipeFanCoil,
  twoPipeWithElectricalHeater,
  twoPipeWithRadiator,
  fourPipeFanCoil,
  fourPipeWithElectricalHeater,
  twoStageHeatingOrCooling,
  compressorsInDXTypeEq
};

*/
        let sufix = this.props.sufix;
        let headerClassName = this.state.data['thermostatModbusAddress' + sufix] === 0 ? 'bg-dark' : 'bg-dark';
        if (sufix === '_t1') {
            headerClassName = 'bg-dark'; //because, internal is always enabled by default
        }
        //heating, cooling, dualHeating, dualCooling, dualAuto, disabled
        let expandedView = (
            <div className="p-2">
                <div className="row">
                    <div className="col-md-6">
                        {window.translate("FUNCTION")}:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name={'thermostatFunction' + sufix}
                            value={this.state.data['thermostatFunction' + sufix]}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>2 PIPE FAN COIL</option>
                            <option disabled={true} value={1}>
                                2 PIPE FAN COIL WITH AUX. HEATER
                            </option>
                            <option disabled={true} value={2}>
                                2 PIPE FAN COIL AND RADIATOR OR FLOOR HEATING
                            </option>
                            <option value={3}>4 PIPE FAN COIL</option>
                            <option disabled={true} value={4}>
                                4 PIPE FAN COIL WITH AUX. HEATER
                            </option>
                            <option disabled={true} value={5}>
                                TWO STAGE HEATING OR COOLING
                            </option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        {window.translate("MODE:")}
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name={'thermostatMode' + sufix}
                            value={this.state.data['thermostatMode' + sufix]}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>{window.translate("HEATING")}</option>
                            <option value={1}>{window.translate("COOLING")}</option>
                            <option value={2}>{window.translate("Automatic changeover")}</option>
                            <option value={3}>{window.translate("DISABLED")}</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {window.translate("SWITCHING DIFFERENTIAL - HEATING")}:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name={'SDH_switchingDifferencialHeating' + sufix}
                            value={this.state.data['SDH_switchingDifferencialHeating' + sufix]}
                            className="form-control form-control-sm"
                        >
                            <option value={5}>0.5 C</option>
                            <option value={10}>1 Celsius</option>
                            <option value={15}>1.5 Celsius</option>
                            <option value={20}>2 Celsius</option>
                            <option value={25}>2.5 Celsius</option>
                            <option value={30}>3 Celsius</option>
                            <option value={35}>3.5 Celsius</option>
                            <option value={40}>4 Celsius</option>
                            <option value={45}>4.5 Celsius</option>
                        </select>
                    </div>
                    <div className="col-md-6">
                        {window.translate("SWITCHING DIFFERENTIAL - COOLING")}:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name={'SDC_switchingDifferencialCooling' + sufix}
                            value={this.state.data['SDC_switchingDifferencialCooling' + sufix]}
                            className="form-control form-control-sm"
                        >
                            <option value={5}>0.5 C</option>
                            <option value={10}>1 Celsius</option>
                            <option value={15}>1.5 Celsius</option>
                            <option value={20}>2 Celsius</option>
                            <option value={25}>2.5 Celsius</option>
                            <option value={30}>3 Celsius</option>
                            <option value={35}>3.5 Celsius</option>
                            <option value={40}>4 Celsius</option>
                            <option value={45}>4.5 Celsius</option>
                        </select>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-6"></div>
                    <div className="col-md-6"></div>
                </div>
                <div className="row">
                    <div className="col-md-6">
                        {window.translate("MODBUS ADDRESS")}
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name={'thermostatModbusAddress' + sufix}
                            value={this.state.data['thermostatModbusAddress' + sufix]}
                            className="form-control form-control-sm"
                            type="number"
                            min={0}
                            max={255}
                        />
                    </div>
                    <div className="col-md-6">
                        {window.translate("IR MODBUS ADDRESS")}
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name={'irModbusAddress' + sufix}
                            value={this.state.data['irModbusAddress' + sufix]}
                            className="form-control form-control-sm"
                            type="number"
                            min={0}
                            max={255}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 p-2 m-2">
                        {window.translate("ECO MODE")}
                        <InputRange
                            maxValue={35}
                            minValue={5}
                            value={{ min: this.state.data['minECO' + sufix], max: this.state.data['maxECO' + sufix] }}
                            onChange={this.handleRangeChange.bind(this, 'ECO')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 p-2 m-2">
                        {window.translate("PRECOMFORT MODE")}
                        <InputRange
                            maxValue={35}
                            minValue={5}
                            value={{
                                min: this.state.data['minPRECOMFORT' + sufix],
                                max: this.state.data['maxPRECOMFORT' + sufix],
                            }}
                            onChange={this.handleRangeChange.bind(this, 'PRECOMFORT')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 p-2 m-2">
                        {window.translate("COMFORT MODE")}
                        <InputRange
                            maxValue={35}
                            minValue={5}
                            value={{
                                min: this.state.data['minCOMFORT' + sufix],
                                max: this.state.data['maxCOMFORT' + sufix],
                            }}
                            onChange={this.handleRangeChange.bind(this, 'COMFORT')}
                        />
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12 p-2 m-2">
                        {window.translate("DEFAULT TEMP.")}
                        <InputRange
                            maxValue={this.state.data['maxCOMFORT' + sufix]}
                            minValue={this.state.data['minCOMFORT' + sufix]}
                            value={this.state.data['defaultThermostatTemperature' + sufix]}
                            onChange={this.handleRangeChange.bind(this, 'DEFAULT')}
                        />
                    </div>
                </div>
                {window.translate("FLOOR TEMPERATURE LIMIT (20-30 Celsius)")}:
                <input
                    onChange={this.inputHandler.bind(this)}
                    name={'floorTemperatureLimit' + sufix}
                    value={this.state.data['floorTemperatureLimit' + sufix]}
                    className="form-control form-control-sm"
                    type="number"
                    min={20}
                    max={30}
                />
                <div className="text-center mt-2">
                    <button
                        onClick={this.saveChanges.bind(this)}
                        className={
                            Object.keys(this.state.modified).length > 0 ? 'btn btn-info' : 'btn btn-info disabled'
                        }
                    >
                        {window.translate("Update")}
                    </button>
                    {corruptedSettings ? (
                        <button onClick={this.fixCorruptedSetting.bind(this)} className="ml-2 btn btn-warning">
                            {window.translate("FIX TO DEFAULT")}
                        </button>
                    ) : null}
                </div>
            </div>
        );

        //console.log(this.props.roomSettings.externalThermostatConnection);
        return (
            <div className="card mt-2">
                <div
                    onClick={() => {
                        this.setState({ expanded: !this.state.expanded });
                    }}
                    className={'card-header unselectable text-light text-center pointer ' + headerClassName}
                >
                    {this.props.roomSettings.externalThermostatConnection['conn' + sufix] ? (
                        <i className="fa fa-wifi pull-left text-success" aria-hidden="true"></i>
                    ) : (
                        <i className="fa fa-chain-broken  pull-left" aria-hidden="true"></i>
                    )}
                    {corruptedSettings ? corruptedSettings : this.props.name} &ensp; (
                    {this.props.roomSettings.thermostatSensor['sensor' + sufix].connected
                        ? this.props.roomSettings.thermostatSensor['sensor' + sufix].temperature
                        : '-'}
                    ) °C
                    {this.state.expanded ? (
                        <i className="fa fa-compress ml-2 mr-2 pull-right" aria-hidden="true"></i>
                    ) : (
                        <i className="fa fa-expand ml-2 mr-2 pull-right" aria-hidden="true"></i>
                    )}
                </div>
                <div className="card-body">
                    {this.state.expanded ? (
                        expandedView
                    ) : (
                        <div className="text-center">{window.translate("(click on header to expand)")}</div>
                    )}
                </div>
            </div>
        );
    }
}
export default ThermostatSettings;
