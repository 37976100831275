import React, { Component } from 'react';
import Navbar from '../components/header/navbar';
import Foother from '../components/footer/footer';
import Toaster from '../components/toaster';
import ModalController from '../components/modal';
import BackendDrivenToaster from './backendDrivenToaster';
import { GlobalLoading } from '../components/loaders/globalLoading';

class MasterLayout extends Component {
    constructor() {
        super();
        this.myRef = React.createRef();
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.location.pathname !== this.props.location.pathname && this.myRef.current) {
            this.myRef.current.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
            });
        }
    }

    render() {
        if (
            this.props.location.pathname.lastIndexOf('/invoiceView') !== -1 ||
            this.props.location.pathname.lastIndexOf('/proformaView') !== -1 ||
            this.props.location.pathname.lastIndexOf('/printPreview') !== -1 ||
            this.props.location.pathname.lastIndexOf('/cashRegReportView') !== -1 ||
            this.props.location.pathname.startsWith('/posTerminal/')
        ) {
            return (
                <div>
                    {' '}
                    <ModalController />
                    <GlobalLoading />
                    {this.props.children} <Toaster />
                </div>
            );
        } else if (this.props.location.pathname.startsWith('/documentScanner')) {
            const bgCl =
                this.props.location.search === ''
                    ? 'docScann-bg-step1'
                    : this.props.location.search === '?step2'
                    ? 'docScann-bg-step2'
                    : 'docScann-bg-step3';
            return (
                <div>
                    <div className={bgCl + ' docScanner-background  mb-2 bg-light'}>
                        <Navbar {...this.props} />
                        {this.props.children}
                    </div>
                    <Toaster />
                    <BackendDrivenToaster />
                </div>
            );
        } else {
            return (
                <div ref={this.myRef} style={{ userSelect: 'none' }}>
                    <GlobalLoading />
                    <Navbar {...this.props} />
                    <ModalController />
                    <div className="container-fluid reception-background mb-2 bg-light">
                        {this.props.children}
                        <div className="clear pt-2">&ensp;</div>
                    </div>

                    <Toaster />
                    <Foother {...this.props} />
                    <BackendDrivenToaster />
                </div>
            );
        }
    }
}

export default MasterLayout;
