import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import PageLoader from '../../components/loaders/loader1';
import rest from '../../data/restWrapper';

class PushTemplate extends Component {
    constructor() {
        super();
        this.repeatCount = 0;
        this.state = {
            pushPending: false,
            tagFilter: null,
            selectedIds: [],
            srcEnums: null,
        };
    }
    async componentDidMount() {
        let srcEnums = await rest('/SRCEnums');
        this.setState({ srcEnums });
        //console.log(srcEnums);
    }
    handleSelection(id, e) {
        let selectedIds = _.clone(this.state.selectedIds);
        if (_.includes(this.state.selectedIds, id)) {
            selectedIds = _.filter(selectedIds, id);
        } else {
            selectedIds.push(id);
        }
        this.setState({ selectedIds });
    }

    //accessCodes (except guest1)
    //generalSettings
    //temperatureConfig
    async pushRoomConfig(jsonTemplate, remoteDeviceId) {
        //console.log("KRECEM", jsonTemplate);
        //FIRST GO FOR ACCESS CODES
        let payload = {};
        let accessCodes = jsonTemplate.accessCodes;
        let accessCodeTypes = ['maid1', 'maid2', 'maid3', 'manager', 'security'];
        for (var i = 0; i < accessCodeTypes.length; i++) {
            let type = accessCodeTypes[i];
            let cardCodeType = null;
            _.forEach(this.state.srcEnums.cardCodeTypes, (val, key) => {
                if (val === accessCodes[type].cardCodeType) {
                    cardCodeType = key;
                    return false;
                }
            });
            if (cardCodeType) {
                payload = {
                    code: accessCodes[type].code,
                    validToTimestamp: accessCodes[type].validToTimestamp,
                    cardCodeType,
                };
                await rest('/src/setCardCodeWithValidToTime/' + remoteDeviceId, 'POST', payload);
            }
        }

        //NOW GO FOR GENERAL SETTINGS, ovo se mora promijeniti......, zatraži ključeve sa backenda
        let generalSettings = jsonTemplate.generalSettings;
        let generalSettingsKeys = Object.keys(this.state.srcEnums.generalSettingsParam);

        for (var k = 0; k < generalSettingsKeys.length; k++) {
            let key = generalSettingsKeys[k];
            if (generalSettings[key] !== undefined) {
                payload = { generalSettingsParamEnum: key, value: generalSettings[key] };
                await rest('/src/setGeneralSettingsByte/' + remoteDeviceId, 'POST', payload);
            }
        }

        //NOW GO FOR HW MAP
        //let hwMap = jsonTemplate.hwMap;
        for (var j = 0; j < jsonTemplate.hwMap.length; j++) {
            let cfg = jsonTemplate.hwMap[j];
            if (cfg.enabled && cfg.func !== undefined) {
                let basicIoPayload = { ioName: cfg.func, pinAddress: cfg.icPin, driverName: cfg.driver };
                await rest('/src/setBasicIOConfig/' + remoteDeviceId, 'POST', basicIoPayload);
                if (cfg.emulated !== undefined) {
                    let ioOverridePayload = { ioName: cfg.func, targetValue: cfg.emulated ? cfg.emulatedValue : 2 };
                    await rest('/src/setIOOverride/' + remoteDeviceId, 'POST', ioOverridePayload);
                }
            }
        }
        return true;
    }

    async pushConfiguration() {
        this.setState({ pushPending: true });
        //console.log(this.props.srcTemplate.SRCSettings);
        let srcSettingsTemplate = JSON.parse(this.props.srcTemplate.SRCSettings);
        //	console.log(srcSettingsTemplate);
        let selectedRooms = [];
        _.forEach(this.state.selectedIds, (roomInfoId) => {
            let room = _.find(this.props.rooms, (r) => r.id === roomInfoId);
            if (room) {
                selectedRooms.push(room);
            }
        });

        try {
            for (let i = 0; i < selectedRooms.length; i++) {
                await this.pushRoomConfig(srcSettingsTemplate, selectedRooms[i].remoteDeviceId);
                //now remove room from selection
                let selectedIds = _.clone(this.state.selectedIds);
                selectedIds = _.filter(selectedIds, selectedRooms[i].id);
                this.setState({ selectedIds });
            }
            this.setState({ pushPending: false });
        } catch (err) {
            console.log(err);
            this.repeatCount = this.repeatCount + 1;
            if (this.repeatCount < 5) {
                this.pushConfiguration();
            } else {
                alert(
                    window.translate("Upload failed after ") +
                        this.repeatCount +
                        window.translate(" repeats! Please try again or consult the issue with the supplier!")
                );
                this.repeatCount = 0;
                this.setState({ pushPending: false });
            }
        }
    }
    render() {
        //console.log(JSON.parse(this.props.srcTemplate.SRCSettings));
        let filteredRooms =
            this.state.tagFilter === null
                ? this.props.rooms
                : _.filter(this.props.rooms, (r) => _.includes(r.tags, this.state.tagFilter));

        if (this.state.pushPending) {
            return (
                <PageLoader
                    style={{ paddingTop: '20px' }}
                    header={window.translate("MULTICASTING SRC CONFIGURATION")}
                    heading={window.translate("please be patient and don't do anything until done")}
                />
            );
        }
        return (
            <div>
                <div className="alert alert-danger" role="alert">
                    {window.translate("SRC configuration multicasting may take a while. It depends on number of selected rooms and underlaying modems. Data collisions may occure, so please use late at night or on any other low usage period. In worst case, operation should be repeated.")}
                </div>

                {this.props.roomTags.length > 0 ? (
                    <button
                        onClick={() => {
                            this.setState({ tagFilter: null });
                        }}
                        className={
                            this.state.tagFilter === null
                                ? 'btn btn-sm btn-primary ml-2'
                                : 'btn btn-sm btn-outline-primary ml-2'
                        }
                    >
                        {window.translate("All tags")}
                    </button>
                ) : null}

                {_.map(this.props.roomTags, (tag) => {
                    return (
                        <button
                            onClick={() => {
                                this.setState({ tagFilter: tag, selectedIds: [] });
                            }}
                            className={
                                this.state.tagFilter === tag
                                    ? 'btn btn-sm btn-primary ml-2'
                                    : 'btn btn-sm btn-outline-primary ml-2'
                            }
                            key={tag}
                        >
                            {tag}
                        </button>
                    );
                })}

                <ul className="list-group mt-3 unselectable">
                    {_.filter(
                        _.map(filteredRooms, (r) => {
                            if (r.remoteDeviceId !== null) {
                                let isChecked = _.includes(this.state.selectedIds, r.id);
                                return (
                                    <li className="list-group-item" key={r.id}>
                                        <input
                                            onChange={this.handleSelection.bind(this, r.id)}
                                            checked={isChecked}
                                            id={'rm_' + r.id}
                                            type="checkbox"
                                        />
                                        <label className="ml-2" htmlFor={'rm_' + r.id}>
                                            {r.name}
                                        </label>
                                    </li>
                                );
                            } else {
                                return null;
                            }
                        }),
                        null
                    )}
                </ul>

                <button
                    onClick={this.pushConfiguration.bind(this)}
                    disabled={this.state.selectedIds.length === 0}
                    className="btn btn-warning pull-right mt-3"
                >
                    {window.translate("PUSH CONFIGURATION")}
                </button>
            </div>
        );
    }
}

export default branch(
    {
        roomTags: ['monkeys', 'roomTags'],
        rooms: ['monkeys', 'parsedRoomInfo'],
    },
    PushTemplate
);
