import { InvoiceItemsModel, InvoiceModelWithInvoiceItems, InvoicePreviewObject } from '@common/modelDefinition';
//@ts-ignore
import _ from 'lodash';

// jer je invoice iz invoicePreview s backenda jako okrnjen
const getInvoicePropertiesNeededForInvoicePreview = (
    editedInvoice: InvoicePreviewObject,
    invoice: InvoiceModelWithInvoiceItems, // grupni
    invoiceItems: InvoiceItemsModel[]
) => {
    const { generalDiscountPercentage } = invoice;
    const invoiceItemsSummary = getInvoiceItemsSummaryForInvoicePreview(invoiceItems, generalDiscountPercentage);

    const targetInvoice: InvoiceModelWithInvoiceItems = {
        ...invoice,
        ...editedInvoice,
        ...invoiceItemsSummary,
    };
    return targetInvoice;
};

const getInvoiceItemsSummaryForInvoicePreview = (
    invoiceItems: InvoiceItemsModel[],
    generalDiscountPercentage: number
) => {
    const invoiceValue = _.sumBy(
        invoiceItems.map((i) => {
            return Number(i.bruttoValueWithDiscount);
        })
    );
    const deliveredValue = _.sumBy(
        invoiceItems.map((i) => {
            return Number(i.bruttoUnitPrice);
        })
    );
    const totalValue = _.sumBy(
        invoiceItems.map((i) => {
            return Number(i.bruttoValueWithDiscount) * (1 - Number(generalDiscountPercentage) / 100);
        })
    );
    const generalDiscountValue = Math.abs(invoiceValue - totalValue);

    const invoice = {
        totalValue,
        deliveredValue,
        generalDiscountValue,
        invoiceValue,
    };
    return invoice;
};

 const getInvoiceTemplate =(invoice:InvoiceModelWithInvoiceItems)=> {
   
     const invoiceTemplate={
        InvoiceItems: [],
        advancePayments: [],
        advancePaymentsValue: 0,
        checkInTimestamp: invoice.checkInTimestamp,
        checkOutTimestamp: invoice.checkOutTimestamp,
        companyId: invoice.companyId,
        companyInfo: invoice.companyInfo,
        connectedInvoiceProcessed: null,
        connectedInvoices: invoice.connectedInvoices,
        createdAt: undefined,
        currency: invoice.currency,
        currencyExchangeRate: invoice.currencyExchangeRate,
        customerId: invoice.customerId,
        customerInfo: invoice.customerInfo,
        defaultCurrency: invoice.defaultCurrency,
        deliveredValue: 0,
        exchangeRates: invoice.exchangeRates,
        fiscal: 0,
        fiscalId: null,
        fiscalSRCProxy: null,
        generalDiscountPercentage: 0,
        generalDiscountValue: 0,
        id: undefined,
        invoiceNumber: null,
        invoiceType: 0,
        invoiceValue: 0,
        issuerCompanyInfo: invoice.issuerCompanyInfo,
        issuerSecurityCode: null,
        locale: invoice.locale,
        note: null,
        operatorName: invoice.operatorName,
        orderNumber: null,
        params: invoice.params,
        partialPayment: false,
        paymentAmount: 0,
        pendingInvoice: invoice.pendingInvoice,
        posTerminalId: null,
        posTerminalName: null,
        reclamation: 0,
        reclamationFiscalId: null,
        reclamationReason: null,
        refInvoice: null,
        reservationGroupUuid: invoice.reservationGroupUuid,
        reservationId: null,
        sfBusinessSpaceLabel: null,
        sfJIR: null,
        sfTollDeviceLabel: null,
        taxIncludedInPrice: true,
        taxRecapitulation: null,
        terminalType: "reservation",
        timestamp: null,
        totalBaseVAT: 0,
        totalBaseVAT_BD: 0,
        totalBaseValue: 0,
        totalBaseValue_BD: 0,
        totalLineItemDiscountValue: 0,
        totalVAT: 0,
        totalValue: 0,
        typeOfPayment: "G",
        updatedAt: undefined,
        vatRegistered: invoice.vatRegistered,
        invoiceNumberFull: null
     }
     return invoiceTemplate
}

export { getInvoicePropertiesNeededForInvoicePreview, getInvoiceTemplate};
