import rest from '@data/restWrapper';
import {
    GroupInvoiceDetails,
    InvoiceItemsModel,
    InvoiceModelWithInvoiceItems,
    InvoicePreview,
} from '@common/modelDefinition';

interface InvoiceDataForPrintView {
    invoice: InvoiceModelWithInvoiceItems;
    refInvoice: InvoiceModelWithInvoiceItems;
}

interface posTerminalInvoiceData {
    invoice: InvoiceModelWithInvoiceItems;
    invoiceItems: InvoiceItemsModel[];
    stornoInvoices: InvoiceModelWithInvoiceItems[];
}

export async function loadInvoiceDataForPrintView(invoiceId: number): Promise<InvoiceDataForPrintView> {
    const { invoice, refInvoice } = await rest('/loadInvoiceDataForPrintView/' + invoiceId);
    return { invoice, refInvoice };
}

export async function stornoPosTerminalInvoice(
    invoiceId: number,
    reclamationReason: string,
    terminalType: string
): Promise<posTerminalInvoiceData> {
    const { invoice, invoiceItems, stornoInvoices } = await rest('/stornoPosTerminalInvoice', 'POST', {
        invoiceId,
        properties: { reclamation: 1, reclamationReason },
        terminalType,
    });
    return { invoice, invoiceItems, stornoInvoices };
}

export async function fiscalizeConcludedInvoice(invoiceId: number): Promise<InvoiceModelWithInvoiceItems> {
    const invoice = await rest('/fiscalizeConcludedInvoice', 'POST', { invoiceId });
    return invoice;
}

export async function getReservationInvoicePreview(reservationId: number): Promise<InvoicePreview> {
    const { invoice, invoiceItems } = await rest(`/getReservationInvoicePreview`, 'POST', { reservationId });
    return { invoice, invoiceItems };
}

export async function getReservationGroupInvoicePreview(reservationGroupUuid: string): Promise<GroupInvoiceDetails> {
    const groupInvoiceDetails: GroupInvoiceDetails = await rest(`/getReservationInvoicePreview`, 'POST', {
        reservationGroupUuid,
    });
    return groupInvoiceDetails;
}
