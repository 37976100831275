import React, { useEffect, useState } from 'react'
import { branch } from 'baobab-react/higher-order';
import rest from '../../data/restWrapper';

const TestHWModalComponent = (props) => {
    const [modemsArray, setmodemsArray] = useState([])
    const [showLoading, setShowLoading] = useState(true)

    useEffect(() => {
        const loadData = async () => {
            fetchData()
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [])

    async function fetchData() {
        try {
            let testResults = await rest("/testSRCProxy", "POST", { srcProxyUuid: props.selectedSRCProxyForTestId })
            const { modems } = testResults
            setmodemsArray(modems)
            setShowLoading(false)
        } catch (error) {
            console.log(error);
            setShowLoading(false)
        }
    }

    async function repeatTest() {
        setShowLoading(true)
        fetchData()
    }
    
   

    if (showLoading) {
        return <div className='text-center'>
            <i className='fa fa-spinner fa-spin'></i>
        </div>
    }
    return (
        <div>
            <table className='table'>
                <thead>
                    <tr className='text-center'>
                        <th>Port</th>
                        <th>Enabled</th>
                        <th>Connected</th>
                    </tr>
                </thead>
                <tbody>
                    {modemsArray && modemsArray.map(mdm => {
                        return (
                            <tr key={mdm.port} className="text-center">

                                <td>{mdm.port}</td>
                                <td>{mdm.enabled ? (
                                    <i className="fa fa-circle text-success"></i>
                                ) : (
                                    <i className="fa fa-circle text-danger"></i>
                                )}</td>
                                <td>{mdm.connected ? (
                                    <i className="fa fa-circle text-success"></i>
                                ) : (
                                    <i className="fa fa-circle text-danger"></i>
                                )}</td>
                            </tr>
                        );
                    })}
                </tbody>
            </table>
            <div className='text-center'>
            {modemsArray && <div onClick={() => { repeatTest() }} className='btn btn-sm btn-info'>Repeat test</div>}
            </div>
        </div>
    )
}

export default branch(
    {
        smartRoomControllers: ['model', 'SmartRoomController'],
    },
    TestHWModalComponent
);