import React from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import { saveModelData } from '../../data/actions/modelActions';

const TagPicker = (props) => {
    const selectTag = (tag, target, tagPropName) => {
        if (target) {
            let payload = {
                id: target.id,
                whitelistTagCSV: target.whitelistTagCSV ? target.whitelistTagCSV : '',
                roomTagWhitelistCSV: target.roomTagWhitelistCSV ? target.roomTagWhitelistCSV : '',
            };

            if (payload[tagPropName] === null || payload[tagPropName] === '') {
                payload[tagPropName] = tag;
            } else {
                const tags = payload[tagPropName].split(';');
                if (!tags.includes(tag)) {
                    payload[tagPropName] = payload[tagPropName] + ';' + tag;
                }
            }

            saveModelData('DoorAccessPoint', payload).catch((err) => {
                console.warn(err);
            });
        } else {
            console.warn('No target while selecting tag!');
        }
    };

    const removeTag = (tag, target, tagPropName) => {
        let filteredTags = null;
        if (target) {
            filteredTags = target[tagPropName] ? target[tagPropName].split(';') : [];
            _.remove(filteredTags, (t) => t === tag);
        }

        let payload = {
            id: target.id,
        };
        payload[tagPropName] = filteredTags.join(';');

        saveModelData('DoorAccessPoint', payload).catch((err) => {
            console.warn(err);
        });
    };

    let tagPropName = 'whitelistTagCSV';
    switch (props.tagSourceTable) {
        default:
        case 'RoomInfo':
            tagPropName = 'roomTagWhitelistCSV';
            break;
        case 'WhiteList':
            tagPropName = 'whitelistTagCSV';
            break;
    }

    let tags = [];
    try {
        props[props.tagSourceTable].forEach((r) => {
            if (r.tags) {
                r.tags.split(';').forEach((tag) => {
                    tags.push(tag.replace(' ', ''));
                });
            }
        });

        tags = [...new Set(tags)];

        if (props.tagSourceTable === 'WhiteList') {
            tags.push('guests');
            tags.push('self check in');
        }
    } catch (err) {
        console.log(err);
    }

    const target = props.DoorAccessPoint.find((dpa) => dpa.id === props.ap.id);
    const targetTags = target && target[tagPropName] ? target[tagPropName].split(';') : [];

    return (
        <div>
            <h5>{window.translate('Available tags')}</h5>
            {tags.map((tag) => {
                if (tag === 'guests' || tag === 'self check in') {
                    return (
                        <span
                            onClick={selectTag.bind(this, tag, target, tagPropName)}
                            key={tag.replace(' ', '')}
                            className="badge badge-warning mr-1 pointer"
                        >
                            {tag}
                        </span>
                    );
                } else {
                    return (
                        <span
                            onClick={selectTag.bind(this, tag, target, tagPropName)}
                            key={tag.replace(' ', '')}
                            className="badge badge-secondary mr-1 pointer"
                        >
                            {tag}
                        </span>
                    );
                }
            })}
            <hr />
            {targetTags ? <h5>{window.translate('Pinned tags')}:</h5> : <h5>{window.translate('No pinned tags')}</h5>}

            {targetTags.map((tag) => {
                return (
                    <span
                        onClick={removeTag.bind(this, tag, target, tagPropName)}
                        key={tag.replace(' ', '')}
                        className="badge badge-primary mr-1 pointer"
                    >
                        {tag}
                    </span>
                );
            })}
        </div>
    );
};

export default branch(
    {
        WhiteList: ['model', 'WhiteList'],
        RoomInfo: ['model', 'RoomInfo'],
        DoorAccessPoint: ['model', 'DoorAccessPoint'],
    },
    TagPicker
);
