import React from 'react';
import {
    RoomInfoStatusProps,
    CleaningStatusEnum,
    ActiveRoomEvent,
    CleaningStatusTexts,
    ReservationStatusEnum,
    ReservationStatusText,
    CleaningStatusColors,
    CleaningStatusIcons,
    StatusInfosProps,
    CleaningInspectionIcons,
    CleaningInspectionEnum,
    CleaningInspectionColor,
} from './interfaces';
import { translate } from '@data/translations';

import moment from 'moment';

//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { CleaningStatusExtendedWithIncludedModels, ReservationEditModel } from '@common/modelDefinition';

const RoomInfoStatus = (props: RoomInfoStatusProps) => {
    let { cleaningStatus, reservations, activeRoomEvents, /*loggedUser*/ showCleaningOptions } = props;
    // const enableRoomStatusChangeHandlers = false; //props.loggedUser.role mislim da i čistačica ima pravo ovo mijenjati
    let animatedCssAddOn = '';
    if (cleaningStatus.cleaningStatus === CleaningStatusEnum.cleaningRequested) {
        animatedCssAddOn = ' animated wobble  ';
    }
    const cleaningStatusName: string = CleaningStatusEnum[cleaningStatus.cleaningStatus];

    let tileStyle =
        cleaningStatus.reservationStatus === ReservationStatusEnum.stay &&
        cleaningStatus.cleaningStatus === CleaningStatusEnum.stagedForCleaning
            ? {
                  color: 'white',
                  backgroundColor: '#c9af2e',
              }
            : CleaningStatusColors[cleaningStatusName as keyof typeof CleaningStatusColors];
    //@ts-ignore
    tileStyle = { ...tileStyle, width: '100%', height: '100%' };

    const iconName: string = CleaningStatusIcons[cleaningStatusName as keyof typeof CleaningStatusIcons];

    const cleaningRequest: ActiveRoomEvent | undefined = activeRoomEvents.find(
        (re: ActiveRoomEvent) => re.roomInfoId === cleaningStatus.roomInfoId && re.ruleName === 'alarm_cleaningService'
    );
    const doNotDisturb: ActiveRoomEvent | undefined = activeRoomEvents.find(
        (re: ActiveRoomEvent) => re.roomInfoId === cleaningStatus.roomInfoId && re.ruleName === 'alarm_doNotDisturb'
    );

    const cleaningRequested =
        cleaningRequest !== undefined ? (
            <span className="text-warning blink-animation pull-right">
                {' '}
                <i className=" fa fa-bell" /> CLR
            </span>
        ) : null;
    const doNotDisturbRequested =
        doNotDisturb !== undefined ? (
            <span className=" text-danger blink-animation pull-right">
                {' '}
                <i className="fa fa-ban" /> DND{' '}
            </span>
        ) : null;
    const cleaningInspectionName: string | null =
        cleaningStatus.inspectionStatus !== null ? CleaningInspectionEnum[cleaningStatus.inspectionStatus] : null;

    const cleaningInspectionColor =
        CleaningInspectionColor[cleaningInspectionName as keyof typeof CleaningInspectionColor];

    const inspectionRequestStatus = cleaningInspectionName ? (
        <span className="pull-right" style={{ color: cleaningInspectionColor }}>
            {' '}
            <i className={CleaningInspectionIcons[cleaningInspectionName as keyof typeof CleaningInspectionIcons]} />
        </span>
    ) : null;
    const noteInfo =
        cleaningStatus.cleaningNote || cleaningStatus.inspectionNote ? (
            <span className=" text-secondary pull-right">
                {' '}
                <i className="fa fa-envelope" />{' '}
            </span>
        ) : null;

    // const appendDown = props.appendDown ? props.appendDown : false;
    const appendDown = false;

    const incommingReservations =
        reservations && reservations.filter((r) => r.statusEnum !== 2 && r.checkInTimestamp > Date.now());

    const orderedReservations = incommingReservations.sort((a, b) => a.checkInTimestamp - b.checkInTimestamp);

    const nextReservation: ReservationEditModel | undefined = orderedReservations.find(
        (r) => r.roomInfoId === cleaningStatus.roomInfoId
    );
    let checkIn = '',
        checkOut = '';
    if (nextReservation) {
        checkIn = moment(nextReservation.checkInTimestamp).format('ll');
        checkOut = moment(nextReservation.checkOutTimestamp).format('ll');
    }

    // nije iskorišteno: roomCleaningMode,outgoingTodaysReservations -nalazilo se u getCleaningStatus objektu
    /* return {
    header,
    status: s,
    room,
    cleaningRequest,
    roomCleaningMode,
    incommingTodaysReservations,
    outgoingTodaysReservations,
    doNotDisturb,
};*/

    return (
        <div
            onClick={() => {
                showCleaningOptions(cleaningStatus);
            }}
            className={
                appendDown
                    ? 'hover-background bg-white pointer border-top border-left border-right'
                    : 'hover-background  bg-white pointer border rounded'
            }
        >
            <div className="row">
                <div className="col-3 col-md-3 col-xl-3 text-center">
                    <div className="pt-4" style={tileStyle}>
                        <i className={iconName + ' fa-2x ' + animatedCssAddOn} />
                    </div>
                </div>
                <div className="col-9 col-md-9 col-xl-9 pl-0">
                    <div className="border-bottom text-primary font-weight-bold p-2 elipsis">
                        {cleaningStatus.RoomInfo ? cleaningStatus.RoomInfo.name : ''} {cleaningRequested}{' '}
                        {doNotDisturbRequested} {noteInfo} {inspectionRequestStatus}
                    </div>
                    <div className="p-1 pl-2 ">
                        <StatusInfos cleaningRequest={cleaningRequest} cleaningStatus={cleaningStatus} />
                        {nextReservation ? (
                            <small className="float-right pt-1">
                                <small> {checkIn + ' / ' + checkOut}</small>
                            </small>
                        ) : null}
                    </div>
                </div>
            </div>

            {/* {props.children} */}
        </div>
    );
};

const StatusInfos = (props: StatusInfosProps) => {
    const { cleaningStatus, cleaningRequest } = props;
    const cleaningStatusName: string = CleaningStatusEnum[cleaningStatus.cleaningStatus];
    const cleaningStatusText: string = CleaningStatusTexts[cleaningStatusName as keyof typeof CleaningStatusTexts];
    const reservationStatus: string = ReservationStatusEnum[cleaningStatus.reservationStatus];
    const reservationStatusText: string =
        ReservationStatusText[reservationStatus as keyof typeof ReservationStatusText];

    const cleaningStatusEnum: number = cleaningStatus.cleaningStatus;

    const cleaningRequested: ActiveRoomEvent | undefined =
        cleaningStatusEnum === CleaningStatusEnum.noActionTaken &&
        cleaningRequest &&
        moment().isSame(cleaningRequest.openingTimestamp, 'day')
            ? cleaningRequest
            : undefined;

    const cleaningFinished: CleaningStatusExtendedWithIncludedModels | undefined =
        cleaningStatusEnum === CleaningStatusEnum.cleaningFinished &&
        cleaningStatus.cleaningStartedTimestamp &&
        cleaningStatus.cleaningFinishedTimestamp
            ? cleaningStatus
            : undefined;

    let cleaningStatusFoother = null;

    if (cleaningRequested) {
        cleaningStatusFoother = (
            <small key="cleaningStatus" className="">
                {translate('Cleaning requested')}: {moment(cleaningRequested.openingTimestamp).format('lll')}
            </small>
        );
    } else if (cleaningFinished) {
        const started = cleaningStatus.cleaningStartedTimestamp
            ? moment(cleaningStatus.cleaningStartedTimestamp)
            : moment();
        const finished = cleaningStatus.cleaningFinishedTimestamp
            ? moment(cleaningStatus.cleaningFinishedTimestamp)
            : moment();
        cleaningStatusFoother = (
            <span key="duration">
                <small key="cleaningStatus" className="">
                    {moment.duration(started.diff(finished)).humanize()}
                </small>
            </span>
        );
    } else {
        if (cleaningStatus.reservationStatus > 1) {
            cleaningStatusFoother = (
                <small className="" key="reservationStatus">
                    {translate(reservationStatusText)}
                </small>
            );
        } else {
            cleaningStatusFoother = (
                <small key="cleaningStatus" className="">
                    {translate(cleaningStatusText)}
                </small>
            );
        }
    }

    return (
        // prikaži cleaning status ako imamo vrijeme završetka ili zahtjev za čišćenjem sa sobe, ako to nije ispunjeno prikazi cleaning status samo ako ne postoji rezervacija na sobi inače prikaži status rezervacije
        <small>{cleaningStatusFoother}</small>
    );
};

export default branch(
    {
        reservations: ['model', 'Reservation'],
        activeRoomEvents: ['model', 'RoomEventTicket'],
        loggedUser: ['authTokenInfo', 'user'],
        // rooms: ['monkeys', 'parsedRoomInfo'],
    },
    RoomInfoStatus
);
