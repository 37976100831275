import * as React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { CustomerModel, CompanyModel } from '@common/modelDefinition';
import { translate } from '@data/translations';
import { ReservationController } from './resController';
import { CustomersAndCompanies } from './parts/CustomersAndCompanies';
import PageTitle from '@components/pageTitle';
import TabsWrapper from './parts/TabsWrapper';
import { getUrlParameterByName } from '@data/utils';

import { getQuery, getCheckInAndCheckoutTimestamps } from './utils';

const ctrl = new ReservationController();

function ReservationForm(props: any) {
    const { history, location, otherSettings } = props;
    const reservationId = props?.match?.params?.reservationId ? Number(props.match.params.reservationId) : null;
    const uuid = getUrlParameterByName('uuid');

    React.useEffect(() => {
        const roomInfoId = Number(props.match.params.roomInfoId); //should be always present!!!
        const roomIds: number[] = [];

        if (getUrlParameterByName('roomIds')) {
            getUrlParameterByName('roomIds')
                ?.split(',')
                .forEach((id) => {
                    roomIds.push(Number(id));
                });
        } else {
            roomIds.push(roomInfoId);
        }

        const ctrl2 = new ReservationController();
        if (reservationId) {
            ctrl2.loadReservation(reservationId);
        } else if (uuid) {
            ctrl2.loadGroup(uuid);
        } else {
            const { checkInTimestamp, checkOutTimestamp } = getCheckInAndCheckoutTimestamps(location, otherSettings);
            const { adults = 1 } = getQuery(location);
            roomIds.forEach((roomId: number, index: number) => {
                const clearPrevious = index === 0;
                ctrl2.addReservation(clearPrevious, roomId, checkInTimestamp, checkOutTimestamp, adults);
            });
        }

        return () => {
            ctrl2.clearAll(); //cleanup on unmount
        };
        // eslint-disable-next-line
    }, [reservationId, uuid]);

    const groupReservation = ctrl.getGroupReservation();
    const groupReservationHolder = ctrl.getGrupReservationHolder();

    return (
        <div>
            <PageTitle title={groupReservation ? translate('Group reservation') : translate('Reservation')} />

            <button
                onClick={() => {
                    props.history.push('/'); //go to dashbaord
                    //props.history.goBack();
                }}
                className="btn btn-sm btn-secondary mr-1 mb-2 "
            >
                {translate('Dashboard')}
            </button>

            {((groupReservation && groupReservation.Reservations) || reservationId) && (
                <button
                    className="btn btn-sm btn-primary uppercase  mr-1 mb-2"
                    onClick={() => {
                        if (groupReservation && groupReservation.uuid) {
                            history.push('/issueGroupInvoice/' + groupReservation.uuid);
                        } else {
                            const reservation = ctrl.getReservations()[0];
                            history.push('/checkoutAndInvoice/' + reservation.id);
                        }
                    }}
                >
                    <i className="fa fa-print" /> {translate('Invoices')}
                </button>
            )}

            <div style={{ backgroundColor: 'rgb(250,250,250)' }} className={'rounded shadow-sm p-2'}>
                {groupReservation && ( //prikaži samo ako je grupna rezervacija u pitanju
                    <>
                        <div className="row">
                            <div className="col-md-2 pt-1">
                                <b>{translate('Group reservation holder')}:</b>
                            </div>
                            <div className="col-md-10">
                                <CustomersAndCompanies
                                    history={history}
                                    company={groupReservationHolder.company}
                                    customer={groupReservationHolder.customer}
                                    customerSelect={(c: CustomerModel) => {
                                        groupReservationHolder.customer = c;
                                        ctrl.setGroupReservationHolder(groupReservationHolder);
                                    }}
                                    companySelect={(c: CompanyModel) => {
                                        groupReservationHolder.company = c;
                                        ctrl.setGroupReservationHolder(groupReservationHolder);
                                    }}
                                    hideOnSelected={false}
                                    deleteCustomer={(c: CustomerModel) => {
                                        groupReservationHolder.customer = null;
                                        ctrl.setGroupReservationHolder(groupReservationHolder);
                                    }}
                                    deleteCompany={(c: CompanyModel) => {
                                        groupReservationHolder.company = null;
                                        ctrl.setGroupReservationHolder(groupReservationHolder);
                                    }}
                                />
                            </div>
                        </div>
                        <hr className="p-1 m-1" />
                    </>
                )}
                {groupReservation && ( //prikaži samo ako je grupna rezervacija u pitanju
                    <>
                        <div className="row pb-1">
                            <div className="col-2 pt-1">
                                <b>{translate('Group reservation name')}:</b>
                            </div>
                            <div className="col-4">
                                <input
                                    onChange={(e) => ctrl.setGroupName(e.target.value)}
                                    placeholder={groupReservation.groupName}
                                    value={groupReservation.groupName}
                                    type="text"
                                    className="form-control form-control-sm"
                                />
                            </div>
                        </div>
                        <hr className="p-1 m-1" />
                    </>
                )}

                <TabsWrapper history={history} />
            </div>
        </div>
    );
}

export default branch(
    {
        _reservations: ['state', 'reservationForm2', 'reservations'],
        otherSettings: ['otherSettings'],
        groupReservationInfo: ['state', 'reservationForm2', 'groupReservation'], //refresh only
    },
    ReservationForm
);
