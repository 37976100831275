import React, { useEffect, useState } from 'react';
import { translate } from '@data/translations';
import {
    ReservationModelWithModelsIncluded,
    MinibarLogWithIncludedModels,
    RoomInfoEditModel,
} from '@common/modelDefinition';
import Image from '@components/image';
import Tooltip from '@components/tooltip/tooltip';
import { setModalContent } from '@components/modal';
import Delete from '@components/confirmModal';

import PageTitle from '@components/pageTitle';
import { isFeatureEnabled } from '@components/FeatureFlag';
import {
    loadActiveReservation,
    loadMinibarItemsForRoom,
    attachMinibarLogsToReservation,
    delMinibarData as _delMinibarData,
} from './api';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { ConsumptionLog } from '@pages/reservations2/parts/MinibarConsumption';
import { getTodaysRoomConsumption, MinibarLogsGroupByDate } from './utils';

interface ItemsGridViewProps {
    roomMiniBarItems: MinibarLogWithIncludedModels[];
    handleItemConsumption: Function;
    consumedMinibarItems: MinibarLogWithIncludedModels[];
}
interface ReservationInfoProps {
    activeReservation: ReservationModelWithModelsIncluded | null;
    consumedMinibarItems: MinibarLogWithIncludedModels[];
    setRoomMiniBarItems: Function;
    setConsumedMinibarItems: Function;
    setTodayMinibarData: Function;
}

const CheckRoomMinibar = (props: any) => {
    const { roomInfo, loggedUser } = props;
    const [activeReservation, setActiveReservation] = useState<ReservationModelWithModelsIncluded | null>(null);
    const [roomMiniBarItems, setRoomMiniBarItems] = useState<MinibarLogWithIncludedModels[]>([]);
    const [consumedMinibarItems, setConsumedMinibarItems] = useState<MinibarLogWithIncludedModels[]>([]);
    const [todayMinibarData, setTodayMinibarData] = useState<MinibarLogsGroupByDate>({});
    const [expandConsumption, setExpandConsumption] = useState<boolean>(false);

    const roomInfoId = Number(props.match.params.roomInfoId);
    const room = roomInfo.length > 0 ? roomInfo.find((r: RoomInfoEditModel) => r.id === roomInfoId) : null;

    useEffect(() => {
        const loadData = async () => {
            if (roomInfoId) {
                const _activeReservation = await loadActiveReservation(roomInfoId);
                setActiveReservation(_activeReservation);
                const _roomMiniBarItems = await loadMinibarItemsForRoom(roomInfoId);
                setRoomMiniBarItems(_roomMiniBarItems);
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });
    }, [roomInfoId]);

    useEffect(() => {
        const loadData = async () => {
            if (activeReservation) {
                const _todayMinibarData = await getTodaysRoomConsumption(activeReservation.id);
                setTodayMinibarData(_todayMinibarData);
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });
    }, [activeReservation]);

    if (!isFeatureEnabled('minibar')) {
        return 'Minibar disabled';
    }

    if (!room) {
        return 'Loading ...';
    }

    const handleItemConsumption = (minibarLog: MinibarLogWithIncludedModels, undo: boolean = false) => {
        const _consumedMinibarItems = [...consumedMinibarItems];
        const alreadyConsumed = _consumedMinibarItems.find((ci) => ci.posItemId === minibarLog.posItemId);
        if (alreadyConsumed) {
            const newConsumption = _consumedMinibarItems.map((mb) => {
                if (mb.posItemId === minibarLog.posItemId) {
                    const newMinibarLog = { ...mb };
                    newMinibarLog.previousState = minibarLog.newState;
                    newMinibarLog.newState = !undo ? newMinibarLog.newState - 1 : newMinibarLog.newState + 1;
                    newMinibarLog.reservationId = activeReservation?.id;
                    newMinibarLog.userUUID = loggedUser.uuid;
                    return newMinibarLog;
                } else {
                    return mb;
                }
            });
            setConsumedMinibarItems(newConsumption);
        } else {
            const newMinibarLog = { ...minibarLog };
            newMinibarLog.previousState = minibarLog.newState;
            newMinibarLog.newState = !undo ? newMinibarLog.newState - 1 : newMinibarLog.newState + 1;
            newMinibarLog.reservationId = activeReservation?.id;
            newMinibarLog.userUUID = loggedUser.uuid;
            _consumedMinibarItems.push(newMinibarLog);
            setConsumedMinibarItems(_consumedMinibarItems);
        }
    };

    const deleteMinibarData = (minibarLog: MinibarLogWithIncludedModels) => {
        setModalContent(
            <Delete
                actionForYes={async () => {
                    await _delMinibarData(minibarLog, loggedUser);
                    if (activeReservation) {
                        const _todayMinibarData = await getTodaysRoomConsumption(activeReservation.id);
                        setTodayMinibarData(_todayMinibarData);
                    }
                    const _roomMiniBarItems = await loadMinibarItemsForRoom(roomInfoId);
                    setRoomMiniBarItems(_roomMiniBarItems); 
                }}
            />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };

    let title = translate('Minibar from ') + ` ${room.name} `;

    return (
        <div className="">
            <PageTitle title={title} />
            <div className="">
                <button
                    onClick={() => {
                        props.history.goBack();
                    }}
                    className="btn btn-sm btn-secondary mb-2"
                >
                    {translate('GO BACK')}
                </button>
            </div>
            <div className="row">
                <div className="col-md-10">
                    <ItemsGridView
                        roomMiniBarItems={roomMiniBarItems}
                        handleItemConsumption={handleItemConsumption}
                        consumedMinibarItems={consumedMinibarItems}
                    />
                </div>
                <div className="col-md-2">
                    <ReservationInfo
                        activeReservation={activeReservation}
                        consumedMinibarItems={consumedMinibarItems}
                        setConsumedMinibarItems={setConsumedMinibarItems}
                        setRoomMiniBarItems={setRoomMiniBarItems}
                        setTodayMinibarData={setTodayMinibarData}
                    />
                </div>
            </div>
            <div className="">
                <h5
                    onClick={() => {
                        setExpandConsumption(!expandConsumption);
                    }}
                    className="mt-3 pointer text-center border-bottom border-top uppercase text-primary bg-light p-2"
                >
                    <i className={expandConsumption ? 'fa fa-compress' : 'fa fa-expand '} />{' '}
                    {translate('CONSUMPTION LOG')}{' '}
                    <i className={expandConsumption ? 'fa fa-compress' : 'fa fa-expand '} />
                </h5>
            </div>
            {expandConsumption ? (
                <ConsumptionLog
                    deleteMinibarData={deleteMinibarData}
                    logs={todayMinibarData}
                    showRes={true}
                    defCurrency={''}
                    message={''}
                />
            ) : null}
        </div>
    );
};

const ItemsGridView = (props: ItemsGridViewProps) => {
    const { roomMiniBarItems, handleItemConsumption, consumedMinibarItems } = props;
    if (roomMiniBarItems.length === 0) {
        return <div className="p-2 col-4 text-center m-auto border border-danger">{translate('Minibar is empty')}</div>;
    }
    return (
        <div className="row border">
            {roomMiniBarItems.map((p: MinibarLogWithIncludedModels) => {
                const currentItem = consumedMinibarItems.find((i) => i.posItemId === Number(p.posItemId));
                let count = 0;
                if (currentItem) {
                    count = p.newState - currentItem.newState;
                }
                return (
                    <div className="col-md-2 mt-2 mb-2" key={p.id}>
                        <div
                            className="bg-white pointer"
                            onClick={() => {
                                handleItemConsumption(p);
                            }}
                        >
                            <Image
                                posItemId={p.PosItem.id}
                                style={{ height: '70px', width: '100%', objectFit: 'contain' }}
                                alt={translate('CONSUMATION')}
                            />
                        </div>
                        <div className="bg-white">
                            <h4>
                                <Tooltip style={{ fontSize: '12px' }} message={translate('Consumption')}>
                                    <b>{count} / </b>
                                </Tooltip>
                                <Tooltip style={{ fontSize: '12px' }} message={translate('Stock')}>
                                    <small>
                                        <b>{p.newState - count}</b>
                                    </small>
                                </Tooltip>
                                <b className="pull-right">
                                    <Tooltip style={{ fontSize: '12px' }} message={translate('Undo consumption')}>
                                        <i
                                            className={count ? 'fa fa-undo p-1' : 'fa fa-undo p-1 text-light'}
                                            onClick={() => {
                                                if (count > 0) {
                                                    handleItemConsumption(p, true);
                                                }
                                            }}
                                        />
                                    </Tooltip>
                                </b>
                            </h4>
                            <p className="elipsis text-center m-0 bg-primary text-white" style={{userSelect: 'none' }}>{p.PosItem.name}</p>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const ReservationInfo = (props: ReservationInfoProps) => {
    const {
        activeReservation,
        consumedMinibarItems,
        setRoomMiniBarItems,
        setConsumedMinibarItems,
        setTodayMinibarData,
    } = props;
    if (!activeReservation) {
        return null;
    }
    const guest = activeReservation
        ? activeReservation.Customer
            ? activeReservation.Customer.firstName + ' ' + activeReservation.Customer.lastName
            : activeReservation.Company
            ? activeReservation.Company.name
            : ''
        : '';
    const { roomInfoId, id } = activeReservation;
    return (
        <div className="bg-white rounded h-100 border">
            <div className="d-flex justify-content-center">
                <div className="text-center mt-2">
                    <h5>
                        <small>
                            <b>{translate('Active reservation')}</b>
                        </small>
                    </h5>
                    <h6>
                        <small>
                            {translate('From')}: {new Date(activeReservation.checkInTimestamp).toLocaleDateString()} -
                            {translate('To')}: {new Date(activeReservation.checkOutTimestamp).toLocaleDateString()}
                        </small>
                    </h6>
                    <h6>
                        <small>
                            {translate('Guest')}: {guest}
                        </small>
                    </h6>
                </div>
            </div>
            <div className="text-center mt-4">
                <button
                    onClick={async () => {
                        await attachMinibarLogsToReservation(consumedMinibarItems);
                        const _roomMiniBarItems = await loadMinibarItemsForRoom(roomInfoId);
                        setRoomMiniBarItems(_roomMiniBarItems);
                        setConsumedMinibarItems([]);
                        const _todayMinibarData = await getTodaysRoomConsumption(id);
                        setTodayMinibarData(_todayMinibarData);
                    }}
                    className="btn btn-sm btn-outline-primary"
                >
                    {translate('Attach to reservation')}
                </button>
            </div>
        </div>
    );
};

export default branch(
    {
        posItems: ['model', 'PosItem'],
        roomInfo: ['model', 'RoomInfo'],
        loggedUser: ['authTokenInfo', 'user'],
    },
    CheckRoomMinibar
);
