import rest from '../../data/restWrapper';
import { toast } from 'react-toastify';

const registerDeposit = async ({ cashAmt, operation, enuCode }) => {
    try {
        const cashAmtDec = parseFloat(cashAmt).toFixed(2);
        const result = await rest('/registerDeposit', 'POST', { cashAmt: cashAmtDec, operation, enuCode });
        console.log('result', result);
        toast("Deposit register: '", { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
    } catch (err) {
        toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
    }
};

export { registerDeposit };
