import * as React from 'react';
import PageTitle from '@components/pageTitle';
import SearchField from '@pages/search/searchField';
import { CompanyModel, CustomerModel, GroupReservationModel, ReservationModel } from '@common/modelDefinition';
import { ReservationController } from '../reservations2/resController';
import { GroupReservationController } from './groupController';
import { translate } from '@data/translations';
import SingleGroupReservation from './singleGroupRes';
import { getQuery } from '../reservations2/utils';

export interface GroupReservation extends GroupReservationModel {
    Customer: CustomerModel | null;
    Company: CompanyModel | null;
    Reservations: ReservationModel[] | null;
}

interface GroupReservationsProps {
    history: any;
    match: any;
}

function GroupReservations(props: GroupReservationsProps) {
    const ctrl = new GroupReservationController();
    const resCtrl = new ReservationController();
    const { history, match } = props;
    const reservationUuid = match?.params?.reservationUuid;
    const [resId, setReservationId] = React.useState(null as number | null);

    const [groupReservations, setGroupReservations] = React.useState([] as GroupReservation[]);
    const [reservation, setReservation] = React.useState(null as ReservationModel | null);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [searchTerm, setSearchTerm] = React.useState('');

    React.useEffect(() => {
        const loadData = async () => {
            if (reservationUuid) {
                const gr = await ctrl.getGroupReservations(reservationUuid);
                setGroupReservations(gr);
            } else {
                const gr: GroupReservation[] = await ctrl.getGroupReservations(null);
                gr.sort((a, b) => (a.createdAt && b.createdAt && a.createdAt < b?.createdAt ? 1 : -1));
                setGroupReservations(gr);
            }
            const queryParam = await getQuery(history.location);
            const { reservationId } = queryParam;
            if (reservationId) {
                setReservationId(Number(reservationId));
                await resCtrl.loadReservation(Number(reservationId));
                const reservations = await resCtrl.getReservations();
                const _reservation: any = reservationId
                    ? reservations.find((r) => r.id === Number(reservationId))
                    : null;
                setReservation(_reservation);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
        // eslint-disable-next-line
    }, [reservationUuid]);

    const filteredGroupReservations: GroupReservation[] = [];

    groupReservations.forEach((gr: GroupReservation) => {
        if (
            gr.Customer?.firstName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
            gr.Customer?.lastName?.toLowerCase().includes(searchTerm.toLowerCase())
        ) {
            filteredGroupReservations.push(gr);
        }
        if (gr.Company?.name?.toLowerCase().includes(searchTerm.toLowerCase())) {
            filteredGroupReservations.push(gr);
        }
    });

    return (
        <div>
            <PageTitle />

            <div className="row ">
                <div className="col-12 ">
                    <div className="form-inline">
                        <button
                            onClick={() => {
                                history.goBack();
                            }}
                            className="btn btn-sm btn-secondary mr-1 mb-2 "
                        >
                            {translate('GO BACK')}
                        </button>
                        {resId ? (
                            <button
                                onClick={async () => {
                                    if (reservation) {
                                        await ctrl.createNewGroupRes(reservation);
                                        history.goBack();
                                    }
                                }}
                                className="btn btn-sm btn-warning mr-1 mb-2 "
                            >
                                {translate('CREATE NEW GROUP')}
                            </button>
                        ) : null}
                        {reservation ? null : (
                            <SearchField
                                className="form-control form-control form-control-sm mr-1 mb-2"
                                history={history}
                                onChangeHandler={(e: any) => setSearchTerm(e.target.value)}
                            />
                        )}
                    </div>
                </div>
            </div>

            <ul className="list-group list-group-flush ">
                {filteredGroupReservations.map((g, index) => {
                    if (g.status === 1) {
                        return (
                            <li key={index} className={'px-1 py-1 list-group-item mb-3 p-3'}>
                                <SingleGroupReservation
                                    g={g}
                                    index={index}
                                    history={history}
                                    reservation={reservation}
                                    setGroupReservations={setGroupReservations}
                                    reservationId={resId}
                                />
                            </li>
                        );
                    }
                    return null;
                })}
            </ul>
            <h5
                onClick={() => {
                    setIsExpanded(!isExpanded);
                }}
                className="mt-3 pointer text-center border-bottom border-top uppercase text-primary bg-light p-2"
            >
                <i className={isExpanded ? 'fa fa-compress' : 'fa fa-expand '} />{' '}
                {translate('CLOSED GROUP RESERVATIONS')}{' '}
                <i className={isExpanded ? 'fa fa-compress' : 'fa fa-expand '} />
            </h5>
            {isExpanded ? (
                <ul className="list-group list-group-flush ">
                    {filteredGroupReservations.map((g, index) => {
                        if (g.status === 0) {
                            return (
                                <li key={index} className={'px-1 py-1 list-group-item mb-3 p-3'}>
                                    <SingleGroupReservation
                                        g={g}
                                        index={index}
                                        history={history}
                                        reservation={reservation}
                                        setGroupReservations={setGroupReservations}
                                        reservationId={resId}
                                    />
                                </li>
                            );
                        }
                        return null;
                    })}
                </ul>
            ) : null}
        </div>
    );
}

export default GroupReservations;
