import React from 'react';
import PageTitle from '../../components/pageTitle';
import { useBranch } from 'baobab-react/hooks';
import rest from '../../data/restWrapper';
import baobab from '../../data/state/index';

const FeatureFlagsList = (props) => {
    const { featureFlag, userInfo } = useBranch({
        featureFlag: ['model', 'FeatureFlag'],
        userInfo: ['authTokenInfo', 'user']
    });

    if(userInfo?.role !=="admin"){
        return "Access denied"
    }

    return (
        <div className="container-fluid">
            <PageTitle />

            <div className="row justify-content-center">
                <div className="d-flex col-12">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary mr-2"
                    >
                        {window.translate('GO BACK')}
                    </button>
                </div>
            </div>

            <ul className="list-group m-3">
                {featureFlag
                    ? featureFlag.map((f, key) => {
                          const checkboxIcon = f.isEnabled ? (
                              <i className="fa fa-2x fa-check-square-o pull-right" />
                          ) : (
                              <i className="fa fa-2x fa-square-o pull-right " />
                          );

                          return (
                              <li
                                  onClick={async () => {
                                      const fflag = { ...f };
                                      fflag.isEnabled = fflag.isEnabled ? false : true;
                                      try {
                                          await rest('/FeatureFlag', 'POST', fflag);
                                          let featureFlags = [];
                                          featureFlag.forEach((flag) => {
                                              if (f.name === flag.name) {
                                                  let ff = { ...flag };
                                                  ff.isEnabled = fflag.isEnabled;
                                                  featureFlags.push(ff);
                                              } else {
                                                  featureFlags.push(flag);
                                              }
                                          });
                                          baobab.root.select('model', 'FeatureFlag').set(featureFlags);
                                      } catch (error) {
                                          console.log('error', error);
                                      }
                                  }}
                                  className={'px-1 py-2 row shadow-sm pointer ' + (key % 2 === 0 ? 'bg-white ' : '')}
                                  key={key}
                              >
                                  <div className="col-4">{f.name}</div>
                                  <div className="col-4">{f.description}</div>
                                  <div className="col-2">{f.version}</div>
                                  <div className="col-2">{checkboxIcon}</div>
                              </li>
                          );
                      })
                    : null}
            </ul>
        </div>
    );
};

export default FeatureFlagsList;