import React, { useState, useEffect, createRef } from 'react';
import { branch } from 'baobab-react/higher-order';
import rest from '../../data/restWrapper';
import _ from 'lodash';
import PageTitle from '../../components/pageTitle';
import FormHeader from '../../components/formHeader';
import CondoUserRoomSelection from './condoUserRoomsSelect';
import { toast } from 'react-toastify';

const emptyFormTemplate = {
    uuid: null,
    tags: null,
    enabled: true,
    verified: false,
    email: null,
    role: 'receptionist',
    surname: '',
    name: '',
    cardNumber: null,
    level: null,
    oib: null,
    customerId: null,
    condoRoomInfoIds: null,
    condoUserPersonalDaysPerYear: null,
};

const RoleName = {
    admin: 'Admin',
    condoUser: 'Condo user',
    documentScanner: 'Document scanner',
    gdprOfficer: 'GDPR officer',
    hwAdmin: 'HW Admin',
    managerFull: 'Full manager',
    managerBussiness: 'Bussiness manager',
    managerHousehold: 'Household manager',
    managerSecurity: 'Security manager',
    receptionist: 'Receptionist',
    maid: 'Maid',
    janitor: 'Janitor',
    security: 'Security',
    reporting: 'Reporting',
    sos: 'SOS',
};

const UserForm = (props) => {
    const { roles } = props;
    const [data, setData] = useState(_.clone(emptyFormTemplate));
    const _email = createRef();

    useEffect(() => {
        const loadData = async () => {
            if (props.match.params.uuid) {
                try {
                    const userInfo = await rest('/getUser/' + props.match.params.uuid);
                    setUserData(userInfo);
                } catch (err) {
                    console.warn(err);
                }
            } else {
                setData(_.clone(emptyFormTemplate));
            }
            checkValidationInputs(data);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.match.params.uuid]);

    const setUserData = (user) => {
        if (user) {
            const _data = { ...emptyFormTemplate };
            for (const val in _data) {
                _data[val] = user[val];
            }
            setData(_data);
        }
    };

    const handleInputChange = (e) => {
        const _data = { ...data };
        _data[e.target.name] = e.target.value;
        setData(_data);
        if (e.target.name === 'email') {
            checkValidationInputs(_data);
        }
    };

    const handleCardNumber = (e) => {
        const _data = { ...data };
        if (_.isNumber(Number(e.target.value))) {
            _data[e.target.name] = e.target.value ? Number(e.target.value) : null;
        } else {
            _data[e.target.name] = null;
        }
        setData(_data);
    };

    const checkValidationInputs = (objToValidate) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);
    };

    const markInvalidInputRefs = (validationResults) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    };

    const getValidationInputs = (data) => {
        let invalidInputs = [];
        let validInputs = [];
        // this.clearValidationRefs();
        if (!data.email || data.email === '') {
            invalidInputs.push(_email);
        } else if (data.email !== null && data.email.trim() !== '') {
            /* eslint-disable-next-line */
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(data.email).toLowerCase())) {
                invalidInputs.push(_email);
            } else {
                validInputs.push(_email);
            }
        } else {
            validInputs.push(_email);
        }
        return { invalidInputs, validInputs };
    };

    const saveFormData = async (e) => {
        try {
            const userInfo = { ...data };
            const isEditForm = props.location.pathname.lastIndexOf('/new') === -1;
            let validationResults = getValidationInputs(userInfo);
            markInvalidInputRefs(validationResults);

            if (validationResults.invalidInputs.length === 0) {
                if (isEditForm) {
                    //updating the user
                    userInfo.uuid = props.match.params.uuid;
                    await rest('/updateUserProfile', 'PUT', { uuid: userInfo.uuid, formData: userInfo });
                    props.history.push('/users');
                } else {
                    //register new user
                    const newUser = await rest('/createUser', 'POST', {
                        email: userInfo.email,
                        password: 'password',
                        role: userInfo.role,
                    });
                    toast(window.translate('User successfully added!'), {
                        position: toast.POSITION.BOTTOM_RIGHT,
                        type: toast.TYPE.SUCCESS,
                    });
                    userInfo.uuid = newUser.uuid;
                    await rest('/updateUserProfile', 'PUT', { uuid: userInfo.uuid, formData: userInfo });
                    props.history.push('/users');
                }
                setData(_.clone(emptyFormTemplate));
                if (props.callback) {
                    props.callback();
                }
            } else {
                toast(window.translate('Email is not valid!'), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.ERROR,
                });
            }
        } catch (err) {
            console.warn(err);
        }
    };

    const cancelHandler = (e) => {
        setData(_.clone(emptyFormTemplate));
        props.history.goBack();
    };

    const handleLevel = (e) => {
        const _data = { ...data };
        _data['level'] = Number(e.target.value);
        setData(_data);
    };

    const levelToDescription = {
        1: 'Rookie',
        2: 'Intermediate',
        3: 'Experienced',
    };
    const isEditForm = props.location.pathname.lastIndexOf('/new') === -1;
    const unableToDeactivateUSer =
        props.loggedUser.role === 'admin' && props.user && props.user.uuid === props.loggedUser.uuid;

    const filteredRoles = roles.filter((r) => r.key !== 'default');

    return (
        <div className="container-fluid">
            <div className="container bg-white">
                <PageTitle />

                <div className="row justify-content-center ">
                    <div className="col-md-8  pl-0 pr-0 ">
                        <FormHeader>
                            {props.location.pathname.lastIndexOf('/new') !== -1
                                ? window.translate('Add new user')
                                : window.translate('Edit user info')}
                        </FormHeader>
                    </div>
                </div>

                <div className="row justify-content-center ">
                    <div className="col-md-10">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            className="pt-4  pr-2 pl-2  text-md-right"
                        >
                            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {window.translate('Email')}
                                </label>
                                <div className="col-md-8">
                                    <input
                                        ref={_email}
                                        disabled={isEditForm}
                                        autoComplete="off"
                                        name="email"
                                        placeholder={window.translate('Email')}
                                        type="text"
                                        className="form-control"
                                        value={data.email ? data.email : ''}
                                        onChange={handleInputChange.bind(this)}
                                    />
                                </div>
                            </div>

                            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {window.translate('First name')}
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        name="name"
                                        placeholder={window.translate('First name')}
                                        type="text"
                                        className="form-control"
                                        value={data.name ? data.name : ''}
                                        onChange={handleInputChange.bind(this)}
                                    />
                                </div>
                            </div>

                            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {window.translate('Last name')}
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        name="surname"
                                        placeholder={window.translate('Last name')}
                                        type="text"
                                        className="form-control"
                                        value={data.surname ? data.surname : ''}
                                        onChange={handleInputChange.bind(this)}
                                    />
                                </div>
                            </div>

                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {window.translate('Card number')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        placeholder={window.translate('Card number')}
                                        name="cardNumber"
                                        value={data.cardNumber ? data.cardNumber : ''}
                                        onChange={handleCardNumber.bind(this)}
                                        type="number"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            {data.role === 'receptionist' ? (
                                <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                                    <label className="col-md-4 col-form-label" htmlFor="">
                                        OIB
                                    </label>
                                    <div className="col-md-8">
                                        <input
                                            autoComplete="off"
                                            name="oib"
                                            placeholder={'OIB'}
                                            type="text"
                                            className="form-control"
                                            value={data.oib ? data.oib : ''}
                                            onChange={handleInputChange.bind(this)}
                                        />
                                    </div>
                                </div>
                            ) : null}

                            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {window.translate('Select role')}
                                </label>
                                <div className="col-md-8">
                                    <select
                                        value={data.role}
                                        onChange={handleInputChange.bind(this)}
                                        name="role"
                                        className="form-control form-control-sm"
                                    >
                                        <option value="user">{window.translate('No privileges')}</option>
                                        {filteredRoles.map((role) => {
                                            return (
                                                <option value={role.key} key={role.key}>
                                                    {RoleName[role.key]
                                                        ? window.translate(RoleName[role.key])
                                                        : role.key}
                                                </option>
                                            );
                                        })}
                                    </select>
                                </div>
                            </div>

                            {data.role === 'maid' ? (
                                <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-md-4 col-form-label" htmlFor="">
                                        {window.translate('Experience')}:
                                    </label>
                                    <div className="col-md-8">
                                        {[1, 2, 3].map((lvl) => {
                                            return (
                                                <div className="form-check text-left" key={lvl}>
                                                    <input
                                                        className="form-check-input"
                                                        type="radio"
                                                        name={lvl}
                                                        id={lvl}
                                                        value={lvl}
                                                        checked={data.level && data.level === lvl ? true : false}
                                                        onChange={handleLevel.bind(this)}
                                                    />
                                                    <label className="form-check-label" htmlFor={lvl}>
                                                        {levelToDescription[lvl]}
                                                    </label>
                                                </div>
                                            );
                                        })}
                                    </div>
                                </div>
                            ) : null}

                            {data.role === 'condoUser' ? (
                                <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-md-4 col-form-label" htmlFor="">
                                        {window.translate('CONDO rooms')}:
                                    </label>
                                    <div className="col-md-8 text-left">
                                        <CondoUserRoomSelection
                                            onCondoRoomSelectionUpdated={(condoRoomInfoIds) => {
                                                const _data = { ...data };
                                                _data.condoRoomInfoIds = condoRoomInfoIds;
                                                setData(_data);
                                            }}
                                            user={data}
                                        />
                                    </div>
                                </div>
                            ) : null}

                            {data.role === 'condoUser' ? (
                                <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-md-4 col-form-label" htmlFor="">
                                        {window.translate('Personal days for CONDO user per year')}:
                                    </label>
                                    <div className="col-md-8 ">
                                        <input
                                            autoComplete="off"
                                            placeholder={window.translate('Personal days for CONDO user')}
                                            name="condoUserPersonalDaysPerYear"
                                            value={
                                                data.condoUserPersonalDaysPerYear
                                                    ? data.condoUserPersonalDaysPerYear
                                                    : ''
                                            }
                                            onChange={handleInputChange.bind(this)}
                                            type="number"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            ) : null}

                            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {window.translate('Activate user')}
                                </label>
                                <div className="col-md-8 text-left">
                                    <input
                                        disabled={unableToDeactivateUSer}
                                        onChange={() => {
                                            let _data = { ...data };
                                            _data['enabled'] = !_data.enabled;
                                            setData(_data);
                                        }}
                                        checked={data.enabled}
                                        name="enabled"
                                        type="checkbox"
                                    />
                                    &ensp;<small>{window.translate('Activate user')}</small>
                                </div>
                            </div>

                            {props.user ? null : (
                                <div className="alert alert-warning text-center" role="alert">
                                    {window.translate(
                                        'After user is created, please set the password using the button'
                                    )}
                                    <i className="fa fa-key ml-2" />
                                </div>
                            )}
                        </form>
                        <div className="text-center mb-3 ">
                            <button onClick={saveFormData.bind(this)} className="btn btn-outline-primary">
                                {window.translate('Save')}
                            </button>
                            <button onClick={cancelHandler.bind(this)} className="btn btn-outline-secondary ml-2">
                                {window.translate('Cancel')}
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        //do not use baobab here...
        loggedUser: ['authTokenInfo', 'user'],
        roles: ['model', 'Role'],
    },
    UserForm
);
