import React from 'react';
import { setModalContent, closeModal } from '@components/modal';
import { ReservationStatus } from '@common/modelDefinition';
import { translate } from '@data/translations';
import { Reservation } from '../resController';
import { condoStatusCheck } from '../utils';
import { ReservationController } from '../resController';
import { reservationEnums } from '@data/reservationStatusEnums';

interface ConfirmModalProps {
    type: ReservationStatusActions;
    confirmActionHandler: (action: ReservationStatusActions) => void;
    continueToCheckout?: (reservation: Reservation, type: ReservationStatusActions) => void;
    reservation: Reservation;
    text?: any;
}

enum ReservationStatusActions {
    confirm = 'confirm',
    checkin = 'checkin',
    cancel = 'cancel',
    checkout = 'checkout',
    earlyBird = 'earlyBird',
    invoice = 'invoice',
}

interface StatusButtonsProps {
    reservation: Reservation;
    statusButtonActionHandler: (action: string) => void;
    history: any;
    isPending: boolean;
}

function ConfirmModal(props: ConfirmModalProps) {
    const { reservation } = props;
    const { isCondoReservation } = condoStatusCheck(reservation);

    if (props.type === 'checkout' && !isCondoReservation) {
        return (
            <div className="text-center ">
                <div>{props.text}</div>
                <div className="mt-2 mb-3">
                    <button
                        className="btn btn-primary mr-2"
                        onClick={() => {
                            if (props.continueToCheckout) {
                                props.continueToCheckout(props.reservation, props.type);
                            }
                        }}
                    >
                        {translate('Proceed')}
                    </button>
                    <button
                        className="btn btn-secondary"
                        onClick={() => {
                            closeModal();
                        }}
                    >
                        {translate('Cancel')}
                    </button>
                </div>
            </div>
        );
    } else {
        return (
            <div className="text-center">
                <div>{props.text}</div>
                <div className="mt-2 mb-3">
                    <button
                        className="btn btn-primary mr-2"
                        onClick={() => {
                            props.confirmActionHandler(props.type);
                        }}
                    >
                        {translate('Yes')}
                    </button>
                    <button className="btn btn-secondary" onClick={closeModal}>
                        {translate('No')}
                    </button>
                </div>
            </div>
        );
    }
}

function getButtons(reservation: Reservation) {
    const currStatus: ReservationStatus = reservation.statusEnum;
    const buttons = [];
    if (currStatus === ReservationStatus.earlyBird) {
        buttons.push(ReservationStatusActions.confirm);
        buttons.push(ReservationStatusActions.checkin);
        buttons.push(ReservationStatusActions.cancel);
    } else if (currStatus === ReservationStatus.confirmed) {
        buttons.push(ReservationStatusActions.checkin);
        buttons.push(ReservationStatusActions.cancel);
    } else if (currStatus === ReservationStatus.canceled) {
        if (new Date().getTime() > reservation.checkOutTimestamp) {
        } else {
            buttons.push(ReservationStatusActions.confirm);
        }
    } else if (currStatus === ReservationStatus.checkedIn) {
        buttons.push(ReservationStatusActions.checkout);
    } else if (currStatus === ReservationStatus.autoBlocked) {
        if (new Date().getTime() > reservation.checkOutTimestamp) {
        } else {
            buttons.push(ReservationStatusActions.confirm);
            buttons.push(ReservationStatusActions.checkin);
            buttons.push(ReservationStatusActions.cancel);
        }
    }
    return buttons;
}

function StatusButtons(props: StatusButtonsProps) {
    const { reservation, history, isPending } = props;
    const ctrl = new ReservationController()

    if (reservation.accommodationPriceListId === null && !reservation.openPrice) {
        if(reservation.statusEnum !== reservationEnums.canceled){
            return null;
        }
    }
    const { isCondoReservation } = condoStatusCheck(reservation);

    const confirmActionHandler = async (action: ReservationStatusActions) => {
        if (props.statusButtonActionHandler) {
            try {
                await props.statusButtonActionHandler(action);
                if(action==="cancel"){
                    ctrl.save(reservation.groupUuid ? true : false)
                }
            } catch (err) {
                console.warn(err);
            }
            closeModal();
        }
    };

    const onClickHandler = (type: ReservationStatusActions) => {
        switch (type) {
            case ReservationStatusActions.confirm:
                setModalContent(
                    <ConfirmModal
                        type={type}
                        text={translate('Do you want to move this reservation to CONFIRMED state? ')}
                        confirmActionHandler={confirmActionHandler}
                        reservation={reservation}
                    />,
                    translate('Confirm reservation'),
                    false,
                    'modal-md'
                );

                break;
            case ReservationStatusActions.checkin:
                setModalContent(
                    <ConfirmModal
                        type={type}
                        text={
                            <span>
                                {translate('Are you sure you want change status to CHECK IN')}? <br />{' '}
                                <small>{translate('NOTE: Current time will be applyed as check in time')}!</small>
                            </span>
                        }
                        confirmActionHandler={confirmActionHandler}
                        reservation={reservation}
                    />,
                    translate('Confirm checkin'),
                    false,
                    'modal-md'
                );
                break;
            case ReservationStatusActions.cancel: //
                setModalContent(
                    <ConfirmModal
                        type={type}
                        text={translate('Are you sure you want to cancel this reservation?')}
                        confirmActionHandler={confirmActionHandler}
                        reservation={reservation}
                    />,
                    translate('Confirm cancel reservation'),
                    false,
                    'modal-md'
                );
                break;
            case ReservationStatusActions.checkout:
                if (!isCondoReservation) {
                    if (reservation.groupUuid) {
                        history.push(`/issueGroupInvoice/${reservation.groupUuid}?reservationId=${reservation.id}`);
                    } else {
                        history.push(`/checkoutAndInvoice/${reservation.id}`);
                    }
                } else {
                    setModalContent(
                        <ConfirmModal
                            type={type}
                            text={translate('Are you sure you want to close reservation?')}
                            confirmActionHandler={confirmActionHandler}
                            reservation={reservation}
                        />,
                        translate('Confirm cancel reservation'),
                        false,
                        'modal-md'
                    );
                }

                break;
            case ReservationStatusActions.earlyBird:
                //implement if needed
                break;
            default:
                break;
        }
    };

    const buttons = getButtons(reservation);
    return (
        <div>
            {buttons.includes(ReservationStatusActions.confirm) && (
                <button
                    onClick={() => {
                        onClickHandler(ReservationStatusActions.confirm);
                    }}
                    className="btn btn-sm btn-primary mr-2"
                >
                    {translate('CONFIRM')}
                </button>
            )}

            {buttons.includes(ReservationStatusActions.checkin) && (
                <button
                    onClick={() => {
                        onClickHandler(ReservationStatusActions.checkin);
                    }}
                    className="btn btn-sm btn-success mr-2"
                >
                    {translate('CHECK IN')}
                </button>
            )}

            {buttons.includes(ReservationStatusActions.cancel) && (
                <button
                   disabled={reservation.genuineInvoiceIssued ? true:false}
                    onClick={() => {
                        onClickHandler(ReservationStatusActions.cancel);
                    }}
                    className="btn btn-sm btn-warning mr-2"
                >
                    {translate('CANCEL')}
                </button>
            )}

            {buttons.includes(ReservationStatusActions.checkout) && (
                <button
                    disabled={isPending}
                    onClick={() => {
                        onClickHandler(ReservationStatusActions.checkout);
                    }}
                    className="btn btn-sm btn-secondary mr-2"
                >
                    {translate('CHECKOUT')}
                </button>
            )}
        </div>
    );
}

export default StatusButtons;
