import React from 'react';
import { deleteModelData } from '../../../data/actions/modelActions';
import { setModalContent } from '@components/modal';
import Delete from '../../../components/confirmModal';
import PageTitle from '../../../components/pageTitle';
import { branch } from 'baobab-react/higher-order';
import Form from './form';
import baobab from '../../../data/state';
import { toast } from 'react-toastify';

const Currencies = (props) => {
    const currencies = props.currency;

    const deleteItem = async (model, itemId) => {
        try {
            await deleteModelData(model, itemId);
            toast(window.translate("Currency deleted!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
            const _currencies = currencies.filter(c=>c.id!==itemId)
            baobab.root.select(['model', 'Currency']).set(_currencies);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = (model, itemId) => {
        setModalContent(
            <Delete actionForYes={deleteItem.bind(this, model, itemId)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const showFormModal = (currencyId) => {
        setModalContent(<Form currencyId={currencyId} />, window.translate('New currency'), false, 'modal-lg');
    };

    return (
        <div className="">
            <PageTitle />
            <div className="row">
                <div className="d-flex col-12">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {window.translate('GO BACK')}
                    </button>
                    <button onClick={showFormModal.bind(this, null)} className="btn btn-sm btn-primary ml-2">
                        {window.translate('ADD NEW')}
                    </button>
                    <button
                        onClick={() => {
                            props.history.push('/priceLists/currencies/exchangeRates');
                        }}
                        className="btn btn-sm btn-primary ml-2"
                    >
                        {window.translate('Exchange rate')}
                    </button>
                </div>
            </div>

            <div className="list-group mt-3 mx-3">
                <small>
                    <div className="px-1 py-2 row shadow-sm bg-white">
                        <div className="col-4">{window.translate('Name')}</div>
                        <div className="col-4">{window.translate('Short name')}</div>
                        <div className="col-4"></div>
                    </div>
                </small>
            </div>

            <div className="row">
                <div className="col-12">
                    <ul className="list-group mt-2 mx-3">
                        {currencies.map((cu, key) => {
                            return (
                                <li key={cu.id + key} className={'px-2 py-2 row shadow-sm bg-white my-1 rounded'}>
                                    <div className="col-md-4">{cu.name}</div>
                                    <div className="col-md-4">{cu.shortName}</div>
                                    <div className="col-md-4">
                                        <button
                                            onClick={showFormModal.bind(this, cu.id)}
                                            className="btn btn-sm btn-outline-dark pull-right ml-1"
                                        >
                                            <i className="fa fa-edit" aria-hidden="true"></i>
                                        </button>
                                        <button
                                            onClick={showDeleteModal.bind(this, 'Currency', cu.id)}
                                            className="btn btn-sm btn-outline-danger pull-right ml-1"
                                        >
                                            <i className="fa fa-trash"></i>
                                        </button>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        currency: ['model', 'Currency'],
    },
    Currencies
);
