import React, { Component } from 'react';
import moment from 'moment';
import rest from '../../data/restWrapper';
import _ from 'lodash';

class RoomSensorStrip extends Component {
    constructor(props) {
        super();
        this.state = {
            roomTimestamp: props.roomSettings.roomTimestamp,
        };
    }
    async syncClock() {
        try {
            let remoteDeviceId = this.props.match.params.remoteDeviceId;
            let newRoomTime = await rest('/src/setCurrentDateTime/' + remoteDeviceId, 'POST');
            this.setState({ roomTimestamp: newRoomTime.utcTimestamp });
        } catch (err) {
            console.warn(err); //TODO - show error in UI
        }
    }
    render() {
        let tempSensor0 = this.props.roomSettings.wireSensor
            ? this.props.roomSettings.wireSensor.temp0
            : { connected: false };
        let tempSensor1 = this.props.roomSettings.wireSensor
            ? this.props.roomSettings.wireSensor.temp1
            : { connected: false };

        return (
            <div style={{ top: 0, right: 0, left: 0, position: 'relative' }}>
                <div className="card-header bg-white text-center ">
                    <div className="row ">
                        <div className="col-md-3 text-center">
                            <h3>
                                {window.translate('Room clock')}{' '}
                                <i onClick={this.syncClock.bind(this)} className="fa fa-refresh pointer"></i>
                            </h3>
                            <h3>{moment(this.state.roomTimestamp).format('LLL')}</h3>
                        </div>
                        <div className="col-md-3 text-center">
                            <h3>{window.translate('Wire sensor 1')}</h3>
                            <h3>
                                {tempSensor0.connected
                                    ? tempSensor0.temperature.toFixed(2) + ' °C'
                                    : window.translate('NOT CONNECTED')}
                            </h3>
                        </div>
                        <div className="col-md-3 text-center">
                            <h3>{window.translate('Wire sensor 2')}</h3>
                            <h3>
                                {tempSensor1.connected
                                    ? tempSensor1.temperature.toFixed(2) + ' °C'
                                    : window.translate('NOT CONNECTED')}
                            </h3>
                        </div>
                        <div className="col-md-3 text-center">
                            <h3>SRC EXTENDER</h3>
                            <h3>
                                {_.includes(this.props.roomSettings.availableDevices, 'i2cModbusExtension')
                                    ? window.translate('READY')
                                    : window.translate('NOT AVAILABLE')}
                            </h3>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default RoomSensorStrip;
