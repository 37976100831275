import React from 'react';

const SearchField = (props) => {
    const { className = 'form-control m-auto align-self-center', style = {} } = props;

    const onSearch = async (e) => {
        if (e.key === 'Enter') {
            props.history.push('/search?' + e.target.value);
        }
    };

    const searchTerm = props.history ? props.history.location.search.replace('?', '') : ''

    return (
        <input
            onKeyPress={onSearch.bind(this)}
            onChange={(e) => {
                if (props.onChangeHandler) {
                    props.onChangeHandler(e);
                }
            }}
            placeholder={window.translate('Search for a customer or company name')}
            type="text"
            className={className}
            style={style}
            defaultValue={searchTerm}
        />
    );
};

export default SearchField;