import React from 'react';
import { handleChange, saveFormData } from '../settingsActions';

const FiscalCertificate = (settings) => {
    const { fiscalCertificate } = settings;

    const countryOptions = ['CRO'].map((c) => {
        return (
            <option value={c} key={c}>
                {c}
            </option>
        );
    });
    countryOptions.unshift(
        <option key="none" value="alpha-3">
            {' '}
            -{' '}
        </option>
    );

    return (
        <div className=" bg-white p-3 ">
            <b>{window.translate('FISCAL CERTIFICATE')}</b>
            <hr />

            <div className="row mt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="region">{window.translate('Region')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="region"
                        className="form-control"
                        value={fiscalCertificate?.region}
                        onChange={handleChange.bind(this, 'fiscalCertificate', 'object')}
                    >
                        {countryOptions}
                    </select>
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    <label htmlFor="issuerOib">{window.translate('OIB')}:</label>
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="issuerOib"
                        value={fiscalCertificate?.issuerOib}
                        onChange={handleChange.bind(this, 'fiscalCertificate', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    <label htmlFor="certificateHash">{window.translate('Certificate Hash')}:</label>
                </div>
                <div className="col-md-6">
                    <textarea
                        autoComplete="off"
                        rows="20"
                        name="certificateHash"
                        value={fiscalCertificate?.certificateHash}
                        onChange={handleChange.bind(this, 'fiscalCertificate', 'object')}
                        type="textarea"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    <label htmlFor="key">{window.translate('Key')}:</label>
                </div>
                <div className="col-md-6">
                    <textarea
                        autoComplete="off"
                        rows="20"
                        name="key"
                        value={fiscalCertificate?.key}
                        onChange={handleChange.bind(this, 'fiscalCertificate', 'object')}
                        type="textarea"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    <label htmlFor="issuerName">{window.translate('Issuer Name')}:</label>
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="issuerName"
                        value={fiscalCertificate?.issuerName}
                        onChange={handleChange.bind(this, 'fiscalCertificate', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    <label htmlFor="serialNumber">{window.translate('Serial Number')}:</label>
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="serialNumber"
                        value={fiscalCertificate?.serialNumber}
                        onChange={handleChange.bind(this, 'fiscalCertificate', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row  mt-2">
                <div className="col-md-4">
                    <label htmlFor="sequenceMarker">{window.translate('Sequence marker')}:</label>
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="sequenceMarker"
                        value={fiscalCertificate?.sequenceMarker}
                        onChange={handleChange.bind(this, 'fiscalCertificate', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="text-center p-5">
                <div
                    onClick={() => saveFormData('fiscalCertificate', fiscalCertificate)}
                    className="btn btn-outline-primary pointer"
                >
                    {window.translate('Save')}
                </div>
            </div>
        </div>
    );
};

export default FiscalCertificate;
