import React from 'react';
import moment from 'moment/min/moment-with-locales';

import {
	reservationEnums,
	reservationStatusEnumsDesc,
	reservationStatusColors,
} from '../../data/reservationStatusEnums';

const CardCodeResults = (props) => {
	const { reservationCardCode, roomInfoById, history } = props;

	if (reservationCardCode.length === 0) {
		return null;
	}

	return (
		<div>
			<h5>{window.translate('Card code search results')}</h5>

			<ul className="list-group mt-3 mx-3">
				<li className="px-1 py-1 row text-white small navbar-dark navbar-gradient">
					<div className="col-3">{window.translate('Guests')}</div>
					<div className="col-2">{window.translate('Check in')}</div>
					<div className="col-2">{window.translate('Check out')}</div>
					<div className="col-2">{window.translate('Room')}</div>
					<div className="col-2">{window.translate('Status')}</div>
				</li>
				{reservationCardCode.map((r, index) => {
					let guest =
						r.firstName !== null ? (
							<div>{`${r.firstName} ${r.lastName}, (${r.documentId})`}</div>
						) : (
							<div>{`${r.name}, (${r.idNumber})`}</div>
						);
					return (
						<li
							onClick={() => {
								r.statusEnum === reservationEnums.closed
									? history.push(`/reservationDetails/${r.id}`)
									: history.push(`/reservations/${r.id}/edit`);
							}}
							className={'pointer px-1 py-1 row ' + (index % 2 === 0 ? 'bg-white' : '')}
							key={index}
						>
							<div className="col-3">{guest}</div>

							<div className="col-2 elipsis">{moment(r.checkInTimestamp).format('DD.MM.YYYY')}</div>
							<div className="col-2 elipsis">{moment(r.checkOutTimestamp).format('DD.MM.YYYY')}</div>
							<div className="col-2 elipsis">{roomInfoById[r.roomInfoId].name}</div>
							<div className={`col-2 elipsis ${reservationStatusColors[r.statusEnum]}`}>
								{reservationStatusEnumsDesc[r.statusEnum]}
							</div>
						</li>
					);
				})}
			</ul>
		</div>
	);
};

export default CardCodeResults;