import React from 'react';
import { setModalContent } from '@components/modal';
import Delete from '@components/confirmModal';
import PageTitle from '@components/pageTitle';
import { branch } from 'baobab-react/higher-order';
import Form from './form';
import numbro from 'numbro';
import baobab from '@data/state';
import { toast } from 'react-toastify';
import rest from '@data/restWrapper';


function Tax(props) {
    const { defaultCurrency, taxes } = props;

    const deleteItem = async (tax) => {
        try {
            const deletedTax = { ...tax }
            deletedTax.isActive = false
            await rest('/Tax/' + deletedTax.id, 'PUT', deletedTax);
            toast(window.translate("Tax deleted!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
            const taxes = props.taxes.filter(t => t.id !== deletedTax.id)
            baobab.root.select(['model', 'Tax']).set(taxes);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = (tax) => {
        setModalContent(
            <Delete actionForYes={deleteItem.bind(this, tax)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const showFormModal = (taxId) => {
        setModalContent(<Form taxId={taxId} />, window.translate('New Tax'), false, 'modal-lg');
    };

    const setGeneralTax = async (tax) => {
        const alreadyGeneralTax = taxes.find(t => t.isGeneralTax)
        if (alreadyGeneralTax && alreadyGeneralTax.id !== tax.id) {
            const newTax = { ...alreadyGeneralTax }
            newTax.isGeneralTax = false
            await rest('/Tax/' + newTax.id, 'PUT', newTax);
        }
        const targetTax = { ...tax }
        if (targetTax.isGeneralTax) {
            targetTax.isGeneralTax = false
        } else {
            targetTax.isGeneralTax = true
        }
        await rest('/Tax/' + targetTax.id, 'PUT', targetTax);
    }

    const activeTaxes=taxes.filter(t=>t.isActive)

    return (
        <div className="">
            <PageTitle />
            <div className="row">
                <div className="d-flex col-12">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {window.translate('GO BACK')}
                    </button>
                    <button onClick={showFormModal.bind(this, null)} className="btn btn-sm btn-primary ml-2">
                        {window.translate('ADD NEW')}
                    </button>
                </div>
            </div>

            <div className="list-group mt-3 mx-3">
                <small>
                    <div className="px-1 py-2 row shadow-sm bg-white">
                        <div className="col-4">{window.translate('Name')}</div>
                        <div className="col-3">{window.translate('Amount')}</div>
                        <div className="col-2">{window.translate('Accommodation tax rate')}</div>
                        <div className="col-3"></div>
                    </div>
                </small>
            </div>

            <ul className="list-group mt-2 mx-3">
                {activeTaxes.map((t, key) => {
                    const amount = t.fixedAmount
                        ? `${numbro(Number(t.fixedAmount)).format({ mantissa: 2 })} ${defaultCurrency}`
                        : `${numbro(Number(t.percentageAmount)).format({ mantissa: 2 })} %`;
                    return (
                        <li key={key} className={'px-2 py-2 row shadow-sm bg-white my-1 rounded'}>
                            <div className="col-4 elipsis">{t.name}</div>
                            <div className="col-3 elipsis">{amount}</div>
                            <div className="col-2">
                                <i
                                    onClick={async () => {
                                        if(!t.fixedAmount){
                                            await setGeneralTax(t)
                                        }
                                    }}
                                    name="isGeneralTax"
                                    value={t.isGeneralTax ? true : false}
                                    className={
                                        t.isGeneralTax ? 'fa fa-check-square fa-lg ml-2' : 'fa fa-square fa-lg ml-2'
                                    }
                                ></i>
                            </div>
                            <div className="col-3">
                                <button
                                    onClick={showFormModal.bind(this, t.id)}
                                    className="btn btn-sm btn-outline-dark pull-right ml-1"
                                >
                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                </button>

                                <button
                                    onClick={showDeleteModal.bind(this, t)}
                                    className="btn btn-sm btn-outline-danger pull-right ml-1"
                                >
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default branch(
    {
        defaultCurrency: ['defaultCurrency'],
        taxes: ['model', 'Tax'],
    },
    Tax
);
