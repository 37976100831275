import React, { useState, useEffect } from 'react';
import rest from '../../data/restWrapper';
import { branch } from 'baobab-react/higher-order';
import pubsub from '../../data/pubsub';
import countryCodes from '@data/countryCodesIndexed';

const customersAfterScanTemp = {
    data: [],
    typeOfScan: 0,
    visaInfo: null,
    customer: {},
};

const DocScannSecondStep = ({ reservation, nextStepCallback, setDocScannerUuid, docScan }) => {
    const [scannedDoc, setScannedDoc] = useState([]); //trenutno skenirani dokument
    const [guestToScan, setGuestToScan] = useState(null);
    const [scannedData, setScannedData] = useState(null); //svi skenirani dokumenti
    const [holder, setHolder] = useState(false);
    const [resGuestRef, setResGuestRef] = useState(false);
    const [guest, setGuest] = useState(false);
    const [date, setDate] = useState(null);
    const [numOfGuests, setNumOfGuests] = useState(0);
    const [acceptGuestIfNoMatch, setAcceptGuestIfNoMatch] = useState(false);
    const [customersAfterScan, setCustomersAfterScan] = useState({ ...customersAfterScanTemp });
    const [visaInfo, setVisaInfo] = useState(null);
    let otherGuestsNumArr = [];

    useEffect(() => {
        const loadData = async () => {};

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    useEffect(() => {
        const loadData = async () => {};

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [customersAfterScan]);

    useEffect(() => {
        const loadDocScann = async () => {
            if (date) {
                const scans = docScan.filter((d) => new Date(d._DateOfScan).getTime() > new Date(date).getTime());
                const scan = scans[scans.length - 1];
                setScannedData(scan);
                if (scan.docScannerUuid) {
                    setDocScannerUuid(scan.docScannerUuid);
                } else {
                    pubsub.emit('toastMessage', 'No valid scans', 'error');
                }
                setAcceptGuestIfNoMatch(false);
            }
        };

        loadDocScann().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [docScan.length]);

    const scanDocument = async (_guestId) => {
        //ovo ce ici samo na događaj skeniranja, ovdje se ručno poziva
        setDate(new Date());
        // await rest('/scanDocumentData?token=' + '6ff1f7cd-6bbf-4267-9b6a-08c84c960479', 'POST');
    };

    const checkUser = async () => {
        try {
            setDate(null);
            const dataAfterScan = await rest('/getOrCreateCustomerFromDocumentScan/' + scannedData.id, 'POST', {
                reservationId: reservation.id,
                customerId: null,
            });
            setVisaInfo(dataAfterScan.visaInfo);
            if (dataAfterScan.data.length === 1 && dataAfterScan.typeOfScan === 1) {
                //nadjen gost po documentId-u
                acceptGuest(dataAfterScan.data[0], dataAfterScan.visaInfo);
            } else if (dataAfterScan.data.length > 0 && dataAfterScan.typeOfScan === 2) {
                //nadjeni gosti po hashu imena
                setCustomersAfterScan(dataAfterScan);
            } else if (dataAfterScan.typeOfScan === 3) {
                //ne postoji ni jedan, kreiraj novog
                acceptGuest(dataAfterScan.customer, dataAfterScan.visaInfo);
            }
            setScannedData(null);
        } catch (error) {
            pubsub.emit('toastMessage', error, 'error');
            console.log('err', error);
        }
    };

    const acceptGuest = async (g, visa = undefined) => {
        const customer = await rest('/createOrUpdateCustomer', 'POST', g);
        let resGuestReference = null;

        if (customer && customer.id) {
            const reservationObj = await rest('/Reservation/' + reservation.id, 'GET');
            const res = { ...reservation };
            if (holder) {
                //zamijeni holdera u rezervaciji i u viza info
                reservationObj.customerId = customer.id;
                await rest('/safeSaveOrUpdateReservationModel', 'POST', reservationObj);
                resGuestReference = res.reservationGuestReferences.find(
                    (r) => r.customerId === customer.id && r.reservationId === reservationObj.id
                );
            } else if (resGuestRef) {
                //zamijeni gosta u viza info
                resGuestReference = res.reservationGuestReferences.find(
                    (r) => r.customerId === resGuestRef.id && r.reservationId === reservationObj.id
                );
            } else if (guest) {
                //dodaj gosta u viza info, guest ne postoji pa se kreira novi resguestref
                const resGuestObj = {
                    reservationId: res.id,
                    customerId: customer.id,
                    documentValidityPeriod: visa ? visa.documentValidityPeriod : null,
                    visaTypeAndNumber: visa ? visa.visaTypeAndNumber : null,
                    visaValidityPeriod: visa ? visa.visaValidityPeriod : null,
                    visaEntryDate: visa ? visa.visaEntryDate : null,
                };
                await rest('/ReservationGuestReference', 'POST', resGuestObj);
            }
            const _scannedDoc = [...scannedDoc];
            _scannedDoc.push(guestToScan);
            setScannedDoc(_scannedDoc);
            setNumOfGuests(numOfGuests + 1);
        }

        let referenceToChange = null;

        if (resGuestReference) {
            resGuestReference.customerId = customer.id;
            referenceToChange = resGuestReference.id;
            if (visa) {
                resGuestReference.documentValidityPeriod = visa.documentValidityPeriod;
                resGuestReference.visaTypeAndNumber = visa.visaTypeAndNumber;
                resGuestReference.visaValidityPeriod = visa.visaValidityPeriod;
                resGuestReference.visaEntryDate = visa.visaEntryDate;
            }
            if (referenceToChange) {
                await rest('/ReservationGuestReference/' + referenceToChange, 'PUT', resGuestReference);
            }
        }

        setGuestToScan(null);
        setHolder(false);
        setGuest(false);
        setResGuestRef(false);
        setCustomersAfterScan({ ...customersAfterScanTemp });
        setScannedData(null);
    };

    const classHolder =
        guestToScan === reservation?.Customer.id
            ? 'border-primary bg-light'
            : scannedDoc.includes(reservation.Customer.id)
            ? 'bg-success border-secondary'
            : 'border-secondary';

    const resGuest = reservation.customers.find((c) => c.id !== reservation.Customer.id && c.id === guestToScan);

    if (reservation.customers.length < reservation.adultsNumber) {
        for (let i = reservation.customers.length; i < reservation.adultsNumber; i++) {
            otherGuestsNumArr.push(i);
        }
    }

    const dataToDisplayForScanDoc = [
        'FirstName',
        'LastName',
        'IDNumber',
        'BirthDate',
        'Gender',
        'LicenseNumber',
        'Address',
        'City',
        'Zip',
        'Country',
    ];

    const dataToDisplayAfterScan = ['firstName', 'lastName', 'documentId', 'dateOfBirth', 'town', 'country'];

    return (
        <div className="bg-white border rounded shadow m-auto col-9">
            <h4 className="mt-5 pt-4 col-12 m-auto text-center">
                {window.translate('Your reservation is ')}
                <b>#{reservation.id}</b>.
            </h4>
            <div className="row">
                <div className="col-12 col-md-6 d-inline-block">
                    <p className="my-5 mx-5">
                        {window.translate('Please select one of the following guests first, then scann his data.')}
                    </p>
                    <p className="mx-5">{window.translate('Reservation holder: ')}</p>
                    <div className="ml-5 mb-3 col-12 d-inline-flex align-items-center text-center">
                        <div
                            className={'mx-2 border rounded shadow-sm col-3 col-md-4 p-2 pointer ' + classHolder}
                            onClick={() => {
                                if (!scannedDoc.includes(reservation.customerId)) {
                                    setGuestToScan(reservation.customerId);
                                    scanDocument(reservation.customerId);
                                    setHolder(reservation.customerId);
                                }
                            }}
                        >
                            <b className="text-uppercase">
                                {reservation.Customer
                                    ? reservation.Customer.firstName + ' ' + reservation.Customer.lastName
                                    : ''}
                            </b>
                        </div>{' '}
                    </div>
                    <br />
                    <br />
                    <p className="mx-5">{window.translate('Other guests: ')}</p>
                    <div className="ml-5 mb-3 col-12 align-items-center text-center">
                        {reservation.customers.map((c, key) => {
                            if (c.id !== reservation.customerId) {
                                return (
                                    <div
                                        key={c.id + key}
                                        className={
                                            'm-2 border rounded shadow-sm col-3 col-md-4 p-2 pointer ' +
                                            (resGuest?.id === c.id
                                                ? 'border-primary bg-light'
                                                : scannedDoc.includes(c.id)
                                                ? 'bg-success border-secondary'
                                                : 'border-secondary')
                                        }
                                        onClick={() => {
                                            if (!scannedDoc.includes(c.id)) {
                                                setGuestToScan(c.id);
                                                scanDocument(c.id);
                                                setResGuestRef(c);
                                            }
                                        }}
                                    >
                                        <b className="text-uppercase">{c.firstName + ' ' + c.lastName}</b>
                                    </div>
                                );
                            }
                            return null;
                        })}
                        {otherGuestsNumArr.map((g) => {
                            return (
                                <div
                                    key={g}
                                    className={
                                        'm-2 border rounded shadow-sm col-3 col-md-4 p-2 pointer ' +
                                        (guestToScan === `Guest ${g + 1}`
                                            ? 'border-primary bg-light'
                                            : scannedDoc.includes(`Guest ${g + 1}`)
                                            ? 'bg-success border-secondary'
                                            : 'border-secondary')
                                    }
                                    onClick={() => {
                                        if (!scannedDoc.includes(`Guest ${g + 1}`)) {
                                            setGuestToScan(`Guest ${g + 1}`);
                                            scanDocument(`Guest ${g + 1}`);
                                            setGuest(true);
                                        }
                                    }}
                                >
                                    <b className="text-uppercase">
                                        {window.translate('Guest ')}
                                        {g + 1}
                                    </b>
                                </div>
                            );
                        })}
                    </div>
                </div>
                <div className="col-12 col-md-6 d-inline-block ">
                    <div className=" mt-5 ml-5 mb-3">
                        {customersAfterScan.data.length > 0
                            ? 'There is a few similar guests. Click on card that match with your data or create new with scanned data if no data match.'
                            : window.translate('Scanned data')}
                    </div>
                    {scannedData && numOfGuests < Number(otherGuestsNumArr.length + reservation.customers.length) ? (
                        <div className="mx-5 mb-3 col-10 align-items-center">
                            {Object.keys(scannedData).map((d, key) => {
                                const dataToDisplay = acceptGuestIfNoMatch
                                    ? dataToDisplayAfterScan
                                    : dataToDisplayForScanDoc;
                                const country =
                                    d === 'country' ? (scannedData[d] ? countryCodes[scannedData[d]] : '') : false;
                                if (dataToDisplay.includes(d)) {
                                    return (
                                        <div
                                            key={key}
                                            className="col-12 p-1 border rounded shadow-sm text-left d-flex align-items-center "
                                        >
                                            <label className="col-3 overflow-hidden ">
                                                <small>{d}:</small>
                                            </label>
                                            <label className="col-9 text-primary overflow-hidden  ">
                                                <b>
                                                    {d === 'BirthDate'
                                                        ? new Date(scannedData[d]).toLocaleDateString()
                                                        : country
                                                        ? country.name
                                                        : scannedData[d]}
                                                </b>
                                            </label>
                                        </div>
                                    );
                                }
                                return null;
                            })}
                            {
                                <div className="text-center m-2">
                                    <div
                                        className="btn btn-sm p-1 btn-primary mr-1 pointer"
                                        onClick={() =>
                                            acceptGuestIfNoMatch
                                                ? acceptGuest(customersAfterScan.customer, visaInfo)
                                                : checkUser()
                                        }
                                    >
                                        {acceptGuestIfNoMatch
                                            ? window.translate('ACCEPT')
                                            : window.translate('CHECK USER')}
                                    </div>
                                    <div
                                        className="btn btn-sm p-1 btn-outline-secondary ml-1 pointer"
                                        onClick={() => {
                                            setScannedData(null);
                                            setHolder(null);
                                            setResGuestRef(null);
                                            setGuest(null);
                                            setGuestToScan(null);
                                        }}
                                    >
                                        {window.translate('DECLINE')}
                                    </div>
                                </div>
                            }
                        </div>
                    ) : customersAfterScan.data.length > 0 && customersAfterScan.typeOfScan === 2 ? (
                        <div className="mx-5 mb-3 col-10 align-items-center">
                            {customersAfterScan.data.map((cus, key) => {
                                return (
                                    <div
                                        key={key}
                                        className="col-12 p-1 border rounded shadow-sm mb-3 pointer"
                                        onClick={() => acceptGuest(cus, visaInfo)}
                                    >
                                        {Object.keys(cus).map((d, key) => {
                                            if (dataToDisplayAfterScan.includes(d)) {
                                                const country =
                                                    d === 'country' ? (cus[d] ? countryCodes[cus[d]] : '') : false;
                                                return (
                                                    <div
                                                        key={key}
                                                        className="col-12 p-1 text-left d-flex align-items-center"
                                                    >
                                                        <label className="col-3 overflow-hidden ">
                                                            <small>{d}:</small>
                                                        </label>
                                                        <label className="col-9 text-primary overflow-hidden  ">
                                                            <b>
                                                                {d === 'dateOfBirth'
                                                                    ? new Date(cus[d]).toLocaleDateString()
                                                                    : country
                                                                    ? country.name
                                                                    : cus[d]}
                                                            </b>
                                                        </label>
                                                    </div>
                                                );
                                            }
                                            return null;
                                        })}
                                    </div>
                                );
                            })}
                        </div>
                    ) : null}
                    {customersAfterScan.data.length > 0 && customersAfterScan.typeOfScan === 2 ? (
                        <div
                            className="mt-3 mb-3 btn btn-sm btn-primary p-1 d-flex pointer col-2 m-auto"
                            onClick={() => {
                                setScannedData(customersAfterScan.customer);
                                setCustomersAfterScan({ ...customersAfterScan, data: [] });
                                setAcceptGuestIfNoMatch(true);
                            }}
                        >
                            {window.translate('ADD NEW')}
                        </div>
                    ) : null}
                </div>
            </div>
            <div className="col-4 pb-3 m-auto text-center">
                {scannedDoc.length >= reservation.adultsNumber && scannedDoc.includes(reservation.customerId) ? (
                    <div
                        className="btn btn-sm btn-primary pointer p-2 mt-5"
                        onClick={() => {
                            window.location = '/#/documentScanner?step3';
                            nextStepCallback();
                        }}
                    >
                        {window.translate('NEXT STEP')}
                    </div>
                ) : null}
            </div>{' '}
        </div>
    );
};

export default branch({
    docScan: ['model', 'DocumentScan'],
}, DocScannSecondStep);
