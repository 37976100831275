import React from 'react';
import { useBranch } from 'baobab-react/hooks';
import { setModalContent, closeModal } from '@components/modal';
import ConfirmButtons from '@components/buttons/confirmButtons';
import { registerDeposit } from './deposidActions';

const DepositForm = () => {
    const [cashAmt, setCashAmt] = React.useState(null);
    const [operation, setOperation] = React.useState('INITIAL');

     const { selectedPosTerminalId, PosTerminal } = useBranch({
        selectedPosTerminalId: ['state', 'selectedPosTerminalId'],
        PosTerminal: ['model', 'PosTerminal'],
    });
      

    if (!PosTerminal || !selectedPosTerminalId) {
        return null;
    }

    const selectedPosTerminal =
        PosTerminal.filter((p) => p.id === selectedPosTerminalId).length > 0 &&
        PosTerminal.filter((p) => p.id === selectedPosTerminalId)[0];
        
    const enuCode = selectedPosTerminal?.fiscalInfo?.enuCode   

    return (
        <div className="col-12 col-md-6 p-0 m-0 mb-2 pull-left ">
            <div className="m-1 card bg-white h-100 ">
                <div className="card-body">
                    <h5 className="card-title">{window.translate('Deposit register')}</h5>
                    <div className="form-group row justify-content-center align-items-center">
                        <label className="col-md-4 col-form-label" htmlFor="">
                            {window.translate('Type')}:
                        </label>
                        <div className="col-md-8">
                            {['INITIAL', 'WITHDRAW'].map((type) => {
                                return (
                                    <div className="form-check text-left" key={type}>
                                        <input
                                            className="form-check-input"
                                            type="radio"
                                            name={type}
                                            id={type}
                                            value={type}
                                            checked={operation && operation === type ? true : false}
                                            onChange={() => {
                                                setOperation(type);
                                            }}
                                        />
                                        <label className="form-check-label" htmlFor={type}>
                                            {type}
                                        </label>
                                    </div>
                                );
                            })}
                        </div>
                    </div>
                    <div className="form-group row justify-content-left align-items-center mt-2 mb-2">
                        <label className="col-md-4 col-form-label" htmlFor="">
                            {window.translate('Amount')}:
                        </label>
                        <div className="col-md-8">
                            <input
                                id="input-cash"
                                name="input-cash"
                                placeholder=""
                                autoComplete="off"
                                value={cashAmt ? cashAmt : ''}
                                onChange={(e) => {
                                    if (!isNaN(e.target.value)) {
                                        setCashAmt(e.target.value);
                                    }
                                }}
                                type="number"
                                className="form-control"
                            />
                        </div>
                    </div>

                    <div className=" text-center mt-2">
                        <button
                            disabled={cashAmt === null || cashAmt === '' || enuCode === null ? true : false}
                            onClick={() =>
                                setModalContent(
                                    <div className="container-fluid">
                                        <div className="text-center">
                                            {window.translate('Please confirm?')}
                                            <hr />
                                        </div>

                                        <div className="text-center">
                                            <ConfirmButtons
                                                onConfirm={async () => {
                                                    await registerDeposit({ cashAmt, operation, enuCode });
                                                    closeModal();
                                                }}
                                                onCancel={() => {
                                                    closeModal();
                                                }}
                                            />
                                        </div>
                                    </div>,

                                    window.translate('Issue invoice'), // header
                                    false, // showCloseButton
                                    'modal-md'
                                )
                            }
                            className="btn btn-primary btn-sm p-2 "
                        >
                            {window.translate('Apply')}
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DepositForm;
