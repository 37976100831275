import * as React from 'react';
//@ts-ignore
import { useBranch } from 'baobab-react/hooks';
//@ts-ignore
import { Link } from 'react-router-dom';
import { translate } from '@data/translations';
import { /* ReservationController, */ Reservation } from '../resController';
import { ReservationStatus } from '@common/modelDefinition';
import FeatureFlag from '@components/FeatureFlag';
import moment from 'moment';

interface PosTerminalConsumptionProps {
    reservation: Reservation;
    history: any;
}

interface ConsumptionInvoicesProps {
    reservation: Reservation;
    history: any;
    consumptionInvoices: any[];
    posTerminals?: any;
}

function PosTerminalConsumption(props: PosTerminalConsumptionProps) {
    const { reservation, history } = props;
    // const ctrl = new ReservationController();

    const { posTerminals } = useBranch({
        posTerminals: ['model', 'PosTerminal'],
    });

    const [isExpanded, setIsExpanded] = React.useState(false);

    const posTerminalInvoices: any[] = reservation.Invoices.filter(
        (inv: any) => inv.terminalType === 'posTerminal'
    );
    const reservationId = reservation ? reservation.id : null;

    if (!reservationId || reservation.statusEnum !== ReservationStatus.checkedIn) {
        return null;
    }

    const headerClass = isExpanded
        ? 'pointer text-center border-bottom border-top uppercase text-primary  p-2'
        : 'pointer text-center border-top uppercase text-primary p-2'; //no undeline

    return (
        <FeatureFlag flag="simplePosTerminal">
            <div className="mt-2">
                <h5
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                    className={headerClass}
                >
                    <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                    &ensp;{translate('Items from terminal')}&ensp;
                    <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                </h5>

                {isExpanded ? <div className=""></div> : null}

                {isExpanded ? (
                    <ConsumptionInvoices
                        reservation={reservation}
                        consumptionInvoices={posTerminalInvoices}
                        history={history}
                        posTerminals={posTerminals}
                    />
                ) : null}
            </div>
        </FeatureFlag>
    );
}

export const ConsumptionInvoices = (props: ConsumptionInvoicesProps) => {
    // Minibar ili POS Terminal
    const { reservation, consumptionInvoices, posTerminals = null, history } = props;

    const headerField = posTerminals ? (
        <div className="col-3 col-md-3">{translate('TERMINAL')}</div>
    ) : (
        <div className="col-3 col-md-3 uppercase">{translate('Invoice issued by')}</div>
    );

    if (consumptionInvoices.length === 0 && !posTerminals) {
        return null; // ne prikazuj lista je prazna ako su upitanju minibar računi
    }

    return (
        <div>
            <div className="mt-2">
                {consumptionInvoices.length === 0 ? (
                    <small>{translate('List is empty')}</small>
                ) : (
                    <ul className="list-group clear">
                        <li className="list-group-item">
                            <small>
                                <div className="row align-items-center">
                                    <div className="col-3 col-md-3">{translate('DATE')}</div>
                                    {headerField}
                                    <div className="col-2 col-md-2">{translate('TOTAL VALUE')}</div>
                                    <div className="col-2 col-md-2">{translate('INV. ID')}</div>
                                    <div className="col-2 col-md-2"></div>
                                </div>
                            </small>
                        </li>
                        {consumptionInvoices.map((t, key) => {
                            const terminal = posTerminals
                                ? posTerminals.find((p: any) => p.id === t.posTerminalId)
                                : null;
                            return (
                                <li key={key} className="list-group-item">
                                    <small>
                                        <div className="row align-items-center">
                                            <div className="col-3 col-md-3">
                                                {moment(t.timestamp).format('DD/MM/YYYY')}
                                            </div>
                                            <div className="col-3 col-md-3">
                                                {terminal ? terminal.name : t.operatorName}
                                            </div>
                                            <div className="col-2 col-md-2">
                                                {t.totalValue} {t.defaultCurrency}
                                            </div>
                                            <div className="col-2 col-md-2">
                                                <Link
                                                    key={key}
                                                    target="_blank"
                                                    rel="noopener noreferrer"
                                                    to={'/invoiceView/' + t.id}
                                                >
                                                    #{t.id}
                                                </Link>
                                            </div>
                                            <div className="col-2 col-md-2">
                                                <button
                                                    // disabled={!addOrRemoveFromConnectedInvoices}
                                                    onClick={() => {
                                                        const { groupUuid, id } = reservation;
                                                        if (!groupUuid) {
                                                            history.push('/checkoutAndInvoice/' + id);
                                                        } else {
                                                            history.push('/issueGroupInvoice/' + groupUuid);
                                                        }
                                                    }}
                                                    className="btn btn-sm btn-primary mr-1"
                                                >
                                                    {translate('Invoices')}
                                                </button>
                                            </div>
                                        </div>
                                    </small>
                                </li>
                            );
                        })}
                    </ul>
                )}
            </div>
        </div>
    );
};

export default PosTerminalConsumption;
