import React, { useEffect, useState } from 'react';
import { branch } from 'baobab-react/higher-order';
import baobabTree from '../../data/state';
import rest from '@data/restWrapper';
import PermissionCheck from '../../components/PermissionCheck';
import { toast } from 'react-toastify';

const RoomsOnFailure = (props) => {
    const [searchTermState, setSearchTerm] = useState('');

    useEffect(() => {}, []);

    const searchRoom = (e) => {
        setSearchTerm(e.target.value);
    };

    const toggleSendNotificationOnFailure = async (id, currentValue, roomInfo) => {
        if (id) {
            try {
                let targetedSrc = await rest('/SmartRoomController', 'POST', {
                    id: id,
                    sendNotificationAboutFailure: currentValue ? 0 : 1,
                });
                if(targetedSrc?.sendNotificationAboutFailure === true){
                    toast(window.translate("Send room failure notification for the room ") + roomInfo.name, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
                }else{
                    toast(window.translate("Don't send room failure notification for the room ") + roomInfo.name, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
                }

                baobabTree.root
                    .select(['model', 'SmartRoomController', { remoteDeviceId: roomInfo.remoteDeviceId }])
                    .set(targetedSrc);
            } catch (error) {
                console.log('Cannot toggle notification.', error);
            }
        }
    };

    return (
        <div className='m-1'>
            <button
                onClick={() => {
                    props.setShowRoomsToSelect(false);
                }}
                className="btn btn-sm btn-secondary mb-2"
            >
                {window.translate('GO BACK')}
            </button>

            {props.smartRoomControllers && props.smartRoomControllers.length > 0 ? (
                <div className="">
                    <div className="mx-2 mt-2">{window.translate('Select rooms for notification on failure')}</div>
                    <div className="mx-2 mt-2">
                        <input
                            type="text"
                            style={{ width: '300px' }}
                            className="form-control form-control-sm"
                            onChange={(e) => searchRoom(e)}
                            placeholder={window.translate('Search by room name')}
                        ></input>
                    </div>
                </div>
            ) : (
                <div className="d-flex justify-content-between mx-2 mt-2">
                    <div className="">{window.translate('No connected rooms')}</div>
                </div>
            )}

            <div className="row ">
                {props.smartRoomControllers.map((sRC, index) => {
                    const roomInfo = props.roomInfo.find((r) => r.remoteDeviceId === sRC.remoteDeviceId);
                    if (roomInfo && roomInfo.name.toLowerCase().includes(searchTermState.toLowerCase())) {
                        return (
                            <div className='col-3' key={index}>
                            <div
                                className="p-2 col-12 mx-2 mt-2 d-inline-flex bg-white shadow-sm list-group"
                               
                            >
                                <div className="px-1 py-2 row">
                                    <div className="col-6">
                                        <b>{roomInfo?.name}</b>
                                    </div>
                                    <PermissionCheck name="roomInfo_roomSettings">
                                        <div
                                            onClick={toggleSendNotificationOnFailure.bind(
                                                this,
                                                sRC?.id,
                                                sRC?.sendNotificationAboutFailure,
                                                roomInfo
                                            )}
                                            className="pointer col-6"
                                        >
                                            <i
                                                className={
                                                    sRC?.sendNotificationAboutFailure
                                                        ? 'fa fa-2x fa-check-square-o pull-right'
                                                        : 'fa fa-2x fa-square-o pull-right'
                                                }
                                            ></i>
                                        </div>
                                    </PermissionCheck>
                                </div>
                            </div>
                        </div>
                        );
                    } else {
                        return null
                    }
                })}
            </div>
        </div>
    );
};

export default branch(
    {
        smartRoomControllers: ['model', 'SmartRoomController'],
        roomInfo: ['model', 'RoomInfo'],
    },
    RoomsOnFailure
);
