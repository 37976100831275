import React, { useState, useEffect } from 'react';
import ReportTable from '../components/ReportTable';
import PageTitle from '../components/pageTitle';
import { getReportData, getExcelExport } from '../data/reports';
import { setModalContent, closeModal } from '@components/modal';
import _ from 'lodash';
import rest from '../data/restWrapper';

const sosTicketTypeEnums = {
    alarm_roomService: 'CALL',
    alarm_sos: 'SOS',
};

const EditSosMessageModal = (props) => {
    const { event, onUpdateHandler } = props;

    const updateEventNote = async (note) => {
        /*const updatedEventInfo =*/ await rest(`/SosResponse/${event.id}`, 'PUT', { eventNotes: note });
        const eventClone = _.clone(event);
        eventClone.eventNotes = note;
        onUpdateHandler(eventClone);
        closeModal();
    };

    const [eventNotes, setEventNotes] = React.useState(event.eventNotes ? event.eventNotes : '');

    React.useEffect(() => {
        setEventNotes(event.eventNotes);
    }, [event.eventNotes]);

    return (
        <div key={event.id}>
            <textarea
                onChange={(e) => {
                    setEventNotes(e.target.value);
                }}
                style={{ height: '200px' }}
                value={eventNotes}
                className="form-control"
            ></textarea>
            <button
                onClick={() => {
                    updateEventNote(eventNotes);
                }}
                className="btn mt-2 mb-3 btn-sm btn-info pull-right"
            >
                {window.translate('Update')}
            </button>
        </div>
    );
};

function ReservationsReportList(props) {
    const [items, setItems] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [_page, setPage] = React.useState(0);

    useEffect(() => {
        const loadData = async () => {
            getReservationsForYearAndSetState(year);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const getReservationsForYearAndSetState = async (year, page = _page) => {
        const _items = await getReportData('sosEvents100', { year, page });
        setItems([..._items]);
        setPage(page);
    };

    const _setYear = async (e) => {
        setYear(Number(e.target.value));
        await getReservationsForYearAndSetState(Number(e.target.value));
    };

    const showSosFormModal = async (sosResponseId) => {
        const onUpdateHandler = (updatedEvent) => {
            const _items = [...items];
            const target = _items.find((event) => event.id === updatedEvent.id);
            target.eventNotes = updatedEvent.eventNotes;
            setItems(_items);
        };

        const ev = await rest(`/SosResponse/${sosResponseId}`);
        setModalContent(
            <EditSosMessageModal onUpdateHandler={onUpdateHandler} event={ev} />,
            window.translate('RESPOND TO EVENT'),
            false,
            'modal-md'
        );
    };

    const headers = [
        {
            filterKey: 'eventId',
            headerTitle: window.translate('Event id'),
            label: 'eventId',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '80px',
        },
        {
            filterKey: 'ticketType',
            headerTitle: window.translate('Type'),
            label: 'ticketType',
            type: 'enum',
            filterType: 'select',
            enumDescription: sosTicketTypeEnums,
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '200px',
        },
        {
            filterKey: 'startTime',
            headerTitle: window.translate('Time'),
            label: 'startTime',
            type: 'datetime',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '150px',
        },
        {
            filterKey: 'roomName',
            headerTitle: window.translate('Source'),
            label: 'roomName',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'responder',
            headerTitle: window.translate('Responder'),
            label: 'responder',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'reactionMinutes',
            headerTitle: window.translate('Reaction time (min)'),
            label: 'reactionMinutes',
            type: 'number',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'enterRoomReactionMinutes',
            headerTitle: window.translate('Room entry after start (min)'),
            label: 'enterRoomReactionMinutes',
            type: 'number',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'helpDurationMinutes',
            headerTitle: window.translate('Assistance duration'),
            label: 'helpDurationMinutes',
            type: 'number',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'durationMinutes',
            headerTitle: window.translate('Event duration'),
            label: 'durationMinutes',
            type: 'number',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'eventNotes',
            headerTitle: window.translate('Notes'),
            label: 'eventNotes',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '300px',
        },
    ];

    return (
        <div className=" ">
            <PageTitle />

            <ReportTable
                year={year}
                setYear={_setYear.bind(this)}
                onYearMonthChange={(filter) => {
                    /*var {year, month} = filter
                        setState({year, month});
                        reloadData(year, month);*/
                }}
                itemsArray={items}
                headers={headers}
                monthFilter={true}
                history={props.history}
                targetedColumnForMonthFilter={'startTime'}
                onRowClickHandler={(row) => {
                    showSosFormModal(row.id);
                }}
                targetedColumnForDateFilter={'startTime'}
                showDateFilter={true}
                xlsExportHandler={(params) => {
                    getExcelExport('sosEvents100', params);
                }}
                loadData={(page) => getReservationsForYearAndSetState(year, page)}
                page={_page}
                listOfItems={items}
            ></ReportTable>
        </div>
    );
}

export default ReservationsReportList;
