import React from 'react';
import DayPickerInput from 'react-day-picker/DayPickerInput';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import baobab from '@data/state';
import { CustomerModel, ReservationGuestReferenceModel, ReservationModel } from '@common/modelDefinition';
import translate from '@data/translations';
import { DocumentScanner } from './DocumentScanner';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';

interface VisaDataModalProps {
    visaData: ReservationGuestReferenceModel | undefined;
    saveHandler: any;
    customer: CustomerModel;
    reservation: ReservationModel;
    docScan: any;
}

const VisaDataModal = (props: VisaDataModalProps) => {
    const { visaData, saveHandler, customer, reservation } = props;
    const [visaDataClone, setVisaDataClone] = React.useState({ ...visaData } as ReservationGuestReferenceModel);
    const [showDocumentScannerData, setShowDocumentScannerData] = React.useState(false);
    const [customersAfterScan, setCustomersAfterScan] = React.useState({
        data: [],
        typeOfScan: 0,
        visaInfo: {} as ReservationGuestReferenceModel,
        customer: {} as CustomerModel,
    });

    React.useEffect(() => {
        if (visaData) {
            setVisaDataClone({ ...visaData });
        }
    }, [visaData]);

    React.useEffect(() => {
        const loadData = async () => {
            if (customersAfterScan.visaInfo.customerId) {
                const _visaData = { ...customersAfterScan.visaInfo, id: visaDataClone.id };
                setVisaDataClone(_visaData);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [customersAfterScan]);

    const handleInputChange = (e: any) => {
        const _visaDataClone: any = { ...visaDataClone };
        _visaDataClone[e.target.name] = e.target.value;
        setVisaDataClone({ ..._visaDataClone });
    };

    const handleDate = (attribute: string, d: any) => {
        if (attribute === 'visaEntryDate') {
            let date = moment(d).format('YYYY-MM-DD');
            visaDataClone['visaEntryDate'] = date;
        }
        if (attribute === 'visaValidityPeriod') {
            let date = moment(d).format('YYYY-MM');
            visaDataClone['visaValidityPeriod'] = date;
        }
        if (attribute === 'documentValidityPeriod') {
            let date = moment(d).format('YYYY-MM');
            visaDataClone['documentValidityPeriod'] = date;
        }
        setVisaDataClone({ ...visaDataClone });
    };

    const closeModal = () => {
        baobab.root.select(['modalData', 'display']).set(false);
        baobab.root.select(['modalData', 'content']).set(null);
    };

    return (
        <div className="container pb-2">
            <div className="mt-2 mb-4 p-0">
                <button
                    className="ml-3 btn btn-sm btn-info pointer"
                    onClick={(e) => {
                        e.preventDefault();
                        setShowDocumentScannerData(!showDocumentScannerData);
                    }}
                >
                    <small>{translate('READ FROM SCANNER')}</small>
                </button>
            </div>

            {showDocumentScannerData ? (
                <DocumentScanner
                    docScan={props.docScan}
                    customer={customer}
                    reservation={reservation}
                    setCustomersAfterScan={setCustomersAfterScan}
                    setShowDocumentScannerData={setShowDocumentScannerData}
                    visa={true}
                />
            ) : (
                <>
                    <div className="form-group row justify-content-center align-items-center mt-2 mb-3">
                        <label className="col-md-4 col-form-label text-right" htmlFor="">
                            {translate('Visa Type And Number')}
                        </label>
                        <div className="col-md-8">
                            <input
                                autoComplete="off"
                                name="visaTypeAndNumber"
                                placeholder={translate('Visa Type And Number')}
                                type="text"
                                className="form-control"
                                value={visaDataClone.visaTypeAndNumber ? visaDataClone.visaTypeAndNumber : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="form-group row justify-content-center align-items-center mt-2 mb-3">
                        <label className="col-md-4 col-form-label text-right" htmlFor="">
                            {translate('Visa Validity Period')}
                        </label>
                        <div className="col-md-8">
                            <DayPickerInput
                                // name="visaValidityPeriod"
                                value={
                                    visaDataClone.visaValidityPeriod
                                        ? moment(visaDataClone.visaValidityPeriod).format('YYYY-MM')
                                        : ''
                                }
                                format="YYYY-MM"
                                placeholder="YYYY-MM"
                                onDayChange={handleDate.bind(this, 'visaValidityPeriod')}
                            />
                        </div>
                    </div>
                    <div className="form-group row justify-content-center align-items-center mt-2 mb-3">
                        <label className="col-md-4 col-form-label text-right" htmlFor="">
                            {translate('Date entry in country')}
                        </label>
                        <div className="col-md-8">
                            <DayPickerInput
                                // name="visaEntryDate"
                                value={
                                    visaDataClone.visaEntryDate
                                        ? moment(visaDataClone.visaEntryDate).format('YYYY-MM-DD')
                                        : ''
                                }
                                format="YYYY-MM-DD"
                                placeholder="YYYY-MM-DD"
                                onDayChange={handleDate.bind(this, 'visaEntryDate')}
                            />
                        </div>
                    </div>
                    <div className="form-group row justify-content-center align-items-center mt-2 mb-3">
                        <label className="col-md-4 col-form-label text-right" htmlFor="">
                            {translate('Entry place')}
                        </label>
                        <div className="col-md-8">
                            <input
                                autoComplete="off"
                                name="placeEntry"
                                placeholder={translate('Entry place')}
                                type="text"
                                className="form-control"
                                value={visaDataClone.placeEntry ? visaDataClone.placeEntry : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <div className="form-group row justify-content-center align-items-center mt-2 mb-3">
                        <label className="col-md-4 col-form-label text-right" htmlFor="">
                            {translate('Document Validity Period')}
                        </label>
                        <div className="col-md-8">
                            <DayPickerInput
                                // name="documentValidityPeriod"
                                value={
                                    visaDataClone.documentValidityPeriod
                                        ? moment(visaDataClone.documentValidityPeriod).format('YYYY-MM')
                                        : ''
                                }
                                format="YYYY-MM"
                                placeholder="YYYY-MM"
                                onDayChange={handleDate.bind(this, 'documentValidityPeriod')}
                            />
                        </div>
                    </div>
                    <div className="form-group row justify-content-center align-items-center mt-2 mb-3">
                        <label className="col-md-4 col-form-label text-right" htmlFor="">
                            {translate('Notes')}
                        </label>
                        <div className="col-md-8">
                            <input
                                autoComplete="off"
                                name="note"
                                placeholder={translate('Notes')}
                                type="text"
                                className="form-control"
                                value={visaDataClone.note ? visaDataClone.note : ''}
                                onChange={handleInputChange}
                            />
                        </div>
                    </div>
                    <br />
                    <div className="text-center mb-5 pt-5">
                        <button
                            data-dismiss="modal"
                            onClick={() => {
                                saveHandler(visaDataClone);
                            }}
                            className="btn btn-outline-primary"
                        >
                            {translate('Save')}
                        </button>
                        <button
                            data-dismiss="modal"
                            onClick={() => closeModal()}
                            className="btn btn-outline-secondary ml-2"
                        >
                            {translate('Cancel')}
                        </button>
                    </div>{' '}
                </>
            )}
        </div>
    );
};

export default branch(
    {
        docScan: ['model', 'DocumentScan'],
    },
    VisaDataModal
);
