import React, { Component } from 'react';
import _ from 'lodash';
import PageTitle from '../../components/pageTitle';
import { branch } from 'baobab-react/higher-order';
import DailyFiscalClosure from './dailyFiscalClosure';
import FiscalMemoryReportByDate from './fiscalMemoryReportByDate';
import DuplicateDocumentsfrom from './duplicateDocuments';
import InputAndOutputOfCash from './inputAndOutputOfCash';
import ConfirmFiscalClosure from '../../components/confirmModal';
import DeleteAllArticlesFromFiscalPrinter from './deleteAllArticlesFromFiscalPrinter';
import rest from '../../data/restWrapper';
import moment from 'moment';
import { setModalContent } from '@components/modal';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';

class FiscalPrinterTools extends Component {
    constructor() {
        super();
        this.state = {
            fiscalConfig: null,
            selectedFiscalPrinter: null,
            selectedDate: { from: moment().valueOf(), to: moment().add(1, 'days').valueOf() },
            type: 'F',
            periodOrNumber: 'Document number',
            duplicateFrom: moment().startOf('day').valueOf(),
            duplicateTo: moment().endOf('day').valueOf(),
            fromFiscalReceiptId: 1,
            toFiscalReceiptId: null,
            inputOrOutputAmount: 0,
            inputOrOutputToFiscalPrinter: 'Input',
        };
    }

    async componentDidMount() {
        await this.redrawComponent();
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.srcProxies.length !== this.props.srcProxies) {
            await this.redrawComponent();
        }
    }

    async redrawComponent() {
        try {
            let selectedFiscalPrinter = _.clone(this.state.selectedFiscalPrinter);
            if (selectedFiscalPrinter === null) {
                const srcProxyName = this.props.match.params.srcProxyName; //should be always present!!!
                const { srcProxies } = this.props;
                let fiscalSrcProxies = _.filter(srcProxies, (s) => {
                    return s.fiscalProxy;
                });
                const hasFiscalPrinters = fiscalSrcProxies.length > 0;
                if (hasFiscalPrinters) {
                    selectedFiscalPrinter = fiscalSrcProxies.find((fs) => fs.name === srcProxyName).SRCProxyUUID;
                    if (selectedFiscalPrinter) {
                        const fiscalConfig = await rest('/getFiscalConfig/' + selectedFiscalPrinter, 'GET');
                        this.setState({ selectedFiscalPrinter, fiscalConfig });
                    }
                }
            }
        } catch (err) {
            console.warn(err);
        }
    }

    async dailyFiscalClosure(reportType) {
        startGlobalLoading();
        try {
            const { selectedFiscalPrinter = null } = this.state;
            if (selectedFiscalPrinter) {
                
                await rest('/issueDailyFiscalClosure/' + selectedFiscalPrinter, 'POST', {
                    selectedFiscalPrinter,
                    reportType,
                });
            }
        } catch (err) {
            console.warn(err); //To Do
        }
     
        stopGlobalLoading();
        await this.redrawComponent();
    }

    showConfirmModal(payload) {
        let option = payload.option;
        let actionForYes = null;
        let content = '';
        if (option === 'dailyFiscalClosure') {
            actionForYes = this.dailyFiscalClosure.bind(this, payload.reportType);
            content = window.translate(payload.reportType==='zReport'?'Issue fiscal closure':'Issue daily report') + '?';
        } else if (option === 'fiscalMemoryReportByDate') {
            actionForYes = this.fiscalMemoryReportByDate.bind(this, payload.fiscalPrinter, payload.from, payload.to);
            content = window.translate('Issue report by date') + '?';
        } else if (option === 'duplicateDocument') {
            actionForYes = this.issueFiscalDocumentDuplicate.bind(this, payload);
            content = window.translate('Issue document duplicate') + '?';
        } else if (option === 'internalInputAndOutputOfCash') {
            actionForYes = this.internalInputAndOutputOfCash.bind(this, payload.type);
            if (payload.type === 'Input') {
                content = window.translate('Input cash to fiscal printer') + '?';
            } else if (payload.type === 'Output') {
                content = window.translate('Output cash from fiscal printer') + '?';
            }
        } else if (option === 'deleteAllArticlesFromFiscalPrinter') {
            actionForYes = this.deleteAllArticlesFromFiscalPrinter.bind(this);
            content = window.translate('Delete all articles from Fiscal Printer') + '?';
        }
        setModalContent(
            <ConfirmFiscalClosure content={content} actionForYes={actionForYes} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    }

    async deleteAllArticlesFromFiscalPrinter() {
        startGlobalLoading();
        try {
            const { selectedFiscalPrinter = null } = this.state;
            if (selectedFiscalPrinter) {
                await rest('/deleteAllArticlesFromFiscalPrinter/' + selectedFiscalPrinter, 'POST', {
                    selectedFiscalPrinter,
                });
            }
        } catch (err) {
            console.warn(err); //To Do
        }
        stopGlobalLoading();
        await this.redrawComponent();
    }

    async internalInputAndOutputOfCash(type) {
        startGlobalLoading();
        try {
            const { selectedFiscalPrinter = null, inputOrOutputAmount } = this.state;
            if (selectedFiscalPrinter) {
                await rest('/internalInputAndOutputOfCash/' + selectedFiscalPrinter, 'POST', {
                    selectedFiscalPrinter,
                    type,
                    inputOrOutputAmount,
                });
                this.setState({ inputOrOutputAmount: 0, inputOrOutputToFiscalPrinter: 'Input' });
            }
        } catch (err) {
            console.warn(err); //To Do
        }
        stopGlobalLoading();
        await this.redrawComponent();
    }

    handleSelectDateChange(type, _selectedDate) {
        let selectedDate = _.clone(this.state.selectedDate);
        let duplicateFrom = _.clone(this.state.duplicateFrom);
        let duplicateTo = _.clone(this.state.duplicateTo);
        if (type === 'from') {
            selectedDate['from'] = _.clone(this.state.selectedDate['from']);
            selectedDate['from'] = moment(_selectedDate).valueOf();
        } else if (type === 'to') {
            selectedDate['to'] = _.clone(this.state.selectedDate['to']);
            selectedDate['to'] = moment(_selectedDate).valueOf();
        } else if (type === 'duplicateFrom') {
            duplicateFrom = moment(_selectedDate).valueOf();
        } else if (type === 'duplicateTo') {
            duplicateTo = moment(_selectedDate).valueOf();
        }
        // Change the date and go to route of selected date
        this.setState({ selectedDate, duplicateFrom, duplicateTo });
    }

    async fiscalMemoryReportByDate(selectedFiscalPrinter, from, to) {
        startGlobalLoading();
        try {
            if (selectedFiscalPrinter) {
                await rest('/issueFiscalMemoryReportByDate/' + selectedFiscalPrinter, 'POST', {
                    selectedFiscalPrinter,
                    from,
                    to,
                });
            }
        } catch (err) {
            console.warn(err); //To Do
        }
        stopGlobalLoading();
        await this.redrawComponent();
    }

    handlePeriodOrDocumentNumber(e) {
        let periodOrNumber = _.clone(this.state.periodOrNumber);
        periodOrNumber = e.target.value;
        this.setState({ periodOrNumber });
    }

    handleInputOrOutputToFiscalPrinter(e) {
        let inputOrOutputToFiscalPrinter = _.clone(this.state.inputOrOutputToFiscalPrinter);
        inputOrOutputToFiscalPrinter = e.target.value;
        this.setState({ inputOrOutputToFiscalPrinter });
    }

    handleSelectType(e) {
        let type = _.clone(this.state.type);
        type = e.target.value;
        this.setState({ type });
    }

    handleTimeChange(type, e) {
        let duplicateFrom = _.clone(this.state.duplicateFrom);
        let duplicateTo = _.clone(this.state.duplicateTo);
        if (type === 'timeFrom') {
            duplicateFrom = e.valueOf();
        } else if (type === 'timeTo') {
            duplicateTo = e.valueOf();
        }
        this.setState({ duplicateFrom, duplicateTo });
    }

    async issueFiscalDocumentDuplicate(payload) {
        startGlobalLoading();
        try {
            const { selectedFiscalPrinter = null } = this.state;
            if (selectedFiscalPrinter) {
                await rest('/issueFiscalDocumentDuplicate/' + selectedFiscalPrinter, 'POST', { payload });
                this.setState({
                    selectedFiscalPrinter: null,
                    selectedDate: { from: moment().valueOf(), to: moment().add(1, 'days').valueOf() },
                    type: 'F',
                    periodOrNumber: 'Document number',
                    duplicateFrom: moment().startOf('day').valueOf(),
                    duplicateTo: moment().endOf('day').valueOf(),
                    fromFiscalReceiptId: 1,
                    toFiscalReceiptId: null,
                });
            }
        } catch (err) {
            console.warn(err); //To Do
        }
        stopGlobalLoading();
        await this.redrawComponent();
    }

    handleSelectFiscalReceiptId(type, e) {
        let fromFiscalReceiptId = _.clone(this.state.fromFiscalReceiptId);
        let toFiscalReceiptId = _.clone(this.state.toFiscalReceiptId);
        if (type === 'from') {
            fromFiscalReceiptId = Number(e.target.value);
        } else if (type === 'to') {
            toFiscalReceiptId = Number(e.target.value);
        }
        this.setState({ fromFiscalReceiptId, toFiscalReceiptId });
    }

    inputHandler(e) {
        let inputOrOutputAmount = _.clone(this.state.inputOrOutputAmount);
        if (_.isNumber(Number(e.target.value))) {
            inputOrOutputAmount = Number(e.target.value);
        }
        this.setState({ inputOrOutputAmount });
    }

    render() {
        let showFbih_config =
            this.state.fiscalConfig && this.state.fiscalConfig.countryAndProvider === 'fbih_config' ? true : false;
        let showRs_galeb =
            this.state.fiscalConfig && this.state.fiscalConfig.countryAndProvider === 'rs_galeb' ? true : false;

        return (
            <div>
                <PageTitle />
                <div className="d-flex">
                    <button
                        className="btn btn-sm btn-secondary"
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                    >
                        {window.translate('GO BACK')}
                    </button>
                </div>
                <div className="col-md-12">
                    <div className="row">
                        <DailyFiscalClosure showConfirmModal={this.showConfirmModal.bind(this)} {...this.state} />
                        <FiscalMemoryReportByDate
                            handleSelectDateChange={this.handleSelectDateChange.bind(this)}
                            showConfirmModal={this.showConfirmModal.bind(this)}
                            {...this.state}
                        />

                        {showFbih_config ? (
                            <DuplicateDocumentsfrom
                                handleSelectDateChange={this.handleSelectDateChange.bind(this)}
                                showConfirmModal={this.showConfirmModal.bind(this)}
                                {...this.state}
                                handlePeriodOrDocumentNumber={this.handlePeriodOrDocumentNumber.bind(this)}
                                handleSelectType={this.handleSelectType.bind(this)}
                                handleTimeChange={this.handleTimeChange.bind(this)}
                                handleSelectFiscalReceiptId={this.handleSelectFiscalReceiptId.bind(this)}
                            />
                        ) : null}
                        <InputAndOutputOfCash
                            showConfirmModal={this.showConfirmModal.bind(this)}
                            {...this.state}
                            inputHandler={this.inputHandler.bind(this)}
                            handleInputOrOutputToFiscalPrinter={this.handleInputOrOutputToFiscalPrinter.bind(this)}
                        />
                        {showRs_galeb ? (
                            <DeleteAllArticlesFromFiscalPrinter
                                showConfirmModal={this.showConfirmModal.bind(this)}
                                {...this.state}
                            />
                        ) : null}
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        srcProxies: ['model', 'SRCProxy'],
    },
    FiscalPrinterTools
);
