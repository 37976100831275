import React, { Component } from 'react';
import rest from '../../data/restWrapper';
import AccessCodes from './accessCodes';
import PageLoader from '../../components/loaders/loader1';
import IOConfiguration from './IOConfiguration';
import GeneralSettings from './generalSettings';
import RoomSensorStrip from './roomSensorStrip';
//  import dummyData from "./dummySRCData"
import CommunicationError from './communicationError';
import Thermostat from './thermostat';
import PageTitle from '../../components/pageTitle';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';

//checkIfRoomHasSettings

import { checkIfRoomHasSettings } from '../../data/controllers/SRCStatus';

import validateSRCSettingsCorruption from './validator';

class RoomSettings extends Component {
    constructor(params) {
        super(params);
        this.state = {
            showConnectionError: false,
            SRCSettings: {},
            settingsFetched: false,
            invalidSettings: [],
        };
    }

    async fetchRoomSettings(remoteDeviceId, roomInfoId) {
        try {
            let SRCSettings = this.props.onSaveHandler
                ? this.props.SRCTemplateData
                : await rest('/src/fetchRoomSettingsByRoomInfoId/' + roomInfoId, 'POST');
            //validate all regured ranges....
            let invalidSettings = validateSRCSettingsCorruption(SRCSettings);

            console.log("----SRCSettings",SRCSettings);

            this.setState({
                showConnectionError: false,
                SRCSettings,
                settingsFetched: true,
                invalidSettings,
            });
        } catch (error) {
            this.setState({ showConnectionError: true });
        }
    }

    setActivePage(type) {
        this.setState({
            activeLink: type,
        });
    }

    componentDidMount() {
        if (this.props.template) {
            if (this.props.SRCTemplateData.generalSettings) {
                //it is edit
                this.setState({ SRCSettings: this.props.SRCTemplateData, settingsFetched: true });
            }
        } else {
            this.readDeviceSettings();
        }
    }

    readDeviceSettings() {
        let remoteDeviceId = this.props.match.params.remoteDeviceId;
        let roomInfoId = this.props.match.params.roomInfoId; //Number(this.props.match.params.roomInfoId);
        let roomInfo = _.find(this.props.rooms, (r) => r.id === Number(this.props.match.params.roomInfoId));
        let srcInfo = _.find(
            this.props.smartRoomControllers,
            (src) => src.srcProxyId === Number(roomInfo.srcProxyId) && src.remoteDeviceId === roomInfo.remoteDeviceId
        );

        if (srcInfo && (srcInfo.srcType === null || srcInfo.srcType === 'SRC')) {
            this.fetchRoomSettings(remoteDeviceId, roomInfoId);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.props.template) {
            if (prevProps.SRCTemplateData.selectTimestamp !== this.props.SRCTemplateData.selectTimestamp) {
                this.setState({ SRCSettings: this.props.SRCTemplateData, settingsFetched: true });
            }
        }
    }

    render() {
        let roomName = '';
        let room = _.find(this.props.rooms, { id: Number(this.props.match.params.roomInfoId) });
        

        if (room) {
            roomName = room.name;
            if (checkIfRoomHasSettings(room) === false) {
                return <div className="mt-4">{window.translate("SRC settings not available for this device. Probably SOS Calling Device.")}</div>;
            }
        } else {
            if (this.props.template) {
                //allow rendering....
                if (this.state.SRCSettings.generalSettings === undefined) {
                    return null;
                }
            } else {
                return null;
            }
        }

        if (this.state.showConnectionError) {
            return (
                <div>
                    <PageTitle title={roomName} />
                    <CommunicationError />
                </div>
            );
        }

        if (this.state.settingsFetched === false) {
            return (
                <div>
                    <PageTitle title={roomName} />
                    <PageLoader
                        style={{ paddingTop: '10rem' }}
                        header={window.translate("READING EXTENDED SRC SETTINGS")}
                        heading={window.translate("(please wait)")}
                    />
                </div>
            );
        } else {
            return (
                <div>
                    <PageTitle title={roomName} />

                    <button
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className="btn btn-sm btn-secondary mb-2"
                    >
                        {window.translate("GO BACK")}
                    </button>

                    {this.props.templateMode ? null : (
                        <RoomSensorStrip roomSettings={this.state.SRCSettings} {...this.props} />
                    )}

                    <AccessCodes roomSettings={this.state.SRCSettings} {...this.props} />

                    <Thermostat
                        invalidSettings={this.state.invalidSettings}
                        name={window.translate("MAIN THERMOSTAT")}
                        sufix={'_t1'}
                        {...this.props}
                        roomSettings={this.state.SRCSettings}
                    />

                    <GeneralSettings
                        invalidSettings={this.state.invalidSettings}
                        roomSettings={this.state.SRCSettings}
                        {...this.props}
                    />
                    <IOConfiguration roomSettings={this.state.SRCSettings} {...this.props} />
                </div>
            );
        }
    }
}
export default branch(
    {
        rooms: ['model', 'RoomInfo'],
        smartRoomControllers: ['model', 'SmartRoomController'],
    },
    RoomSettings
);
