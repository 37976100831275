import React from "react"
import { useBranch } from 'baobab-react/hooks';
import { getLanguagesInArray } from '../../../data/translations';
import { handleChange, saveFormData } from "../settingsActions"

const LocaleAndCurrency = (settings) => {
    const { locale, defaultCurrency } = settings
    const { currency } = useBranch({
        currency: ['model', 'Currency'],
    });

    let localeList = getLanguagesInArray();
    let defaultCurrencyList = currency ? currency.map(c => c.shortName) : [];

    let localeOptions = [];
    localeList.forEach((locale, ky) => {
        localeOptions.push(
            <option key={ky} value={locale}>
                {locale}
            </option>
        );
    });

    let defaultCurrencyOptions = [];
    defaultCurrencyList.forEach((defCurr, defCurrKey) => {
        defaultCurrencyOptions.push(
            <option key={defCurrKey} value={defCurr}>
                {defCurr}
            </option>
        );
    });

    return (
        <div className=" bg-white  ">
            <div className="p-3">
                <b>{window.translate('LOCALISATION')}</b>
                <hr />

                <div className="row mt-2">
                    <div className="col-md-3">
                        {' '}
                        <label htmlFor="locale">{window.translate('Locale')}:</label>{' '}
                    </div>
                    <div className="col-md-3">
                        <select
                            value={locale}
                            onChange={handleChange.bind(this, "locale", "string")}
                            className="custom-select my-1 mr-sm-2 "
                            id="locale"
                        >
                            <option value="">{window.translate('Nothing selected')}</option>
                            {localeOptions}
                        </select>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-3">
                        {' '}
                        <label htmlFor="defaultCurrency">{window.translate('Default currency')}:</label>{' '}
                    </div>
                    <div className="col-md-3">
                        <select
                            onChange={handleChange.bind(this, "defaultCurrency", "string")}
                            value={defaultCurrency}
                            className="custom-select my-1 mr-sm-2 "
                            id="defaultCurrency"
                        >
                            <option value="">{window.translate('default_currency')}</option>
                            {defaultCurrencyOptions}
                        </select>
                    </div>
                </div>
            </div>

            <div className="text-center p-5">
                {' '}
                <div onClick={async () => {
                    await saveFormData("locale", locale)
                    saveFormData("defaultCurrency", defaultCurrency)
                }}
                    className="btn btn-outline-primary mb-4 pointer"
                >
                    {window.translate('Save')}
                </div>{' '}
            </div>
        </div>
    );
}

export default LocaleAndCurrency;