import React, { useEffect, useState } from 'react';
import { deleteModelData } from '@data/actions/modelActions';
import rest from '@data/restWrapper';
import { setModalContent } from '@components/modal';
import Delete from '@components/confirmModal';
import PageTitle from '@components/pageTitle';
import FeatureFlag from '@components/FeatureFlag';
import countryCodes from '@data/countryCodesIndexed';
import { CompanyModel } from '@common/modelDefinition';
import translate from '@data/translations';
//@ts-ignore
import { toast } from 'react-toastify';

interface CompaniesPagedListProps {
    history: any;
}

const CompaniesPagedList = (props: CompaniesPagedListProps) => {
    const [companies, setCompanies] = useState([] as CompanyModel[]);
    const [searchTerm, setSearchTerm] = useState('');
    const [page, setPage] = useState(0);
    const [rows] = useState(500);
    const [companiesFromSearchBox, setCompaniesFromSearchBox] = useState([] as CompanyModel[]);
    let searchDebouncer: any = null;

    useEffect(() => {
        const loadData = async () => {
            getPageData();
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const getPageData = async (_page = page) => {
        const _companies = await rest('/Company?page=' + _page + '&rows=' + rows);
        setCompanies(_companies);
        setPage(_page);
    };

    const onSearch = async (e: any) => {
        clearTimeout(searchDebouncer);
        const _searchTerm = e.target.value;
        searchDebouncer = setTimeout(async () => {
            if (_searchTerm.length > 1) {
                const path = '/searchCompany?search=' + _searchTerm;
                const _companiesFromSearchBox = await rest(path);
                setCompaniesFromSearchBox(_companiesFromSearchBox);
                setSearchTerm(_searchTerm);
            } else {
                setCompaniesFromSearchBox([]);
                setSearchTerm('');
            }
        }, 400);
    };

    const deleteItem = async (itemId: number) => {
        try {
            const deletedCompany = await deleteModelData('Company', itemId);
            toast(translate("Company deleted!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
            let statecompanies = [...companies];

            statecompanies = statecompanies.filter((cust) => cust.id !== deletedCompany.id);
            setCompanies(statecompanies);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = async (companyId: number) => {
        setModalContent(
            <Delete actionForYes={deleteItem.bind(this, companyId)} />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };
    const pageDown = async () => {
        if (page > 0) {
            let _page = page - 1;
            await getPageData(_page);
        }
    };

    const pageUp = async () => {
        if (companies.length > 0) {
            let _page = page + 1;
            await getPageData(_page);
        }
    };

    const _companies = searchTerm.length > 0 ? companiesFromSearchBox : companies;
    const companiesList = _companies.map((c, index) => {
        return (
            <CompanyListLine
                key={index}
                c={c}
                index={index}
                history={props.history}
                showDeleteModal={showDeleteModal.bind(this)}
            />
        );
    });

    return (
        <div className="">
            <PageTitle />
            <div className="row ">
                <div className="d-flex col-12 ">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary mr-2"
                    >
                        {translate('GO BACK')}
                    </button>

                    <button
                        onClick={() => {
                            props.history.push('/companies/new');
                        }}
                        className="btn btn-sm btn-primary mr-2"
                        type="submit"
                    >
                        {translate('ADD NEW')}
                    </button>

                    <div className="ml-2 d-none d-sm-block">
                        <input
                            onChange={onSearch.bind(this)}
                            placeholder={translate('SEARCH FOR COMPANY (by name, town, idNumber)')}
                            type="text"
                            className="form-control form-control-sm"
                        />
                    </div>

                    <div className="ml-auto align-self-end">
                        <button onClick={pageDown.bind(this)} className="btn btn-sm btn-outline-primary mr-2">
                            {translate('prev')}
                        </button>
                        <small className="mt-1">
                            {translate('page')} {page}
                        </small>
                        <button onClick={pageUp.bind(this)} className="btn btn-sm btn-outline-primary ml-2">
                            {translate('next')}
                        </button>
                    </div>
                </div>
            </div>

            <ul className="list-group mt-3 mx-3">
                <small>
                    <li className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                        <div className="col-6 col-sm-3  elipsis">{translate("Name")}</div>
                        <div className="col-6 col-sm-3 elipsis">{translate("Address")}</div>
                        <div className="col-6 col-sm-2 ">{translate("Id")}</div>
                        <div className="col-6 col-sm-2 "> {translate("Web")}</div>
                        <div className="col-6 col-sm-2"></div>
                    </li>
                </small>
            </ul>
            
            <ul className="list-group mt-2 mx-3">{companiesList}</ul>
        </div>
    );
};

interface CompanyListLineProps {
    c: CompanyModel;
    history: any;
    showDeleteModal: Function;
    index: number;
}

const CompanyListLine = (props: CompanyListLineProps) => {
    const { c, history, showDeleteModal } = props;
    const country = c.country ? countryCodes[c.country] : null;
    const address = [];

    if (c.town) {
        address.push(c.town);
    }

    if (c.address) {
        address.push(c.address);
    }

    if (country) {
        address.push(country['alpha-3']);
    }

    return (
        <li key={'c' + c.id} className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'} style={{ userSelect:"text" }}>
            <div className="col-6 col-sm-3  elipsis">{c.name}</div>

            <div className="col-6 col-sm-3 elipsis">
                <small>
                    {address.map((a, index) => {
                        return <div key={index}>{a}</div>;
                    })}
                </small>
            </div>
            <div className="col-6 col-sm-2 ">{c.idNumber}</div>
            <div className="col-6 col-sm-2 "> {c.web}</div>
            <div className="col-6 col-sm-2 ">
                <div className="pt-2 pr-2">
                    <button
                        onClick={() => {
                            history.push('/companies/' + c.id + '/edit');
                        }}
                        className="btn btn-sm btn-outline-dark pull-right  ml-1 mb-1"
                    >
                        <i className="fa fa-edit"></i>
                    </button>
                    <FeatureFlag flag="integrityDeleteButton">
                        <button
                            onClick={() => {
                                showDeleteModal(c.id);
                            }}
                            className="btn btn-sm btn-outline-danger pull-right mb-1"
                        >
                            <i className="fa fa-trash"></i>
                        </button>
                    </FeatureFlag>
                </div>
            </div>
            
        </li>
    );
};

export default CompaniesPagedList;
