import React from 'react';
import { attributesEnum, attributesIconsEnum } from './enum';
import SingeRoomAttributes from './singleRoomAttr';

const InstallationStep3 = ({
    nextStepCallback,
    commonAttrArr,
    setCommonAttrArr,
    rooms,
    setRooms,
    singleAttrArr,
    setSingleAttrArr,
}) => {
    const [dataChange, setDataChange] = React.useState([]);
    const [nextStep, setNextStep] = React.useState(false);

    React.useEffect(() => {}, [dataChange]);

    return (
        <div className=" bg-white  ">
            <div className="p-3 text-center">
                <b>{window.translate('Initial setup required')}</b>
            </div>
            <hr />
            {nextStep === false ? (
                <>
                    <div className="p-3 text-center">
                        {window.translate('Mark common attributes for all rooms')}
                        <hr />
                    </div>
                    <div className="row mt-2 p-3">
                        {Object.keys(attributesEnum).map((a, key) => {
                            const attr = attributesEnum[a],
                                attribute = Number(a);
                            const checkboxIcon = commonAttrArr.includes(attribute) ? (
                                <i className="fa fa-2x fa-check-square-o pull-left" />
                            ) : (
                                <i className="fa fa-2x fa-square-o pull-left" />
                            );

                            const className = commonAttrArr.includes(attribute) ? 'bg-light' : '';

                            return (
                                <div
                                    onClick={async () => {
                                        let _commonAttrArr = commonAttrArr;
                                        if (!_commonAttrArr.includes(attribute)) {
                                            _commonAttrArr.push(attribute);
                                            setCommonAttrArr(_commonAttrArr);
                                            setDataChange(!dataChange);
                                        } else {
                                            _commonAttrArr = _commonAttrArr.filter((attr) => attr !== attribute);
                                            setCommonAttrArr(_commonAttrArr);
                                            setDataChange(!dataChange);
                                        }
                                    }}
                                    className={'pointer col-4 '}
                                    key={key}
                                >
                                    <div className={'border rounded m-1 p-2 ' + className}>
                                        <div className="row">
                                            <div className="col-2">
                                                <img
                                                    style={{ height: '25px', width: '25px' }}
                                                    className="mr-3"
                                                    src={attributesIconsEnum[a]}
                                                    alt="Img"
                                                />
                                            </div>
                                            <div className="col-8">{window.translate(attr)}</div>
                                            <div className="col-2 pull-right">{checkboxIcon}</div>
                                        </div>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                    <div className="text-center p-5">
                        <div
                            onClick={async (e) => {
                                e.preventDefault();
                                let _rooms = { ...rooms };
                                Object.keys(_rooms).forEach((floor) => {
                                    _rooms[floor].forEach((r) => {
                                        r.roomAttributes = commonAttrArr;
                                    });
                                });
                                setRooms(_rooms);
                                setNextStep(true);
                            }}
                            className="btn btn-outline-primary mb-4 pointer"
                        >
                            {window.translate('SAVE AND NEXT')}
                        </div>{' '}
                    </div>
                </>
            ) : (
                <SingeRoomAttributes
                    nextStepCallback={nextStepCallback}
                    rooms={rooms}
                    setRooms={setRooms}
                    commonAttrArr={commonAttrArr}
                    singleAttrArr={singleAttrArr}
                    setSingleAttrArr={setSingleAttrArr}
                />
            )}
        </div>
    );
};

export default InstallationStep3;
