import React from 'react';
import { branch } from 'baobab-react/higher-order';
import moment from 'moment/min/moment-with-locales';
import { alarmAndEventCSSStyling, userFrendlyAlarmAndEventsNames } from '@data/SRCAlarmAndAlertController';
import FeatureFlag, { isFeatureEnabled } from '../../components/FeatureFlag';
import EventResolutionModal from './eventRespondModal';
import { setModalContent } from '@components/modal';

const SituationCenter = (props) => {
    const showEventResolutionModal = async (ev, e) => {
        try {
            setModalContent(
                <EventResolutionModal event={ev} />,
                window.translate('RESPOND TO EVENT'),
                true,
                'modal-lg'
            );
        } catch (err) {
            console.log(err);
        }
    };

    // const testHIGHBitAtPosition = (sourceWord, position) => {
    //     const res = (sourceWord & (1 << (15 - position))) > 0;
    //     return res;
    // };

    const composeAlertListLine = (ev, classWrapper = 'bg-info text-white p-2  rounded pointer mt-2') => {
        const thisRoomEvents = props.eventTickets.filter((r) => r.roomInfoId === ev.roomInfoId).map((r) => r.ruleName);

        var currentRoomMode = '';
        if (
            [
                'alarm_flood',
                'customAlarm_minibarNotClosed',
                'alarm_doNotDisturb',
                'alarm_smoke',
                'alarm_fire',
                'alarm_cleaningService',
            ].includes(ev.ruleName)
        )
            if (thisRoomEvents.includes('alarm_ecoRoomMode')) {
                currentRoomMode = 'ECO';
            } else if (thisRoomEvents.includes('alarm_comfortRoomMode')) {
                currentRoomMode = 'COMFORT';
            } else if (thisRoomEvents.includes('alarm_preComfortRoomMode')) {
                currentRoomMode = 'PRE-COMFORT';
            } else if (thisRoomEvents.includes('alarm_cleaningRoomMode')) {
                currentRoomMode = 'CLEANING';
            }

        let showModalDialog =
            ev.ruleName === 'alarm_cleaningService' ||
            ev.ruleName === 'alarm_doNotDisturb' ||
            ev.ruleName === 'alarm_roomService';
        let pointerCSS = showModalDialog ? ' pointer ' : '';
        let roomInfo = props.roomInfo.find((r) => r.id === ev.roomInfoId);
        if (roomInfo === undefined) {
            return null;
        }

        return (
            <div
                onClick={showEventResolutionModal.bind(this, ev)}
                className={
                    alarmAndEventCSSStyling[ev.ruleName] + pointerCSS + '  animated faster fadeIn mt-2' || classWrapper
                }
                key={ev.id}
            >
                <small className="pull-right">{moment(ev.openingTimestamp).format('HH:mm')} </small>
                <b> {roomInfo.name} </b>

                <div> {window.translate(userFrendlyAlarmAndEventsNames[ev.ruleName])}</div>
                <div>
                    {' '}
                    <small>{ev.note}</small>{' '}
                </div>
                <small className="pull-right">{moment(ev.openingTimestamp).fromNow()}</small>
                <small className="pull-left">{currentRoomMode}</small>
                <div className="clear"></div>
            </div>
        );
    };

    if (!isFeatureEnabled('situationCenter')) {
        return window.translate('Situation center is disabled');
    }

    const alarms = props.eventTickets.filter((e) => {
        return (
            (e.closingTimestamp === null || e.closingTimestamp === undefined) &&
            (e.ruleName === 'alarm_sos' ||
                e.ruleName === 'alarm_fire' ||
                e.ruleName === 'alarm_smoke' ||
                e.ruleName === 'alarm_flood' ||
                e.ruleName === 'customAlarm_minibarNotClosed')
        );
    });

    const roomServices = props.eventTickets.filter((e) => {
        return e.ruleName === 'alarm_cleaningService' || e.ruleName === 'alarm_roomService';
    });

    const doNotDisturb = props.eventTickets.filter((e) => {
        return e.ruleName === 'alarm_doNotDisturb';
    });

    const cleaningTracker = props.eventTickets.filter((e) => {
        return e.ruleName === 'alarm_cleaningRoomMode';
    });

    return (
        <div>
            <div className="row mt-2">
                <div className="col-md-3 ">
                    <h5
                        onClick={() => {
                            if (isFeatureEnabled('sosGroupsAndLogs')) {
                                props.history.push('/report/sosResponseLog');
                            }
                        }}
                        className="text-center bg-white text-black p-2 animated rounded pointer "
                    >
                        {window.translate('Alarms')}
                        <FeatureFlag flag="sosGroupsAndLogs">
                            {' '}
                            <i className="fa fa-edit" />
                        </FeatureFlag>
                    </h5>

                    {alarms.map((ev) => composeAlertListLine(ev))}
                </div>
                <div className="col-md-3 ">
                    <h5 className="text-center bg-white text-black p-2 animated rounded ">
                        {window.translate('Room Service')}
                    </h5>
                    {roomServices.map((ev) => composeAlertListLine(ev))}
                </div>
                <div className="col-md-3 ">
                    <h5 className="text-center bg-white text-black p-2 animated rounded ">
                        {window.translate('Do not disturb')}
                    </h5>
                    {doNotDisturb.map((ev) => composeAlertListLine(ev))}
                </div>
                <div className="col-md-3 ">
                    <h5 className="text-center bg-white text-black p-2 animated rounded ">
                        {window.translate('Cleaning tracker')}
                    </h5>
                    {cleaningTracker.map((ev) => composeAlertListLine(ev))}
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        roomInfo: ['model', 'RoomInfo'],
        eventTickets: ['model', 'RoomEventTicket'],
    },
    SituationCenter
);
