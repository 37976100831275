import React, { Component } from 'react';
import './modal.css';

class InlineModal extends Component {
    render() {
        let { show = false, width = 'md' } = this.props;
        let style = show ? { display: 'block', overflowY: 'auto', paddingTop: '40px' } : { display: 'none' };

        return (
            <div id="modal-overlay" style={style}>
                <div className="modal fade show" tabIndex="-1" style={style}>
                    <div className={'modal-dialog modal-' + width}>
                        <div className="modal-content">
                            {this.props.header ? (
                                <div className="modal-header">
                                    <h5 className="modal-title">{this.props.header}</h5>
                                </div>
                            ) : null}

                            <div className="modal-body">{this.props.children}</div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default InlineModal;
