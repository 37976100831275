import * as React from 'react';
import CustomerForm from '@pages/customers/form';
import CompanyForm from '@pages/company/form';
import { setModalContent } from '@components/modal';
import { translate } from '@data/translations';
import { CompanyModel, CustomerModel } from '@common/modelDefinition';
import { Reservation /* ReservationController */ } from '../resController';
import { searchCustomer, searchCompany } from '../api';

var searchDebouncer: NodeJS.Timeout = setTimeout(() => {}, 0);

interface CustomersAndCompaniesInterface {
    customer: CustomerModel | null;
    company: CompanyModel | null;
    history: any;
    customerSelect: (customer: CustomerModel) => void;
    companySelect: (company: CompanyModel) => void;
    docScanner?: boolean;
    hideOnSelected?: boolean;
    showCompany?: boolean;
    //className?: string;
    customerOrCompanyInvalid?: boolean;
    deleteCompany?: (company: CompanyModel) => void;
    deleteCustomer?: (customer: CustomerModel) => void;
    reservation?: Reservation;
}

const CustomersAndCompanies = (props: CustomersAndCompaniesInterface) => {
    const { customerSelect, companySelect, docScanner = false } = props;
    const {
        history,
        company,
        // className,
        customer,
        customerOrCompanyInvalid = false,
        showCompany = true,
        hideOnSelected = true,
        reservation = null,
    } = props;

    // const ctrl = new ReservationController();

    const [customers, setCustomers] = React.useState<CustomerModel[]>([]);
    const [companies, setCompanies] = React.useState<CompanyModel[]>([]);
    const [customerSearch, setCustomerSearch] = React.useState('');
    const [companySearch, setCompanySearch] = React.useState('');

    const onCustomerSearch = (e: any) => {
        clearTimeout(searchDebouncer);
        const searchString = e.target.value;
        setCustomerSearch(searchString);
        searchDebouncer = setTimeout(async () => {
            const result = await searchCustomer(searchString);
            setCustomers(result);
        }, 500);
    };

    const onCompanySearch = (e: any) => {
        clearTimeout(searchDebouncer);
        const searchString = e.target.value;
        setCompanySearch(searchString);
        searchDebouncer = setTimeout(async () => {
            const result = await searchCompany(searchString);
            setCompanies(result);
        }, 500);
    };

    const onSelectCompany = (company: CompanyModel) => {
        companySelect(company);
        setCompanies([]);
        setCompanySearch('');
    };

    const onSelectCustomer = (customer: CustomerModel) => {
        customerSelect(customer);
        setCustomers([]);
        setCustomerSearch('');
    };

    const addCompany = () => {
        setModalContent(
            <CompanyForm
                history={history}
                callback={(company: CompanyModel) => {
                    companySelect(company);
                }}
                customHeader={' '}
                cancelHandler={() => {}}
                showPageTitle={false}
            />,
            translate('Company'),
            false,
            'modal-xl'
        );
    };

    const addCustomer = () => {
        setModalContent(
            <CustomerForm
                history={history}
                callback={(customer: CustomerModel) => {
                    customerSelect(customer);
                }}
                customHeader={' '}
                cancelHandler={() => {}}
                showPageTitle={false}
                reservation={reservation}
            />,
            translate('Guest'),
            false,
            'modal-xl'
        );
    };

    if (hideOnSelected) {
        if (showCompany === true && (company !== null || customer !== null)) {
            return null;
        }
    }

    const comapniesSearchResult = companies.map((c: CompanyModel, index: number) => {
        const name =
            c.name && c.name !== '' ? (
                <span className="mr-2">
                    <i className="fa fa-user mr-1" aria-hidden="true"></i>
                    {c.name}
                </span>
            ) : null;
        const town =
            c.town && c.town !== '' ? (
                <span className="mr-2">
                    <i className="fa fa-building mr-1" aria-hidden="true"></i> {c.town}
                </span>
            ) : null;
        const web =
            c.web && c.web !== '' ? (
                <span className="elipsis">
                    <i className="fa fa-globe mr-1" aria-hidden="true"></i>
                    {c.web}
                </span>
            ) : null;

        return (
            <li key={'c' + c.id + index} className="list-group-item">
                <button
                    onClick={onSelectCompany.bind(this, c)}
                    className="btn btn-sm btn-outline-primary pull-left mr-3"
                >
                    {translate('Select')}
                </button>
                <div className="mt-1 pull-left">
                    <small>
                        {name}
                        {town}
                        {web}
                    </small>
                </div>
            </li>
        );
    });

    const customersSearchResult = customers.map((c: CustomerModel, index: number) => {
        const doc =
            c.documentId && c.documentId !== '' ? (
                <span className="mr-2">
                    <b>
                        <i className="fa fa-id-card-o mr-1" />
                        {c.documentId}
                    </b>
                </span>
            ) : null;
        const name =
            c.lastName && c.lastName !== '' ? (
                <span className="mr-2">
                    <i className="fa fa-user mr-1" aria-hidden="true"></i>
                    {c.firstName} {c.lastName}
                </span>
            ) : null;
        const phone =
            c.phoneOrMobile && c.phoneOrMobile !== '' ? (
                <span className="mr-2">
                    <i className="fa fa-phone mr-1 ml-2" aria-hidden="true"></i>
                    {c.phoneOrMobile}
                </span>
            ) : null;
        const email =
            c.email && c.email !== '' ? (
                <span className="elipsis">
                    <i className="fa fa-envelope-o mr-1 ml-2" aria-hidden="true"></i> {c.email}
                </span>
            ) : null;

        return (
            <li key={'c' + c.id + index} className="list-group-item">
                <button
                    onClick={onSelectCustomer.bind(this, c)}
                    className="btn btn-sm btn-outline-primary pull-left mr-3"
                >
                    {translate('Select')}
                </button>
                <div className="mt-1">
                    <small>
                        {doc}
                        {name}
                        {email}
                        {phone}
                    </small>
                </div>
            </li>
        );
    });

    let inputFieldCss = 'form-control form-control-sm ';

    if (showCompany && customerOrCompanyInvalid) {
        inputFieldCss = inputFieldCss + ' is-invalid';
    }

    return (
        <div>
            <div className="row">
                <div className="col-md-6">
                    {customer ? (
                        <>
                            {`${customer.firstName} ${customer.lastName}`}
                            {props.deleteCustomer ? (
                                <button
                                    className="ml-2 btn btn-sm btn-outline-secondary"
                                    onClick={() => {
                                        if (props.deleteCustomer) {
                                            props.deleteCustomer(customer);
                                        }
                                    }}
                                >
                                    <i className="fa fa-times" />
                                </button>
                            ) : null}
                        </>
                    ) : (
                        <div className="row">
                            <div className={docScanner ? 'col-7 ' : 'col-9 '}>
                                <div className="input-group">
                                    <div className="input-group-prepend">
                                        <span className="input-group-text text-primary" title="Search for customers">
                                            <i className="fa fa-info-circle pointer" aria-hidden="true"></i>
                                        </span>
                                    </div>

                                    <input
                                        value={customerSearch}
                                        onChange={onCustomerSearch}
                                        className={inputFieldCss}
                                        type="text"
                                        placeholder={translate('SEARCH CUSTOMERS')}
                                    />
                                </div>
                            </div>
                            <div className={'col-2'}>
                                <button
                                    onClick={(e) => {
                                        e.preventDefault();
                                        addCustomer();
                                    }}
                                    className={'btn btn-outline-primary btn-sm mr-1'}
                                >
                                    <i className="fa fa-plus mr-1" />
                                </button>
                            </div>
                        </div>
                    )}
                </div>
                {showCompany ? (
                    <div className="col-md-6 mt-2 mt-md-0">
                        {company ? (
                            <>
                                {company.name}
                                {props.deleteCompany ? (
                                    <button
                                        className="ml-2 btn btn-sm btn-outline-secondary"
                                        onClick={() => {
                                            if (props.deleteCompany) {
                                                props.deleteCompany(company);
                                            }
                                        }}
                                    >
                                        <i className="fa fa-times" />
                                    </button>
                                ) : null}
                            </>
                        ) : (
                            <div className="row">
                                <div className="col-10 ">
                                    <div className="input-group">
                                        <div className="input-group-prepend">
                                            <span
                                                className="input-group-text text-primary"
                                                title="HINT: Use % before first letter of query for more results."
                                            >
                                                <i className="fa fa-info-circle pointer" aria-hidden="true"></i>
                                            </span>
                                        </div>
                                        <input
                                            value={companySearch}
                                            onChange={onCompanySearch}
                                            className={inputFieldCss}
                                            type="text"
                                            placeholder={translate('SEARCH COMPANIES')}
                                        />
                                    </div>
                                </div>
                                <div className="col-2">
                                    <button onClick={addCompany} className={'btn btn-outline-primary btn-sm mr-1'}>
                                        <i className="fa fa-plus mr-1" />
                                    </button>
                                </div>
                            </div>
                        )}
                    </div>
                ) : null}
            </div>

            <ul className="list-group list-group-flush mt-2">
                {showCompany ? comapniesSearchResult : null}
                {customersSearchResult}
            </ul>
        </div>
    );
};

export { CustomersAndCompanies };
