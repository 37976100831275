import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import PageLoader from '@components/loaders/loader1';
import Sidebar from './sidebar';
import FeatureFlag, { isFeatureEnabled } from '@components/FeatureFlag';
import TimelineView from './reservationsTimeline';
import HwRoomView from './hwRoomView';
import Toolbar from './toolbar';
import baobabTree from '@data/state';
import ToolbarReservationMode from './toolbarReservationMode';
import SidebarToolbar from './sidebarToolbar';
import ActivityView from './activityView';
import { getResGuests } from '@pages/reservations2/api';

function Dashboard(props) {
    const [availableFromTimestamp, setAvailableFromTimestamp] = useState(null);
    const [availableToTimestamp, setAvailableToTimestamp] = useState(null);
    const [stayInDays, setStayInDays] = useState(null);
    const [groupReservations, setGroupReservations] = useState(false);
    const [searchTerm, setSearchTerm] = useState('');
    const [numberOfBeds, setNumberOfBeds] = useState('');
    const [numberOfSpareBeds, setNumberOfSpareBeds] = useState('');
    const [tagsFilter, setTagsFilter] = useState([]);
    const [filterOutRoomsById, setFilterOutRoomsById] = useState([]);
    const [selectedRoomsForReservations, setSelectedRoomsForReservations] = useState([]);
    const [attributesFilter, setAttributesFilter] = useState([]);
    const [attributesFilteringType, setAttributesFilteringType] = useState(true);
    const [showOccupiedRooms, setShowOccupiedRooms] = useState(false);
    const [cleaningStatusesFilter, setCleaningStatusesFilter] = useState([]);
    const [reservations_, setReservations] = useState(props.reservations);

    useEffect(() => {//dodano da imamo sve goste koji su na jednoj rezervaciji, a ne samo nositelje
        const loadData = async () => {
            const resFinal = []
            let _reservations = [...props.reservations]
            const guestPromises = _reservations.map(async (r) => {
                return getResGuests(r.id);
            });
            const guestRefs = await Promise.all(guestPromises);

            let index = 0;
            for (const ref of guestRefs) {
                let reservation = {..._reservations[index]};
                reservation.Guests = ref.customers;
                resFinal.push(reservation)
                index++;
            }
            setReservations(resFinal)
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.reservations]);



    const toggleTagFilter = (tag) => {
        if (tag === null) {
            setTagsFilter([]);
        } else {
            let _tagsFilter = [...tagsFilter];
            if (_tagsFilter.includes(tag)) {
                _tagsFilter = _tagsFilter.filter((t) => t !== tag);
            } else {
                _tagsFilter.push(tag);
                _tagsFilter = _tagsFilter.filter((t) => t === tag);
            }
            setTagsFilter(_tagsFilter);
        }
    };

    const toggleCleaningStatusesFilter = (clStatus) => {
        if (clStatus === null) {
            setCleaningStatusesFilter([]);
        } else {
            let _cleaningStatusesFilter = [...cleaningStatusesFilter];
            if (_cleaningStatusesFilter.includes(clStatus)) {
                _cleaningStatusesFilter = _cleaningStatusesFilter.filter((t) => t !== clStatus);
            } else {
                _cleaningStatusesFilter.push(clStatus);
                _cleaningStatusesFilter = _cleaningStatusesFilter.filter((t) => t === clStatus);
            }
            setCleaningStatusesFilter(_cleaningStatusesFilter);
        }
    };

    const toggleGroupReservations = (forcedValue = undefined) => {
        if (forcedValue !== undefined) {
            setGroupReservations(forcedValue);
        } else {
            setGroupReservations(!groupReservations);
        }
    };

    const setNumOfBeds = (e) => {
        if (e === '') {
            setNumberOfBeds('');
        } else if (e.target.value === '') {
            setNumberOfBeds('');
        } else {
            if (e.target.value === '0') {
                setNumberOfBeds('');
            } else {
                setNumberOfBeds(Number(e.target.value));
            }
        }
    };

    const setNumOfSpareBeds = (e) => {
        if (e === '') {
            setNumberOfSpareBeds('');
        } else if (e.target.value === '') {
            setNumberOfSpareBeds('');
        } else {
            if (e.target.value === '0') {
                setNumberOfSpareBeds('');
            } else {
                setNumberOfSpareBeds(Number(e.target.value));
            }
        }
    };

    const _setSearchTerm = (e) => {
        setSearchTerm(e.target.value);
    };

    const toggleShowComponent = (componentName, forceShow = undefined, e) => {
        let temp = _.clone(props.uiStates.showComponents);

        if (props.uiStates.showComponents.includes(componentName)) {
            temp = temp.filter((t) => t !== componentName);
        } else {
            temp.push(componentName);
        }

        if (forceShow !== undefined) {
            if (forceShow === true) {
                temp.push(componentName);
                temp = _.uniq(temp);
            } else if (forceShow === false) {
                temp = temp.filter((t) => t !== componentName);
            }
        }

        baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(temp);
    };

    const setAvailableFromAndToTimestamps = (availableFromTimestamp, _availableToTimestamp, stayInDays) => {
        const availableToTimestamp_ = _availableToTimestamp - 8 * 60 * 60 * 1000; //add some tollerance on filtering since this will always be at 12 o clock (noon)
        setAvailableFromTimestamp(availableFromTimestamp);
        setAvailableToTimestamp(availableToTimestamp_);
        setStayInDays(stayInDays);
    };

    const toggleSelectRoomForReservation = (roomInfoId) => {
        if (roomInfoId === null) {
            setSelectedRoomsForReservations([]);
        } else {
            let _selectedRoomsForReservations = [...selectedRoomsForReservations];
            if (_selectedRoomsForReservations.includes(roomInfoId)) {
                //remove it
                _.remove(_selectedRoomsForReservations, (r) => r === roomInfoId);
            } else {
                _selectedRoomsForReservations.push(roomInfoId);
            }
            setSelectedRoomsForReservations(_selectedRoomsForReservations);
        }
    };

    const handleAttributesSelect = (values) => {
        if (values === null) {
            setAttributesFilter([]);
        } else {
            const attrFilter = values.map((v) => v.value);
            setAttributesFilter(attrFilter);
        }
    };

    const filterRoomsByAttribute = (rooms) => {
        let _attributesFilter = [...attributesFilter];
        let filteredRooms = rooms.map((r) => {
            if (_attributesFilter.length > 0) {
                let eject = false;
                _attributesFilter.forEach((att) => {
                    if (r.roomAttributes !== null) {
                        if (!JSON.parse(r.roomAttributes).includes(att)) {
                            eject = true;
                            return true;
                        }
                    } else {
                        eject = true;
                    }
                });
                if (eject) {
                    return null;
                } else {
                    return r;
                }
            } else {
                return r;
            }
        });
        return filteredRooms;
    };

    const filterRoomsByCleaningStatuses = (rooms, cleaningStatusHeaders) => {
        let _cleaningStatusesFilter = [...cleaningStatusesFilter]

        let filteredRooms = rooms.map((r) => {
            if (_cleaningStatusesFilter.length > 0) {
               let statusObj = cleaningStatusHeaders?.CleaningStatuses?.find(sh => {return sh?.RoomInfo?.id === r?.id})
               let cleaningStatus = statusObj?.cleaningStatus
               if(cleaningStatus === _cleaningStatusesFilter[0]){
                   return r
               } else {
                   return null
               }
            } else {
                return r;
            }
        });
        return filteredRooms;

    }

    const filterRoomsByAnyAttribute = (rooms) => {
        let _attributesFilter = [...attributesFilter];
        let filteredRooms = [];
        if (_attributesFilter.length > 0) {
            rooms.forEach((r) => {
                const roomAttributes = JSON.parse(r.roomAttributes) ? JSON.parse(r.roomAttributes) : [];
                _attributesFilter.forEach((a) => {
                    if (roomAttributes.includes(a)) {
                        if (!filteredRooms.map((r) => r.id).includes(r.id)) {
                            filteredRooms.push(r);
                        }
                    }
                });
            });
        } else {
            filteredRooms = rooms;
        }
        return filteredRooms;
    };

    const { parsedRoomInfo } = props,
        roomsMap = {};

    for (const r of parsedRoomInfo) {
        roomsMap[r.id] = r;
    }

    if (props.roomsByTags.length === 0) {
        return <PageLoader />;
    }

    let showOutOfServiceLegend = false;
    //in first step, filter all rooms by tag....
    let filteredRooms = parsedRoomInfo.map((r) => {
        if (tagsFilter.length > 0) {
            let eject = false;
            tagsFilter.forEach((t) => {
                if (!r.tags.includes(t)) {
                    eject = true;
                    return true;
                }
            });
            if (eject) {
                return null;
            } else {
                return r;
            }
        } else {
            return r;
        }
    });

    filteredRooms = filteredRooms.filter((i) => (i ? i.availableForRent : null));

    if(props.cleaningStatusHeaders[0]){
        filteredRooms = filterRoomsByCleaningStatuses(filteredRooms, props.cleaningStatusHeaders[0]);
    }

    //implement room name search
    filteredRooms = filteredRooms.map((roomInfo) => {
        if (roomInfo && roomInfo.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            return roomInfo;
        } else {
            return null;
        }
    });

    filteredRooms = filteredRooms.filter((f) => f !== null);
    //bed search params...
    if (numberOfBeds) {
        filteredRooms = filteredRooms.filter((r) => r.bedCount === Number(numberOfBeds));
    }
    
    if(numberOfSpareBeds){
        filteredRooms = filteredRooms.filter((r)=>r.spareBedCount === Number(numberOfSpareBeds))
    }

    filteredRooms = filteredRooms.filter((f) => f !== null);

    //aditional room removal....
    if (filterOutRoomsById.length > 0) {
        filteredRooms = !showOccupiedRooms
            ? filteredRooms.filter((r) => !filterOutRoomsById.includes(r.id))
            : filteredRooms;
    }

    //if search term was a card code number
    let cardNumberSearch = parseInt(searchTerm, 10);
    if (!_.isNaN(cardNumberSearch) && _.isNumber(cardNumberSearch)) {
        let reservations = reservations_.filter((r) => {
            return (
                (r.guest1CodeCopy === cardNumberSearch ||
                    r.guest2CodeCopy === cardNumberSearch ||
                    r.guest3CodeCopy === cardNumberSearch) &&
                Number(r.statusEnum) === 2
            ); //checkedIn
        });
        if (reservations.length > 0) {
            filteredRooms = []; //RESETING filteredRooms
        }
        reservations.forEach((reservation) => {
            let room = props.parsedRoomInfo.find((r) => r.id === reservation.roomInfoId);
            if (room) {
                filteredRooms.push(room);
            }
        });
    } else if (searchTerm) {
        const filteredRoomMap = {};
        for (const r of filteredRooms) {
            filteredRoomMap[r.id] = r.id;
        }

        reservations_.forEach((res) => {

        for (const guest of res.Guests) {
            if (
                guest &&
                guest.firstName &&
                guest.firstName.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1
            ) {
                if (!filteredRoomMap[res.roomInfoId]) {
                    filteredRoomMap[res.roomInfoId] = roomsMap[res.roomInfoId];
                    filteredRooms.push(roomsMap[res.roomInfoId]);
                }
            }

            if (
                guest &&
                guest.lastName &&
                guest.lastName.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1
            ) {
                if (!filteredRoomMap[res.roomInfoId]) {
                    filteredRoomMap[res.roomInfoId] = roomsMap[res.roomInfoId];
                    filteredRooms.push(roomsMap[res.roomInfoId]);
                }
            }
        }
            if (
                res.Company &&
                res.Company.name &&
                res.Company.name.toLowerCase().lastIndexOf(searchTerm.toLowerCase()) !== -1
            ) {
                if (!filteredRoomMap[res.roomInfoId]) {
                    filteredRoomMap[res.roomInfoId] = roomsMap[res.roomInfoId];
                    filteredRooms.push(roomsMap[res.roomInfoId]);
                }
            }
        });
    }

    if (attributesFilteringType) {
        filteredRooms = filterRoomsByAnyAttribute(filteredRooms);
    } else {
        filteredRooms = filterRoomsByAttribute(filteredRooms);
    }

    //remove all nulls
    filteredRooms = filteredRooms.filter((f) => f !== null);

    filteredRooms = _.orderBy(filteredRooms, ['name'], ['asc']);

    var { showComponents } = props.uiStates;
    if (
        (showComponents.includes('timeline') && !isFeatureEnabled('reservationsTimelineDashboard')) ||
        props.loggedUser.role === 'hwAdmin'
    ) {
        showComponents = ['tiles'];
    }

    const state = {
        availableFromTimestamp,
        availableToTimestamp,
        stayInDays,
        groupReservations,
        searchTerm,
        numberOfBeds,
        numberOfSpareBeds,
        tagsFilter,
        filterOutRoomsById,
        selectedRoomsForReservations,
        attributesFilter,
        attributesFilteringType,
        showOccupiedRooms,
        cleaningStatusesFilter
    };

    return (
        <div className="">
            <Toolbar
                showComponents={showComponents}
                history={props.history}
                {...state}
                setNumberOfBeds={setNumOfBeds.bind(this)}
                setNumberOfSpareBeds={setNumOfSpareBeds.bind(this)}
                setHideRoomByIdFilter={(array) => {
                    setFilterOutRoomsById(array);
                }}
                toggleShowComponent={toggleShowComponent.bind(this)}
                toggleTagFilter={toggleTagFilter.bind(this)}
                setAvailableFromAndToTimestamps={setAvailableFromAndToTimestamps.bind(this)}
                handleAttributesSelect={handleAttributesSelect.bind(this)}
                toggleAttributesCheck={() => setAttributesFilteringType(!attributesFilteringType)}
                setSearchTerm={_setSearchTerm.bind(this)}
                tagsFilter={tagsFilter}
                attributesFilter={attributesFilter}
                cleaningStatusesFilter={cleaningStatusesFilter}
                setCleaningStatusesFilter={setCleaningStatusesFilter}
                toggleCleaningStatusesFilter={toggleCleaningStatusesFilter.bind(this)}
            />

            <div className="row unselectable">
                <div className="col-sm-8 col-md-9 col-lg-10 ">
                    {showComponents.includes('timeline') ? (
                        <FeatureFlag flag="reservationsTimelineDashboard">
                            <ToolbarReservationMode
                                selectedRoomsForReservations={selectedRoomsForReservations}
                                toggleSelectRoomForReservation={toggleSelectRoomForReservation.bind(this)}
                                toggleGroupReservations={toggleGroupReservations.bind(this)}
                                groupReservations={groupReservations}
                                filterOutRoomsById={filterOutRoomsById}
                                showComponents={showComponents}
                                toggleShowComponent={toggleShowComponent.bind(this)}
                                setNumberOfBeds={setNumOfBeds.bind(this)}
                                setNumberOfSpareBeds={setNumOfSpareBeds.bind(this)}
                                setHideRoomByIdFilter={(array) => {
                                    setFilterOutRoomsById(array);
                                }}
                                setAvailableFromAndToTimestamps={setAvailableFromAndToTimestamps.bind(this)}
                                toogleOccupiedRooms={() => setShowOccupiedRooms(!showOccupiedRooms)}
                                {...state}
                                {...props}
                            />
                        </FeatureFlag>
                    ) : null}

                    {showComponents.includes('tiles') ? (
                        <div className="clear w-100" style={{ overflowX: 'auto' }}>
                            <HwRoomView rooms={filteredRooms} {...props} />
                        </div>
                    ) : null}

                    {
                        showComponents.includes('activity') ? (
                            <FeatureFlag flag="activityDashboard">
                                <ActivityView
                                    history={props.history}
                                    reservations={reservations_}
                                    rooms={filteredRooms}
                                />
                            </FeatureFlag>
                        ) : null
                        //activity
                    }

                    {showComponents.includes('timeline') ? (
                        <div className="mb-1 clear">
                            <TimelineView
                                selectedRoomsForReservations={selectedRoomsForReservations}
                                showComponents={showComponents}
                                availableFromTimestamp={availableFromTimestamp}
                                availableToTimestamp={availableToTimestamp}
                                numberOfBeds={numberOfBeds}
                                numberOfSpareBeds={numberOfSpareBeds}
                                history={props.history}
                                reservations={reservations_}
                                toggleSelectRoomForReservation={toggleSelectRoomForReservation.bind(this)}
                                groupReservations={groupReservations}
                                rooms={filteredRooms}
                            />
                        </div>
                    ) : null}
                </div>
                <div className="col-sm-4 mt-2 mt-md-0 col-md-3 col-lg-2  ">
                    <Sidebar
                        {...props}
                        searchTerm={searchTerm}
                        rooms={filteredRooms}
                        availableFromTimestamp={availableFromTimestamp}
                        availableToTimestamp={availableToTimestamp}
                    >
                        <SidebarToolbar setSearchTerm={_setSearchTerm.bind(this)} showComponents={showComponents} />
                    </Sidebar>
                </div>
            </div>

            {showOutOfServiceLegend ? (
                <div className="text-white mt-3 ml-1">
                    <small>(*) - {window.translate('OUT OF SERVICE')}</small>
                </div>
            ) : null}
        </div>
    );
}

export default branch(
    {
        reservations: ['model', 'Reservation'],
        roomsByTags: ['monkeys', 'parsedAndTagedRoomInfo'],
        parsedRoomInfo: ['monkeys', 'parsedRoomInfo'],
        smartRoomControllers: ['model', 'SmartRoomController'],
        uiStates: ['uiStates', 'dashboard'],
        loggedUser: ['authTokenInfo', 'user'],
        cleaningStatusHeaders: ['monkeys', 'cleaningStatusHeadersWithCleaningStatuses']

        // priceListItems: ["model","PriceListItem"]
    },
    Dashboard
);
