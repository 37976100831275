import React from 'react';
import _ from 'lodash';

const KeyboardComp = (props) => {
    const { pinCode, setPinCode, setShowKeyboard, checkPinCode, type } = props;

    const changePinCode = (num) => {
        let _pinCode = _.clone(pinCode);
        _pinCode = _pinCode + num;
        setPinCode(_pinCode);
    };

    const changePosItemAmount = (num) => {
        let _amount = pinCode;
        _amount = '' + _amount + num;
        _amount = (_amount.indexOf(".") >= 0) ? (_amount.substr(0, _amount.indexOf(".")) + _amount.substr(_amount.indexOf("."), 3)) : _amount;
        setPinCode(_amount);
    };

    return (
        <div className="border text-center p-2">
            <div className="row p-2">
                {[7, 8, 9].map((n, key) => {
                    return (
                        <div
                            key={key}
                            className={'col-4 pointer ' + (n === 9 ? '' : 'border-right')}
                            onClick={() => (type ? changePosItemAmount(n) : changePinCode(n))}
                        >
                            {n}
                        </div>
                    );
                })}
            </div>
            <div className="row p-2">
                {[4, 5, 6].map((n, key) => {
                    return (
                        <div
                            key={key}
                            className={'col-4 pointer ' + (n === 6 ? '' : 'border-right')}
                            onClick={() => (type ? changePosItemAmount(n) : changePinCode(n))}
                        >
                            {n}
                        </div>
                    );
                })}
            </div>
            <div className="row p-2">
                {[1, 2, 3].map((n, key) => {
                    return (
                        <div
                            key={key}
                            className={'col-4 pointer ' + (n === 3 ? '' : 'border-right')}
                            onClick={() => (type ? changePosItemAmount(n) : changePinCode(n))}
                        >
                            {n}
                        </div>
                    );
                })}
            </div>
            <div className="row p-2">
                <div
                    className="col-4 border-right pointer"
                    onClick={() => {
                        let _pinCode = _.clone(pinCode);
                        _pinCode = type ? _pinCode.toString().slice(0, -1) : _pinCode.slice(0, -1);
                        setPinCode(_pinCode);
                    }}
                >
                    <b>C</b>
                </div>
                <div
                    className="col-4 border-right pointer"
                    onClick={() => (type ? changePosItemAmount(0) : changePinCode(0))}
                >
                    0
                </div>
                {type ? (
                    <div
                        className="col-4 pointer"
                        onClick={(e) => {
                            let _amount = pinCode;
                            _amount = _amount.includes('.') ? _amount : _amount += '.';
                            setPinCode(_amount);
                        }}
                    >
                        .
                    </div>
                ) : (
                    <div
                        className="col-4 pointer"
                        onClick={() => {
                            setShowKeyboard(false);
                            checkPinCode();
                        }}
                    >
                        <i className="fa fa-check" aria-hidden="true"></i>
                    </div>
                )}
            </div>
        </div>
    );
};

export default KeyboardComp;
