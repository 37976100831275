import React, { useState, useEffect } from 'react'
import rest from '../../data/restWrapper';
import Delete from '../../components/confirmModal';
import { setModalContent } from '@components/modal';
import TestHWModalComponent from "./testHWModalComponent";
import moment from "moment";

import Settings from "./settings"

const ControllerAdministration = (props) => {
  const [srcProxy, setsrcProxy] = useState({})
  const [scriptUrl, setScriptUrl] = useState("")
  const [logs, setLogs] = useState([])

  async function fetchData() {
    try {

      const proxyId = props.match.params.srcProxyId;

      if (proxyId) {
        let srcProxy = await rest('/SRCProxy/' + proxyId);
        setsrcProxy(srcProxy);
      }
    } catch (error) {
      console.log(error);
      // setShowLoading(false)
    }
  }

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line
  }, [])




  async function restartProxy() {
    try {
      await rest("/restartSRCProxy", "POST", { srcProxyUuid: srcProxy.SRCProxyUUID })
    } catch (error) {
      console.log(error);
    }
  }

  async function updateProxy() {
    try {
      await rest("/remoteUpdateSRCProxy", "POST", { srcProxyUuid: srcProxy.SRCProxyUUID, scriptUrl:(scriptUrl ? scriptUrl.trim() : "") })
    } catch (error) {
      console.log(error);
    }
  }

  async function resetSettingsToDefault() {
    try {
      await rest("/resetProxyToFactorySettings", "POST", { srcProxyUuid: srcProxy.SRCProxyUUID, scriptUrl })
    } catch (error) {
      console.log(error);
    }
  }

  async function getSRCProxyLogs() {
    
    try {
      const remoteLogs = await rest("/getSRCProxyLogs", "POST", { srcProxyUuid: srcProxy.SRCProxyUUID })
      setLogs(remoteLogs.logs)
    } catch (error) {
      console.log(error);
    }
  }


  async function showTestHWModal() {
    setModalContent(
      <TestHWModalComponent selectedSRCProxyForTestId={srcProxy.SRCProxyUUID} />,
      window.translate('Test'),
      false,
      'modal-md'
    );
  }
  async function showResetProxyModal() {
    setModalContent(
      <Delete actionForYes={() => { resetSettingsToDefault(srcProxy.SRCProxyUUID) }} content="Are you sure you want to reset all settings to factory settings?" />,
      window.translate('Confirm'),
      false,
      'modal-md'
    );
  }

  async function showResetToFactorySettingsProxyModal() {
    setModalContent(
      <Delete actionForYes={() => { restartProxy(srcProxy.SRCProxyUUID) }} content="Are you sure you want to restart?" />,
      window.translate('Confirm'),
      false,
      'modal-md'
    );
  }

  let logRows = logs.map((lg, ind) => {
    let borderClass = ""

    borderClass = ind === logs.length - 1 ? "border-top border-bottom" : "border-top"
    return (
      <div key={ind} className={'text-left border-right border-left ' + borderClass}>
        <small className='pl-2'>{ind + 1} </small>
        <small>{JSON.stringify(lg)}</small>
      </div>
    );
  })


  return (
    <div className='text-center'>
      <div className='text-center pt-2'>
        <h4>{srcProxy.SRCProxyUUID}</h4>
        <div className='py-2'> <span>Last seen: </span> <i className="fa fa-plug" /> {moment(srcProxy.lastSeenTimestamp).fromNow()}</div>
      </div>
      <button
        onClick={() => { showTestHWModal() }}
        className="btn btn-sm btn-outline-primary mr-1"
      >
        {window.translate('Hardware test ').toUpperCase()}{' '}
      </button>

      <button
        onClick={() => { showResetToFactorySettingsProxyModal() }}
        className="btn btn-sm btn-outline-primary mr-1"
      >
        {window.translate('Restart device').toUpperCase()}{' '}
      </button>

      <div className='d-flex justify-content-center align-items-center py-2'>
        <textarea value={scriptUrl} onChange={(e) => { setScriptUrl(e.target.value) }} className='form-control w-50 pt-1 text-small' type="text" placeholder='Script url'>
        </textarea>
        <button onClick={(e) => { e.preventDefault(); updateProxy() }} className='btn btn-sm btn-outline-primary mx-1 pt-1'>Download and run script</button>
      </div>


      <div className='row my-1'>

        <div className='col-md-6 text-center bg-white'>
          <div className='text-center py-1'>
            <h4>Config</h4>
          </div>

          <Settings srcProxyUuid={srcProxy.SRCProxyUUID}/>
          <div onClick={()=>{showResetProxyModal()}} className='btn btn-danger btn-sm my-2'>Reset to factory settings</div>
            </div>
        <div className='col-md-6'>
          <div className='text-center py-1 mb-1'>
            <button onClick={(e) => { e.preventDefault(); getSRCProxyLogs() }} className='btn btn-sm btn-outline-primary mx-1 py-1'>Get logs</button>
          </div>
          {logRows}
        </div>
      </div>
    </div>
  )
}

export default ControllerAdministration