import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import { getSpecificModelRow } from '../../../data/actions/modelActions';
import { toast } from 'react-toastify';
import rest from '../../../data/restWrapper';
import { closeModal } from '@components/modal';
import ListRoomsOrPricelistByTags from '../../roomInfo/listByTags';
import TaxLabel from './taxLabel';
import Select from 'react-select/creatable';

let paramsModel = [
    {
        accommodationTaxId: null,
        applyTaxPerDay: true,
        multipliedWithAdultsNumber: true,
        multipliedWithChildrenNumber: false,
    },
];

const emptyModel = {
    id: null,
    parentId: null,
    pluBase: null,
    name: null, ////
    description: null, ////
    weight: 0, ////
    infinite: true,
    validFrom: null, //new Date().getTime(),
    validTo: null, //new Date().getTime(),
    blockPromotions: false, ////
    repeatRules: null,
    accomodationPriceRules: [0], //{[100, 180]}
    discountRules: null,
    generalTaxCode: null, /////
    isActive: true,
    params: JSON.stringify(_.clone(paramsModel)),
    validationMessage: null,
    tags: '',
};





class AccommodationPriceListForm extends Component {
    constructor(props) {
        super(props);
        this.state = _.clone(emptyModel);
        this.personsCount = React.createRef();
        this.nameRef = React.createRef();
        this.priceRefs = [];
        this.accTags = [];
        this.tagsValues = [];
    }

    async componentDidMount() {
        this.clearValidationRefs();
        let { accommodationPriceListId } = this.props;
        if (accommodationPriceListId) {
            //editing
            this.redrawComponent(accommodationPriceListId);
        } else {
            emptyModel.pluBase = this.renderFirstAvailablePluBase();
            this.setState(emptyModel);
        }
    }

    componentDidUpdate(prevProps, prevState) {
        let { accommodationPriceListId } = this.props;
        if (accommodationPriceListId) {
            //editing
            if (prevProps.accommodationPriceListId !== accommodationPriceListId) {
                this.redrawComponent(accommodationPriceListId);
            } else if (prevState.id !== accommodationPriceListId) {
                this.redrawComponent(accommodationPriceListId);
            }
        } else {
            if (prevState.id !== null) {
                emptyModel.pluBase = this.renderFirstAvailablePluBase();
                this.setState(emptyModel);
                this.priceRefs = [];
            }
        }
    }

    async redrawComponent(accommodationPriceListId) {
        if (
            !_.isUndefined(accommodationPriceListId) &&
            accommodationPriceListId !== null &&
            _.isNaN(accommodationPriceListId) === false
        ) {
            let currentAccommodationPriceList = await getSpecificModelRow(
                'AccommodationPriceList',
                accommodationPriceListId
            );
            let accomodationPriceRules = currentAccommodationPriceList.accomodationPriceRules
                ? JSON.parse(currentAccommodationPriceList.accomodationPriceRules)
                : [0];
            this.clearValidationRefs();
            this.priceRefs = [];
            for (let i = 0; i < currentAccommodationPriceList.accomodationPriceRules.length; i++) {
                this.priceRefs.push(React.createRef());
            }

            let tagsValues = [];
            const tags =
                currentAccommodationPriceList.tags !== null
                    ? currentAccommodationPriceList.tags.split(';').sort((a, b) => (a > b ? 1 : -1))
                    : [];
            tags.forEach((t) => {
                let obj = {};
                obj.value = t;
                obj.label = t;
                tagsValues.push(obj);
            });

            this.tagsValues = tagsValues;
            this.setState({
                id: currentAccommodationPriceList.id,
                name: currentAccommodationPriceList.name,
                description: currentAccommodationPriceList.description,
                pluBase: currentAccommodationPriceList.pluBase,
                weight: currentAccommodationPriceList.weight,
                blockPromotions: currentAccommodationPriceList.blockPromotions,
                accomodationPriceRules: accomodationPriceRules,
                generalTaxCode: currentAccommodationPriceList.generalTaxCode,
                params: currentAccommodationPriceList.params,
                tags: currentAccommodationPriceList.tags === '' ? null : currentAccommodationPriceList.tags,
            });
        }
    }

    inputHandler(e) {
        let state = _.clone(this.state);
        switch (e.target.type) {
            default:
            case 'text':
                state[e.target.name] = e.target.value;
                break;
            case 'select-one':
            case 'number':
                if (_.isNumber(Number(e.target.value))) {
                    state[e.target.name] = Number(e.target.value);
                } else {
                    state[e.target.name] = null;
                }

                break;
            case 'checkbox':
                state[e.target.name] = !e.target.value;
                break;
        }
        this.setState(state);
    }

    clearValidationRefs() {
        _.forEach(this.priceRefs, (p) => {
            if (p.current) {
                p.current.className = p.current.className.replace('is-invalid', '');
            }
        });
        if (this.nameRef && this.nameRef.current) {
            this.nameRef.current.className = this.nameRef.current.className.replace('is-invalid', '');
        }

        if (this.personsCount && this.personsCount.current) {
            this.personsCount.current.className = this.personsCount.current.className.replace('is-invalid', '');
        }
    }

    getValidationInputs(data) {
        let invalidInputs = [];
        let validInputs = [];
        let validationMessage = [];
        // this.clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(this.nameRef);
            validationMessage.push(window.translate('Name is mandatory'));
        } else {
            validInputs.push(this.nameRef);
        }
        let accomodationPriceRules = data.accomodationPriceRules ? JSON.parse(data.accomodationPriceRules) : [0];
        for (let i = 0; i < accomodationPriceRules.length; i++) {
            this.priceRefs.push(React.createRef());
            if (
                accomodationPriceRules[i] === null ||
                accomodationPriceRules[i] === '' ||
                accomodationPriceRules[i] <= 0
            ) {
                invalidInputs.push(this.priceRefs[i]);
            } else {
                validInputs.push(this.priceRefs[i]);
            }
        }

        if (accomodationPriceRules.length === 0) {
            invalidInputs.push(this.personsCount);
        } else {
            validInputs.push(this.personsCount);
        }
        return { invalidInputs, validInputs, validationMessage };
    }

    markInvalidInputRefs(validationResults) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        _.forEach(invalidInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        _.forEach(validInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }

    async onSave() {
        try {
            let data = _.clone(this.state);
            let accomodationPriceRules = JSON.stringify(data['accomodationPriceRules']);
            data['accomodationPriceRules'] = accomodationPriceRules;
            let validationResults = this.getValidationInputs(data);
            this.setState({ validationMessage: validationResults.validationMessage[0] });

            this.markInvalidInputRefs(validationResults);

            if (validationResults.invalidInputs.length === 0) {
                if (data.id !== null) {
                    let id = data.id;
                    delete data.id;
                    await rest('/AccommodationPriceList/' + id, 'PUT', data);
                } else {
                    await rest('/AccommodationPriceList', 'POST', data);
                    toast(window.translate("Accomodation price list added!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
                }
                this.setState(_.clone(emptyModel));
                closeModal();
            }
        } catch (err) {
            console.warn(err);
        }
    }

    cancelHandler() {
        this.setState(emptyModel);
        closeModal();
    }

    renderInputField(_name, label, type = 'text', value, ref) {
        return (
            <div className="form-group row hidden-lg hidden-md visible-xs visible-xs justify-content-center align-items-center">
                <label className="col-4  col-form-label" htmlFor={'pl-' + _name}>
                    {label}:
                </label>
                <div className="col-8 ">
                    <input
                        ref={ref}
                        id={'pl-' + _name}
                        name={_name}
                        placeholder={label}
                        autoComplete="off"
                        value={value ? value : ''}
                        onChange={this.inputHandler.bind(this)}
                        type={type}
                        className="form-control"
                    />
                </div>
            </div>
        );
    }

    renderAccomodationPriceRulesInputs() {
        let accomodationPriceRules = _.clone(this.state.accomodationPriceRules);
        let inputs = null;
        if (accomodationPriceRules !== null) {
            inputs = _.map(accomodationPriceRules, (value, index) => {
                let personCount = Number(index) + 1;
                return (
                    <div key={index} className="form-group row justify-content-center align-items-center">
                        <label className="col-4  col-form-label" htmlFor={'pl-accomodationPriceRules' + index}>
                            {window.translate('Person count')}: {index + 1}
                        </label>
                        <div className="col-8 ">
                            <div className="input-group">
                                <div className="input-group-prepend">
                                    <span className="input-group-text">{this.props.defaultCurrency}</span>
                                </div>
                                <input
                                    ref={this.priceRefs[index]}
                                    id={'pl-pr' + personCount}
                                    name="accomodationPriceRules"
                                    placeholder={window.translate('Price')}
                                    autoComplete="off"
                                    value={accomodationPriceRules !== null && value !== null ? value : ''}
                                    onChange={this.accomodationPriceRulesHandler.bind(this, index)}
                                    type="number"
                                    className="form-control"
                                />
                            </div>
                            <TaxLabel value={value} generalTaxCode={this.state.generalTaxCode} />
                        </div>
                    </div>
                );
            });
            return <div>{inputs}</div>;
        }
    }

    accomodationPriceRulesHandler(index, e) {
        // e.preventDefault()
        let accomodationPriceRules = _.clone(this.state.accomodationPriceRules);
        if (accomodationPriceRules !== null) {
            if (e.target.value !== '') {
                accomodationPriceRules[index] = Number(e.target.value);
                this.setState({ accomodationPriceRules: accomodationPriceRules });
            } else {
                accomodationPriceRules[index] = null;
                this.setState({ accomodationPriceRules: accomodationPriceRules });
            }
        } else {
            accomodationPriceRules = [];
            accomodationPriceRules[index] = e.target.value === '' ? null : Number(e.target.value);
            this.setState({ accomodationPriceRules: accomodationPriceRules });
        }
    }

    checkboxHandler(name, e) {
        let obj = _.clone(this.state);
        obj[name] = !obj[name];
        this.setState(obj);
    }

    getCheckboxInput(_name, label, value) {
        return (
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-4 col-form-label" htmlFor="PL-isActive-input">
                    {label}
                </label>
                <div className="col-8 text-left">
                    <i
                        onClick={this.checkboxHandler.bind(this, _name)}
                        name={_name}
                        value={value}
                        className={value ? 'fa fa-check-square fa-lg ml-2' : 'fa fa-square fa-lg ml-2'}
                    ></i>
                </div>
            </div>
        );
    }

    paramsFormInputHandler(index, e) {
        let params = this.state.params ? JSON.parse(this.state.params) : _.clone(paramsModel);
        if (e.target.type === 'select-one') {
            params[index][e.target.name] = Number(e.target.value);
        } else {
            params[index][e.target.name] = e.target.value;
        }
        let stringifiedParams = JSON.stringify(params);
        this.setState({ params: stringifiedParams });
    }

    paramsFormCheckboxHandler(name, currentValue, index) {
        let params = this.state.params ? JSON.parse(this.state.params) : _.clone(paramsModel);
        params[index][name] = !currentValue;
        let stringifiedParams = JSON.stringify(params);
        this.setState({ params: stringifiedParams });
    }

    changePricePerPersonCount(e) {
        if (e.target.value > -1) {
            let persons = Number(e.target.value);
            const { accomodationPriceRules } = this.state;
            if (persons !== accomodationPriceRules.length) {
                var accomodationPriceRules_clone = _.clone(accomodationPriceRules);
                if (persons > accomodationPriceRules.length) {
                    while (accomodationPriceRules_clone.length < persons) {
                        accomodationPriceRules_clone.push(0);
                    }
                } else {
                    while (accomodationPriceRules_clone.length > persons) {
                        accomodationPriceRules_clone.pop();
                    }
                }
                this.setState({ accomodationPriceRules: accomodationPriceRules_clone });
            }
        }
    }

    renderFirstAvailablePluBase() {
        let accommodationPriceLists = this.props.accommodationPriceLists;
        let pluArray = [];
        let min = 99;
        if (!_.isEmpty(accommodationPriceLists)) {
            _.map(accommodationPriceLists, (acc) => {
                pluArray.push(Number(acc.pluBase));
            });
        }
        if (!_.includes(pluArray, min)) {
            pluArray.push(min);
        }
        pluArray = _.sortBy(pluArray);
        let lowest = -1;
        let offset = pluArray[0];
        if (!_.isEmpty(pluArray)) {
            for (let i = 0; i < pluArray.length; ++i) {
                if (pluArray[i] !== offset) {
                    lowest = offset;
                    break;
                }
                ++offset;
            }
            if (lowest === -1) {
                lowest = pluArray[pluArray.length - 1] + 1;
            }
        } else {
            lowest = 100;
        }
        return Number(lowest);
    }

    checkIsPluTaken(_plu, id) {
        let plu = Number(_plu);
        let isTaken = false;
        let accommodationPriceLists = this.props.accommodationPriceLists;
        if (
            _.includes(
                _.map(accommodationPriceLists, (acc) => {
                    if (id !== acc.id && acc.pluBase) {
                        return acc.pluBase;
                    }
                }),
                plu
            )
        ) {
            isTaken = true;
        }
        return isTaken;
    }


    handleTagsChange(val) {
        this.tagsValues = val;
        let tags = '';
        const tagsVal = [];
        if (val && val.length > 0) {
            val.forEach((v) => tagsVal.push(v.value));
        }
        tags = tagsVal.length > 0 ? tagsVal.join(';') : null;
        this.setState({ tags });
    }

    render() {
        const { taxes } = this.props;
        const taxesWithPercentageAmount = _.filter(taxes, (t) => {
            return t.percentageAmount !== null;
        });
        if (taxesWithPercentageAmount.length === 0) {
            return (
                <div className="container-fluid">
                    {window.translate('Please, before continiue, enter at least one tax group.')}
                </div>
            );
        }

        const tagsOptions = [];
        let tags = [...this.props.AccommodationPriceListTags];
        tags = tags.sort((a, b) => (a > b ? 1 : -1));
        tags.forEach((t) => {
            let obj = {};
            obj.value = t;
            obj.label = t;
            tagsOptions.push(obj);
        });

        return (
            <div className="container-fluid">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-md-10 pl-0 pr-0  ">
                            <div className="pt-4 pb-4 text-md-right bg-white ">
                                {this.renderInputField(
                                    'name',
                                    window.translate('Name'),
                                    'text',
                                    this.state.name,
                                    this.nameRef
                                )}
                                {this.renderInputField(
                                    'description',
                                    window.translate('Description'),
                                    'text',
                                    this.state.description
                                )}
                                {/*{ this.getCheckboxInput("blockPromotions", "Block promotions", this.state.blockPromotions)}*/}
                                <hr />

                                <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-4 col-form-label" htmlFor={'pl-tax'}>
                                        {window.translate('Tax')}:
                                    </label>
                                    <div className="col-8">
                                        <select
                                            className="form-control"
                                            name="generalTaxCode"
                                            onChange={this.inputHandler.bind(this)}
                                            value={this.state.generalTaxCode ? this.state.generalTaxCode : ''}
                                        >
                                            <option>{window.translate('No tax')}</option>
                                            {_.map(taxesWithPercentageAmount, (t) => {
                                                return (
                                                    <option key={t.id} value={t.id}>
                                                        {t.name}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    </div>
                                </div>

                                <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-4 col-form-label" htmlFor="personCount">
                                        {window.translate('Max. billable persons')}:
                                    </label>
                                    <div className="col-8 text-left">
                                        <input
                                            ref={this.personsCount}
                                            id="pl-accomodationPriceRules"
                                            name="accomodationPriceRules"
                                            placeholder={window.translate('Price')}
                                            autoComplete="off"
                                            value={
                                                this.state.accomodationPriceRules.length === 0
                                                    ? ''
                                                    : this.state.accomodationPriceRules.length
                                            }
                                            onChange={this.changePricePerPersonCount.bind(this)}
                                            min={1}
                                            max={100}
                                            type="number"
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                {this.renderAccomodationPriceRulesInputs()}
                                <hr />

                               <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-4 col-form-label" htmlFor={'tags'}>
                                        {window.translate('Tags')}:
                                    </label>
                                    <div className="col-8 text-md-left">
                                        <Select
                                            isMulti
                                            className="mt-2"
                                            placeholder={window.translate('Slice pricelist by tags')}
                                            options={tagsOptions}
                                            onChange={this.handleTagsChange.bind(this)}
                                            value={this.tagsValues}
                                        ></Select>
                                    </div>
                                </div>
                                <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-4 col-form-label" htmlFor={''}>
                                        {window.translate('Rooms by tags')}:
                                    </label>
                                    <div className="col-8" style={{ maxHeight: '250px', overflowY: 'auto' }}>
                                        <ListRoomsOrPricelistByTags acp={this.state.tags} />
                                    </div>
                                </div>

                                {this.state.validationMessage ? (
                                    <div className="alert alert-danger text-center">{this.state.validationMessage}</div>
                                ) : (
                                    ''
                                )}
                                <div className="text-center mt-5">
                                    <button onClick={this.onSave.bind(this)} className="btn btn-outline-primary">
                                        {window.translate('Save')}
                                    </button>
                                    <div
                                        onClick={this.cancelHandler.bind(this)}
                                        className="btn btn-outline-secondary ml-2 pointer"
                                    >
                                        {window.translate('Cancel')}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        defaultCurrency: ['defaultCurrency'],
        taxes: ['model', 'Tax'],
        accommodationPriceLists: ['model', 'AccommodationPriceList'],
        AccommodationPriceListTags: ['tag', 'AccommodationPriceList'],
    },
    AccommodationPriceListForm
);
