import React from 'react';
import PageLoader from '@components/loaders/loader1';
import { loadInvoiceDataForPrintView } from './controler';
import PaginateA4Frame from './printPaginationFrame';
import { getInvoicePropertiesNeededForInvoicePreview } from '../invoicePresenter/utils';
import baobab from '@data/state/index';
import { translate } from '@data/translations';

import {
    GroupInvoiceDetails,
    InvoiceItemsModel,
    InvoiceModelWithInvoiceItems,
    InvoicePreview,
    ReservationModelWithModelsIncluded,
} from '@common/modelDefinition';
import { PaginateA4FrameProps } from './printPaginationFrame';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';

export interface InvoicePrintViewProps {
    invoice: InvoiceModelWithInvoiceItems;
    invoiceItems:InvoiceItemsModel[]
    groupInvoiceDetails?: GroupInvoiceDetails;
    invoicePreview?: InvoicePreview;
    groupReservationsArray?: ReservationModelWithModelsIncluded[];
    match?: any;
    location?: any;
    history?: any;
}

const InvoicePrintView = (props: InvoicePrintViewProps) => {
    const invoiceId = props?.match?.params?.invoiceId;
    const { invoice } = props;

    React.useEffect(() => {
        //ovdje samo okidam da se račun učita u baobab
        if (invoiceId) {
            loadInvoiceDataForPrintView(invoiceId);
        }
    }, [invoiceId]);

    const { InvoiceItems } = invoice;

    if (!InvoiceItems) {
        return <PageLoader />;
    }
    const newProps={...props,invoiceItems:InvoiceItems}

    return (
        <div className="bg-light">
            <A4Frame
              {...newProps}
            />
        </div>
    );
};

const A4Frame = (props: PaginateA4FrameProps) => {
    const { invoice, location, history, groupInvoiceDetails, groupReservationsArray, invoicePreview } =
        props;
    return (
        <div style={{ maxWidth: '900px', margin: 'auto' }} className="">
            <PaginateA4Frame
              {...props}
            />

            {groupInvoiceDetails && // 👈 null and undefined check
                Object.keys(groupInvoiceDetails).length > 0 &&
                groupInvoiceDetails.constructor === Object && (
                    <div>
                        {Object.keys(groupInvoiceDetails).map((key, index) => {
                            const invoiceItems = groupInvoiceDetails[key].invoiceItems;
                            const targetInvoice = getInvoicePropertiesNeededForInvoicePreview(
                                groupInvoiceDetails[key].invoice,
                                invoice,
                                invoiceItems
                            );
                            return (
                                <React.Fragment key={index}>
                                    <PaginateA4Frame                                    
                                        invoice={targetInvoice}
                                        invoiceItems={invoiceItems}
                                        location={location}
                                        details={true}
                                        history={history}
                                        groupReservationsArray={groupReservationsArray}
                                        invoicePreview={invoicePreview}
                                    />
                                </React.Fragment>
                            );
                        })}
                        <div className="text-center mt-4 mb-4 d-print-none">
                            <button
                                className="btn btn-sm btn-secondary"
                                onClick={() => {
                                    baobab.root.select('state', 'invoicePresenter', 'groupInvoiceDetails').set({});
                                }}
                            >
                                {translate('Close')}
                            </button>
                        </div>
                    </div>
                )}
        </div>
    );
};

export default branch(
    {
        invoice: ['state', 'invoicePresenter', 'invoice'],
        groupInvoiceDetails: ['state', 'invoicePresenter', 'groupInvoiceDetails'],
        invoicePreview: ['state', 'invoicePresenter', 'invoicePreview'],
        groupReservationsArray: ['state', 'invoicePresenter', 'groupReservationsArray'],
    },
    InvoicePrintView
);
