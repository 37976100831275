import React, { useState, useEffect } from 'react';
import rest from '../../../data/restWrapper';
import pubsub from '../../../data/pubsub';
import { useBranch } from 'baobab-react/hooks';
import { closeModal } from '@components/modal';
import _ from 'lodash'
import { toast } from 'react-toastify';

const PosTerminalForm = (props) => {
    const { SrcProxy } = useBranch({
        SrcProxy: ['model', 'SRCProxy'],
    });
    const { posTerminal = {} } = props;

    const [posTerminalState, setPosTerminal] = useState(posTerminal);
    const name = React.createRef();

    useEffect(() => {
        const _posTerminal = { ...posTerminal };
        const { fiscalInfo } = _posTerminal;
        if (fiscalInfo && typeof fiscalInfo === 'string') {
            _posTerminal.fiscalInfo = JSON.parse(fiscalInfo);
        }
        if(!_posTerminal.isEnabled){
            _posTerminal.isEnabled = 0
        }
        setPosTerminal(_posTerminal);
        checkValidationInputs(_posTerminal)
        // eslint-disable-next-line
    }, [posTerminal]);

    const handleInputChange = (e) => {
        const _posTerminal = { ...posTerminalState };
        _posTerminal[e.target.name] = e.target.value;
        setPosTerminal(_posTerminal);
        if(e.target.name==="name"){
            checkValidationInputs(_posTerminal)
        }
    };

    const checkValidationInputs = (objToValidate) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);
    }

    const handleFiscalInfo = (e) => {
        const _posTerminal = { ...posTerminalState };
        const _fiscalInfo = {..._posTerminal.fiscalInfo};
        _fiscalInfo[e.target.name] = e.target.value;
        if (e.target.name === 'srcProxyName') {
            _fiscalInfo.SRCProxyUUID =
                SrcProxy.filter((src) => src.name === e.target.value).length > 0 &&
                SrcProxy.filter((src) => src.name === e.target.value)[0].SRCProxyUUID;
        }
        _posTerminal.fiscalInfo = _fiscalInfo;
        setPosTerminal(_posTerminal);
    };

    const handleChecked = (e) => {
        const _posTerminal = { ...posTerminalState };
        _posTerminal.isEnabled = !_posTerminal.isEnabled;
        setPosTerminal(_posTerminal);
    };

    const markInvalidInputRefs = (validationResults) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes,'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes,'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }

    const getValidationInputs = (data) => {
        let invalidInputs = [];
        let validInputs = [];
        // this.clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(name);
        } else {
            validInputs.push(name);
        }
        return { invalidInputs, validInputs };
    }

    const saveFormData = async (e) => {
        try {
            if(!posTerminalState.name || (posTerminalState.name && posTerminalState.name==="")){
                pubsub.emit('toastMessage', "Please enter the name field.", 'error');
            }else{
                await rest('/PosTerminal', 'POST', posTerminalState);
                if(!posTerminalState.id){
                    toast(window.translate("Terminal added!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
                }
                //close form
                closeModal();
                setPosTerminal({});
            }
        } catch (err) {
            pubsub.emit('toastMessage', err.message, 'error');
        }
    };

    return (
        <div className="container pb-2">
            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Name')}
                </label>
                <div className="col-md-8">
                    <input
                        ref={name}
                        autoComplete="false"
                        name="name"
                        placeholder={window.translate('Name')}
                        type="text"
                        className="form-control"
                        value={posTerminalState.name ? posTerminalState.name : ''}
                        onChange={handleInputChange}
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Enable')}
                </label>
                <div className="col-md-8">
                    <input
                        onChange={handleChecked}
                        checked={posTerminalState.isEnabled ? true : false}
                        name="isEnabled"
                        type="checkbox"
                    />
                    &ensp;<small>{window.translate('Enable')}</small>
                </div>
            </div>
            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Terminal type')}
                </label>
                <div className="col-md-8">
                    <select
                        onChange={(e) => {
                            const temp = { ...posTerminalState };
                            temp.terminalType = e.target.value;
                            setPosTerminal(temp);
                        }}
                        className="form-control"
                        value={posTerminalState.terminalType}
                    >
                        <option value={1}>POS interface terminal</option>
                        <option value={0}>Checkout terminal</option>
                    </select>
                </div>
            </div>
            <PosTerminalFiscalInfo posTerminalState={posTerminalState} handleFiscalInfo={handleFiscalInfo} />
            <br />

            <div className="text-center mb-5 pt-5">
                <button data-dismiss="modal" onClick={saveFormData} className="btn btn-outline-primary">
                    {window.translate('Save')}
                </button>
                <button data-dismiss="modal" onClick={props.cancelHandler} className="btn btn-outline-secondary ml-2">
                    {window.translate('Cancel')}
                </button>
            </div>
        </div>
    );
};

const PosTerminalFiscalInfo = ({ posTerminalState, handleFiscalInfo }) => {
    const { fiscalGeneralSettings } = useBranch({
        fiscalGeneralSettings: ['fiscal', 'fiscalGeneralSettings'],
    });
    const { enableFiscalisation = false, country = '' } = fiscalGeneralSettings
        ? fiscalGeneralSettings
        : { enableFiscalisation: false, country: '' };

    if (!enableFiscalisation) {
        return null;
    }
    const { fiscalInfo } = posTerminalState;

    return (
        <React.Fragment>
            {country === 'CRO' || country === 'MNE'
                ? ['businessSpace', 'tollDevice'].map((name) => {
                      const translateString = name === 'businessSpace' ? 'Business Space' : 'Toll Device';
                      return (
                          <div
                              className="form-group row justify-content-center align-items-center mt-2 mb-2"
                              key={name}
                          >
                              <label className="col-md-3 col-form-label" htmlFor="">
                                  {window.translate(translateString)}
                              </label>
                              <div className="col-md-8">
                                  <input
                                      autoComplete="false"
                                      name={name}
                                      placeholder={window.translate(translateString)}
                                      type="text"
                                      className="form-control"
                                      value={fiscalInfo?.[name] ? fiscalInfo[name] : ''}
                                      onChange={handleFiscalInfo}
                                  />
                              </div>
                          </div>
                      );
                  })
                : null}
            {country === 'MNE' &&
                ['enuCode', 'softCode', 'maintainerCode'].map((name) => {
                    const translateString =
                        name === 'enuCode' ? 'Enu Code' : name === 'softCode' ? 'Software Code' : 'Maintainer Code';
                    return (
                        <div className="form-group row justify-content-center align-items-center mt-2 mb-2" key={name}>
                            <label className="col-md-3 col-form-label" htmlFor="">
                                {window.translate(translateString)}
                            </label>
                            <div className="col-md-8">
                                <input
                                    autoComplete="false"
                                    name={name}
                                    placeholder={window.translate(translateString)}
                                    type="text"
                                    className="form-control"
                                    value={fiscalInfo?.[name] ? fiscalInfo[name] : ''}
                                    onChange={handleFiscalInfo}
                                />
                            </div>
                        </div>
                    );
                })}
            {country === 'BIH' && (
                <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                    <label className="col-md-3 col-form-label" htmlFor="">
                        {window.translate('Src Proxy Name')}
                    </label>
                    <div className="col-md-8">
                        <input
                            autoComplete="false"
                            name="srcProxyName"
                            placeholder={window.translate('Src Proxy Name')}
                            type="text"
                            className="form-control"
                            value={fiscalInfo?.srcProxyName ? fiscalInfo.srcProxyName : ''}
                            onChange={handleFiscalInfo}
                        />
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

export default PosTerminalForm;
