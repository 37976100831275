import React, { useEffect, useState, useRef } from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
//@ts-ignore
import _ from 'lodash';

//@ts-ignore
import { toast } from 'react-toastify';
import { closeModal } from '@components/modal';
import { RoleWithPermissions } from '@common/modelDefinition';
import translate from '@data/translations';
import { saveOrUpdateRole } from './controler';
import { getRolePermissions } from './api';


interface RoleFormProps {
    match: any;
    roleKey?: string | undefined;
    callback?: Function;
    history: any;
    cancelHandler?: Function;
    cancelModal?: boolean;
    showPageTitle: boolean | undefined;
    customHeader: any;
    setSelectedRole:Function
}

const RoleForm = (props: RoleFormProps) => {
    const { roleKey ,setSelectedRole} = props;
    const [validationMessage, setValidationMessage] = useState('');
    const [role, setRole] = useState({
        key: '',
        value: null,
        isActive: true,
        params: null,
        sessionDurationInMinutes: 32000,
        dashboardRoute: '/',
        fixedRoute: '',
        Permissions: [],
    } as RoleWithPermissions);
    const key = useRef(null);

    useEffect(() => {
        const loadData = async () => {
            if (roleKey) {
                const _role = await getRolePermissions(roleKey);
                setRole(_role);
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [roleKey]);

    const handleInputChange = (e: any) => {
        const _role: any = { ...role };
        _role[e.target.name] = e.target.value;
        setRole(_role);
    };

    const markInvalidInputRefs = (validationResults: any) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!classes.includes('is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (classes.includes('is-invalid')) {
                    _.remove(classes, (i: any) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    };

    const getValidationInputs = (data: any) => {
        const invalidInputs = [],
            validInputs = [];
        // clearValidationRefs();
        if (data.key === null || data.key.trim() === '') {
            invalidInputs.push(key);
        } else {
            validInputs.push(key);
        }

        return { invalidInputs, validInputs };
    };

    const saveFormData = async () => {
        try {
            let _role = { ...role };

            const validationResults = getValidationInputs(_role);
            markInvalidInputRefs(validationResults);
            if (validationResults.invalidInputs.length === 0) {
                await saveOrUpdateRole(_role);
                setSelectedRole(_role.key)
            }
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
        closeModal();
    };

    const cancelHandler = () => {
        if (props.cancelHandler) {
            props.cancelHandler();
        } else {
            if (props.history) {
                props.history.push('/roles');
            }
        }
        cancelhandler();
    };

    const cancelhandler = () => {
        setValidationMessage('');
        setRole({
            key: '',
            value: null,
            isActive: true,
            params: null,
            sessionDurationInMinutes: null,
            dashboardRoute: '',
            fixedRoute: '',
            Permissions: [],
        });
        if (props.cancelModal === false) {
        } else {
            closeModal();
        }
    };

    return (
        <div className="container-fluid">
            <div className="container bg-white  ">
                <div className="row justify-content-center">
                    <div className="col-md-10 pl-0 pr-0  ">
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            className="pt-4 pb-4 pr-2 pl-2 bg-white text-md-right"
                        >
                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Name')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        disabled={roleKey ? true : false}
                                        ref={key}
                                        autoComplete="off"
                                        placeholder={translate('Name')}
                                        name="key"
                                        value={role.key ? role.key : ''}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Dashboard route')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        placeholder={translate('')}
                                        name="dashboardRoute"
                                        value={role.dashboardRoute ? role.dashboardRoute : ''}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Fixed route')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        name="fixedRoute"
                                        placeholder={translate('')}
                                        value={role.fixedRoute ? role.fixedRoute : ''}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Session duration time (min)')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        name="sessionDurationInMinutes"
                                        placeholder={translate('')}
                                        value={role.sessionDurationInMinutes ? role.sessionDurationInMinutes : ''}
                                        onChange={(e) => {
                                            handleInputChange(e);
                                        }}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            {validationMessage === '' ? null : (
                                <div className="alert alert-danger" role="alert">
                                    {validationMessage}
                                </div>
                            )}

                            <div className="text-center">
                                <div
                                    onClick={async () => {
                                        await saveFormData();
                                    }}
                                    className="btn btn-outline-primary"
                                >
                                    {translate('Save')}
                                </div>
                                <div onClick={cancelHandler.bind(this)} className="btn btn-outline-secondary ml-2">
                                    {translate('Cancel')}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        //fiscalGeneralSettings: ['fiscal', 'fiscalGeneralSettings'],
    },
    RoleForm
);
