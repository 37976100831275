import React, { useState } from 'react';
import ReportTable from '../../components/ReportTable';
import {  getExcelExport } from '../../data/reports';

const InvoicesListView = (props) => {
    const { filteredInvoices } = props;
    const [year, setYearState] = useState(new Date().getFullYear());

    const setYear = async (e) => {
        setYearState(Number(e.target.value));
    };


    var invoiceReclamationStatusEnums = {
        0: window.translate('Regular'),
        1: window.translate('Cancelled'),
        2: window.translate('Storno'),
    };

    const stornoInvoiceIds = [];
    const fiscalInvoiceIds = [];
    const fiscalStornoInvoices = [];
    filteredInvoices.forEach((i) => {
        if (i.reclamation === 0 && i.invoiceType !== 2) {
            stornoInvoiceIds.push(i.id);
        }
        if (!i.fiscalId && i.reclamation !== 2) {
            fiscalInvoiceIds.push(i.id);
        }
        if (!i.reclamationFiscalId && !i.sfJIR && i.reclamation === 2) {
            fiscalStornoInvoices.push(i.id);
        }
    });

    const headers = [
        {
            filterKey: 'reservationId',
            headerTitle: 'R. Id',
            label: 'reservationId',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '70px',
        },
        {
            filterKey: 'reservationGroupUuid',
            headerTitle: 'Group Uuid',
            label: 'reservationGroupUuid',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'id',
            headerTitle: window.translate('Invoice id'),
            label: 'id',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '70px',
        },
        {
            filterKey: 'checkInTimestamp',
            headerTitle: window.translate('Check in'),
            label: 'checkInTimestamp',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-2',
            cellWidth: '150px',
        },
        {
            filterKey: 'checkOutTimestamp',
            headerTitle: window.translate('Check out'),
            label: 'checkOutTimestamp',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-2',
            cellWidth: '100px',
        },
        {
            filterKey: 'createdAt',
            headerTitle: window.translate('Created at'),
            label: 'createdAt',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-2',
            cellWidth: '100px',
        },
        // {filterKey:"daysTotal", headerTitle:"Days stayed", label:"daysTotal",type:"number", filterType:"sum", bootstrapColumnClass:"col-md-2 d-none d-md-block" },
        {
            filterKey: 'reclamation',
            headerTitle: window.translate('Reclamation type'),
            label: 'reclamation',
            type: 'enum',
            filterType: 'select',
            enumDescription: invoiceReclamationStatusEnums,
            bootstrapColumnClass: 'col-md-2 d-none d-md-block',
            cellWidth: '200px',
        },
        {
            filterKey: 'totalVAT',
            headerTitle: 'VAT',
            label: 'totalVAT',
            type: 'price',
            filterType: '',
            bootstrapColumnClass: 'col-md-2',
            cellWidth: '100px',
        },
        {
            filterKey: 'totalValue',
            headerTitle: window.translate('Total'),
            label: 'totalValue',
            type: 'price',
            filterType: '',
            bootstrapColumnClass: 'col-md-2',
            cellWidth: '100px',
        },
        {
            filterKey: 'terminalType',
            headerTitle: window.translate('Terminal type'),
            label: 'terminalType',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-2',
            cellWidth: '100px',
        },
        {
            headerTitle: window.translate('Action'),
            type: 'link',
            openInNewTab: true,
            linkText: window.translate('View'),
            linkSchema: ['invoiceView', '*'],
            linkSubstitutions: ['id'],
            filterType: '',
            bootstrapColumnClass: 'col-md-2 d-none d-md-block',
            cellWidth: '100px',
        },
        {
            headerTitle: window.translate('Action'),
            type: 'link',
            linkText: window.translate('Reservation details'),
            linkSchema: ['reservationDetails', '*'],
            linkSubstitutions: ['reservationId', 'reservationGroupUuid'],
            filterType: '',
            bootstrapColumnClass: 'col-md-2 d-none d-md-block',
            cellWidth: '100px',
        },

    ];

    return (
        <div>
            <ReportTable
                year={year}
                setYear={setYear.bind(this)}
                onYearMonthChange={(filter) => {
                    /*var {year, month} = filter
                        this.setState({year, month});
                        this.reloadData(year, month);*/
                }}
                itemsArray={filteredInvoices}
                headers={headers}
                monthFilter={true}
                history={props.history}
                targetedColumnForMonthFilter={'checkInTimestamp'}
                xlsExportHandler={(params) => {
                    getExcelExport('invoices', params);
                }}
                showTableToolbar={false}
            ></ReportTable>
        </div>
    );
};

export default InvoicesListView;
