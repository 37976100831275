import _ from 'lodash';
import baobabTree from '../data/state';

const checkRoleAccess = (...requiredRoles) => {
    //strings as params
    let accessAllowed = false;

    const role = baobabTree.root.select(['authTokenInfo', 'user', 'role']).get();
    let roles = [role];

    if (_.isArray(requiredRoles) || !_.isString(requiredRoles)) {
        _.forEach(requiredRoles, (reqRole) => {
            if (!accessAllowed) {
                accessAllowed = _.includes(roles, reqRole);
            }
        });
    } else {
        accessAllowed = _.includes(roles, requiredRoles);
    }
    return accessAllowed;
};

export default checkRoleAccess;
