import React from 'react';

import { loadCheckoutInvoice } from './controler';
import { translate } from '@data/translations';

import CheckoutOptions from './CheckoutOptions';
import PlainInvoiceView from '../invoicePresenter/PlainInvoiceView';
import SingleReservationPreview from '../invoicePresenter/SingleReservationPreview';
import SelectPosTerminalWrapper from './SelectPosTerminalWrapper';

import { InvoiceAndCloseReservationProps } from './interfaces';

//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { InvoiceItemsModel, InvoiceModelWithInvoiceItems } from '@common/modelDefinition';

const InvoiceAndCloseReservation = (props: InvoiceAndCloseReservationProps) => {
    const { location, invoice, invoicePreview, invoiceItems, invoiceStornoPreview, groupReservationsArray } = props;

    const reservationId: number | null = props?.match?.params?.reservationId
        ? Number(props?.match?.params?.reservationId)
        : null;
    const reservationGroupUuid: string | null = props?.match?.params?.reservationGroupUuid;
    React.useEffect(() => {
        //ovdje samo okidam da se račun učita u baobab
        const loadData = async () => {
            if (reservationGroupUuid || reservationId) {
                await loadCheckoutInvoice({ reservationId, reservationGroupUuid, location });
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [reservationId, reservationGroupUuid, location]);
    const plainInvoiceView: InvoiceModelWithInvoiceItems = invoiceStornoPreview ? invoiceStornoPreview : invoice;
    const plainInvoiceItemsView: InvoiceItemsModel[] = invoiceStornoPreview
        ? invoiceStornoPreview.InvoiceItems
        : invoiceItems;

    return (
        <div className="mt-2 mb-5">
            <SelectPosTerminalWrapper>
                <div className="row">
                    <div className="col-md-4">
                        <CheckoutOptions
                            translate={translate}
                            {...props}
                            invoice={plainInvoiceView}
                            invoiceItems={plainInvoiceItemsView}
                            reservationId={reservationId}
                            reservationGroupUuid={reservationGroupUuid}
                        />
                    </div>

                    <div className="col-md-8">
                        {invoicePreview && invoicePreview.invoiceItems.length > 0 ? (
                            <SingleReservationPreview invoicePreview={invoicePreview} groupInvoice={invoice} />
                        ) : (
                            <PlainInvoiceView
                                invoice={plainInvoiceView}
                                invoiceItems={plainInvoiceItemsView}
                                groupReservationsArray={groupReservationsArray}
                                location={location}
                            />
                        )}
                    </div>
                </div>
            </SelectPosTerminalWrapper>
        </div>
    );
};

export default branch(
    {
        invoice: ['state', 'checkoutInvoice', 'invoice'],
        invoiceItems: ['state', 'checkoutInvoice', 'invoiceItems'],
        advanceInvoices: ['state', 'checkoutInvoice', 'advanceInvoices'],
        proformaInvoices: ['state', 'checkoutInvoice', 'proformaInvoices'],
        stornoInvoices: ['state', 'checkoutInvoice', 'stornoInvoices'],
        groupReservationsArray: ['state', 'checkoutInvoice', 'groupReservationsArray'],
        reservation: ['state', 'checkoutInvoice', 'reservation'],
        posTerminalInvoices: ['state', 'checkoutInvoice', 'posTerminalInvoices'],
        checkoutDateMismatchs: ['state', 'checkoutInvoice', 'checkoutDateMismatchs'],
        genuineInvoices: ['state', 'checkoutInvoice', 'genuineInvoices'],
        stayoverOverlap: ['state', 'checkoutInvoice', 'stayoverOverlap'],
        minibarInvoices: ['state', 'checkoutInvoice', 'minibarInvoices'],
        invoiceStornoPreview: ['state', 'checkoutInvoice', 'invoiceStornoPreview'],
        availableForAdvancePayment: ['state', 'checkoutInvoice', 'availableForAdvancePayment'],
        invoicePreview: ['state', 'checkoutInvoice', 'invoicePreview'],
        accommodationInvoice: ['state', 'checkoutInvoice', 'accommodationInvoice'],
        roomInfoMap: ['map', 'RoomInfo'],
    },
    InvoiceAndCloseReservation
);
