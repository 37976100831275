import React, { Component } from 'react';
import 'react-input-range/lib/css/index.css';
import rest from '../../data/restWrapper';
import Thermostat from './thermostat';

class GeneralSettings extends Component {
    constructor(props) {
        super(props);
        this.state = {
            showBody: false,
            data: {
                currentRoomMode: props.roomSettings.generalSettings.currentRoomMode,
                currentRoomModeAdjustment: props.roomSettings.generalSettings.currentRoomModeAdjustment,
                welcomeLightSecondsDuration: props.roomSettings.generalSettings.welcomeLightSecondsDuration,
                useDigitalInputAsFanSpeedControl: props.roomSettings.generalSettings.useDigitalInputAsFanSpeedControl,

                enableIOStatesChangeEmitting: props.roomSettings.generalSettings.enableIOStatesChangeEmitting,

                enforceDisposedRFIDCardCheckingForGeneralElectric:
                    props.roomSettings.generalSettings.enforceDisposedRFIDCardCheckingForGeneralElectric,
                TH01_UTCOffset: props.roomSettings.generalSettings.TH01_UTCOffset,
                bathroomOneWireSensorIndex: props.roomSettings.generalSettings.bathroomOneWireSensorIndex,
                bathroomTargetTemperature: props.roomSettings.generalSettings.bathroomTargetTemperature,
                generalElectricOffTimeoutSecondsAfterCardIsRemovedFromDispenser:
                    props.roomSettings.generalSettings.generalElectricOffTimeoutSecondsAfterCardIsRemovedFromDispenser,

                enableMandeks3DStatusLightMode: props.roomSettings.generalSettings.enableMandeks3DStatusLightMode,

                /*
              enableManualThermostatModeSwitching_t1: props.roomSettings.generalSettings.enableManualThermostatModeSwitching_t1,
              enableManualThermostatModeSwitching_t2: props.roomSettings.generalSettings.enableManualThermostatModeSwitching_t2,
              enableManualThermostatModeSwitching_t3: props.roomSettings.generalSettings.enableManualThermostatModeSwitching_t3,
              
*/
                thermostat_deadZone: props.roomSettings.generalSettings.thermostat_deadZone,
                thermostat_setpointDifferential: props.roomSettings.generalSettings.thermostat_setpointDifferential, //(range 5 - 50 - means 0.5K to 5K)
                thermostat_minimuOutputOnTime: props.roomSettings.generalSettings.thermostat_minimuOutputOnTime, //(range 1 to 20 minutes)
                thermostat_minimuOutputOffTime: props.roomSettings.generalSettings.thermostat_minimuOutputOffTime, //(range 1 to 20 minutes)
                thermostat_fanHighProportionalBandSwitchingPoint:
                    props.roomSettings.generalSettings.thermostat_fanHighProportionalBandSwitchingPoint, //80% to 100%
                thermostat_fanMedProportionalBandSwitchingPoint:
                    props.roomSettings.generalSettings.thermostat_fanMedProportionalBandSwitchingPoint, //30% to 75%
                thermostat_fanLowProportionalBandSwitchingPoint:
                    props.roomSettings.generalSettings.thermostat_fanLowProportionalBandSwitchingPoint, //1% to 15%
                thermostat_fanStartBooster: props.roomSettings.generalSettings.thermostat_fanStartBooster, //if switching to fan speed 1, first start motor at fan speed 3 for one second, then move back to first speed
                thermostat_minimumFanOnTime: props.roomSettings.generalSettings.thermostat_minimumFanOnTime, // (1 to 6 minutes)
                thermostat_fanKickInterval: props.roomSettings.generalSettings.thermostat_fanKickInterval, // 0 - 90 minutes (0 is OFF) //avoid damage from moisture. set fan1 on minimum active time in this interval

                //not using this one
                //  thermostat_fanOverrunForElectricalHeater : 67, //seconds - When the electrical heater is switched off, the fan overruns for 60 seconds  - safety feature
                thermostat_fanStartDelay: props.roomSettings.generalSettings.thermostat_fanStartDelay, //(0 - 180 seconds)
                debounceMaidCardWhenGuestIsInTheRoom:
                    props.roomSettings.generalSettings.debounceMaidCardWhenGuestIsInTheRoom, //0 - no debouncing, 1- one time, 2 - two times, 3 - three times

                 doorRelayHoldingSeconds : props.roomSettings.generalSettings.doorRelayHoldingSeconds, //2 up do 15 seconds
                thermostatFakeOffset :props.roomSettings.generalSettings.thermostatFakeOffset// 0 up to 4 celzius 
            },
            modified: {},
        };
    }

    inputHandler(e) {
        let obj = this.state;
        obj.data[e.target.name] = parseInt(e.target.value, 10); //for now, they are all numbers
        obj.modified[e.target.name] = true;
        this.setState(obj);
    }

    async saveChanges() {
        try {
            let remoteDeviceId = this.props.match.params.remoteDeviceId;
            let keys = Object.keys(this.state.modified);
            for (let i = 0; i < keys.length; i++) {
                let payload = { generalSettingsParamEnum: keys[i], value: this.state.data[keys[i]] };
                if (this.props.onSaveHandler) {
                    this.props.onSaveHandler('generalSettings', this.state.data);
                } else {
                    await rest('/src/setGeneralSettingsByte/' + remoteDeviceId, 'POST', payload);
                }
            }
            this.setState({ modified: {} });
        } catch (err) {
            console.warn(err); //TODO:
        }
    }

    render() {
        let settingsBody = (
            <div className="card-body">
                <div className="row">
                    <div className="col-md-6">
                        <h4 className="text-center bg-light p-2">{window.translate('ROOM SETTINGS')}</h4>
                        {window.translate('Room mode adjustment')}:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name="currentRoomModeAdjustment"
                            value={this.state.data.currentRoomModeAdjustment}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>{window.translate('AUTO ADJUSTMENT')}</option>
                            <option value={1}>{window.translate('MANUAL')}</option>
                        </select>
                        {window.translate('Active room mode')}:
                        <select
                            disabled={this.state.data.currentRoomModeAdjustment === 0}
                            onChange={this.inputHandler.bind(this)}
                            name="currentRoomMode"
                            value={this.state.data.currentRoomMode}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>ECO</option>
                            <option value={1}>PRE COMFORT</option>
                            <option value={2}>COMFORT</option>
                            <option value={3}>CLEANING IN PROGRESS</option>
                            <option value={4}>OUT OF SERVICE</option>
                        </select>
                        {window.translate('Enable IO state emitting (RMCU)')}
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name="enableIOStatesChangeEmitting"
                            value={this.state.data.enableIOStatesChangeEmitting}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>{window.translate('DISABLE')}</option>
                            <option value={1}>{window.translate('ENABLE')}</option>
                        </select>
                        {window.translate('Welcome light duration in seconds (15-60 seconds)')}:
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="welcomeLightSecondsDuration"
                            value={this.state.data.welcomeLightSecondsDuration}
                            className="form-control form-control-sm"
                            type="number"
                            min={15}
                            max={60}
                        />
                        {window.translate(
                            'General electric ON, after card is pulled off from dispenser (10-60 seconds). It works only if forced check RFID is DISABLED'
                        )}
                        :
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="generalElectricOffTimeoutSecondsAfterCardIsRemovedFromDispenser"
                            value={this.state.data.generalElectricOffTimeoutSecondsAfterCardIsRemovedFromDispenser}
                            className="form-control form-control-sm"
                            type="number"
                            min={10}
                            max={60}
                        />
                        {window.translate('Force checking disposed RFID card')}:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name="enforceDisposedRFIDCardCheckingForGeneralElectric"
                            value={this.state.data.enforceDisposedRFIDCardCheckingForGeneralElectric}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>{window.translate('DISABLE')}</option>
                            <option value={1}>{window.translate('ENABLE')}</option>
                        </select>
                        {window.translate('Debounce entrance for MAID when guest is in the room')}:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name="debounceMaidCardWhenGuestIsInTheRoom"
                            value={this.state.data.debounceMaidCardWhenGuestIsInTheRoom}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>{window.translate('DISABLE DEBOUNCING')}</option>
                            <option value={1}>{window.translate('ONE EXTRA PASS')}</option>
                            <option value={2}>{window.translate('TWO EXTRA PASSES')}</option>
                            <option value={3}>{window.translate('THREE EXTRA PASSES')}</option>
                        </select>
                        {window.translate('Enable Mandeks 3D light')}:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name="enableMandeks3DStatusLightMode"
                            value={this.state.data.enableMandeks3DStatusLightMode}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>{window.translate('DISABLE')}</option>
                            <option value={1}>{window.translate('ENABLE')}</option>
                        </select>

                        {window.translate('Door relay holding time (2-15 seconds)')}:
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="doorRelayHoldingSeconds"
                            value={this.state.data.doorRelayHoldingSeconds}
                            className="form-control form-control-sm"
                            type="number"
                            min={0}
                            max={15}
                        />

                        <h4 className="text-center mt-2 bg-light p-2">{window.translate('BATHROOM HEATING')}</h4>
                        {window.translate('Bathroom wire sensor')}:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name="bathroomOneWireSensorIndex"
                            value={this.state.data.bathroomOneWireSensorIndex}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>Wire sensor 1</option>
                            <option value={1}>Wire sensor 2</option>
                        </select>
                        {window.translate('Target temperature (°C)')}:
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="bathroomTargetTemperature"
                            value={this.state.data.bathroomTargetTemperature}
                            className="form-control form-control-sm"
                            type="number"
                            min={5}
                            max={30}
                        />
                    </div>
                    <div className="col-md-6">
                        <h4 className="text-center bg-light p-2">{window.translate('THERMOSTAT OPTIONS')}</h4>
                        {window.translate('Thermostat Dead Zone')}:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_deadZone"
                            value={this.state.data.thermostat_deadZone}
                            className="form-control form-control-sm"
                        >
                            <option value={5}>0.5 C</option>
                            <option value={10}>1 Celsius</option>
                            <option value={15}>1.5 Celsius</option>
                            <option value={20}>2 Celsius</option>
                            <option value={25}>2.5 Celsius</option>
                            <option value={30}>3 Celsius</option>
                            <option value={35}>3.5 Celsius</option>
                            <option value={40}>4 Celsius</option>
                            <option value={45}>4.5 Celsius</option>
                        </select>
                        {window.translate(
                            'Thermostat setpoint differential (for additional heaters, or two step modes)'
                        )}
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_setpointDifferential"
                            value={this.state.data.thermostat_setpointDifferential}
                            className="form-control form-control-sm"
                        >
                            <option value={5}>0.5 C</option>
                            <option value={10}>1 Celsius</option>
                            <option value={15}>1.5 Celsius</option>
                            <option value={20}>2 Celsius</option>
                            <option value={25}>2.5 Celsius</option>
                            <option value={30}>3 Celsius</option>
                            <option value={35}>3.5 Celsius</option>
                            <option value={40}>4 Celsius</option>
                            <option value={45}>4.5 Celsius</option>
                        </select>
                        {window.translate('Minimum valve output ON time (minutes)')}:
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_minimuOutputOnTime"
                            value={this.state.data.thermostat_minimuOutputOnTime}
                            className="form-control form-control-sm"
                            type="number"
                            min={1}
                            max={20}
                        />
                        {window.translate('Minimum valve output OFF time (minutes)')}:
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_minimuOutputOffTime"
                            value={this.state.data.thermostat_minimuOutputOffTime}
                            className="form-control form-control-sm"
                            type="number"
                            min={1}
                            max={20}
                        />
                        {window.translate('Minimum FAN ON time (minutes)')}:
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_minimumFanOnTime"
                            value={this.state.data.thermostat_minimumFanOnTime}
                            className="form-control form-control-sm"
                            type="number"
                            min={1}
                            max={6}
                        />
                        {window.translate('Fan start booster')}:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_fanStartBooster"
                            value={this.state.data.thermostat_fanStartBooster}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>{window.translate('DISABLE')}</option>
                            <option value={1}>{window.translate('ENABLE')}</option>
                        </select>
                        {window.translate('Proportional band switching point - HIGH fan (80-100)')}:
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_fanHighProportionalBandSwitchingPoint"
                            value={this.state.data.thermostat_fanHighProportionalBandSwitchingPoint}
                            className="form-control form-control-sm"
                            type="number"
                            min={80}
                            max={100}
                        />
                        {window.translate('Proportional band switching point - MED fan (30-75)')}:
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_fanMedProportionalBandSwitchingPoint"
                            value={this.state.data.thermostat_fanMedProportionalBandSwitchingPoint}
                            className="form-control form-control-sm"
                            type="number"
                            min={30}
                            max={75}
                        />
                        {window.translate('Proportional band switching point - LOW fan (1-15)')}:
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_fanLowProportionalBandSwitchingPoint"
                            value={this.state.data.thermostat_fanLowProportionalBandSwitchingPoint}
                            className="form-control form-control-sm"
                            type="number"
                            min={1}
                            max={15}
                        />
                        {window.translate(
                            'Fan kick interval (0-90 minutes, 0 = always fan1 running in dead zone, 90-fan kick disabled)'
                        )}
                        :
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_fanKickInterval"
                            value={this.state.data.thermostat_fanKickInterval}
                            className="form-control form-control-sm"
                            type="number"
                            min={0}
                            max={90}
                        />
                        {window.translate('Fan start delay (0-180 seconds, 0 is off)')}
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="thermostat_fanStartDelay"
                            value={this.state.data.thermostat_fanStartDelay}
                            className="form-control form-control-sm"
                            type="number"
                            min={0}
                            max={180}
                        />
                        UTC offset:
                        <select
                            onChange={this.inputHandler.bind(this)}
                            name="TH01_UTCOffset"
                            value={this.state.data.TH01_UTCOffset}
                            className="form-control form-control-sm"
                        >
                            <option value={0}>UTC+/-0</option>
                            <option value={1}>UTC - 1</option>
                            <option value={2}>UTC - 2</option>
                            <option value={3}>UTC - 3</option>
                            <option value={4}>UTC - 4</option>
                            <option value={5}>UTC - 5</option>
                            <option value={6}>UTC - 6</option>
                            <option value={7}>UTC - 7</option>
                            <option value={8}>UTC - 8</option>
                            <option value={9}>UTC - 9</option>
                            <option value={10}>UTC - 10</option>
                            <option value={11}>UTC - 11</option>
                            <option value={12}>UTC - 12</option>
                            <option value={13}>UTC + 1</option>
                            <option value={14}>UTC + 2</option>
                            <option value={15}>UTC + 3</option>
                            <option value={16}>UTC + 4</option>
                            <option value={17}>UTC + 5</option>
                            <option value={18}>UTC + 6</option>
                            <option value={19}>UTC + 7</option>
                            <option value={20}>UTC + 8</option>
                            <option value={21}>UTC + 9</option>
                            <option value={22}>UTC + 10</option>
                            <option value={23}>UTC + 11</option>
                            <option value={24}>UTC + 12</option>
                        </select>

                        {window.translate('Temperature output offset (0-4 Celsius)')}:
                        <input
                            onChange={this.inputHandler.bind(this)}
                            name="thermostatFakeOffset"
                            value={this.state.data.thermostatFakeOffset}
                            className="form-control form-control-sm"
                            type="number"
                            min={0}
                            max={4}
                        />


                    </div>
                </div>

                <div className="text-center mt-4 mb-2">
                    <button
                        onClick={this.saveChanges.bind(this)}
                        className={
                            Object.keys(this.state.modified).length > 0 ? 'btn btn-info' : 'btn btn-info disabled'
                        }
                    >
                        {window.translate('Update')}
                    </button>
                </div>
            </div>
        );

        return (
            <div>
                <div className="row">
                    <div className="col-md-12">
                        <Thermostat name={window.translate('EXTERNAL THERMOSTAT')} sufix={'_t2'} {...this.props} />
                    </div>
                </div>

                <div className="row">
                    <div className="col-md-12">
                        <Thermostat name={window.translate('EXTERNAL THERMOSTAT')} sufix={'_t3'} {...this.props} />
                    </div>
                </div>

                <div className="card mt-2">
                    <div
                        onClick={() => {
                            this.setState({
                                showBody: !this.state.showBody,
                            });
                        }}
                        className="card-header text-light bg-dark text-center"
                    >
                        {window.translate('GENERAL SETTINGS')}{' '}
                        {this.state.showBody ? (
                            <i className="fa fa-compress ml-2 mr-2" aria-hidden="true"></i>
                        ) : (
                            <i className="fa fa-expand ml-2 mr-2" aria-hidden="true"></i>
                        )}
                    </div>

                    {this.state.showBody ? settingsBody : null}
                </div>
            </div>
        );
    }
}
export default GeneralSettings;