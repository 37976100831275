import { translate } from '../../../data/translations';

const companyInfoKeyToName = {
    name: translate('gsettings_name'),
    hotelName: translate('gsettings_hotelName'),
    address: translate('gsettings_address'),
    postOfficeNumber: translate('gsettings_postOfficeNumber'),
    town: translate('gsettings_town'),
    contactEmail: translate('gsettings_contactEmail'),
    idNumber: translate('gsettings_idNumber'),
    notes: translate('gsettings_notes'),
    countiresNeedVisa: '',
};

export { companyInfoKeyToName };