import React from 'react';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';

import {
    reservationEnums,
    reservationStatusEnumsDesc,
    reservationStatusColors,
} from '../../data/reservationStatusEnums';

const ReservationsResults = (props) => {
    const {
        reservations,
        roomInfoById,
        reservationCustomersById,
        customersByReservationId,
        history,
        companiesById,
    } = props;

    if (reservations.length === 0) {
        return null;
    }

    return (
        <div>
            <h5>{window.translate('Reservations')}</h5>

            <ul className="list-group mt-3 mx-3">
                <li className="px-1 py-1 row text-white small navbar-dark navbar-gradient">
                    <div className="col-3">{window.translate('Guests')}</div>
                    <div className="col-2">{window.translate('Check in')}</div>
                    <div className="col-2">{window.translate('Check out')}</div>
                    <div className="col-2">{window.translate('Room')}</div>
                    <div className="col-2">{window.translate('Status')}</div>
                </li>
                {_.orderBy(reservations, 'statusEnum').map((r, index) => {
                    const reservationCustomerIds = customersByReservationId[r.id]
                        ? customersByReservationId[r.id]
                        : null;
                    const company = r.companyId ? companiesById[r.companyId] : null;

                    return (
                        <li
                            onClick={() => {
                                r.statusEnum === reservationEnums.closed
                                    ? history.push(`/reservationDetails/${r.id}`)
                                    : history.push(`/reservations/${r.id}/edit`);
                            }}
                            className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}
                            key={index}
                        >
                            <div className="col-3">
                                {reservationCustomerIds !== null
                                    ? reservationCustomerIds.map((cid) => {
                                          return (
                                              <div
                                                  key={cid}
                                              >{`${reservationCustomersById[cid].firstName} ${reservationCustomersById[cid].lastName}, (${reservationCustomersById[cid].documentId})`}</div>
                                          );
                                      })
                                    : company
                                    ? company.name
                                    : ''}
                            </div>

                            <div className="col-2 elipsis">{moment(r.checkInTimestamp).format('DD.MM.YYYY')}</div>
                            <div className="col-2 elipsis">{moment(r.checkOutTimestamp).format('DD.MM.YYYY')}</div>
                            <div className="col-2 elipsis">
                                {roomInfoById[r.roomInfoId] ? roomInfoById[r.roomInfoId].name : null}
                            </div>
                            <div className={`col-2 elipsis ${reservationStatusColors[r.statusEnum]}`}>
                                {reservationStatusEnumsDesc[r.statusEnum]}
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default ReservationsResults;
