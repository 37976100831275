import React from 'react';
import InstallationForm from './step1';
import { loadSettings } from '../settingsActions';
import { branch } from 'baobab-react/higher-order';
import InstallationStep2 from './step2';
import InstallationStep3 from './step3';
import InstallationStep4 from './step4';

const FirstInstallPage = (props) => {
    const [maxSteps] = React.useState(4);
    const [step, setStep] = React.useState(0);
    const [rooms, setRooms] = React.useState({});
    const [commonAttrArr, setCommonAttrArr] = React.useState([]);
    const [singleAttrArr, setSingleAttrArr] = React.useState([]);
    const { settings } = props

    React.useEffect(() => {
        const loadData = async () => {
            try {
                await loadSettings();
                if (window.location.hash.indexOf('step2') !== -1) {
                    setStep(1);
                } else if (window.location.hash.indexOf('step3') !== -1) {
                    setStep(2);
                } else if (window.location.hash.indexOf('step4') !== -1) {
                    setStep(3);
                }
            } catch (err) {
                console.warn('err', err);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, [step]);

    const nextStepCallback = () => {
        setStep(step === maxSteps ? maxSteps : step + 1);
    };

    let displayControll = null;

    switch (step) {
        case 0:
            displayControll = (
                <InstallationForm nextStepCallback={nextStepCallback} properties={props} settings={settings} />
            );
            break;
        case 1:
            displayControll = (
                <InstallationStep2 nextStepCallback={nextStepCallback} rooms={rooms} setRooms={setRooms} />
            );
            break;
        case 2:
            displayControll = (
                <InstallationStep3
                    nextStepCallback={nextStepCallback}
                    commonAttrArr={commonAttrArr}
                    setCommonAttrArr={setCommonAttrArr}
                    rooms={rooms}
                    setRooms={setRooms}
                    singleAttrArr={singleAttrArr}
                    setSingleAttrArr={setSingleAttrArr}
                />
            );
            break;
        case 3:
            displayControll = (
                <InstallationStep4
                    nextStepCallback={nextStepCallback}
                    rooms={rooms}
                    commonAttrArr={commonAttrArr}
                    singleAttrArr={singleAttrArr}
                />
            );
            break;
        default:
        case 4:
            window.location = '/';
            break;
    }

    return (
        <div style={props.style ? props.style : { top: '0px'}} className="keys-background text-center">
            {displayControll}
        </div>
    );
};

export default branch({
    settings: ['state', 'generalSettings'],
}, FirstInstallPage);
