import React from 'react';
import numbro from 'numbro';
import { AdvancePaymentsInterface, InvoicePresenter } from '@common/modelDefinition';
//@ts-ignore
import { useBranch } from 'baobab-react/hooks';

const invoiceTypeEnum = {
    geniue: 0,
    advance: 1,
    proforma: 2,
};

const InvoiceItemsSummary = (props: InvoicePresenter) => {
    const { invoice, translate, invoicePreview } = props;
    const {
        currency,
        exchangeRates,
        totalValue,
        deliveredValue,
        invoiceType,
        advancePaymentsValue,
        generalDiscountValue,
        invoiceValue,
        advancePayments,
        reclamation,
        generalDiscountPercentage,
        terminalType,
    } = invoice;

    const exchangeRatesWhenInvoiceIssued: any = exchangeRates ? JSON.parse(exchangeRates) : {};
    const currencyExchangeRate: number = exchangeRatesWhenInvoiceIssued[currency]
        ? Number(exchangeRatesWhenInvoiceIssued[currency])
        : 1;

    const invoiceValueWithGeneralDiscount: number =
        reclamation !== 2
            ? Number(invoiceValue) - Number(generalDiscountValue)
            : Number(invoiceValue) + Number(generalDiscountValue); // Vraćamo izbijeni popust, sada je invoiceValue negativnog predznaka kod storno računa

    return (
        <div className="">
            <div className="row mt-2">
                <div className="col-md-6">
                    {invoiceType === invoiceTypeEnum.geniue && invoice.id ? (
                        <React.Fragment>
                            <div>
                                <small>
                                    <small>
                                        {translate('Total delivered value')}:{' '}
                                        {deliveredValue
                                            ? numbro(deliveredValue * currencyExchangeRate).format({
                                                  mantissa: 2,
                                                  trimMantissa: false,
                                                  thousandSeparated: true,
                                              })
                                            : ''}{' '}
                                        {currency}
                                    </small>
                                </small>
                            </div>
                            {advancePayments.length > 0 && (
                                <AdvancePayments {...props} currencyExchangeRate={currencyExchangeRate} />
                            )}
                            {generalDiscountPercentage ? (
                                <div className="text-justify">
                                    <small>
                                        <small>
                                            <b>(*)</b> Sukladno Zakonu o turističkoj pristojbi, turistička pristojba se
                                            mora naplatiti u visini propisanoj ovim Zakonom, te se na
                                            nju ne obračunava ukupni popust na račun.
                                        </small>
                                    </small>
                                </div>
                            ) : null}
                        </React.Fragment>
                    ): null}
                </div>

                <div className="col-md-6 text-right">
                    {invoiceType !== invoiceTypeEnum.advance && invoice.id ? (
                        <div>
                            <div>
                                {translate('Invoice value')}:{' '}
                                {invoiceValue
                                    ? numbro(invoiceValue * currencyExchangeRate).format({
                                          mantissa: 2,
                                          trimMantissa: false,
                                          thousandSeparated: true,
                                      })
                                    : ''}{' '}
                                {currency}
                            </div>

                            <GeneralDiscount
                                {...props}
                                invoiceValueWithGeneralDiscount={invoiceValueWithGeneralDiscount}
                                currencyExchangeRate={currencyExchangeRate}
                            />
                        </div>
                    ): null}

                    {advancePaymentsValue &&
                    advancePaymentsValue > 0 &&
                    invoiceType === invoiceTypeEnum.geniue &&
                    terminalType === 'reservation' &&
                    !invoicePreview ? (
                        <div>
                            {translate('Advance payments')}:{reclamation !== 2 ? ' -' : ' '}
                            {numbro(advancePaymentsValue * currencyExchangeRate).format({
                                mantissa: 2,
                                trimMantissa: false,
                                thousandSeparated: true,
                            })}{' '}
                            {currency}
                            <hr className="m-0 mb-3" />
                        </div>
                    ) : null}

                    <h5>
                        {translate('Total')}:{' '}
                        {totalValue !== null && invoice.id
                            ? numbro(totalValue * currencyExchangeRate).format({
                                  mantissa: 2,
                                  trimMantissa: false,
                                  thousandSeparated: true,
                              })
                            : ''}{' '}
                        {invoice.id ? currency : ''}
                    </h5>

                    <hr style={invoice.id ? { borderTop: 'solid 2px black' } : { borderTop: 'solid 2px rgba(0, 0, 0, 0.4)' }} />
                </div>
            </div>
        </div>
    );
};

interface InvoiceItemsSummaryProps extends InvoicePresenter {
    invoiceValueWithGeneralDiscount?: number;
    currencyExchangeRate: number;
}
const GeneralDiscount = (props: InvoiceItemsSummaryProps) => {
    const { invoice, translate, invoiceValueWithGeneralDiscount } = props;

    const {
        generalDiscountValue,
        currencyExchangeRate,
        currency,
        reclamation, // Ako je storno računa ne oduzimamo generalni popust nego ga vraćamo
        generalDiscountPercentage,
    } = invoice;
    if (generalDiscountValue === 0) {
        return null;
    }
    return (
        <div>
            <div>
                (*) {translate('Invoice discount')}: {generalDiscountPercentage}%
            </div>
            <div>
                {translate('Discount value')}:{reclamation !== 2 ? ' -' : ' '}
                {numbro(generalDiscountValue * currencyExchangeRate).format({
                    mantissa: 2,
                    trimMantissa: false,
                    thousandSeparated: true,
                })}{' '}
                {currency}
            </div>

            <hr className="m-0" />

            <div>
                <b>
                    {invoiceValueWithGeneralDiscount
                        ? numbro(invoiceValueWithGeneralDiscount * currencyExchangeRate).format({
                              mantissa: 2,
                              trimMantissa: false,
                              thousandSeparated: true,
                          })
                        : null}{' '}
                    {currency}
                </b>
            </div>
        </div>
    );
};

const AdvancePayments = (props: InvoiceItemsSummaryProps) => {
    const { invoice, translate, currencyExchangeRate, invoicePreview } = props;
    const { advancePayments, currency, terminalType } = invoice;
    if (terminalType !== 'reservation' || invoicePreview) {
        // ne prikazuj na naknadnu potrošnju, na nju ne izdajemo avansne račune
        return null;
    }
    return (
        <ul className="list-group list-group-flush mt-2 mb-2 border-bottom border-top">
            <li className="list-group-item p-1 text-bold">
                <small className="">{translate('Previous advance payments')}</small>
            </li>
            <small>
                {advancePayments.map((a: AdvancePaymentsInterface) => {
                    return (
                        <li key={a.id} className="list-group-item p-1 ">
                            <div className="row d-flex justify-content-between">
                                <div className="col-2">
                                    <small>
                                        {'#'}
                                        {a.id}
                                    </small>
                                </div>
                                <div className="col-4">
                                    {' '}
                                    <small>{a.itemName}</small>
                                </div>
                                <div className="col-3">
                                    {' '}
                                    <small>{a.invoiceNumber}</small>
                                </div>
                                <div className="col-3">
                                    <small>
                                        {numbro(a.paymentAmount * currencyExchangeRate).format({
                                            mantissa: 2,
                                            trimMantissa: false,
                                            thousandSeparated: true,
                                        })}{' '}
                                        {currency}
                                    </small>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </small>
        </ul>
    );
};

const NoteAndReclamation = (props: InvoicePresenter) => {
    const { invoice, translate } = props;
    const { note, reclamationReason, vatRegistered /*generalDiscountPercentage*/ } = invoice;

    const { otherSettings } = useBranch({
        otherSettings: ['otherSettings'],
    });

    const invoiceNote: string = otherSettings && otherSettings.taxFreeNote ? otherSettings.taxFreeNote : '';

    // Prilagoditi napomenu odabranoj državi
    return (
        <div className="col-12 m-0 p-0 mt-4">
            {!vatRegistered && (
                <div>
                    <small>
                        <b>{translate('Note')}:</b> {translate(invoiceNote)}
                    </small>
                </div>
            )}
            {reclamationReason && (
                <div>
                    <small>
                        <b>{translate('Reclamation reason')}:</b> {reclamationReason}
                    </small>
                </div>
            )}

            {note && (
                <div>
                    <small>
                        <b>{translate('Note')}:</b> <small>{note}</small>
                    </small>
                </div>
            )}
        </div>
    );
};

export { NoteAndReclamation };

export default InvoiceItemsSummary;
