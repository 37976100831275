import React from 'react';
import { translate } from '@data/translations';
import PageTitle from '@components/pageTitle';
import RoomReservationsTimeline from '@pages/reservations2/parts/RoomReservationsTimeline';
import ReservationDateTimeSelector from '@pages/reservations2/parts/ReservationDateTimeSelector';
import { getCheckInAndCheckoutTimestamps } from '@pages/reservations2/utils';
import { ReservationController, Reservation, ValidationParams } from '@pages/reservations2/resController';
import MiniLoader from '@components/loaders/miniLoader';
import OverlappingReservations from '@pages/reservations2/parts/OverlappingReservations';
import StatusButtons from '@pages/reservations2/parts/StatusButtons';
import LoadingButton from '@components/buttons/loadingButton';
import { reservationStatusColors, reservationStatusEnumsDesc } from '@data/reservationStatusEnums';
import ReservationValidationMessageList from '@pages/reservations2/parts/ReservationValidationMessageList';
import { getOccupancyOverview } from './condoApi';

//@ts-ignore
import { RouteComponentProps } from 'react-router-dom';
import {
    ReservationModel,
    AccommodationPricelistModel,
    ReservationEditModel,
    RoomInfoEditModel,
    ReservationStatus,
    UserInfo,
} from '@common/modelDefinition';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';

import { SRCClearRoomCodes } from '@components/SRCClearRoomCodes';

import RoomInfoAndReservationsInfo from './RoomInfoAndReservationsInfo';

const ctrl = new ReservationController();
interface CondoReservationFormProps {
    match: any;
    condoPriceLists: AccommodationPricelistModel[];
    otherSettings: any;
    isPending: boolean;
    location: any;
    loggedUser: UserInfo;
    reservations: Reservation[]; // Rezzervacija koju kreiramo ili loadamo, reservationForm2, može biti samo jedna
    history: RouteComponentProps['history'];
}

const condoValidationParams: ValidationParams = {
    checkInTimestamp: true,
    accommodationPriceListIdOrOpenPrice: true,
    roomInfoId: true,
    groupReservationHolder: false,
    reservationHolder: true,
};

const CondoReservationForm = (props: CondoReservationFormProps) => {
    const {
        match,
        loggedUser,
        location,
        condoPriceLists = [],
        otherSettings,
        isPending,
        history,
        reservations,
    } = props;

    const roomInfoId = Number(match.params.roomInfoId); //should be always present!!!
    const reservationId = match?.params?.reservationId ? Number(match.params.reservationId) : null;

    const [occupancyOverview, setOccupancyOverview] = React.useState<any>([]);
    const [numberOfDaysForCurrentEditingReservation, setNumberOfDaysForCurrentRes] = React.useState<any>(0);

    React.useEffect(() => {
        let numberOfDaysForEditingReservations = 0
        if(reservationId){
            let resFromDb = reservations.find(resFrDB => {return resFrDB.id === reservationId})
            if(resFromDb){
                numberOfDaysForEditingReservations = Math.ceil(
                    moment
                        .duration(
                            moment(resFromDb.checkOutTimestamp).endOf('day').diff(moment(resFromDb.checkInTimestamp).endOf('day'))
                        )
                        .asDays()
                );
            }
        }
        setNumberOfDaysForCurrentRes(numberOfDaysForEditingReservations)
    
    }, [reservationId, reservations]);

    

    React.useEffect(() => {
        if (reservationId) {
            ctrl.loadCondoReservation(reservationId);
        } else {
            const { checkInTimestamp, checkOutTimestamp } = getCheckInAndCheckoutTimestamps(location, otherSettings);
            const clearPrevious = true;
            const adults = 1;
            const condoPriceListId = condoPriceLists.length > 0 ? condoPriceLists[0].id : undefined;
            ctrl.addReservation(
                clearPrevious,
                roomInfoId,
                checkInTimestamp,
                checkOutTimestamp,
                adults,
                condoPriceListId,
                loggedUser.customerId
            );
        }
        const loadData = async () => {
            try {
                if (occupancyOverview.length === 0) {
                    const overview = await getOccupancyOverview(loggedUser.uuid);
                    setOccupancyOverview(overview);
                }
            } catch (err) {
                console.warn(err);
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });

        return () => {
            ctrl.clearAll(); //cleanup on unmount
        };
        // eslint-disable-next-line
    }, [reservationId, roomInfoId]);

    const res: Reservation = ctrl.getActiveReservation();
    const reservation = res ? reservations.find((r) => r.id === res.id) : null;
    if (!reservation || !reservation.RoomInfo) {
        return <MiniLoader />;
    }
    const isReadOnly = reservationId && reservation.customerId !== loggedUser.customerId;
    const formPageTitle = reservation.RoomInfo
        ? `${reservation.RoomInfo.name} / ${loggedUser.name} ${loggedUser.surname}`
        : '';
    const isCheckedInOnLoad = reservation.statusEnum === ReservationStatus.checkedIn;
    const isAutoBlocked = reservation.statusEnum === ReservationStatus.autoBlocked;

    const onReservationInputChange = (e: any) => {
        const res = { ...reservation };
        if (e.target.name === 'statusEnum') {
            (res as any)[e.target.name] = Number(e.target.value);
            if (Number(e.target.value) === 2) {
                (res as any)['checkInTimestamp'] = new Date().getTime();
            }
        } else {
            (res as any)[e.target.name] = e.target.value;
        }
        ctrl.updateActiveReservation(res as ReservationModel);
    };

    let hideSaveButtonDueToOverdraft = false
    const reservationNumOfDays: number = Math.ceil(
        moment
            .duration(
                moment(res.checkOutTimestamp).endOf('day').diff(moment(res.checkInTimestamp).endOf('day'))
            )
            .asDays()
    );

    const occupancyObj = occupancyOverview.find((ov:any) => {return ov.roomInfoId === res.roomInfoId})
    let condoDaysLeftForThisRoom = occupancyObj?.condoUserPersonalDaysPerYear - occupancyObj?.condoUserPersonalDaysAccomplished 
    condoDaysLeftForThisRoom = condoDaysLeftForThisRoom - reservationNumOfDays
    
    if(reservationId){
        condoDaysLeftForThisRoom = condoDaysLeftForThisRoom + numberOfDaysForCurrentEditingReservation
    }

    if (condoDaysLeftForThisRoom < 0) {
        hideSaveButtonDueToOverdraft = true
    }
        
    let showCheckout = true;
    const activeProformaInvoices = reservation.Invoices
        ? reservation.Invoices.filter(
              // Računi su dohvaćeni filtrirani kao predračini s pos terminala ili minibara
              (pi: any) => pi.connectedInvoiceProcessed !== 1
          )
        : [];

    const minibarConsumption = reservation.MinibarLogs
        ? reservation.MinibarLogs.filter((ml: any) => !ml.InvoiceItem)
        : [];
    if (activeProformaInvoices.length > 0 || minibarConsumption.length > 0) {
        showCheckout = false;
    }

    return (
        <div className="container">
            <PageTitle title={translate('Room reservation')} />
            <PageTitle title={formPageTitle} />

            <RoomReservationsTimeline reservation={reservation} />

            <div className="row">
                <div className="col-md-12">
                    {isReadOnly && reservation ? (
                        <div>
                            <h4 className="text-success mt-2 text-center">
                                {' '}
                                - {translate('Comercial reservation')} -{' '}
                            </h4>
                            <div>
                                <b>{translate('Check in')}:</b> {moment(reservation.checkInTimestamp).format('llll')}
                            </div>
                            <div>
                                <b>{translate('Check out')}:</b> {moment(reservation.checkOutTimestamp).format('llll')}
                            </div>
                        </div>
                    ) : (
                        <div className="col-md-6">
                            <ReservationDateTimeSelector
                                reservation={reservation}
                                onReservationUpdate={(reservation: ReservationEditModel) => {
                                    const res = { ...reservation };
                                    ctrl.updateActiveReservation(res);
                                }}
                            />
                        </div>
                    )}
                </div>
            </div>

            {!isReadOnly ? (
                <div className="text-center mt-3">
                    {reservationId ? (
                        <div>
                            {showCheckout ? (
                                <div>
                                    <StatusButtons
                                        statusButtonActionHandler={(action: string) => {
                                            const res = { ...reservation };
                                            if (action === 'confirm') {
                                                res.statusEnum = ReservationStatus.confirmed;
                                            } else if (action === 'checkin') {
                                                res.statusEnum = ReservationStatus.checkedIn;
                                                res.checkInTimestamp = Date.now();
                                            } else if (action === 'cancel') {
                                                res.statusEnum = ReservationStatus.canceled;
                                            } else if (action === 'checkout') {
                                                res.statusEnum = ReservationStatus.closed;
                                                res.checkOutTimestamp = moment().valueOf();
                                            }
                                            if (
                                                res.statusEnum === ReservationStatus.checkedIn ||
                                                res.statusEnum === ReservationStatus.closed
                                            ) {
                                                // Želimo izbjeći promjenu statusa ako ta promjena nije validna
                                                ctrl.updateActiveReservation(res as ReservationModel);
                                                const { isFullyValid } = ctrl.getFullValidationReport(condoValidationParams);
                                                if (!isFullyValid) {
                                                    if (res.statusEnum === ReservationStatus.checkedIn) {
                                                        // Ako nam ne dopusti check in, vrati na status confirmed
                                                        res.statusEnum = ReservationStatus.confirmed;
                                                    } else if (res.statusEnum === ReservationStatus.closed) {
                                                        // Ako nam ne dopusti check out, vrati na status checked in
                                                        res.statusEnum = ReservationStatus.checkedIn;
                                                    }
                                                    ctrl.updateActiveReservation(res as ReservationModel);
                                                }
                                            } else {
                                                ctrl.updateActiveReservation(res as ReservationModel);
                                            }
                                        }}
                                        reservation={reservation}
                                        history={history}
                                        isPending={isPending}
                                    />

                                    {reservation.statusEnum === ReservationStatus.closed && reservation.RoomInfo && (
                                        <SRCClearRoomCodes roomInfo={reservation.RoomInfo as RoomInfoEditModel} />
                                    )}
                                </div>
                            ) : (
                                <div>
                                    {translate('Before check-out, you need to go to the reception for invoicing!')}
                                </div>
                            )}

                            <h5 className={' mt-2 ' + reservationStatusColors[reservation.statusEnum]}>
                                {reservationStatusEnumsDesc[reservation.statusEnum]}{' '}
                            </h5>
                        </div>
                    ) : (
                        <div className="mt-2">
                            <div className="form-inline">
                                <div>
                                    <b>{translate('Reservation status')}:</b>&ensp;
                                </div>
                                <select
                                    onChange={onReservationInputChange}
                                    name="statusEnum"
                                    value={reservation.statusEnum}
                                    className="form-control form-control-sm"
                                >
                                    <option disabled={isAutoBlocked || isCheckedInOnLoad} value={0}>
                                        {translate('Early bird')}
                                    </option>
                                    <option disabled={isCheckedInOnLoad} value={1}>
                                        {translate('Confirmed')}
                                    </option>
                                    <option disabled={reservation?.id ? false : true} value={2}>
                                        {translate('Checked in')}
                                    </option>
                                    <option disabled={reservation?.id ? false : true} value={3}>
                                        {translate('Canceled')}
                                    </option>
                                    <option disabled={reservation.statusEnum !== ReservationStatus.closed} value={4}>
                                        {translate('Close')}
                                    </option>
                                    <option disabled={true} value={5}>
                                        {translate('Auto blocked')}
                                    </option>
                                </select>
                            </div>
                        </div>
                    )}
                </div>
            ) : null}
            <br />
            <div>{translate("Remaining number days for condo reservations")}: {condoDaysLeftForThisRoom}</div>
            <ReservationValidationMessageList validationParams={condoValidationParams}>
                <div className="text-center ">
                    <LoadingButton
                        className="btn btn-primary mr-2"
                        disabled={!isPending || hideSaveButtonDueToOverdraft}
                        onClick={async () => {
                            ctrl.setCondoUserUuid(loggedUser.uuid);
                            const redirectTo = await ctrl.save(false);
                            if (redirectTo) {
                                (window as any).location = `/#` + redirectTo;
                            }
                        }}
                    >
                        {reservation.id ? translate('Update') : translate('Save')}
                    </LoadingButton>
                    {hideSaveButtonDueToOverdraft ? <div className='text-danger'>Maximum number of days for condo reservations exceeded</div> : null}
                </div>
            </ReservationValidationMessageList>
            <OverlappingReservations reservation={reservation} />

            <div className="mt-2">
                <RoomInfoAndReservationsInfo roomInfoId={roomInfoId} />
            </div>
        </div>
    );
};

export default branch(
    {
        otherSettings: ['otherSettings'],
        condoPriceLists: ['model', 'AccommodationPriceList'],
        reservations: ['state', 'reservationForm2', 'reservations'],
        loggedUser: ['authTokenInfo', 'user'],
        isPending: ['state', 'reservationForm2', 'isPending'],
    },
    CondoReservationForm
);