import React, { Component } from 'react';

class DailyFiscalClosure extends Component {
    constructor() {
        super();
        this.state = {};
    }

    render() {
        const { selectedFiscalPrinter } = this.props;
        // let payload = {}
        return (
            <div className="col-12 col-md-6 p-0 m-0 mb-2 pull-left ">
                <div className="m-1 card bg-white h-100 ">
                    <div className="card-body">
                        <h5 className="card-title">{window.translate('Daily fiscal closure')}</h5>
                        <button
                            className="btn btn-sm btn-primary mr-2 mt-2"
                            onClick={this.props.showConfirmModal.bind(this, {
                                option: 'dailyFiscalClosure',
                                fiscalPrinter: selectedFiscalPrinter,
                                reportType: 'xReport',
                            })}
                        >
                            {window.translate('X REPORT')}
                        </button>
                        <button
                            className="btn btn-sm btn-primary mr-2 mt-2"
                            onClick={this.props.showConfirmModal.bind(this, {
                                option: 'dailyFiscalClosure',
                                fiscalPrinter: selectedFiscalPrinter,
                                reportType: 'zReport',
                            })}
                        >
                            {window.translate('Z REPORT')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default DailyFiscalClosure;
