import React, { Component } from 'react';
import _ from 'lodash';
import rest from '../../data/restWrapper';

class IOConfiguration extends Component {
    constructor(props) {
        super(props);
        this.state = {
            hwMap: props.roomSettings.hwMap,
            availableFunc: props.roomSettings.availableFunc,
            activeLink: 'SRC',
            showBody: false,
            changesSaved: true,
            updatedIOs: [],
        };
    }

    setActivePage(type) {
        this.setState({
            activeLink: type,
        });
    }

    async saveChanges() {
        try {
            let remoteDeviceId = this.props.match.params.remoteDeviceId;
            _.forEach(this.state.updatedIOs, async (pin) => {
                // console.log("SPREMI PIN", pin);
                let basicIoPayload = {
                    ioName: pin.func,
                    pinAddress: pin.icPin,
                    driverName: pin.driver,
                };

                let ioOverridePayload = {
                    ioName: pin.func,
                    targetValue: pin.emulated ? pin.emulatedValue : 2,
                };
                if (this.props.onSaveHandler) {
                    this.props.onSaveHandler('hwMap', this.state.hwMap);
                } else {
                    /* let setBasicIo =*/ await rest('/src/setBasicIOConfig/' + remoteDeviceId, 'POST', basicIoPayload);
                    /* let setIoOverride = */ await rest(
                        '/src/setIOOverride/' + remoteDeviceId,
                        'POST',
                        ioOverridePayload
                    );
                }
                // console.log("REZULTAT",setBasicIo, setIoOverride, );
            });

            this.setState({ changesSaved: true, updatedIOs: [] });
        } catch (err) {
            console.warn(err);
        }
    }

    updateIO(pin, e) {
        // console.log(e.target.name, e.target.value, pin);
        let hwMap = this.state.hwMap;
        let updatedIOs = this.state.updatedIOs;
        _.forEach(hwMap, (h) => {
            if (h.device === pin.device && h.name === pin.name) {
                if (e.target.name === 'emulatedValue') {
                    h[e.target.name] = parseInt(e.target.value, 10);
                    h['emulated'] = h[e.target.name] === -1 ? false : true;
                } else {
                    h[e.target.name] = e.target.value;
                }
                updatedIOs.push(pin);
            }
        });
        this.setState({ hwMap, changesSaved: false, updatedIOs });
    }

    getFunctionOptions(pin) {
        let pinType = pin.type;
        let takenFunctions = _.map(this.state.hwMap, 'func');
        let options = [
            <option /*disabled={true}*/ key="null" value="">
                {window.translate('Nothing selected')}
            </option>,
        ];
        if (pinType === 'input') {
            _.forEach(this.state.availableFunc['inputs'], (funcName, key) => {
                if (!_.includes(takenFunctions, funcName) || funcName === pin.func) {
                    options.push(
                        <option key={key} value={funcName}>
                            {funcName}
                        </option>
                    );
                }
            });
        } else if (pinType === 'output') {
            _.forEach(this.state.availableFunc['outputs'], (funcName, fnKey) => {
                if (!_.includes(takenFunctions, funcName) || funcName === pin.func) {
                    options.push(
                        <option key={fnKey} value={funcName}>
                            {funcName}
                        </option>
                    );
                }
            });
        }
        return options;
    }

    isConfigChangedFromLastLoad(pin) {
        let res = false;
        _.forEach(this.state.updatedIOs, (updatedPin) => {
            if (pin.device === updatedPin.device && pin.name === updatedPin.name) {
                res = true;
                return true; //break foreach
            }
        });

        return res;
    }

    render() {
        let ioBody = [];
        let pinsGroupedByDevice = _.groupBy(this.state.hwMap, 'device');
        let navItems = [];
        _.forIn(pinsGroupedByDevice, (val, key) => {
            if (_.includes(this.props.roomSettings.availableDevices, key)) {
                navItems.push(
                    <li key={key} className="nav-item">
                        <span
                            onClick={this.setActivePage.bind(this, key)}
                            className={(this.state.activeLink === key ? 'nav-link active' : 'nav-link') + ' pointer'}
                        >
                            {key}
                        </span>
                    </li>
                );
            }
        });

        let inputPins = [];
        let outputPins = [];

        _.forEach(pinsGroupedByDevice[this.state.activeLink], (pin, pinKey) => {
            let options = this.getFunctionOptions(pin);
            let emulationValueString = pin.emulated === false ? -1 : pin.emulatedValue;

            let template = (
                <div className="row" key={pinKey}>
                    <div className="col-md-2">
                        <label>
                            <strong>{pin.name}:</strong>{' '}
                        </label>
                        {this.isConfigChangedFromLastLoad(pin) ? <span className="text-danger">** </span> : null}
                    </div>
                    <div className="col-md-6">
                        <select
                            className="form-control form-control-sm"
                            name="func"
                            onChange={this.updateIO.bind(this, pin)}
                            value={pin.func ? pin.func : window.translate('Nothing selected')}
                        >
                            {options}
                        </select>
                    </div>
                    <div className="col-md-4">
                        <select
                            className="form-control form-control-sm"
                            name="emulatedValue"
                            onChange={this.updateIO.bind(this, pin)}
                            value={emulationValueString}
                        >
                            <option value={-1}>{window.translate('EMULATION DISABLED')}</option>
                            <option value={1}>{window.translate('CLOSED')}</option>
                            <option value={0}>{window.translate('OPEN')}</option>
                        </select>
                    </div>
                </div>
            );

            if (!window.enableSrcPins) {
                if (pin.enabled) {
                    if (pin.type === 'input') {
                        inputPins.push(template);
                    } else {
                        outputPins.push(template);
                    }
                }
            } else {
                if (pin.type === 'input') {
                    inputPins.push(template);
                } else {
                    outputPins.push(template);
                }
            }
        });

        ioBody = (
            <div className="row">
                <div
                    className={
                        inputPins.length > 0 ? 'col-xs-6 col-md-6 col-xl-6 col-lg-6 text-center' : 'hidden display-none'
                    }
                >
                    <h3>{window.translate('inputs')}:</h3>
                    {inputPins}
                </div>
                <div
                    className={
                        outputPins.length > 0
                            ? 'col-xs-6 col-md-6 col-xl-6 col-lg-6 text-center'
                            : 'hidden display-none'
                    }
                >
                    <h3>{window.translate('outputs')}:</h3>
                    {outputPins}
                </div>
            </div>
        );

        return (
            <div className="card mt-2 mb-5">
                <div
                    onClick={() => {
                        this.setState({ showBody: !this.state.showBody });
                    }}
                    className="card-header bg-dark text-light text-center pointer"
                >
                    {window.translate('SRC IO CONFIGURATION')}
                    {this.state.showBody ? (
                        <i className="fa fa-compress ml-2 mr-2" aria-hidden="true"></i>
                    ) : (
                        <i className="fa fa-expand ml-2 mr-2" aria-hidden="true"></i>
                    )}
                </div>

                {this.state.showBody ? (
                    <div className="card-body">
                        <div className="card text-center">
                            <div className="card-header">
                                <ul className="nav nav-tabs card-header-tabs">{navItems}</ul>
                            </div>
                            <div className="card-body">
                                {ioBody}
                                <div className="mt-2">
                                    <button
                                        onClick={this.saveChanges.bind(this)}
                                        className={this.state.changesSaved ? 'btn btn-info disabled' : 'btn btn-info'}
                                    >
                                        {window.translate('Update')}
                                    </button>
                                </div>
                            </div>
                        </div>
                    </div>
                ) : null}
            </div>
        );
    }
}

export default IOConfiguration;