import React from 'react';
import { branch } from 'baobab-react/higher-order';
import baobab from '../../data/state/index';
import { getRoomSRCStatusIconByLastSeenTimestamp } from '../../data/controllers/SRCStatus';
import rest from '../../data/restWrapper';
import baobabTree, { saveCachedBaobabData } from '../../data/state/index';
//const srcSettingsMap = {};

const calcDashboard = ({ rooms, eventTickets, src, SRCEnums, alarmInterpreter }) => {
    const result = [];
    const roomModeInterpreter = alarmInterpreter?.roomModeInterpreter ? alarmInterpreter.roomModeInterpreter : {};
    if (!SRCEnums.ioNamedEnum) {
        return result;
    }
    const dashboardItemTemplate = {
        roomInfoId: null,
        roomName: '',
        lastSeenTimestamp: null,
        inputs: {},
        outputs: {},

        minibar: false,
        cleaningStatus: '', //cleaningStatus.noActionTaken,
        minTemperature: 5,
        maxTemperature: 26,
        targetTemperature: 22,
        currentTemperature: 21,
        roomMode: '',
    };

    const evTicketRoomMap = {};
    eventTickets.forEach((ev) => {
        if (evTicketRoomMap[ev.roomInfoId] === undefined) {
            evTicketRoomMap[ev.roomInfoId] = {};
        }
        evTicketRoomMap[ev.roomInfoId][ev.ruleName] = ev;
    });

    const ioKeys = Object.keys(SRCEnums.ioNamedEnum);

    const srcMap = {};
    src.forEach((s) => {
        srcMap[s.remoteDeviceId] = s;
    });

    for (const room of rooms) {
        if (evTicketRoomMap[room.id]) {
            //ili nema HW-a

            const temp = { ...dashboardItemTemplate, inputs: {} };
            temp.roomInfoId = room.id;
            temp.roomName = room.name;
            temp.lastSeenTimestamp = srcMap[room.remoteDeviceId] ? srcMap[room.remoteDeviceId].lastSeenTimestamp : 0;

            temp.lastCleaningDate = new Date(room.cleaningModeActivatedTs).toISOString(true).substr(0, 10);

            for (let i = 0; i < ioKeys.length; i++) {
                const ioName = ioKeys[i];
                const isInput = SRCEnums.inputs.includes(ioKeys[i]); //_.includes(SRCEnums.inputs, ioKeys[i]); //else are output
                const inputOpenOutputClosed = room.SRCIOStates & (1 << (31 - i));
                if (isInput) {
                    temp.inputs[ioName] = inputOpenOutputClosed; //false for closed
                } else {
                    temp.outputs[ioName] = inputOpenOutputClosed === 0; // false for off
                }
            }

            if (evTicketRoomMap[room.id]['alarm_ecoRoomMode']) {
                temp.roomMode = roomModeInterpreter['ECO'];
            } else if (evTicketRoomMap[room.id]['alarm_comfortRoomMode']) {
                temp.roomMode = roomModeInterpreter['COMFORT'];
            } else if (evTicketRoomMap[room.id]['alarm_preComfortRoomMode']) {
                temp.roomMode = roomModeInterpreter['PRECOMFORT'];
            } else if (evTicketRoomMap[room.id]['alarm_cleaningRoomMode']) {
                temp.roomMode = 'CLN';
            }
            temp.minibar = evTicketRoomMap[room.id]['customAlarm_minibarNotClosed'] !== undefined;
            result.push(temp);
        } else {
            //console.log('ZAPELO', evTicketRoomMap[room.id], room.id);
        }
    }

    return result;
};

const HwRoomView = (props) => {
    const { history, rooms, alarmInterpreter, columnsNumber } = props;
    const { eventTickets, src, SRCEnums } = props;
    /*const cursor = useBranch({
eventTickets: ['model', 'RoomEventTicket'],
src: ['model', 'SmartRoomController'],
SRCEnums: ['SRCEnums'],
});*/
    const srcStatusDashboard = calcDashboard({ eventTickets, src, SRCEnums, rooms, alarmInterpreter });

    let numberOfItems = srcStatusDashboard.length;
    let maxNumberOfItemsPerColumn = Math.ceil(numberOfItems / columnsNumber);

    let clmnArray = [];

    React.useEffect(() => {
        const loadData = async () => {
            try {
                const data = await rest('/getGeneralSettingByKey/alarmInterpreter');
                if (data !== null) {
                    baobab.root.select('state', 'alarmInterpreter').set(JSON.parse(data.params));
                }
            } catch (err) {
                console.log('error', err);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, []);

    for (let i = 0; i < columnsNumber; i++) {
        clmnArray.push(i);
    }

    return (
        <div className="w-100">
            <div className="d-flex justify-content-center align-items-center my-1">
                <div
                    className="px-3 btn increase-decrease-button pointer"
                    onClick={() => {
                        if (columnsNumber > 1) {
                            // baobab.root.select('uiStates', 'columnsNumber').set(columnsNumber - 1);
                            baobabTree.root.select(['uiStates', 'columnsNumber']).set(columnsNumber - 1);
                            saveCachedBaobabData(baobabTree);
                        }
                    }}
                >
                    -
                </div>
                <div className=" px-1 d-inline-block">Columns: {columnsNumber}</div>
                <div
                    className="px-3 btn increase-decrease-button pointer"
                    onClick={() => {
                        // baobab.root.select('uiStates', 'columnsNumber').set(columnsNumber + 1);
                        baobabTree.root.select(['uiStates', 'columnsNumber']).set(columnsNumber + 1);
                        saveCachedBaobabData(baobabTree);
                    }}
                >
                    +
                </div>
            </div>

            {srcStatusDashboard.map((val, ind) => {
                if (ind < maxNumberOfItemsPerColumn) {
                    return (
                        <div key={ind} className="d-flex w-100">
                            {clmnArray.map((itm, i) => {
                                if (srcStatusDashboard[ind + i * maxNumberOfItemsPerColumn]) {
                                    return (
                                        <div
                                            key={ind + '_' + i}
                                            className="d-flex justify-content-between"
                                            style={{ flexBasis: `${100 / columnsNumber}%` }}
                                        >
                                            <HWLine
                                                history={history}
                                                dashboardObj={{
                                                    ...srcStatusDashboard[ind + i * maxNumberOfItemsPerColumn],
                                                }}
                                                alarmInterpreter={alarmInterpreter}
                                            ></HWLine>
                                        </div>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </div>
                    );
                } else {
                    return null;
                }
            })}
        </div>
    );
};

const HWLine = ({ history, dashboardObj: i, alarmInterpreter }) => {
    return (
        <small
            className="w-100"
            style={{ backgroundColor: 'white', margin: '1px', marginRight: '5px', border: '1px solid #ccc' }}
        >
            <div
                className="pointer"
                onClick={() => {
                    history.push('/roomInfo/' + i.roomInfoId);
                }}
                style={{ display: 'flex', justifyContent: 'space-between' }}
            >
                <div className="px-1 elipsis" style={{ flex: 1 }}>
                    {getRoomSRCStatusIconByLastSeenTimestamp(i.lastSeenTimestamp)}
                </div>
                <div className="px-1 elipsis" style={{ flex: 1 }}>
                    {i.roomName}
                </div>
                {HWIcon(i, 'minibarStateInput', alarmInterpreter)}
                {HWIcon(i, 'cardHolderInput', alarmInterpreter)}
                {HWIcon(i, 'floodAlarmInput', alarmInterpreter)}
                {HWIcon(i, 'windowStateInput', alarmInterpreter)}
                {HWIcon(i, 'doorStateInput', alarmInterpreter)}

                <div className="px-1 elipsis" style={{ flex: 1 }}>
                    {i.lastCleaningDate === new Date().toISOString(true).substr(0, 10) ? (
                        <i className="fa fa-female text-success" />
                    ) : (
                        <i className="fa fa-female" />
                    )}
                </div>

                {/*<div style={{ width: '30px' }}>{HWIcon(i, 'sosInput')}</div> //ovo nisu state inputi, već event inputi
<div style={{ width: '30px' }}>{HWIcon(i, 'doNotDisturbInput')}</div>
<div style={{ width: '30px' }}>{HWIcon(i, 'cleaningServiceInput')}</div>*/}

                <div className="px-1 elipsis" style={{ width: '80px' }}>
                    {i.roomMode}
                </div>
            </div>
        </small>
    );
};

const HWIcon = (dashboardObj, inputName, alarmInterpreter) => {
    let ioSRC = {};
    ioSRC['inputs'] = alarmInterpreter?.inputInterpreter
        ? alarmInterpreter.inputInterpreter.filter((i) => i.enabled)
        : [];
    ioSRC['outputs'] = [];

    ioSRC['inputMap'] = {};
    ioSRC.inputs.forEach((i) => {
        ioSRC['inputMap'][i.name] = i;
    });

    const desc = ioSRC.inputMap[inputName];
    if (inputName === 'floodAlarmInput' || inputName === 'cardHolderInput') {
        const isInputClosed = dashboardObj.inputs[inputName] === 0; //inputi su zatvoreni ako postoji i ako je false!!!
        //alarm se pali na true state
        const iconStyle = isInputClosed
            ? `${desc?.icon} ${desc?.falseStateAnimationBlink === 'true' ? ' animated infinite flash ' : ''}`
            : `${desc?.icon} ${desc?.trueStateAnimationBlink === 'true' ? ' animated infinite flash ' : ''}`;
        if (desc && desc.enabled) {
            return (
                <div className="px-1 elipsis" style={{ flex: 1 }}>
                    <i
                        className={iconStyle}
                        style={{ color: isInputClosed ? desc?.falseStateIconColor : desc?.trueStateIconColor }}
                    />
                </div>
            );
        } else {
            return null;
        }
    } else {
        const isInputClosed = dashboardObj.inputs[inputName] === 0; //inputi su zatvoreni ako postoji i ako je false!!!
        //alarm se pali na false state
        const iconStyle = !isInputClosed
            ? `${desc?.icon} ${desc?.falseStateAnimationBlink === 'true' ? ' animated infinite flash ' : ''}`
            : desc?.icon;
        if (desc && desc.enabled) {
            return (
                <div className="px-1 elipsis" style={{ flex: 1 }}>
                    <i
                        className={iconStyle}
                        style={{ color: !isInputClosed ? desc?.falseStateIconColor : desc?.trueStateIconColor }}
                    />
                </div>
            );
        } else {
            return null;
        }
    }
};

export default branch(
    {
        eventTickets: ['model', 'RoomEventTicket'],
        src: ['model', 'SmartRoomController'],
        SRCEnums: ['SRCEnums'],
        alarmInterpreter: ['state', 'alarmInterpreter'],
        columnsNumber: ['uiStates', 'columnsNumber'],
    },
    HwRoomView
);
