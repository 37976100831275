import React from 'react';
import PageTitle from '../../components/pageTitle';
import { branch } from 'baobab-react/higher-order';
import { post, put, del } from '../../data/restWrapper';
import baobab from '../../data/state';

import RoomSelector from './RoomSelector';

const GroupView = (props) => {
    const { rooms, onGroupUpdate, group, sosGroups, sosEventTickets } = props;

    var allRoomInfoIds = [];
    var allMasterIds = [];
    for (const key in sosGroups) {
        if (sosGroups[key].roomInfoIds) {
            let temp = JSON.parse(sosGroups[key].roomInfoIds);
            allRoomInfoIds = allRoomInfoIds.concat(temp);
        }
         if (sosGroups[key].masterRoomInfoData) {
             let temp = JSON.parse(sosGroups[key].masterRoomInfoData);
             allMasterIds = allMasterIds.concat(temp.map((m) => {return m.roomInfoId}));
         }
    }

    const roomInfoIds = group.roomInfoIds ? JSON.parse(group.roomInfoIds) : [];
    const masterRoomInfoData = group.masterRoomInfoData ? JSON.parse(group.masterRoomInfoData) : [];
    const masterRoomInfoIds = masterRoomInfoData.map((m) => m.roomInfoId);

    const [selectMode, setSelectMode] = React.useState(false);
    const [groupName, setGroupName] = React.useState(group.name);

    const updateRoomSelection = (newRoomInfoIds) => {
        onGroupUpdate({ roomInfoIds: JSON.stringify(newRoomInfoIds) });
    };

    const updateMasterRoomSelection = (newMasterRoomInfoData) => {
        onGroupUpdate({ masterRoomInfoData: JSON.stringify(newMasterRoomInfoData) });
    };

    const toggleListenSos = (targetMasterInfo) => {
        targetMasterInfo.listenSos = !targetMasterInfo.listenSos;
        updateMasterRoomSelection(masterRoomInfoData);
    };

    const toggleListenRoomService = (targetMasterInfo) => {
        targetMasterInfo.listenRS = !targetMasterInfo.listenRS;
        updateMasterRoomSelection(masterRoomInfoData);
    };

    const groupedRooms = rooms.filter((room) => roomInfoIds.includes(room.id));
    const masterRooms = rooms.filter((room) => masterRoomInfoIds.includes(room.id));

    const groupedRoomsView = groupedRooms.concat(masterRooms).map((room) => {
        let isSosActive = sosEventTickets.find((t) => t.roomInfoId === room.id);

        let icon = room.remoteDeviceId ? (
            <i className={isSosActive ? `fa fa-bell text-info blink-animation` : 'fa fa-bell text-info'} />
        ) : (
            <small>
                <small>NO HW</small>
            </small>
        );

        let masterSwitchProps = null;
        let roomName = room.name;
        let targetMasterInfo = masterRoomInfoData.find((m) => m.roomInfoId === room.id);

        //console.log(targetMasterInfo);

        let listenSos = targetMasterInfo ? targetMasterInfo.listenSos : false;
        let listenRS = targetMasterInfo ? targetMasterInfo.listenRS : false;

        if (masterRoomInfoIds.includes(room.id)) {
            //roomName = null;
            icon = (
                <i
                    className={isSosActive ? 'fa fa-medkit text-danger  blink-animation ' : 'fa fa-medkit text-danger'}
                />
            );
            masterSwitchProps = (
                <div className="mt-1">
                    <small>
                        SOS:{' '}
                        <input
                            //disabled={true}
                            onChange={() => {
                                toggleListenSos(targetMasterInfo);
                            }}
                            checked={listenSos}
                            type="checkbox"
                        />
                        <br />
                        CALL:{' '}
                        <input
                            //disabled={true}
                            onChange={() => {
                                toggleListenRoomService(targetMasterInfo);
                            }}
                            checked={listenRS}
                            type="checkbox"
                        />
                    </small>
                </div>
            );
        }

        return (
            <div
                key={room.id}
                style={{ height: '100px', width: '100px' }}
                className="d-flex flex-column  align-items-end text-center justify-content-center align-items-center border rounded mr-2 mb-2"
            >
                <div className="mb-2">
                    <small>{roomName}</small>
                </div>
                {icon}
                {masterSwitchProps}
            </div>
        );
    });

    return (
        <div className="bg-white border rounded p-2 mt-2">
            <div className="row">
                <div className="col-sm-12">
                    <div className="form-inline">
                        <button
                            onClick={() => {
                                //setSelectMode(!selectMode);
                                setSelectMode('sos');
                            }}
                            className="btn bg-white border mb-2 mr-2"
                        >
                            <i className="fa fa-plus text-info " /> <i className="fa fa-bell text-info" />
                        </button>

                        <button
                            onClick={() => {
                                setSelectMode('master');
                            }}
                            className="btn bg-white border mb-2 mr-2"
                        >
                            <i className="fa fa-plus text-info " /> <i className="fa fa-medkit text-danger" />
                        </button>

                        <input
                            style={{
                                width: '350px',
                            }}
                            onBlur={(e) => {
                                onGroupUpdate({ name: groupName });
                            }}
                            value={groupName}
                            onChange={(e) => {
                                setGroupName(e.target.value);
                            }}
                            placeholder="Group name"
                            type="text"
                            className="form-control text-center mb-2 mr-2"
                        />

                        <button
                            onClick={async () => {
                                try {
                                    await del(`/SosGroup/${group.id}`);
                                    const cursor = baobab.root.select(['model', 'SosGroup', { id: group.id }]);
                                    cursor.unset();
                                } catch (err) {
                                    console.log(err);
                                }
                            }}
                            className="btn btn-outline-danger mb-2"
                        >
                            <i className="fa fa-trash" />
                        </button>
                    </div>
                </div>
            </div>

            <div className="d-flex ">
                <div className="d-flex flex-wrap">
                    {selectMode ? (
                        <RoomSelector
                            selectMode={selectMode}
                            allMasterIds={allMasterIds}
                            allRoomInfoIds={allRoomInfoIds}
                            onRoomSelected={(room) => {
                                if (
                                    selectMode === 'sos' &&
                                    masterRoomInfoData.filter((m) => m.roomInfoId === room.id).length === 0
                                ) {
                                    if (roomInfoIds.includes(room.id)) {
                                        updateRoomSelection([...roomInfoIds.filter((id) => id !== room.id)]);
                                    } else {
                                        roomInfoIds.push(room.id);
                                        updateRoomSelection([...roomInfoIds]);
                                    }
                                } else if (selectMode === 'master' && !roomInfoIds.includes(room.id)) {
                                    let exist = false;
                                    masterRoomInfoData.forEach((m) => {
                                        if (m.roomInfoId === room.id) {
                                            exist = true;
                                            return false; //break the loop
                                        }
                                    });

                                    if (exist) {
                                        updateMasterRoomSelection([
                                            ...masterRoomInfoData.filter((m) => m.roomInfoId !== room.id),
                                        ]);
                                    } else {
                                        masterRoomInfoData.push({
                                            roomInfoId: room.id,
                                            listenSos: true,
                                            listenRS: true,
                                        });
                                        updateMasterRoomSelection([...masterRoomInfoData]);
                                    }
                                }
                            }}
                            onFinish={(commit = true) => {
                                if (commit) {
                                    if (selectMode === 'sos') {
                                        const payload = {
                                            name: groupName,
                                            roomInfoIds: JSON.stringify(roomInfoIds),
                                        };
                                        onGroupUpdate(payload);
                                    } else {
                                        const payload = {
                                            name: groupName,
                                            masterRoomInfoData: JSON.stringify(masterRoomInfoData),
                                        };
                                        onGroupUpdate(payload);
                                    }
                                }
                                setSelectMode(false);
                            }}
                            roomInfoIds={roomInfoIds}
                            masterRoomInfoIds={masterRoomInfoIds}
                            rooms={rooms}
                        />
                    ) : null}
                </div>

                <div className="d-flex flex-wrap">{groupedRoomsView}</div>
            </div>
        </div>
    );
};

const SosGroups = (props) => {
    const { sosGroups } = props;
    const sosEventTickets = props.eventTickets.filter((f) => f.ruleName === 'alarm_sos');

    const sosGroupViews = sosGroups.map((g) => {
        return (
            <GroupView
                key={g.id}
                group={g}
                onGroupUpdate={async (groupData) => {
                    //console.log("GROUP UPDATE");
                    const updatedGroup = await put(`/SosGroup/${g.id}`, groupData);
                    const cursor = baobab.root.select(['model', 'SosGroup', { id: g.id }]);
                    cursor.set(updatedGroup);
                }}
                sosGroups={sosGroups}
                rooms={props.rooms}
                sosEventTickets={sosEventTickets}
            />
        );
    });
    return (
        <div className="">
            <PageTitle />

            <div className="row justify-content-center  ">
                <div className="col-sm-12">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary  mr-2 mt-1 mb-1"
                    >
                        {window.translate('GO BACK')}
                    </button>

                    <button
                        onClick={async () => {
                            let sosGroup = {
                                name: 'UNNAMED SOS GROUP',
                                roomInfoIds: '[]',
                                parentId: null,
                                masterRoomInfoId: null,
                                listenChildrenSos: true,
                                listenChildrenRoomService: true,
                            };
                            /*let createdGroup =*/ await post('/SosGroup', sosGroup);
                            // baobab.root.select(['model', 'SosGroup']).push(createdGroup); //this is added via sockets
                        }}
                        className="btn btn-sm btn-primary  mr-2 mt-1 mb-1"
                    >
                        {window.translate('ADD GROUP')}
                    </button>
                </div>
            </div>

            <div className="mt-2">{sosGroupViews}</div>

            <div className="mt-3">
                <div>
                    <i className="fa fa-medkit text-danger" /> - Help Station Switch
                </div>
                <div>
                    <i className="fa fa-bell text-info" /> - Call Origin
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        eventTickets: ['model', 'RoomEventTicket'],
        sosGroups: ['model', 'SosGroup'],
        rooms: ['model', 'RoomInfo'],
    },
    SosGroups
);