import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { isFeatureEnabled } from '../../components/FeatureFlag';

function BMSManagement(props) {
    const { IOTatewayIntegration } = props;
    const [selectedSufix, setSelectedSufix] = React.useState('gw1');
    const authenticatedSessions = ['gw1', 'gw2', 'gw3'];

    if (!isFeatureEnabled('BMS') || IOTatewayIntegration === undefined || IOTatewayIntegration === null) {
        return <div>BMS feature disabled</div>;
    }
    let predefinedGWCount = 3;
    let gwConfig = [];
    for (var i = 1; i <= predefinedGWCount; i++) {
        if (IOTatewayIntegration['enableIntegration_gw' + i]) {
            let gw_type = IOTatewayIntegration[`gw${i}_type`];
            let slaveUuid = IOTatewayIntegration[`gw${i}_slaveUuid`];

            gwConfig.push({
                sufix: 'gw' + i,
                gw_type,
                slaveUuid,
                enabled: IOTatewayIntegration['enableIntegration_gw' + i],
                name: IOTatewayIntegration['name_gw' + i] === '' ? 'gw' + i : IOTatewayIntegration['name_gw' + i],
                address: IOTatewayIntegration['gatewayAddress_gw' + i], //gatewayAddress_gw1
                apiKey: IOTatewayIntegration['APIToken_gw' + i], //APIToken_gw1
            });
        }
    }
    let body = null;
    if (gwConfig.length === 1) {
        body =
            IOTatewayIntegration['gw1_type'] === 'cloud' ? (
                <iframe
                    title="gw1"
                    style={{ height: '91vh', width: '100%', border: 'none' }}
                    src={
                        // IOTatewayIntegration['gatewayAddress_gw1'] +
                        // '/#/?dashboardOnly=true&slaveDiveIn=' +
                        // IOTatewayIntegration['gw1_slaveUuid']
                        IOTatewayIntegration['gatewayAddress_gw1'] +
                        '/?slaveUuid='+IOTatewayIntegration['gw1_slaveUuid']+'#/?dashboardOnly=true' 
                        
                    }
                />
            ) : (
                <iframe
                    title="gw1"
                    style={{ height: '91vh', width: '100%', border: 'none' }}
                    src={IOTatewayIntegration['gatewayAddress_gw1'] + '/#/?dashboardOnly=true'}
                />
            );
    } else if (gwConfig.length > 1) {
        body = (
            <div className="pl-0 pr-0">
                <nav className="bg-light">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                        {gwConfig.map((gw) => {
                            return (
                                <a
                                    onClick={() => {
                                        setSelectedSufix(gw.sufix);
                                    }}
                                    key={gw.sufix}
                                    className={
                                        selectedSufix === gw.sufix
                                            ? 'nav-item nav-link active'
                                            : 'nav-item nav-link '
                                    }
                                    id={'nav-' + gw.sufix + '-tab'}
                                    data-toggle="tab"
                                    href={'#nav-' + gw.sufix}
                                    role="tab"
                                    aria-controls={'nav-' + gw.sufix}
                                    aria-selected="true"
                                >
                                    {gw.name}
                                </a>
                            );
                        })}
                    </div>
                </nav>

                <div className="tab-content " id="nav-tabContent">
                    {gwConfig.map((gw) => {
                        if (authenticatedSessions.includes(gw.sufix)) {
                            return (
                                <div
                                    key={gw.sufix}
                                    className={
                                        selectedSufix === gw.sufix
                                            ? 'tab-pane fade show active'
                                            : 'tab-pane fade show'
                                    }
                                    id={'nav-' + gw.sufix}
                                    role="tabpanel"
                                    aria-labelledby={'nav-' + gw.sufix + '-tab'}
                                >
                                    {gw.gw_type === 'cloud' ? (
                                        <iframe
                                            title={gw.name}
                                            style={{
                                                height: '86vh',
                                                width: '100%',
                                                border: 'none',
                                            }}
                                            src={gw.address + '/?slaveUuid='+gw.slaveUuid+'#/?dashboardOnly=true' }
                                        />
                                    ) : (
                                        <iframe
                                            title={gw.name}
                                            style={{
                                                height: '86vh',
                                                width: '100%',
                                                border: 'none',
                                            }}
                                            src={gw.address + '/#/?dashboardOnly=true&token=' + gw.apiKey}
                                        />
                                    )}
                                </div>
                            );
                        } else {
                            return (
                                <div
                                    key={gw.sufix}
                                    className={
                                        selectedSufix === gw.sufix
                                            ? 'tab-pane fade show active'
                                            : 'tab-pane fade show'
                                    }
                                >
                                    <h3>Not authenticated</h3>
                                    <b>HINT: </b>
                                    On{' '}
                                    <a href="https://pimatico.com/business/pimatico_products/plc-gateway/">
                                        PLC gateway
                                    </a>
                                    , go to: <i>Advanced-Settings-API settings</i> and check chechbox "Enable api key
                                    login method". Be sure to take a copy of generateted API key on gateway and paste it
                                    in hotel software: <i>System menu-General settings-Gateway integration</i>
                                </div>
                            );
                        }
                    })}
                </div>
            </div>
        );
    }

    return <div>{body}</div>;
}

export default branch(
    {
        IOTatewayIntegration: ['IOTatewayIntegration'],
    },
    BMSManagement
);