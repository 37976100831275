import React from 'react';
import { useBranch } from 'baobab-react/hooks';
import { addInvoiceItem } from '../terminalViewActions';
import Image from '../../../../components/image';
import InlineModal from '@components/modalInline';
import ConfirmButtons from '@components/buttons/confirmButtons';
import rest from '@data/restWrapper';
import _ from 'lodash';
import KeyboardComp from '../../../documentScanner/keyboard';

const ItemsGridView = (props) => {
    const [amount, setAmount] = React.useState('');
    const [currentItem, setCurrentItem] = React.useState(null);

    const { defaultCurrency, posItems, filteredItems, posTerminals } = useBranch({
        posItems: ['model', 'PosItem'],
        filteredItems: ['state', 'posTerminal', 'filteredItems'],
        defaultCurrency: ['defaultCurrency'],
        posTerminals: ['model', 'PosTerminal'],
    });

    React.useEffect(() => {
        const loadData = () => {
            if (currentItem) {
                setAmount(currentItem.price);
            }
        };
        loadData();
    }, [currentItem]);

    const itemsToShow = filteredItems ? filteredItems : posItems;
    const posTerminal = posTerminals.find((p) => p.id === Number(props?.match?.params?.posTerminalId));

    if (posTerminal && !posTerminal.isEnabled) {
        return (
            <div className="p-2 col-4 text-center m-auto border border-danger">
                {window.translate('Terminal is disabled... Enable it before use!')}
            </div>
        );
    }

    return (
        <React.Fragment>
            <InlineModal show={currentItem} header={window.translate('New price')}>
                <AddAmount
                    setAmount={setAmount}
                    setCurrentItem={setCurrentItem}
                    amount={amount}
                    currentItem={currentItem}
                />
            </InlineModal>
            <div className="d-flex  flex-wrap pos-goods-grid-wrapper">
                {itemsToShow.map((p, key) => {
                    return (
                        <div
                            key={key}
                            className="  pointer d-inline-block d-flex justify-content-center"
                            onClick={() => {
                                p.flexiblePrice === true ? setCurrentItem(p) : addInvoiceItem(p.id);
                            }}
                            style={{ flexBasis: '16.66%', height: '160px', marginTop: '5px' }}
                        >
                            <div
                                className="glow-on-hover pos-item-inner"
                                // style={{
                                //     backgroundImage: `url("${p.id ? '/getPosItemImage/' + p.id : imagePlaceholder}")`,
                                // }}
                            >
                                <Image posItemId={p.id} style={{ height: '100%', width: '100%' }} />
                                <div className="d-flex justify-content-center align-items-center  pos-item-name-price">
                                    <p className="elipsis m-0 py-2">
                                        {p.name + ' - ' + p.price + ' ' + defaultCurrency}
                                    </p>
                                </div>
                            </div>
                        </div>
                    );
                })}
            </div>
        </React.Fragment>
    );
};

const AddAmount = (props) => {
    const { amount, setAmount, setCurrentItem, currentItem } = props;
    let _amount = _.clone(amount.toString());
    return (
        <div>
            <div className="form-group">
                <label className="">{window.translate('Add new price or confirm current:')}</label>
                <input
                    type="text"
                    onChange={(e) => {
                        if (!isNaN(Number(e.target.value))) {
                            e.target.value = e.target.value.replace(/\s/g, '');
                            e.target.value = (e.target.value.indexOf(".") >= 0) ? (e.target.value.substr(0, e.target.value.indexOf(".")) + e.target.value.substr(e.target.value.indexOf("."), 3)) : e.target.value;
                            setAmount(e.target.value);
                        }
                    }}
                    onKeyPress={async (event) => {
                        if (event.key === 'Enter') {
                            const newItem = { ...currentItem };
                            newItem.price = Number(amount).toFixed(2);
                            await rest('/PosItem/' + newItem.id, 'PUT', newItem);
                            addInvoiceItem(newItem.id, Number(newItem.price));
                            setCurrentItem(null);
                            setAmount('');
                        }
                    }}
                    value={amount ? amount : ''}
                    className="form-control"
                />
                    <KeyboardComp
                        pinCode={_amount}
                        setPinCode={setAmount}
                        setShowKeyboard={null}
                        checkPinCode={null}
                        type={'posItem'}
                    />
            </div>
            <hr />
            <div className="text-center">
                <ConfirmButtons
                    onConfirm={async () => {
                        if (amount) {
                            const newItem = { ...currentItem };
                            newItem.price = Number(amount).toFixed(2);
                            await rest('/PosItem/' + newItem.id, 'PUT', newItem);
                            addInvoiceItem(newItem.id, Number(newItem.price));
                            setCurrentItem(null);
                            setAmount('');
                        } else {
                            addInvoiceItem(currentItem.id);
                            setCurrentItem(null);
                        }
                    }}
                    onCancel={() => {
                        setCurrentItem(null);
                    }}
                />
            </div>
        </div>
    );
};

export default ItemsGridView;
