import React, { Component } from 'react';
import DateRangePicker from '../react-daterange-picker/index'; //'react-daterange-picker';
import Moment from 'moment/min/moment-with-locales';
import { extendMoment } from 'moment-range';
import _ from 'lodash';
import {
    reservationStatusEnumsDesc,
    indexToReservationEnum,
    reservationStatusColorsHEX,
} from '../../data/reservationStatusEnums';

const moment = extendMoment(Moment);

class DatePicker extends Component {
    constructor() {
        super();
        this.state = {
            value: null, //keep in mind, to use fully rounded timestamps to midnight of targeteted day. Or it may show errors when changing checkin or checkout times....
        };
    }

    handleSelect(range, states) {
        // range is a moment-range object
        // console.log(range, states);
        if (!this.props.disableSelection) {
            this.setState({
                value: range,
                states: states,
            });
            if (this.props.dateRangePickerHandler) {
                this.props.dateRangePickerHandler(range.start.valueOf(), range.end.valueOf());
            }
        }
    }

    getDateAtMidnight(dateTimestamp) {
        let date = new Date(dateTimestamp);
        return new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate(), 0, 0);
    }

    componentDidMount() {
        if (this.props.editingReservation.id !== null) {
            //ovo računanje ispod je HACK!!!!! možda bug u range pickeru prilikom componentDidMounta. Imače radi OK
            let value = moment().range(
                this.getDateAtMidnight(this.props.editingReservation.checkInTimestamp),
                this.getDateAtMidnight(this.props.editingReservation.checkOutTimestamp)
            );
            this.setState({ value });
        } else if (this.props.preselectedDate && this.props.preselectedDate.from !== null) {
            let value = moment().range(
                this.getDateAtMidnight(this.props.preselectedDate.from),
                this.getDateAtMidnight(this.props.preselectedDate.to * 1)
            );
            this.setState({ value });
        }
    }
    componentDidUpdate(prevProps) {
        let qualifiedForRedraw = false;
        if (
            prevProps.editingReservation &&
            this.props.editingReservation &&
            prevProps.editingReservation.id !== this.props.editingReservation.id
        ) {
            qualifiedForRedraw = true;
        } else if (
            prevProps.editingReservation &&
            this.props.editingReservation &&
            prevProps.editingReservation.checkInTimestamp !== this.props.editingReservation.checkInTimestamp
        ) {
            qualifiedForRedraw = true;
        } else if (
            prevProps.editingReservation &&
            this.props.editingReservation &&
            prevProps.editingReservation.checkOutTimestamp !== this.props.editingReservation.checkOutTimestamp
        ) {
            qualifiedForRedraw = true;
        }

        if (qualifiedForRedraw) {
            //  console.log(this.getDateAtMidnight(this.props.editingReservation.checkInTimestamp);
            this.setState({
                value: moment.range(
                    this.getDateAtMidnight(this.props.editingReservation.checkInTimestamp),
                    this.getDateAtMidnight(this.props.editingReservation.checkOutTimestamp)
                ),
            });
        }
    }

    render() {
        /*
  earlyBird:0,
  confirmed:1,
  checkedIn:2,
  canceled:3, //not important, but hey...
  closed:4,
  autoBlocked: 5
    */

        let showStatusEnumList = this.props.showStatusEnumList || [0, 1, 2, 4, 5];

        let dateRanges = [];
        let stateDefinitions = {
            available: {
                selectable: true,
                color: null,
                label: 'Available',
            },
        };

        _.forEach(_.orderBy(this.props.reservations, 'checkInTimestamp', 'ASC'), (r) => {
            if (this.props.editingReservation.id !== r.id) {
                // && r.statusEnum !== 3 && r.statusEnum !== 4) {
                if (_.includes(showStatusEnumList, r.statusEnum)) {
                    dateRanges.push({
                        state: indexToReservationEnum[r.statusEnum],
                        range: moment.range(moment(r.checkInTimestamp), moment(r.checkOutTimestamp)),
                    });

                    stateDefinitions[indexToReservationEnum[r.statusEnum]] = {
                        selectable: false,
                        color: reservationStatusColorsHEX[r.statusEnum], //"#78818b", //
                        label: reservationStatusEnumsDesc[r.statusEnum],
                    };
                }
            }
        });

        //console.log(this.state.value);

        let maximumDate = new Date(new Date().getFullYear(), new Date().getMonth() + 6);
        return (
            <DateRangePicker
                disableSelection={this.props.disableSelection}
                locale={moment.locale()}
                disableNavigation={this.props.disableNavigation ? this.props.disableNavigation : true}
                firstOfWeek={1}
                numberOfCalendars={this.props.numberOfCalendars ? this.props.numberOfCalendars : 4}
                selectionType="range"
                minimumDate={this.props.minimumDate ? this.props.minimumDate : new Date()}
                maximumDate={this.props.maximumDate ? this.props.maximumDate : maximumDate}
                stateDefinitions={stateDefinitions}
                dateStates={dateRanges}
                defaultState="available"
                selectedLabel={this.props.selectionLabel ? this.props.selectionLabel : 'Your selection'}
                showLegend={this.props.showLegend !== undefined ? this.props.showLegend : true}
                value={this.state.value}
                onSelect={this.handleSelect.bind(this)}
            />
        );
    }
}

export default DatePicker;
