import React, { useEffect } from 'react';
import PageTitle from '../../components/pageTitle';
import { toast } from 'react-toastify';
import NavLinksSettings from './navLinksSettings';
import { loadSettings } from './settingsActions';

const GeneralSettings = (props) => {
    useEffect(() => {
        const loadData = async () => {
            try {
                await loadSettings();
            } catch (err) {
                toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, []);

    return (
        <div className="">
            <PageTitle />

            <button
                onClick={() => {
                    props.history.push('/menu/system');
                }}
                className="btn btn-sm btn-secondary mr-2"
            >
                {window.translate('GO BACK')}
            </button>

            <NavLinksSettings />
        </div>
    );
};

export default GeneralSettings;
