import React from 'react';
import numbro from 'numbro';
import { useBranch } from 'baobab-react/hooks';
import { issueInvoice, substractInvoiceItem, setPointOfSaleInputs, loadPendingInvoice } from '../terminalViewActions';
import PointOfSaleInputs from "../../../checkoutAndInvoice/checkout/PointOfSaleInputs"
import AttachToReservation from "./attachToReservation"
import { setModalContent, closeModal } from '@components/modal';
import ConfirmButtons from '@components/buttons/confirmButtons';

const Sidebar = ({ posItems, posTerminalId, }) => {
	const { defaultCurrency } = useBranch({
		defaultCurrency: ['defaultCurrency'],
	});
	const [attachToRes, setAttachToRes] = React.useState(false)

	return (
		<div className="container">
			<InvoicePresenter defaultCurrency={defaultCurrency} />
			<ActionButtons posTerminalId={posTerminalId} setAttachToRes={setAttachToRes} />
			{attachToRes === true
				? <AttachToReservation posTerminalId={posTerminalId} setAttachToRes={setAttachToRes} />
				: null
			}
		</div>
	);
};

const InvoicePresenter = (props) => {
	const { invoiceItems } = useBranch({
		invoiceItems: ['state', 'posTerminal', 'invoiceItems'],
	});

	return (
		<div>
			<small>
			<b>
				<div className="row mt-2 ml-0 mr-0 mb-1 border-bottom">
					<div className="col-5 text-center">{window.translate("Name")}</div>
					<div className="col-2 text-right">{window.translate("Qty.")}</div>
					<div className="col-2 text-right">{window.translate("Unit price")}</div>
					<div className="col-3 text-right">{window.translate("Total")} {props.defaultCurrency}</div>
				</div>
				</b>
			</small>
			<ul className="list-group">

				{invoiceItems.length===0 && <li  className="list-group-item" >{window.translate("No data")}</li>}

				{invoiceItems.map((item, index) => {
					return (
						<li className="list-group-item" key={index}>
						<small>
							<div className="row">
							
								<div className="col-5">{item.itemName}</div>
								<div className="col-2 text-right" onClick={substractInvoiceItem.bind(this, item.posItemId)} >
									<span style={{fontSize:"11px"}} className="badge badge-pill badge-warning pointer">{item.quantity} x</span>
								</div>
								<div className="col-2 text-right">{item.bruttoUnitPrice}</div>
								<div className="col-3 text-right">{item.bruttoUnitsValue.toFixed(2)}</div>
							</div></small>
						</li>
					);
				})}
			</ul>

			<InvoiceSummary />
		</div>
	);
};

const InvoiceSummary = () => {
	const { invoiceItems, invoice } = useBranch({
		invoiceItems: ['state', 'posTerminal', 'invoiceItems'],
		invoice: ['state', 'posTerminal', 'invoice'],
	});

	if (invoiceItems.length === 0) {
		return null;
	}

	return (
		<div>

			{/*<div className="row mt-2">
				<div className="col-md-6 ">
					<b className="pl-1">{window.translate("Taxes")}:</b>{' '}

				</div>
				<div className="col-md-6 text-right">
					{numbro(invoice.totalVAT).format({
						mantissa: 2,
						trimMantissa: false,
						thousandSeparated: true,
					})}{' '}
					{invoice.defaultCurrency}
				</div>

			</div>

		*/}
			<div className="row mt-1">
				<div className="col-md-6">
					<b className="pl-1">{window.translate("To pay")}:</b>{' '}

				</div>
				<div className="col-md-6 text-right">
					<b>
						{numbro(invoice.totalBaseValue).format({
							mantissa: 2,
							trimMantissa: false,
							thousandSeparated: true,
						})}
					 {' '}
					{invoice.defaultCurrency}
					</b>
				</div>

			</div>

		</div>
	);
};

const ActionButtons = ({ posTerminalId, setAttachToRes }) => {
	const { invoiceItems, invoice } = useBranch({
		invoiceItems: ['state', 'posTerminal', 'invoiceItems'],
		invoice: ['state', 'posTerminal', 'invoice'],
	});

	return (
		<div>
			<hr />
			<div className="text-center">
				<PointOfSaleInputs invoice={invoice} translate={window.translate} setPointOfSaleViaProps={setPointOfSaleInputs} />

				<div className="text-center d-flex justify-content-center" style={{ backgroundColor: "#1ca2ef" }} >
					<button disabled={invoiceItems.length === 0}
						className="px-1 py-2 sidebar-pos-button pointer"
						onClick={() => setAttachToRes(true)}
					>
						{window.translate("ATTACH TO RES.")}
					</button>
					<button disabled={invoiceItems.length === 0}
						className="px-1 py-2 sidebar-pos-button pointer"
						onClick={() => {
							setModalContent(
								<div className="container-fluid">
									<div className="text-center">
										{window.translate('Please confirm?')}
										<hr />
									</div>
									<div className="text-center"  >
										<ConfirmButtons
											onConfirm={async () => {
												await issueInvoice();
												await loadPendingInvoice(posTerminalId);
												closeModal();
											}}
											onCancel={() => { closeModal() }}
										/>
									</div>
								</div>,
								"", // header
								false, // showCloseButton
								'modal-md'
							)
						}}>
						{window.translate("ISSUE INVOICE")}
					</button>
				</div>

			</div>
		</div >
	);
};

export default Sidebar;