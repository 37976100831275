import React from 'react';
import { branch } from 'baobab-react/higher-order';
import rest from '../../data/restWrapper';
import moment from 'moment/min/moment-with-locales';
import baobabTree from '../../data/state';
import PermissionCheck from '../../components/PermissionCheck';

const ClaimEvent = (props) => {
    const [viewType, setViewType] = React.useState(0); //or 1 for suggested responders

    const { loggedUser, claimResponse, employees } = props;
    const userNameOrEmail = loggedUser.name ? `${loggedUser.name} ${loggedUser.surname}` : loggedUser.email;

    if (viewType === 0) {
        return (
            <div>
                <button onClick={() => setViewType(1)} className="btn btn-warning mr-2 mt-2">
                    {window.translate('SUGGEST RESPONDER')}
                </button>
                <button
                    onClick={claimResponse.bind(this, loggedUser.uuid, loggedUser.uuid)}
                    className="btn btn-success mr-2 mt-2"
                >
                    {window.translate('CLAIM RESPONSE')} ({userNameOrEmail})
                </button>
            </div>
        );
    } else {
        return (
            <div>
                <h3>{window.translate('SELECT RESPONDER')}</h3>
                <ul className="list-group">
                    {employees.map((e) => {
                        return (
                            <li
                                onClick={claimResponse.bind(this, loggedUser.uuid, e.uuid)}
                                key={e.uuid}
                                className="list-group-item list-group-item-action pointer"
                            >
                                <b>{e.email}</b>{' '}
                                <i>
                                    {e.name} {e.surname}
                                </i>{' '}
                                <b>({e.role})</b>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
};

const FinishEvent = (props) => {
    const [eventNotes, setEventNotes] = React.useState('');

    const closeEventLog = () => {
        props.closeEventLog(eventNotes);
        setEventNotes('');
    };

    const { sosResponseInfo, employees } = props;
    const responder = employees.find((e) => e.uuid === sosResponseInfo.responderUserUuid);

    if (responder === undefined) {
        return window.translate('no responder info');
    }

    return (
        <div className="text-left">
            <b>{window.translate('EVENT RESPONDER')}:</b> {responder.email} {responder.name} {responder.surname}
            <div>
                <small className="text-danger">
                    {window.translate(
                        'Please, fill the following informations after you have returned from the location'
                    )}
                    .
                </small>
            </div>
            <b>{window.translate('EVENT NOTES')}:</b>
            <textarea
                onChange={(e) => setEventNotes(e.target.value)}
                value={eventNotes}
                rows={5}
                className="form-control"
            ></textarea>
            <button onClick={closeEventLog.bind(this)} className="btn btn-warning mt-2">
                {window.translate('CLOSE EVENT LOG')}
            </button>
        </div>
    );
};

function ShowInfo(props) {
    const { loggedUser, sosResponseInfo, employees } = props;
    const responder = employees.find((e) => e.uuid === sosResponseInfo.responderUserUuid);
    const receptionst = employees.find((e) => e.uuid === loggedUser.uuid);

    if (responder === undefined) {
        return 'ShowInfo responder undefined';
    }

    if (receptionst === undefined) {
        return 'ShowInfo receptionst undefined';
    }

    return (
        <div className="text-left">
            <div className="text-danger">
                {' '}
                <b>{window.translate('Event time')}:</b> {moment(sosResponseInfo.eventTimestamp).format('LLL')}{' '}
            </div>
            <div className="text-warning">
                {' '}
                <b>{window.translate('Event accepted')}:</b>{' '}
                {moment(sosResponseInfo.eventAcceptedTimestamp).format('LLL')}{' '}
            </div>
            <div>
                {' '}
                <b>{window.translate('Receptionist')}:</b> <i>{receptionst.email}</i> {receptionst.name}{' '}
                {receptionst.surname}
            </div>
            <div>
                {' '}
                <b>{window.translate('Responder')}:</b> <i>{responder.email}</i> {responder.name} {responder.surname}
            </div>
            <div className="text-success">
                {' '}
                <b>{window.translate('Event closed')}:</b>{' '}
                {moment(sosResponseInfo.eventFinishedTimestamp).format('LLL')}{' '}
            </div>
            <div>
                {' '}
                <b>{window.translate('EVENT NOTES')}:</b> <i>{sosResponseInfo.eventNotes}</i>
            </div>

            <PermissionCheck name="roomRoomTicket_SWEventDismiss">
                <button
                    onClick={() => {
                        const { event, roomInfo } = props;
                        props.handleMuteClick(event, roomInfo);
                    }}
                    className="btn btn-sm btn-info mt-2"
                >
                    {window.translate('DISMISS SOS CALL')}
                </button>
            </PermissionCheck>
            <hr />
            {props.children}
        </div>
    );
}

const SosResponseForm = (props) => {
    const [employees, setEmployees] = React.useState([]);
    const [customer, setCusomter] = React.useState(null);
    const [roomInfo, setRoomInfo] = React.useState(null);
    const [sosResponseInfo, setSosResponseInfo] = React.useState(null);
    const [errorMessage, setError] = React.useState(null);

    const { event, rooms, activeSosResponses, reservation } = props;
    const sosResponseInfoId = sosResponseInfo ? sosResponseInfo.id : null;

    const params = {
        ...props,
        employees,
        customer,
        roomInfo,
        sosResponseInfo,
        sosResponseInfoId,
    };

    const claimResponse = async (userUuid, responderUserUuid) => {
        try {
            const payload = {
                eventAcceptedTimestamp: Date.now(),
                userUuid,
                responderUserUuid,
            };
            const result = await rest('/SosResponse/' + sosResponseInfo.id, 'PUT', payload);
            setSosResponseInfo(result);
        } catch (err) {
            console.log(err);
        }
    };

    const closeEventLog = async (eventNotes) => {
        try {
            const payload = {
                eventFinishedTimestamp: Date.now(),
                eventNotes: eventNotes,
            };
            const result = await rest('/SosResponse/' + sosResponseInfo.id, 'PUT', payload);
            setSosResponseInfo(result);
        } catch (err) {
            console.log(err);
        }
    };

    const loadEmployees = async () => {
        const emp = await rest('/getEmployees');
        setEmployees(emp);
    };

    const closeRoomEventTicket = async () => {
        try {
            await rest(`/RoomEventTicket/${event.id}`, 'PUT', {
                closingTimestamp: Date.now(),
            });
        } catch (err) {
            setError(err.message);
        }
    };

    React.useEffect(() => {
        loadEmployees();
    }, []);

    React.useEffect(() => {
        const loadData = async () => {
            let _sosResponseInfo = activeSosResponses.find((sosRes) => sosRes.roomEventTicketId === event.id);
            if (!_sosResponseInfo) {
                let activeSosResponses2 = await rest('/getActiveSosResponses');
                _sosResponseInfo = activeSosResponses2.find((sosRes) => sosRes.roomEventTicketId === event.id);
                baobabTree.root.select(['model', 'SosResponse']).set(activeSosResponses2);
            }
            if (_sosResponseInfo) {
                const _roomInfo = rooms.find((r) => r.id === event.roomInfoId);
                setSosResponseInfo(_sosResponseInfo);
                setRoomInfo(_roomInfo);
            } else {
                setSosResponseInfo(null);
                setRoomInfo(null);
            }
        };

        loadData();

        const _customer = reservation ? reservation.customer : {};
        setCusomter(_customer);
        setError(null);
        // eslint-disable-next-line
    }, [event.id /* , activeSosResponses, reservation, rooms, event.roomInfoId */]);

    const getResponseViewState = (sosResponseEventInfo) => {
        let viewState = sosResponseEventInfo ? '' : 'missingSOSResponse';
        if (sosResponseEventInfo) {
            if (sosResponseEventInfo.eventAcceptedTimestamp === null) {
                //step 1
                viewState = 'claimEvent';
            } else if (sosResponseEventInfo.eventFinishedTimestamp === null) {
                //step2 (also, userUuid should be setted by now)
                viewState = 'finishEvent';
            } else {
                viewState = 'showInfo';
            }
        }
        return viewState;
    };

    const viewState = getResponseViewState(sosResponseInfo);

    let renderBody = null;
    switch (viewState) {
        case 'claimEvent':
            renderBody = <ClaimEvent claimResponse={claimResponse.bind(this)} {...params} />;
            break;
        case 'finishEvent':
            renderBody = (
                <FinishEvent
                    closeEventLog={closeEventLog.bind(this)}
                    claimResponse={claimResponse.bind(this)}
                    {...params}
                ></FinishEvent>
            );
            break;
        case 'showInfo':
            renderBody = <ShowInfo {...params}>{props.children}</ShowInfo>;
            break;
        case 'missingSOSResponse':
        default:
            renderBody = (
                <div>
                    {window.translate('Missing SOS response record. Close this event?')}
                    <div>
                        <button
                            onClick={async () => {
                                closeRoomEventTicket(event);
                            }}
                            className="btn btn-danger m-1"
                        >
                            {window.translate('TRY TO CLOSE EVENT')}
                        </button>
                    </div>

                    {props.children}
                </div>
            );
            break;
    }

    if (errorMessage) {
        return <div className="text-danger">{errorMessage}</div>;
    }

    return (
        <div className="mt-3 text-center">
            <h3>{roomInfo ? roomInfo.name : null}</h3>
            <h3>{customer ? customer.name : null}</h3>
            <hr />
            {renderBody}
        </div>
    );
};

export default branch(
    {
        //do not use baobab here...
        rooms: ['model', 'RoomInfo'],
        loggedUser: ['authTokenInfo', 'user'],
        activeSosResponses: ['model', 'SosResponse'],
    },
    SosResponseForm
);
