import React from 'react';
import { logout } from '../../data/actions';
import { branch } from 'baobab-react/higher-order';
import FeatureFlag from '../FeatureFlag';
import PermissionCheck from '../PermissionCheck';

const Navbar = (props) => {
    const { history, location, companyInfo } = props;

    const _logout = (e) => {
        if (e) {
            e.preventDefault();
            logout();
            // history.push('/'); 
        }
    };

    const navigateRoute = (e) => {
        e.preventDefault();
        history.push(e.target.name);
    };

    const getNavLink = (route, name) => {
        return (
            <span>
                <a
                    className={
                        location.pathname === route ? 'nav-link active d-none d-lg-block' : 'nav-link d-none d-lg-block'
                    }
                    onClick={navigateRoute}
                    name={route}
                    href={route}
                >
                    {' '}
                    {name}{' '}
                </a>

                <a
                    className={location.pathname === route ? 'nav-link active d-lg-none' : 'nav-link d-lg-none'}
                    data-toggle="collapse"
                    data-target="#navbarNav"
                    onClick={navigateRoute}
                    name={route}
                    href={route}
                >
                    {name}
                </a>
            </span>
        );
    };

    if(history.location.pathname.indexOf("firstTimeSetup")!==-1){
        return null
    }   

    let companyName = companyInfo ? companyInfo.hotelName : 'NEW HOTEL';
    return (
        <nav className="navbar  fixed-top navbar-expand-lg navbar-dark navbar-gradient">
            <a onClick={navigateRoute} className="navbar-brand animated faster slideInLeft" name="/" href="/">
                {companyName}
            </a>

            <button
                className="navbar-toggler"
                type="button"
                data-toggle="collapse"
                data-target="#navbarNav"
                aria-controls="navbarNav"
                aria-expanded="false"
                aria-label="Toggle navigation"
            >
                <span className="navbar-toggler-icon"></span>
            </button>
            <div className="collapse navbar-collapse flex-row-reverse animated faster slideInRight" id="navbarNav">
                <ul className="navbar-nav">
                    <PermissionCheck name="dashboard">
                        <li className="nav-item"> {getNavLink('/', window.translate('Dashboard'))} </li>
                    </PermissionCheck>

                    <FeatureFlag flag="BMS">
                        <PermissionCheck name="gatewayIntegration">
                            <li className="nav-item"> {getNavLink('/bms', 'BMS')} </li>
                        </PermissionCheck>
                    </FeatureFlag>

                    <FeatureFlag flag="household">
                        <PermissionCheck name="household">
                            <li className="nav-item"> {getNavLink('/household', window.translate('Household'))} </li>
                        </PermissionCheck>
                    </FeatureFlag>

                    <FeatureFlag flag="situationCenter">
                        <PermissionCheck name="situationCenter">
                            <li className="nav-item">
                                {getNavLink('/allRoomEvents', window.translate('Situation center'))}{' '}
                            </li>
                        </PermissionCheck>
                    </FeatureFlag>

                    <PermissionCheck name="reports">
                        <li className="nav-item"> {getNavLink('/menu/reports', window.translate('Reports'))} </li>
                    </PermissionCheck>

                    <PermissionCheck name="systemMenu">
                        <li className="nav-item"> {getNavLink('/menu/system', window.translate('System menu'))} </li>
                    </PermissionCheck>

                    <li className="nav-item">
                        <a
                            onClick={_logout.bind(this)}
                            data-toggle="collapse"
                            data-target="#navbarNav"
                            className={'nav-link'}
                            name="/logout"
                            href="/logout"
                        >
                            <i className="fa fa-1x fa-sign-out" /> {window.translate('Log out')}
                        </a>
                    </li>
                </ul>
            </div>
        </nav>
    );
};

export default branch(
    {
        pageTitle: ['pageTitle'],
        companyInfo: ['monkeys', 'companyInfo'],
        IOTatewayIntegration: ['IOTatewayIntegration'],
    },
    Navbar
);