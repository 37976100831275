import rest from '@data/restWrapper';
import { CleaningStatusExtendedWithIncludedModels, UserInfo } from '@common/modelDefinition';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';
import { closeModal } from '@components/modal';

export async function getMaids(): Promise<UserInfo[]> {
    return await rest('/getEmployeesByRole/maid');
}

export const updateCleaningStatus = async (cleaningStatus: CleaningStatusExtendedWithIncludedModels) => {
    startGlobalLoading();
    await rest('/updateCleaningStatus', 'POST', {
        cleaningStatus,
    });
    closeModal()
    stopGlobalLoading();
};
