import React, { Component } from 'react';
import _ from 'lodash';
import { reservationStatusColorsHEX, reservationStatusEnumsDesc } from '../../data/reservationStatusEnums';
import Timeline from '../../components/timeline/src';
import moment from 'moment/min/moment-with-locales';
import './legend-checkbox-styling.css';
import { branch } from 'baobab-react/higher-order';
import { translate } from '../../data/translations';
import { buildMonthsTimebar, buildDaysTimebar } from '../../components/timeline/pimaticoHotelUtils';
import {
    CleaningStatusColors,
    // CleaningStatusIcons,
    CleaningStatusEnum,
    ReservationStatusEnum
} from '../household/interfaces';
import ReservationStatusLegend from './reservationStatusLegend';

const MIN_ZOOM = 2;
const MAX_ZOOM = 100;

const startFrom = new Date().getFullYear() - 1;

let monthsTimebar = buildMonthsTimebar(startFrom, 3, translate('ROOM'));
let daysTimebar = buildDaysTimebar(startFrom, 3, translate('DAY'));
const timebar = [daysTimebar, monthsTimebar];

function getPos(el) {
    // yay readability
    for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
    return { x: lx, y: ly };
}

class ReservationsTimeline extends Component {
    constructor(props) {
        super(props);
        this.dragOffsetStart = 0;
        this.dragOffset = 0;

        this.reservationRoute = props.reservationRoute ? props.reservationRoute : 'reservations';

        this.state = {
            open: true, //rooms
            zoom: props.zoom ? props.zoom : 80,
            hideReservationStatusEnums: [3, 4],
            tableContentHeight: 400,
        };
    }

    componentDidMount() {
        let el = document.getElementById('timeline');
        let footer = document.getElementById('main-footer');

        let positionOfTableHeaderElement = getPos(el);
        let positionOfDocumentFooterElement = getPos(footer);
        // let heightOfTableHeaderElement = el.offsetHeight;

        const heightOfTheSpaceFromTableHeaderToDocumentFooter =
            positionOfDocumentFooterElement.y - positionOfTableHeaderElement.y;
        const tableContentHeight = heightOfTheSpaceFromTableHeaderToDocumentFooter - 50;
        this.setState({ tableContentHeight });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.availableFromTimestamp !== this.props.availableFromTimestamp) {
            window.dispatchEvent(new Event('timelineScrollToNow'));
        }
    }

    toggleHideReservationStatusEnums(statusEnum) {
        let hideReservationStatusEnums = _.clone(this.state.hideReservationStatusEnums);
        if (_.includes(hideReservationStatusEnums, statusEnum)) {
            _.remove(hideReservationStatusEnums, (r) => r === statusEnum);
        } else {
            hideReservationStatusEnums.push(statusEnum);
        }
        this.setState({ hideReservationStatusEnums });
    }

    getAllGuestsFromReservation(reservation){
        let returnGuests = null
        const guests = reservation.Guests
        const customerId = reservation.customerId
        const companyId = reservation.companyId
        if(customerId){
            returnGuests = _.map(guests, (guest, index) =>{
                if(guest && guest.id !== customerId){
                    return <div key={index}>- {guest.firstName + " " + guest.lastName}</div>
                }
            })
        }else if(companyId){
            returnGuests = _.map(guests, (guest, index) =>{
                return <div key={index}>- {guest.firstName + " " + guest.lastName}</div>
            })
        }
        return <div className='mb-2 mr-1 text-right'>{returnGuests}</div>
    }

    getTracksFromRoomsAndReservations(rooms, reservations) {
        let trackButtonMode = this.getTrackButtonMode();

        return _.map(rooms, (room) => {
            let statusObj = null
            if(this.props.cleaningStatusHeaders[0]){
                statusObj = this.props.cleaningStatusHeaders[0].CleaningStatuses?.find(sh => {return sh?.RoomInfo?.id === room?.id})
            }       
            const cleaningStatus = statusObj?.cleaningStatus
            const cleaningStatusName = cleaningStatus ? CleaningStatusEnum[cleaningStatus] : "";
            // const cleaningStatusColor = CleaningStatusColors[cleaningStatusName];
            
            let cleaningStatusColor =
            statusObj?.reservationStatus === ReservationStatusEnum.stay &&
            cleaningStatus === CleaningStatusEnum.stagedForCleaning
                ? {
                      color: 'white',
                      backgroundColor: '#c9af2e',
                  }
                : CleaningStatusColors[cleaningStatusName];

            // const iconName = CleaningStatusIcons[cleaningStatusName];

            let hasButton = true;
            let trackButtonIconOverride = null;
            if (trackButtonMode === 'groupReservationsMode') {
                if (_.includes(this.props.selectedRoomsForReservations, room.id)) {
                    trackButtonIconOverride = <i className="fa fa-check-square-o text-dark" />;
                }
                hasButton = this.props.availableFromTimestamp && this.props.availableToTimestamp ? true : false;
            }

            let roomReservations = _.filter(reservations, (r) => r.roomInfoId === room.id);

            return {
                id: 'room-' + room.id,
                title: <div className='d-flex justify-content-center align-items-center position-relative'>
                    <span title={translate(cleaningStatusName)} style={{...cleaningStatusColor,display:"inline-block", width:"5px",position:"absolute", left:0, height:"100%"}}>
                    {/* <i className={(iconName ? iconName : "fa fa-circle-thin") + " px-1"}></i> */}
                    </span> <span className='pl-1'>{room.name}</span> </div> ,
                hasButton,
                room: room,
                trackButtonIconOverride,
                elements: _.map(roomReservations, (r) => {
                    const condoOwner = this.props.condoOwnerMap[r.customerId];
                    let customerName = condoOwner ? `${condoOwner.firstName} ${condoOwner.lastName}` : translate('GUEST');
                    
                    const isCondoOwner = r.condoUserUuid !== null && r.condoUserUuid !== undefined

                    if (r.Customer) {
                        customerName = `${r.Customer.firstName} ${r.Customer.lastName}`;
                    } else if (r.Company) {
                        customerName = r.Company.name;
                    }
                    let allGuestFromReservation = this.getAllGuestsFromReservation(r) //u tooltipu na dashboardu ispise sve goste koji su na toj rezervaciji a nisu nositelji
                    let tooltip = (
                        <div>
                            <div className="mb-1">
                                #{r.id} - <b>{customerName} {r.notes ? <i className="fa fa-commenting" aria-hidden="true"></i> : ""}</b>
                                    {allGuestFromReservation}
                            </div>
                            <i className="fa fa-calendar-check-o" /> {moment(r.checkInTimestamp).format('lll')} <br />
                            <i className="fa fa-sign-out" /> {moment(r.checkOutTimestamp).format('lll')} <br />
                            <div className="mt-3">{reservationStatusEnumsDesc[r.statusEnum]}</div>
                        </div>
                    );
                    return {
                        id: 'reservation-' + r.id,
                        reservation: r,
                        title: `${r.id.toString()} - ${customerName} ${r.companyName ? r.companyName : ''} `, //"REZERVACIJA",
                        start: new Date(r.checkInTimestamp),
                        end: new Date(r.checkOutTimestamp),
                        tooltip: tooltip,
                        style: {
                            backgroundColor: isCondoOwner ? 'purple' : reservationStatusColorsHEX[r.statusEnum],
                            boxShadow: '1px 1px 0px rgba(0, 0, 0, 0.25)',
                        },
                    };
                }),
            };
        });
    }
    reservationClickHandler(element) {
        let reservation = element.reservation;
        if (/*reservation.statusEnum===2 || */ reservation.statusEnum === 4) {
            this.props.history.push('/reservationDetails/' + reservation.id);
        } else {
            //this.reservationRoute
            if (this.reservationRoute === 'reservations') {
                this.props.history.push(`/reservations/${reservation.id}/edit`);
            } else {
                this.props.history.push(`/${this.reservationRoute}/${reservation.roomInfoId}/${reservation.id}/edit`);
            }
        }
    }
    clickTrackButton(element) {
        let { room } = element;
        let { availableFromTimestamp, availableToTimestamp } = this.props;
        const adultsNum =
            this.props.numberOfBeds && this.getTrackButtonMode() === 'reservationMode'
                ? `&adults=${this.props.numberOfBeds}`
                : this.props.numberOfBeds && this.getTrackButtonMode() === 'roomMode'
                ? `?adults=${this.props.numberOfBeds}`
                : '';

        switch (this.getTrackButtonMode()) {
            default:
            case 'roomMode':
                this.props.history.push(`/${this.reservationRoute}/${room.id}/new` + adultsNum);
                //this.props.history.push(`/roomInfo/${room.id}`)
                break;
            case 'reservationMode':
                this.props.history.push(
                    `/${this.reservationRoute}/${room.id}/new?from=${availableFromTimestamp}&to=${availableToTimestamp}` +
                        adultsNum
                );
                break;
            case 'groupReservationsMode':
                this.props.toggleSelectRoomForReservation(element.room.id);
                //console.log("Not implemented", element.room);
                break;
        }
    }

    getTrackButtonMode() {
        let mode = 'roomMode';

        if (this.props.groupReservations) {
            mode = 'groupReservationsMode';
        } else if (_.includes(this.props.showComponents, 'timeline')) {
            if (this.props.availableFromTimestamp && this.props.availableToTimestamp) {
                mode = 'reservationMode';
            } else {
                mode = 'roomMode';
            }
        }
        return mode;
    }

    onMouseDrag(e) {
        if (e.buttons === 1) {
            let ev = new Event('mouseDragScroll');
            ev.clientX = e.clientX;
            ev.dragOffset = this.dragOffsetStart - e.clientX;
            if (this.dragOffset !== ev.dragOffset) {
                this.dragOffset = ev.dragOffset;
                this.dragOffsetStart = e.clientX;
                window.dispatchEvent(ev);
            }
        } else {
            this.dragOffsetStart = e.clientX;
        }
    }

    render() {
        const { open, zoom, tableContentHeight } = this.state;
        const {
            ghostTrack = null,
            basic = false,
            showLegend = true,
            showMarker = true,
            timelineYearsCount = 2,
            reservations,
        } = this.props;

        // const latestCheckIn = reservations.length>0 ? Math.min(reservations.map(r=>r.checkInTimestamp)) : new Date(`${new Date().getFullYear()}`).getTime()

        const start = new Date(`${startFrom}`);
        const end = new Date(`${new Date().getFullYear() + timelineYearsCount}`);

        let nowMarker = this.props.availableFromTimestamp ? new Date(this.props.availableFromTimestamp) : new Date();
        if (this.props.nowMarker) {
            nowMarker = this.props.nowMarker;
        }

        let scale = {
            start,
            end,
            zoom,
            zoomMin: MIN_ZOOM,
            zoomMax: MAX_ZOOM,
        };

        let filteredReservations = _.filter(reservations, (r) => {
            return !_.includes(this.state.hideReservationStatusEnums, r.statusEnum);
        });

        let tracks2 = this.getTracksFromRoomsAndReservations(this.props.rooms, filteredReservations);

        if (ghostTrack) {
            tracks2[0].elements.push({
                ...ghostTrack,
                id: 'ghost',
                style: {
                    backgroundColor: 'rgba(4, 190, 254, 0.1)',
                    borderRadius: '0px',
                    top: '-6px',
                    height: '40px',
                },
            });
        }

        let trackButtonIcon = null;
        switch (this.getTrackButtonMode()) {
            default:
            case 'roomMode':
                trackButtonIcon = <i className="fa fa-plus text-info" />;
                //trackButtonIcon = <i className="fa fa-link text-dark"/>
                break;
            case 'reservationMode':
                trackButtonIcon = <i className="fa fa-plus text-info" />;
                break;
            case 'groupReservationsMode':
                trackButtonIcon = <i className="fa fa-square-o text-dark" />; //when clicked, move to "fa-check-square-o"
                break;
        }
        //style={{height:`${tableContentHeight}px`}}
        const { style = { height: `${tableContentHeight}px`, overflowY: 'auto' } } = this.props;

        let Legend = showLegend ? (
            <ReservationStatusLegend
                toggleHideReservationStatusEnums={this.toggleHideReservationStatusEnums.bind(this)}
                hideReservationStatusEnums={this.state.hideReservationStatusEnums}
            />
        ) : null;

        //console.log(this.props);
        return (
            <div className="clear">
                <div className="clear" id="timeline" name="timeline" onMouseMove={this.onMouseDrag.bind(this)}>
                    <Timeline
                        style={style}
                        scale={scale}
                        enableSticky
                        clickElement={basic ? null : this.reservationClickHandler.bind(this)}
                        trackButtonIcon={basic ? null : trackButtonIcon}
                        clickTrackButton={basic ? null : this.clickTrackButton.bind(this)}
                        scrollToNow
                        isOpen={basic ? false : open}
                        timebar={timebar}
                        tracks={tracks2}
                        now={showMarker ? nowMarker : null}
                    />
                </div>

                {Legend ? <div className="d-none d-sm-block mt-2">{Legend}</div> : null}
            </div>
        );
    }
}

export default branch(
    {
        condoOwnerMap: ['monkeys', 'condoOwnerMap'],
        timelineYearsCount: ['uiStates', 'accommodationPriceListStack', 'timelineYearsCount'],
        cleaningStatusHeaders: ['monkeys', 'cleaningStatusHeadersWithCleaningStatuses']
    },
    ReservationsTimeline
);