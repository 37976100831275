import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import { saveModelData, getSpecificModelRow } from '../../data/actions/modelActions';
//import dummyData from "../SRCSettings/dummySRCData"

import SRCSettingsForm from '../SRCSettings/index';
import PageTitle from '../../components/pageTitle';
import FormHeader from '../../components/formHeader';
import pubsub from '../../data/pubsub';

class SRCTemplateForm extends Component {
    constructor(props) {
        super(props);
        this.name = React.createRef();
        this.srcTemplate = React.createRef();
        this.state = {
            srcTemplateId: null,
            roomInfoId_asTemplate: null,
            SRCSettings: {},
            name: '',
            description: '',
        };
    }

    async componentDidMount() {
        let srcTemplateId = this.props.srcTemplateId || this.props.match.params.srcTemplateId;
        srcTemplateId = parseInt(srcTemplateId, 10);
        this.redrawComponent(srcTemplateId);
    }

    componentDidUpdate(prevProps, prevState) {
        let srcTemplateId = this.props.srcTemplateId || this.props.match.params.srcTemplateId;
        srcTemplateId = parseInt(srcTemplateId, 10);
        if (prevState.srcTemplateId !== srcTemplateId) {
            if (!_.isNaN(prevState.srcTemplateId) && !_.isNaN(prevState.srcTemplateId)) {
                this.redrawComponent(srcTemplateId);
            }
        }
    }

    async redrawComponent(srcTemplateId) {
        if (!_.isUndefined(srcTemplateId) && srcTemplateId !== null && _.isNaN(srcTemplateId) === false) {
            let currentSRCTemplate = await getSpecificModelRow('SRCTemplate', srcTemplateId);
            this.clearValidationRefs();
            this.setState({
                srcTemplateId,
                name: currentSRCTemplate.name,
                description: currentSRCTemplate.description,
                SRCSettings: JSON.parse(currentSRCTemplate.SRCSettings),
            });
        } else {
            this.setState({
                srcTemplateId,
                //  SRCSettings: dummyData
            });
        }
    }

    handleNameChange(e) {
        this.setState({
            name: e.target.value,
        });
    }
    handleDescriptionChange(e) {
        this.setState({
            description: e.target.value,
        });
    }

    cancelHandler() {
        if (this.props.cancelHandler) {
            this.props.cancelHandler();
        } else {
            this.props.history.push('/srcTemplates');
        }
    }

    onDataSave(type, data) {
        let SRCSettings = _.clone(this.state.SRCSettings);
        SRCSettings[type] = data;
        this.setState({
            SRCSettings: SRCSettings,
        });
    }

    clearValidationRefs() {
        if (this.name && this.name.current) {
            this.name.current.className = this.name.current.className.replace('is-invalid', '');
        }
        if (this.srcTemplate && this.srcTemplate.current) {
            this.srcTemplate.current.className = this.srcTemplate.current.className.replace('is-invalid', '');
        }
    }

    markInvalidInputRefs(validationResults) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        _.forEach(invalidInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        _.forEach(validInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }
    getValidationInputs(data, isNew) {
        let invalidInputs = [];
        let validInputs = [];
        // this.clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(this.name);
        } else {
            validInputs.push(this.name);
        }
        if (isNew===false && (data.id === null || !_.isNumber(data.id) || isNaN(data.id))) {
            invalidInputs.push(this.srcTemplate);
        } else {
            validInputs.push(this.srcTemplate);
        }
        return { invalidInputs, validInputs };
    }

    onSRCTemplateSave() {
        //prvo validacija
        let validation = true;
        let validationMessage = 'Validation error';
        const isNew = this.props.match.path.lastIndexOf('/new') !== -1;

        if (isNew) {
            //if new
            if (!_.isNumber(this.state.roomInfoId_asTemplate)) {
                validation = false;
                validationMessage = window.translate("Please, select a valid template.");
            }
        }

        let srcTemplateId = this.state.srcTemplateId;
        let srcTemplateObject = {};
        srcTemplateObject['name'] = this.state.name;
        srcTemplateObject['description'] = this.state.description;
        srcTemplateObject['SRCSettings'] = JSON.stringify(this.state.SRCSettings);
        if (!_.isUndefined(srcTemplateId) && srcTemplateId !== null) {
            srcTemplateObject['id'] = parseInt(srcTemplateId, 10);
        }
        let validationResults = this.getValidationInputs(srcTemplateObject, isNew);
        this.markInvalidInputRefs(validationResults);
        if (validation && validationResults.invalidInputs.length === 0) {
            saveModelData('SRCTemplate', srcTemplateObject)
                .then((res) => {
                    if (this.props.callback) {
                        this.props.callback(res);
                    } else {
                        this.props.history.push('/srcTemplates');
                    }
                    // this.props.history.goBack()
                })
                .catch((err) => {
                    console.log(err);
                });
        } else {
            pubsub.emit('toastMessage', validationMessage, 'error');
        }
    }

    createTemplateFromRoom(e) {
        let roomInfoId_asTemplate = null;
        if (e.target.value) {
            try {
                let roomInfo = JSON.parse(e.target.value);
                roomInfoId_asTemplate = roomInfo.id;
                let SRCSettings = JSON.parse(roomInfo.SRCSettings);
                delete SRCSettings.roomTimestamp;
                SRCSettings.thermostatSensor.sensor_t1.connected = false;
                SRCSettings.thermostatSensor.sensor_t2.connected = false;
                SRCSettings.thermostatSensor.sensor_t3.connected = false;
                SRCSettings.selectTimestamp = Date.now();
                this.setState({ SRCSettings, roomInfoId_asTemplate });
            } catch (err) {
                console.warn(err);
                this.setState({ roomInfoId_asTemplate: null });
            }
        } else {
            this.setState({ roomInfoId_asTemplate });
        }
    }

    render() {
        let isNewTemplate = this.props.match.path.lastIndexOf('/new') !== -1;
        if (!isNewTemplate) {
            if (_.isUndefined(this.state.SRCSettings) || _.isEmpty(this.state.SRCSettings)) {
                return null;
            }
        }

        let roomsWithSettings = _.filter(this.props.rooms, (r) => {
            return r.SRCSettings !== null;
        });

        // console.log("----",roomsWithSettings);
        return (
            <div className="container-fluid">
                <PageTitle />

                <div className="container bg-white">
                    <div className="row justify-content-center">
                        <div className="col-md-10 pl-0 pr-0">
                            {this.props.header ? (
                                this.props.header
                            ) : (
                                <FormHeader>
                                    {this.state.srcTemplateId
                                        ? window.translate("EDIT SRC TEMPLATE")
                                        : window.translate("ADD SRC TEMPLATE")}
                                </FormHeader>
                            )}
                            <form
                                onSubmit={(e) => {
                                    e.preventDefault();
                                }}
                                className="pt-4 pb-4 pr-2 pl-2 bg-white  text-md-right"
                            >
                                <div className="form-group row justify-content-center">
                                    <label className="col-md-4 col-form-label" htmlFor="PL-name-input">
                                        {window.translate("Name:")}
                                    </label>
                                    <div className="col-md-8">
                                        <input
                                            ref={this.name}
                                            id="PL-name-input"
                                            autoComplete="off"
                                            value={this.state.name ? this.state.name : ''}
                                            onChange={this.handleNameChange.bind(this)}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>

                                <div className="form-group row justify-content-center">
                                    <label
                                        className="col-md-4 col-form-label align-self-center"
                                        htmlFor="PL-desc-input"
                                    >
                                        {window.translate("Description")}:
                                    </label>
                                    <div className="col-md-8">
                                        <textarea
                                            id="PL-desc-input"
                                            value={this.state.description ? this.state.description : ''}
                                            onChange={this.handleDescriptionChange.bind(this)}
                                            rows="4"
                                            cols="50"
                                            className="form-control"
                                        >
                                            {' '}
                                        </textarea>
                                    </div>
                                </div>

                                {isNewTemplate ? (
                                    <div className="form-group row justify-content-center">
                                        <label
                                            className="col-md-4 col-form-label align-self-center"
                                            htmlFor="PL-desc-input"
                                        >
                                            {window.translate("Create from config")}:
                                        </label>
                                        <div className="col-md-8">
                                            <select
                                                ref={this.srcTemplate}
                                                onChange={this.createTemplateFromRoom.bind(this)}
                                                className="form-control"
                                            >
                                                <option value={null}>{window.translate("Select room with configured SRC as a template")}</option>
                                                {_.map(roomsWithSettings, (r) => {
                                                    return (
                                                        <option key={r.id} value={JSON.stringify(r)}>
                                                            {r.name}
                                                        </option>
                                                    );
                                                })}
                                            </select>
                                        </div>
                                    </div>
                                ) : null}
                            </form>
                        </div>
                    </div>
                </div>

                <SRCSettingsForm
                    templateMode={true}
                    onSaveHandler={this.onDataSave.bind(this)}
                    {...this.props}
                    template={true}
                    SRCTemplateData={this.state.SRCSettings}
                />

                <div className="text-center pb-5 mt-2">
                    <div onClick={this.onSRCTemplateSave.bind(this)} className="btn  btn-outline-primary mx-1 pointer">
                        {window.translate("Save")}
                    </div>
                    <div
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className="btn  btn-outline-secondary mx-1 pointer"
                    >
                        {window.translate("Cancel")}
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        rooms: ['model', 'RoomInfo'],
    },
    SRCTemplateForm
);
