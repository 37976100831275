import React, { useState, useEffect } from 'react';
import DocScannSecondStep from './secondStep';
import KeyboardComp from './keyboard';
import rest from '../../data/restWrapper';
import pubsub from '../../data/pubsub';

function DocumentScanner(props) {
    const maxSteps = 3;
    const [step, setStep] = useState(0);
    const [pinCode, setPinCode] = useState('');
    const [showKeyboard, setShowKeyboard] = useState(false);
    const [companyInfo, setCompanyInfo] = useState(null);
    const [reservation, setReservation] = useState(null);
    const [docScannerUuid, setDocScannerUuid] = useState(null);

    useEffect(() => {
        const loadData = async () => {
            try {
                const _companyInfo = await rest('/getGeneralSettingByKey/companyInfo');
                setCompanyInfo(JSON.parse(_companyInfo.params));
                if (window.location.hash.indexOf('step2') !== -1) {
                    setStep(1);
                } else if (window.location.hash.indexOf('step3') !== -1) {
                    setStep(2);
                } else {
                    setStep(0);
                    setPinCode('');
                }
            } catch (err) {
                console.warn('err', err);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, [step]);

    useEffect(() => {
        const loadData = async () => {};

        loadData().catch((err) => {
            console.warn(err);
        });
    }, [reservation]);

    const nextStepCallback = () => {
        setStep(step === maxSteps ? maxSteps : step + 1);
    };

    const checkPinCode = async () => {
        //provjera pina tj. dohvaćanje rezervacije preko pin koda
        try {
            const res = await rest('/checkPinCodeForDocScanner', 'POST', { pinCode: pinCode });
            if(res && res.id){
                
                if(res.statusEnum === 0 || res.statusEnum === 1){
                    let otherGuests = await rest('/getReservationGuests/' + res.id);
                    const _reservation = { ...res, ...otherGuests };
                    pubsub.emit('toastMessage', 'Pin code scanned successfully', 'info');
                    
                    setReservation(_reservation);
                }else if(res.statusEnum === 2){
                    pubsub.emit('toastMessage', 'Guest already checked in', 'info');
                    setPinCode("")
                }else{
                    pubsub.emit('toastMessage', 'Reservation is closed', 'info');
                    setPinCode("")
                }
            }

        } catch (error) {
            pubsub.emit('toastMessage', error, 'error');
            setPinCode("")
            console.log('err', error);
        }
    };

    const openTheDoor = async () => {
        try {
            await rest('/openDoorForDocScannSelfCheckIn', 'POST', {
                pinCode: pinCode,
                docScannerUuid: docScannerUuid,
            });
        } catch (error) {
            pubsub.emit('toastMessage', error, 'error');
            console.log('error', error);
        }

        nextStepCallback();
    };

    let displayControll = null;

    switch (step) {
        case 0:
            displayControll = (
                <DocScannFirstStep
                    checkPinCode={checkPinCode}
                    pinCode={pinCode}
                    setPinCode={setPinCode}
                    showKeyboard={showKeyboard}
                    setShowKeyboard={setShowKeyboard}
                    reservation={reservation}
                    nextStepCallback={nextStepCallback}
                />
            );
            break;
        case 1:
            displayControll = (
                <DocScannSecondStep
                    reservation={reservation}
                    nextStepCallback={nextStepCallback}
                    setDocScannerUuid={setDocScannerUuid}
                />
            );
            break;
        case 2:
            displayControll = (
                <DocScannThirdStep
                    nextStepCallback={nextStepCallback}
                    openTheDoor={openTheDoor}
                    reservation={reservation}
                />
            );
            break;
        default:
        case 3:
            window.location = '/#/documentScanner';
            break;
    }
    return (
        <div className="m-0">
            <div className="p-3 m-0 border-bottom mb-5" style={{ backgroundColor: '#82d7ff' }}>
                <b className="text-uppercase">{companyInfo ? companyInfo.hotelName : ''}</b>
            </div>
            {displayControll}
        </div>
    );
}

const DocScannFirstStep = ({
    checkPinCode,
    pinCode,
    setPinCode,
    setShowKeyboard,
    showKeyboard,
    reservation,
    nextStepCallback,
}) => {
    return (
        <div className="col-10 col-lg-7 m-auto rounded shadow bg-white">
            <div className="text-center mb-3 mt-3 pt-3">
                <h3 className="mt-4 mb-4">{window.translate('Welcome to our hotel!')}</h3>
                <h5 className="mb-5">{window.translate('We wish you a pleasant stay.')}</h5>
                <p className="mt-5 pt-3">{window.translate('To enter, please enter the pin code first.')}</p>
            </div>
            <div className="m-auto col-8 col-md-4 mb-5">
                <input
                    onClick={() => setShowKeyboard(!showKeyboard)}
                    type="number"
                    placeholder="Pin code"
                    value={pinCode}
                    className="form-control"
                    readOnly
                />
                {showKeyboard ? (
                    <KeyboardComp
                        pinCode={pinCode}
                        setPinCode={setPinCode}
                        setShowKeyboard={setShowKeyboard}
                        checkPinCode={checkPinCode}
                    />
                ) : null}
            </div>
            <div className="m-auto text-center pb-4">
                {reservation && (
                    <div
                        className="p-2 col-4 col-md-2 mt-5 btn btn-sm btn-primary pointer"
                        onClick={() => {
                            if (reservation) {
                                window.location = '/#/documentScanner?step2';
                                nextStepCallback();
                            }
                        }}
                    >
                        {window.translate('NEXT STEP')}
                    </div>
                )}
            </div>
        </div>
    );
};

const DocScannThirdStep = ({ openTheDoor, reservation }) => {
    return (
        <div className="bg-white m-auto col-10 col-md-6 border rounded shadow">
            <div className="text-center mb-3 mt-5">
                <h3 className="mt-4 mb-4">{window.translate('You have successfully checked in to the hotel!')}</h3>
                <h5 className="mb-5">
                    {window.translate('You can proceed to your room ')}
                    <b>{reservation?.RoomInfo.name}</b>.
                </h5>
                <h6 className="mt-5 pt-3">{window.translate('Pleasant stay!')}</h6>
            </div>
            <div className="m-auto text-center pb-4">
                <div className="p-2 col-4 col-md-2 mt-5 btn btn-sm btn-primary pointer" onClick={() => openTheDoor()}>
                    {window.translate('FINISH')}
                </div>
            </div>
        </div>
    );
};

export default DocumentScanner;
