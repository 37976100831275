import React, { useEffect, useState } from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import { setModalContent } from '@components/modal';
import Delete from '@components/confirmModal';
import rest from '@data/restWrapper';
import PageTitle from '@components/pageTitle';
import Form from './form';
import pubsub from '@data/pubsub';
import baobabTree from '@data/state';
import Image from '@components/image';
import { toast } from 'react-toastify';

const PosItem = (props) => {
    const [posItems, setPosItems] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [itemsFromSearchBox, setItemsFromSearchBox] = useState([]);
    const [minibarItems, setMinibarItems] = useState([]);
    const [page, setPage] = useState(0);
    const [rows] = useState(20);

    useEffect(() => {
        const load = async () => {
            loadData();
        };

        load().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.posItem]);

    const loadData = async () => {
        //provjeriti kako dohvatit iz baobaba da gleda page and rows
        const _posItems = await rest('/PosItem?page=' + page + '&rows=' + rows);
        const _minibarItems = baobabTree.root.select(['model', 'RoomInfo']).get();
        setPosItems(_posItems);
        setMinibarItems(_minibarItems);
    };

    const getPageData = async (_page = page) => {
        const _posItems = await rest('/PosItem?page=' + _page + '&rows=' + rows);
        setPosItems(_posItems);
        setPage(_page);
    };

    const showFormModal = (posItemId) => {
        setModalContent(<Form posItemId={posItemId} />,posItemId ? window.translate('Edit item') : window.translate('New item'), false, 'modal-lg');
    };

    const onSearch = async (e) => {
        const path = '/searchService?search=' + e.target.value; //searchPosItem
        const _searchTerm = e.target.value;
        const _itemsFromSearchBox = await rest(path);
        setItemsFromSearchBox(_itemsFromSearchBox);
        setSearchTerm(_searchTerm);
    };

    const pageDown = async () => {
        if (page > 0) {
            const _page = page - 1;
            await getPageData(_page);
        }
    };

    const pageUp = async () => {
        if (posItems.length > 0) {
            const _page = page + 1;
            await getPageData(_page);
        }
    };

    const delPosItem = async (posItemId) => {
        let items = [];
        minibarItems.map((mb) => {
            if (mb.posItemIds !== null) {
                const item = JSON.parse(mb.posItemIds);
                item.forEach((i) => {
                    items.push(i);
                });
            }
            return null;
        });

        items = _.uniq(items);

        if (items.includes(posItemId)) {
            pubsub.emit('toastMessage', window.translate('Item exist in minibar'), 'error');
        } else {
            let item = posItems.find((s) => s.id === posItemId);
            item.plu = null;
            item.enabled = 0;
            await rest('/PosItem/' + posItemId, 'PUT', item);
            toast(window.translate("PosItem inactivated!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
        }
    };

    const deletePosItem = (posItemId) => {
        setModalContent(
            <Delete actionForYes={delPosItem.bind(this, posItemId)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const _posItems = searchTerm.length > 0 ? itemsFromSearchBox : posItems;

    const posItemsList = _posItems.map((p) => {
        let tax = props.taxes.find((t) => t.id === Number(p.taxId));
        if (tax) {
            tax = <small>{tax.name}</small>;
        }
        let category = p.category ? p.category.split(';') : [];
        category = category.map((c, key) => {
            return (
                <div key={key} className="badge badge-success mr-1 p-1">
                    {c}
                </div>
            );
        });

        return (
            <li key={p.id} className={'px-1 py-1 row shadow-sm bg-white my-1 rounded align-items-center'}>
                <div className="d-none d-sm-block col-1">{p.plu}</div>
                <div className="d-none d-lg-block col-1">
                    <Image posItemId={p.id} style={{ height: '30px', width: '30px' }} />
                </div>
                <div className="col-4 col-sm-3 col-md-2">{p.name}</div>
                <div className="col-4 col-sm-3 col-md-2">{category}</div>
                <div className="d-none d-md-block col-2">{p.price + ' ' + props.defaultCurrency}</div>
                <div className="d-none d-md-block col-1">{tax}</div>
                <div
                    className={
                        'd-none d-md-block col-1 text-right text-uppercase ' +
                        (p.enabled ? 'text-success' : 'text-danger')
                    }
                >
                    <small>{p.enabled ? <b>{window.translate('active')}</b> : window.translate('inactive')}</small>
                </div>
                <div className="d-block col-4 col-sm-3 col-md-2">
                    <button
                        data-toggle="hide"
                        data-target="#mainModal"
                        onClick={showFormModal.bind(this, p.id)}
                        className="btn btn-sm btn-outline-dark ml-1 mb-2 pull-right"
                    >
                        <i className="fa fa-edit"></i>
                    </button>
                    <button
                        data-toggle="hide"
                        data-target="#mainModal"
                        onClick={deletePosItem.bind(this, p.id)}
                        className="btn btn-sm btn-outline-danger ml-1 mb-2 pull-right"
                    >
                        <i className="fa fa-trash"></i>
                    </button>
                </div>
            </li>
        );
    });

    return (
        <div className="">
            <PageTitle />
            <div className="row">
                <div className="d-flex col-12">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {window.translate('GO BACK')}
                    </button>
                    <button
                        onClick={showFormModal.bind(this, null)}
                        className="btn btn-sm btn-primary ml-2 mr-2"
                        type="submit"
                    >
                        {window.translate('ADD NEW')}
                    </button>

                    <div className="m-auto align-self-center">
                        <button onClick={pageDown.bind(this)} className="btn btn-sm btn-outline-primary mr-2">
                            {window.translate('prev')}
                        </button>
                        <small className="mt-1">
                            {window.translate('page')} {page + 1}
                        </small>
                        <button onClick={pageUp.bind(this)} className="btn btn-sm btn-outline-primary ml-2">
                            {window.translate('next')}
                        </button>
                    </div>

                    <div className="align-self-end">
                        <input
                            onChange={onSearch.bind(this)}
                            placeholder={window.translate('Name or code')}
                            type="text"
                            className="form-control form-control-sm"
                        />
                    </div>
                </div>
            </div>

            <ul className="list-group mt-3 mx-3">
                <small>
                    <li className="px-1 py-2 row shadow-sm  bg-white">
                        <div className="d-none d-sm-block col-1"> {window.translate('PLU')}</div>
                        <div className="d-none d-lg-block col-1 "></div>
                        <div className="col-4 col-sm-3 col-md-2">{window.translate('Name')}</div>
                        <div className="col-4 col-sm-3 col-md-2">{window.translate('Category')}</div>
                        <div className="d-none d-md-block col-2">{window.translate('Price')}</div>
                        <div className="d-none d-md-block col-1">{window.translate('TAX')}</div>
                        <div className="d-none d-md-block col-1 text-right">{window.translate('Status')}</div>
                        <div className="col-4 col-sm-3 col-md-2"></div>
                    </li>
                </small>
            </ul>
            <ul className="list-group mt-2 mx-3">{posItemsList}</ul>
        </div>
    );
};

export default branch(
    {
        posItem: ['model', 'PosItem'],
        taxes: ['model', 'Tax'],
        defaultCurrency: ['defaultCurrency'],
    },
    PosItem
);