import React from 'react';
import { useBranch } from 'baobab-react/hooks';
import DateTime from './dateTime';
const HeaderToolbar = (props) => {
    const { posTerminals, loggedUser, previousInvoices } = useBranch({
        posTerminals: ['model', 'PosTerminal'],
        loggedUser: ['authTokenInfo', 'user'],
        previousInvoices: ['state', 'posTerminal', 'previousInvoices'],
    });

    const posTerminal = posTerminals.find((p) => p.id === Number(props?.match?.params?.posTerminalId));

    const openInNewTab = (url) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div className="row">
            <div className="col-2 d-flex align-items-center">
                <div
                    className=" pointer"
                    onClick={() => {
                        props.history.push('/');
                    }}
                >
                    <i className="fa fa-home px-1"></i>
                    {posTerminal ? posTerminal.name : ''}
                </div>
            </div>
            <div className="col-6 align-self-center">
                <div
                    className="btn btn-sm btn-outline-primary float-right ml-2 pointer"
                    onClick={() => props.history.push(`/report/invoices?posTerminal=${props?.match?.params?.posTerminalId}`)}
                >
                    {window.translate("Invoices") }
                </div>
                {previousInvoices.map((i) => {
                    return (
                        <div
                            key={i.id}
                            className="btn btn-sm btn-outline-primary float-right ml-2 pointer"
                            onClick={() => openInNewTab(window.location.origin + `/#/invoiceView/${i.id}`)}
                        >
                            #{i.id}
                        </div>
                    );
                })}
            </div>
            <div className="col-4 d-flex justify-content-between">
                <div className="text-right p-2">
                    {loggedUser ? (loggedUser.name + ' ' + loggedUser.surname).toUpperCase() : ''}
                </div>
                <div className="align-self-left p-2 mr-4">
                    <DateTime />
                </div>
            </div>
        </div>
    );
};

export default HeaderToolbar;
