import React, { useState, useEffect, useRef } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import { saveModelData } from '@data/actions/modelActions';
import rest from '@data/restWrapper';
import pubsub from '@data/pubsub';
import Image from '@components/image';
import Select from 'react-select/creatable';
import { closeModal } from '@components/modal';
import { toast } from 'react-toastify';
import Tooltip from '@components/tooltip/tooltip';

const unitOfMeasure = {
    piece: 'Piece',
    lit: 'L (liter)',
    kg: 'KG (kilogram)',
}; 

var emptyFormTemplate = {
    id: null,
    barcode: null, //add support for barcodes later on if neccecary
    plu: null,
    type: '',
    name: '',
    description: '',
    category: '',
    image: null,
    thumbnail: null,
    props: '{}',
    enabled: true,
    tags: '', //add this later on
    unitMeasure: unitOfMeasure[0],
    composites: null,
    price: 0,
    flexiblePrice: false,
    taxId: null,
    tax2Id: null,
    tax3Id: null,
};

const PosItemForm = (props) => {
    const [data, setData] = useState(_.clone(emptyFormTemplate));
    const [tagsValues, setTagsValues] = useState([]);
    const [tagsOptions, setTagsOptions] = useState([]);
    const name = useRef();
    const plu = useRef();

    useEffect(() => {
        const loadData = async () => {
            let _data = { ...data };
            const { posItemId } = props;
            if (posItemId) {
                //editing
                loadFormData(posItemId);
            } else {
                _data.plu = renderFirstAvailablePlu();
                setData(_data);
                checkValidationInputs(_data)
            }

            const _tagsOptions = [];
            props.PosItemTags.forEach((t) => {
                let obj = {};
                obj.value = t;
                obj.label = t;
                _tagsOptions.push(obj);
            });
            setTagsOptions(_tagsOptions);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.posItemId, props.posItems]);

    const loadFormData = async (serviceId) => {
        if (!_.isUndefined(serviceId) && serviceId !== null && _.isNaN(serviceId) === false) {
            const currentData = props.posItems.find((t) => t.id === Number(serviceId));
            const tags = currentData.category ? currentData.category.split(';') : [];
            const _tagsValue = [];
            tags.forEach((t) => {
                let obj = {};
                obj.value = t;
                obj.label = t;
                _tagsValue.push(obj);
            });
            setTagsValues(_tagsValue);
            setData(currentData);
            checkValidationInputs(currentData)
        }
    };

    const handleInputChange = (e) => {
        let _data = { ...data };
        if (e.target.type === 'text') {
            _data[e.target.name] = e.target.value;
            setData(_data);
        } else if (e.target.type === 'number') {
            _data[e.target.name] = parseFloat(e.target.value, 10);
            setData(_data);
        }
        if (e.target.name === 'plu') {
            _data.plu = renderFirstAvailablePlu();
            setData(_data);
        }
        if(e.target.name==="name"){
            checkValidationInputs(_data)
        }
    };

    const handleSelectChange = (e) => {
        let _data = { ...data };
        _data[e.target.name] = e.target.value;
        if (e.target.name === 'taxId' || e.target.name === 'tax2Id' || e.target.name === 'tax3Id') {
            if (isNaN(parseFloat(e.target.value))) {
                _data[e.target.name] = null;
            }
        }
        setData(_data);
    };

    const handleIntegerInputChange = (e) => {
        if (e.target.type === 'number') {
            let _data = { ...data };
            _data[e.target.name] = parseInt(e.target.value, 10);
            setData(_data);
            if(e.target.name==="plu"){
                checkValidationInputs(_data)
            }
        }
    };

    const handleCategoryChange = (tags) => {
        let category = [];
        if (tags !== null) {
            tags.forEach((t) => {
                category.push(t.value);
            });
            category = category.join(';');
        } else {
            category = null;
        }
        let _data = { ...data };
        _data['category'] = category;
        setTagsValues(tags);
        setData(_data);
    };

    const handleFileChange = (e) => {
        let _data = { ...data };
        var selectedFile = e.target.files;
        if (selectedFile.length > 0) {
            var imageFile = selectedFile[0];
            var fileReader = new FileReader();

            fileReader.onload = (e) => {
                var dataurl = e.target.result;
                var img = document.createElement('img');
                img.src = dataurl;
                img.onload = () => {
                    var canvas = document.createElement('canvas');
                    var MAX_WIDTH = 100;
                    var MAX_HEIGHT = 100;
                    var width = img.width;
                    var height = img.height;

                    if (width > height) {
                        if (width > MAX_WIDTH) {
                            height *= MAX_WIDTH / width;
                            width = MAX_WIDTH;
                        }
                    } else {
                        if (height > MAX_HEIGHT) {
                            width *= MAX_HEIGHT / height;
                            height = MAX_HEIGHT;
                        }
                    }
                    canvas.width = width;
                    canvas.height = height;

                    var ctx = canvas.getContext('2d');
                    ctx.drawImage(img, 0, 0, width, height);
                    var url = canvas.toDataURL('image/png');

                    _data.image = _data.thumbnail = url;
                    setData(_data);
                };
            };
            fileReader.readAsDataURL(imageFile);
        }
    };

    const checkValidationInputs = (objToValidate) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);
    }

    const markInvalidInputRefs = (validationResults) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!classes.includes('is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (classes.includes('is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    };

    const getValidationInputs = (_data) => {
        const invalidInputs = [],
            validInputs = [];
        if (_data.name === null || _data.name.trim() === '') {
            invalidInputs.push(name);
        } else {
            validInputs.push(name);
        }
        if (_data.plu === null || _.isNaN(_data.plu)) {
            invalidInputs.push(plu);
        } else {
            validInputs.push(plu);
        }
        return { invalidInputs, validInputs };
    };

    const renderFirstAvailablePlu = (_props = undefined) => {
        if (_props === undefined) {
            _props = props;
        }
        let pluArray = [];
        const min = 1;

        if (_props.posItems && !_.isEmpty(_props.posItems)) {
            _props.posItems.map((ta) => {
                if (ta.plu) {
                    pluArray.push(Number(ta.plu.toString()));
                }
                return null;
            });
        }

        if (!pluArray.includes(min)) {
            pluArray.push(min);
        }
        pluArray = _.sortBy(pluArray);
        let lowest = -1;
        let offset = pluArray[0];
        if (!_.isEmpty(pluArray)) {
            for (let i = 0; i < pluArray.length; ++i) {
                if (pluArray[i] !== offset) {
                    lowest = offset;
                    break;
                }
                ++offset;
            }
            if (lowest === -1) {
                lowest = pluArray[pluArray.length - 1] + 1;
            }
        } else {
            lowest = 1;
        }

        return Number(lowest);
    };

    const saveFormData = async (e) => {
        try {
            let service = { ...data };
            service.params = JSON.stringify(service.params);
            service.plu = service.plu === '' ? null : service.plu;

            let pluRepeats = { count: 0 }; //for null
            if (service.plu !== null) {
                pluRepeats = await rest('/checkIfPosItemPluIsUnique/' + service.id, 'POST', { plu: service.plu });
            }
            if (service.price === '') {
                service.price = 0;
                setData(service);
            }
            if (pluRepeats.count > 0) {
                throw new Error('PLU code already exists!');
            } else {
                let validationResults = getValidationInputs(service);
                markInvalidInputRefs(validationResults);
                if (validationResults.invalidInputs.length === 0) {
                    saveModelData('PosItem', service)
                        .then((res) => {
                            if(!service.id){
                                toast(window.translate("PosItem added!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
                            }
                            if (props.callback) {
                                props.callback(res);
                            } else {
                                if (props.history) {
                                    props.history.push('/priceLists/posItem');
                                }
                            }
                            setData({ ...emptyFormTemplate });
                            closeModal();
                        })
                        .catch((err) => {
                            pubsub.emit('toastMessage', err.message, 'error');
                        });
                }
            }
        } catch (err) {
            pubsub.emit('toastMessage', err.message, 'error');
        }
    };

    return (
        <div className="container pb-2">
            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Name')} *
                </label>
                <div className="col-md-8">
                    <input
                        ref={name}
                        autoComplete="off"
                        name="name"
                        placeholder={window.translate('Name')}
                        type="text"
                        className="form-control"
                        value={data.name ? data.name : ''}
                        onChange={handleInputChange.bind(this)}
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('PLU')}
                </label>
                <div className="col-md-8">
                    <input
                        ref={plu}
                        autoComplete="off"
                        name="plu"
                        placeholder={window.translate('Next available PLU: ') + renderFirstAvailablePlu()}
                        type="number"
                        className="form-control"
                        value={data.plu ? data.plu : ''}
                        onChange={handleIntegerInputChange.bind(this)}
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Description')}
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        name="description"
                        placeholder={window.translate('Description')}
                        type="text"
                        className="form-control"
                        value={data.description ? data.description : ''}
                        onChange={handleInputChange.bind(this)}
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Category')}
                </label>
                <div className="col-md-8">
                    <Select
                        isMulti
                        className="mt-2"
                        placeholder={window.translate('Add tags')}
                        options={tagsOptions}
                        onChange={handleCategoryChange.bind(this)}
                        value={tagsValues}
                    ></Select>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Image')}
                </label>
                <div className="col-md-4">
                    <input
                        autoComplete="off"
                        name="image"
                        placeholder={window.translate('Image')}
                        type="file"
                        id="fotka"
                        onChange={handleFileChange.bind(this)}
                    />
                    <div id="image"></div>
                </div>
                <div className="col-md-4">
                    <Image posItemId={props.posItemId} alt={data.name} style={{ height: '100px', width: '100px' }} />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Unit of measure')}
                </label>
                <div className="col-md-8">
                    <select
                        name="unitMeasure"
                        type="select"
                        autoComplete="off"
                        placeholder={window.translate('Service type')}
                        className="form-control"
                        value={data.unitMeasure ? data.unitMeasure : ''}
                        onChange={handleSelectChange.bind(this)}
                    >
                        {Object.keys(unitOfMeasure).map((key) => (
                            <option key={key} value={key}>
                                {unitOfMeasure[key]}
                            </option>
                        ))}
                    </select>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Price')}
                </label>
                <div className="input-group col-md-8">
                    <input
                        autoComplete="off"
                        name="price"
                        placeholder={window.translate('Price')}
                        type="number"
                        className="form-control"
                        value={data.price ? data.price : ''}
                        onChange={handleInputChange.bind(this)}
                    />
                    <div className="input-group-append">
                        <span className="input-group-text form-control">
                                    <i
                                        name="flexiblePrice"
                                        type="checkbox"
                                        checked={data.flexiblePrice}
                                        onClick={() => {
                                            let _data = { ...data };
                                            _data['flexiblePrice'] = !_data.flexiblePrice;
                                            setData(_data);
                                        }}
                                        className={
                                            data.flexiblePrice
                                                ? 'fa fa-check-square-o fa-lg pointer'
                                                : 'fa fa-square-o fa-lg pointer'
                                        }
                                    ></i>
                                <Tooltip
                                    message={window.translate(
                                        'If the square is marked the price can be changed in the terminal' 
                                    )}
                                    style={{marginTop:"-30px", marginLeft:"-90px", width: 'auto'}}
                                >
                                    <i className="fa fa-info-circle text-primary ml-2"></i>
                                </Tooltip>
                        </span>
                        
                    </div>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-3 col-form-label" htmlFor="">
                    {window.translate('Tax')}:
                </label>
                <div className="col-8">
                    <select
                        type="select"
                        name="taxId"
                        className="form-control"
                        onChange={handleSelectChange.bind(this)}
                        value={data.taxId ? data.taxId : ''}
                    >
                        <option>{window.translate('No tax')}</option>
                        {props.taxes.map((t) => {
                            return t.id !== Number(data.tax2Id) && t.id !== Number(data.tax3Id) ? (
                                <option key={t.id} value={t.id}>
                                    {t.name}
                                </option>
                            ) : null;
                        })}
                    </select>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-3 col-form-label" htmlFor="">
                    {window.translate('Tax 2')}:
                </label>
                <div className="col-8">
                    <select
                        type="select"
                        name="tax2Id"
                        className="form-control"
                        onChange={handleSelectChange.bind(this)}
                        value={data.tax2Id ? data.tax2Id : ''}
                    >
                        <option>{window.translate('No tax')}</option>
                        {props.taxes.map((t) => {
                            return t.id !== Number(data.taxId) && t.id !== Number(data.tax3Id) ? (
                                <option key={t.id} value={t.id}>
                                    {t.name}
                                </option>
                            ) : null;
                        })}
                    </select>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-3 col-form-label" htmlFor="">
                    {window.translate('Tax 3')}:
                </label>
                <div className="col-8">
                    <select
                        type="select"
                        name="tax3Id"
                        className="form-control"
                        onChange={handleSelectChange.bind(this)}
                        value={data.tax3Id ? data.tax3Id : ''}
                    >
                        <option>{window.translate('No tax')}</option>
                        {props.taxes.map((t) => {
                            return t.id !== Number(data.taxId) && t.id !== Number(data.tax2Id) ? (
                                <option key={t.id} value={t.id}>
                                    {t.name}
                                </option>
                            ) : null;
                        })}
                    </select>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Enabled')}
                </label>
                <div className="col-md-8">
                    <input
                        onChange={(e) => {
                            let _data = { ...data };
                            _data['enabled'] = !_data.enabled;
                            setData(_data);
                        }}
                        checked={data.enabled}
                        name="enabled"
                        type="checkbox"
                    />
                    &ensp;<small>{window.translate('Enable item at POS')}</small>
                </div>
            </div>
            <br />

            <div className="text-center mb-5 pt-5">
                <button data-dismiss="modal" onClick={saveFormData.bind(this)} className="btn btn-outline-primary">
                    {window.translate('Save')}
                </button>
                <button data-dismiss="modal" onClick={closeModal.bind(this)} className="btn btn-outline-secondary ml-2">
                    {window.translate('Cancel')}
                </button>
            </div>
        </div>
    );
};

export default branch(
    {
        posItems: ['model', 'PosItem'],
        taxes: ['model', 'Tax'],
        PosItemTags: ['tag', 'PosItem'],
    },
    PosItemForm
);
