import React, { useEffect } from 'react';
import Select from 'react-select';
import { WorldLanguages } from '@data/worldLanguages';
import Keywords from './keyWords';
import translate from '../../data/translations';

const VariousLanguages = props => {
    useEffect(() => {}, []);

    const handleChange = obj => {
        props.onLanguageSelect(obj.value, templateType);
    };

    const languageList = Object.keys(WorldLanguages);
    const templateType = props.uniqueName;

    const getTitleBodyRows = () => {
        let titleBodyRows = [];

        for (const key in props.titleBodyTranslations) {
            titleBodyRows.push(
                <div className="row p-2 border rounded m-1" key={key}>
                    <div className="col-12 pb-1">
                        <span>
                            <strong>
                                {WorldLanguages[key].name} {`(${key})`}
                            </strong>
                        </span>
                        <span
                            className="btn btn-outline-danger btn-sm pull-right pointer"
                            onClick={props.removeLanguageFromSelection.bind(this, key)}
                        >
                            <i className="fa fa-trash"></i>
                        </span>
                    </div>
                    <div className="col-md-3">
                        {' '}
                        <label className="my-1 mr-2 " htmlFor="">
                            {' '}
                            <label>{translate('Title')}</label>:
                        </label>{' '}
                    </div>
                    <div className="col-md-9">
                        <input
                            type="text"
                            name={key}
                            className="form-control"
                            value={props.titleBodyTranslations[key].title}
                            onChange={props.onLanguageFieldChange.bind(this, key, 'title')}
                        />
                    </div>

                    <div className="col-md-3">
                        {' '}
                        <label className="my-1 mr-2 " htmlFor="">
                            {' '}
                            <label>{translate('Body')}</label>:
                        </label>{' '}
                    </div>
                    <div className="col-md-9">
                        <textarea
                            rows="5"
                            type="text"
                            name={key}
                            className="form-control"
                            value={props.titleBodyTranslations[key].body}
                            onChange={props.onLanguageFieldChange.bind(this, key, 'body')}
                        />
                    </div>
                </div>
            );
        }
        return titleBodyRows;
    };

    const optionList = languageList.map(itm => {
        return { label: WorldLanguages[itm].name, value: itm };
    });
    return (
        <div>
            <button
                onClick={() => {
                    props.setShowOtherLanguages(false);
                }}
                className="btn btn-sm btn-secondary mb-2"
            >
                {window.translate('GO BACK')}
            </button>
            <div className="row">
                <div className="col-md-6">
                    {getTitleBodyRows()}
                    <Select
                        // isMulti
                        className="mt-2"
                        placeholder={window.translate('Select options...')}
                        options={optionList}
                        name="languageSelect"
                        onChange={handleChange.bind(this)}
                        // value={valuesRequiringVisa}
                    ></Select>
                </div>

                <div className="col-md-6">
                    <Keywords keyWords={props.keyWords}></Keywords>
                </div>

                <div className="text-center col-md-12 my-2">
                    <div
                        onClick={props.saveLanguagesFormData.bind(this)}
                        className="btn btn-outline-primary mb-4 pointer"
                    >
                        {window.translate('Save')}
                    </div>
                </div>
            </div>
        </div>
    );
};

export default VariousLanguages;
