import React from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import { setModalContent } from '@components/modal';
import DeleteDialog from '../../components/confirmModal';
import { deleteModelData } from '../../data/actions/modelActions';
import WhiteListForm from './form';
import PageTitle from '../../components/pageTitle';
import baobab from '../../data/state';
import { toast } from 'react-toastify';

const WhiteList = (props) => {
    const showWhiteListForm = (whitelist) => {
        setModalContent(<WhiteListForm whitelist={whitelist} />, window.translate('New White List'), null);
    };

    const edit = (whitelist) => {
        setModalContent(
            <WhiteListForm whitelist={whitelist} />,
            window.translate('Edit White List'),
            false,
            'modal-md'
        );
    };

    const deleteFunc = (whitelist) => {
        setModalContent(
            <DeleteDialog
                actionForYes={async () => {
                    await deleteModelData('WhiteList', whitelist.id);
                    toast(window.translate("Whitelist deleted!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
                    const _whitelist = props.whitelist.filter(w=>w.id!==whitelist.id)
                    baobab.root.select(['model', 'WhiteList']).set(_whitelist);
                }}
                name={whitelist.name}
            />,
            window.translate('Delete White List'),
            false,
            'modal-md'
        );
    };

    const select = (whitelist) => {
        props.history.push('/whiteListItems/' + whitelist.id);
    };

    return (
        <div className="">
            <PageTitle />
            <div className="row">
                <div className="d-flex col-12">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {window.translate('GO BACK')}
                    </button>
                    <button onClick={showWhiteListForm.bind(this, {name: null, description: null, tags: null})} className="btn btn-sm btn-primary ml-2">
                        {window.translate('ADD NEW')}
                    </button>
                </div>
            </div>

            <div className="list-group mt-3 mx-3">
                <small>
                    <div className="px-1 py-2 row shadow-sm bg-white">
                        <div className="col-3">{window.translate('Name')}</div>
                        <div className="col-7">{window.translate('Tags')}</div>
                    </div>
                </small>
            </div>

            <ul className="list-group mt-2 mx-3">
                {props.whitelist &&
                    props.whitelist.map((w, key) => {
                        let tags = w.tags ? w.tags.split(';') : [];
                        tags = _.map(tags, (t, key) => {
                            return (
                                <span key={w.id + t + key} className="badge badge-primary mr-1">
                                    {t}
                                </span>
                            );
                        });

                        return (
                            <li className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'} key={w.id + key}>
                                <div className="col-3">{w.name}</div>

                                <span className="col-7">{tags}</span>
                                <div className="col-2 ">
                                    <button
                                        onClick={edit.bind(this, w)}
                                        className="btn btn-sm btn-outline-secondary pull-right"
                                    >
                                        <i className="fa fa-edit" />
                                    </button>
                                    <button
                                        onClick={deleteFunc.bind(this, w)}
                                        className="btn btn-sm btn-outline-danger pull-right mr-1"
                                    >
                                        <i className="fa fa-trash" />
                                    </button>
                                    <button
                                        onClick={select.bind(this, w)}
                                        className="btn btn-sm btn-outline-secondary pull-right mr-1"
                                    >
                                        <i className="fa fa-list" />
                                    </button>
                                </div>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
};

export default branch(
    {
        whitelist: ['model', 'WhiteList'],
    },
    WhiteList
);
