import React from 'react';
import { branch } from 'baobab-react/higher-order';
import baobab from '@data/state';

var modalContent = null; //react component placeholder!!!

export function setModalContent(content, header, showBottomCloseButton = true, width = 'modal-lg', modalParams = {}) {
    modalContent = content;
    baobab.root.select('modalData').set({
        display: true,
        header,
        showBottomCloseButton,
        width,
        params: modalParams,
    });
}

export function closeModal() {
    baobab.root.select(['modalData', 'display']).set(false);
    modalContent = null;
}

function MainModal({ data }) {
    const { display, width, header, showBottomCloseButton } = data;
    const style = display === true ? { display: 'block', overflowY: 'auto' } : { display: 'none' };

    const modalOverlayStyle = {
        position: 'fixed' /* Sit on top of the page content */,
        display: 'none' /* Hidden by default, but will be overwritten dynamicly */,
        width: '100%' /* Full width (cover the whole page) */,
        height: '100%' /* Full height (cover the whole page) */,
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0,0,0,0.5)' /* Black background with opacity */,
        zIndex: 10000 /* Specify a stack order in case you're using a different order for other elements */,
    };

    return (
        <div style={{ ...modalOverlayStyle, ...style }}>
            <div className="modal fade show" id="mainModal" tabIndex="-1" style={style}>
                <div className={'modal-dialog ' + width}>
                    <div className="modal-content">
                        {header ? (
                            <div className="modal-header">
                                <h5 className="modal-title">{header}</h5>
                                <button type="button" className="close" onClick={closeModal}>
                                    <span aria-hidden="true">&times;</span>
                                </button>
                            </div>
                        ) : null}

                        <div className="modal-body">{modalContent}</div>

                        {showBottomCloseButton ? (
                            <div className="modal-footer">
                                <button onClick={closeModal} type="button" className="btn btn-secondary">
                                    {window.translate('Close')}
                                </button>{' '}
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
}

export function showConfirmationModal(props){
    const { title = 'title', callback = ()=>{} } = props; //defaults are here
    setModalContent(
        <div className="container-fluid">
            <div className="text-center">
                {window.translate('Please confirm?')}
                <hr />
            </div>

            <div className="text-center">
                <button
                    onClick={async () => {
                        callback();
                        closeModal();
                    }}
                    className="btn btn-sm btn-outline-primary mr-2"
                >
                    {window.translate('Confirm')}
                </button>

                <button
                    onClick={() => {
                        closeModal();
                    }}
                    className="btn btn-sm btn-outline-secondary"
                >
                    {window.translate('Cancel')}
                </button>
            </div>
        </div>,

        title, // header
        false, // showCloseButton
        'modal-md'
    );
};


export default branch(
    {
        data: ['modalData'],
    },
    MainModal
);