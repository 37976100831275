import rest from '@data/restWrapper';
import { UserInfo } from '@common/modelDefinition';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';

export async function getMaids(): Promise<UserInfo[]> {
    return await rest('/getEmployeesByRole/maid');
}

export async function loadHouseholdPlan(): Promise<any[]> {
    startGlobalLoading();

    const householdPlan = await rest('/getPlannedCleaningStatusHeaders');
    stopGlobalLoading();
    return householdPlan;
}



export const updateCleaningPlanOnSelectedRoom = async (
    cleaningStatusHeaderId: number | null,
    selectedMaid: string,
    roomInfoId: number,
    position:number,
    deleteFromPosition: boolean | null = null
) => {
    startGlobalLoading();
    const updatedCleaningStatus = await rest('/updateCleaningPlanOnSelectedRoom', 'POST', {
        cleaningStatusHeaderId,
        selectedMaid,
        roomInfoId,
        position,
        deleteFromPosition,
    });
    stopGlobalLoading();
    return updatedCleaningStatus;
};


export const updateCleaningPlansOnSelectedRoom=async(cleaningStatusHeaderIds:number[],selectedMaids:string[], roomInfoId:number)=>{
    startGlobalLoading();
    await rest('/updateCleaningPlansOnSelectedRoom', 'POST', {
        cleaningStatusHeaderIds,
        selectedMaids,
        roomInfoId,
    });
    stopGlobalLoading();
}
