import React, { Component } from 'react';
import ReportToolbar from '../reportingToolbar';
import rest from '../../data/restWrapper';
import _ from 'lodash';
import PageTitle from '../../components/pageTitle';
import baobabTree from '../../data/state/index';
import InvoicesListView from './listView';
import { branch } from 'baobab-react/higher-order';

class InvoicesReport extends Component {
    constructor() {
        super();
        this.state = {
            searchText: '',
            invoiceType: null,
            invoices: [],
        };
    }

    async componentDidMount() {
        let from = baobabTree.root.select('uiStates', 'invoicesReport', 'from').get();
        let to = baobabTree.root.select('uiStates', 'invoicesReport', 'to').get();
        let invoices = await rest('/getInvoicesList?from=' + from.getTime() + '&to=' + to.getTime()); //24 hours stronger... :) until you figure out how to handle UTC offset
        baobabTree.root.select('model', 'Invoice').set(invoices);
        this.setState({ invoices });
    }

    async componentDidUpdate(prevProps, prevState) {
        //this.props.match.params.customerId
        if (prevProps.match) {
            if (prevProps.from !== this.props.from || prevProps.to !== this.props.to) {
                let invoices = await rest(
                    '/getInvoicesList?from=' + this.props.from.getTime() + '&to=' + this.props.to.getTime()
                );
                baobabTree.root.select('model', 'Invoice').set(invoices);
            }
        }
    }

    handleDateSelection(type, date) {
        baobabTree.root.select('uiStates', 'invoicesReport', type).set(date);
    }

    onSearch(e) {
        this.setState({ searchText: e.target.value });
    }

    handleInvoiceType(_invoiceType) {
        let invoiceType = _.clone(this.state.invoiceType);
        if (invoiceType === _invoiceType) {
            return null;
        } else {
            invoiceType = _invoiceType;
        }
        this.setState({ invoiceType });
    }

    render() {
        const { invoices } = this.props;
        const { invoiceType } = this.state;

        let filteredInvoices =
            invoices !== null
                ? _.filter(invoices, (i) => {
                      if (this.state.searchText !== '') {
                          let hit = false;

                          if (_.isString(i.customerName)) {
                              hit =
                                  i.customerName.toLowerCase().lastIndexOf(this.state.searchText.toLowerCase()) !== -1;
                          }
                          if (_.isString(i.companyName)) {
                              hit = i.companyName.toLowerCase().lastIndexOf(this.state.searchText.toLowerCase()) !== -1;
                          }
                          if (Number(this.state.searchText) === i.reservationId) {
                              hit = true;
                          }
                          return hit;
                      } else {
                          return true;
                      }
                  })
                : [];

        if (invoiceType !== null) {
            filteredInvoices = _.filter(filteredInvoices, (i) => i.invoiceType === invoiceType);
        }

        if (this.props.history.location.search.includes('posTerminal')) {
            const posTerminalId = this.props.history.location.search.split('=')[1];
            filteredInvoices = filteredInvoices.filter(
                (f) => f.terminalType === 'posTerminal' && f.posTerminalId === Number(posTerminalId)
            );
        }

        let controllButtons = (
            <div>
                <span>
                    <button
                        onClick={() => {
                            this.props.history.goBack();
                        }}
                        className="btn btn-sm btn-secondary mr-1 ml-1"
                    >
                        {window.translate('GO BACK')}
                    </button>
                </span>
                <span>
                    <button
                        onClick={this.handleInvoiceType.bind(this, null)}
                        className={
                            this.state.invoiceType === null
                                ? 'btn btn-sm btn-secondary m-1'
                                : 'btn btn-sm btn-light border m-1'
                        }
                    >
                        {window.translate('All')}
                    </button>
                    <button
                        onClick={this.handleInvoiceType.bind(this, 0)}
                        className={
                            this.state.invoiceType === 0
                                ? 'btn btn-sm btn-secondary m-1'
                                : 'btn btn-sm btn-light border m-1'
                        }
                    >
                        {window.translate('Regular')}
                    </button>
                    <button
                        onClick={this.handleInvoiceType.bind(this, 1)}
                        className={
                            this.state.invoiceType === 1
                                ? 'btn btn-sm btn-secondary m-1'
                                : 'btn btn-sm btn-light border m-1'
                        }
                    >
                        {window.translate('Advance')}
                    </button>
                    <button
                        onClick={this.handleInvoiceType.bind(this, 2)}
                        className={
                            this.state.invoiceType === 2
                                ? 'btn btn-sm btn-secondary m-1'
                                : 'btn btn-sm btn-light border m-1'
                        }
                    >
                        {window.translate('Proforma')}
                    </button>
                </span>
            </div>
        );

        return (
            <div>
                <PageTitle />

                <ReportToolbar
                    classNameSufix="bg-white border shadow-sm rounded p-2"
                    {...this.state}
                    from={this.props.from}
                    to={this.props.to}
                    actionsComponent={controllButtons}
                    handleDateSelection={this.handleDateSelection.bind(this)}
                    onSearch={this.onSearch.bind(this)}
                />

                <InvoicesListView history={this.props.history} filteredInvoices={filteredInvoices} />
            </div>
        );
    }
}

export default branch(
    {
        viewType: ['uiStates', 'invoicesReport', 'viewType'],
        from: ['uiStates', 'invoicesReport', 'from'],
        to: ['uiStates', 'invoicesReport', 'to'],
        SRCProxies: ['model', 'SRCProxy'],
        invoices: ['model', 'Invoice'], //ne sjećam se zašto je ovo ovdje....???? ali mislim da ne držimo račune u baobabu???
        softwareFiscal: ['softwareFiscal'],
        authTokenInfo: ['authTokenInfo'],
        taxes: ['model', 'Tax'],
        fiscalGeneralSettings: ['fiscal', 'fiscalGeneralSettings'],
    },
    InvoicesReport
);
