import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import { setModalContent } from '@components/modal';
import PageTitle from '../../../components/pageTitle';
import rest from '../../../data/restWrapper';
import MinibarForm from './form';
import { isFeatureEnabled } from '../../../components/FeatureFlag';
import { useBranch } from 'baobab-react/hooks';
import PermissionCheck from '../../../components/PermissionCheck';

var minibarLogTemplate = {
    roomInfoId: null,
    posItemId: null,
    previousState: null,
    newState: null,
    userUUID: null,
    logType: null,
};

const Minibar = (props) => {
    const [posItemsQuantityState, setPosItemsQuantity] = useState({});
    const [valuesState, setValue] = useState('');
    const [addOrRemoveState, setAddOrRemove] = useState('');
    const [dataChange, setDataChange] = useState(false);

    const { posItems, roomInfos } = useBranch({
        posItems: ['model', 'PosItem'],
        roomInfos: ['map', 'RoomInfo'],
    });
    const room = roomInfos ? roomInfos[props.match.params.roomInfoId] : null;

    const fetchData = async () => {
        try {
            if (room && room.posItemIds && (room.posItemIds !== null || room.posItemIds.length !== 0)) {
                let minibarLog = await rest(
                    '/getMinibarStatesForRoom/' + Number(props.match.params.roomInfoId)
                );
                let posItemsQuantity = {};
                minibarLog.forEach((lg) => {
                    posItemsQuantity[lg.posItemId] = {
                        currentValue: lg.newState,
                        newValue: lg.newState,
                        addOrRemove: '',
                        removeFromMinibar: null,
                        logType: null,
                        id: lg.posItemId,
                    };
                });
                setPosItemsQuantity(posItemsQuantity);
            }
        } catch (err) {
            console.log(err);
        }
    };

    useEffect(() => {
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [room, dataChange]);

    const showFormModal = () => {
        let roomInfo = _.clone(props.roomInfo.find((r) => r.id === Number(props.match.params.roomInfoId)));
        setModalContent(
            <MinibarForm roomInfo={roomInfo} posItemsQuantity={posItemsQuantityState} fetchData={fetchData} />,
            window.translate('Add items'),
            false,
            'modal-lg'
        );
    };

    const handleIntegerInputChange = (item, e) => {
        if (e.target.type === 'number') {
            let posItemsQuantity = { ...posItemsQuantityState };

            if (posItemsQuantity && posItemsQuantity[item.id]) {
                posItemsQuantity[item.id].newValue = posItemsQuantity[item.id].currentValue;
            } else if (posItemsQuantity === undefined) {
                posItemsQuantity = {};
                posItemsQuantity[item.id].newValue = Number(e.target.value);
                posItemsQuantity[item.id].addOrRemove = Number(e.target.value);
            }

            if (posItemsQuantity[item.id]) {
                if (e.target.name === 'addOrRemove') {
                    posItemsQuantity[item.id]['newValue'] =
                        Number(posItemsQuantity[item.id].currentValue) + Number(e.target.value);
                    Number(e.target.value) > 0
                        ? (posItemsQuantity[item.id]['logType'] = 0)
                        : (posItemsQuantity[item.id]['logType'] = 1);

                    posItemsQuantity[item.id]['addOrRemove'] = Number(e.target.value);
                    posItemsQuantity[item.id]['id'] = item.id;
                }
            } else {
                posItemsQuantity[item.id] = {
                    currentValue: 0,
                    newValue: Number(e.target.value),
                    logType: 0,
                    id: item.id,
                    addOrRemove: Number(e.target.value),
                };
            }

            let values = Number(posItemsQuantity[item.id]['newValue']);
            let addOrRemove = Number(e.target.value);

            setPosItemsQuantity(posItemsQuantity);
            setValue((prevValuesState) => {
                return { ...prevValuesState, values };
            });
            setAddOrRemove((prevAddOrRemoveState) => {
                return { ...prevAddOrRemoveState, addOrRemove };
            });
        }
    };

    const saveData = () => {
        let posItemsQuantity = {...posItemsQuantityState};
        try {
            _.forIn(posItemsQuantity, async (log, key) => {
                if (log.currentValue !== log.newValue) {
                    let posItemId = Number(key);
                    let payload = _.clone(minibarLogTemplate);
                    payload.roomInfoId = Number(props.match.params.roomInfoId);
                    payload.posItemId = posItemId;
                    payload.userUUID = props.loggedUser.uuid;
                    payload.previousState = log.currentValue;
                    payload.newState = log.newValue;
                    payload.logType = log.logType;
                    log.addOrRemove = '';
                    log.logType = null;
                    log.currentValue = log.newValue;
                    await rest('/saveMinibarLog', 'POST', payload);
                }
            });
        } catch (err) {
            console.log(err);
        }

        setPosItemsQuantity(posItemsQuantity);
        setAddOrRemove('');
        setTimeout(() => {
            setDataChange(!dataChange);
        }, 2000);
    };

    if (!isFeatureEnabled('minibar')) {
        return window.translate('Minibar disabled');
    }

    let roomInfo = props.roomInfo.find((r) => r.id === Number(props.match.params.roomInfoId));

    if (roomInfo === undefined) {
        return 'Loading ...';
    }

    let title = window.translate('Minibar from ') + ` ${roomInfo.name}`;
    let posItemIds = roomInfo.posItemIds ? JSON.parse(roomInfo.posItemIds) : [];
    let roomMinibarItemIds = [];
    posItemIds.forEach((p) => {
        posItems.forEach((item) => {
            if (item.id === p && item.enabled) {
                roomMinibarItemIds.push(p);
            }
        });
    });

    return (
        <div>
            <PageTitle title={title} />

            <div className="row">
                <div className="d-flex col-12">
                    <button
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {window.translate('GO BACK')}
                    </button> 
                    <PermissionCheck name="minibar_addRemove">
                        <button onClick={showFormModal} className="btn btn-sm btn-primary ml-2">
                            {window.translate('ADD/REMOVE ITEM')}
                        </button>
                    </PermissionCheck>
                </div>
            </div>

            <div className="list-group mt-3 mx-3">
                <small>
                    <div className="px-1 py-2 row shadow-sm bg-white">
                        <div className="col-2 col-2">{window.translate('PLU')}</div>
                        <div className="col-2 col-4">{window.translate('Name')}</div>
                        <div className="col-2 col-3">{window.translate('Quantity')}</div>
                        <div className="col-2 col-3"></div>
                    </div>
                </small>
            </div>

            <ul className="list-group mt-2 mx-3">
                {roomMinibarItemIds.map((itemId, key) => {
                    let item = _.find(posItems, { id: Number(itemId) });
                    let values = valuesState;
                    let addOrRemove = addOrRemoveState;
                    if (item) {
                        if (posItemsQuantityState[item.id]) {
                            values =
                                posItemsQuantityState[item.id]['currentValue'] ===
                                posItemsQuantityState[item.id].newValue
                                    ? posItemsQuantityState[item.id]['currentValue']
                                    : posItemsQuantityState[item.id].newValue;
                            addOrRemove = posItemsQuantityState[item.id]['addOrRemove']
                                ? posItemsQuantityState[item.id]['addOrRemove']
                                : '';
                        }
                        return (
                            <li key={itemId} className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                                <div className="col-sm-2 col-md-2">{item.plu}</div>
                                <div className="col-sm-2 col-md-4">{item.name}</div>
                                <div className="col-sm-2 col-md-3">
                                    <label>{posItemsQuantityState[item.id] ? values : 0}</label>
                                </div>
                                <div className="col-sm-2 col-md-3">
                                    <input
                                        autoComplete="off"
                                        name="addOrRemove"
                                        placeholder={window.translate('Add or remove')}
                                        type="number"
                                        className="form-control"
                                        onChange={handleIntegerInputChange.bind(this, item)}
                                        value={posItemsQuantityState[item.id] ? addOrRemove : ''}
                                    />
                                </div>
                            </li>
                        );
                    } else {
                        return null;
                    }
                })}
            </ul>

            <div className="text-center mb-5 pt-5">
                <button data-dismiss="modal" onClick={saveData} className="btn btn-outline-primary">
                    {window.translate('Save')}
                </button>
                <button
                    data-dismiss="modal"
                    onClick={() => {
                        setAddOrRemove('');
                        setDataChange(!dataChange);
                    }}
                    className="btn btn-outline-secondary ml-2"
                >
                    {window.translate('Cancel')}
                </button>
            </div>
        </div>
    );
};

export default branch(
    {
        roomInfo: ['model', 'RoomInfo'],
        loggedUser: ['authTokenInfo', 'user'],
    },
    Minibar
);
