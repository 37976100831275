import React from 'react';
import PageTitle from '@components/pageTitle';
import rest, { fileUpload } from '@data/restWrapper';
import { branch } from 'baobab-react/higher-order';
import { isFeatureEnabled } from '@components/FeatureFlag';
import { logout } from '@data/actions';

const DatabaseManagement = (props) => {
    const { loggedUser } = props;

    const [restoringInProgress, setRestoringInProgress] = React.useState(false);

    if (loggedUser.role !== 'admin') {
        return window.translate('Not authorized');
    }

    if (!isFeatureEnabled('databaseTools')) {
        return window.translate('Feature not enabled!');
    }

    if (restoringInProgress) {
        return window.translate('Database restoring in progress. Please wait.');
    }

    return (
        <div>
            <PageTitle />

            <div className="row">
                <div className="col-6">
                    <h4>{window.translate('Manual backup')}</h4>
                    <DumpAndDownloadDatabase />
                    <div className="mt-2">
                        <UploadAndRestoreDatabase setRestoringInProgress={setRestoringInProgress} />
                    </div>

                    <div>
                        {window.translate(
                            'WARNING: If using manual mode, make sure you have fast connection with server.'
                        )}
                    </div>
                </div>
            </div>
        </div>
    );
};

const DumpAndDownloadDatabase = (props) => {
    const [dumpingInProgress, setDumpingInProgress] = React.useState(false);
    const dumpAndDownloadDatabase = async () => {
        setDumpingInProgress(true);
        const { dumpName } = await rest('/dumpDatabase', 'POST', {});
        setDumpingInProgress(false);

        if (process.env.NODE_ENV === 'development') {
            window.open(
                `http://localhost:7777/downloadDatabaseDump/${dumpName}`,
                '_blank' // <- This is what makes it open in a new window.
            );
        } else {
            window.open(
                `downloadDatabaseDump/${dumpName}`,
                '_blank' // <- This is what makes it open in a new window.
            );
        }
    };

    if (dumpingInProgress) {
        return window.translate("Database dumping in progress...");
    }

    return (
        <button
            onClick={() => {
                dumpAndDownloadDatabase();
            }}
            className="btn btn-warning"
        >
            <i className="fa fa-download mr-2" aria-hidden="true"></i>
            <small>{window.translate('DOWNLOAD DATABASE')}</small>
        </button>
    );
};

const UploadAndRestoreDatabase = ({ setRestoringInProgress }) => {
    const onChangeHandler = async (e) => {
        try {
            setRestoringInProgress(true);
            await fileUpload('/restoreDatabaseDump', e.target.files[0]);
            setRestoringInProgress(false);
            alert('Database restored. You will be required to login again.');
            logout();
        } catch (err) {
            setRestoringInProgress(false);
            console.warn(err);
            alert(err.message);
        }
    };

    return (
        <div>
            <input
                id="file"
                className="inputfile"
                type="file"
                name="file"
                accept=".backup"
                onChange={onChangeHandler.bind(this)}
            />

            <label className={' btn btn-info'} htmlFor="file">
                <i className="fa fa-upload" aria-hidden="true"></i> {window.translate('RESTORE DATABASE')}
            </label>
        </div>
    );
};

export default branch(
    {
        //do not use baobab here...
        loggedUser: ['authTokenInfo', 'user'],
    },
    DatabaseManagement
);
