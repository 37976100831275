import React from 'react';
import PageTitle from '@components/pageTitle';
import Timeline from '@pages/dashboard/reservationsTimeline';
import { UserInfo,  RoomInfoEditModel } from '@common/modelDefinition';
import { Reservation } from '../resController'
import { translate } from '@data/translations';
import OccupancyOverview from './occupancyOverview';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
//@ts-ignore
import { RouteComponentProps } from 'react-router-dom';

export interface CondoViewDashboardProps {
    loggedUser: UserInfo;
    reservations: Reservation[];
    history: RouteComponentProps['history'];
    rooms: RoomInfoEditModel[];
}

const CondoViewDashboard = (props: CondoViewDashboardProps) => {
    const { loggedUser, reservations, history, rooms } = props;

    if (loggedUser.role !== 'condoUser') {
        return "This view is only for 'condoUser'!";
    }

    const timelineHeight: number = 60 + rooms.length * 41;

    const myReservations: Reservation[] = reservations.filter(
        (r) => r.customerId === loggedUser.customerId && (r.statusEnum === 1 || r.statusEnum === 2)
    );
    return (
        <React.Fragment>
            <PageTitle />

            <Timeline
                selectedRoomsForReservations={[]}
                //showComponents={showComponents}
                availableFromTimestamp={null}
                availableToTimestamp={null}
                history={history}
                reservations={reservations}
                toggleSelectRoomForReservation={() => {
                    return null;
                }}
                groupReservations={false}
                rooms={rooms}
                style={{ height: `${timelineHeight}px` }}
                reservationRoute={'condoReservation'}
            />

            <div className="row mt-3">
                {myReservations.length > 0 ? (
                    <div className="col-md-3">
                        <h5 className="text-primary">{translate('My reservations')}</h5>
                        <small>
                        <ul className="list-group">
                            {myReservations.map((r, index) => {
                                return (
                                    <li
                                        key={index}
                                        className="list-group-item pointer"
                                        onClick={() => {
                                            history.push(`condoReservation/${r.roomInfoId}/${r.id}/edit`);
                                        }}
                                    >
                                        <b>{translate('Check in')}:</b> {moment(r.checkInTimestamp).format('lll')} <br />
                                        <b>{translate('Check out')}:</b> {moment(r.checkOutTimestamp).format('lll')}
                                    </li>
                                );
                            })}
                        </ul>
                        </small>
                    </div>
                ) : null}

                <div className={myReservations.length > 0 ? 'col-md-9' : 'col-md-12'}>
                    <h5 className="text-primary">{translate('Occupancy overview')}</h5>
                   
                    <OccupancyOverview/>
                </div>
            </div>
        </React.Fragment>
    );
};

export default branch(
    {
        rooms: ['model', 'RoomInfo'],
        reservations: ['model', 'Reservation'],
        loggedUser: ['authTokenInfo', 'user'],
    },
    CondoViewDashboard
);
