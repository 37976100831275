import {
    InvoiceModel,
    InvoiceItemsModel,
    PriceOfferIntervalsGroupByReservationId,
    FiscalGeneralSettings,
    InvoiceBaobabModel,
    PosTerminalModel,
    RoomInfoMap,
    InvoiceModelWithInvoiceItems,
} from '@common/modelDefinition';

export enum InvoiceTypeEnum {
    geniue,
    advance,
    proforma,
}

export interface RoomCleaningRequest {
    key: string;
    value: boolean;
}

export interface ReservationTypeBody {
    reservationId: number | null;
    reservationGroupUuid: string | null;
}

export interface ApplyCurrentOffer extends ReservationTypeBody {
    priceOfferIntervals: PriceOfferIntervalsGroupByReservationId;
}

export interface LoadCheckoutInvoice {
    reservationId: number | null;
    reservationGroupUuid: string | null;
    location?: any | null;
}

export interface InvoiceAndCloseReservationProps extends InvoiceBaobabModel {
    history?: any;
    location?: any;
    match?: any;
    translate?: any;
}

export interface CheckoutOptionsProps extends InvoiceAndCloseReservationProps {
    showReclamationNote?: boolean;
    setReclamationReason?: Function;
    systemLocale: string;
    children: any;
    selectedPosTerminalId: number;
    PosTerminals: PosTerminalModel[];
    fiscalGeneralSettings: FiscalGeneralSettings;
    invoiceForStorno?: InvoiceModelWithInvoiceItems;
    roomInfoMap?: RoomInfoMap;
    reservationId: number | null;
    reservationGroupUuid: string | null;
}

export interface PaymentType {
    name: string;
    icon: string;
    value: string;
}

export interface ConfirmationModalProps {
    title?: string;
    callback?: any;
}

export interface ExchangeRates {
    [key: string]: number;
}

export interface GroupedInvoiceItems {
    [key: string]: InvoiceItemsModel[];
}

export interface CurrentOfferProps {
    invoice: InvoiceModel;
    invoiceItems: InvoiceItemsModel[];
    priceOfferIntervals?: PriceOfferIntervalsGroupByReservationId;
    undo?: boolean;
    translate?: any;
    reservationId: number | null;
    reservationGroupUuid: string | null;
}
