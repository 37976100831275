import * as React from 'react';
import { Reservation, ReservationController } from '../resController';
import { translate } from '@data/translations';

interface OpenPriceProps {
    reservation: Reservation;
    isPending?: boolean;
}

const OpenPrice = (props: OpenPriceProps) => {
    const { reservation } = props;
    const [localOpenPrice, setLocalOpenPrice] = React.useState<number | null>();
    React.useEffect(() => {
        if (reservation) {
            const { openPrice } = reservation;
            setLocalOpenPrice(openPrice);
        }
    }, [reservation]);
    const ctrl = new ReservationController();
    if (!reservation) {
        return null;
    }
    return (
        <div className="input-group">
            <input
                disabled={reservation.canceledReservationInvoicingStatus === 2}
                className={
                    reservation.canceledReservationInvoicingStatus !== 1 ? 'form-control input-lg' : 'form-control form-control-sm'
                }
                placeholder={translate(
                    reservation.canceledReservationInvoicingStatus === 1 ? 'Cancellation fee' : 'Room price'
                )}
                autoComplete="off"
                type="number"
                value={localOpenPrice ? Number(Number(localOpenPrice).toFixed(2)) : ''}
                onChange={(e: any) => {
                    if (!isNaN(Number(e.target.value))) {
                        setLocalOpenPrice(e.target.value);
                    }
                }}
                onKeyPress={(event) => {
                    if (event.key === 'Enter') {
                        const res = { ...reservation };
                        if (localOpenPrice !== undefined) {
                            res.openPrice = localOpenPrice;
                            ctrl.updateActiveReservation(res);
                        }
                    }
                }}
            />
        </div>
    );
};

export default OpenPrice;
