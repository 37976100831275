import React, { useEffect, useState } from 'react';
import rest from '../../data/restWrapper';
import { setModalContent } from '@components/modal';
import Delete from '../../components/confirmModal';
import PageTitle from '../../components/pageTitle';
import { isFeatureEnabled } from '../../components/FeatureFlag';
import { branch } from 'baobab-react/higher-order';
import { RoleName } from '@pages/rolesManagement/enums';
import InlineModal from '@components/modalInline';

const UserManagement = (props) => {
    const { roles } = props;
    const [allApiKeys, setApiKeys] = useState([]);
    const [dataChange, setDataChange] = useState(false);
    const [role, setRole] = useState(null);
    const [modalShow, setModalShow] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            const _allApiKeys = await rest('/getApiKeys', 'GET');
            setApiKeys(_allApiKeys);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [dataChange]);

    useEffect(() => {
        const loadData = () => {
            const roleKey = roles.length > 0 ? roles[0].key : null;
            if (roleKey && !role) {
                setRole(roleKey);
            }
        };
        loadData();
    }, [roles, role]);

    const generateToken = async () => {
        const newToken = await rest('/generateApiToken', 'POST', { roleKey: role });
        const tokens = [...allApiKeys];
        tokens.push(newToken);
        setApiKeys(tokens);
        setDataChange(!dataChange);
    };

    const deleteItem = async (encryptedToken) => {
        try {
            await rest('/deleteApiKey', 'POST', { encryptedToken: encryptedToken });
            let statesrcApiKeys = [...allApiKeys];
            statesrcApiKeys = statesrcApiKeys.filter((apiKey) => apiKey.encryptedToken !== encryptedToken);
            setApiKeys(statesrcApiKeys);
            setDataChange(!dataChange);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = (token) => {
        setModalContent(
            <Delete actionForYes={deleteItem.bind(this, token)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const closeInlineModal = () => {
        setModalShow(false);
    };

    if (!isFeatureEnabled('apiKeys')) {
        return window.translate('API keys access disabled');
    }
    const apiKeysToShow = [];

    allApiKeys.forEach((apiKey, key) => {
        apiKeysToShow.push(
            <tr className={apiKey.generatedOnLogin === 1 ? 'table-warning' : null} key={key}>
                <td style={{ wordBreak: 'break-all', userSelect: "text" }}>
                    {apiKey.encryptedToken}{' '}
                    {apiKey.generatedOnLogin === 1 ? (
                        <span className="text-danger">{window.translate("(TEMPORARY KEY! DON'T USE!)")}</span>
                    ) : null}
                </td>
                <td>{new Date(apiKey.validTo).toLocaleDateString()}</td>
                <td style={{ userSelect: "text" }}>
                    {apiKey.email}
                    <br /> ({apiKey.role})
                </td>
                <td className="text-center">
                    <i
                        onClick={showDeleteModal.bind(this, apiKey.encryptedToken)}
                        className="fa fa-trash pointer text-danger"
                    ></i>
                </td>
            </tr>
        );
    });

    return (
        <div className="container-fluid ">
            <InlineModal show={modalShow}>
                <SelectRoleModal setRole={setRole} roles={roles} generateToken={generateToken} closeInlineModal={closeInlineModal}/>
            </InlineModal>
            <PageTitle />
            <button
                onClick={() => {
                    props.history.goBack();
                }}
                className="btn btn-sm btn-secondary "
            >
                {window.translate('GO BACK')}
            </button>
            <button
                onClick={() => {
                    setModalShow(true);
                }}
                className="btn btn-sm btn-primary  ml-2 "
            >
                {window.translate('Generate token')}
            </button>
            <table className="table table-striped mt-2 ">
                <thead className="bg-white text-secondary ">
                    <tr>
                        <th>{window.translate('Token')}</th>
                        <th>{window.translate('Valid to')}</th>
                        <th>{window.translate('User uuid')}</th>
                        <th>{window.translate('Delete')}</th>
                    </tr>
                </thead>
                <tbody className="bg-white ">{apiKeysToShow}</tbody>
            </table>
        </div>
    );
};

const SelectRoleModal = (props) => {
    const { setRole, roles, generateToken, closeInlineModal } = props;
    return (
        <div className="container-fluid">
            <div className="">
                <h5>Role</h5>
            </div>
            <hr />
            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {window.translate('Select role')}
                </label>
                <div className="col-md-8">
                    <select
                        onChange={(e) => {
                            setRole(e.target.value);
                        }}
                        name="role"
                        className="form-control form-control-sm"
                    >
                        {roles.map((r) => {
                            return (
                                <option key={r.key} value={r.key}>
                                    {RoleName[r.key] ? RoleName[r.key] : r.key}
                                </option>
                            );
                        })}
                    </select>
                </div>
            </div>

            <div className="text-center mt-3">
                <div
                    onClick={async () => {
                        await generateToken();
                        closeInlineModal();
                    }}
                    className="btn btn-sm btn-outline-primary"
                >
                    {window.translate('Generate')}
                </div>
                <div
                    onClick={() => {
                        closeInlineModal();
                    }}
                    className="btn btn-sm btn-outline-secondary ml-2"
                >
                    {window.translate('Cancel')}
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        roles: ['model', 'Role'],
    },
    UserManagement
);
