import React from 'react';
import numbro from 'numbro';
//@ts-ignore
import _ from 'lodash';
import { InvoiceItemsModel, InvoicePresenter } from '@common/modelDefinition';
import moment from 'moment';
import 'moment/min/locales.min';
interface GroupedInvoiceItems {
    [key: string]: InvoiceItemsModel[];
}

//ako budemo dodavali popust na pojedinačne stavke ovdje, onda ćeš samo gledati u status invoiceCovera NIŠTA DRUGOOOOO!!!!
const InvoiceHeader = (props: InvoicePresenter) => {
    const { invoiceItems, invoice, translate } = props;
    const { exchangeRates, currency, locale } = invoice;

    const exchangeRatesWhenInvoiceIssued = exchangeRates ? JSON.parse(exchangeRates) : {};
    const currencyExchangeRate: number = exchangeRatesWhenInvoiceIssued[currency]
        ? Number(exchangeRatesWhenInvoiceIssued[currency])
        : 1;

    const groupedInvoiceItems: GroupedInvoiceItems = _.groupBy(invoiceItems, 'group');
    const itemsWithTaxPercentage2: InvoiceItemsModel[] = invoiceItems.filter((i) => Number(i.taxPercentage2) > 0);


    if (Object.keys(groupedInvoiceItems).length === 0) {
        if (!invoice.id) {
            return (
                <React.Fragment>
                    <InvoiceItemsHeader {...props} />
                    <ul className="list-group list-group-flush mt-3 border-top">
                        <li className="list-group-item p-3"></li>
                        <li className="list-group-item p-3"></li>
                        <li className="list-group-item p-3"></li>
                    </ul>
                </React.Fragment>
            );
        } else {
            return null;
        }
    }

    return (
        <div className="">
            <InvoiceItemsHeader {...props} />

            {Object.keys(groupedInvoiceItems).map((group: string, key: number) => {
                return (
                    <ul key={key} className="list-group list-group-flush mt-0 border-bottom border-top">
                        {group !== 'null' && (
                            <li className="list-group-item p-1 bg-light">
                                <small>
                                    <div className="text-left">
                                        {group.includes('Room')
                                            ? group.split(':').map((text: string) => {
                                                  if (text === 'Room') {
                                                      return translate(text);
                                                  } else {
                                                      return `: ${text}`;
                                                  }
                                              })
                                            : group}
                                    </div>
                                </small>
                            </li>
                        )}
                        {groupedInvoiceItems[group].map((i, index) => {
                            const taxgroup =
                                Number(i.taxPercentage1) === 0 && Number(i.taxPercentage2) === 0
                                    ? 0
                                    : Number(i.taxPercentage1) === 0 && Number(i.taxPercentage2) > 0
                                    ? Number(i.taxPercentage2)
                                    : Number(i.taxPercentage1) > 0 && Number(i.taxPercentage2) === 0
                                    ? Number(i.taxPercentage1)
                                    : `Preračunata stopa`;
                            return (
                                <li key={index} className="list-group-item p-1 ">
                                    <small>
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-4 col-sm-2">
                                                {i.type === 'tax' || i.type === 'accommodation'
                                                    ? i.itemName.split(':').map((text: string, index: number) => {
                                                          if (i.type === 'accommodation') {
                                                              if (index === 0) {
                                                                  return translate(text);
                                                              } else if (index === 1) {
                                                                  return `: ${moment(text)
                                                                      .locale(locale)
                                                                      .format('ll')}`;
                                                              } else if (index === 2) {
                                                                  return ` - ${moment(text)
                                                                      .locale(locale)
                                                                      .format('ll')}`;
                                                              } else {
                                                                  return null;
                                                              }
                                                          } else if (i.type === 'tax') {
                                                              if (index === 0) {
                                                                  return translate(text);
                                                              } else if (index === 1) {
                                                                  return `: (-${text}%)`;
                                                              } else {
                                                                  return null;
                                                              }
                                                          } else {
                                                              return null;
                                                          }
                                                      })
                                                    : i.itemName}
                                            </div>
                                            <div className="col-4 col-sm-1">
                                                {numbro(i.quantity).format({ mantissa: 2, trimMantissa: true })}
                                            </div>

                                            <div className="col-4 col-sm-1 text-center">
                                                {i.nettoUnitPrice !== null
                                                    ? numbro(i.nettoUnitPrice * Number(currencyExchangeRate)).format({
                                                          mantissa: 2,
                                                          trimMantissa: false,
                                                          thousandSeparated: true,
                                                          //negative: 'parenthesis',
                                                      })
                                                    : 0}
                                            </div>
                                            <div className="col-4 col-sm-1 text-center">
                                                {i.bruttoUnitPrice !== null
                                                    ? numbro(i.bruttoUnitPrice * Number(currencyExchangeRate)).format({
                                                          mantissa: 2,
                                                          trimMantissa: false,
                                                          thousandSeparated: true,
                                                          // negative: 'parenthesis',
                                                      })
                                                    : 0}
                                            </div>
                                            {/*trenutnno podržavamo samo jednu poreznu grupu, ali ako bude trebalo više, spremni smo*/}
                                            <div className="col-4 col-sm-1 text-center">
                                                {Number(i.taxPercentage1) > 0 && Number(i.taxPercentage2) > 0
                                                    ? taxgroup
                                                    : numbro(taxgroup).format({
                                                          mantissa: 2,
                                                          trimMantissa: true,
                                                      })}
                                                {Number(i.taxPercentage1) > 0 && Number(i.taxPercentage2) > 0
                                                    ? ''
                                                    : '%'}
                                            </div>
                                            <div className="col-4 col-sm-1 text-right">
                                                {i.tax1ValueWithDiscount !== null
                                                    ? numbro(
                                                          i.tax1ValueWithDiscount * Number(currencyExchangeRate)
                                                      ).format({
                                                          mantissa: 2,
                                                          trimMantissa: false,
                                                          thousandSeparated: true,
                                                          //  negative: 'parenthesis',
                                                      })
                                                    : 0}
                                            </div>

                                            {itemsWithTaxPercentage2.length > 0 ? (
                                                <div className="col-4 col-sm-1 text-right">
                                                    {i.tax2ValueWithDiscount !== null
                                                        ? numbro(
                                                              i.tax2ValueWithDiscount * Number(currencyExchangeRate)
                                                          ).format({
                                                              mantissa: 2,
                                                              trimMantissa: false,
                                                              thousandSeparated: true,
                                                              //  negative: 'parenthesis',
                                                          })
                                                        : 0}
                                                </div>
                                            ) : null}
                                            <div className="col-4 col-sm-1 text-right">
                                                {numbro(i.discountPercentage ? i.discountPercentage : 0).format({
                                                    mantissa: 2,
                                                    trimMantissa: true,
                                                })}
                                                %
                                            </div>
                                            <div className="col-4 col-sm-2 text-right">
                                                {i.bruttoValueWithDiscount !== null
                                                    ? numbro(
                                                          i.bruttoValueWithDiscount * Number(currencyExchangeRate)
                                                      ).format({
                                                          mantissa: 2,
                                                          trimMantissa: false,
                                                          thousandSeparated: true,
                                                      })
                                                    : 0}
                                            </div>
                                        </div>
                                    </small>
                                </li>
                            );
                        })}
                    </ul>
                );
            })}
        </div>
    );
};

const InvoiceItemsHeader = (props: InvoicePresenter) => {
    const { translate, invoiceItems } = props;

    const itemsWithTaxPercentage2: InvoiceItemsModel[] = invoiceItems.filter((i) => Number(i.taxPercentage2) > 0);
    return (
        <div className="">
            <ul className="list-group list-group-flush mt-3 border-top">
                <li className="list-group-item p-0">
                    <div className="row d-flex justify-content-between">
                        <div className="col-4 col-sm-2">
                            <b> {translate('Name')} </b>
                        </div>
                        <div className="col-4 col-sm-1">
                            <b> {translate('Quantity')} </b>
                        </div>
                        <div className="col-4 col-sm-1 text-center">
                            <b> {translate('Unit price')} </b>
                        </div>
                        <div className="col-4 col-sm-1 text-center">
                            <b> {translate('Retail price')} </b>
                        </div>
                        <div className="col-4 col-sm-1 text-center">
                            <b> {translate('Tax rate')} </b>
                        </div>
                        <div className="col-4 col-sm-1 text-right">
                            <b> {translate('Tax')} </b>
                        </div>
                        {itemsWithTaxPercentage2.length > 0 ? (
                            <div className="col-4 col-sm-1 text-right">
                                <b> {translate('Tax2')}</b>
                            </div>
                        ) : null}
                        <div className="col-4 col-sm-1 text-right">
                            <b> {translate('Discount')} </b>
                        </div>
                        <div className="col-4 col-sm-2 text-right">
                            <b> {translate('Amount')} </b>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    );
};

export default InvoiceHeader;
