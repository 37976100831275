import React from 'react';
import PageTitle from '../../components/pageTitle';
import { useBranch } from 'baobab-react/hooks';
import { Tags } from '../../components/tags/';
import rest from '../../data/restWrapper';
import pubsub from '../../data/pubsub';
import Select from 'react-select/creatable';

const RoomsAndPriceLists = (props) => {
    const { acp, roomInfo, roomTags } = useBranch({
        acp: ['monkeys', 'filteredAccommodationPriceLists'],
        roomInfo: ['model', 'RoomInfo'],
        roomTags: ['monkeys', 'roomTags'],
    });

    const [options, setOptions] = React.useState([]);
    const [values, setValue] = React.useState('');
    const [priceLists, setPriceLists] = React.useState([]);
    const [filterList, setFilterList] = React.useState(false);
    const [optionsForFilter, setOptionsForFilter] = React.useState([]);
    const [valuesForFilter, setValuesForFilter] = React.useState([]);

    React.useEffect(() => {
        const loadData = async () => {
            if (acp.length > 0 && roomInfo.length > 0) {
                let _options = [],
                    optForFilter = [];
                const models = [acp, roomInfo];
                models.forEach((model) => {
                    model.forEach((m) => {
                        if (m.tags !== null) {
                            m.tags.split(';').forEach((t) => {
                                if (!_options.includes(t)) {
                                    _options.push(t);
                                    let obj = {
                                        label: t,
                                        value: t,
                                    };
                                    optForFilter.push(obj);
                                }
                            });
                        }
                    });
                });
                _options.sort((a, b) => (a > b ? 1 : -1));
                optForFilter.sort((a, b) => (a.label > b.label ? 1 : -1));

                const _priceLists = acp.filter((a) => {
                    if ((a.isDeleted === 0 || a.isDeleted === false) && a.parentId === null) {
                        return a;
                    }
                    return null;
                });
                setPriceLists(_priceLists);
                setOptions(_options);
                setOptionsForFilter(optForFilter);
                setFilterList(false);
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });
    }, [acp, roomInfo]);

    const onDragStart = (e, tag) => {
        e.dataTransfer.setData('tag', tag);
    };

    const onDrop = async (e, mod, modelName) => {
        let t = e.dataTransfer.getData('tag');
        let model = { ...mod };
        const tags = model.tags !== null ? model.tags.split(';') : [];
        if (!tags.includes(t)) {
            model.tags = model.tags !== null && model.tags !== '' ? model.tags.concat(';' + t) : t;
            try {
                if (modelName === 'RoomInfo') {
                    await rest('/RoomInfo/' + model.id, 'PUT', model);
                } else if (modelName === 'Acc') {
                    await rest('/AccommodationPriceList/' + model.id, 'PUT', model);
                }
                pubsub.emit('toastMessage', window.translate('Tag added'), 'info');
            } catch (err) {
                console.log('err', err);
            }
        } else {
            pubsub.emit('toastMessage', window.translate('Tag already exist!'), 'error');
        }
        setFilterList(false);
    };

    const onDragOver = (e) => {
        e.preventDefault();
    };

    const deleteTag = async (model, tag, modelName) => {
        let filterModel = { ...model };
        filterModel.tags = filterModel.tags.split(';').filter((t) => t !== tag);
        filterModel.tags = filterModel.tags && filterModel.tags.length > 0 ? filterModel.tags.join(';') : null;
        try {
            if (modelName === 'RoomInfo') {
                await rest('/RoomInfo/' + filterModel.id, 'PUT', filterModel);
            } else if (modelName === 'Acc') {
                await rest('/AccommodationPriceList/' + filterModel.id, 'PUT', filterModel);
            }
            pubsub.emit('toastMessage', window.translate('Tag deleted'), 'info');
        } catch (err) {
            console.log('err', err);
        }
        setFilterList(false);
    };

    const listProps = {
        values,
        onDragOver,
        onDrop,
        deleteTag,
        filterList,
        valuesForFilter,
    };

    return (
        <div style={{ height: '96%', overflow: 'hidden' }}>
            <div style={{ height: '25%'}}>
                <PageTitle />
                <div className="row justify-content-center">
                    <div className="col-12">
                        <button
                            onClick={() => {
                                props.history.goBack();
                            }}
                            className="btn btn-sm btn-secondary mr-2"
                        >
                            {window.translate('GO BACK')}
                        </button>
                    </div>
                </div>

                <div className="row mt-2" style={{ position: 'relative' }}>
                    <Tags
                        classNameForTags="col-12"
                        modelTags={options}
                        handleChange={(tag) => {                        
                            setValue(tag);
                            setValuesForFilter([]);
                            setFilterList(false);
                        }}
                        handleClick={(tag) => {
                            if (values.indexOf(tag) === -1) {
                                setValue(tag);
                            } else {
                                setValue('');
                            }
                        }}
                        selectedTag={values}
                        tags={roomTags}
                        onDragStart={onDragStart}
                        placeholderText={window.translate("Add tag...")}
                    />

                    <Select
                        isMulti
                        className="col-12 mb-0 pr-3 pb-0 mt-auto"
                        style={{ position: 'absolute' }}
                        placeholder={window.translate('Filter rooms and pricelists by tags')}
                        options={optionsForFilter}
                        onChange={(item) => {
                            setValuesForFilter(item);
                            setValue('');
                            if (item === null) {
                                setFilterList(false);
                            } else {
                                setFilterList(true);
                            }
                        }}
                        value={valuesForFilter}
                    ></Select>
                    
                </div>
            </div>

            <div style={{ height: '75%', overflow: 'hidden' }}>
                <div style={{ height: '100%', overflowY: 'hidden' }} className="row justify-content-center mt-4">
                    <div style={{height:"100%", overflowY:"auto", overflowX:"hidden", paddingBottom:"50px"}} className="col-6">  
                        {window.translate('ROOMS')}
                       
                        <ul className="list-group mx-3">
                            <List listProps={listProps} model={roomInfo} modelName={'RoomInfo'} />
                        </ul>
                    </div>
                    <div  style={{height:"100%", overflowY:"auto", overflowX:"hidden", paddingBottom:"50px"}} className="col-6">
                        {window.translate('PRICELISTS')}
                        <ul className="list-group mx-3">
                            <List listProps={listProps} model={priceLists} modelName={'Acc'} />
                        </ul>
                    </div>
                </div>
            </div>
        </div>
    );
};

const List = ({ listProps, model, modelName }) => {
    const { values, onDragOver, onDrop, deleteTag, filterList, valuesForFilter } = listProps;
    const indexModel = [];

    if (values.length > 0 && filterList === false) {
        values.split(';').forEach((v) => {
            model.forEach((m, index) => {
                if (m.tags !== null) {
                    const tags = m.tags.split(';');
                    if (tags.includes(v)) {
                        indexModel.push(index);
                    }
                }
            });
        });
    } else if (valuesForFilter && valuesForFilter.length > 0 && filterList === true) {
        valuesForFilter.forEach((v) => {
            model = model.filter((m) => {
                if (m.tags !== null) {
                    const tags = m.tags.split(';');
                    if (tags.includes(v.label)) {
                        return m;
                    }
                }
                return null;
            });
        });
    }

    const list = model.map((m, key) => {
        let tags = m.tags ? m.tags.split(';') : [];

        let beds = m.bedCount ? <span>{m.bedCount}x <i title={window.translate('Bed count')} className="fa fa-bed mr-1" /></span> : null;
        let spareBeds = m.spareBedCount ? <span>{m.spareBedCount}x <i title={window.translate('Spare beds')} className="fa fa-bed mr-1 text-info" /> </span> : null;
        // for (let i = 0; i < m.bedCount; i++) {
        //     beds.push(<i key={i} title={window.translate('Bed count')} className="fa fa-bed mr-1" />);
        // }
    
        // for (let i = 0; i < m.spareBedCount; i++) {
        //     spareBeds.push(<i key={i} title={window.translate('Spare beds')} className="fa fa-bed mr-1 text-info" />);
        // }

        tags.sort((a, b) => (a > b ? 1 : -1));
        tags = tags.map((tt, index) => {
            return (
                <span key={tt.id + m + index} className="badge badge-primary mr-1">
                    {tt}
                    <i
                        className="ml-3 fa fa-times text-dark pointer"
                        onClick={(e) => {
                            e.stopPropagation();
                            deleteTag(m, tt, modelName);
                        }}
                    />
                </span>
            );
        });

        return (
            <li
                className={'pointer px-1 py-1 row rounded border-bottom mb-1'}
                style={indexModel.includes(key) ? { backgroundColor: '#E0E0E0' } : { backgroundColor: 'white' }}
                key={key}
                onDrop={(e) => onDrop(e, m, modelName)}
                onDragOver={(e) => onDragOver(e)}
            >
                <div className="col-3">
                    <small>
                        <b>{m.name}</b>
                    </small>
                    <small className="ml-3">{beds} {spareBeds}</small>
                </div>
                <div className="col-9">{tags}</div>
            </li>
        );
    });

    return list;
};

export default RoomsAndPriceLists;
