import React, { Component } from 'react';
import baobab from '../../data/state';

export default class Delete extends Component {
    yesHandler() {
        if (this.props.actionForYes) {
            this.props.actionForYes();
            baobab.root.select(['modalData', 'display']).set(false);
        }
    }

    noHandler() {
        if (this.props.actionForNo) {
            this.props.actionForNo();
        }
        baobab.root.select(['modalData', 'display']).set(false);
    }

    render() {
        let buttons = [];
        if (this.props.buttons) {
            buttons = this.props.buttons;
        } else {
            buttons = [
                <div key={1} onClick={this.yesHandler.bind(this)} className="btn btn-primary mx-1 mt-2 pointer">
                    {window.translate("Yes")}
                </div>,
                <div key={2} onClick={this.noHandler.bind(this)} className="btn btn-secondary mx-1 mt-2 pointer">
                    {window.translate("No")}
                </div>,
            ];
        }

        return (
            <div>
                <div className="text-center ">
                    {this.props.content ? window.translate(this.props.content) : <div>{window.translate("Are you sure you want to delete?")}</div>}
                    <div>{buttons}</div>
                </div>
            </div>
        );
    }
}

//required props
//content nije required
//showOnlyCloseButton nije required
