import { rest } from './restWrapper';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';
import baobabTree from "@data/state"

const getReportData = async (reportName, params = {})=>{
	startGlobalLoading()
	const result=await rest(`/report/${reportName}?params=${Buffer.from(JSON.stringify(params)).toString('base64')}`)	
	stopGlobalLoading()
	return result
}

const getExcelExport = async (reportName, params={}) => {
	const opt = {
		report: reportName,
		...params
	}

	let stringifiedOptions = JSON.stringify({...opt})

	stringifiedOptions = stringifiedOptions.replaceAll("Ž", "Z")
	stringifiedOptions = stringifiedOptions.replaceAll("ž", "z")
	stringifiedOptions = stringifiedOptions.replaceAll("Č", "C")
	stringifiedOptions = stringifiedOptions.replaceAll("č", "c")
	stringifiedOptions = stringifiedOptions.replaceAll("Đ", "D")
	stringifiedOptions = stringifiedOptions.replaceAll("đ", "d")
	stringifiedOptions = stringifiedOptions.replaceAll("Š", "S")
	stringifiedOptions = stringifiedOptions.replaceAll("š", "s")

	const uptUTF8= Buffer.from(stringifiedOptions).toString("base64")
	// const bufferObj = Buffer.from(uptUTF8, "base64");

	const token = baobabTree.root.select(['authTokenInfo', 'token']).get();   
	if (process.env.NODE_ENV === 'development') {
		window.open(`http://localhost:7777/getExcelFile/${reportName}
			?params=${uptUTF8}
			&token=${Buffer.from(token).toString('base64')}`);
    } else {
        window.open(`/getExcelFile/${reportName}?params=${uptUTF8}&token=${Buffer.from(token).toString('base64')}`);
    }
}


export {getExcelExport, getReportData}