import React from 'react';
import _ from 'lodash';
import { setModalContent } from '@components/modal';
import PageTitle from '../../../components/pageTitle';
import { branch } from 'baobab-react/higher-order';
import baobabTree from '../../../data/state';

import Form2 from './form2';
import Form from './form';

import StackedTimeline from './stackedTimeline';

import repeatRulesEnum from './enums';
import moment from 'moment/min/moment-with-locales';
import rest from '../../../data/restWrapper';
import { toast } from 'react-toastify';
import baobab from '@data/state';

const AccommodationPriceListStack = (props) => {
    React.useEffect(() => {}, [props.accommodationPriceLists.length]);

    const deleteItem = async (itemId) => {
        if (!_.isUndefined(itemId)) {
            try {
                await rest('/AccommodationPriceList/' + itemId, 'PUT', {
                    isDeleted: true,
                });
                toast(window.translate("Accomodation price list deleted!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
                const _accommodationPriceLists = props.accommodationPriceLists.filter(w=>w.id!==itemId)
                baobab.root.select(['model', 'AccommodationPriceList']).set(_accommodationPriceLists);
            } catch (err) {
                //TODO: tostificiraj ovo
                console.log(err);
            }
        }
    };

    const showFormModal = (accommodationPriceListId) => {
        let parentId = Number(props.match.params.accommodationPriceListId);
        if (accommodationPriceListId === null) {
            //adding new
            setModalContent(
                <Form2 parentId={parentId} accommodationPriceListId={null} deleteItem={deleteItem.bind(this)} />,
                window.translate('New price list'),
                false,
                'modal-lg'
            );
        } else {
            let priceList = _.find(props.accommodationPriceLists, (p) => p.id === accommodationPriceListId);
            if (priceList.weight === 0) {
                setModalContent(
                    <Form accommodationPriceListId={accommodationPriceListId} />,
                    window.translate('Update baseline price list'),
                    false,
                    'modal-lg'
                );
            } else {
                setModalContent(
                    <Form2
                        parentId={parentId}
                        accommodationPriceListId={accommodationPriceListId}
                        deleteItem={deleteItem}
                    />,
                    window.translate('Update price list'),
                    false,
                    'modal-lg'
                );
            }
        }
    }

    const handleTimelineSpanChange = (e) => {
        baobabTree.root
            .select(['uiStates', 'accommodationPriceListStack', 'startFromYear'])
            .set(Number(e.target.value));
    }

    const getTooltipFromPriceList = (pl, listMode = false, defaultCurrency = 'EUR', clickHandler = () => {}) => {
        let accomodationPriceRules = pl.accomodationPriceRules ? JSON.parse(pl.accomodationPriceRules) : [];
        let repeatRules = pl.repeatRules ? JSON.parse(pl.repeatRules) : {};
        let repeatRulesKeys = repeatRules ? Object.keys(repeatRules) : [];
        let personCount = 0;
        accomodationPriceRules = _.map(accomodationPriceRules, (price) => {
            personCount++;
            return (
                <span key={personCount}>
                    {' '}
                    <b>{personCount}&#8594;</b> {price} {defaultCurrency};{' '}
                </span>
            );
        });
        let validInfo = '';
        if (pl.weight === 0 || pl.infinite) {
            validInfo = window.translate('Always valid.');
        } else {
            validInfo = `Valid from ${moment(pl.validFrom).format('L')}, to ${moment(pl.validTo).format('L')}.`;
        }
        let repeatInfo = window.translate('No repeat rules');
        if (_.includes(repeatRulesKeys, repeatRulesEnum.weeklyOnDays)) {
            repeatInfo = `Repeating every week on days: ${repeatRules.weeklyOnDays.join(', ')}.`;
        } else if (_.includes(repeatRulesKeys, repeatRulesEnum.monthly)) {
            repeatInfo = `Repeating every month on dates: ${repeatRules.monthly.join(', ')}.`;
        } else if (_.includes(repeatRulesKeys, repeatRulesEnum.yearly)) {
            repeatInfo = `Repeating every year between ${moment(repeatRules.yearly.from).format('L')} and ${moment(
                repeatRules.yearly.to
            ).format('L')}.`;
        }
        return (
            <div className="row" onClick={clickHandler}>
                <div className="col-6 col-md-3">
                    {listMode ? (
                        <h5>
                            w({pl.weight}) &#8594; {pl.name}
                        </h5>
                    ) : (
                        pl.name
                    )}
                    {listMode ? null : <hr className="m-0 p-0 mt-1 mb-1" />}
                    <b>{window.translate('Price per person')}</b> <br />
                    {accomodationPriceRules}
                </div>
                <div className="col-6">
                    <div>{repeatInfo}</div>
                    <div>{validInfo}</div>
                </div>

                <hr className="m-0 p-0 mt-1 mb-1" />
            </div>
        );
    }

        const accommodationPriceListId = Number(props.match.params.accommodationPriceListId);
        const priceListStack = _.filter(
            props.accommodationPriceLists,
            (apl) => apl.parentId === accommodationPriceListId || apl.id === accommodationPriceListId
        );

        const priceListOrderedByWeight = _.sortBy(priceListStack, ['weight']);


        return (
            <div>
                <PageTitle />
                <div className=" ">
                    <div className="form-inline">
                        <button
                            onClick={() => {
                                props.history.push('/priceLists/accommodation');
                            }}
                            className="btn btn-sm btn-secondary mr-2"
                        >
                            {window.translate('GO BACK')}
                        </button>
                        <button onClick={showFormModal.bind(this, null)} className="btn btn-sm btn-primary mr-2">
                            {window.translate('ADD NEW')}
                        </button>
                        <select
                            value={props.startFromYear}
                            onChange={handleTimelineSpanChange.bind(this)}
                            style={{ width: '80px' }}
                            className="form-control form-control-sm"
                        >
                            <option value={new Date().getFullYear()}>{new Date().getFullYear()}</option>
                            <option value={new Date().getFullYear() + 1}>{new Date().getFullYear() + 1}</option>
                        </select>
                    </div>
                </div>

                <div className="mt-2">
                    <StackedTimeline
                        getTooltipFromPriceList={getTooltipFromPriceList}
                        {...props}
                        showFormModal={showFormModal}
                        priceListStack={priceListStack}
                    />
                </div>

                <div className="mt-2">
                    <h4>{window.translate('Accommodation price list')}</h4>

                    <ul className="list-group list-group-flush">
                        {_.map(priceListOrderedByWeight, (pl) => {
                            return (
                                <li key={pl.id} className="list-group-item list-group-item-action pointer">
                                    <small>
                                        {getTooltipFromPriceList(pl, true, props.defaultCurrency, () => {
                                            showFormModal(pl.id);
                                        })}
                                    </small>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        );
};
export default branch(
    {
        defaultCurrency: ['defaultCurrency'],
        accommodationPriceLists: ['model', 'AccommodationPriceList'],
        timelineYearsCount: ['uiStates', 'accommodationPriceListStack', 'timelineYearsCount'],
        startFromYear: ['uiStates', 'accommodationPriceListStack', 'startFromYear'],
    },
    AccommodationPriceListStack
);