import * as React from 'react';
import { ReservationController, ValidationParams } from '../resController';
import { translate } from '@data/translations';

const InvalidIcon = () => <i className="fa fa-question-circle text-danger" />;

interface ReservationValidationMessageListProps {
    children: any;
    validationParams: ValidationParams;
}

function ReservationValidationMessageList(props: ReservationValidationMessageListProps) {
    const { children, validationParams } = props;
    const ctrl = new ReservationController();
    const fullValidationReport = ctrl.getFullValidationReport(validationParams);


    const { isFullyValid, validationInfo } = fullValidationReport;
    // const { /* overlaps */ /*validationInfo*/ } = activeReservationValidationReport; //otkomentiraj ako želiš da prikazuje poruke od odabrane rezervacije

    if (isFullyValid) {
        return <React.Fragment>{children}</React.Fragment>;
    } else if (validationInfo) {
        const { messages } = validationInfo;

        if (messages.length > 0) {
            return (
                <ul className="list-group">
                    {messages.map((m, index) => {
                        return (
                            <li className="list-group-item" key={index}>
                                <InvalidIcon /> &ensp; {m}
                            </li>
                        );
                    })}
                </ul>
            );
        } else {
            return <div className="text-center">{translate('Action not available due to validation errors')}</div>;
        }
    } else {
        return <div className="text-center">{translate('Action not available due to validation errors')}</div>;
    }
}

export default ReservationValidationMessageList;
