import React, { useEffect, useState } from 'react';
import { branch } from 'baobab-react/higher-order';
import rest from '@data/restWrapper';
import { saveModelData } from '@data/actions/modelActions';
import moment from 'moment/min/moment-with-locales';
import { setModalContent } from '@components/modal';
import DeleteDialog from '@components/confirmModal';
import { deleteModelData } from '@data/actions/modelActions';
import PageTitle from '@components/pageTitle';
import FormHeader from '@components/formHeader';

const InlineWLIForm = (props) => {
    const [code, setCode] = useState('');
    const [name, setName] = useState('');
    const [validationMessage, setValidationMessage] = useState(null);

    useEffect(() => {
        const load = async () => {
            if (props.whiteListItem) {
                setName(props.whiteListItem.name);
                setCode(props.whiteListItem.code);
                setValidationMessage(null);
            }
        };

        load().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const submit = (e) => {
        if (e.preventDefault) {
            e.preventDefault();
        }
        if (name === '' || code === '') {
            setValidationMessage(window.translate('Please, enter all form values!'));
        } else {
            let payload = {
                whiteListId: props.whiteListId,
                name: name,
                code: code,
                enabled: true,
            };
            if (props.whiteListItem) {
                payload.id = props.whiteListItem.id;
            }
            saveModelData('WhiteListItem', payload)
                .then(() => {
                    setName('');
                    setCode('');
                    setValidationMessage(null);
                    if (props.onSubmitSuccess) {
                        props.onSubmitSuccess();
                    }
                })
                .catch((err) => {
                    console.warn(err);
                });
        }
    };
    const cancel = () => {
        if (props.onCancel) {
            props.onCancel();
        }
    };

    let classNameOnAddOrUpdate = 'btn btn-sm btn-outline-';
    if (props.whiteListItem) {
        classNameOnAddOrUpdate = classNameOnAddOrUpdate + 'primary';
    } else {
        classNameOnAddOrUpdate = classNameOnAddOrUpdate + 'secondary';
    }

    return (
        <form onSubmit={submit.bind(this)}>
            {validationMessage ? (
                <div className="alert alert-danger" role="alert">
                    {' '}
                    {validationMessage}{' '}
                </div>
            ) : null}

            <div className="row mt-2 align-items-center">
                <div className="col-md-4">
                    <input
                        className="bg-white form-control"
                        value={code}
                        onChange={(e) => setCode(e.target.value)}
                        name="code"
                        placeholder={window.translate('ADD CARD CODE')}
                        autoComplete="off"
                        type="number"
                    />
                </div>
                <div className="col-md-4">
                    <input
                        className="bg-white form-control"
                        value={name}
                        onChange={(e) => setName(e.target.value)}
                        name="name"
                        placeholder={window.translate('CARD HOLDER NAME')}
                        autoComplete="off"
                        type="text"
                    />
                </div>
                <div className="col-md-4">
                    <button onClick={submit.bind(this)} className={classNameOnAddOrUpdate}>
                        {props.whiteListItem ? window.translate('Update') : window.translate('ADD')}
                    </button>
                    {props.whiteListItem ? (
                        <button onClick={cancel.bind(this)} className="btn btn-sm btn-outline-dark ml-1">
                            {window.translate('ODUSTANI')}
                        </button>
                    ) : null}
                </div>
            </div>
        </form>
    );
};

const WhiteListItems = (props) => {
    const [whiteListItems, setWhiteListItems] = useState([]);
    const [editingItem, setEditingItem] = useState(null);
    const [dataChange, setDataChange] = useState(false);

    useEffect(() => {
        const load = async () => {
            const _whiteListItems = await rest('/getWhiteListItems/' + props.match.params.whiteListId);
            setWhiteListItems(_whiteListItems);
            setEditingItem(null);
        };

        load().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [dataChange]);

    const delItem = (whiteListItem) => {
        setModalContent(
            <DeleteDialog
                actionForYes={async () => {
                    try {
                        //throw new Error("Nećeš razbojniče!");
                        const res = await deleteModelData('WhiteListItem', whiteListItem.id);
                        if (res.id === whiteListItem.id) {
                            const _whiteListItems = whiteListItems.filter((w) => w.id !== whiteListItem.id);
                            setWhiteListItems(_whiteListItems);
                        }
                    } catch (err) {
                        console.warn(err);
                    }
                }}
                name={whiteListItem.name}
            />,
            null,
            false
        );
    };

    const whiteListId = parseInt(props.match.params.whiteListId, 10);
    const whiteList = props.whiteList.find((w) => w.id === whiteListId);

    return (
        <div className="container-fluid">
            <PageTitle />
            <div className="container">
                {whiteList ? <FormHeader>{whiteList.name}</FormHeader> : null}

                <button
                    onClick={() => {
                        props.history.push('/whiteLists');
                    }}
                    className="btn btn-sm btn-secondary mt-2 "
                >
                    {window.translate('GO BACK')}
                </button>

                <ul className="list-group bg-white mt-2">
                    {whiteListItems.map((w) => {
                        return (
                            <li className="list-group-item" key={w.id}>
                                <button
                                    onClick={() => setEditingItem(w)}
                                    className="btn btn-sm btn-outline-secondary pull-right mr-1"
                                >
                                    <i className="fa fa-edit" />
                                </button>
                                <button
                                    onClick={delItem.bind(this, w)}
                                    className="btn btn-sm btn-outline-danger pull-right mr-1"
                                >
                                    <i className="fa fa-trash" />
                                </button>

                                {editingItem && editingItem.id === w.id ? (
                                    <InlineWLIForm
                                        whiteListItem={editingItem}
                                        onCancel={() => setEditingItem(null)}
                                        onSubmitSuccess={() => {
                                            setEditingItem(null);
                                            setDataChange(!dataChange);
                                        }}
                                        whiteListId={whiteListId}
                                    />
                                ) : (
                                    <span>
                                        <div className="row">
                                            <div className="col-md-3">{w.code}</div>
                                            <div className="col-md-3">{w.name}</div>
                                            <div className="col-md-3">
                                                {window.translate('changed on ')} {moment(w.updatedAt).format('ll')}{' '}
                                            </div>
                                        </div>
                                    </span>
                                )}
                            </li>
                        );
                    })}
                </ul>

                {editingItem ? null : (
                    <InlineWLIForm onSubmitSuccess={() => setDataChange(!dataChange)} whiteListId={whiteListId} />
                )}
            </div>
        </div>
    );
};

export default branch(
    {
        whiteList: ['model', 'WhiteList'],
    },
    WhiteListItems
);
