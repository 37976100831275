var validateSRCSettingsCorruption = (SRCSettings) => {
    let gs = SRCSettings.generalSettings;
    let invalidSettings = [];

    if (gs.minECO_t1 > gs.maxECO_t1) {
        //newvalja
        let temp = {
            name: 'minECO_t1',
            currVal: gs.minECO_t1,
            expectedVal: 6,
        };
        let temp2 = {
            name: 'maxECO_t1',
            currVal: gs.maxECO_t1,
            expectedVal: 30,
        };
        invalidSettings.push(temp);
        invalidSettings.push(temp2);
    }
    if (gs.minPRECOMFORT_t1 > gs.maxPRECOMFORT_t1) {
        let temp = {
            name: 'minPRECOMFORT_t1',
            currVal: gs.minPRECOMFORT_t1,
            expectedVal: 14,
        };
        let temp2 = {
            name: 'maxPRECOMFORT_t1',
            currVal: gs.maxPRECOMFORT_t1,
            expectedVal: 28,
        };
        invalidSettings.push(temp);
        invalidSettings.push(temp2);
    }
    if (gs.minCOMFORT_t1 > gs.maxCOMFORT_t1) {
        let temp = {
            name: 'minCOMFORT_t1',
            currVal: gs.minCOMFORT_t1,
            expectedVal: 18,
        };
        let temp2 = {
            name: 'minCOMFORT_t1',
            currVal: gs.minCOMFORT_t1,
            expectedVal: 26,
        };
        invalidSettings.push(temp);
        invalidSettings.push(temp2);
    }
    if (gs.minECO_t2 > gs.maxECO_t2) {
        //newvalja
        let temp = {
            name: 'minECO_t2',
            currVal: gs.minECO_t2,
            expectedVal: 6,
        };
        let temp2 = {
            name: 'maxECO_t2',
            currVal: gs.maxECO_t2,
            expectedVal: 30,
        };
        invalidSettings.push(temp);
        invalidSettings.push(temp2);
    }
    if (gs.minPRECOMFORT_t2 > gs.maxPRECOMFORT_t2) {
        let temp = {
            name: 'minPRECOMFORT_t2',
            currVal: gs.minPRECOMFORT_t2,
            expectedVal: 14,
        };
        let temp2 = {
            name: 'maxPRECOMFORT_t2',
            currVal: gs.maxPRECOMFORT_t2,
            expectedVal: 28,
        };
        invalidSettings.push(temp);
        invalidSettings.push(temp2);
    }
    if (gs.minCOMFORT_t2 > gs.maxCOMFORT_t2) {
        let temp = {
            name: 'minCOMFORT_t2',
            currVal: gs.minCOMFORT_t2,
            expectedVal: 18,
        };
        let temp2 = {
            name: 'maxCOMFORT_t2',
            currVal: gs.maxCOMFORT_t2,
            expectedVal: 26,
        };
        invalidSettings.push(temp);
        invalidSettings.push(temp2);
        //invalidSettings.push("minCOMFORT_t2");
    }
    if (gs.minECO_t3 > gs.maxECO_t3) {
        //newvalja
        let temp = {
            name: 'minECO_t3',
            currVal: gs.minECO_t3,
            expectedVal: 6,
        };
        let temp2 = {
            name: 'maxECO_t3',
            currVal: gs.maxECO_t3,
            expectedVal: 30,
        };
        invalidSettings.push(temp);
        invalidSettings.push(temp2);
    }
    if (gs.minPRECOMFORT_t3 > gs.maxPRECOMFORT_t3) {
        //invalidSettings.push("minPRECOMFORT_t3");
        let temp = {
            name: 'minPRECOMFORT_t3',
            currVal: gs.minPRECOMFORT_t3,
            expectedVal: 14,
        };
        let temp2 = {
            name: 'maxPRECOMFORT_t3',
            currVal: gs.maxPRECOMFORT_t3,
            expectedVal: 28,
        };
        invalidSettings.push(temp);
        invalidSettings.push(temp2);
    }
    if (gs.minCOMFORT_t3 > gs.maxCOMFORT_t3) {
        let temp = {
            name: 'minCOMFORT_t3',
            currVal: gs.minCOMFORT_t3,
            expectedVal: 18,
        };
        let temp2 = {
            name: 'maxCOMFORT_t3',
            currVal: gs.maxCOMFORT_t3,
            expectedVal: 26,
        };
        invalidSettings.push(temp);
        invalidSettings.push(temp2);
    }

    return invalidSettings;
};

module.exports = validateSRCSettingsCorruption;
