import {
    CashRegistarModel,
    CashRegistarPaymentPurposeModel,
    CashRegistarPaymentsModel,
    CashRegistarReportModel,
    InvoiceModel,
} from '@common/modelDefinition';
import rest from '@data/restWrapper';

export interface CashReportWithPayments {
    activeReport: CashRegistarReportModel;
    payments: CashRegistarPaymentsModel[];
}

export async function createOrEditCashRegistar(_cashRegistar: CashRegistarModel): Promise<CashRegistarModel> {
    if (_cashRegistar.id) {
        return await rest('/CashRegistar/' + _cashRegistar.id, 'PUT', _cashRegistar);
    } else {
        const cashReg = await rest('/CashRegistar', 'POST', _cashRegistar);
        await getActiveCashRegReportWithPayments(cashReg.id, 0, 50);
        return cashReg;
    }
}

export async function createCashRegPurpose(
    paymentPurpose: CashRegistarPaymentPurposeModel | {}
): Promise<CashRegistarPaymentPurposeModel[]> {
    return await rest('/CashRegistarPaymentPurpose', 'POST', paymentPurpose);
}

export async function createCashRegPayment(
    payment: CashRegistarPaymentsModel | {},
    cashRegistarId: number
): Promise<CashRegistarPaymentsModel[]> {
    if(cashRegistarId){
        return await rest('/createNewCashRegistarPayment', 'POST', { payment, cashRegistarId });
    }else{
        throw new Error("Cash registar is not defined!")
    }
    
}

export async function getActiveCashRegReportWithPayments(
    cashRegId: number | undefined,
    page: number,
    rows: number
): Promise<CashReportWithPayments> {
    if (cashRegId) {
        const cashRegistarId = Number(cashRegId);
        return await rest(
            '/loadActiveCashRegistarReportWithPayments/' + cashRegistarId + '?page=' + page + '&rows=' + rows,
            'GET'
        );
    } else {
        throw new Error('Cash registar is not defined!');
    }
}

export async function concludeCashRegistarReport(to: any, cashRegistarId: number): Promise<CashRegistarReportModel> {
    if(cashRegistarId){
        return await rest('/concludeCashRegistarReport', 'POST', { to, cashRegistarId });
    }else{
        throw new Error("Cash registar is not defined!")
    }
}

export async function getPaymentPurposes(): Promise<CashRegistarPaymentPurposeModel[]> {
    //ovo mozda dodati u baobab
    return await rest('/CashRegistarPaymentPurpose', 'GET');
}

export async function getUncollectedBillsList(): Promise<InvoiceModel[]> {
    return await rest('/getUncollectedBillsList', 'POST');
}

export async function changeInvoicePaymentStatus(invoices: InvoiceModel[]) {
    for (let i = 0; i < invoices.length; i++) {
        await rest('/setInvoicePaymentStatus/' + invoices[i], 'POST');
    }
}

export async function getByIdCashRegReportAndPayments(cashRegReportId: number): Promise<CashReportWithPayments> {
    return await rest('/getCashRegistarReportAndPaymentsById/' + cashRegReportId, 'GET');
}

export async function setPartialPayment(invoiceId: number, value: number): Promise<InvoiceModel> {
    return await rest('/Invoice/' + invoiceId, 'PUT', { paymentStatus: 2, receivedPayment: value });
}
