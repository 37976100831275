import React from 'react';
import { branch } from 'baobab-react/higher-order';
import PageTitle from '../../components/pageTitle';
import countries from '../../data/countryCodes.json';
import flags from './flags.json';
import rest from '../../data/restWrapper';
import baobab from '../../data/state';

var flagsIndexed = {};

flags.forEach((f) => {
    flagsIndexed[f.alpha3] = f;
});

const VisaInfo = (props) => {
    const mapInfo = {};

    const deleteRequiredVisaMap =async (ccodeId) => {
        await rest(`/RequiredVisaMap/${ccodeId}`, 'DELETE', {});
        const _requiredVisaMap = props.requiredVisaMap.filter(r=>r.id!==ccodeId)
        baobab.root.select(['model', 'RequiredVisaMap']).set(_requiredVisaMap);
    }

    props.requiredVisaMap.forEach((r) => {
        mapInfo[r.countryCode] = r;
    });
    
    const visaMessage = window.translate("Nationalities who need visa to enter in country"); //locales.text.need_visa : locales.text.donot_need_visa

    return (
        <div className="container-fluid">
            <PageTitle />

            <div className="row justify-content-center">
                <div className="d-flex col-sm-12 col-md-11 col-lg-9 col-xl-8 m-auto ">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary mr-2"
                    >
                        {window.translate("GO BACK")}
                    </button>
                </div>
            </div>

            <div className="row mt-2">
                <div className="d-flex col-sm-12 col-md-11 col-lg-9 col-xl-8 m-auto ">
                    <div>{visaMessage}</div>
                </div>
            </div>

            <div className="row justify-content-center mt-2">
                <div className="col-sm-12 col-md-11 col-lg-9 col-xl-8">
                    <ul className="list-group">
                        {countries.map((c) => {
                            const ccode = Number(c['country-code']);
                            const flag = mapInfo[ccode] ? flagsIndexed[c['alpha-3']] : null;
                            const checkboxIcon = mapInfo[ccode] ? (
                                <i className="fa fa-2x fa-check-square-o pull-left" />
                            ) : (
                                <i className="fa fa-2x fa-square-o pull-left" />
                            );

                            return (
                                <li
                                    onClick={async () => {
                                        if (mapInfo[ccode]) {
                                            await deleteRequiredVisaMap(mapInfo[ccode].id)
                                        } else {
                                            await rest('/RequiredVisaMap', 'POST', { countryCode: ccode });
                                        }
                                    }}
                                    className="list-group-item pointer"
                                    key={ccode}
                                >
                                    {c.name}

                                    {checkboxIcon}

                                    {flag ? (
                                        <img
                                            alt={c.name}
                                            src={flag.file_url}
                                            className="pull-right"
                                            style={{
                                                width: '40px',
                                                height: '25px',
                                                paddingRight: '5px',
                                                fontWeight: 'normal',
                                                fontSize: '12px',
                                            }}
                                        />
                                    ) : null}
                                </li>
                            );
                        })}
                    </ul>
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        requiredVisaMap: ['model', 'RequiredVisaMap'],
    },
    VisaInfo
);