import React, { useState, useEffect } from 'react';
import { branch } from 'baobab-react/higher-order';
import PageTitle from '../../components/pageTitle';
import rest from '../../data/restWrapper';
import _ from 'lodash';
import { setModalContent } from '@components/modal';
import Delete from '../../components/confirmModal';
import SetResetPasswordForm from './setResetPasswordForm';
import FeatureFlag from '../../components/FeatureFlag';
import NotificationSubscriptions from './notificationSubscriptions';
import { toast } from 'react-toastify';

const Users = (props) => {
    const [users, setUsers] = useState([]);
    const [page, setPage] = useState(0);
    const [rows] = useState(200);
    const [roles, setRoles] = useState([]);
    const [selectedRole, setSelectedRole] = useState('');

    useEffect(() => {
        const loadData = async () => {
            await getPageData();
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const getPageData = async (_page = page) => {
        const _users = await rest('/getPagedUsers?page=' + _page + '&rows=' + rows);
        const roles = [];
        _users.forEach((u) => roles.push(u.role));
        setUsers(_users);
        setPage(_page);
        setRoles(roles);
    };

    const showSubscriptionsModal = async (userUuid) => {
        setModalContent(
            <NotificationSubscriptions userUuid={userUuid} />,
            window.translate('Notification subscriptions'),
            true,
            'modal-lg'
        );
    };

    const showSetResetPassword = async (userObject) => {
        setModalContent(
            <SetResetPasswordForm user={userObject} type="admin" />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const deleteItem = async (userUuid) => {
        try {
            await rest('/deleteUser/' + userUuid, 'DELETE');
            toast(window.translate('User is deleted!'), {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.INFO,
            });
            let _users = users;
            _users = _users.filter((u) => u.uuid !== userUuid);
            setUsers(_users);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = async (userUuid) => {
        setModalContent(
            <Delete actionForYes={deleteItem.bind(this, userUuid)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const pageDown = async () => {
        if (page > 0) {
            let _page = page - 1;
            await getPageData(_page);
        }
    };

    const pageUp = async () => {
        if (users.length > 0) {
            let _page = page + 1;
            await getPageData(_page);
        }
    };

    const { licenceInfo } = props;

    const licenceLimitText = `LICENCE LIMIT ${licenceInfo.currentUsersCount} of ${licenceInfo.licence?.decodedLicence?.usersCount}`;

    const _users =
        selectedRole === '' || selectedRole === 'Select role...' ? users : users.filter((u) => u.role === selectedRole);

    const uniqRoles = _.uniq(roles);

    return (
        <div className="">
            <PageTitle />

            <div className="row">
                <div className="d-flex col-12">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {window.translate('GO BACK')}
                    </button>

                    <button
                        disabled={licenceInfo.usersCountExceeded}
                        onClick={() => {
                            props.history.push('/users/new');
                        }}
                        className="btn btn-sm btn-primary ml-2"
                    >
                        {licenceInfo.usersCountExceeded ? licenceLimitText : window.translate('ADD NEW')}
                    </button>

                    <FeatureFlag flag="apiKeys">
                        <button
                            onClick={() => {
                                props.history.push('/apiKeys');
                            }}
                            className="btn btn-sm btn-primary ml-2"
                        >
                            {window.translate('API KEYS')}
                        </button>
                    </FeatureFlag>

                    <FeatureFlag flag="roleManagement">
                        <button
                            onClick={() => {
                                props.history.push('/roles');
                            }}
                            className="btn btn-sm btn-primary ml-2"
                        >
                            {window.translate('ROLES')}
                        </button>
                    </FeatureFlag>

                    <div className="ml-auto  align-self-end">
                        <button onClick={pageDown.bind(this)} className="btn btn-sm btn-outline-primary mr-2">
                            {window.translate('prev')}
                        </button>
                        <small className="mt-1">
                            {window.translate('page')} {page}
                        </small>
                        <button onClick={pageUp.bind(this)} className="btn btn-sm btn-outline-primary ml-2">
                            {window.translate('next')}
                        </button>
                    </div>
                </div>
            </div>

            <ul className="list-group mt-3 mx-3">
                <li className="px-1 py-2 row shadow-sm bg-white mb-2">
                    <div className="col-md-4">
                        <i className="fa fa-envelope-o" />
                    </div>
                    <div className="col-md-4">
                        <i className="fa fa-user" />{' '}
                    </div>
                    <div className="col-md-2">
                        <select
                            className="form-control form-control-sm"
                            onChange={(e) => setSelectedRole(e.target.value)}
                        >
                            <option>{window.translate('Select role...')}</option>
                            {uniqRoles.map((r, key) => {
                                return (
                                    <option key={key} value={r}>
                                        {window.translate(r)}
                                    </option>
                                );
                            })}
                        </select>
                    </div>
                    <div className="col-md-2  text-center">
                        <i className="fa fa-bolt" />
                    </div>
                </li>
                {_users.map((u, key) => {
                    let username = u.name ? u.name + ' ' + u.surname : 'Not set';
                    return (
                        <li
                            key={u.uuid + key}
                            className={
                                'px-1 py-1 row shadow-sm bg-white my-1 rounded ' + (u.enabled ? '' : 'text-danger')
                            }
                        >
                            <div className="col-md-4">
                                <div> {u.email} </div>
                            </div>
                            <div className="col-md-4">{username}</div>
                            <div className="col-md-2"> {u.role ? window.translate(u.role) : 'nn'}</div>
                            <div className="col-md-2">
                                <div>
                                    <button
                                        onClick={() => {
                                            props.history.push('/users/edit/' + u.uuid);
                                        }}
                                        className="btn btn-sm btn-info ml-1 pull-right"
                                    >
                                        <i className="fa fa-edit" />
                                    </button>

                                    {u.enabled ? (
                                        <div>
                                            <button
                                                onClick={showSetResetPassword.bind(this, u)}
                                                className="btn btn-sm btn-info ml-1 pull-right"
                                            >
                                                <i className="fa fa-key" />
                                            </button>

                                            <button
                                                onClick={showSubscriptionsModal.bind(this, u)}
                                                className="btn btn-sm btn-info ml-1 pull-right"
                                            >
                                                <i className="fa fa-bell" />
                                            </button>
                                        </div>
                                    ) : (
                                        <button
                                            onClick={showDeleteModal.bind(this, u.uuid)}
                                            disabled={props.loggedUser.uuid === u.uuid}
                                            className="btn btn-sm btn-danger ml-1 pull-right"
                                        >
                                            <i className="fa fa-trash" />
                                        </button>
                                    )}
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default branch(
    {
        loggedUser: ['authTokenInfo', 'user'],
        licenceInfo: ['licence'],
    },
    Users
);
