import React, { Component } from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import baobabTree from '../../data/state';
import '../roomInfo/style.css';
import Tooltip from '../../components/tooltip/tooltip';
import FeatureFlag from '../../components/FeatureFlag';
import SearchField from '../search/searchField';
import Select from 'react-select';
import PermissionCheck from '@components/PermissionCheck';
import { CleaningStatusEnum } from '@pages/household/interfaces';

class Toolbar extends Component {
    clearReservationsFilterFields() {
        this.props.setHideRoomByIdFilter([]); //set as empty
        this.props.setAvailableFromAndToTimestamps(null, null, null);
        this.props.setNumberOfBeds('');
        this.props.setNumberOfSpareBeds('')
    }

    toggleMainViewButtons(mainViewType) {
        let temp = _.clone(baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).get());
        if (_.includes(temp, 'timeline')) {
            _.remove(temp, (t) => t === 'timeline');
        }
        if (_.includes(temp, 'tiles')) {
            _.remove(temp, (t) => t === 'tiles');
        }
        if (_.includes(temp, 'activity')) {
            _.remove(temp, (t) => t === 'activity');
        }

        temp.push(mainViewType);
        baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(temp);
        setTimeout(() => {
            this.clearReservationsFilterFields();
        }, 10);
    }

    render() {
        const tagsOptions = this.props.roomTags.map((tag) => {
            return {
                value: tag,
                label: tag,
            };
        });

        tagsOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

        const tagsValues = this.props.tagsFilter.map((tag) => {
            return {
                value: tag,
                label: tag,
            };
        });

        const customStyles = {
            control: (base, state) => ({
                ...base,
                // height: '34px',
                // 'min-height': '24px',
                zIndex: 1000,
            }),
            menu: (base) => ({ ...base, zIndex: 9999 }),
        };

        const attributesOptions = this.props.attributes.map((attr) => {
            return {
                value: attr.id,
                label: window.translate(attr.name),
            };
        });

        attributesOptions.sort((a, b) => (a.label > b.label ? 1 : -1));

        const attributesValues = this.props.attributesFilter.map((attrId) => {
            const attr = this.props.attributes.find((at) => at.id === attrId);
            return {
                value: attr.id,
                label: window.translate(attr.name),
            };
        });

        let clStatusesOptions = Object.keys(CleaningStatusEnum).map((cleaningStatusString) => {
            if (isNaN(Number(cleaningStatusString))) {
                const cleaningStatusValue = cleaningStatusString ? CleaningStatusEnum[cleaningStatusString] : null;
                return {
                    value: cleaningStatusValue,
                    label: window.translate(cleaningStatusString),
                };
            } else {
                return null
            } 
        })

        clStatusesOptions = clStatusesOptions.filter(function( element ) {
            return element !== undefined;
         });
         clStatusesOptions = clStatusesOptions.filter(Boolean);
         
         
        const clStatusesValues = this.props.cleaningStatusesFilter.map((clStatuses) => {
             const cl = Object.keys(CleaningStatusEnum).find((cleaningStatusString) => clStatuses === CleaningStatusEnum[cleaningStatusString])
             const cleaningStatusValue = cl ? CleaningStatusEnum[cl] : null;
                return {
                    value: cleaningStatusValue,
                    label: window.translate(cl),
                };
        });
        return (
            <div>
                <nav className="navbar mb-0 mt-2 p-0  rounded row icons icons-small">
                    <div className="col-sm-12 col-md-9 col-lg-10">
                        <div className="row p-0">
                            <div className='col-md-3 col-xs-12 col-sm-12 pt-1'>
                            <div className="btn-group  rounded  mr-2  mb-2" role="group" aria-label="Sidebar filter">
                                <FeatureFlag flag="reservationsTimelineDashboard">
                                    <PermissionCheck name='dashboard_reservation'>
                                    <button
                                    style={{minHeight:"38px"}}
                                        onClick={this.toggleMainViewButtons.bind(this, 'timeline')}
                                        className={
                                            _.includes(this.props.showComponents, 'timeline')
                                                ? 'btn btn-sm btn-light active border'
                                                : 'btn btn-sm btn-light border'
                                        }
                                    >
                                        <i className="fa fa-calendar text-info" /> {window.translate('Reservations')}
                                    </button>
                                    </PermissionCheck>
                                </FeatureFlag>

                                <button
                                style={{minHeight:"38px"}}
                                    onClick={this.toggleMainViewButtons.bind(this, 'tiles')}
                                    className={
                                        _.includes(this.props.showComponents, 'tiles')
                                            ? 'btn btn-sm btn-light active border'
                                            : 'btn btn-sm btn-light border'
                                    }
                                >
                                    <i className="fa fa-th text-info" /> {window.translate('Rooms')}
                                </button>

                                <FeatureFlag flag="activityDashboard">
                                <PermissionCheck name='dashboard_activity'>
                                    <button
                                    style={{minHeight:"38px"}}
                                        onClick={this.toggleMainViewButtons.bind(this, 'activity')}
                                        className={
                                            _.includes(this.props.showComponents, 'activity')
                                                ? 'btn btn-sm btn-light active border'
                                                : 'btn btn-sm btn-light border'
                                        }
                                    >
                                        <i className="fa fa-list text-info" /> {window.translate('Activity')}{' '}
                                    </button>
                                    </PermissionCheck>
                                </FeatureFlag>
                                {/* <FeatureFlag flag="groupReservationsDashboard">
                                    <button
                                        onClick={this.toggleMainViewButtons.bind(this, 'groupReservations')}
                                        className={
                                            _.includes(this.props.showComponents, 'groupReservations')
                                                ? 'btn btn-sm btn-light active border'
                                                : 'btn btn-sm btn-light border'
                                        }
                                    >
                                        <i className="fa fa-list text-info" />{' '}
                                        {locales.label.dashboard_toolbar_groupReservations}{' '}
                                    </button>
                                </FeatureFlag> */}
                            </div>
                            </div>
                            <div className='col-md-5 col-xs-12 col-sm-12 pt-1'>
                            <div className="d-inline-block" style={{width:"45%"}}>  
                            {
                                <Select
                                    isMulti
                                    styles={customStyles}
                                    className=""
                                    placeholder={window.translate('Filter by statuses')}
                                    options={clStatusesOptions}
                                    onChange={(values) => {
                                        if (values === null) {
                                            this.props.toggleCleaningStatusesFilter(null);
                                        } else {
                                            this.props.toggleCleaningStatusesFilter(values[values.length - 1].value);
                                        }
                                    }}
                                    value={clStatusesValues}
                                ></Select>
                            }
                                </div>
                            <div className=" d-inline-block  pr-1 text-center" style={{width:"10%"}}>
                            {!_.isEmpty(this.props.roomTags) ? (
                                <Tooltip
                                    message={window.translate(
                                        'Tags: Filtering by tag remove filters by previously selected tag'
                                    )}
                                >
                                    <i className="fa fa-info-circle text-primary mb-2 p-1"></i>
                                </Tooltip>
                            ) : null}
                            </div>
                            <div className="d-inline-block" style={{width:"45%"}}>  
                            {
                                <Select
                                    isMulti
                                    styles={customStyles}
                                    className=""
                                    placeholder={window.translate('Filter by tag')}
                                    options={tagsOptions}
                                    onChange={(values) => {
                                        if (values === null) {
                                            this.props.toggleTagFilter(null);
                                        } else {
                                            this.props.toggleTagFilter(values[values.length - 1].value);
                                        }
                                    }}
                                    value={tagsValues}
                                ></Select>
                            }
                                </div></div>
                            {/* {_.map(this.props.roomTags, (tag) => {
                                return (
                                    <button
                                        key={tag}
                                        onClick={() => {
                                            this.props.toggleTagFilter(tag);
                                        }}
                                        className={
                                            _.includes(this.props.tagsFilter, tag)
                                                ? 'btn btn-sm btn-secondary mr-1 mb-2'
                                                : 'btn btn-sm btn-light border mr-1 mb-2'
                                        }
                                    >
                                        {/* <input onChange={() => { }} type="checkbox" checked={_.includes(this.props.tagsFilter, tag)} /> */}
                            {/* {tag}
                                    </button>
                                );
                            })}  */}
                            <div className='col-md-4 col-xs-12 col-sm-12 pt-1'>
                            <div className="d-inline-block  pr-1 text-center" style={{width:"16%"}}>
                                {!_.isEmpty(this.props.attributes) ? (
                                    <Tooltip
                                        message={window.translate(
                                            'Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes'
                                        )}
                                    >
                                        <i
                                            className="fa fa-info-circle text-primary ml-1 mb-2 pr-1"
                                            /* title="Attributes: if checked, return rooms that have any of selected attributes, else only rooms that have all selected attributes" */
                                        ></i>
                                    </Tooltip>
                                ) : null}
                                {
                                    <i
                                        onClick={this.props.toggleAttributesCheck.bind(this)}
                                        name="attributesCheck"
                                        value={this.props.attributesFilteringType}
                                        className={
                                            this.props.attributesFilteringType
                                                ? 'fa fa-check-square-o mb-2 fa-lg'
                                                : 'fa fa-square-o mb-2 fa-lg'
                                        }
                                    ></i>
                                }
                            </div>
                            {<div className="d-inline-block" style={{width:"84%"}}>
                                <Select
                                    isMulti
                                    styles={customStyles}
                                    className=""
                                    placeholder={window.translate('Filter by attributes')}
                                    options={attributesOptions}
                                    onChange={(values) => {
                                        if (values === null) {
                                            this.props.handleAttributesSelect(null);
                                        } else {
                                            this.props.handleAttributesSelect(values);
                                        }
                                    }}
                                    value={attributesValues}
                                ></Select>
                                {/* <small className="ml-3">{this.props.attributesFilteringType
                                    ? window.translate("any selected attribute")
                                    : window.translate("all selected attributes")}</small> */}
                                
                                </div>
                                
                                // _.map(this.props.attributes, (att, key) => {
                                //     return (
                                //         <button
                                //             key={key}
                                //             onClick={() => {
                                //                 this.props.handleAttributesSelect(att.id);
                                //             }}
                                //             className={
                                //                 _.includes(this.props.attributesFilter, att.id)
                                //                     ? 'btn btn-sm btn-secondary mr-1 mb-2'
                                //                     : 'btn btn-sm btn-light border mr-1 mb-2'
                                //             }
                                //         >
                                //             <img
                                //                 style={{ width: '14px', height: '14px', marginBottom: '3px' }}
                                //                 className=""
                                //                 src={att.icon}
                                //                 alt=""
                                //             />{' '}
                                //             {window.translate(att.name)}
                                //         </button>
                                //     );
                                // })
                            }
                            </div>
                            
                        </div>
                    </div>
                    <div className="col-sm-12 col-md-3 col-lg-2">
                        <SearchField
                            onChangeHandler={this.props.setSearchTerm}
                            className="form-control mt-1 mb-2"
                            history={this.props.history}
                        />
                    </div>
                </nav>
            </div>
        );
    }
}

export default branch(
    {
        roomTags: ['monkeys', 'roomTags'],
        reservations: ['model', 'Reservation'],
        attributes: ['model', 'RoomAttribute'],
    },
    Toolbar
);
