import React from 'react';
import PageTitle from '@components/pageTitle';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { translate } from '@data/translations';
import { setModalContent } from '@components/modal';
import Form from './form';
import { CashRegistarModel, CurrencyModel, PosTerminalModel } from '@common/modelDefinition';

interface CashRegistarsProps {
    history: any;
    cashRegistars: CashRegistarModel[];
    currency: CurrencyModel[];
    posTerminal: PosTerminalModel[];
}

function CashRegistars(props: CashRegistarsProps) {
    const { cashRegistars, currency, posTerminal } = props;

    const showFormModal = (cashRegistar: CashRegistarModel | null) => {
        setModalContent(
            <Form
                cashRegistar={cashRegistar}
                cashRegistars={cashRegistars}
                history={props.history}
                currency={currency}
            />,
            translate(!cashRegistar ? translate('New cash registar') : translate('Edit cash registar')),
            false,
            'modal-md'
        );
    };

    return (
        <div>
            <PageTitle />

            <button
                onClick={() => {
                    props.history.goBack();
                }}
                className="btn btn-sm btn-secondary "
            >
                {translate('GO BACK')}
            </button>
            <button
                onClick={() => {
                    showFormModal(null);
                }}
                className="btn btn-sm btn-primary m-1"
            >
                {translate('ADD NEW')}
            </button>
            <button
                onClick={() => {
                    props.history.push('/uncollectedBills');
                }}
                className="btn btn-sm btn-info m-1"
            >
                {translate('UNCOLLECTED BILLS')}
            </button>

            <ul className="list-group mt-3 mx-3">
                <small>
                    <li className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                        <div className="col-6 col-sm-3  elipsis">{translate('Name')}</div>
                        <div className="col-6 col-sm-3  elipsis">{translate('Terminal')}</div>
                        <div className="col-6 col-sm-3 elipsis">{translate('Currency')}</div>
                        <div className="col-6 col-sm-3"></div>
                    </li>
                </small>
            </ul>

            <ul className="list-group mt-2 mx-3">
                {cashRegistars &&
                    cashRegistars.map((cr: CashRegistarModel, key: number) => {
                        const teriminal =
                            posTerminal &&
                            cr.posTerminalId &&
                            posTerminal.find((p: PosTerminalModel) => p.id === Number(cr.posTerminalId));
                        return (
                            <li key={key} className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                                <div className="col-3 col-sm-3  elipsis">{translate(cr.name)}</div>
                                <div className="col-3 col-sm-3  elipsis">
                                    {teriminal ? translate(teriminal.name) : ''}
                                </div>
                                <div className="col-3 col-sm-3 elipsis">{cr.currency}</div>
                                <div className="col-3 col-sm-3">
                                    <button
                                        onClick={() => {
                                            showFormModal(cr);
                                        }}
                                        className="btn btn-sm btn-outline-dark pull-right  ml-1 mb-1"
                                    >
                                        <i className="fa fa-edit"></i>
                                    </button>
                                    <button disabled={true} className="btn btn-sm btn-outline-danger pull-right mb-1">
                                        <i className="fa fa-trash"></i>
                                    </button>
                                    <button
                                        onClick={() => props.history.push('/cashRegistarPage/' + cr.id)}
                                        className="btn btn-sm btn-info pull-right  mr-1 mb-1"
                                    >
                                        {translate('Open cash registar')}
                                    </button>
                                </div>
                            </li>
                        );
                    })}
            </ul>
        </div>
    );
}

export default branch(
    {
        cashRegistars: ['model', 'CashRegistar'],
        currency: ['model', 'Currency'],
        posTerminal: ['model', 'PosTerminal'],
    },
    CashRegistars
);
