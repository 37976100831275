import React from 'react';
import rest from '@data/restWrapper';
import { setModalContent, closeModal } from '@components/modal';
import ConfirmButtons from '@components/buttons/confirmButtons';
import AssignPriceList from './AssignPriceList';
import numbro from 'numbro';
import {  checkOfferMismatch } from '../utils';
import { ReservationController } from '../resController';
import { translate } from '@data/translations';
//@ts-ignore
import { useBranch } from 'baobab-react/hooks';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import { PriceOffer } from '@common/modelDefinition';
import { reservationEnums } from '@data/reservationStatusEnums';

/*
OVAJ FAJL TREBA ADEKVATNO TIPIZIRATI!!!!  - ali neka sada stoji ovako!!!
*/

const ctrl = new ReservationController();

function AccommodationPriceOffer(props: any) {
    const { reservation } = props;

    const [offerInvoiceItems, setOfferInvoiceItems] = React.useState([]);
    const [proformaInvoiceItems, setProformaInvoiceItems] = React.useState([]);

    const [priceOfferIntervals, setPriceOfferIntervals] = React.useState([]);
    const [proformaPriceOfferIntervals, setProformaPriceOfferIntervals] = React.useState([]);

    const { defaultCurrency } = useBranch({
        defaultCurrency: ['defaultCurrency'],
    });

    const {
        id,
        accommodationPriceListId,
        checkOutTimestamp,
        checkInTimestamp,
        adultsNumber,
        childrenNumber,
        genuineInvoiceIssued,
        openPrice,
        statusEnum,
        canceledReservationInvoicingStatus,
    } = reservation;

    const reservationOfferArr = reservation.rememberedAccommodationPriceInfo
        ? reservation.rememberedAccommodationPriceInfo
        : null;
    const reservationOffer =
        reservationOfferArr && reservationOfferArr.length > 0
            ? reservationOfferArr[reservationOfferArr.length - 1]
            : reservation;

    React.useEffect(() => {
        const loadProformaOffer = async () => {
            const {
                invoiceItems: proformaItems,
                priceOfferByLinkedIntervals: proformaOffer,
            } = await rest('/getAccommodationOffer', 'POST', { reservation: reservationOffer });
            setProformaInvoiceItems(proformaItems);
            setProformaPriceOfferIntervals(proformaOffer);
        };
        const loadCurrentOffer = async () => {
            const { invoiceItems: items, priceOfferByLinkedIntervals: offer } = await rest(
                '/getAccommodationOffer',
                'POST',
                { reservation: reservation }
            );
            setOfferInvoiceItems(items);
            setPriceOfferIntervals(offer);
        };
        if (accommodationPriceListId || openPrice || statusEnum === 3) {
            loadProformaOffer().catch((err) => {
                console.warn(err);
            });

            loadCurrentOffer().catch((err) => {
                console.warn(err);
            });
        }
        // eslint-disable-next-line
    }, [
        id,
        accommodationPriceListId,
        checkInTimestamp,
        checkOutTimestamp,
        adultsNumber,
        childrenNumber,
        reservation.rememberedAccommodationPriceInfo,
        openPrice,
        statusEnum,
        canceledReservationInvoicingStatus, 
    ]); //ako ikada djeca postanu faktor u cijeni, ovdjde ubaci childrenNumber

    if (!accommodationPriceListId && !openPrice && canceledReservationInvoicingStatus !== 2) {
        return <AssignPriceList {...props} />;
    }

    const mismatchOffer = checkOfferMismatch({
        invoiceItems: offerInvoiceItems,
        proformaInvoiceItems,
        reservations: [reservation],
    });

    const hasMissmatch = mismatchOffer?.length > 0 && !genuineInvoiceIssued;

    const proformaOffer = (
        <React.Fragment>
            <DisplayCompressedPriceOffer
                warning={hasMissmatch}
                invoiceItems={proformaInvoiceItems}
                defaultCurrency={defaultCurrency}
                reservation={reservationOffer}
                res={reservation}
            />
            <OfferBreakdown
                reservation={reservationOffer}
                res={reservation}
                priceOfferIntervals={proformaPriceOfferIntervals}
                invoiceItems={proformaInvoiceItems}
                defaultCurrency={defaultCurrency}
            />
        </React.Fragment>
    );

    const preview = (
        <div className="mt-2 pt-2 border-top">
            <div className="row">
                <div className="col-md-6 border-right">
                    <b>{translate('Given price offer')}:</b>
                    {proformaOffer}
                </div>

                {hasMissmatch ? (
                    <div className="col-md-6 ">
                        <b>{translate('Given price offer change detected')}:</b>
                        <DisplayCompressedPriceOffer
                            showClearPriceListButton={false}
                            invoiceItems={offerInvoiceItems}
                            defaultCurrency={defaultCurrency}
                            reservation={reservation}
                            res={reservation}
                        />

                        <OfferBreakdown
                            reservation={reservation}
                            priceOfferIntervals={priceOfferIntervals}
                            invoiceItems={offerInvoiceItems}
                            defaultCurrency={defaultCurrency}
                        />
                        <div className="text-center">
                            <ApplyButton
                                buttonText={translate('Apply new price')}
                                reservation={reservation}
                                invoiceItems={offerInvoiceItems}
                                priceOfferIntervals={priceOfferIntervals}
                            />
                        </div>
                    </div>
                ) : !genuineInvoiceIssued ? (
                    <UndoApplyNewOffer buttonText={translate('Undo last offer')} reservation={reservation} />
                ) : null}
            </div>
        </div>
    );

    return <div>{preview}</div>;
}

const DisplayCompressedPriceOffer = (props: any) => {
    const {
        title = '',
        reservation,
        defaultCurrency,
        invoiceItems,
        showClearPriceListButton = true,
        warning = false,
        res,
    } = props;
    const { adultsNumber = 1, childrenNumber = 0 } = res;
    const totalToPay = invoiceItems
        .map((i: any) => i.bruttoValueWithDiscount)
        .reduce((a: number, b: number) => a + b, 0);
    const daysCount = invoiceItems.map((i: any) => i.quantity).reduce((a: number, b: number) => a + b, 0);
    const totalTax = invoiceItems.map((i: any) => i.totalTaxValue).reduce((a: number, b: number) => a + b, 0);
    const totalDiscount = invoiceItems.map((i: any) => i.discountValue).reduce((a: number, b: number) => a + b, 0);

    return (
        <div>
            <b>{title} </b>

            <div className={warning ? 'text-danger text-left mt-1 mb-1' : 'text-primary text-left mt-1 mb-1'}>
                <div className="clear">
                    {reservation && (reservation.accommodationPriceListId || reservation.openPrice) && showClearPriceListButton === true ? (
                        <button
                            onClick={(e) => {
                                e.preventDefault();
                                const _res = { ...res };                            
                                    _res.openPrice = null;                             
                                    _res.accommodationPriceListId = null;                                                           
                                ctrl.updateActiveReservation(_res);
                            }}
                            className="btn btn-sm btn-info mr-2"
                        >
                            <i className="fa fa-exchange" />
                        </button>
                    ) : null}
                    <b>{translate('Adults')}:</b> {adultsNumber} |&ensp;
                    <b>{translate('Children')}:</b> {childrenNumber} |&ensp;
                    <b>{translate('Days')}:</b> {daysCount} |&ensp;
                    <b>{translate(reservation.statusEnum !== reservationEnums.canceled ? 'Accommodation': 'Cancellation fee')}:</b>{' '}
                    {totalToPay
                        ? numbro(totalToPay).format({ mantissa: 2, trimMantissa: false, thousandSeparated: true })
                        : 0}{' '}
                    {defaultCurrency}
                    &ensp;|&ensp;
                    <b>{translate('Tax')}:</b>{' '}
                    {totalTax
                        ? numbro(totalTax).format({ mantissa: 2, trimMantissa: false, thousandSeparated: true })
                        : 0}{' '}
                    {defaultCurrency}
                    {totalDiscount ? (
                        <span>
                            <b>{translate('Discount')}:</b>{' '}
                            {totalDiscount
                                ? numbro(totalDiscount).format({
                                      mantissa: 2,
                                      trimMantissa: false,
                                      thousandSeparated: true,
                                  })
                                : 0}{' '}
                            {defaultCurrency}
                        </span>
                    ) : null}
                </div>
            </div>
        </div>
    );
};

const OfferBreakdown = (props: any) => {
    const { priceOfferIntervals, reservation, alert = '', defaultCurrency } = props;

    const { adultsNumber } = reservation;

    let listStyles = alert ? 'alert alert-danger list-group-item' : 'alert alert-success list-group-item';
    if (alert === undefined) {
        listStyles = 'list-group-item';
    }

    return (
        <ul className="list-group ">
            {priceOfferIntervals.map((p: any, index: number) => {
                //zašto je dva puta index redeklariran???? ovo nekada istražiti!
                const pricesPerAdult = p.apRules.map((price: number, index: number) => {
                    let result = null;
                    if (adultsNumber === index + 1) {
                        result = (
                            <span>
                                <b>{index + 1}</b> {translate('person(s)')} &#8594; {price} {defaultCurrency}{' '}
                                {translate('per day')}
                            </span>
                        );
                    } else {
                        result = (
                            <small>
                                {index + 1} &#8594; {price} {defaultCurrency}
                            </small>
                        );
                    }
                    return <span key={index}>{result}; &ensp;</span>;
                });

                const _from = moment(p.from).format('LL');
                const _to = moment(p.to).format('LL');
                const header =
                    _from === _to ? (
                        <small>
                            <b>{_from}</b>
                        </small>
                    ) : (
                        <small>
                            <b>
                                {_from} &#8594; {_to}
                            </b>
                        </small>
                    );
                return (
                    <li key={index} className={listStyles + ' p-2 d-flex justify-content-between'}>
                        <div>{header}</div>{' '}
                        <div>
                            <small> {pricesPerAdult}</small>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

const ApplyButton = (props: any) => {
    //ovo tipizirati!!!!
    const { reservation, /* invoiceItems,*/ priceOfferIntervals, buttonText = translate('Apply') } = props;
    return (
        <button
            onClick={(e) => {
                e.preventDefault();
                setModalContent(
                    <div className="container-fluid">
                        <div className="text-center">
                            {translate('Please confirm?')}
                            <hr />
                        </div>

                        <div className="text-center">
                            <ConfirmButtons
                                onConfirm={async () => {
                                    const res = { ...reservation };
                                    const {
                                        accommodationPriceListId,
                                        checkInTimestamp,
                                        checkOutTimestamp,
                                        adultsNumber,
                                        childrenNumber,
                                        openPrice,
                                        canceledReservationInvoicingStatus,
                                    } = reservation;
                                    const newOffer:PriceOffer = {
                                        accommodationPriceListId,
                                        checkInTimestamp,
                                        checkOutTimestamp,
                                        adultsNumber,
                                        childrenNumber,
                                        priceOfferIntervals: priceOfferIntervals, // OVDJE
                                        openPrice,
                                        canceledReservationInvoicingStatus,
                                    };
                                    const offers = [...res.rememberedAccommodationPriceInfo];

                                    offers.push(newOffer);
                                    res.rememberedAccommodationPriceInfo = offers;
                                    ctrl.updateActiveReservation(res);
                                    closeModal();
                                }}
                                onCancel={() => {
                                    closeModal();
                                }}
                            />
                        </div>
                    </div>,

                    translate('Apply new offer'), // header
                    false, // showCloseButton
                    'modal-md'
                );
            }}
            className="btn btn-sm btn-warning mt-2"
        >
            {buttonText}
        </button>
    );
};

const UndoApplyNewOffer = (props: any) => {
    const { reservation, buttonText = translate('Undo last offer') } = props;
    if (!reservation.rememberedAccommodationPriceInfo || reservation.rememberedAccommodationPriceInfo.length < 2) {
        return null;
    }

    return (
        <div className="col-md-6 ">
            <div className="text-center">
                <button
                    onClick={() =>
                        setModalContent(
                            <div className="container-fluid">
                                <div className="text-center">
                                    {translate('Please confirm?')}
                                    <hr />
                                </div>

                                <div className="text-center">
                                    <ConfirmButtons
                                        onConfirm={async () => {
                                            const res = { ...reservation };
                                            const offers = [...res.rememberedAccommodationPriceInfo];
                                            offers.pop();
                                            res.rememberedAccommodationPriceInfo = offers;
                                            ctrl.updateActiveReservation(res);
                                            closeModal();
                                        }}
                                        onCancel={() => {
                                            closeModal();
                                        }}
                                    />
                                </div>
                            </div>,

                            translate('Undo last offer'), // header
                            false, // showCloseButton
                            'modal-md'
                        )
                    }
                    className="btn btn-sm btn-light mt-2"
                >
                    {buttonText}
                </button>
            </div>
        </div>
    );
};

export default AccommodationPriceOffer;
