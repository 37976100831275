import React from 'react';
import { deleteModelData } from '../../data/actions/modelActions';
import { setModalContent } from '@components/modal';
import Delete from '../../components/confirmModal';
import PageTitle from '../../components/pageTitle';
import { branch } from 'baobab-react/higher-order';
import AttributeForm from './attributeForm';
import baobab from '../../data/state';

function RoomAttributes(props) {
    const attributes = [...props.attributes];

    const deleteItem = async (model, itemId) => {
        try {
            await deleteModelData(model, itemId);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = (model, itemId) => {
        setModalContent(
            <Delete
                actionForYes={async () => {
                    await deleteItem(model, itemId);
                    const _attributes = attributes.filter((a) => a.id !== Number(itemId));
                    baobab.root.select(['model', 'RoomAttribute']).set([..._attributes]);
                }}
            />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const showFormModal = (attributeId) => {
        setModalContent(
            <AttributeForm attributeId={attributeId} />,
            window.translate('New attribute'),
            false,
            'modal-lg'
        );
    };

    return (
        <div>
            <PageTitle />
            <button
                onClick={() => {
                    props.history.goBack();
                }}
                className="btn btn-sm btn-secondary"
            >
                {window.translate('GO BACK')}
            </button>
            <button onClick={() => showFormModal(null)} className="btn btn-sm btn-primary ml-2">
                {window.translate('ADD NEW')}
            </button>

            <ul className="list-group mt-3 mx-3">
                {attributes.map((att, index) => {
                    return (
                        <li key={att.id} className={'px-1 py-1 row ' + (index % 2 === 0 ? 'bg-white' : '')}>
                            <div className="col-md-3 align-self-center">{window.translate(att.name)}</div>
                            <div className="col-md-3 align-self-center">{window.translate(att.description)}</div>
                            <div className="col-md-3">
                                <img className="" src={att.icon} alt="" style={{ width: '40px', height: '40px' }} />
                            </div>
                            <div className="col-md-3 align-self-center">
                                <button
                                    onClick={() => showFormModal(att.id)}
                                    className="btn btn-sm btn-outline-dark pull-right ml-1"
                                >
                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                </button>
                                <button
                                    onClick={() => showDeleteModal('RoomAttribute', att.id)}
                                    className="btn btn-sm btn-outline-danger pull-right ml-1"
                                >
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
}

export default branch(
    {
        attributes: ['model', 'RoomAttribute'],
    },
    RoomAttributes
);
