import React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';

export interface DocumentProps{
    otherSettings:any 
    children:any
}

const DocumentFooter = (props:DocumentProps) => {
    let documentFooterInfo =
        props.otherSettings && props.otherSettings.documentFooter ? props.otherSettings.documentFooter : '';

    return (
        <footer className="text-center border-top mt-2">
            <small>
                <small>
                    <small dangerouslySetInnerHTML={{ __html: documentFooterInfo }}></small>
                </small>
            </small>
            {props.children}
            <div className="no-print bg-dark text-white">
                - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - - -
            </div>
        </footer>
    );
};

export default branch(
    {
        otherSettings: ['otherSettings'],
    },
    DocumentFooter
);
