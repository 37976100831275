import React, { Component } from 'react';
// import _ from "lodash";
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { formatDate, parseDate } from 'react-day-picker/moment';

class FiscalMemoryReportByDate extends Component {
    constructor() {
        super();
        // this.handleSelectDateChange = this.handleSelectDateChange.bind(this);
        this.state = {
            month: new Date(),
            disabledDates: [],
        };
    }

    changeMonth = (month) => {
        this.setState({ month: month });
    };

    render() {
        const { selectedFiscalPrinter } = this.props;
        let from = this.props.selectedDate ? new Date(this.props.selectedDate.from) : new Date();
        let to = this.props.selectedDate ? new Date(this.props.selectedDate.to) : new Date();
        return (
            <div className="col-12 col-md-6 p-0 m-0 mb-2 pull-left ">
                <div className="m-1 card bg-white h-100 ">
                    <div className="card-body">
                        <h5 className="card-title">{window.translate("Fiscal memory report by date")}</h5>
                        <p className="card-text">
                            <small>{window.translate("Start date – defines the starting date for the report")}</small>
                        </p>
                        <p className="card-text">
                            <small>{window.translate("End date – defines the ending date for the report")}</small>
                        </p>
                        <div className="row">
                            <div className="InputSelectDate col-md-6">
                                <label className="pr-2">
                                    <small>{window.translate("From")} /</small>
                                </label>
                                <DayPickerInput
                                    value={from}
                                    placeholder={window.translate("Select Date")}
                                    format="LL"
                                    inputProps={{ className: 'form-control form-control-sm' }}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    onDayChange={this.props.handleSelectDateChange.bind(this, 'from')}
                                    dayPickerProps={{
                                        onMonthChange: this.changeMonth,
                                        modifiers: {
                                            disabled: this.state.disabledDates,
                                        },
                                    }}
                                />
                            </div>
                            <div className="InputSelectDate col-md-6">
                                <label className="pr-2">
                                    <small>{window.translate("To")} /</small>
                                </label>
                                <DayPickerInput
                                    value={to}
                                    placeholder={window.translate("Select Date")}
                                    format="LL"
                                    inputProps={{ className: 'form-control form-control-sm' }}
                                    formatDate={formatDate}
                                    parseDate={parseDate}
                                    onDayChange={this.props.handleSelectDateChange.bind(this, 'to')}
                                    dayPickerProps={{
                                        onMonthChange: this.changeMonth,
                                        modifiers: {
                                            disabled: this.state.disabledDates,
                                        },
                                    }}
                                />
                            </div>
                        </div>

                        <button
                            className="btn btn-sm btn-primary mr-2 mt-2"
                            onClick={this.props.showConfirmModal.bind(this, {
                                option: 'fiscalMemoryReportByDate',
                                fiscalPrinter: selectedFiscalPrinter,
                                from: from,
                                to: to,
                            })}
                        >
                            {window.translate("Issue report")}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default FiscalMemoryReportByDate;
