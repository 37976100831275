import baobab from '@data/state/index';

import {
    loadInvoiceDataForPrintView as _loadInvoiceDataForPrintView,
    stornoPosTerminalInvoice as _stornoPosTerminalInvoice,
    fiscalizeConcludedInvoice as _fiscalizeConcludedInvoice,
    getReservationInvoicePreview,
    getReservationGroupInvoicePreview,
} from './api';
import { getBelongingGroupReservation, setReservationInvoiceProperties } from '../checkout/api';
import {
    GroupInvoiceDetails,
    GroupReservationWithReservations,
    InvoiceModelWithInvoiceItems,
    ReservationModelWithModelsIncluded,
} from '@common/modelDefinition';

const loadInvoiceDataForPrintView = async (invoiceId: number) => {
    clearInvoicePrintPreview();
    baobab.root.select('state', 'invoicePresenter').set({ invoice: {}, groupReservationsArray: [], refInvoice: null });
    const { invoice, refInvoice } = await _loadInvoiceDataForPrintView(invoiceId);
    const { reservationGroupUuid } = invoice;
    const groupReservation: GroupReservationWithReservations | null = reservationGroupUuid
        ? await getBelongingGroupReservation(reservationGroupUuid)
        : null;
    const groupReservationsArray: ReservationModelWithModelsIncluded[] = groupReservation
        ? groupReservation.Reservations
        : [];

    baobab.root.select('state', 'invoicePresenter').set({ invoice, groupReservationsArray, refInvoice });
};

const clearInvoicePrintPreview = () => {
    baobab.root.select('state', 'invoicePresenter', 'invoicePreview').set({ invoice: {}, invoiceItems: [] });
};

const setInvoiceProperty = (propertyName: string, value: string) => {
    // samo locale i currency
    const invoiceCursor = baobab.root.select('state', 'invoicePresenter', 'invoice');
    invoiceCursor.set([propertyName], value);
};

const stornoInvoiceByIdAndWithReclamationNote = async (
    invoiceId: number,
    reclamationReason: string | null,
    terminalType: string = 'reservation'
) => {
    if (reclamationReason === null) {
        throw new Error('You have to give a reason for storning invoice?');
    }
    if (terminalType === 'reservation' || terminalType === 'reservationConsumption') {
        const properties = { reclamation: 1, reclamationReason };
        const { invoice, invoiceItems, stornoInvoices } = await setReservationInvoiceProperties(invoiceId, properties);
        baobab.root.select('state', 'invoicePresenter').set({ invoice, invoiceItems, stornoInvoices });
    } else if (terminalType === 'posTerminal' || terminalType === 'minibar') {
        const { invoice, invoiceItems, stornoInvoices } = await _stornoPosTerminalInvoice(
            invoiceId,
            reclamationReason,
            terminalType
        );
        baobab.root.select('state', 'invoicePresenter').set({ invoice, invoiceItems, stornoInvoices });
    }
};

const fiscalizeConcludedInvoice = async (invoiceId: number) => {
    const invoice: InvoiceModelWithInvoiceItems = await _fiscalizeConcludedInvoice(invoiceId);
    baobab.root.select('state', 'invoicePresenter').set({ invoice });
};

const loadInvoicePreview = async (reservationId: number) => {
    baobab.root.select('state', 'invoicePresenter', 'groupInvoiceDetails').set({});
    const { invoice, invoiceItems } = await getReservationInvoicePreview(reservationId);
    invoice.reservationId = reservationId;
    baobab.root.select('state', 'invoicePresenter', 'invoicePreview').set({ invoice, invoiceItems });
};

const getIssuedStornoInvoice = (invoiceId: number) => {
    const stornoInvoices: InvoiceModelWithInvoiceItems[] = baobab.root
        .select('state', 'invoicePresenter', 'stornoInvoices')
        .get();
    const issuedStornoInvoice: InvoiceModelWithInvoiceItems | undefined | null =
        stornoInvoices && stornoInvoices.length > 0 ? stornoInvoices.find((i) => i.refInvoice === invoiceId) : null;
    return issuedStornoInvoice;
};

const loadGroupInvoiceDetails = async (reservationGroupUuid: string | null) => {
    if (!reservationGroupUuid) {
        return null;
    }
    const groupInvoiceDetails: GroupInvoiceDetails = baobab.root
        .select('state', 'invoicePresenter', 'groupInvoiceDetails')
        .get();
    if (
        groupInvoiceDetails &&
        Object.keys(groupInvoiceDetails).length > 0 &&
        groupInvoiceDetails.constructor === Object
    ) {
        baobab.root.select('state', 'invoicePresenter', 'groupInvoiceDetails').set({});
    } else {
        clearInvoicePrintPreview();
        const invoices: GroupInvoiceDetails = await getReservationGroupInvoicePreview(reservationGroupUuid);
        baobab.root.select('state', 'invoicePresenter', 'groupInvoiceDetails').set(invoices);
    }
};

export {
    loadInvoiceDataForPrintView,
    setInvoiceProperty,
    stornoInvoiceByIdAndWithReclamationNote,
    fiscalizeConcludedInvoice,
    loadInvoicePreview,
    clearInvoicePrintPreview,
    getIssuedStornoInvoice,
    loadGroupInvoiceDetails,
};
