import React from 'react';

const InstallationStep2 = ({ nextStepCallback, rooms, setRooms }) => {
    const [floor, setFloor] = React.useState('');
    const [roomsNum, setRoomsNum] = React.useState({});
    const [floorsNum, setFloorsNum] = React.useState(0);
    const [floorsArray, setFloorsArray] = React.useState([]);
    const [confirmSetup, setConfirmSetup] = React.useState(false);
    const [dataChange, setDataChange] = React.useState(false);

    React.useEffect(() => {}, [dataChange]);

    const saveFormData = () => {
        let _rooms = rooms;
        Object.keys(roomsNum).forEach((r) => {
            let roomsArr = [],
                startRoom = Number(r + 0 + 1);
            for (let i = 0; i < Number(roomsNum[r]); i++) {
                roomsArr.push({ name: startRoom, bedCount: 1, spareBedCount: 0 });
                startRoom++;
            }
            _rooms[r] = roomsArr;
        });
        setRooms(rooms);
        setConfirmSetup(true);
    };

    const handleRoomsName = (room, floor, e) => {
        rooms[floor][room]['name'] = e.target.value;
        setRooms(rooms);
        setDataChange(!dataChange);
    };

    const handleBedsCount = (room, floor, e) => {
        if (e.target.name === 'bedCount') {
            rooms[floor][room]['bedCount'] = Number(e.target.value);
        } else if (e.target.name === 'spareBedCount') {
            rooms[floor][room]['spareBedCount'] = Number(e.target.value);
        }
        setDataChange(!dataChange);
    };

    return (
        <div className=" bg-white  ">
            <div className="text-center">
                <b>{window.translate('Initial setup required')}</b>
                <hr />
            </div>
            {!confirmSetup ? (
                <>
                    <div className="p-3">
                        <div className="row mt-2 text-center">
                            <div className="col-md-3">
                                {' '}
                                <label htmlFor="rooms">
                                    {window.translate('Does your hotel have multiple floors or buildings?')}
                                </label>{' '}
                            </div>
                            <div className="col-md-3">
                                <select
                                    value={floor}
                                    onChange={(e) => {
                                        setFloor(e.target.value);
                                        if (e.target.value !== 'Da') {
                                            setRoomsNum({});
                                            setFloorsArray([]);
                                        }
                                    }}
                                    className="custom-select my-1 mr-sm-2 "
                                    id="rooms"
                                >
                                    <option value="">{window.translate('Nothing selected')}</option>
                                    <option value="Da">{window.translate('Yes')}</option>
                                    <option value="Ne">{window.translate('No')}</option>
                                </select>
                            </div>
                        </div>
                        {floor === 'Da' ? (
                            <div className="row mt-2 text-center">
                                <div className="col-md-3">
                                    {' '}
                                    <label htmlFor="floorNum">{window.translate('Number of floors')}:</label>{' '}
                                </div>
                                <div className="col-md-3">
                                    <input
                                        autoComplete="off"
                                        name="floorNum"
                                        value={floorsNum}
                                        onChange={(e) => {
                                            setFloorsNum(Number(e.target.value));
                                            let _floorsArr = [];
                                            for (let i = 1; i <= Number(e.target.value); i++) {
                                                _floorsArr.push(i);
                                            }
                                            setFloorsArray(_floorsArr);
                                        }}
                                        type="number"
                                        min={1}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        ) : null}
                        {floorsArray.length > 0 ? (
                            floorsArray.map((f, index) => {
                                return (
                                    <div key={index} className="row mt-2 text-center">
                                        <div className="col-md-3">
                                            {' '}
                                            <label htmlFor="roomsNum">
                                                {window.translate('Number of rooms ')}
                                                {f}. {window.translate('floor')}:
                                            </label>{' '}
                                        </div>
                                        <div className="col-md-3">
                                            <input
                                                autoComplete="off"
                                                name="roomsNum"
                                                value={roomsNum[f]}
                                                onChange={(e) => {
                                                    let _roomsNum = roomsNum;
                                                    _roomsNum[f] = Number(e.target.value);
                                                    setRoomsNum(_roomsNum);
                                                }}
                                                type="number"
                                                min={0}
                                                className="form-control"
                                            />
                                        </div>
                                    </div>
                                );
                            })
                        ) : (
                            <div className="row mt-2 text-center">
                                <div className="col-md-3">
                                    {' '}
                                    <label htmlFor="roomsNum">{window.translate('Number of rooms ')}</label>{' '}
                                </div>
                                <div className="col-md-3">
                                    <input
                                        autoComplete="off"
                                        name="roomsNum"
                                        value={roomsNum[1]}
                                        onChange={(e) => {
                                            let _roomsNum = roomsNum;
                                            _roomsNum[1] = Number(e.target.value);
                                            setRoomsNum(_roomsNum);
                                        }}
                                        type="number"
                                        min={0}
                                        className="form-control"
                                    />
                                </div>
                            </div>
                        )}
                    </div>

                    <div className="text-center p-5">
                        {' '}
                        <div
                            onClick={() => {
                                if (roomsNum && roomsNum[1]) {
                                    saveFormData();
                                } else {
                                    alert('Please enter rooms number!');
                                }
                            }}
                            className="btn btn-outline-primary mb-4 pointer"
                        >
                            {window.translate('Confirm')}
                        </div>{' '}
                    </div>
                </>
            ) : (
                <RoomsAndFloorsSetup
                    rooms={rooms}
                    handleRoomsName={handleRoomsName}
                    nextStepCallback={nextStepCallback}
                    handleBedsCount={handleBedsCount}
                />
            )}
        </div>
    );
};

const RoomsAndFloorsSetup = ({ rooms, handleRoomsName, nextStepCallback, handleBedsCount }) => {
    const roomsAndFloors = Object.keys(rooms).map((floor, key) => {
        return (
            <div key={key} className="m-2">
                <div className="text-center mt-1 mb-2">
                    <b>
                        {floor}. {window.translate('floor')}
                    </b>
                </div>
                {rooms[floor].map((r, index) => {
                    return (
                        <div key={index} className="col-md-2 m-2 d-inline-block">
                            <div className="m-1 p-1">
                                <div className="row border shadow-sm py-2">
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <small className="col-md-6">{window.translate('Name')}:</small>{' '}
                                        <input
                                            autoComplete="off"
                                            value={r.name}
                                            onChange={handleRoomsName.bind(this, index, floor)}
                                            type="text"
                                            className="form-control form-control-sm col-6 my-1"
                                        />
                                    </div>
                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <small className="col-md-6">{window.translate('Bed count')}:</small>{' '}
                                        <input
                                            name="bedCount"
                                            min={1}
                                            onChange={handleBedsCount.bind(this, index, floor)}
                                            value={r.bedCount}
                                            type="number"
                                            className="form-control form-control-sm col-6 my-1"
                                        />
                                    </div>

                                    <div className="col-12 d-flex align-items-center justify-content-between">
                                        <small className="col-md-6">{window.translate('Spare beds')}:</small>{' '}
                                        <input
                                            name="spareBedCount"
                                            min={0}
                                            onChange={handleBedsCount.bind(this, index, floor)}
                                            value={r.spareBedCount}
                                            type="number"
                                            className="form-control form-control-sm col-6 my-1"
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                    );
                })}
                <hr />
            </div>
        );
    });
    return (
        <div>
            <div className="text-center">
                {window.translate('Accept or change rooms name, bed and spare bed count')}
                <hr />
            </div>
            {roomsAndFloors}
            <div className="p-5 text-center">
                {' '}
                <div
                    onClick={async (e) => {
                        e.preventDefault();
                        window.location = '/#/firstTimeSetup?step3';
                        nextStepCallback();
                    }}
                    className="btn btn-outline-primary mb-4 pointer"
                >
                    {window.translate('SAVE AND NEXT')}
                </div>{' '}
            </div>
        </div>
    );
};

export default InstallationStep2;
