import React, { useState, useEffect } from 'react';

export const DateTime = () => {
    const [date, setDate] = useState(new Date());

    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 1000); // funkcija koja se izvrsava svake sekunde i seta datum i vrijeme
        //setInterval inace prima dva parametra, (ono sto ce se izvrsiti, nakon koliko vremena ce izvrsavati)
        return function cleanup() {
            clearInterval(timer); //id vrijednost koja se dobije iz setInterval funckije se koristi ovdje za stopiranje intervala
            //funkcija je potrebna kad imamo interval koji moze trajati beskonacno dugo kao ovdje
            //s ovom funkcijom stopiramo setInterval, te ce se ona izvrsavati kako je i zadano,
            //a bez stopiranja izmedju svake sekunde bi jos par puta setala isti datum, sto bi nekad moglo zaustaviti app
        };
    });

    return <> {date.toLocaleDateString() + ' ' + date.toLocaleTimeString('en-GB')}</>;
};

export default DateTime;