import React, { useState, useEffect } from 'react';
import { branch } from 'baobab-react/higher-order';
import _ from 'lodash';
import PageTitle from '../../components/pageTitle';
import rest from '../../data/restWrapper';
import numbro from 'numbro';

import InlineModal from '../../components/modalInline';
import pubsub from '../../data/pubsub';
import FiscalBroadcastStatusMonitor from '../fiscalPrinterTools/broadcastStatusMonitor';

const PricelistLookup = (props) => {
    const [priceListLookup, setPriceListLookup] = useState(null);
    const [showModal, setShowModal] = useState(false);

    useEffect(() => {
        const load = async () => {
            const _priceListLookup = await rest('/getPriceListLookup');
            setPriceListLookup(_priceListLookup);
        };

        load().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [showModal]);



    const renderPosItemsLookup = (defaultCurrency) => {
        const posItemsLookup = priceListLookup ? priceListLookup.posItemsLookup : [];
        let posItemsLookupTable = posItemsLookup.map((item, key) => {
            return (
                <div className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'} key={item.plu + key}>
                    <div className="col-2">{item.plu}</div>
                    <div className="col-4">{item.name}</div>
                    <div className="col-3">
                        {numbro(item.nettoPrice).format({ mantissa: 2 })} {defaultCurrency}
                    </div>
                    <div className="col-3">
                        {numbro(item.bruttoPrice).format({ mantissa: 2 })} {defaultCurrency}
                    </div>
                </div>
            );
        });
        return posItemsLookupTable;
    };

    const updatePOSPriceListLookup = () => {
        setShowModal(true);
        pubsub.emit('broadcastFiscalOperation', '/uploadPricelistLookupIntoPOS', {});
    };

    const { defaultCurrency, SRCProxies } = props;
    const fiscalSRCProxies =
        SRCProxies &&
        SRCProxies.filter((src) => {
            return src.fiscalProxy;
        });

    return (
        <div className="">
            <InlineModal show={showModal}>
                <div className="mt-3">
                    <FiscalBroadcastStatusMonitor />
                    <div className="text-center mt-3">
                        <button onClick={() => setShowModal(false)} className="btn btn-sm btn-info">
                            {window.translate('Close')}
                        </button>
                    </div>
                </div>
            </InlineModal>

            <PageTitle />
            <div className="row mt-3 align-items-center">
                <div className="col-md-12">
                    <button
                        onClick={() => {
                            props.history.push('/menu/system');
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {window.translate('GO BACK')}
                    </button>
                    {!_.isEmpty(fiscalSRCProxies) ? (
                        <button onClick={updatePOSPriceListLookup.bind(this)} className="btn btn-sm btn-primary ml-2">
                            {window.translate('Update fiscal memory')}
                        </button>
                    ) : (
                        ''
                    )}
                </div>
            </div>
            <div className="list-group mt-3 mx-3">
                <small>
                    <div className="px-1 py-2 row shadow-sm bg-white">
                        <div className="col-2">{window.translate('Id')}</div>
                        <div className="col-4">{window.translate('Name')}</div>
                        <div className="col-3">{window.translate('Neto price')}</div>
                        <div className="col-3">{window.translate('Bruto price')}</div>
                    </div>
                </small>
            </div>
            <div className="list-group mt-1 mx-3">
                {renderPosItemsLookup(defaultCurrency)}
            </div>
        </div>
    );
};

export default branch(
    {
        defaultCurrency: ['defaultCurrency'],
        SRCProxies: ['model', 'SRCProxy'],
    },
    PricelistLookup
);
