import {
    GroupReservationModel,
    GroupReservationStatus,
    ReservationModel,
    ReservationInvoicedStatus,
} from '@common/modelDefinition';
import baobab from '@data/state/index';
import { Reservation, GroupReservation } from '@pages/reservations2/resController';
import { getGroupRes, saveOrUpdateReservation, cancelGroupRes, saveGroupRes } from './api';
import { v4 as uuidv4 } from 'uuid';

interface GroupReservationInterface {
    getGroupReservations(reservationUuid: string | null): Promise<GroupReservation[]>;
    setGroupReservations(groupRes: GroupReservation, reservationdId: number): void;
    cancelGroupReservation(groupReservation: GroupReservation): void;
    createNewGroupRes(reservation: ReservationModel): void; // kada želimo jednu rezervaciju konvertat u grupnu
}

class GroupReservationController implements GroupReservationInterface {
    async getGroupReservations(reservationUuid: string | null): Promise<GroupReservation[]> {
        const groupRes = await getGroupRes(reservationUuid);
        return groupRes;
    }

    //ovo mozda promijeniti da gleda u resController tako da nemam ovdje safesaveRes jer se ova funkcija koristi kad iz forme idemo na attach to group
    async setGroupReservations(groupRes: GroupReservation, reservationdId: number) {
        const reservations = baobab.root
            .select(['state', 'reservationForm2', 'reservations'])
            .get()
            .map((r: Reservation) => {
                return { ...r }; //break baobab reference
            });
        const target = reservations.find((r: Reservation) => r.id === reservationdId);
        if (target) {
            target.groupUuid = groupRes.uuid;
            baobab.root.select(['state', 'reservationForm2', 'reservations']).set(reservations);
            await saveOrUpdateReservation(target);
        }
    }

    async createNewGroupRes(reservation: ReservationModel) {
        const uuid = uuidv4();
        const res = { ...reservation };
        res.groupUuid = uuid;
        const groupRes: GroupReservationModel = {
            uuid: uuid,
            checkOutTimestamp: res.checkOutTimestamp,
            checkInTimestamp: res.checkInTimestamp,
            invoicedStatus: ReservationInvoicedStatus.notPaid,
            customerId: res.customerId,
            companyId: res.companyId,
            status: GroupReservationStatus.active,
            receptionistUuid: baobab.root.select(['authTokenInfo', 'user', 'uuid']).get(),
            groupName: uuid
        };
        await saveGroupRes([res], groupRes);
    }

    async cancelGroupReservation(groupReservation: GroupReservation) {
        const {
            uuid,
            note,
            discountPercentage,
            invoicedStatus,
            customerId,
            companyId,
            receptionistUuid,
            checkInTimestamp,
            checkOutTimestamp,
            Reservations: reservations,
        } = groupReservation;

        const groupCover = {
            uuid,
            note,
            discountPercentage,
            status: 0, //closed - canceled
            invoicedStatus,
            customerId,
            companyId,
            receptionistUuid,
            checkInTimestamp,
            checkOutTimestamp,
        };
        if (reservations) {
            await cancelGroupRes(reservations, groupCover);
        }
    }
}

export { GroupReservationController };
