import React from 'react';
import { loadInvoicePreview, checkoutReservation, restorePreviousCheckoutDate } from './controler';
import { StayoverOverlapSelection } from './ReservationActions';
import { getQuery } from '../../reservations2/utils';
import { CheckoutDateMissmatch } from './CheckoutOptions';
import moment from 'moment';
import { CheckoutOptionsProps,ConfirmationModalProps } from './interfaces';
import {
    PriceOffer,
    ReservationEditModel,
    ReservationModelWithModelsIncluded,
    RoomInfoEditModel,
} from '@common/modelDefinition';
import { showConfirmationModal } from '@components/modal';

import { SRCClearRoomCodes } from '@components/SRCClearRoomCodes';

const GroupReservationActions = (props: CheckoutOptionsProps) => {
    const { history, translate, groupReservationsArray, stayoverOverlap, location , reservationId, reservationGroupUuid, roomInfoMap} = props;

    if (groupReservationsArray.length === 0) {
        return null;
    }

    const query: any = getQuery(history.location);
    const _reservationId: number = Number(query.reservationId);
    const conflictedReservation: ReservationEditModel | null =
        stayoverOverlap.overlapReservations.length > 0 ? stayoverOverlap.overlapReservations[0] : null;
    return (
        <div>
            <b>{translate('Reservations from the group')}</b>
            <ul className="list-group text-left">
                {groupReservationsArray.map((r: ReservationModelWithModelsIncluded, index) => {

                    const room: RoomInfoEditModel | null = roomInfoMap?roomInfoMap[r.roomInfoId]:null
                    const roomName: string = room ? room.name : '';
                    const { rememberedAccommodationPriceInfo } = r;
                    const lastOffer: PriceOffer | null =
                        rememberedAccommodationPriceInfo && rememberedAccommodationPriceInfo.length > 0
                            ? rememberedAccommodationPriceInfo[rememberedAccommodationPriceInfo.length - 1]
                            : null;
                    const mismatchDateApplied: boolean =
                        lastOffer && !moment(r.checkOutTimestamp).isSame(lastOffer.checkOutTimestamp, 'day')
                            ? true
                            : false;
                    let reservationHolder: string = `${r.Customer?.firstName} ${r.Customer?.lastName} ${r.Company?.name}`;
                    if (r.Company && r.Customer) {
                        reservationHolder = `${r.Customer?.firstName} ${r.Customer?.lastName} ${r.Company?.name}`;
                    } else if (r.Company) {
                        reservationHolder = `${r.Company?.name}`;
                    } else if (r.Customer) {
                        reservationHolder = `${r.Customer?.firstName} ${r.Customer?.lastName} `;
                    }

                    return (
                        <React.Fragment key={index}>
                            <li key={index} className="list-group-item">
                                <CheckoutDateMissmatch {...props} reservation={r}>
                                    {' '}
                                    <button
                                        className="btn btn-sm btn-outline-primary mr-2"
                                        onClick={() => {
                                            history.push(`/reservations/${r.id}/edit`);
                                        }}
                                    >
                                        {roomName ? roomName : r.roomInfoId}
                                    </button>
                                    {r.id === _reservationId && (
                                        <span
                                            className="spinner-grow spinner-grow-sm"
                                            role="status"
                                            aria-hidden="true"
                                        ></span>
                                    )}
                                    <button
                                        onClick={() => {
                                            loadInvoicePreview(r.id);
                                        }}
                                        className="btn btn-sm btn-secondary uppercase pull-right ml-2"
                                    >
                                        <i className="fa fa-print" />
                                    </button>
                                    <button
                                        disabled={
                                            r.statusEnum !== 2 /* ||
                                            (conflictedReservation && conflictedReservation.roomInfoId !== r.roomInfoId)*/
                                                ? true
                                                : false
                                        }
                                        className="btn btn-sm btn-info uppercase pull-right ml-2"
                                        onClick={() => {
                                            const confirmationModalProps:ConfirmationModalProps={
                                                title:'Confirm checkout',
                                                callback:() => {
                                                    checkoutReservation(r,reservationId, reservationGroupUuid, location)
                                                }
                                            }
                                            showConfirmationModal(confirmationModalProps);
                                        }}
                                    >
                                        {translate('Checkout')}
                                    </button>
                                    {mismatchDateApplied &&
                                        r.statusEnum === 2 && lastOffer ?
                                        moment(r.checkOutTimestamp).isSame(moment().valueOf(), 'day') && (
                                            <button
                                                className="btn btn-sm btn-light uppercase pull-right ml-2"
                                                onClick={() => {
                                                    const confirmationModalProps:ConfirmationModalProps={
                                                        title:'Previous checkout date',
                                                        callback:() => {
                                                            restorePreviousCheckoutDate(r, location,reservationId, reservationGroupUuid);
                                                        }
                                                    }
                                                    showConfirmationModal(confirmationModalProps);
                                                }}
                                            >
                                                {/* {window.translate('Previous checkout date')} */}
                                                <small>
                                                    <small className="text-danger mr-2">
                                                        {translate('Previous checkout date')}
                                                    </small>
                                                    {moment(lastOffer.checkOutTimestamp).format('ll')}
                                                </small>
                                            </button>
                                        ):null}
                                    {reservationHolder}
                                    {r.statusEnum === 4 && room && (
                                        <SRCClearRoomCodes roomInfo={room}/>
                                    )}
                                </CheckoutDateMissmatch>
                            </li>
                            {conflictedReservation && conflictedReservation.roomInfoId === r.roomInfoId && (
                                <StayoverOverlapSelection {...props} />
                            )}
                        </React.Fragment>
                    );
                })}
            </ul>
        </div>
    );
};

export default GroupReservationActions;
