import React, { Component } from 'react';
import PageTitle from '../../components/pageTitle';
import _ from 'lodash';
import moment from 'moment';

import TimePicker from 'rc-time-picker';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import InputRange from 'react-input-range';
import { broadcastCardCodeWithValidToTime, broadcastGeneralSettings } from './api';
import { startGlobalLoading, stopGlobalLoading } from '../../components/loaders/globalLoading';
import { toast } from 'react-toastify';

function BathroomThermostat(props) {
    const [settings, setSetttings] = React.useState({ bathroomTargetTemperature: 23 });

    return (
        <div className="bg-white border p-4 rounded mt-2 pb-5 col-md-6">
            <h4 className="mb-3">{window.translate('BATHROOM HEATING')}</h4>
            {window.translate('Target temperature (°C)')}:
            <input
                onChange={(e) => {
                    const set = { ...settings };
                    set.bathroomTargetTemperature = Number(e.target.value);
                    setSetttings(set);
                }}
                name="bathroomTargetTemperature"
                value={settings.bathroomTargetTemperature}
                className="form-control form-control-sm"
                type="number"
                min={5}
                max={30}
            />
            <div className="mt-5">
                <button
                    onClick={async () => {
                        try {
                            await broadcastGeneralSettings(
                                'bathroomTargetTemperature',
                                settings.bathroomTargetTemperature
                            );
                            toast('Broadcast success!', {
                                position: toast.POSITION.BOTTOM_RIGHT,
                                type: toast.TYPE.SUCCESS,
                            });
                        } catch (err) {
                            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
                        }

                        //alert('Done!');
                    }}
                    className="btn btn-sm btn-primary mr-2"
                >
                    <i className="fa fa-rss" /> BROADCAST
                </button>
            </div>
        </div>
    );
}

class Thermostat extends Component {
    constructor(props) {
        super(props);
        this.state = {
            ECO: {
                min: 6,
                max: 30,
            },
            PRECOMFORT: {
                min: 14,
                max: 28,
            },
            COMFORT: {
                min: 18,
                max: 26,
            },
            defaultTemperature: 21,
            thermostatMode: 'heating',
        };
    }
    handleRangeChange(mode, value) {
        let state = _.clone(this.state);
        state[mode] = value;
        this.setState(state);
    }
    handleSelectionChange(value) {
        this.setState({ defaultTemperature: value });
    }
    async broadcast() {
        //let payload = {generalSettingsParamEnum: keys[i], value: this.state.data[keys[i]]};
        let payloads = [];
        payloads.push({ generalSettingsParamEnum: 'maxECO_' + this.props.sufix, value: this.state.ECO.max });
        payloads.push({ generalSettingsParamEnum: 'minECO_' + this.props.sufix, value: this.state.ECO.min });
        payloads.push({
            generalSettingsParamEnum: 'maxPRECOMFORT_' + this.props.sufix,
            value: this.state.PRECOMFORT.max,
        });
        payloads.push({
            generalSettingsParamEnum: 'minPRECOMFORT_' + this.props.sufix,
            value: this.state.PRECOMFORT.min,
        });
        payloads.push({ generalSettingsParamEnum: 'maxCOMFORT_' + this.props.sufix, value: this.state.COMFORT.max });
        payloads.push({ generalSettingsParamEnum: 'minCOMFORT_' + this.props.sufix, value: this.state.COMFORT.min });
        payloads.push({
            generalSettingsParamEnum: 'defaultThermostatTemperature_' + this.props.sufix,
            value: this.state.defaultTemperature,
        });
        payloads.push({
            generalSettingsParamEnum: 'thermostatMode_' + this.props.sufix,
            value: this.state.thermostatMode,
        });

        startGlobalLoading();
        try {
            for (let i = 0; i < payloads.length; i++) {
                //console.log("---->",payloads[i]);
                const { generalSettingsParamEnum, value } = payloads[i];
                await broadcastGeneralSettings(generalSettingsParamEnum, value);
            }
            toast('Broadcast success!', { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
        } catch (err) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
        stopGlobalLoading();
    }
    modeSelectHandler(e) {
        let state = _.clone(this.state);
        state.thermostatMode = e.target.value;
        this.setState(state);
    }
    render() {
        return (
            <div className="bg-white border p-4 rounded mt-2 pb-5 col-md-6">
                <h4 className="mb-3">ECO</h4>
                <InputRange
                    maxValue={35}
                    minValue={5}
                    value={{ min: this.state.ECO.min, max: this.state.ECO.max }}
                    onChange={this.handleRangeChange.bind(this, 'ECO')}
                />

                <h4 className="mt-3 mb-3">PRE COMFORT</h4>
                <InputRange
                    maxValue={35}
                    minValue={5}
                    value={{ min: this.state.PRECOMFORT.min, max: this.state.PRECOMFORT.max }}
                    onChange={this.handleRangeChange.bind(this, 'PRECOMFORT')}
                />

                <h4 className="mt-3 mb-3">COMFORT</h4>
                <InputRange
                    maxValue={35}
                    minValue={5}
                    value={{ min: this.state.COMFORT.min, max: this.state.COMFORT.max }}
                    onChange={this.handleRangeChange.bind(this, 'COMFORT')}
                />

                <h4 className="mt-3 mb-3">DEFAULT TEMPERATURE</h4>
                <InputRange
                    maxValue={this.state.COMFORT.max}
                    minValue={this.state.COMFORT.min}
                    value={this.state.defaultTemperature}
                    onChange={this.handleSelectionChange.bind(this)}
                />

                <h4 className="mt-3 mb-3"> {window.translate('MODE:')}</h4>
                <div className="col-md-6">
                    <select
                        onChange={this.modeSelectHandler.bind(this)}
                        name={'thermostatMode'}
                        value={this.state.thermostatMode}
                        className="form-control form-control-sm"
                    >
                        <option value={0}>{window.translate('HEATING')}</option>
                        <option value={1}>{window.translate('COOLING')}</option>
                        <option value={2}>{window.translate('Automatic changeover')}</option>
                        <option value={3}>{window.translate('DISABLED')}</option>
                    </select>
                </div>

                <div className="mt-5">
                    <button onClick={this.broadcast.bind(this)} className="btn btn-sm btn-primary mr-2">
                        <i className="fa fa-rss" /> BROADCAST
                    </button>
                </div>
            </div>
        );
    }
}

class CardCode extends Component {
    constructor(props) {
        super(props);
        this.state = {
            code: 0,
            validToTimestamp: Date.now(),
        };
    }
    onCodeChange(e) {
        let code = e.target.value ? parseInt(e.target.value, 10) : 0;
        this.setState({ code });
    }

    handleTimeChange(e) {
        let validToTimestamp = e.valueOf();
        this.setState({ validToTimestamp });
    }

    handleDateChange(e) {
        let validToTimestamp = e.getTime();
        this.setState({ validToTimestamp });
    }

    handleDateTimeSet(targetTimestamp) {
        let validToTimestamp = targetTimestamp;
        this.setState({ validToTimestamp });
    }

    async broadcast() {
        try {
            await broadcastCardCodeWithValidToTime(this.state.code, this.state.validToTimestamp, this.props.type);
            toast('Broadcast success!', { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
        } catch (err) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
    }
    render() {
        return (
            <div className="bg-white border p-3 rounded mt-2 col-sm-4">
                {window.translate('Access code:')}&ensp;
                <input
                    onChange={this.onCodeChange.bind(this)}
                    type="text"
                    className="input-text form-control form-control-sm mb-2"
                    value={this.state.code}
                ></input>
                <div className="p1">
                    {window.translate('Expires')}&ensp;
                    <DayPickerInput
                        inputProps={{ className: 'form-control form-control-sm mt-1 mt-md-0', readOnly: true }}
                        placeholder="DD/MM/YYYY"
                        value={new Date(this.state.validToTimestamp).toLocaleDateString()}
                        format="DD/MM/YYYY"
                        className="input-text "
                        onDayChange={this.handleDateChange.bind(this)}
                    />
                    <TimePicker
                        id={this.props.type}
                        allowEmpty={false}
                        style={{ background: 'transparent', textAlign: 'center' }}
                        showSecond={false}
                        value={moment(this.state.validToTimestamp).clone()}
                        className="mt-2 ml-2"
                        onChange={this.handleTimeChange.bind(this)}
                    />
                </div>
                <div className="mt-2">
                    <button onClick={this.broadcast.bind(this)} className="btn btn-sm btn-primary mr-2">
                        <i className="fa fa-rss" /> BROADCAST
                    </button>
                </div>
            </div>
        );
    }
}

function SRCBroadcastMulticast(props) {
    return (
        <div className="">
            <PageTitle />
            <div className="col pl-0 pb-1">
                <button
                    onClick={() => {
                        props.history.push('/menu/system');
                    }}
                    className="btn btn-sm btn-secondary"
                >
                    {window.translate('GO BACK')}
                </button>
            </div>
            <nav>
                <div className="nav nav-tabs" id="nav-tab" role="tablist">
                    <a
                        className="nav-item nav-link active"
                        id="nav-th1-tab"
                        data-toggle="tab"
                        href="#nav-th1"
                        role="tab"
                        aria-controls="nav-th1"
                        aria-selected="true"
                    >
                        {window.translate('Thermostat ')} 1
                    </a>
                    <a
                        className="nav-item nav-link"
                        id="nav-th2-tab"
                        data-toggle="tab"
                        href="#nav-th2"
                        role="tab"
                        aria-controls="nav-th2"
                        aria-selected="false"
                    >
                        {window.translate('Thermostat ')} 2
                    </a>
                    <a
                        className="nav-item nav-link"
                        id="nav-th3-tab"
                        data-toggle="tab"
                        href="#nav-th3"
                        role="tab"
                        aria-controls="nav-th3"
                        aria-selected="false"
                    >
                        {window.translate('Thermostat ')} 3
                    </a>

                    <a
                        className="nav-item nav-link"
                        id="nav-bathroom1-tab"
                        data-toggle="tab"
                        href="#nav-bathroom1"
                        role="tab"
                        aria-controls="nav-bathroom1"
                        aria-selected="false"
                    >
                        Bathroom 1
                    </a>

                    <a
                        className="nav-item nav-link"
                        id="nav-maid1-tab"
                        data-toggle="tab"
                        href="#nav-maid1"
                        role="tab"
                        aria-controls="nav-maid1"
                        aria-selected="false"
                    >
                        Maid 1
                    </a>
                    <a
                        className="nav-item nav-link"
                        id="nav-maid2-tab"
                        data-toggle="tab"
                        href="#nav-maid2"
                        role="tab"
                        aria-controls="nav-maid2"
                        aria-selected="false"
                    >
                        Maid 2
                    </a>
                    <a
                        className="nav-item nav-link"
                        id="nav-maid3-tab"
                        data-toggle="tab"
                        href="#nav-maid3"
                        role="tab"
                        aria-controls="nav-maid3"
                        aria-selected="false"
                    >
                        Maid 3
                    </a>
                    <a
                        className="nav-item nav-link"
                        id="nav-manager-tab"
                        data-toggle="tab"
                        href="#nav-manager"
                        role="tab"
                        aria-controls="nav-manager"
                        aria-selected="false"
                    >
                        {window.translate("Access only")}
                    </a>
                    <a
                        className="nav-item nav-link"
                        id="nav-security-tab"
                        data-toggle="tab"
                        href="#nav-security"
                        role="tab"
                        aria-controls="nav-security"
                        aria-selected="false"
                    >
                        {window.translate("Access only")}
                    </a>
                </div>
            </nav>
            <div className="tab-content" id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-th1" role="tabpanel" aria-labelledby="nav-th1-tab">
                    <Thermostat sufix="t1" />
                </div>
                <div className="tab-pane fade" id="nav-th2" role="tabpanel" aria-labelledby="nav-th2-tab">
                    <Thermostat sufix="t2" />
                </div>
                <div className="tab-pane fade" id="nav-th3" role="tabpanel" aria-labelledby="nav-th3-tab">
                    <Thermostat sufix="t3" />
                </div>

                <div className="tab-pane fade" id="nav-bathroom1" role="tabpanel" aria-labelledby="nav-bathroom1-tab">
                    <BathroomThermostat />
                </div>

                <div className="tab-pane fade" id="nav-maid1" role="tabpanel" aria-labelledby="nav-maid1-tab">
                    <CardCode type="maid1" />
                </div>
                <div className="tab-pane fade" id="nav-maid2" role="tabpanel" aria-labelledby="nav-maid2-tab">
                    <CardCode type="maid2" />
                </div>
                <div className="tab-pane fade" id="nav-maid3" role="tabpanel" aria-labelledby="nav-maid3-tab">
                    <CardCode type="maid3" />
                </div>
                <div className="tab-pane fade" id="nav-manager" role="tabpanel" aria-labelledby="nav-manager-tab">
                    <CardCode type="manager" />
                </div>
                <div className="tab-pane fade" id="nav-security" role="tabpanel" aria-labelledby="nav-security-tab">
                    <CardCode type="security" />
                </div>
            </div>
        </div>
    );
}

export default SRCBroadcastMulticast;