import { RoleWithPermissions, PermissionModel } from '@common/modelDefinition';
import baobab from '@data/state/index';
import {
    getRolePermissions as _getRolePermissions,
    getPermissions as _getPermissions,
    updateRolePermission as _updateRolePermission,
    saveOrUpdateRole as _saveOrUpdateRole,
    deleteRole as _deleteRole,
} from './api';

const getRolePermissions = async (role: string) => {
    const rolePermissions = await _getRolePermissions(role);
    baobab.root.select(['state', 'rolePermissions']).set(rolePermissions);
};

const getPermissions = async () => {
    const permissions = await _getPermissions();
    baobab.root.select(['state', 'permissions']).set(permissions);
};

const updateRolePermission = async (roleName: string, permissionId: number) => {
    const updatedPermissions = await _updateRolePermission(roleName, permissionId);
    baobab.root.select(['state', 'rolePermissions']).set(updatedPermissions);
};

const getRolePermissionIds = (roleWithPermissions: RoleWithPermissions) => {
    const rolePermissions: PermissionModel[] = roleWithPermissions.Permissions;
    const rolePermitedIds: number[] = [];
    for (const permission of rolePermissions) {
        if (permission.id) {
            rolePermitedIds.push(permission.id);
        }
    }
    return rolePermitedIds;
};

const getChildesOfPanel = (permissions: PermissionModel[], rolePermitedIds: number[]) => {
    const availableChilds: string[] = [];
    const childes: string[] = [];
    const checkedPanels: string[] = [];
    for (const permission of permissions) {
        const name = permission.name.split(/[.]/)[2];
        const isChecked = permission.id && rolePermitedIds.includes(permission.id) ? true : false;
        const panel = !name.includes('_') ? name : null;
        const child = name.includes('_') ? name : null;
        const isPanelCheckedName = panel && isChecked ? panel : null;

        if (isPanelCheckedName) {
            checkedPanels.push(isPanelCheckedName);
        }
        if (child) {
            childes.push(child);
        }
    }
    for (const child of childes) {
        const splitedChild = child.split(/[_]/)[0];
        if (checkedPanels.includes(splitedChild)) {
            availableChilds.push(child);
        }
    }

    return availableChilds;
};

const saveOrUpdateRole = async (role: RoleWithPermissions) => {
    const updatedRole = await _saveOrUpdateRole(role);
    baobab.root.select(['state', 'rolePermissions']).set(updatedRole);
};


const deleteRole = async (roleKey:string)=> {
    await _deleteRole(roleKey)
}

export {
    getRolePermissions,
    getPermissions,
    updateRolePermission,
    getRolePermissionIds,
    getChildesOfPanel,
    saveOrUpdateRole,
    deleteRole
};
