import React, { Component } from 'react';
import moment from 'moment/min/moment-with-locales';
import { branch } from 'baobab-react/higher-order';

class Foother extends Component {
    constructor() {
        super();
        this.timeout = null;
        this.state = {
            date: new Date(),
        };
    }
    componentDidMount() {
        this.timeout = setInterval(() => {
            this.setState({ date: new Date() });
        }, 50 * 1000);
    }

    componentWillUnmount() {
        clearTimeout(this.timeout);
    }
    render() {
        let { version = 'xxx' } = this.props.codeVersion;

        return (
            <nav id="main-footer" className="d-flex unselectable flex-row navbar fixed-bottom  border-top shadow bg-white text-secondary">
                <div
                    onClick={() => {
                        this.props.history.push('/userProfile');
                    }}
                    className="align-self-start px-1 pointer hover-background-dark"
                >
                    <small><span></span>{this.props.loggedUser.email} <i className='fa fa-info-circle px-1'></i></small>
                </div>
                <div className="align-self-end">
                    <small>
                        <b>{this.props.pageTitle}</b>
                    </small>
                    <small className="d-none d-md-inline">&ensp;|&ensp;{moment(this.state.date).format('LLL')}</small>
                    <small className="d-none d-lg-inline">&ensp;|&ensp;{version}</small>
                </div>
            </nav>
        );
    }
}

export default branch(
    {
        pageTitle: ['pageTitle'],
        codeVersion: ['version'],
        loggedUser: ['authTokenInfo', 'user'],
    },
    Foother
);
