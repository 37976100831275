import React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { Reservation } from '../resController';
import translate from '@data/translations';
import { ReservationStatus } from '@common/modelDefinition';
import { condoStatusCheck } from '../utils';
import { BasicSRCSettingsButton } from './ActionsToolbar';


interface CondoOwnerProps {
    history: any;
    reservation: Reservation;
    condoOwnerMap: any;
    condoOwnerReceptionistUuidMap: any;
    children: any;
}

function CondoOwnerRestrictionWrapper(props: CondoOwnerProps) {
    const { history, reservation, children } = props;
    const { isReservationMadeByOwner, isReservationMadeForOwner } = condoStatusCheck(reservation);
    if (isReservationMadeForOwner) {
        //ovdje ponudi opciju manualnog checkina od recpecionara
        return 'isReservationMadeForOwner to do!!!!';
    }
    const minibarConsumption =reservation.MinibarLogs ? reservation.MinibarLogs.filter((ml: any) => !ml.InvoiceItem): [];
    if (isReservationMadeByOwner && minibarConsumption.length===0) {
        return (
            <div className="text-center">
                <h3 className="text-center mt-5">{translate('Reservation managed by aparment owner!')}</h3>
                <p>
                    {translate('Reservation status')}: {translate(ReservationStatus[reservation.statusEnum])}
                </p>

                {reservation.statusEnum === ReservationStatus.checkedIn && (
                    <div>
                        <BasicSRCSettingsButton reservation={reservation} room={reservation.RoomInfo} />
                        {!reservation.guest1CodeCopy ? (
                            <div className="text-danger">{translate('Room is NOT encoded with condo owner card code!')}</div>
                        ) : null}
                    </div>
                )}

                <br />

                <button
                    className="mr-2 btn btn-sm btn-secondary"
                    onClick={() => {
                        history.goBack();
                    }}
                >
                    {translate('GO BACK')}
                </button>
                <button
                    className="mr-2  btn btn-sm btn-primary"
                    onClick={() => {
                        history.push(`/checkoutAndInvoice/${reservation.id}`);
                    }}
                >
                    {translate('CHECKOUT')}
                </button>
            </div>
        );
    } else {
        return <React.Fragment>{children}</React.Fragment>;
    }
}

//registered props are required to trigger component update when monkeys are updated
export default branch(
    {
        condoOwnerMap: ['monkeys', 'condoOwnerMap'],
        condoOwnerReceptionistUuidMap: ['monkeys', 'condoOwnerReceptionistUuidMap'],
    },
    CondoOwnerRestrictionWrapper
);