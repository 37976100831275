import React, { Component } from 'react';
import _ from 'lodash';
import ReportToolbar from '../reportingToolbar';
import rest from '../../data/restWrapper';
import PageLoader from '../../components/loaders/loader1';
import moment from 'moment/min/moment-with-locales';

class DoorAPLogs extends Component {
    constructor() {
        super();
        this.state = {
            logs: null,
            viewType: 'tiles',
            searchText: '',
            invoices: null,
            from: new Date(moment().subtract(7, 'days').startOf('day')), //new Date(new Date().getFullYear(), new Date().getMonth()-1, new Date().getDate()),
            to: new Date(),
        };
    }
    async componentDidMount() {
        //await rest("")
        let logs = await rest(
            '/getDoorAccessLogs/' +
                this.props.match.params.doorControlPointId +
                '?from=' +
                this.state.from.getTime() +
                '&to=' +
                this.state.to.getTime()
        );
        this.setState({ logs });
    }

    async componentDidUpdate(prevProps, prevState) {
        //this.props.match.params.customerId
        if (prevProps.match) {
            if (this.state.from !== prevState.from || this.state.to !== prevState.to) {
                this.setState({ logs: null });
                let logs = await rest(
                    '/getDoorAccessLogs/' +
                        this.props.match.params.doorControlPointId +
                        '?from=' +
                        this.state.from.getTime() +
                        '&to=' +
                        this.state.to.getTime()
                );
                this.setState({ logs });
            }
        }
    }

    handleDateSelection(type, date) {
        //type is "from", "to"
        let obj = {};
        obj[type] = date;
        this.setState(obj);
    }
    onSearch(e) {
        this.setState({ searchText: e.target.value });
    }
    render() {
        if (this.state.logs === null) {
            return <PageLoader />;
        }

        let logs =
            this.state.searchText !== ''
                ? _.filter(this.state.logs, (l) => {
                      return l.note.toLowerCase().lastIndexOf(this.state.searchText) !== -1;
                  })
                : this.state.logs;

        let actionButtons = (
            <button
                onClick={() => {
                    this.props.history.goBack();
                }}
                className="btn btn-sm btn-secondary mr-1 "
            >
                {window.translate("GO BACK")}
            </button>
        );

        return (
            <div>
                <ReportToolbar
                    actionsComponent={actionButtons}
                    {...this.state}
                    handleDateSelection={this.handleDateSelection.bind(this)}
                    onSearch={this.onSearch.bind(this)}
                />

                <div className="animated faster fadeIn">
                    {_.map(logs, (log) => {
                        return (
                            <div key={log.id}>
                                {' '}
                                {log.id}; {moment(log.timestamp).format('lll')}; {log.cardSerialNumber}; {log.note};{' '}
                                {log.rejected ? window.translate("rejected") : window.translate("pass")}
                            </div>
                        );
                    })}
                </div>
            </div>
        );
    }
}

export default DoorAPLogs;
