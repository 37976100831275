import { GroupReservationModel, ReservationModel } from '@common/modelDefinition';
import rest from '@data/restWrapper';
import { GroupReservation } from '@pages/reservations2/resController';

export async function getGroupRes(reservationUuid: string | null): Promise<GroupReservation[]> {
    let groupRes: GroupReservation[] = [];
    if (reservationUuid) {
        const group = await rest(`/getGroupReservations/${reservationUuid}`);
        groupRes.push(group);
    } else {
        groupRes = await rest(`/getGroupReservations`);
    }
    return groupRes;
}

export async function saveOrUpdateReservation(reservation: ReservationModel) {
    return await rest('/safeSaveOrUpdateReservationModel', 'POST', reservation);
}

export async function saveGroupRes(reservations: ReservationModel[], groupCover: GroupReservationModel) {
    return await rest('/transactionalSaveGroupReservations', 'POST', {
        reservations,
        groupCover,
    });
}

export async function cancelGroupRes(reservations: ReservationModel[], groupCover: GroupReservationModel) {
    await rest('/transactionalSaveGroupReservations', 'POST', {
        reservations,
        groupCover,
    });
}
