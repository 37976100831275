import * as React from 'react';

import DayPickerInput from 'react-day-picker/DayPickerInput';
//@ts-ignore
import TimePicker from 'rc-time-picker';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';

interface DateTimeSelectorProps {
    dateTimePickHandler: (timestamp: number) => void;
    disabledDays: any;
    readOnly?: boolean;
    value: number;
}

function DateTimeSelector(props: DateTimeSelectorProps) {
    const { readOnly = false, value, disabledDays = {}, dateTimePickHandler } = props;

    const handleTimeChange = (e: any) => {
        const selection = e.valueOf();
        if (dateTimePickHandler) {
            dateTimePickHandler(selection);
        }
    };

    const handleDateChange = (pick: any) => {
        let selection = pick.getTime();
        let selectionDate = new Date(selection);
        let prevSelectedDate = new Date(value);
        let beginingOfDay = new Date(selectionDate.getFullYear(), selectionDate.getMonth(), selectionDate.getDate());
        let previousBeginingOfDay = new Date(
            prevSelectedDate.getFullYear(),
            prevSelectedDate.getMonth(),
            prevSelectedDate.getDate()
        );
        let prevSelectionTimeMs = prevSelectedDate.getTime() - previousBeginingOfDay.getTime();
        let selectionWithPreservedTime = beginingOfDay.getTime() + prevSelectionTimeMs;
        if (dateTimePickHandler) {
            dateTimePickHandler(selectionWithPreservedTime);
        }
    };

    if (value === undefined) {
        return <div>Missing value timestamp</div>;
    }
    return (
        <div className="row">
            <div className="col-7 ">
                {!readOnly ? (
                    <DayPickerInput
                        format={'ll'}
                        inputProps={{ className: 'form-control form-control-sm', readOnly: true }}
                        formatDate={formatDate}
                        parseDate={parseDate}
                        value={new Date(value)}
                        onDayChange={handleDateChange}
                        dayPickerProps={{
                            disabledDays,
                        }}
                    />
                ) : (
                    moment(value).format('ll')
                )}
            </div>
            <div className="col-3 p-0">
                {!readOnly ? (
                    <TimePicker
                        id={Date.now().toString()}
                        allowEmpty={false}
                        showSecond={false}
                        minuteStep={15}
                        value={moment(value)}
                        onChange={handleTimeChange}
                    />
                ) : (
                    moment(value).format('HH:mm')
                )}
            </div>
        </div>
    );
}

export default DateTimeSelector;
