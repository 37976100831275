import React, { useEffect, useState, useRef } from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
//@ts-ignore
import _ from 'lodash';
import rest from '@data/restWrapper';
import { saveModelData, getSpecificModelRow } from '@data/actions/modelActions';
import FormHeader from '@components/formHeader';
import PageTitle from '@components/pageTitle';
//@ts-ignore
import { toast } from 'react-toastify';
import countryCodes from '@data/countryCodes.json';
import { closeModal } from '@components/modal';
import { CompanyModel } from '@common/modelDefinition';
import translate from '@data/translations';

interface CompanyFormProps {
    match: any;
    companyId?: number | undefined;
    callback?: Function;
    history: any;
    cancelHandler?: Function;
    cancelModal?: boolean;
    fiscalGeneralSettings: any;
    showPageTitle: boolean | undefined;
    customHeader: any;
}

const CompanyForm = (props: CompanyFormProps) => {
    const [validationMessage, setValidationMessage] = useState('');
    const [company, setCompany] = useState({
        name: '',
        idNumber: '0000000000000',
        address: '',
        poNumber: '',
        town: '',
        country: '',
        notes: '',
        web: '',
    } as CompanyModel);
    const name = useRef(null);
    const idNumber = useRef(null);

    useEffect(() => {
        const loadData = async () => {
            let companyId = null;
            if (props.match && props.match.params.companyId) {
                companyId = props.match.params.companyId;
            } else if (props.companyId) {
                companyId = props.companyId;
            }
            if (companyId) {
                let _company = await getSpecificModelRow('Company', companyId);
                _company.params = JSON.parse(_company.params);
                setCompany(_company);
                checkValidationInputs(_company)
            }else{
                checkValidationInputs(company)
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props?.match?.params?.companyId, props?.companyId]);

    const handleInputChange = (e: any) => {
        let _company: any = { ...company };
        _company[e.target.name] = e.target.value;
        setCompany(_company);
        if(e.target.name==="name" || e.target.name==="idNumber"){
            checkValidationInputs(_company)
        }
    };

    const checkValidationInputs = (objToValidate: any) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);
    }

    const markInvalidInputRefs = (validationResults: any) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!classes.includes('is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (classes.includes('is-invalid')) {
                    _.remove(classes, (i: any) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    };

    const getValidationInputs = (data: any) => {
        const invalidInputs = [],
            validInputs = [];
        // clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(name);
        } else {
            validInputs.push(name);
        }
        if (data.idNumber === null || data.idNumber.trim() === '') {
            invalidInputs.push(idNumber);
        } else {
            validInputs.push(idNumber);
        }

        return { invalidInputs, validInputs };
    };

    const saveFormData = async () => {
        try {
            let _company = { ...company };
            _company.params = JSON.stringify(_company.params);
            _company.idNumber = _company.idNumber === '' ? null : _company.idNumber;

            let idNumberRepeats = { count: 0 }; //for null
            if (_company.idNumber !== null) {
                idNumberRepeats = await rest('/checkIfCompanyIdNumberIsUnique/' + _company.id, 'POST', {
                    idNumber: _company.idNumber,
                });
            }

            if (idNumberRepeats.count > 0) {
                throw new Error(
                    translate("Field: '") + translate('VAT or ID number') + translate("' is not unique!!!! ")
                );
            } else {
                const validationResults = getValidationInputs(_company);
                markInvalidInputRefs(validationResults);
                if (validationResults.invalidInputs.length === 0) {
                    saveModelData('Company', company)
                        .then((res) => {
                            if(!company.id){
                                toast(translate("Company created!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
                            }
                            if (props.callback) {
                                props.callback(res);
                            } else {
                                if (props.history) {
                                    props.history.push('/companies');
                                }
                            }
                            cancelhandler();
                        })
                        .catch((err) => {
                            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
                        });
                }
            }
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
    };

    const cancelHandler = () => {
        if (props.cancelHandler) {
            props.cancelHandler();
        } else {
            if (props.history) {
                props.history.push('/companies');
            }
        }
        cancelhandler();
    };

    const cancelhandler = () => {
        setValidationMessage('');
        setCompany({
            name: '',
            idNumber: '',
            address: '',
            poNumber: '',
            town: '',
            country: '',
            notes: '',
            web: '',
        });
        if (props.cancelModal === false) {
        } else {
            closeModal();
        }
    };

    const { fiscalGeneralSettings } = props;
    let companyId = undefined;
    if (props.match && props.match.params.companyId) {
        companyId = props.match.params.companyId;
    } else if (props.companyId) {
        companyId = props.companyId;
    }
    const countryOptions = countryCodes.map((c) => {
        return (
            <option value={c['country-code']} key={c['country-code']}>
                {c.name}
            </option>
        );
    });
    countryOptions.unshift(
        <option key="none" value="">
            {' '}
            -{' '}
        </option>
    );

    return (
        <div className="container-fluid">
            {props.showPageTitle === undefined || props.showPageTitle === true ? <PageTitle /> : null}
            <div className="container bg-white  ">
                <div className="row justify-content-center">
                    <div className="col-md-10 pl-0 pr-0  ">
                        {props.customHeader ? (
                            props.customHeader
                        ) : (
                            <FormHeader>
                                {companyId === undefined ? translate('Add company') : translate('Edit company')}
                            </FormHeader>
                        )}
                        <form
                            onSubmit={(e) => {
                                e.preventDefault();
                            }}
                            className="pt-4 pb-4 pr-2 pl-2 bg-white text-md-right"
                        >
                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Name')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        ref={name}
                                        autoComplete="off"
                                        placeholder={translate('Name')}
                                        name="name"
                                        value={company.name ? company.name : ''}
                                        onChange={handleInputChange.bind(this)}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('VAT or ID number')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        ref={idNumber}
                                        autoComplete="off"
                                        placeholder={translate('VAT or ID number')}
                                        name="idNumber"
                                        value={company.idNumber ? company.idNumber : ''}
                                        onChange={handleInputChange.bind(this)}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            {fiscalGeneralSettings && fiscalGeneralSettings.country === 'CRO' ? (
                                <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-md-4 col-form-label" htmlFor="">
                                        {'OIB'}:
                                    </label>
                                    <div className="col-md-8">
                                        <input
                                            autoComplete="off"
                                            placeholder="OIB"
                                            name="oib"
                                            value={company.oib ? company.oib : ''}
                                            onChange={handleInputChange.bind(this)}
                                            type="text"
                                            className="form-control"
                                        />
                                    </div>
                                </div>
                            ) : null}

                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Address')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        name="address"
                                        placeholder={translate('Address')}
                                        value={company.address ? company.address : ''}
                                        onChange={handleInputChange.bind(this)}
                                        type="email"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Post office number')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        name="poNumber"
                                        placeholder={translate('Post office number')}
                                        value={company.poNumber ? company.poNumber : ''}
                                        onChange={handleInputChange.bind(this)}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>
                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Town')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        name="town"
                                        placeholder={translate('Town')}
                                        value={company.town ? company.town : ''}
                                        onChange={handleInputChange.bind(this)}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Country')}:
                                </label>
                                <div className="col-md-8">
                                    <select
                                        name="country"
                                        className="form-control"
                                        value={company.country ? company.country : ''}
                                        onChange={handleInputChange.bind(this)}
                                    >
                                        {countryOptions}
                                    </select>
                                </div>
                            </div>

                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Web')}:
                                </label>
                                <div className="col-md-8">
                                    <input
                                        autoComplete="off"
                                        name="web"
                                        placeholder={translate('Web')}
                                        value={company.web ? company.web : ''}
                                        onChange={handleInputChange.bind(this)}
                                        type="text"
                                        className="form-control"
                                    />
                                </div>
                            </div>

                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="">
                                    {translate('Notes')}:
                                </label>
                                <div className="col-md-8">
                                    <textarea
                                        name="notes"
                                        placeholder={translate('Notes')}
                                        onChange={handleInputChange.bind(this)}
                                        value={company.notes ? company.notes : ''}
                                        className="form-control"
                                    ></textarea>
                                </div>
                            </div>

                            {validationMessage === '' ? null : (
                                <div className="alert alert-danger" role="alert">
                                    {validationMessage}
                                </div>
                            )}

                            <div className="text-center">
                                <div onClick={saveFormData.bind(this)} className="btn btn-outline-primary pointer">
                                    {translate('Save')}
                                </div>
                                <div onClick={cancelHandler.bind(this)} className="btn btn-outline-secondary ml-2 pointer">
                                    {translate('Cancel')}
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        fiscalGeneralSettings: ['fiscal','fiscalGeneralSettings'],
    },
    CompanyForm
);
