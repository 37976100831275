import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';

class PageTitle extends Component {
    render() {
        if (this.props.alternate) {
            return (
                <h4 className=" text-center text-primary uppercase bg-white  p-1 border-bottom rounded ">
                    {this.props.title || this.props.pageTitle}
                </h4>
            );
        } else {
            return (
                <h4 className="text-center uppercase text-secondary p-1 border-bottom rounded  font-weight-bold">
                    {this.props.title || this.props.pageTitle}
                </h4>
            );
        }
    }
}

export default branch(
    {
        pageTitle: ['pageTitle'],
    },
    PageTitle
);
