import React from 'react';
import rest from '../../../data/restWrapper';
import { startGlobalLoading, stopGlobalLoading } from '../../../components/loaders/globalLoading';
import { attributesEnum, attributesIconsEnum } from './enum';

const InstallationStep4 = ({ nextStepCallback, commonAttrArr, rooms, singleAttrArr }) => {
    const [dataChange, setDataChange] = React.useState([]);
    const [accPriceRules, setAccPriceRules] = React.useState({});
    const [personsNum, setPersonsNum] = React.useState(1);
    const [personsArr, setPersonsArr] = React.useState([1]);

    React.useEffect(() => {}, [dataChange]);

    const saveFormData = async () => {
        try {
            startGlobalLoading();
            const _commonAttrArr = [...commonAttrArr],
                attrIcons = [];

            singleAttrArr.forEach((a) => {
                if (!_commonAttrArr.includes(a)) {
                    _commonAttrArr.push(a);
                }
            });
            _commonAttrArr.forEach((a) => {
                const obj = {
                    attrName: attributesEnum[a],
                    icon: attributesIconsEnum[a],
                    enumAttr: Number(a),
                };
                attrIcons.push(obj);
            });

            const installationSteps = {
                rooms: rooms,
                accPriceRules: accPriceRules,
                attrIcons: attrIcons,
            };
            await rest('/installationSteps', 'POST', { installationSteps: installationSteps });
            stopGlobalLoading();
        } catch (error) {
            console.log('err', error);
        }
    };

    return (
        <div className=" bg-white  ">
            <div className="p-3 text-center">
                <b>{window.translate('Initial setup required')}</b>
                <hr />
                {window.translate('Insert accomodation price for persons number')}
                <hr />
            </div>
            <div className="row mt-2 text-center">
                <div className="col-md-3">
                    {' '}
                    <label htmlFor="personsNum">{window.translate('Number of persons')}:</label>{' '}
                </div>
                <div className="col-md-3">
                    <input
                        autoComplete="off"
                        name="personsNum"
                        value={personsNum}
                        onChange={(e) => {
                            setPersonsNum(Number(e.target.value));
                            let _personsArr = [];
                            for (let i = 1; i <= Number(e.target.value); i++) {
                                _personsArr.push(i);
                            }
                            setPersonsArr(_personsArr);
                        }}
                        type="number"
                        min={1}
                        className="form-control"
                    />
                </div>
            </div>
            {personsArr.map((f, index) => {
                return (
                    <div key={index} className="row mt-2 text-center">
                        <div className="col-md-3">
                            {' '}
                            <label htmlFor="accPriceRules">
                                {window.translate('Price for ')}
                                {f} {window.translate('person(s)')}:
                            </label>{' '}
                        </div>
                        <div className="col-md-3">
                            <input
                                autoComplete="off"
                                name="accPriceRules"
                                value={accPriceRules[f]}
                                onChange={(e) => {
                                    let _accPriceRules = accPriceRules;
                                    _accPriceRules[f] = Number(e.target.value);
                                    setAccPriceRules(_accPriceRules);
                                    setDataChange(!dataChange);
                                }}
                                type="number"
                                min={1}
                                className="form-control"
                            />
                        </div>
                    </div>
                );
            })}
            <div className="text-center p-5">
                <div
                    onClick={async (e) => {
                        e.preventDefault();
                        await saveFormData();
                        nextStepCallback();
                    }}
                    className="btn btn-outline-primary mb-4 pointer"
                >
                    {window.translate('SAVE AND FINISH')}
                </div>
            </div>
        </div>
    );
};

export default InstallationStep4;
