import React from 'react';
import PageLoader from '../../../components/loaders/loader1';
import ReportA4Frame from './ReportA4Frame';

const ReportPrintView = (props) => {
    const { setFinancialReport, financialReport } = props;

    if (!financialReport) {
        return <PageLoader />;
    }

    return (
        <div className="bg-light">
            <div style={{ maxWidth: '900px', margin: 'auto' }} className="">
                <div className="mt-2">
                    <button
                        className="btn btn-sm btn-secondary d-print-none"
                        onClick={() => {
                            setFinancialReport(null);
                        }}
                    >
                        {window.translate('GO BACK')}
                    </button>
                </div>
                <ReportA4Frame {...props} />

                <div className="text-center mt-4 mb-4 d-print-none">
                    <button
                        className="btn btn-sm btn-secondary"
                        onClick={() => {
                            setFinancialReport(null);
                        }}
                    >
                        {window.translate('Close')}
                    </button>
                </div>
            </div>
        </div>
    );
};

export default ReportPrintView;
