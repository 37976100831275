import React, { Component } from 'react';
import _ from 'lodash';
import { getAllModelData, deleteModelData, getSpecificModelRow } from '../../data/actions/modelActions';

import SRCTemplateForm from './form';
import { setModalContent } from '@components/modal';
import Delete from '../../components/confirmModal';

import PushTemplateModal from './pushTemplate';
import PageTitle from '../../components/pageTitle';

class SRCTemplate extends Component {
    constructor(params) {
        super(params);
        this.state = {
            srcTemplates: [],
            selectedSRCTemplate: null,
            selectedSRCTemplateItems: [],
        };
    }
    async componentDidMount() {
        let srcTemplates = await getAllModelData('SRCTemplate');
        let srcTemplateId = this.props.match.params.srcTemplateId;
        let selectedSRCTemplateItems = [];
        let selectedSRCTemplate = null;

        if (srcTemplateId !== undefined) {
            srcTemplateId = parseInt(srcTemplateId, 10);
            selectedSRCTemplateItems = await getSpecificModelRow('SRCTemplate', srcTemplateId);
            selectedSRCTemplate = _.find(srcTemplates, { id: srcTemplateId });
        }

        this.setState({
            srcTemplates,
            selectedSRCTemplateItems,
            selectedSRCTemplate,
        });
    }

    async selectAndShowSRCTemplate(srcTemplate) {
        let selectedSRCTemplateItems = await getSpecificModelRow('SRCTemplate', srcTemplate.id);
        this.setState({ selectedSRCTemplate: _.clone(srcTemplate), selectedSRCTemplateItems });
        this.props.history.push('/srcTemplates/' + srcTemplate.id); //render failsafe...
    }
    clearSelection() {
        this.setState({ selectedSRCTemplate: null, selectedSRCTemplateItems: [] });
        this.props.history.push('/srcTemplates');
    }

    async deleteItem(model, itemId) {
        let modelArrayFromState = '';
        if (model === 'SRCTemplate') {
            modelArrayFromState = 'srcTemplates';
        } else if (model === 'SRCTemplateItem') {
            modelArrayFromState = 'selectedSRCTemplateItems';
        }

        try {
            let deletedItem = await deleteModelData(model, itemId);
            let stateModelItems = _.clone(this.state[modelArrayFromState]);

            stateModelItems = _.filter(stateModelItems, (itm) => {
                return itm.id !== deletedItem.id;
            });
            this.setState({
                [modelArrayFromState]: stateModelItems,
            });
        } catch (error) {
            console.log(error);
        }
    }

    showDeleteModal(model, itemId) {
        setModalContent(
            <Delete actionForYes={this.deleteItem.bind(this, model, itemId)} />,
            window.translate("Confirm"),
            false,
            'modal-md'
        );
    }

    showPushTemplateModal(srcTemplate) {
        setModalContent(
            <PushTemplateModal srcTemplate={srcTemplate} />,
            'MULTICAST SRC CONFIGURATION TEMPLATE: ' + srcTemplate.name,
            false,
            'modal-lg'
        );
    }

    render() {
        let rightSideRender = null;
        switch (this.props.match.params.action) {
            case 'edit':
                rightSideRender = (
                    <SRCTemplateForm
                        header={
                            <h3 className="text-center bg-info text-white p-4">
                                {window.translate("EDIT BASIC SRC TEMPLATE INFO")}
                            </h3>
                        }
                        srcTemplateId={this.props.match.params.srcTemplateId}
                        history={this.props.history}
                    />
                );
                break;
            case 'new':
                rightSideRender = <SRCTemplateForm history={this.props.history} />;
                break;
            default:
                break;
        }

        return (
            <div className="">
                <PageTitle />
                        <button
                            onClick={() => {
                                this.props.history.push('/menu/system');
                            }}
                            className="btn btn-sm btn-secondary"
                        >
                            {window.translate("GO BACK")}
                        </button>
                        <button
                            onClick={() => {
                                this.props.history.push('/srcTemplate/new');
                            }}
                            className="btn btn-sm btn-primary ml-2"
                        >
                            {window.translate("ADD NEW")}
                        </button>

                        <div className="list-group mt-3 mx-3">
                            <small>
                                <div className="px-1 py-2 row shadow-sm bg-white">
                                    <div className="">{window.translate('Name')}</div>
                                </div>
                            </small>
                        </div>

                        <ul className="list-group mt-2">
                            {_.map(this.state.srcTemplates, (l) => {
                                return (
                                    <li
                                        key={l.id}
                                        style={{display:"flex", alignItems:"center", justifyContent:"space-between"}}
                                        className={
                                            parseInt(this.props.match.params.srcTemplateId, 10) === l.id
                                                ? 'px-2 py-2  shadow-sm my-1 rounded bg-info text-white'
                                                : 'px-2 py-2  shadow-sm bg-white my-1 rounded'
                                        }
                                    >
                                        <div className="">{l.name}</div>
                                        {parseInt(this.props.match.params.srcTemplateId, 10) === l.id ? null : (
                                            <div className="">
                                                <button
                                                    onClick={() => {
                                                        this.props.history.push('/srcTemplate/' + l.id + '/edit');
                                                    }}
                                                    className="btn btn-sm btn-outline-dark pull-right ml-1"
                                                >
                                                    <i className="fa fa-edit" aria-hidden="true"></i>
                                                </button>

                                                <button
                                                    onClick={this.showDeleteModal.bind(this, 'SRCTemplate', l.id)}
                                                    className="btn btn-sm btn-outline-danger pull-right ml-1"
                                                >
                                                    <i className="fa fa-trash"></i>
                                                </button>

                                                <button
                                                    onClick={() => {
                                                        this.showPushTemplateModal(l);
                                                    }}
                                                    className="btn btn-sm btn-outline-primary pull-right ml-1"
                                                >
                                                    {window.translate("PUSH TEMPLATE")}
                                                </button>
                                            </div>
                                        )}
                                    </li>
                                );
                            })}
                        </ul>
                    {rightSideRender ? <div className="col-md-9 bg-light p-3 ">{rightSideRender}</div> : null}
            </div>
        );
    }
}
export default SRCTemplate;
