import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { getLanguagesInArray } from '../../../data/translations';
import { handleChange, saveFormData } from '../settingsActions';

const InstallationForm = ({ settings, nextStepCallback, currency }) => {
    const { locale, defaultCurrency, companyInfo } = settings;

    React.useEffect(() => {}, []);

    let localeList = getLanguagesInArray();
    let defaultCurrencyList =
        currency && currency.length > 0
            ? currency.map((c) => c.shortName)
            : defaultCurrency && defaultCurrency !== ''
            ? [defaultCurrency]
            : ['EUR', 'HRK', 'BAM', 'RSD'];

    let localeOptions = [];
    localeList.forEach((locale, ky) => {
        localeOptions.push(
            <option key={ky} value={locale}>
                {locale}
            </option>
        );
    });

    let defaultCurrencyOptions = [];
    defaultCurrencyList.forEach((defCurr, defCurrKey) => {
        defaultCurrencyOptions.push(
            <option key={defCurrKey} value={defCurr}>
                {defCurr}
            </option>
        );
    });

    return (
        <div className=" bg-white  ">
            <div className="p-3 text-center">
                <b>{window.translate('Initial setup required')}</b>
                <hr />

                <div className="row mt-2">
                    <div className="col-md-3">
                        {' '}
                        <label htmlFor="hotelName">{window.translate('gsettings_hotelName')}:</label>{' '}
                    </div>
                    <div className="col-md-3">
                        <input
                            autoComplete="off"
                            name="hotelName"
                            value={companyInfo && companyInfo.hotelName ? companyInfo.hotelName : ''}
                            onChange={handleChange.bind(this, 'companyInfo', 'object')}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-3">
                        {' '}
                        <label htmlFor="locale">{window.translate('Locale')}:</label>{' '}
                    </div>
                    <div className="col-md-3">
                        <select
                            value={locale ? locale : ''}
                            onChange={handleChange.bind(this, 'locale', 'string')}
                            className="custom-select my-1 mr-sm-2 "
                            id="locale"
                        >
                            <option disabled={true} value="">
                                {window.translate('Nothing selected')}
                            </option>
                            {localeOptions}
                        </select>
                    </div>
                </div>
                <div className="row mt-2">
                    <div className="col-md-3">
                        {' '}
                        <label htmlFor="defaultCurrency">{window.translate('Default currency')}:</label>{' '}
                    </div>
                    <div className="col-md-3">
                        <select
                            onChange={handleChange.bind(this, 'defaultCurrency', 'string')}
                            value={defaultCurrency}
                            className="custom-select my-1 mr-sm-2 "
                            id="defaultCurrency"
                        >
                            <option disabled={true} value={''}>
                                {window.translate('Nothing selected')}
                            </option>
                            {defaultCurrencyOptions}
                        </select>
                    </div>
                </div>
            </div>

            <div className="text-center p-5">
                {' '}
                {companyInfo && companyInfo.hotelName && companyInfo.hotelName !== '' ? (
                    <div
                        onClick={async () => {
                            if (locale && defaultCurrency && companyInfo) {
                                window.location = '/#/firstTimeSetup?step2';
                                await saveFormData('locale', locale, true);
                                await saveFormData('defaultCurrency', defaultCurrency, true);
                                await saveFormData('companyInfo', companyInfo, true);
                                nextStepCallback();
                            } else {
                                alert('You must enter all data!');
                            }
                        }}
                        className="btn btn-outline-primary mb-4 pointer"
                    >
                        {window.translate('SAVE AND NEXT')}
                    </div>
                ) : null}{' '}
            </div>
        </div>
    );
};

export default branch(
    {
        currency: ['model', 'Currency'],
    },
    InstallationForm
);
