import React, { useState, useEffect } from 'react';
import _ from 'lodash';
import pubsub from '../../data/pubsub';

// const ENTER_KEY = 13;
// const COMMA_KEY = 188;
// const BACKSPACE_KEY = 8;

export const Tags = (props) => {
    const [tagsState, setTagsState] = useState([]);
    const [valueState, setValueState] = useState('');
    const [optionsState, setOptionsState] = useState([]);
    const [filteredOptionsState, setFilteredOptions] = useState([]);
    const [, setShowOptions] = useState(false);
    const [cursorState, setCursor] = useState(-1);

    //zašto treba ovaj parametar isReservationForm
    const { tags: itemTags = '', classNameForTags, modelTags, placeholderText = "Add tag, then press enter to confirm", onlyAvailableTags = false, selectedTag } = props;
    useEffect(() => {
        const fetchData = async () => {
            try {
                let tags = itemTags ? itemTags : '';
                tags = _.filter(tags, (t) => {
                    if (t !== '') {
                        return t;
                    }
                });
                setTagsState(tags);
                setOptionsState(modelTags);
            } catch (err) {
                console.log(err);
            }
        };
        fetchData();
    }, [itemTags, modelTags]);

    const onChange = (e) => {
        const options = optionsState;
        const userInput = e.currentTarget.value;

        const filteredOptions = options.filter(
            (optionName) => optionName.toLowerCase().indexOf(userInput.toLowerCase()) > -1
        );

        setFilteredOptions(filteredOptions);
        setShowOptions(true);
        setValueState(e.currentTarget.value);
    };

    // const onClick = (e) => {
    //     setFilteredOptions([]);
    //     setShowOptions(false);
    //     setValueState(e.currentTarget.innerText);
    //     addTag(e.currentTarget.innerText);
    // };

    const onKeyDown = (e) => {
        const key = e.keyCode;
        let value = valueState;
        let cursor = cursorState;

        if (key === 13) {
            setShowOptions(false);
            addTag(value);
        }

        if (key === 38 && cursor > 0) {
            setCursor(cursor - 1);
            setValueState(filteredOptionsState[cursor - 1]);
        }
        if (key === 40 && cursor < filteredOptionsState.length - 1) {
            setCursor(cursor + 1);
            setValueState(filteredOptionsState[cursor + 1]);
        }
    };

    const addTag = (val) => {
        let tagovi = tagsState,
            values = val;
        let tag = values;

        // tag = tag.replace(/;/g, '');
        // eslint-disable-next-line
        if (tag.match(/[`!@#$%^&*()_+\-=\[\]{};':"\\|.<>\/?~]/)) {
            tag = '';
        }

        _.forEach(tag.split(','), (t) => {
            if (t === '') {
                tag = '';
            }
        });

        let tags = [];
        tags = [...new Set(tagovi)];
        if (!tags.includes(tag) && onlyAvailableTags && optionsState.includes(tag) && tag.replace(/\s/g, '').length !== 0) {
            tags.push(tag);
        } else if (!tags.includes(tag) && !onlyAvailableTags && tag.replace(/\s/g, '').length !== 0) {
            tags.push(tag);
        } else if (tag === '') {
            pubsub.emit('toastMessage', window.translate("Invalid value"), 'error');
        } else if (tags.includes(tag)) {
            pubsub.emit('toastMessage', window.translate("Tag already exist!"), 'error');
        }
        setTagsState(tags);
        setValueState('');
        setShowOptions(false);
        setCursor(-1);

        tags = tags.join(';');

        // let e = {
        //     target: {
        //         name: 'tags',
        //         value: tags,
        //     },
        // };
        props.handleChange(values);
    };

    // const deleteTag = (tag) => {
    //     let tags = tagsState;
    //     tags = _.filter(tags, (t) => {
    //         if (t !== tag) {
    //             return t;
    //         }
    //     });

    //     setTagsState(tags);
    //     tags = tags.join(';');
    //     let e = {
    //         target: {
    //             name: 'tags',
    //             value: tags,
    //         },
    //     };
    //     props.handleChange(e);
    // };

    const dragAndDropTags = (e, tag) => {
        if (props.onDragStart) {
            props.onDragStart(e, tag)
        }
    }

    // let optionList = null;
    // if (showOptionsState && valueState) {
    //     if (filteredOptionsState.length) {
    //         optionList = (
    //             <div className="list-group border border-info rounded">
    //                 {filteredOptionsState.map((optionName, key) => {
    //                     return (
    //                         <div
    //                             className={
    //                                 cursorState === key
    //                                     ? 'list-group-item-sm bg-light m-0 p-1 text-left pointer '
    //                                     : 'list-group-item-sm m-0 text-left pointer p-1'
    //                             }
    //                             key={key}
    //                             onClick={onClick}
    //                         >
    //                             {optionName}
    //                         </div>
    //                     );
    //                 })}
    //             </div>
    //         );
    //     }
    // }

    return (
        <div className="w-100  mb-1">

        <div className={classNameForTags ? classNameForTags : "col-md-8 p-0 m-0 "}>
            <div className="overflow-hidden">
            <div >
       <small>{window.translate("*drag and drop tags to rooms or pricelists")}</small>
        </div>
                <div className="d-flex align-items-center flex-wrap">
                    
                    {tagsState.map((tag, i) => {
                        const selTag = selectedTag===tag ? 'bg-primary text-white' : 'text-primary'
                        return(
                        <small key={tag + i}>
                            <div className={'border border-primary mr-2 mb-1 float-left  px-2 font-weight-bold rounded pointer ' + selTag}
                                draggable
                                onDragStart={(e) => {
                                    e.stopPropagation();
                                    dragAndDropTags(e, tag)
                                }}
                                onClick={()=>props.handleClick(tag)}>
                                {tag}
                                {/* <i className="ml-3 fa fa-times text-dark pointer" onClick={(e) => {
                                    e.stopPropagation();
                                    deleteTag(tag)
                                }} /> */}
                            </div>
                        </small>)
                    })}


                    <input
                    type="text"
                    placeholder={window.translate(placeholderText)}
                    className="form-control p-1 mb-1"
                    style={{width:"200px", fontSize:"small"}}
                    onChange={onChange}
                    onKeyDown={onKeyDown}
                    value={valueState}
                />
                {/* {optionList} */}
                   
                   
                        
                </div>

            
            </div>
           
        </div>
        
        </div>

    );
};
