import * as React from 'react';
import { getReservationNotifications,sendNotificationOnAction } from '../api';
import { MessageBrokerInformationModel, ReservationStatus } from '@common/modelDefinition';
import { Reservation } from '@common/reservation';
import { translate } from '@data/translations';
import moment from 'moment';
import ConfirmModal from '@components/confirmModal';
import { setModalContent } from '@components/modal';
//@ts-ignore
import { useBranch } from 'baobab-react/hooks';

export enum MessageStatus {
    Pending = 0,
    Delivered = 1,
    Failed = 2,
    anceled = 3,
    aborted = 4,
    PendingThirdParty = 5,
    FailedThirdParty = 6,
    rejectedThirdParty = 7
}

interface ReservationNotificationsProps {
    reservation?: Reservation;
    history: any;
}

function ReservationNotifications(props: ReservationNotificationsProps) {
    const [reservationNotifications, setReservationNotifications] = React.useState<MessageBrokerInformationModel[]>([]);
    const [selfServiceCheckInId, setSelfServiceCheckInId] = React.useState(null as any);
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [newInfo, setNewInfo] = React.useState(false);

    const { reservation } = props;

    const { otherSettings } = useBranch({
        otherSettings: ['otherSettings'],
    });


     const loadDataSetState = async () => {
        if (reservation?.SelfServiceCheckIn?.id) {
            const _selfServiceCheckInId = reservation?.SelfServiceCheckIn?.id;
            setSelfServiceCheckInId(_selfServiceCheckInId);
        }
        if (reservation && reservation.id) {
            const _reservationNotifications = await getReservationNotifications(
                reservation.id,
                selfServiceCheckInId
            );
            setReservationNotifications(_reservationNotifications);
        }
    }

    React.useEffect(() => {
        const loadData = async () => {
            await loadDataSetState()
        };
        loadData().catch(err => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [reservation?.id, selfServiceCheckInId,newInfo]);

    const sendNotificationAgain = async (model:string | undefined) => {
        if(model){
            const textContent = model==="Reservation" ? "Are you sure you want to send reservation confirmation email?"
                             : model==="SelfServiceCheckIn" ? "Are you sure you want to send self check in email?" : ""
            setModalContent(
                <ConfirmModal
                    actionForYes={async () => {
                        try {
                            await sendNotificationOnAction(model, reservation?.id)
                            await loadDataSetState()
                        } catch (err) {
                            console.warn(err);
                        }
                    }}
                    content={translate(textContent)}
                />,
                translate('Confirm'),
                false,
                'modal-md'
            );
        }
    }

    const headerClass = isExpanded
        ? 'pointer text-center uppercase text-primary  p-2'
        : 'pointer text-center uppercase text-primary  p-2'; //no undeline
    
    const sortedReservationNotifications = reservationNotifications.sort((a, b) => a.timestamp - b.timestamp);

    return (
        <div className="mt-2">
            <div className="d-flex justify-content-between border-bottom border-top">
                <p></p>
                <h5
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                    className={headerClass}
                >
                    <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                    &ensp;{translate('Notification info')}&ensp;
                    <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                </h5>
                <i
                    className={
                        newInfo
                            ? 'fa-rotate-90 fa fa-refresh float-right p-3 pointer'
                            : 'fa fa-refresh float-right p-3 pointer'
                    }
                    title="Refresh notification info"
                    onClick={() => setNewInfo(!newInfo)}
                />
            </div>

            {isExpanded ? (
                <div className="mt-2">
                    {sortedReservationNotifications.length === 0 ? (
                        <small>{translate('List is empty')}</small>
                    ) : (
                        <ul className="list-group clear">
                            <li className="list-group-item">
                                <small>
                                    <div className="row">
                                        <div className="col-1 col-md-1 font-weight-bold">{translate('Date')}</div>
                                        <div className="col-3 col-md-3 font-weight-bold">{translate('Title')}</div>
                                        <div className="col-6 col-md-6 font-weight-bold">{translate('Message')}</div>
                                        <div className="col-2 col-md-2 font-weight-bold">{translate('Status')}</div>
                                    </div>
                                </small>
                            </li>
                            {sortedReservationNotifications.map((t, key) => {
                                const statusType =
                                    t.statusFlag || t.statusFlag === 0
                                        ? (MessageStatus[t.statusFlag] as keyof typeof MessageStatus)
                                        : '';
                                return (
                                    <li key={key} className="list-group-item">
                                        <small>
                                            <div className="row">
                                                <div className="col-1 col-md-1">
                                                    {moment(t.timestamp).format('DD/MM/YYYY HH:mm')}
                                                </div>
                                                <div className="col-3 col-md-3">{t.subject}</div>
                                                <div className="col-6 col-md-6">{t.message}</div>
                                                <div className="col-2 col-md-2">{statusType}</div>
                                            </div>
                                        </small>
                                    </li>
                                );
                            })}
                        </ul>
                    )}
              {reservation?.statusEnum === ReservationStatus.confirmed || reservation?.statusEnum === ReservationStatus.checkedIn  ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                        {sortedReservationNotifications?.length > 0 && otherSettings?.sendReservationConfirmationToGuest ? (
                            <div
                                onClick={() => {
                                    sendNotificationAgain('Reservation');
                                }}
                                className="m-2 btn btn-sm btn-info pointer"
                            >
                                <i className="fa fa-envelope pr-1"></i>
                                {translate('Send confirmation email again')}
                            </div>
                        ) : null}
                        {selfServiceCheckInId ? (
                            <div
                                onClick={() => {
                                    sendNotificationAgain('SelfServiceCheckIn');
                                }}
                                className="m-2 btn btn-sm btn-info pointer"
                            >
                                <i className="fa fa-envelope pr-1"></i>
                                {translate('Send self check in email again')}
                            </div>
                        ) : null}
                    </div> : null}
                </div>
            ) : null}
        </div>
    );
}

export default ReservationNotifications;