import React from 'react';
// import { branch } from 'baobab-react/higher-order';
//import PropTypes from 'prop-types';

import _ from "lodash"
import EnumPicker from "./enumPicker.js"
import ArrayStringInput from "./arrayStringInput.js"
import CheckBox from "./checkBox.js"
import IntegerInput from "./integerInput.js"
import TextInput from "./textInput.js"
import CodeInput from "./codeInput"

const getInputDefinition = (inputDesc, stackArray, inputName, onValueChangedHandler, isDisabled = false)=>{
	switch(inputDesc.inputType){
		case "seperator":
			stackArray.push(<div key={inputName}>{inputDesc.label!=="" ? <h3>{inputDesc.label}</h3> : null}<span className="description py-1">{inputDesc.description}</span></div>);
		break;
		case "arrayStringInput":
			stackArray.push(<ArrayStringInput 
				label={inputDesc.label} 
				description={inputDesc.description} 
				inputName={inputName} 
				value={inputDesc.value} 
				onValueChanged={onValueChangedHandler} 
				key={inputName}/>);
		break;
		case "checkBox":
			stackArray.push(<CheckBox 
				label={inputDesc.label} 
				description={inputDesc.description} 
				inputName={inputName} 
				value={inputDesc.value} 
				onValueChanged={onValueChangedHandler} 
				key={inputName}/>);
		break;
		case "enumPicker":
			stackArray.push(<EnumPicker 
				mandatorySelection={_.isUndefined(inputDesc.mandatorySelection) ? false : inputDesc.mandatorySelection}
				disabledEnums={inputDesc.disabledEnums}
				enumType={inputDesc.enumType ? inputDesc.enumType : "string" }
				enums={inputDesc.enums} 
				label={inputDesc.label} 
				description={inputDesc.description} 
				inputName={inputName} 
				value={inputDesc.value} 
				onValueChanged={onValueChangedHandler} 
				disabled={isDisabled}
				key={inputName}/>);
		break;
		case "code":
			stackArray.push(<CodeInput 
				label={inputDesc.label} 
				description={inputDesc.description} 
				inputName={inputName} 
				value={inputDesc.value} 
				onValueChanged={onValueChangedHandler} 
				key={inputName}/>);
		break;
		case "integer":
			stackArray.push(<IntegerInput 
				label={inputDesc.label} 
				description={inputDesc.description} 
				min={inputDesc.min}
				max={inputDesc.max}
				inputName={inputName} 
				value={inputDesc.value} 
				onValueChanged={onValueChangedHandler} 
				key={inputName}
				disabled={isDisabled}/>);
		break;
		case "singlelineText":
			stackArray.push(<TextInput 
				label={inputDesc.label} 
				description={inputDesc.description} 
				multiline={false}
				inputName={inputName} 
				value={inputDesc.value} 
				onValueChanged={onValueChangedHandler} 
				key={inputName}/>);
		break;
		case "passwordInput":
			stackArray.push(<TextInput 
				label={inputDesc.label} 
				type={"password"}
				description={inputDesc.description} 
				multiline={false}
				inputName={inputName} 
				value={inputDesc.value} 
				onValueChanged={onValueChangedHandler} 
				key={inputName}/>);
		break;
		case "multilineText":
			stackArray.push(<TextInput 
				label={inputDesc.label} 
				description={inputDesc.description} 
				multiline={true}
				inputName={inputName} 
				value={inputDesc.value} 
				onValueChanged={onValueChangedHandler} 
				key={inputName}/>);
		break;
		default:
		break;
	}
}	




export default getInputDefinition;