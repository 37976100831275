import React from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import baobabTree from '../../data/state';

function Toolbar(props) {
    const toggleSidebarButtons = (sidebarType) => {
        let temp = _.clone(baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).get());
        temp = _.filter(temp, (t) => t === sidebarType || t === 'timeline' || t === 'tiles' || t === 'activity');
        temp.push(sidebarType);
        baobabTree.root.select(['uiStates', 'dashboard', 'showComponents']).set(temp);
    };

    let sidebarButtonCheckedRule = 'sidebar_dailyInfo';
    if (props.showComponents.includes('sidebar_dailyInfo')) {
        sidebarButtonCheckedRule = 'sidebar_dailyInfo';
    }
    if (props.showComponents.includes('sidebar_week/month')) {
        sidebarButtonCheckedRule = 'sidebar_week/month';
    }
    if (props.showComponents.includes('sidebar_incomming')) {
        sidebarButtonCheckedRule = 'sidebar_incomming';
    }
    if (props.showComponents.includes('sidebar_checkedIn')) {
        sidebarButtonCheckedRule = 'sidebar_checkedIn';
    }
    if (props.showComponents.includes('sidebar_canceled')) {
        sidebarButtonCheckedRule = 'sidebar_canceled';
    }

    return (
        <div className="d-flex justify-content-center">
            <nav className="navbar mb-0 pb-0 pt-0 rounded row">
                <div className="col-4">
                    <div className="form-inline align-items-center ">
                        <div
                            style={{ display: 'flex' }}
                            className="btn-group rounded  mr-2 "
                            role="group"
                            aria-label="Sidebar filter"
                        >
                            <button
                                title={window.translate('Daily info')}
                                onClick={toggleSidebarButtons.bind(this, 'sidebar_dailyInfo')}
                                type="button"
                                className={
                                    sidebarButtonCheckedRule === 'sidebar_dailyInfo'
                                        ? 'btn btn-sm btn-light active border'
                                        : 'btn btn-sm btn-light border'
                                }
                            >
                                <i className="fa fa-pie-chart text-info" />
                            </button>
                            <button
                                title={window.translate('Weekly and monthly info')}
                                onClick={toggleSidebarButtons.bind(this, 'sidebar_week/month')}
                                type="button"
                                className={
                                    sidebarButtonCheckedRule === 'sidebar_week/month'
                                        ? 'btn btn-sm btn-light active border'
                                        : 'btn btn-sm btn-light border'
                                }
                            >
                                <i className="fa fa-area-chart text-info" />
                            </button>
                            <button
                                title={window.translate('Incomming reservations')}
                                onClick={toggleSidebarButtons.bind(this, 'sidebar_incomming')}
                                type="button"
                                className={
                                    sidebarButtonCheckedRule === 'sidebar_incomming'
                                        ? 'btn btn-sm btn-light active border'
                                        : 'btn btn-sm btn-light border'
                                }
                            >
                                <i className="fa fa-calendar-check-o text-info" />
                            </button>
                            <button
                                title={window.translate('Checked in reservations')}
                                onClick={toggleSidebarButtons.bind(this, 'sidebar_checkedIn')}
                                type="button"
                                className={
                                    sidebarButtonCheckedRule === 'sidebar_checkedIn'
                                        ? 'btn btn-sm btn-light active border'
                                        : 'btn btn-sm btn-light border'
                                }
                            >
                                <i className="fa fa-sign-out text-info" />
                            </button>
                            <button
                                title={window.translate('Canceled reservations')}
                                onClick={toggleSidebarButtons.bind(this, 'sidebar_canceled')}
                                type="button"
                                className={
                                    sidebarButtonCheckedRule === 'sidebar_canceled'
                                        ? 'btn btn-sm btn-light active border'
                                        : 'btn btn-sm btn-light border'
                                }
                            >
                                <i className="fa fa-eraser text-info" />
                            </button>
                        </div>
                    </div>
                </div>
                <div className="col-8 d-sm-none  pr-0">
                    <input
                        onChange={props.setSearchTerm}
                        placeholder={window.translate('Reservations search...')}
                        type="text"
                        className="form-control form-control-sm pull-left"
                    />
                </div>
            </nav>
        </div>
    );
}

export default branch(
    {
        roomTags: ['monkeys', 'roomTags'],
        reservations: ['model', 'Reservation'],
    },
    Toolbar
);
