import React, { Component } from 'react';
import { saveModelData } from '../../data/actions/modelActions';
import _ from 'lodash';
import { v4 as uuidv4 } from 'uuid';
import QRCode from 'qrcode.react';
import rest from '../../data/restWrapper';
import { closeModal } from '@components/modal';

class ApSelfCheckInSettings extends Component {
    constructor() {
        super();
        this.name = React.createRef();
        this.state = {
            name: '',
            description: '',
            messageAfterSuccessfullScan: '',
            qrCode: null,
            afterDocumentScanner: false,
            documentScannerDeviceReference: null,
            documentScannerDevices: [],
        };
    }

    async getDocumentScannerDevices() {
        const documentScannerDevices = await rest('/DocumentScannerDevice?by=uuid');
        // console.log('oooooo', konj);
        if (documentScannerDevices) {
            return documentScannerDevices;
        } else {
            return [];
        }
    }

    async componentDidMount() {
        const documentScannerDevices = await this.getDocumentScannerDevices();
        const {
            name,
            description,
            messageAfterSuccessfullScan,
            qrCode,
            afterDocumentScanner,
            documentScannerDeviceReference,
        } = this.props.ap;
        // let name = this.props.ap.name;
        // let description = this.props.ap.description;
        this.setState({
            name,
            description,
            messageAfterSuccessfullScan,
            qrCode,
            afterDocumentScanner,
            documentScannerDeviceReference,
            documentScannerDevices,
        });
    }

    async componentDidUpdate(prevProps, prevState) {
        if (prevProps.ap.id !== this.props.ap.id) {
            const documentScannerDevices = await this.getDocumentScannerDevices();

            const {
                name,
                description,
                messageAfterSuccessfullScan,
                qrCode,
                afterDocumentScanner,
                documentScannerDeviceReference,
            } = this.props.ap;
            this.setState({
                name,
                description,
                messageAfterSuccessfullScan,
                qrCode,
                afterDocumentScanner,
                documentScannerDeviceReference,
                documentScannerDevices,
            });
        }
    }

    handleInputChange(e) {
        console.log('e.target.value', e.target.value);
        this.setState({ [e.target.name]: e.target.value });
    }

    clearValidationRefs() {
        if (this.name && this.name.current) {
            this.name.current.className = this.name.current.className.replace('is-invalid', '');
        }
    }

    markInvalidInputRefs(validationResults) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        _.forEach(invalidInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        _.forEach(validInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }
    getValidationInputs(data) {
        let invalidInputs = [];
        let validInputs = [];
        // this.clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(this.name);
        } else {
            validInputs.push(this.name);
        }
        return { invalidInputs, validInputs };
    }

    handleSubmit() {
        let payload = {
            id: this.props.ap.id,
            name: this.state.name,
            description: this.state.description,
            messageAfterSuccessfullScan: this.state.messageAfterSuccessfullScan,
            qrCode: this.state.qrCode,
            afterDocumentScanner: this.state.afterDocumentScanner,
            documentScannerDeviceReference: this.state.documentScannerDeviceReference,
        };
        let validationResults = this.getValidationInputs(payload);
        this.markInvalidInputRefs(validationResults);
        if (validationResults.invalidInputs.length === 0) {
            saveModelData('DoorAccessPoint', payload).catch((err) => {
                console.warn(err);
            });
            this.closeModall();
        }
    }

    setAfterDocumentScanner() {
        this.setState({ afterDocumentScanner: !this.state.afterDocumentScanner });
    }

    generateQrCodeForDoorAp() {
        this.setState({ qrCode: uuidv4() });
    }

    getDocumentScannerOptions() {
        let options = [];

        const docScannerDevicesAlreadyBusy = _.filter(
            _.map(this.props.doorAccessPoints, 'documentScannerDeviceReference'),
            (dev) => dev !== null
        );

        _.forEach(this.state.documentScannerDevices, (docScannerDevice) => {
            if (!_.includes(docScannerDevicesAlreadyBusy, docScannerDevice.uuid)) {
                options.push(
                    <option value={docScannerDevice.uuid} key={docScannerDevice.uuid}>
                        {docScannerDevice.name}
                    </option>
                );
            } else {
                if (docScannerDevice.uuid === this.state.documentScannerDeviceReference) {
                    options.push(
                        <option value={docScannerDevice.uuid} key={docScannerDevice.uuid}>
                            {docScannerDevice.name}
                        </option>
                    );
                }
            }
        });

        return options;
    }

    closeModall() {
        this.clearValidationRefs();
        closeModal();
        //baobab.root.select(["modalData","content"]).set(null)
    }

    render() {
        return (
            <div>
                <div className="text-center d-flex justify-content-center items-align-center flex-column">
                    <div>{this.state.qrCode ? <QRCode value={this.state.qrCode} size={100} /> : null}</div>
                    <div>
                        <button
                            onClick={this.generateQrCodeForDoorAp.bind(this)}
                            className="btn btn-sm btn-outline-secondary "
                        >
                            {this.state.qrCode ? window.translate('Regenerate QR') : window.translate('Generate QR')}
                        </button>
                    </div>

                    {/* <div onClick={this.setAfterDocumentScanner.bind(this)} className="pointer mt-2">
                    {this.state.afterDocumentScanner ? <i className="fa fa-check-square-o pr-1"></i> : <i className="fa fa-square-o pr-1"></i>}
                    {window.translate("After document scanner")}
                </div> */}
                </div>

                <div>
                    <div className="form-group mt-2">
                        <label htmlFor="documentScannerDeviceReference">
                            {window.translate('Reference to document scanner device')}:
                        </label>
                        <select
                            value={this.state.documentScannerDeviceReference}
                            className="custom-select mb-1 mr-sm-2 "
                            name="documentScannerDeviceReference"
                            onChange={this.handleInputChange.bind(this)}
                        >
                            <option value="">Nothing selected</option>
                            {this.getDocumentScannerOptions()}
                        </select>
                    </div>
                </div>
                <div>
                    <div className="form-group">
                        <label htmlFor="messageAfterSuccessfullScan">
                            {window.translate('Message after successfull scan')}
                        </label>
                        <textarea
                            id="messageAfterSuccessfullScan"
                            autoComplete="off"
                            name="messageAfterSuccessfullScan"
                            value={this.state.messageAfterSuccessfullScan}
                            onChange={this.handleInputChange.bind(this)}
                            className="form-control"
                            rows="5"
                        ></textarea>
                    </div>
                </div>
                <button onClick={this.closeModall.bind(this)} className="btn btn-sm btn-outline-secondary pull-right">
                    {window.translate('Cancel')}
                </button>
                <button
                    onClick={this.handleSubmit.bind(this)}
                    className="btn btn-sm btn-outline-primary pull-right mr-1"
                >
                    {window.translate('Save')}
                </button>
            </div>
        );
    }
}

export default ApSelfCheckInSettings;
