import React, { Component } from 'react';
// import _ from 'lodash';
import { branch } from 'baobab-react/higher-order'; //DONT use baobab here
import PageTitle from '../../components/pageTitle';

class DoorAP extends Component {
    render() {
        return (
            <div>
                <PageTitle />
                <button
                    onClick={() => {
                        this.props.history.goBack();
                    }}
                    className="btn btn-sm btn-secondary animated faster slideInDown"
                >
                    {window.translate('GO BACK')}
                </button>

                <ul className="list-group mt-2">
                    {this.props.accessPoints.map((r, index) => {
                        return (
                            <li key={index} className="list-group-item d-flex">
                                <div className="col-3"><b>{r.name}</b> {r.description}</div>
                                <div><button
                                    onClick={() => {
                                        this.props.history.push('/report/accessControl/' + r.id);
                                    }}
                                    className="btn btn-sm btn-primary"
                                >
                                    {window.translate('View')}
                                </button>
                                </div>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    }
}

export default branch(
    {
        //do not use baobab here...
        accessPoints: ['model', 'DoorAccessPoint'],
    },
    DoorAP
);