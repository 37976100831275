import React, { useState, useEffect } from 'react';
import ReportTable from '../components/ReportTable';
import PageTitle from '../components/pageTitle';
import { getReportData, getExcelExport } from '../data/reports';
import { branch } from 'baobab-react/higher-order';
import { toast } from 'react-toastify';

function ReservationsReportList(props) {
    const [yearlyReservations, setYearlyReservations] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [filterType, setFilterType] = useState(null);
    const [filterCountry, setFilterCountry] = useState(null);
    const [_page, setPage] = React.useState(0);

    useEffect(() => {
        const loadData = async () => {
            reloadData(year);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const _setYear = async (e) => {
        setYear(Number(e.target.value));
        await reloadData(Number(e.target.value));
    };

    const reloadData = async (year, _filterCountry, _filterType, page = _page) => {
        const _yearlyReservations = await getReportData('reservationGuests', {
            year,
            filterCountry: _filterCountry,
            filterType: _filterType,
            page: page,
        });
        setYearlyReservations([..._yearlyReservations]);
        setPage(page);
    };

    const filterReservations = async (_all, _domestic, _foreign, page = _page) => {
        if (_all) {
            await reloadData(year, null, null, page);
            setFilterType(null);
            setFilterCountry(null);
        } else if (props.otherSettings?.country) {
            if (_domestic) {
                setFilterType('domestic');
                setFilterCountry(props.otherSettings.country);
                await reloadData(year, props.otherSettings.country, 'domestic', page);
            } else if (_foreign) {
                setFilterType('foreign');
                setFilterCountry(props.otherSettings.country);
                await reloadData(year, props.otherSettings.country, 'foreign', page);
            }
        } else {
            toast('Country is not defined!', { type: toast.TYPE.WARNING });
        }
    };

    const headers = [
        {
            filterKey: '',
            headerTitle: window.translate('Hotel name'),
            label: '',
            type: 'hotelName',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '80px',
        },
        {
            filterKey: 'year',
            headerTitle: window.translate('Book reg.num.'),
            label: 'year',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '50px',
        },
        {
            filterKey: 'reference',
            headerTitle: window.translate('Reference Res.id'),
            label: 'reference',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '60px',
        },
        {
            filterKey: 'customerId',
            headerTitle: window.translate('Guest'),
            label: 'firstName,lastName',
            type: 'number',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '150px',
        },
        {
            filterKey: 'country',
            headerTitle: window.translate('Country'),
            label: 'country',
            type: 'text',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'nationality',
            headerTitle: window.translate('Nationality'),
            label: 'nationality',
            type: 'text',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'documentId',
            headerTitle: window.translate('Doc.id'),
            label: 'documentId',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '70px',
        },
        {
            filterKey: 'documentValidityPeriod',
            headerTitle: window.translate('Document Valid Period'),
            label: 'documentValidityPeriod',
            type: 'date',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'checkInTimestamp',
            headerTitle: window.translate('Check in'),
            label: 'checkInTimestamp',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'checkOutTimestamp',
            headerTitle: window.translate('Check out'),
            label: 'checkOutTimestamp',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'gender',
            headerTitle: window.translate('Gender'),
            label: 'gender',
            type: 'none',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '50px',
        },
        {
            filterKey: 'dateOfBirth',
            headerTitle: window.translate('Birth date'),
            label: 'dateOfBirth',
            type: 'date',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'birthTown',
            headerTitle: window.translate('Birth town'),
            label: 'birthTown',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'phoneOrMobile',
            headerTitle: window.translate('Phone'),
            label: 'phoneOrMobile',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'visaTypeAndNumber',
            headerTitle: window.translate('Visa Type And Number'),
            label: 'visaTypeAndNumber',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'visaValidityPeriod',
            headerTitle: window.translate('Visa Validity Period'),
            label: 'visaValidityPeriod',
            type: 'date',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'visaEntryDate',
            headerTitle: window.translate('Entry date'),
            label: 'visaEntryDate',
            type: 'date',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'placeEntry',
            headerTitle: window.translate('Entry place'),
            label: 'placeEntry',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'notes',
            headerTitle: window.translate('Notes'),
            label: 'notes',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
    ];

    return (
        <div className=" ">
            <PageTitle />
            <div className="justify-content-center row m-auto">
                <div
                    className="btn btn-sm btn-primary pointer mr-2"
                    onClick={() => {
                        filterReservations(true, false, false);
                    }}
                >
                    <i className="fa fa-filter" /> {window.translate('Show all')}
                </div>
                <div
                    className="btn btn-sm btn-info pointer mx-2"
                    onClick={() => {
                        filterReservations(false, true, false);
                    }}
                >
                    <i className="fa fa-filter" /> {window.translate('Domestic guests')}
                </div>
                <div
                    className="btn btn-sm btn-secondary pointer ml-2"
                    onClick={() => {
                        filterReservations(false, false, true);
                    }}
                >
                    <i className="fa fa-filter" /> {window.translate('Foreign guests')}
                </div>
            </div>
            <br />

            <ReportTable
                year={year}
                setYear={_setYear.bind(this)}
                onYearMonthChange={(filter) => {
                    /*var {year, month} = filter
                        setState({year, month});
                        reloadData(year, month);*/
                }}
                itemsArray={yearlyReservations}
                headers={headers}
                monthFilter={true}
                history={props.history}
                targetedColumnForMonthFilter={'checkInTimestamp'}
                onRowClickHandler={(row) => {
                    props.history.push(`/reservationDetails/${row.reservationId}`);
                }}
                targetedColumnForDateFilter={'checkInTimestamp'}
                showDateFilter={true}
                xlsExportHandler={(params) => {
                    params['filterCountry'] = filterCountry;
                    params['filterType'] = filterType;
                    getExcelExport('reservationGuests', params);
                }}
                loadData={(page) => {
                    reloadData(year, filterCountry, filterType, page);
                }}
                page={_page}
                listOfItems={yearlyReservations}
            ></ReportTable>
        </div>
    );
}

export default branch(
    {
        otherSettings: ['otherSettings'],
    },
    ReservationsReportList
);
