import React from 'react';
import { handleChange, saveFormData } from '../settingsActions';

const SMTP = (settings) => {
    const { smtp } = settings;

    return (
        <div className=" bg-white p-3">
            <b>{window.translate('SMTP configuration')}</b>
            <hr />

            <div className="row  pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="enabled">{window.translate('Enabled')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="enabled"
                        value={smtp?.enabled}
                        onChange={() => handleChange.bind(this, 'smtp', 'boolean')}
                        className="custom-select my-1 mr-sm-2 "
                        id="enabled"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        <option value={true}>{window.translate('Enabled')}</option>
                        <option value={false}>{window.translate('Disabled')}</option>
                    </select>
                </div>
            </div>

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="host">{window.translate('SMTP host')}:</label>{' '}
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="host"
                        value={smtp?.host}
                        onChange={() => handleChange.bind(this, 'smtp', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row  pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="port">Port:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="port"
                        value={smtp?.port}
                        onChange={handleChange.bind(this, 'smtp', 'object')}
                        className="custom-select my-1 mr-sm-2 "
                        id="port"
                    >
                        <option disabled={true} value="">
                            {window.translate('Nothing selected')}
                        </option>
                        <option value={587}>587</option>
                        <option value={465}>465</option>
                        <option value={25}>25</option>
                    </select>
                </div>
            </div>

            <div className="row  pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="secure">{window.translate('Secure')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="secure"
                        value={smtp?.secure}
                        onChange={handleChange.bind(this, 'smtp', 'object')}
                        className="custom-select my-1 mr-sm-2 "
                        id="secure"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        <option value={true}>Secure (true for port 465)</option>
                        <option value={false}>Non secure</option>
                    </select>
                </div>
            </div>

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="user">{window.translate('User')}:</label>{' '}
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="user"
                        value={smtp?.user}
                        onChange={handleChange.bind(this, 'smtp', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="pass">{window.translate('Pass')}:</label>{' '}
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="pass"
                        value={smtp?.pass}
                        onChange={() => handleChange.bind(this, 'smtp', 'object')}
                        type="password"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="text-center p-5">
                <div onClick={() => saveFormData('smtp', smtp)} className="btn btn-outline-primary pointer">
                    {window.translate('Save')}
                </div>
            </div>
        </div>
    );
};

export default SMTP;
