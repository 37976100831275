import React from 'react';
import { handleChange, saveFormData } from '../settingsActions';

const IOTGatewayIntegration = (settings) => {
    const { IOTatewayIntegration } = settings;

    return (
        <div className=" bg-white p-3 ">
            <b>{window.translate('Gateway integration')}</b>
            <hr />
            <small>
                {window.translate('Find more about IOT Gateway')}{' '}
                <a href="https://pimatico.com/business/pimatico_products/plc-gateway/"> {window.translate('here')}</a>.
            </small>
            <hr />
            <div className="row pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="enableIntegration_gw1">{window.translate('Enable GW 1')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="enableIntegration_gw1"
                        value={IOTatewayIntegration?.enableIntegration_gw1}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'boolean')}
                        className="custom-select my-1 mr-sm-2 "
                        id="enableIntegration"
                    >
                        <option value={true}>{window.translate('Enable')}</option>
                        <option value={false}>{window.translate('Disable')}</option>
                    </select>
                </div>
            </div>

            <div className="row  pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="gw1_type">{window.translate('GW 1 connection')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        disabled={!IOTatewayIntegration?.enableIntegration_gw1}
                        name="gw1_type"
                        value={IOTatewayIntegration?.gw1_type}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                        className="custom-select my-1 mr-sm-2 "
                        id="gw1_type"
                    >
                        <option value={'lan'}>LAN</option>
                        <option /*disabled={true}*/ value={'cloud'}>{window.translate('CLOUD')}</option>
                    </select>
                </div>
            </div>

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="gatewayAddress_gw1">{window.translate('GW1 address (http://address)')}:</label>{' '}
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="gatewayAddress_gw1"
                        value={
                            IOTatewayIntegration && IOTatewayIntegration.gatewayAddress_gw1
                                ? IOTatewayIntegration.gatewayAddress_gw1
                                : ''
                        }
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            {IOTatewayIntegration?.gw1_type === 'lan' ? (
                <div className="row   pt-2">
                    <div className="col-md-4">
                        {' '}
                        <label htmlFor="APIToken_gw1">GW1 API Token:</label>{' '}
                    </div>
                    <div className="col-md-6">
                        <input
                            autoComplete="off"
                            name="APIToken_gw1"
                            value={IOTatewayIntegration?.APIToken_gw1}
                            onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            ) : (
                <div className="row   pt-2">
                    <div className="col-md-4">
                        {' '}
                        <label htmlFor="gw1_slaveUuid">GW1 Slave UUID:</label>{' '}
                    </div>
                    <div className="col-md-6">
                        <input
                            autoComplete="off"
                            name="gw1_slaveUuid"
                            value={
                                IOTatewayIntegration && IOTatewayIntegration.gw1_slaveUuid
                                    ? IOTatewayIntegration.gw1_slaveUuid
                                    : ''
                            }
                            onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            )}

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="name_gw1">{window.translate('GW1 location name')}:</label>{' '}
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="name_gw1"
                        value={IOTatewayIntegration?.name_gw1}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <hr />
            <div className="row  pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="enableIntegration_gw2">{window.translate('Enable GW 2')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="enableIntegration_gw2"
                        value={IOTatewayIntegration?.enableIntegration_gw2}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'boolean')}
                        className="custom-select my-1 mr-sm-2 "
                        id="enableIntegration"
                    >
                        <option value={true}>{window.translate('Enable')}</option>
                        <option value={false}>{window.translate('Disable')}</option>
                    </select>
                </div>
            </div>

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="gw2_type">GW 2 {window.translate('connection')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="gw2_type"
                        value={IOTatewayIntegration?.gw2_type}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                        className="custom-select my-1 mr-sm-2 "
                        id="gw2_type"
                    >
                        <option value={'lan'}>LAN</option>
                        <option /*disabled={true}*/ value={'cloud'}>{window.translate('CLOUD')}</option>
                    </select>
                </div>
            </div>

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="gatewayAddress_gw2">GW2 {window.translate('address (http://address)')}:</label>{' '}
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="gatewayAddress_gw2"
                        value={IOTatewayIntegration?.gatewayAddress_gw2}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            {IOTatewayIntegration?.gw2_type === 'lan' ? (
                <div className="row   pt-2">
                    <div className="col-md-4">
                        {' '}
                        <label htmlFor="APIToken_gw2">GW2 API Token:</label>{' '}
                    </div>
                    <div className="col-md-6">
                        <input
                            autoComplete="off"
                            name="APIToken_gw2"
                            value={IOTatewayIntegration?.APIToken_gw2}
                            onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            ) : (
                <div className="row   pt-2">
                    <div className="col-md-4">
                        {' '}
                        <label htmlFor="gw2_slaveUuid">GW2 Slave UUID:</label>{' '}
                    </div>
                    <div className="col-md-6">
                        <input
                            autoComplete="off"
                            name="gw2_slaveUuid"
                            value={IOTatewayIntegration?.gw2_slaveUuid}
                            onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            )}

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="name_gw2">GW2 {window.translate('location name')}:</label>{' '}
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="name_gw2"
                        value={IOTatewayIntegration?.name_gw2}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <hr />
            <div className="row  pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="enableIntegration_gw3">{window.translate('Enable')} GW 3:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="enableIntegration_gw3"
                        value={IOTatewayIntegration?.enableIntegration_gw3}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'boolean')}
                        className="custom-select my-1 mr-sm-2 "
                        id="enableIntegration"
                    >
                        <option value={true}>{window.translate('Enable')}</option>
                        <option value={false}>{window.translate('Disable')}</option>
                    </select>
                </div>
            </div>

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="gw3_type">GW 3 {window.translate('connection')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="gw3_type"
                        value={IOTatewayIntegration?.gw3_type}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                        className="custom-select my-1 mr-sm-2 "
                        id="gw3_type"
                    >
                        <option value={'lan'}>LAN</option>
                        <option /*disabled={true}*/ value={'cloud'}>{window.translate('CLOUD')}</option>
                    </select>
                </div>
            </div>

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="gatewayAddress_gw3">
                        GW3 {window.translate('LAN address (http://address)')}:
                    </label>{' '}
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="gatewayAddress_gw3"
                        value={IOTatewayIntegration?.gatewayAddress_gw3}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            {IOTatewayIntegration?.gw2_type === 'lan' ? (
                <div className="row   pt-2">
                    <div className="col-md-4">
                        {' '}
                        <label htmlFor="APIToken_gw3">GW3 API Token:</label>{' '}
                    </div>
                    <div className="col-md-6">
                        <input
                            autoComplete="off"
                            name="APIToken_gw3"
                            value={IOTatewayIntegration?.APIToken_gw3}
                            onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            ) : (
                <div className="row   pt-2">
                    <div className="col-md-4">
                        {' '}
                        <label htmlFor="gw3_slaveUuid">GW1 Slave UUID:</label>{' '}
                    </div>
                    <div className="col-md-6">
                        <input
                            autoComplete="off"
                            name="gw3_slaveUuid"
                            value={IOTatewayIntegration?.gw3_slaveUuid}
                            onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                            type="text"
                            className="form-control"
                        />
                    </div>
                </div>
            )}

            <div className="row   pt-2">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="name_gw3">GW3 {window.translate('location name')}:</label>{' '}
                </div>
                <div className="col-md-6">
                    <input
                        autoComplete="off"
                        name="name_gw3"
                        value={IOTatewayIntegration?.name_gw3}
                        onChange={handleChange.bind(this, 'IOTatewayIntegration', 'object')}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="text-center p-5">
                {' '}
                <div
                    onClick={() => saveFormData('IOTatewayIntegration', IOTatewayIntegration)}
                    className="btn btn-outline-primary mb-4 pointer"
                >
                    {window.translate('Save')}
                </div>{' '}
            </div>
        </div>
    );
};

export default IOTGatewayIntegration;
