import React, { Component } from 'react';
import PageTitle from '../../components/pageTitle';
import { branch } from 'baobab-react/higher-order';
import QRCode from 'qrcode.react';
import _ from 'lodash';
import rest from '../../data/restWrapper';

class QrGenerator extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomInfos: props.parsedRoomInfo,
            searchTerm: '',
        };
    }

    async generateUuidForRoomInfo(roomInfoId) {
        let responseObj = await rest('/generateUuidForRoomInfo', 'POST', { roomInfoId: roomInfoId });
        let clonedStateRoomInfos = _.cloneDeep(this.state.roomInfos);
        let roomToUpdate = _.find(clonedStateRoomInfos, { id: roomInfoId });
        roomToUpdate.uuid = responseObj.createdUuid;

        this.setState({ roomInfos: clonedStateRoomInfos });
    }
    componentDidUpdate(prevProps, prevState) {
        if (prevProps) {
            if (prevProps.parsedRoomInfo.length !== this.props.parsedRoomInfo.length) {
                this.setState({
                    roomInfos: this.props.parsedRoomInfo,
                });
            }
        }
    }
    searchRoom(e) {
        this.setState({ searchTerm: e.target.value });
    }

    saveSvg(svgEl, name) {
        
        const elem = document.getElementById(svgEl)
        elem.setAttribute("xmlns", "http://www.w3.org/2000/svg");
        var svgData = elem.outerHTML;
        var preface = '<?xml version="1.0" standalone="no"?>\r\n';
        var svgBlob = new Blob([preface, svgData], {type:"image/svg+xml;charset=utf-8"});
        var svgUrl = URL.createObjectURL(svgBlob);
        var downloadLink = document.createElement("a");
        downloadLink.href = svgUrl;
        downloadLink.download = name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    }

    

    render() {
        const { searchTerm } = this.state;

        let filteredRooms = [];

        _.forEach(this.state.roomInfos, (rI) => {
            if (_.includes(rI.name.toLowerCase(), searchTerm.toLowerCase())) {
                filteredRooms.push(rI);
            }
        });

        const tileHeight = 280;
        const tileWidth = 280;

        return (
            <div >
                <PageTitle />
                        <button
                            onClick={() => {
                                this.props.history.goBack();
                            }}
                            className="btn btn-sm btn-secondary "
                        >
                            {window.translate('GO BACK')}
                        </button>
                   

                <div className="text-center d-flex justify-content-center">
                    <input
                        type="text"
                        style={{width:"300px"}}
                        className="form-control mt-2"
                        onChange={this.searchRoom.bind(this)}
                        placeholder={window.translate('Search by room name')}
                    ></input>
                </div>
                <div className="  pl-0 pr-0">
                    <div className="d-flex flex-wrap">
                        {_.map(filteredRooms, (roomInfo, key) => {
                            if (roomInfo.uuid) {
                                return (
                                    <div
                                        key={key}
                                        style={{ height: `${tileHeight}px`, width: `${tileWidth}px` }}
                                        className="m-2 d-flex flex-column justify-content-center align-items-center border"
                                    >
                                        <QRCode id={"room_" + roomInfo.name} value={roomInfo.uuid} size={250} renderAs={"svg"} />
                                        {roomInfo.name}
                                        <div onClick={() => {this.saveSvg("room_" + roomInfo.name,"room_" + roomInfo.name)}} className="pointer hover-background-white"><i className="fa fa-download"></i> SVG</div>
                                      
                                    </div>
                                );
                            } else {
                                return (
                                    <div
                                        key={key}
                                        style={{ height: `${tileHeight}px`, width: `${tileWidth}px` }}
                                        className="m-2 d-flex flex-column justify-content-center align-items-center border"
                                    >
                                        {roomInfo.name}
                                        <div
                                            onClick={this.generateUuidForRoomInfo.bind(this, roomInfo.id)}
                                            className="btn btn-secondary pointer"
                                        >
                                            {window.translate('Generate')}
                                        </div>
                                    </div>
                                );
                            }
                        })}
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        reservations: ['model', 'Reservation'],
        roomsByTags: ['monkeys', 'parsedAndTagedRoomInfo'],
        parsedRoomInfo: ['monkeys', 'parsedRoomInfo'],
        smartRoomControllers: ['model', 'SmartRoomController'],
        uiStates: ['uiStates', 'dashboard'],
        // priceListItems: ["model","PriceListItem"]
    },
    QrGenerator
);
