import React from 'react';
import { translations, dictionaryTranslate } from '@data/translations';
import DocumentHeader from './DocumentHeader';
import DocumentFooter from './DocumentFooter';
import InvoiceItems from '../invoicePresenter/InvoiceItems';
import { SingleReservationInvoiceHeader } from '../invoicePresenter/InvoiceHeaderView';
import InvoiceHeaderView from '../invoicePresenter/InvoiceHeaderView';

import InvoiceItemsSummary from '../invoicePresenter/InvoiceItemsSummary';
import InvoiceTaxSummary from '../invoicePresenter/InvoiceTaxSummary';
import PrintToolbar from './PrintToolbar';
import InvoiceFooter from './InvoiceFooter';
import { clearInvoicePrintPreview } from './controler';
import { NoteAndReclamation } from '../invoicePresenter/InvoiceItemsSummary';
import { getInvoicePropertiesNeededForInvoicePreview } from '../invoicePresenter/utils';

import {
    InvoiceItemsModel,
    InvoiceModelWithInvoiceItems,
} from '@common/modelDefinition';
import { InvoicePrintViewProps } from './PrintView';

export interface PaginateA4FrameProps extends InvoicePrintViewProps {
    page?: number;

    details?: boolean;
}

const PaginateA4Frame = (props: PaginateA4FrameProps) => {
    const {
        invoice,
        invoiceItems: invoiceItemsProps,
        invoicePreview,
        groupReservationsArray,
        page = 0,
        location,
        details = false,
        history,
    } = props;
    // details - prikaz pojedinačnih računa u sklopu grupnog - neke stavke ne prikazujemo na osnovu details propsa
    const { locale } = invoice;
    const [showGuestsNumber, setShowGuestNumber] = React.useState<boolean>(true);

    const translate = (text: string) => {
        return dictionaryTranslate(text, translations[locale as keyof typeof translations]);
    };

    const invoiceItems: InvoiceItemsModel[] =
        invoicePreview && invoicePreview.invoiceItems.length > 0 ? invoicePreview.invoiceItems : invoiceItemsProps;

    const singleReservationPreviewInvoice: InvoiceModelWithInvoiceItems | null =
        invoicePreview && invoicePreview.invoiceItems.length > 0
            ? getInvoicePropertiesNeededForInvoicePreview(invoicePreview.invoice, invoice, invoiceItems)
            : null;

    const singleReservationPreview: boolean = singleReservationPreviewInvoice !== null || details;
    //page - from 0 to x
    const itemsPerPage: number = 15;
    //firstPageInvoiceHeader
    //lastPageInvoiceFooter
    //items
    //itemsPerPage
    const firstPageInvoiceHeader = (
        <div>
            {singleReservationPreview ? (
                <SingleReservationInvoiceHeader
                    translate={translate}
                    invoice={singleReservationPreviewInvoice ? singleReservationPreviewInvoice : invoice}
                    invoiceItems={invoiceItems}
                />
            ) : (
                <InvoiceHeaderView
                    translate={translate}
                    invoice={invoice}
                    invoiceItems={invoiceItems}
                    setHTMLTitle={true}
                    showGuestsNumber={showGuestsNumber}
                    groupReservationsArray={groupReservationsArray}
                    location={location}
                />
            )}
        </div>
    );

    const lastPageInvoiceFooter = (
        <div>
            {invoicePreview && invoicePreview.invoiceItems.length > 0 && singleReservationPreviewInvoice ? (
                <React.Fragment>
                    <InvoiceItemsSummary
                        translate={translate}
                        invoice={singleReservationPreviewInvoice}
                        invoiceItems={invoiceItems}
                    />
                    <div className="text-center mt-4 mb-4 d-print-none">
                        <button
                            className="btn btn-sm btn-secondary"
                            onClick={() => {
                                clearInvoicePrintPreview();
                            }}
                        >
                            {translate('Close')}
                        </button>
                    </div>
                </React.Fragment>
            ) : (
                <React.Fragment>
                    <InvoiceItemsSummary translate={translate} invoice={invoice} invoiceItems={invoiceItems} />
                    <InvoiceTaxSummary translate={translate} invoice={invoice} invoiceItems={invoiceItems} />
                    <NoteAndReclamation translate={translate} invoice={invoice} invoiceItems={invoiceItems} />
                </React.Fragment>
            )}
            <InvoiceFooter />
        </div>
    );

    let itemsToRender: InvoiceItemsModel[] = [];
    for (let i = 0; i < itemsPerPage; i++) {
        let index = page * itemsPerPage + i;
        if (invoiceItems[index]) {
            itemsToRender.push(invoiceItems[index]);
        }
    }

    let maxPageCount: number = itemsPerPage < invoiceItems.length ? Math.floor(Number(invoiceItems.length / itemsPerPage)) : 0;
    let isFirstPage: boolean = page === 0;
    let isLastPage: boolean = page === maxPageCount;
    let isLastRender: boolean = page > maxPageCount;

    if (isLastRender) {
        return null;
    }
    return (
        <div>
            <PrintToolbar
                translate={translate}
                invoice={invoice}
                location={location}
                setShowGuestNumber={setShowGuestNumber}
                showGuestsNumber={showGuestsNumber}
                history={history}
            />
            <div className="p-3 bg-white mt-2">
                <DocumentHeader>
                    <div className="pull-left">
                        <small>
                            <small>
                                <b>
                                    {page + 1}/{maxPageCount + 1}
                                </b>
                            </small>
                        </small>
                    </div>
                </DocumentHeader>
                {isFirstPage ? firstPageInvoiceHeader : null}
                <InvoiceItems translate={translate} invoiceItems={itemsToRender} invoice={invoice} />
                {isLastPage ? lastPageInvoiceFooter : null}
                <DocumentFooter />
            </div>
            <PaginateA4Frame invoice={invoice} invoiceItems={invoiceItems} page={page + 1} location={location} />
        </div>
    );
};

export default PaginateA4Frame;
