import React from 'react';
import { branch } from 'baobab-react/higher-order';
import PageTitle from '../../components/pageTitle';
import FeatureFlag from '../../components/FeatureFlag';
import PermissionCheck from '../../components/PermissionCheck';

const MenuItem = (props) => {
    if (props.hide) {
        return null;
    }
    const { classNameSufix = '' } = props;
    return (
        <div className="col-12 col-md-6 p-0 m-0 mb-2 pull-left ">
            <div className={'m-1 rounded-sm bg-white shadow-sm h-100 ' + classNameSufix}>
                <div className="card-body">
                    <h5 className="card-title">{props.title}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{props.subtitle}</h6>
                    <p className="card-text">
                        <small>{props.body}</small>
                    </p>
                    {props.actions}
                </div>
            </div>
        </div>
    );
};

const SystemMenu = (props) => {
    const hotelRooms_rooms = (
        <a href="#/roomsAdministration" className="card-link">
            {window.translate('Rooms')}
        </a>
    );
    const hotelRooms_controllers = (
        <a href="#/srcProxies" className="card-link">
            {window.translate('Controllers')}
        </a>
    );

    const hotelRooms_SRCTemplates = (
        <a href="#/srcTemplates" className="card-link">
            {window.translate('SRC templates')}
        </a>
    );

    const hotelRooms_SOSGroups = (
        <a href="#/sosGroups" className="card-link">
            {window.translate('SOS groups')}
        </a>
    );

    const hotelRooms_posTerminal = (
        <a href="#/posTerminal" className="card-link">
            {window.translate('POS Terminal')}
        </a>
    );

    const hotelRooms_cashRegistar = (
        <a href="#/cashRegistars" className="card-link">
            {window.translate('Cash Registar')}
        </a>
    );

    const accessControl_doorsAndRules = (
        <a href="#/accessControl" className="card-link">
            {window.translate('Doors & rules')}
        </a>
    );

    const accessControl_whitelists = (
        <a href="#/whiteLists" className="card-link">
            {window.translate('Whitelists')}
        </a>
    );
    const accessControl_documentScannerDevices = (
        <a href="#/docScannerDevices" className="card-link">
            {window.translate('Document Scanners')}
        </a>
    );
    const dataFolders_customers = (
        <a href="#/customers" className="card-link">
            {window.translate('Customers')}
        </a>
    );
    const dataFolders_companies = (
        <a href="#/companies" className="card-link">
            {window.translate('Companies')}
        </a>
    );
    const dataFolders_reservations = (
        <a href="#/reservations" className="card-link">
            {window.translate('Reservations')}
        </a>
    );
    const advancedSettings_visaInfo = (
        <a href="#/visaInfo" className="card-link">
            {window.translate('Visa Info')}
        </a>
    );

    const advancedSettings_settings = (
        <a href="#/generalSettings" className="card-link">
            {window.translate('Settings')}
        </a>
    );
    const advancedSettings_users = (
        <a href="#/users" className="card-link">
            {window.translate('Users')}
        </a>
    );

    const advancedSettings_selfCheckInAdministration = (
        <a href="#/selfCheckInAdministrative" className="card-link">
            {window.translate('Self check in administration')}
        </a>
    );

    const advancedSettings_notificationTemplates = (
        <a href="#/notificationTemplateSettings" className="card-link">
            {window.translate('Notification service settings')}
        </a>
    );

    const softwareUpdates_licence = (
        <a href="#/licence" className="card-link">
            {window.translate('Licence')}
        </a>
    );

    return (
        <div className="container-fluid">
            <PageTitle />

            <div className="row">
                <PermissionCheck name="roomsPanel">
                    <MenuItem
                        title={window.translate('Rooms and hardware')}
                        body={window.translate(
                            'Create or update hotel rooms informations. Declare SRC proxyes and bind their controllers with designated room. Create predefined SRC configurations and download them to selected rooms.'
                        )}
                        actions={
                            <div>
                                <PermissionCheck name="roomsPanel_rooms">{hotelRooms_rooms}</PermissionCheck>
                                <FeatureFlag flag="sosGroupsAndLogs">
                                    <PermissionCheck name="roomsPanel_sosGroups">
                                        {hotelRooms_SOSGroups}
                                    </PermissionCheck>
                                </FeatureFlag>
                                <PermissionCheck name="roomsPanel_controllers">
                                    {' '}
                                    {hotelRooms_controllers}
                                </PermissionCheck>

                                <FeatureFlag flag="hardware">
                                    <PermissionCheck name="roomsPanel_SRCTemplates">
                                        {hotelRooms_SRCTemplates}
                                    </PermissionCheck>
                                </FeatureFlag>
                                <FeatureFlag flag="simplePosTerminal">
                                    <PermissionCheck name="roomsPanel_posTerminal">
                                        {hotelRooms_posTerminal}
                                    </PermissionCheck>
                                </FeatureFlag>
                                <FeatureFlag flag="cashRegistar">
                                    <PermissionCheck name="roomsPanel_cashRegistar">
                                        {hotelRooms_cashRegistar}
                                    </PermissionCheck>
                                </FeatureFlag>
                            </div>
                        }
                    />
                </PermissionCheck>

                <FeatureFlag flag="priceLists">
                    <PermissionCheck name="pricePanel">
                        <MenuItem
                            title={window.translate('Price lists')}
                            //classNameSufix="animated faster slideInRight"
                            body={window.translate(
                                'Create dynamic accommodation price list and bind them to rooms. Beside accommodation, you can create price lists for minibar, goods and services.'
                            )}
                            actions={
                                <div>
                                    <PermissionCheck name="pricePanel_taxes">
                                        <a href="#/priceLists/tax" className="card-link">
                                            {window.translate('Taxes')}
                                        </a>
                                    </PermissionCheck>
                                    <PermissionCheck name="pricePanel_accomodation">
                                        <a href="#/priceLists/accommodation" className="card-link">
                                            {window.translate('Accommodation')}
                                        </a>
                                    </PermissionCheck>
                                    <PermissionCheck name="pricePanel_currencies">
                                        <a href="#/priceLists/currencies" className="card-link">
                                            {window.translate('Currencies')}
                                        </a>
                                    </PermissionCheck>

                                    <PermissionCheck name="pricePanel_pricelistLookup">
                                        <a href="#/priceLists/priceListLookup" className="card-link">
                                            {window.translate('Pricelist lookup')}
                                        </a>
                                    </PermissionCheck>

                                    <FeatureFlag flag="minibar">
                                        <PermissionCheck name="pricePanel_posGoods">
                                            <a href="#/priceLists/posItem" className="card-link">
                                                {window.translate('POS goods')}
                                            </a>
                                        </PermissionCheck>
                                    </FeatureFlag>
                                </div>
                            }
                        />
                    </PermissionCheck>
                </FeatureFlag>
            </div>

            <div className="row">
                <PermissionCheck name="accessControlPanel">
                    <MenuItem
                        title={window.translate('Access control')}
                        //classNameSufix="animated faster slideInLeft"
                        body={window.translate(
                            'Define door, lifts or parking access rules. You can define access whitelist and combine them with any door. Hotel rooms are not managed here.'
                        )}
                        actions={
                            <FeatureFlag flag="hardware">
                                <FeatureFlag flag="accessControl">
                                    <PermissionCheck name="accessControlPanel_doorsAndRules">
                                        {accessControl_doorsAndRules}
                                    </PermissionCheck>
                                    <PermissionCheck name="accessControlPanel_whitelists">
                                        {accessControl_whitelists}
                                    </PermissionCheck>
                                    <PermissionCheck name="accessControlPanel_documentScannerDevices">
                                        {accessControl_documentScannerDevices}
                                    </PermissionCheck>
                                </FeatureFlag>
                            </FeatureFlag>
                        }
                    />
                </PermissionCheck>

                <PermissionCheck name="foldersPanel">
                    <MenuItem
                        title={window.translate('Data folders')}
                        //classNameSufix="animated faster slideInRight"
                        body={window.translate(
                            'Browse and create customers and companies required by reservation system.'
                        )}
                        actions={
                            <FeatureFlag flag="dataFolders">
                                <PermissionCheck name="foldersPanel_customers">{dataFolders_customers}</PermissionCheck>
                                <PermissionCheck name="foldersPanel_companies">{dataFolders_companies}</PermissionCheck>
                                <PermissionCheck name="foldersPanel_reservations">
                                    {dataFolders_reservations}
                                </PermissionCheck>
                            </FeatureFlag>
                        }
                    />
                </PermissionCheck>
            </div>

            <div className="row">
                <PermissionCheck name="advancedSettingsPanel">
                    <MenuItem
                        title={window.translate('Advanced settings')}
                        //classNameSufix="animated faster slideInLeft"
                        body={window.translate(
                            'Setup language, company informations, currency and modems configuration. Manage API keys.'
                        )}
                        actions={
                            <div>
                                <PermissionCheck name="advancedSettingsPanel_settings">
                                    {advancedSettings_settings}
                                </PermissionCheck>
                                <PermissionCheck name="advancedSettingsPanel_users">
                                    {advancedSettings_users}
                                </PermissionCheck>

                                <FeatureFlag flag="outgoingNotificationChannel">
                                    <PermissionCheck name="advancedSettingsPanel_notificationServiceSettings">
                                        {advancedSettings_notificationTemplates}
                                    </PermissionCheck>
                                </FeatureFlag>
                                <PermissionCheck name="advancedSettingsPanel_visaInfo">
                                    {advancedSettings_visaInfo}
                                </PermissionCheck>
                                <PermissionCheck name="advancedSettingsPanel_selfCheckInAdministration">
                                    {advancedSettings_selfCheckInAdministration}
                                </PermissionCheck>
                            </div>
                        }
                    />
                </PermissionCheck>

                <PermissionCheck name="licencePanel">
                    <MenuItem
                        title={window.translate('Software updates and licence')}
                        //classNameSufix="animated faster slideInRight"
                        body={window.translate(
                            'Update your software. Manage licence keys and view licence informations.'
                        )}
                        actions={
                            <div>
                                <PermissionCheck name="licencePanel_licence">{softwareUpdates_licence}</PermissionCheck>
                            </div>
                        }
                    />
                </PermissionCheck>
            </div>
        </div>
    );
};

export default branch(
    {
        // versionInfo: ['version'],
        // SRCProxies: ['model', 'SRCProxy'],
    },
    SystemMenu
);
