import React, { useState, useEffect } from 'react';
import ReportTable from '../components/ReportTable';
import PageTitle from '../components/pageTitle';
import { getReportData, getExcelExport } from '../data/reports';

function ReservationsReportList(props) {
    const [yearlyReservations, setYearlyReservations] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month] = useState(0);
    const [_page, setPage] = React.useState(0);

    useEffect(() => {
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const loadData = async (page = _page) => {
        reloadData(year, page);
    };

    const _setYear = async (e) => {
        setYear(Number(e.target.value));
        await reloadData(Number(e.target.value));
    };

    const reloadData = async (year, page) => {
        const _yearlyReservations = await getReportData('reservations', { year, page }); //await rest(`/getAllReservationsFromTo?from=${moment([year]).startOf("year").valueOf()}&to=${moment([year]).endOf("year").valueOf()}`)
        setYearlyReservations([..._yearlyReservations]);
        setPage(page)
    };

    //----------  filterKey - je property objekta,
    // headerTitle - proizvoljan string (ono što će pisati u headeru tabele),
    // label - vrijednost ćelije data rowa, u odnosu na propertyije objekta, npr za informacije o sobi ne zanima nas roomInfoId nego vrijednost roomName, ili za informacije o customeru nećemo uzeti njegov customerId nego firstName i lastName - ako se referiramo na više propertija odvajamo ih zarezom
    // type - odnosi se na tip podataka filterKey-a (trenutno podrzano: number, date, enum)
    // filterType - trenutno podrzano: sort, select i sum, proslijediti none ako ne treba vršiti nikakvu akciju nad kolonom. sort- daje mogucnost uzlaznog i silaznog sortiranja, select - daje mogucnost odabira jedne opcije, sum - ako postoji ovaj tip u podnozju tabele pojavit ce se footer gdje će biti zbroj svih podataka iz ovako oznacene kolone
    // bootstrapColumnClass - klasa za bootstrap, predvidjeno je za velicinu kolone i za to sto na kojim ekranima zelimo prikazati

    const headers = [
        {
            filterKey: 'reservationId',
            headerTitle: 'R. Id',
            label: 'reservationId',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '70px',
        },
        {
            filterKey: 'roomInfoId',
            headerTitle: window.translate('Room name'),
            label: 'roomName',
            type: 'number',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-2',
            cellWidth: '100px',
        },
        {
            filterKey: 'checkInTimestamp',
            headerTitle: window.translate('Check in'),
            label: 'checkInTimestamp',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-2',
            cellWidth: '150px',
        },
        {
            filterKey: 'checkOutTimestamp',
            headerTitle: window.translate('Check out'),
            label: 'checkOutTimestamp',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-2',
            cellWidth: '100px',
        },
        // {filterKey:"daysTotal", headerTitle:"Days stayed", label:"daysTotal",type:"number", filterType:"sum", bootstrapColumnClass:"col-md-2 d-none d-md-block" },
        {
            filterKey: 'adults',
            headerTitle: window.translate('Adults'),
            label: 'adults',
            type: 'num',
            filterType: '',
            bootstrapColumnClass: 'col-md-2 d-none d-md-block',
            cellWidth: '100px',
        },
        {
            filterKey: 'children',
            headerTitle: window.translate('Children'),
            label: 'children',
            type: 'num',
            filterType: '',
            bootstrapColumnClass: 'col-md-2 d-none d-md-block',
            cellWidth: '100px',
        },
        {
            filterKey: 'statusEnum',
            headerTitle: 'Res. status',
            label: 'statusEnum',
            type: 'text',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-2 d-none d-md-block',
            cellWidth: '200px',
        },
        {
            filterKey: 'tags',
            headerTitle: window.translate('Tags'),
            label: 'tags',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-2 d-none d-md-block',
            cellWidth: '200px',
        },
        {
            filterKey: 'invoicedStatus',
            headerTitle: window.translate('Invoiced status'),
            label: 'invoicedStatus',
            type: 'text',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-2 d-none d-md-block',
            cellWidth: '200px',
        },
        {
            filterKey: 'customerId',
            headerTitle: window.translate('Guest'),
            label: `firstName,lastName`,
            type: 'number',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-2 d-none d-md-block',
            cellWidth: '100px',
        },
    ];

    return (
        <div className="">
            <PageTitle />
            <ReportTable
                year={year}
                month={month}
                setYear={_setYear.bind(this)}
                onYearMonthChange={async (filter) => {
                    /* setYear(filter.year);
                    setMonth(filter.month);
                    await reloadData(filter.year); */
                }}
                itemsArray={yearlyReservations}
                headers={headers}
                monthFilter={true}
                history={props.history}
                showDateFilter={true}
                targetedColumnForMonthFilter={'checkInTimestamp'}
                targetedColumnForDateFilter={'checkInTimestamp'}
                onRowClickHandler={(row) => {
                    props.history.push(`/reservationDetails/${row.reservationId}`);
                }}
                xlsExportHandler={(params) => {
                    getExcelExport('reservations', params);
                }}
                loadData={(page) => {
                    loadData(page);
                }}
                page={_page}
                listOfItems={yearlyReservations}
            ></ReportTable>
        </div>
    );
}

export default ReservationsReportList;
