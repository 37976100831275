import React, { Component } from 'react';

import _ from 'lodash';
export default class RoomAttributes extends Component {
    render() {
        let roomAttributes = this.props.room.roomAttributes ? JSON.parse(this.props.room.roomAttributes) : [];
        let attributes = _.filter(this.props.attributes, (att) => {
            if (_.includes(roomAttributes, att.id)) {
                return att;
            }
        });
        let attributesList = [];
        if (!_.isEmpty(attributes)) {
            attributesList = _.map(attributes, (att, key) => {
                return (
                    <span key={key} className="mr-2 p-1">
                        <img
                            style={{ width: '14px', height: '14px', marginBottom: '3px' }}
                            className=""
                            src={att.icon}
                            alt=""
                        />
                        <small> {window.translate(att.name)}</small>
                    </span>
                );
            });
        } else {
            attributesList = null; //<span className="mr-2 p-1">No assigned attributes</span>
        }

        if (attributesList === null) {
            return null;
        }

        return <div>{attributesList}</div>;
    }
}

//required props
//content nije required
//showOnlyCloseButton nije required
