import React from 'react';
import numbro from 'numbro';
import { InvoicePresenter, TaxRecapitulation } from '@common/modelDefinition';
import { InvoiceTypeEnum } from '../checkout/interfaces';

const InvoiceTaxSummary = (props: InvoicePresenter) => {
    const { invoice, translate } = props;
    if (!invoice.taxRecapitulation) {
        return null;
    }

    const { currency, exchangeRates, totalVAT, invoiceType /* generalDiscountPercentage */ } = invoice;
    const exchangeRatesWhenInvoiceIssued: any = exchangeRates ? JSON.parse(exchangeRates) : {};
    const currencyExchangeRate: number = exchangeRatesWhenInvoiceIssued[currency]
        ? Number(exchangeRatesWhenInvoiceIssued[currency])
        : 1;
    const taxRecapitulation: TaxRecapitulation[] = JSON.parse(invoice.taxRecapitulation);
    const itemsWithTaxPercentage2: TaxRecapitulation[] = taxRecapitulation.filter((i) => Number(i.taxPercentage2) > 0);
    return (
        <small>
            <div className="row">
                <div className={itemsWithTaxPercentage2.length > 0 ? 'col-md-9' : 'col-md-6'}>
                    <b>{translate('TAX RECAPITULATION')}</b>:
                    <ul className="list-group list-group-flush border-bottom mt-1 text-right">
                        <li className="list-group-item p-0">
                            <div className="row">
                                <div
                                    className={
                                        itemsWithTaxPercentage2.length > 0 ? 'col-3 text-left' : 'col-4 text-left'
                                    }
                                >
                                    <b> {translate('Tax group')} </b>
                                </div>
                                <div className={itemsWithTaxPercentage2.length > 0 ? 'col-3' : 'col-4'}>
                                    <b>{translate('Basis')}</b>
                                </div>

                                <div className={itemsWithTaxPercentage2.length > 0 ? 'col-3' : 'col-4'}>
                                    <b>{translate('Tax')}</b>
                                </div>
                                {itemsWithTaxPercentage2.length > 0 ? (
                                    <div className="col-3">
                                        <b>{translate('Tax2')}</b>
                                    </div>
                                ) : null}
                            </div>
                        </li>

                        {invoiceType !== InvoiceTypeEnum.advance && (
                            <SubInvoiceTaxSummary
                                {...props}
                                taxRecapitulation={taxRecapitulation}
                                currencyExchangeRate={currencyExchangeRate}
                                type="geniue"
                            />
                        )}
                        {invoiceType !== InvoiceTypeEnum.advance &&
                            taxRecapitulation.filter((t) => t.taxGroup.startsWith('Avans')).length > 0 && (
                                <li className="list-group-item p-0 mt-2">
                                    <div className="text-left">
                                        <b>{translate('Advance payments')}</b>
                                    </div>
                                </li>
                            )}
                        <SubInvoiceTaxSummary
                            taxRecapitulation={taxRecapitulation}
                            currencyExchangeRate={currencyExchangeRate}
                            type="avans"
                            {...props}
                        />

                        <li style={{ borderTop: 'solid 2px black' }} className="list-group-item  p-0">
                            <div className="row">
                                <div className="col-6 text-left">
                                    <b> {translate('Total VAT')}: </b>
                                </div>
                                <div className="col-3"></div>
                                <div className="col-3">
                                    <b>
                                        {totalVAT !== null
                                            ? numbro(totalVAT * currencyExchangeRate).format({
                                                  mantissa: 2,
                                                  trimMantissa: false,
                                                  thousandSeparated: true,
                                              })
                                            : 0}{' '}
                                        {currency}
                                    </b>
                                </div>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </small>
    );
};

export interface SubInvoiceTaxSummaryProps extends InvoicePresenter {
    taxRecapitulation: TaxRecapitulation[];
    currencyExchangeRate: number;
    type: string;
}

const SubInvoiceTaxSummary = (props: SubInvoiceTaxSummaryProps) => {
    const { invoice, translate, taxRecapitulation, currencyExchangeRate, type } = props;
    const { currency } = invoice;
    let taxRecapitulationPreview: TaxRecapitulation[] =
        type === 'avans'
            ? taxRecapitulation.filter((t) => t.taxGroup.startsWith('Avans'))
            : taxRecapitulation.filter((t) => !t.taxGroup.startsWith('Avans'));

    const itemsWithTaxPercentage2: TaxRecapitulation[] = taxRecapitulation.filter((i) => Number(i.taxPercentage2) > 0);

    taxRecapitulationPreview = taxRecapitulationPreview.sort((a: TaxRecapitulation, b: TaxRecapitulation) =>
        a.totalTaxValue > b.totalTaxValue ? -1 : 1
    );

    return (
        <div>
            {taxRecapitulationPreview.map((t: TaxRecapitulation, index: number) => {
                const taxName = t.taxGroup;
                return (
                    <li key={index} className="list-group-item p-0">
                        <div className="row">
                            <div className={itemsWithTaxPercentage2.length > 0 ? 'col-3 text-left' : 'col-4 text-left'}>
                                {translate(taxName)}
                            </div>

                            <div className={itemsWithTaxPercentage2.length > 0 ? 'col-3' : 'col-4'}>
                                {numbro(t.base * Number(currencyExchangeRate)).format({
                                    mantissa: 2,
                                    trimMantissa: false,
                                    thousandSeparated: true,
                                })}{' '}
                                {currency}
                            </div>

                            <div className={itemsWithTaxPercentage2.length > 0 ? 'col-3' : 'col-4'}>
                                {numbro(t.taxValue1 ? t.taxValue1 * Number(currencyExchangeRate) : 0).format({
                                    mantissa: 2,
                                    trimMantissa: false,
                                    thousandSeparated: true,
                                })}{' '}
                                {currency}
                            </div>
                            {itemsWithTaxPercentage2.length > 0 ? (
                                <div className="col-3">
                                    {numbro(t.taxValue2 ? t.taxValue2 * Number(currencyExchangeRate) : 0).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {currency}
                                </div>
                            ) : null}
                        </div>
                    </li>
                );
            })}
        </div>
    );
};

export default InvoiceTaxSummary;
