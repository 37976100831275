import React from 'react';
import PageTitle from '../../components/pageTitle';
import { useBranch } from 'baobab-react/hooks';
import QRCode from 'qrcode.react';

function AccessControlQRGenerator(props) {
    const [searchTerm, setSearchTerm] = React.useState('');

    const { doorAccessPoints } = useBranch({
        doorAccessPoints: ['model', 'DoorAccessPoint'],
    });

    const saveSvg = (svgEl, name) => {
        const elem = document.getElementById(svgEl);
        elem.setAttribute('xmlns', 'http://www.w3.org/2000/svg');
        var svgData = elem.outerHTML;
        var preface = '<?xml version="1.0" standalone="no"?>\r\n';
        var svgBlob = new Blob([preface, svgData], { type: 'image/svg+xml;charset=utf-8' });
        var svgUrl = URL.createObjectURL(svgBlob);
        var downloadLink = document.createElement('a');
        downloadLink.href = svgUrl;
        downloadLink.download = name;
        document.body.appendChild(downloadLink);
        downloadLink.click();
        document.body.removeChild(downloadLink);
    };

    const filteredDoorAccessPoints = [];

    doorAccessPoints.forEach((dr) => {
        if (dr.name && dr.name.toLowerCase().includes(searchTerm.toLowerCase())) {
            filteredDoorAccessPoints.push(dr);
        }
    });

    const tileHeight = 280;
    const tileWidth = 280;

    return (
        <div>
            <PageTitle />
            <button
                onClick={() => {
                    props.history.goBack();
                }}
                className="btn btn-sm btn-secondary "
            >
                {window.translate('GO BACK')}
            </button>

            <div className="text-center d-flex justify-content-center">
                <input
                    type="text"
                    style={{ width: '300px' }}
                    className="form-control mt-2"
                    onChange={(e) => setSearchTerm(e.target.value)}
                    placeholder={window.translate('Search by door acces point name')}
                ></input>
            </div>

            <div className="  pl-0 pr-0">
                <div className="d-flex flex-wrap">
                    {filteredDoorAccessPoints.map((door, key) => {
                        if (door.qrCode) {
                            return (
                                <div
                                    key={key}
                                    style={{ height: `${tileHeight}px`, width: `${tileWidth}px` }}
                                    className="m-2 d-flex flex-column justify-content-center align-items-center border"
                                >
                                    <QRCode id={'door_' + door.name} value={door.qrCode} size={250} renderAs={'svg'} />
                                    {door.name}
                                    <div
                                        onClick={() => {
                                            saveSvg('door_' + door.name, 'door_' + door.name);
                                        }}
                                        className="pointer hover-background-white"
                                    >
                                        <i className="fa fa-download"></i> {window.translate('SVG')}
                                    </div>
                                </div>
                            );
                        }
                        return null;
                    })}
                </div>
            </div>
        </div>
    );
}

export default AccessControlQRGenerator;
