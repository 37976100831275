import rest from '@data/restWrapper';
import { PermissionModel,  RoleWithPermissions } from '@common/modelDefinition';
import {  stopGlobalLoading ,startGlobalLoading} from '@components/loaders/globalLoading';


const getPermissions = async (): Promise<PermissionModel[]> => {
    const permissions = await rest('/getPermissions');
    return permissions;
};

const getRolePermissions = async (role: string): Promise<RoleWithPermissions> => {
    const rolePermissions = await rest('/getRolePermissions/' + role);
    return rolePermissions;
};

const updateRolePermission = async (roleName: string, permissionId: number) => {
    const updatedRolePermission = await rest('/updateRolePermission', 'POST', { roleName, permissionId });
    return updatedRolePermission;
};

const saveOrUpdateRole = async (role: RoleWithPermissions) => {
    if (role.key) {
        const updatedRole = await rest('/saveOrUpdateRole', 'POST', role);
        return updatedRole;
    }
};

const deleteRole = async (roleKey: string) => {
    await rest('/deleteRole', 'POST', { roleKey });
};

const restoreDefaultRolesPermissions = async ()=>{
    startGlobalLoading()
    await rest('/restoreDefaultRolesPermissions', 'POST', { });   
    stopGlobalLoading()
}

export { getRolePermissions, getPermissions, updateRolePermission, saveOrUpdateRole, deleteRole, restoreDefaultRolesPermissions };
