import {
    InvoiceControlerModel,
    InvoiceModel,
    InvoiceBaobabModel,
    ReservationModelWithModelsIncluded,
    StayOverOverlap,
    PriceOfferInterval,
    PriceOffer,
    InvoiceModelWithInvoiceItems,
    GroupReservationWithReservations,
    InvoiceItemsModel,
} from '@common/modelDefinition';

import {
    InvoiceTypeEnum,
    RoomCleaningRequest,
    ApplyCurrentOffer,
    ReservationTypeBody,
    LoadCheckoutInvoice,
} from './interfaces';

import baobab from '@data/state/index';

import {
    getPendingInvoiceForReservation,
    getBelongingReservation,
    getBelongingGroupReservation,
    setReservationInvoiceProperties,
    getReservationInvoicePreview,
    receptionSetToggleStates,
    createOrJoinNotConnectedConsumptionInvoices as _createOrJoinNotConnectedConsumptionInvoices,
    saveGroupRes,
    removeReservationReferenceFromInvoice as _removeReservationReferenceFromInvoice,
} from './api';

import { saveOrUpdateReservation } from '@pages/reservations2/api';
import { checkReservationFromGroupOfferMismatch } from '@pages/reservations2/utils';
import { getInvoiceTemplate } from '../invoicePresenter/utils';

export const openInNewTab = (url: string) => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null;
};

export const validateAction = () => {
    //pendingInvoice
    const pendingInvoice: InvoiceModel = baobab.root
        .select('state', 'checkoutInvoice', 'invoice', 'pendingInvoice')
        .get();
    if (!pendingInvoice) {
        throw new Error('Action called on non pending invoice! Something is wrong!');
    }
};

export const clearInitialBaobab = (location: any | null) => {
    const checkoutDateMismatchsOnLoad: number[] = baobab.root
        .select('state', 'checkoutInvoice')
        .get('checkoutDateMismatchs');
    baobab.root.select('state', 'checkoutInvoice').set({
        invoice: {},
        invoiceItems: [],
        advanceInvoices: [], //nije da treba, ali eto
        proformaInvoices: [], //prethodno izdani predračuni
        stornoInvoices: [], //stornirani računi,
        groupReservationsArray: [],
        reservation: null,
        posTerminalInvoices: [],
        checkoutDateMismatchs: location ? [] : checkoutDateMismatchsOnLoad,
        genuineInvoices: [],
        stayoverOverlap: { overlapReservations: [], option: null, type: null },
        minibarInvoices: [],
        invoiceStornoPreview: null,
        accommodationInvoice: null,
    });
};

export const setBaobab = (invoiceBabobabModel: InvoiceBaobabModel) => {
    baobab.root.select('state', 'checkoutInvoice').set({
        ...invoiceBabobabModel,
    });
};

export const clearInvoicePreview = () => {
    baobab.root.select('state', 'checkoutInvoice', 'invoicePreview').set({ invoice: {}, invoiceItems: [] });
};

export const loadInvoicePreview = async (reservationId: number) => {
    const invoicePreviewInvoice = baobab.root.select('state', 'checkoutInvoice', 'invoicePreview', 'invoice').get();
    if (
        invoicePreviewInvoice &&
        invoicePreviewInvoice.reservationId &&
        invoicePreviewInvoice.reservationId === reservationId
    ) {
        clearInvoicePreview();
    } else {
        const { invoice, invoiceItems } = await getReservationInvoicePreview(reservationId);
        invoice.reservationId = reservationId;
        baobab.root.select('state', 'checkoutInvoice', 'invoicePreview').set({ invoice, invoiceItems });
    }
};

export const loadCheckoutInvoice = async (props: LoadCheckoutInvoice) => {
    const { reservationId = null, reservationGroupUuid = null, location = null } = props;
    if (location) {
        clearInitialBaobab(location);
    }
    clearInvoicePreview();
    const invoiceControlerModel: InvoiceControlerModel = await getPendingInvoiceForReservation(
        reservationId,
        reservationGroupUuid,
        InvoiceTypeEnum.geniue
    );

    const accommodationInvoice: InvoiceModelWithInvoiceItems = { ...invoiceControlerModel.invoice };
    accommodationInvoice.InvoiceItems = [...invoiceControlerModel.invoiceItems];
    const invoice = getInvoiceTemplate(invoiceControlerModel.invoice);

    const invoiceItems: InvoiceItemsModel[] = [];

    const reservation: ReservationModelWithModelsIncluded | null = await getBelongingReservation(reservationId);
    const groupReservation: GroupReservationWithReservations | null = await getBelongingGroupReservation(
        reservationGroupUuid
    );
    const groupReservationsArray: ReservationModelWithModelsIncluded[] = groupReservation
        ? groupReservation.Reservations
        : [];
    const stayoverOverlap = { overlapReservations: [], option: null, type: null };
    const checkoutDateMismatchsOnLoad = baobab.root.select('state', 'checkoutInvoice').get('checkoutDateMismatchs');
    const checkoutDateMismatchs = location ? invoiceControlerModel.checkoutDateMismatchs : checkoutDateMismatchsOnLoad;
    const invoiceBabobabModel: InvoiceBaobabModel = {
        ...invoiceControlerModel,
        invoice,
        invoiceItems,
        checkoutDateMismatchs,
        reservation,
        groupReservationsArray,
        stayoverOverlap,
        invoiceStornoPreview: null,
        accommodationInvoice: accommodationInvoice,
    };
    setBaobab(invoiceBabobabModel);
};

export const setInvoiceProperties = async (propertyName: string, value: string | number | boolean | null) => {
    if (
        propertyName === 'generalDiscountPercentage' ||
        propertyName === 'note' ||
        propertyName === 'partialPayment' ||
        propertyName === 'paymentAmount'
    ) {
        validateAction();
    }
    const invoiceCursor = baobab.root.select('state', 'checkoutInvoice', 'invoice');
    const invoiceItemsCursor = baobab.root.select('state', 'checkoutInvoice', 'invoiceItems');
    invoiceCursor.set([propertyName], value);
    const invoiceId = invoiceCursor.get('id');
    const pendingInvoice = invoiceCursor.get('pendingInvoice');
    const disableChangesOnNonPendingInvoice =
        !pendingInvoice && (propertyName === 'currency' || propertyName === 'locale');
    const setBaobabOnly = disableChangesOnNonPendingInvoice;
    if (!setBaobabOnly) {
        const properties = { [propertyName]: value };
        const { invoice, invoiceItems } = await setReservationInvoiceProperties(invoiceId, properties);
        invoiceCursor.set(invoice);
        invoiceItemsCursor.set(invoiceItems);
    }
};

export const setInvoiceType = async (invoiceType: number) => {
    validateAction();

    const invoiceCursor = baobab.root.select('state', 'checkoutInvoice', 'invoice');
    const editedInvoice = invoiceCursor.get();
    invoiceCursor.set('invoiceType', invoiceType);
    const invoiceId = invoiceCursor.get('id');
    const properties =
        invoiceType !== InvoiceTypeEnum.proforma && editedInvoice.typeOfPayment === null
            ? { invoiceType, typeOfPayment: 'G' }
            : { invoiceType };
    const invoiceControlerModel: InvoiceControlerModel = await setReservationInvoiceProperties(invoiceId, properties);
    const { invoice, invoiceItems, availableForAdvancePayment, posTerminalInvoices, minibarInvoices } =
        invoiceControlerModel;
    if (invoiceType === InvoiceTypeEnum.advance) {
        baobab.root.select('state', 'checkoutInvoice', 'posTerminalInvoices').set([]);
        baobab.root.select('state', 'checkoutInvoice', 'minibarInvoices').set([]);
    } else {
        baobab.root.select('state', 'checkoutInvoice', 'posTerminalInvoices').set(posTerminalInvoices);
        baobab.root.select('state', 'checkoutInvoice', 'minibarInvoices').set(minibarInvoices);
    }
    invoiceCursor.set(invoice);
    baobab.root.select('state', 'checkoutInvoice', 'invoiceItems').set(invoiceItems);
    baobab.root.select('state', 'checkoutInvoice', 'availableForAdvancePayment').set(availableForAdvancePayment);
};

export const setInvoiceCompany = async (
    companyId: number | null,
    reservationId: number | null,
    reservationGroupUuid: string | null
) => {
    const invoiceCursor = baobab.root.select('state', 'checkoutInvoice', 'invoice');
    invoiceCursor.set('companyId', companyId);
    const invoiceId = invoiceCursor.get('id');
    const properties = companyId ? { companyId } : { companyId, companyInfo: null };
    const { invoice } = await setReservationInvoiceProperties(invoiceId, properties);
    invoiceCursor.set(invoice);
    if (reservationId) {
        const reservation: ReservationModelWithModelsIncluded = baobab.root
            .select('state', 'checkoutInvoice', 'reservation')
            .get();
        const newReservation = { ...reservation };
        newReservation.companyId = companyId;
        await saveOrUpdateReservation(newReservation);
    } else if (reservationGroupUuid) {
        const groupReservation: GroupReservationWithReservations | null = await getBelongingGroupReservation(
            reservationGroupUuid
        );
        if (groupReservation) {
            const updateGroup = { ...groupReservation };
            updateGroup.companyId = companyId;
            await saveGroupRes(updateGroup);
        }
    }
};

export const issueInvoice = async (reservationId: number | null, reservationGroupUuid: string | null) => {
    validateAction();
    const posTerminalId = baobab.root.select('state', 'selectedPosTerminalId').get();
    const pendingInvoice = 0; //ovo izbacuje račun iz pending status i pokreće izdavanje računa na backendu
    const invoiceCursor = baobab.root.select('state', 'checkoutInvoice', 'invoice');
    invoiceCursor.set('pendingInvoice', pendingInvoice); //this means, invoice is no more pending!!!
    const targetInvoice = invoiceCursor.get();
    const { id, partialPayment, invoiceType, typeOfPayment } = targetInvoice;
    const availableForAdvancePayment = baobab.root.select('state', 'checkoutInvoice').get('availableForAdvancePayment'); //
    const paymentAmount =
        !partialPayment && invoiceType === InvoiceTypeEnum.advance // Ako je avans a nije parcijalno , payment amount je cjelokupni preostali iznos
            ? availableForAdvancePayment
            : invoiceCursor.get('paymentAmount');

    const properties = { pendingInvoice, paymentAmount, typeOfPayment, posTerminalId };
    await setReservationInvoiceProperties(id, properties);
    await loadCheckoutInvoice({ reservationId, reservationGroupUuid });
};

export const stornoInvoice = async (
    reclamationReason: string | null = null,
    reservationId: number | null,
    reservationGroupUuid: string | null
) => {
    //1 za reklamirani račun
    if (reclamationReason === null) {
        throw new Error('You have to give a reason for storning invoice?');
    }
    const invoiceStorno = baobab.root.select('state', 'checkoutInvoice', 'invoiceStornoPreview').get();
    const properties = { reclamation: 1, reclamationReason };
    await setReservationInvoiceProperties(invoiceStorno.id, properties);
    await loadCheckoutInvoice({ reservationId, reservationGroupUuid });
};

export const setReclamationReason = async (reclamationReason: string | null = null) => {
    const invoiceStornoPreview = baobab.root.select('state', 'checkoutInvoice', 'invoiceStornoPreview');
    invoiceStornoPreview.set('reclamationReason', reclamationReason);
};

export const setInvoiceStornoPreview = (invoice: InvoiceModelWithInvoiceItems | null) => {
    const invoiceStornoPreviewCursor = baobab.root.select('state', 'checkoutInvoice', 'invoiceStornoPreview');
    invoiceStornoPreviewCursor.set(invoice);
};

export const getIssuedStornoInvoice = (invoiceId: number) => {
    const stornoInvoices = baobab.root.select('state', 'checkoutInvoice', 'stornoInvoices').get();
    const issuedStornoInvoice: InvoiceModel =
        stornoInvoices.length > 0 ? stornoInvoices.find((i: InvoiceModel) => i.refInvoice === invoiceId) : null;
    return issuedStornoInvoice;
};

export const checkoutReservation = async (
    targetReservation: ReservationModelWithModelsIncluded,
    reservationId: number | null,
    reservationGroupUuid: string | null,
    location: any
) => {
    const stayoverOverlap: StayOverOverlap = baobab.root.select('state', 'checkoutInvoice', 'stayoverOverlap').get();
    const reservation = { ...targetReservation };

    reservation.checkOutTimestamp = new Date().getTime();
    reservation.statusEnum = 4;
    const checkForStayoverOverlapRequired =
        stayoverOverlap.overlapReservations.length > 0 && stayoverOverlap.option && stayoverOverlap.type === 'checkOut';
    try {
        if (checkForStayoverOverlapRequired) {
            await checkForStayoverOverlap(
                stayoverOverlap,
                reservation,
                targetReservation,
                reservationId,
                reservationGroupUuid,
                location
            );
        } else {
            await saveOrUpdateReservation(reservation);
            await loadCheckoutInvoice({ reservationId, reservationGroupUuid, location }); // Detektiramo PriceOfferMismatch
        }
    } catch (err: any) {
        if (err.desc && err.desc.length > 0) {
            baobab.root
                .select('state', 'checkoutInvoice', 'stayoverOverlap')
                .set({ overlapReservations: err.desc, option: null, type: 'checkOut' });
        } else {
            console.log(err);
        }
    }
};

export const checkForStayoverOverlap = async (
    stayoverOverlap: StayOverOverlap,
    reservation: ReservationModelWithModelsIncluded,
    targetReservation: ReservationModelWithModelsIncluded | null = null,
    reservationId: number | null,
    reservationGroupUuid: string | null,
    location: any
) => {
    const { overlapReservations, option, type } = stayoverOverlap;
    if (overlapReservations.length > 0) {
        const conflictReservation: ReservationModelWithModelsIncluded = { ...overlapReservations[0] };
        if (option === 1) {
            // Odjavi sada i pomakni sljedeću rezervaciju.
            const timeDiference = conflictReservation.checkOutTimestamp - conflictReservation.checkInTimestamp;
            conflictReservation.checkInTimestamp = new Date().setHours(new Date().getHours() + 1);
            conflictReservation.checkOutTimestamp = conflictReservation.checkInTimestamp + timeDiference;
            try {
                await saveOrUpdateReservation(conflictReservation);
            } catch (err: any) {
                if (err.desc && err.desc.length > 0) {
                    const overlapReservations = err.desc;
                    overlapReservations.push(conflictReservation);
                    baobab.root
                        .select('state', 'checkoutInvoice', 'stayoverOverlap')
                        .set({ overlapReservations: overlapReservations, option: null, type: type });
                } else {
                    console.log(err);
                }
            }
        } else if (option === 2) {
            //Odjavi do početka sljedeće rezervacije.
            const conflictReservationCheckIn = new Date(conflictReservation.checkInTimestamp);
            reservation.checkOutTimestamp = conflictReservationCheckIn.setHours(
                conflictReservationCheckIn.getHours() - 1
            );
        } else if (option === 3 && targetReservation) {
            const { checkOutTimestamp } = targetReservation;
            //Odjavi na inicijalni checkout.
            reservation.checkOutTimestamp = checkOutTimestamp;
        }

        await saveOrUpdateReservation(reservation);
        if (type === 'applyCurrentDate' && option === 1) {
            await loadCheckoutInvoice({ reservationId, reservationGroupUuid, location }); // Račun dobiva nove vrijednosti
        } else {
            const updatedReservation: ReservationModelWithModelsIncluded | null = await getBelongingReservation(
                reservationId
            );
            const groupReservation: GroupReservationWithReservations | null = await getBelongingGroupReservation(
                reservationGroupUuid
            );
            const groupReservationsArray: ReservationModelWithModelsIncluded[] = groupReservation
                ? groupReservation.Reservations
                : [];
            baobab.root.select('state', 'checkoutInvoice').set('reservation', updatedReservation);
            baobab.root.select('state', 'checkoutInvoice').set('groupReservationsArray', groupReservationsArray);
        }
    }
};

export const applyCurrentCheckoutDate = async (
    targetReservation: ReservationModelWithModelsIncluded,
    location: any,
    reservationId: number | null,
    reservationGroupUuid: string | null
) => {
    const stayoverOverlap: StayOverOverlap = baobab.root.select('state', 'checkoutInvoice', 'stayoverOverlap').get();
    const reservation = { ...targetReservation };
    reservation.checkOutTimestamp = new Date().getTime();
    const checkForStayoverOverlapRequired =
        stayoverOverlap.overlapReservations.length > 0 &&
        stayoverOverlap.option &&
        stayoverOverlap.type === 'applyCurrentDate';
    try {
        if (checkForStayoverOverlapRequired) {
            await checkForStayoverOverlap(
                stayoverOverlap,
                reservation,
                targetReservation,
                reservationId,
                reservationGroupUuid,
                location
            );
        } else {
            await saveOrUpdateReservation(reservation);

            await loadCheckoutInvoice({ reservationId, reservationGroupUuid, location }); // Najlakše je ponovo učitati sve, invoice ažuriran
        }
    } catch (err: any) {
        if (err.desc && err.desc.length > 0) {
            baobab.root
                .select('state', 'checkoutInvoice', 'stayoverOverlap')
                .set({ overlapReservations: err.desc, option: null, type: 'applyCurrentDate' });
        } else {
            console.log(err);
        }
    }
};

export const ignoreCheckoutDateMismatch = (reservation: ReservationModelWithModelsIncluded) => {
    const { id } = reservation;
    const checkoutDateMismatchs = baobab.root.select('state', 'checkoutInvoice', 'checkoutDateMismatchs').get();

    const newCheckoutDateMismatchs = checkoutDateMismatchs.filter((m: number) => m !== id);
    baobab.root.select('state', 'checkoutInvoice', 'checkoutDateMismatchs').set(newCheckoutDateMismatchs);
    baobab.root
        .select('state', 'checkoutInvoice', 'stayoverOverlap')
        .set({ overlapReservations: [], option: null, type: null });
};

export const stayOverActions = (
    overlapReservations: ReservationModelWithModelsIncluded[],
    option: number,
    type: string
) => {
    const selectedOption: number = baobab.root.select('state', 'checkoutInvoice', 'stayoverOverlap', 'option').get();
    if (option === selectedOption) {
        baobab.root
            .select('state', 'checkoutInvoice', 'stayoverOverlap')
            .set({ overlapReservations: overlapReservations, option: null, type: type });
    } else {
        baobab.root
            .select('state', 'checkoutInvoice', 'stayoverOverlap')
            .set({ overlapReservations: overlapReservations, option: option, type: type });
    }
};

export const makeRoomCleaningRequest = async (reservation: ReservationModelWithModelsIncluded) => {
    const roomMap = baobab.root.select(['map', 'RoomInfo']).get();
    const room = roomMap[reservation.roomInfoId];
    if (room && room.remoteDeviceId) {
        const roomCleaningRequest: RoomCleaningRequest = {
            key: 'alarm_cleaningService', //cleaning request index
            value: true, //set on state!!!
        };
        const { remoteDeviceId } = room;
        await receptionSetToggleStates(remoteDeviceId, roomCleaningRequest);
    }
};

export const addConnectedInvoice = async (
    targetInvoiceId: number,
    reservationId: number | null,
    reservationGroupUuid: string | null
) => {
    const invoiceCursor = baobab.root.select('state', 'checkoutInvoice', 'invoice');
    let connectedInvoices: number[] = [...invoiceCursor.get('connectedInvoices')]; //backend ga je već parisrao u array

    if (connectedInvoices.includes(targetInvoiceId)) {
        connectedInvoices = connectedInvoices.filter((c) => Number(c) !== targetInvoiceId);
    } else {
        connectedInvoices.push(targetInvoiceId);
    }
    const activeInvoice: InvoiceModel = invoiceCursor.get();
    const originInvoiceId: number = invoiceCursor.get('id');
    const { terminalType } = activeInvoice;
    const properties = { connectedInvoices };
    const { invoice, invoiceItems } = await setReservationInvoiceProperties(originInvoiceId, properties, terminalType);

    baobab.root.select('state', 'checkoutInvoice', 'invoice').set(invoice);
    baobab.root.select('state', 'checkoutInvoice', 'invoiceItems').set(invoiceItems);

    if (terminalType === 'reservationConsumption' && connectedInvoices.length === 0) {
        await loadCheckoutInvoice({ reservationId, reservationGroupUuid });
    }
};

export const applyCurrentOffer = async (props: ApplyCurrentOffer) => {
    const { reservationId = null, reservationGroupUuid = null, priceOfferIntervals } = props;

    if (reservationId) {
        const reservation: ReservationModelWithModelsIncluded = baobab.root
            .select('state', 'checkoutInvoice', 'reservation')
            .get();
        const {
            accommodationPriceListId,
            checkInTimestamp,
            checkOutTimestamp,
            adultsNumber,
            childrenNumber,
            id,
            rememberedAccommodationPriceInfo,
            openPrice,
            canceledReservationInvoicingStatus,
        } = reservation;
        const reservationPriceOfferIntervals: PriceOfferInterval[] = priceOfferIntervals[id];

        if (rememberedAccommodationPriceInfo) {
            const newOffer: PriceOffer = {
                accommodationPriceListId,
                checkInTimestamp,
                checkOutTimestamp,
                adultsNumber,
                childrenNumber,
                priceOfferIntervals: reservationPriceOfferIntervals,
                openPrice,
                canceledReservationInvoicingStatus,
            };

            const offers: PriceOffer[] = [...rememberedAccommodationPriceInfo];
            offers.push(newOffer);
            const res: ReservationModelWithModelsIncluded = { ...reservation };
            res.rememberedAccommodationPriceInfo = offers;
            await saveOrUpdateReservation(res);
        }
    } else if (reservationGroupUuid) {
        const groupReservationsArray: ReservationModelWithModelsIncluded[] = baobab.root
            .select('state', 'checkoutInvoice', 'groupReservationsArray')
            .get();

        const currentTime = new Date().getTime();
        for (const reservation of groupReservationsArray) {
            const {
                accommodationPriceListId,
                checkInTimestamp,
                checkOutTimestamp,
                adultsNumber,
                childrenNumber,
                id,
                rememberedAccommodationPriceInfo,
                openPrice,
                canceledReservationInvoicingStatus,
            } = reservation;
            if (rememberedAccommodationPriceInfo) {
                const offers: PriceOffer[] = [...rememberedAccommodationPriceInfo];
                const lastOffer: PriceOffer = offers[offers.length - 1];
                const reservationPriceOfferIntervals: PriceOfferInterval[] = priceOfferIntervals[id]
                    ? priceOfferIntervals[id]
                    : lastOffer.priceOfferIntervals;
                const reservationFromGroupOfferMismatch: boolean = checkReservationFromGroupOfferMismatch(
                    reservationPriceOfferIntervals,
                    lastOffer,
                    reservation
                );
                if (reservationFromGroupOfferMismatch) {
                    // Apliciramo samo na one koje zahtjevaju promjenu cijene
                    const newOffer: PriceOffer = {
                        accommodationPriceListId,
                        checkInTimestamp,
                        checkOutTimestamp,
                        adultsNumber,
                        childrenNumber,
                        priceOfferIntervals: reservationPriceOfferIntervals,
                        timestamp: currentTime,
                        openPrice,
                        canceledReservationInvoicingStatus,
                    };
                    offers.push(newOffer);
                    const res = { ...reservation };
                    res.rememberedAccommodationPriceInfo = offers;
                    res.previousGroupUuid = null;
                    await saveOrUpdateReservation(res);
                }
            }
        }
    }
};

export const undoLastOffer = async (props: ReservationTypeBody) => {
    const { reservationId = null, reservationGroupUuid = null } = props;
    if (reservationId) {
        const reservation: ReservationModelWithModelsIncluded = baobab.root
            .select('state', 'checkoutInvoice', 'reservation')
            .get();
        const { rememberedAccommodationPriceInfo } = reservation;
        if (rememberedAccommodationPriceInfo) {
            const offers = [...rememberedAccommodationPriceInfo];
            offers.pop();
            const res = { ...reservation };
            res.rememberedAccommodationPriceInfo = offers;
            await saveOrUpdateReservation(res);
        }
    } else if (reservationGroupUuid) {
        const groupReservationsArray: ReservationModelWithModelsIncluded[] = baobab.root
            .select('state', 'checkoutInvoice', 'groupReservationsArray')
            .get();

        const allReservationOffersTimestamps: number[] = [];
        groupReservationsArray.forEach((r: ReservationModelWithModelsIncluded) => {
            const { rememberedAccommodationPriceInfo } = r;
            if (rememberedAccommodationPriceInfo && rememberedAccommodationPriceInfo.length > 0) {
                const lastOffer = rememberedAccommodationPriceInfo[rememberedAccommodationPriceInfo.length - 1];
                const { timestamp } = lastOffer;
                if (timestamp) {
                    allReservationOffersTimestamps.push(timestamp);
                }
            }
        });

        const maxOfferTimestamp = Math.max(...allReservationOffersTimestamps);
        for (const reservation of groupReservationsArray) {
            const { rememberedAccommodationPriceInfo } = reservation;
            if (rememberedAccommodationPriceInfo) {
                const offers: PriceOffer[] = [...rememberedAccommodationPriceInfo];
                const lastOffer: PriceOffer = offers[offers.length - 1];
                if (lastOffer?.timestamp === maxOfferTimestamp) {
                    offers.pop();
                    const res = { ...reservation };
                    res.rememberedAccommodationPriceInfo = offers;
                    await saveOrUpdateReservation(res);
                }
            }
        }
    }
};

export const restorePreviousCheckoutDate = async (
    targetReservation: ReservationModelWithModelsIncluded,
    location: any,
    reservationId: number | null,
    reservationGroupUuid: string | null
) => {
    const { rememberedAccommodationPriceInfo } = targetReservation;
    if (rememberedAccommodationPriceInfo && rememberedAccommodationPriceInfo.length > 0) {
        const lastOffer: PriceOffer = rememberedAccommodationPriceInfo[rememberedAccommodationPriceInfo.length - 1];
        const { checkOutTimestamp } = lastOffer;
        const res: ReservationModelWithModelsIncluded = { ...targetReservation };
        res.checkOutTimestamp = checkOutTimestamp;
        await saveOrUpdateReservation(res);
        await loadCheckoutInvoice({ reservationId, reservationGroupUuid, location });
    }
};

export const createOrJoinNotConnectedConsumptionInvoices = async (
    consumptionInvoice: InvoiceModel,
    reservationId: number | null,
    reservationGroupUuid: string | null
) => {
    clearInvoicePreview();
    const { invoice, invoiceItems } = await _createOrJoinNotConnectedConsumptionInvoices({
        reservationId,
        reservationGroupUuid,
    });
    baobab.root.select('state', 'checkoutInvoice', 'invoice').set(invoice);
    baobab.root.select('state', 'checkoutInvoice', 'invoiceItems').set(invoiceItems);
    if (consumptionInvoice.id) {
        await addConnectedInvoice(consumptionInvoice.id, reservationId, reservationGroupUuid);
    }
};
export const setAccommodationInvoice = async () => {
    const invoice = baobab.root.select('state', 'checkoutInvoice', 'invoice').get();
    if (invoice.id === undefined) {
        const accommodationInvoice = baobab.root.select('state', 'checkoutInvoice', 'accommodationInvoice').get();
        baobab.root.select('state', 'checkoutInvoice', 'invoice').set(accommodationInvoice);
        baobab.root.select('state', 'checkoutInvoice', 'invoiceItems').set(accommodationInvoice.InvoiceItems);
    }
};

export const removeReservationReferenceFromInvoice = async (invoice: InvoiceModel) => {
    await _removeReservationReferenceFromInvoice(invoice);

    if (invoice.terminalType === 'posTerminal') {
        const posTerminalInvoices = baobab.root.select('state', 'checkoutInvoice', 'posTerminalInvoices').get();
        const newPosTerminalInvoices = posTerminalInvoices.filter((i: InvoiceModel) => i.id !== invoice.id)
        baobab.root
            .select('state', 'checkoutInvoice', 'posTerminalInvoices')
            .set(newPosTerminalInvoices);
    } else if (invoice.terminalType === 'minibar') {
        const minibarInvoices = baobab.root.select('state', 'checkoutInvoice', 'minibarInvoices').get();
        const newMinibarInvoices = minibarInvoices.filter((i: InvoiceModel) => i.id !== invoice.id)
        baobab.root
            .select('state', 'checkoutInvoice', 'minibarInvoices')
            .set(newMinibarInvoices);
    }
};
