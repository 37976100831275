import React from 'react';
import {
    CheckinsRooms,
    CheckinsGuests,
    CheckoutsGuests,
    CheckoutsRooms,
    HotelOccupancy,
    IncomingsDeparturesHeader,
} from './sidebarDailyInfo';
import { useBranch } from 'baobab-react/hooks';

import moment from 'moment';

const SidebarWeeklyAndMonthlyInfo = (props) => {
    const { availableToTimestamp } = props;
    const availableFromTimestamp = props.availableFromTimestamp ? props.availableFromTimestamp : moment().valueOf();

    const { locale } = useBranch({
        locale: ['locale'],
    });
    
    return (
        <React.Fragment>
            <ul className="list-group mt-3 border-top">
                <li className="list-group-item text-center p-2">
                    <b>{window.translate('Next 15 days')}</b>
                </li>

                <HotelOccupancy {...props} from={moment().startOf('day')} to={moment().add(14, 'days').endOf('day')} />
                <IncomingsDeparturesHeader />
                <li className="list-group-item text-center p-0  mt-1 d-flex">
                    <CheckinsRooms
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                    <CheckoutsRooms
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                </li>
                <li className="list-group-item text-center p-0  mt-1 d-flex">
                    <CheckinsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                    <CheckoutsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(14, 'days').endOf('day')}
                    />
                </li>
            </ul>

            <ul className="list-group mt-3 border-top">
                <li className="list-group-item text-center p-2">
                    <b>{window.translate('Next 60 days')}</b>
                </li>
                <HotelOccupancy {...props} from={moment().startOf('day')} to={moment().add(59, 'days').endOf('day')} />
                <IncomingsDeparturesHeader />
                <li className="list-group-item text-center p-0  mt-1 d-flex">
                    <CheckinsRooms
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(59, 'days').endOf('day')}
                    />
                    <CheckoutsRooms
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(59, 'days').endOf('day')}
                    />
                </li>
                <li className="list-group-item text-center p-0  mt-1 d-flex">
                    <CheckinsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(59, 'days').endOf('day')}
                    />
                    <CheckoutsGuests
                        {...props}
                        from={moment().startOf('day')}
                        to={moment().add(59, 'days').endOf('day')}
                    />
                </li>
            </ul>

            <ul className="list-group mt-3 border-top">
                <li className="list-group-item text-center p-2">
                    <div>
                        <b>{window.translate('Availability range')}</b>
                    </div>
                    {availableToTimestamp ? (
                        <div>
                            <small>
                                {moment(availableFromTimestamp).locale(locale).format('ll')} -{' '}
                                {moment(availableToTimestamp).locale(locale).format('ll')}
                            </small>
                        </div>
                    ) : null}
                </li>
                {availableToTimestamp ? (
                    <React.Fragment>
                        <HotelOccupancy
                            {...props}
                            from={moment(availableFromTimestamp).startOf('day')}
                            to={moment(availableToTimestamp).endOf('day')}
                        />
                        <IncomingsDeparturesHeader />
                        <li className="list-group-item text-center p-0  mt-1 d-flex">
                            <CheckinsRooms
                                {...props}
                                from={moment(availableFromTimestamp).startOf('day')}
                                to={moment(availableToTimestamp).endOf('day')}
                            />
                            <CheckoutsRooms
                                {...props}
                                from={moment(availableFromTimestamp).startOf('day')}
                                to={moment(availableToTimestamp).endOf('day')}
                            />
                        </li>
                        <li className="list-group-item text-center p-0  mt-1 d-flex">
                            <CheckinsGuests
                                {...props}
                                from={moment(availableFromTimestamp).startOf('day')}
                                to={moment(availableToTimestamp).endOf('day')}
                            />
                            <CheckoutsGuests
                                {...props}
                                from={moment(availableFromTimestamp).startOf('day')}
                                to={moment(availableToTimestamp).endOf('day')}
                            />
                        </li>
                    </React.Fragment>
                ) : (
                    <li className="list-group-item text-center p-1">
                        <small>{window.translate('Select a period on the dashboard toolbar!')}</small>
                    </li>
                )}
            </ul>
        </React.Fragment>
    );
};

export default SidebarWeeklyAndMonthlyInfo;
