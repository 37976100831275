import React from 'react';
import { branch } from 'baobab-react/higher-order';
import numbro from 'numbro';
import _ from 'lodash';

const TaxLabel = (props) => {
    let tax = _.find(props.taxes, (t) => t.id === props.generalTaxCode);
    if (props.parentId) {
        //tax origin is from pricelist
        let baselinePL = _.find(props.accommodationPriceLists, (pl) => pl.id === props.parentId);
        tax = _.find(props.taxes, (t) => t.id === baselinePL.generalTaxCode);
    }
    if (tax) {
        let { value, defaultCurrency = '' } = props;
        let taxValue = 0;
        let basisPrice = 0;
        let grossPrice = 0;
        let label = '';
        if (tax.taxIncludedInPrice) {
            taxValue = value - value / (1 + Number(tax.percentageAmount) / 100);
            basisPrice = value - taxValue;

            label = `${window.translate("Basis")} ${numbro(basisPrice).format({
                mantissa: 2,
                trimMantissa: true,
                thousandSeparated: true,
            })} ${defaultCurrency}; 
            ${tax.name} ${numbro(taxValue).format({
                mantissa: 2,
                trimMantissa: true,
                thousandSeparated: true,
            })} ${defaultCurrency}`;
        } else {
            basisPrice = Number(value);
            taxValue = basisPrice * (1 + Number(tax.percentageAmount) / 100) - basisPrice;
            grossPrice = basisPrice + taxValue;
            label = `${tax.name} ${numbro(taxValue).format({
                mantissa: 2,
                trimMantissa: true,
                thousandSeparated: true,
            })} ${defaultCurrency}; 
            ${window.translate("Gross amount")} ${numbro(grossPrice).format({
                mantissa: 2,
                trimMantissa: true,
                thousandSeparated: true,
            })} ${defaultCurrency}`;
        }
        return (
            <div className="text-danger clear">
                <small>
                    <small>{label}</small>
                </small>
            </div>
        );
    } else {
        return null;
    }
};

export default branch(
    {
        defaultCurrency: ['defaultCurrency'],
        taxes: ['model', 'Tax'],
        accommodationPriceLists: ['model', 'AccommodationPriceList'],
    },
    TaxLabel
);
