import React, { useEffect, useState, createRef } from 'react';
import { saveModelData } from '../../data/actions/modelActions';
import _ from 'lodash';
import { closeModal } from '@components/modal';
import pubsub from '../../data/pubsub';
import { branch } from 'baobab-react/higher-order';
import Select from 'react-select/creatable';
import { toast } from 'react-toastify';

const WhiteListForm = (props) => {
    const [newWhiteList, setWhiteList] = useState({});
    const [tagsOptions, setTagsOptions] = useState([]);
    const [tagsValues, setTagsValues] = useState([]);
    const _name = createRef();
    const _tags = createRef();

    useEffect(() => {
        const loadData = async () => {
            if (props.whitelist && props.whitelist.id) {
                const _tags = props.whitelist.tags ? props.whitelist.tags.split(';') : [];
                const _tagsValue = [];
                _tags.forEach((t) => {
                    let obj = {};
                    obj.value = t;
                    obj.label = t;
                    _tagsValue.push(obj);
                });
                setTagsValues(_tagsValue);
            }
            const _tagsOptions = [];
            props.WhiteListTags.forEach((t) => {
                let obj = {};
                obj.value = t;
                obj.label = t;
                _tagsOptions.push(obj);
            });
            setTagsOptions(_tagsOptions);
            setWhiteList(props.whitelist)
            checkValidationInputs(props.whitelist)
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const handleTagsChange = (tagsVal) => {
        let tagovi = [];
        if (tagsVal !== null) {
            tagsVal.forEach((t) => {
                tagovi.push(t.value);
            });
            tagovi = tagovi.join(';');
        } else {
            tagovi = null;
        }
        let _whiteList = { ...newWhiteList };
        _whiteList["tags"] = tagovi;
        setWhiteList(_whiteList);
        setTagsValues(tagsVal);
        checkValidationInputs(_whiteList)
    };

    const handleInputChange = (e) => {
        let _whiteList = { ...newWhiteList };
        _whiteList[e.target.name] = e.target.value;
        setWhiteList(_whiteList);
        if(e.target.name==="name"){
            checkValidationInputs(_whiteList)
        }
    };

    const checkValidationInputs = (objToValidate) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);
    }

    const clearValidationRefs = () => {
        if (_name && _name.current) {
            _name.current.className = _name.current.className.replace('is-invalid', '');
        }
        if (_tags && _tags.current) {
            _tags.current.className = _tags.current.className.replace('is-invalid', '');
        }
    };

    const markInvalidInputRefs = (validationResults) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!classes.includes('is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (classes.includes('is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    };

    const getValidationInputs = (data) => {
        const invalidInputs = [],
            validInputs = [];
        // clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(_name);
        } else {
            validInputs.push(_name);
        }
        if (data.tags === null || data.tags.trim() === '') {
            invalidInputs.push(_tags);
        } else {
            validInputs.push(_tags);
        }

        return { invalidInputs, validInputs };
    };

    const handleSubmit = () => {
        let payload = {...newWhiteList}

        if (props.whitelist) {
            payload.id = props.whitelist.id;
        }
        let validationResults = getValidationInputs(payload);
        markInvalidInputRefs(validationResults);
        if (validationResults.invalidInputs.length === 0) {
            saveModelData('WhiteList', payload).catch((err) => {
                console.warn(err);
            });
            if(!payload.id){
                toast(window.translate("Whitelist added!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
            }
            closeHandler();
        } else {
            pubsub.emit('toastMessage', window.translate('Tags filed can not be empty'), 'error');
        }
    };

    const closeHandler = () => {
        clearValidationRefs();
        closeModal();
    };

    return (
        <div className="container-fluid">
            <div className="container unselectable">
                <div className="row justify-content-center">
                    <div className="col-md-10 pl-0 pr-0  ">
                        <div className="pt-4 pb-4 pr-2 pl-2 bg-white text-md-right">
                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="APName">
                                    {window.translate('Whitelist name')}
                                </label>
                                <input
                                    ref={_name}
                                    className="col-md-8 form-control"
                                    id="APName"
                                    autoComplete="off"
                                    name="name"
                                    value={newWhiteList.name ? newWhiteList.name : ''}
                                    onChange={(e) => handleInputChange(e)}
                                    type="text"
                                />
                            </div>

                            <div className="form-group row ">
                                <label className="col-md-4 col-form-label" htmlFor="tags">
                                    {window.translate('Tags')}
                                </label>
                                <Select
                                    isMulti
                                    className="col-md-8 m-0 p-0"
                                    placeholder={window.translate('Add tags')}
                                    options={tagsOptions}
                                    onChange={handleTagsChange.bind(this)}
                                    value={tagsValues}
                                ></Select>
                            </div>

                            <div className="form-group row justify-content-center align-items-center">
                                <label className="col-md-4 col-form-label" htmlFor="description">
                                    {window.translate('Description')}
                                </label>
                                <textarea
                                    id="description"
                                    autoComplete="off"
                                    name="description"
                                    value={newWhiteList.description ? newWhiteList.description : ''}
                                    onChange={(e) => handleInputChange(e)}
                                    type="text"
                                    className="col-md-8 form-control"
                                ></textarea>
                            </div>
                            <div className="form-group row justify-content-center align-items-center pt-2">
                                <button
                                    onClick={handleSubmit.bind(this)}
                                    className="btn btn-sm btn-outline-primary mr-2"
                                >
                                    {window.translate('Save')}
                                </button>
                                <button
                                    type="button"
                                    onClick={closeHandler.bind(this)}
                                    className="btn btn-sm btn-outline-secondary"
                                    data-dismiss="modal"
                                >
                                    {window.translate('Cancel')}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default branch(
    {
        // whitelist: ['model', 'WhiteList'],
        WhiteListTags: ['tag', 'WhiteList'],
    },
    WhiteListForm
);
