import * as React from 'react';
import { ReservationController } from '../resController';
import { translate } from '@data/translations';
import { SelfCheckInLogModel } from '@common/modelDefinition';

interface SelfCheckInLogsProps {
    // reservation: Reservation;
}

function SelfCheckInLogs(props: SelfCheckInLogsProps) {
    const ctrl = new ReservationController();
    const reservation = ctrl.getActiveReservation();

    const [isExpanded, setIsExpanded] = React.useState(false);

    const selfCheckInLogs: SelfCheckInLogModel[] = reservation.SelfCheckInLogs; //kupim ove logove iz reservation modela - ne useEffecta!!!

    const headerClass = isExpanded
        ? 'pointer text-center border-bottom uppercase text-primary  p-2'
        : 'pointer text-center  uppercase text-primary p-2'; //no undelin

    if (!reservation.enableSelfCheckIn) {
        return null;
    }

    return (
        <div className="mt-2">
            <h5
                onClick={(e) => {
                    e.stopPropagation();
                    setIsExpanded(!isExpanded);
                }}
                className={headerClass}
            >
                <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                &ensp;{translate('Self check in logs')}&ensp;
                <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                {isExpanded ? (
                    <div
                        onClick={(e) => {
                            e.stopPropagation();
                            // loadData(reservation);
                        }}
                        style={{ position: 'absolute', right: 0, display: 'inline-block' }}
                        className="pr-4 "
                    >
                        <i className={'fa fa-refresh  py-1 px-2  hover-background-white'}></i>
                    </div>
                ) : null}
            </h5>

            {isExpanded ? (
                <div className="border rounded bg-white">
                    <small>
                        <div className="row text-center p-2">
                            <div className="col-4 col-md-2">{translate('Date')}</div>
                            <div className="col-4 col-md-4">{translate('Method')}</div>
                            <div className={'col-4 col-md-6 '}>{translate('Description')}</div>
                        </div>
                    </small>
                    <ul className="list-group">
                        {selfCheckInLogs.map((s, key) => {
                            const classN = s.methodSuccessfullyPassed ? '' : ' text-danger';
                            return (
                                <li key={key} className={'list-group-item ' + classN}>
                                    <div className="row text-center">
                                        <div className="col-4 col-md-2">
                                            <small>{new Date(s.timestamp).toLocaleString()}</small>
                                        </div>
                                        <div className="col-4 col-md-4 overflow-hidden">
                                            <small>{s.methodName}</small>
                                        </div>
                                        <div className={'col-4 col-md-6 '}>
                                            <small>{s.description}</small>
                                        </div>
                                    </div>
                                </li>
                            );
                        })}
                    </ul>
                </div>
            ) : null}
        </div>
    );
}

export default SelfCheckInLogs;
