import * as React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { translate } from '@data/translations';
import { ReservationController, Reservation } from '../resController';
import TabsBody from './TabsBody';
import { setModalContent } from '@components/modal';
import ShowAvailableRoomsForReservationModal from './ChangeRoomModal';
import { RoomInfoModel } from '@common/modelDefinition';
import { condoStatusCheck } from '../utils';
import { reservationEnums } from '@data/reservationStatusEnums';

const ValidIcon = () => <i className="fa fa-check-circle text-success" />;
const InvalidIcon = () => <i className="fa fa-question-circle text-danger" />;
const ctrl = new ReservationController();

interface TabsWrapperProps {
    history: any;
    activeReservationIndex: number;
    roomInfoMap: any;
}

function TabsWrapper(props: TabsWrapperProps) {
    const { roomInfoMap, history, activeReservationIndex } = props;

    const tabIndex: number = activeReservationIndex;
    const reservations = ctrl.getReservations();
    const groupReservation = ctrl.getGroupReservation();

    function validateReservation(r: Reservation) {
        const { isFullyValid } = ctrl.getReservationValidationReport(r);
        if (!isFullyValid) {
            return <InvalidIcon />;
        } else {
            return <ValidIcon />;
        }
    }

    const createNewRes = async (room: RoomInfoModel) => {
        if (room.id) {
            const { checkInTimestamp, checkOutTimestamp } = ctrl.getActiveReservation();
            await ctrl.addReservation(false, room.id, checkInTimestamp, checkOutTimestamp, 1);
        }
    };

    const addRoom = () => {
        setModalContent(
            <ShowAvailableRoomsForReservationModal
                reservation={{ ...reservations[0] }}
                changeRoomHandler={createNewRes}
            />,
            translate('Confirm'),
            false,
            'modal-lg'
        );
    };

    const { isCondoReservation } = condoStatusCheck(reservations[tabIndex]);
    const isAccommodationInvoiceIssued: boolean = reservations[tabIndex]?.genuineInvoiceIssued === 1 ? true : false;

    return (
        <div>
            <ul className="nav nav-tabs">
                {reservations.map((r, index) => {
                    const room = r.roomInfoId ? roomInfoMap[r.roomInfoId] : null;
                    if (!room) {
                        return (
                            <li
                                onClick={() => ctrl.setActiveReservationIndex(index)}
                                key={index}
                                className="nav-item pointer"
                            >
                                <div
                                    className={
                                        reservations.length > tabIndex
                                            ? index === tabIndex
                                                ? 'nav-link active'
                                                : 'nav-link '
                                            : index === tabIndex - 1
                                            ? 'nav-link active'
                                            : 'nav-link '
                                    }
                                >
                                    R-{index + 1} <InvalidIcon />
                                </div>
                            </li>
                        );
                    }

                    const removedFromGroup = groupReservation && groupReservation.uuid !== r.groupUuid;
                    return (
                        <li
                            onClick={() => {
                                ctrl.setActiveReservationIndex(index);
                            }}
                            key={index}
                            className="nav-item pointer"
                        >
                            <div
                                className={
                                    reservations.length > tabIndex
                                        ? index === tabIndex
                                            ? 'nav-link active'
                                            : 'nav-link '
                                        : index === tabIndex - 1
                                        ? 'nav-link active'
                                        : 'nav-link '
                                }
                            >
                                {room.name} ({r.adultsNumber},{r.childrenNumber}) #{r.id} {validateReservation(r)}
                                {removedFromGroup ? <i className="fa fa-toggle-up text-danger ml-2" title={translate('Removed from group')}/> : null}
                                {r.statusEnum === reservationEnums.canceled ? <i className="fa fa-toggle-up text-warning ml-2"  title={translate('Canceled')}/> : null}

                            </div>
                        </li>
                    );
                })}

                {!isCondoReservation ? (
                    <li
                        style={isAccommodationInvoiceIssued ? { pointerEvents: 'none' } : {}}
                        onClick={() => {
                            if (!isAccommodationInvoiceIssued) {
                                addRoom();
                            } else {
                                return null;
                            }
                        }}
                        className="nav-item pointer"
                    >
                        <div className={'nav-link'}>
                            <i className="fa fa-plus" />
                        </div>
                    </li>
                ) : null}
            </ul>

            <TabsBody history={history} />
        </div>
    );
}

export default branch(
    {
        roomInfoMap: ['map', 'RoomInfo'],
        activeReservationIndex: ['state', 'reservationForm2', 'activeReservationIndex'],
    },
    TabsWrapper
);