import React from 'react';
import DocumentHeader from '../../../pages/checkoutAndInvoice/printView/DocumentHeader';
import DocumentFooter from '../../../pages/checkoutAndInvoice/printView/DocumentFooter';
import { companyInfoKeyToName } from '../../../pages/generalSettings/forms/enums';
import InvoiceFooter from '../../../pages/checkoutAndInvoice/printView/InvoiceFooter';

const ReportA4Frame = ({ financialReport, companyInfo, reportType, page = 0 }) => {
    // details - prikaz pojedinačnih računa u sklopu grupnog - neke stavke ne prikazujemo na osnovu details propsa
    // const locale = 'hr';

    // const translate = (text) => {
    //     return dictionaryTranslate(text, translations[locale]);
    // };

    //page - from 0 to x
    const itemsPerPage = 16;
    //firstPageInvoiceHeader
    //lastPageInvoiceFooter
    //items
    //itemsPerPage

    const firstPageInvoiceHeader = (
        <div className="p-2">
            <ReportHeader companyInfo={companyInfo} />
            <div className="p-2 text-center border bg-light mb-2">
                <b>{window.translate(reportType)}</b>
            </div>
        </div>
    );

    const lastPageInvoiceFooter = (
        <div>
            <InvoiceFooter />
        </div>
    );

    //  let isFirstPage=true
    //  let isLastPage=true

    let itemsToRender = [];
    for (let i = 0; i < itemsPerPage; i++) {
        let index = page * itemsPerPage + i;
        if (Object.keys(financialReport)[index]) {
            itemsToRender.push(Object.keys(financialReport)[index]);
        }
    }

    let maxPageCount =
        itemsPerPage < Object.keys(financialReport).length
            ? parseInt(Object.keys(financialReport).length / itemsPerPage, 10)
            : 0;
    let isFirstPage = page === 0;
    let isLastPage = page === maxPageCount;
    let isLastRender = page > maxPageCount;

    if (isLastRender) {
        return null;
    }

    return (
        <div>
            <div className="p-3 bg-white">
                <DocumentHeader>
                    <div className="pull-left">
                        <small>
                            <small>
                                <b>
                                    {page + 1}/{maxPageCount + 1}
                                </b>
                            </small>
                        </small>
                    </div>
                </DocumentHeader>
                {isFirstPage ? firstPageInvoiceHeader : null}
                <FinancialReportItems itemsToRender={itemsToRender} financialReport={financialReport} />
                {isLastPage ? lastPageInvoiceFooter : null}
                <DocumentFooter />
            </div>
            <ReportA4Frame
                financialReport={financialReport}
                companyInfo={companyInfo}
                reportType={reportType}
                page={page + 1}
            />
        </div>
    );
};

const FinancialReportItems = ({ itemsToRender, financialReport }) => {
    return Object.keys(financialReport).map((key, index) => {
        if (itemsToRender.includes(key)) {            
            const keyName = key.includes('Avans') ? key.replace('Avans', '').split('  ') : key.split(' ');
            return !key.includes('taxGroup') && key !== 'totalGenuine' && key !== 'totalAdvance' ? (
                <div className="d-flex" key={index}>
                    <div className="col-md-3">
                        {window.translate(keyName[0])} {keyName[1]}:
                    </div>

                    <div className="col-md-9">{financialReport[key]}</div>
                </div>
            ) : (
                <div className="d-flex flex-column text-center border-top mt-2 bg-light" key={index}>
                    {window.translate(keyName[0])} {keyName[1]}
                </div>
            );
        }
        return null
    });
};

const ReportHeader = ({ companyInfo }) => {
    if (!companyInfo) {
        return null;
    }
    return (
        <div className="m-2">
            {Object.keys(companyInfo).map((key) => {
                if (key !== 'countiresNeedVisa' && key !== 'notes') {
                    return (
                        <div className="d-flex" key={key}>
                            <div className="mr-2">{companyInfoKeyToName[key]}:</div>
                            <div>{companyInfo[key]}</div>
                        </div>
                    );
                }
                return null
            })}
        </div>
    );
};

export default ReportA4Frame;
