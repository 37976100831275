import React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { InvoiceModelWithInvoiceItems, ReservationModelWithModelsIncluded, RoomInfoMap } from '@common/modelDefinition';

interface TerminalHeaderProps {
    invoice: InvoiceModelWithInvoiceItems;
    translate: any;
    roomInfoMap: RoomInfoMap;
    reservations: ReservationModelWithModelsIncluded[];
    groupReservationsArray: ReservationModelWithModelsIncluded[];
}

const TerminalHeader = (props: TerminalHeaderProps) => {
    const { invoice, translate, reservations, roomInfoMap } = props;
   
    const reservation = reservations.find((r: ReservationModelWithModelsIncluded) => r.id === invoice.reservationId);

    if (!reservation) {
        return null;
    }
    const { roomInfoId } = reservation;
    const room = roomInfoMap[roomInfoId];

    return (
        <div className="d-flex justify-content-between mt-3 p-2">
            <div className="p-2">
                <small>
                    <div>
                        <b>{translate('Reservation id')}</b>: {reservation.id}
                    </div>

                    <div>
                        <b>{translate('Room')}</b>: {room ? room.name : ''}
                    </div>
                </small>
            </div>
        </div>
    );
};

export default branch(
    {
        roomInfoMap: ['map', 'RoomInfo'],
        reservations: ['model', 'Reservation'],
    },
    TerminalHeader
);
