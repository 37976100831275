import * as React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import { translate } from '@data/translations';
import {
    reservationStatusColors,
    reservationStatusEnumsDesc,
    //  reservationInvoicedStatusEnum,
} from '@data/reservationStatusEnums';
import { ReservationController, Reservation } from '../resController';
import {
    ReservationModel,
    CompanyModel,
    CustomerModel,
    ReservationStatus /* ReservationPaymentStatus */,
    SelfServiceCheckInModel,
} from '@common/modelDefinition';

import FolderBox from '@components/documentTools/folderBox';
import DateTimeSelector from './DateTimeSelector';

import CustomerForm from '../../customers/form';
import CompanyForm from '../../company/form';
import Delete from '@components/confirmModal';

import { setModalContent } from '@components/modal';

import ReservationDateTimeSelector from './ReservationDateTimeSelector';
import MiniLoader from '@components/loaders/miniLoader';
import StatusButtons from './StatusButtons';
import { WorldLanguages } from '@data/worldLanguages';
import Tooltip from '@components/tooltip/tooltip';
import OpenPrice from './OpenPrice';

interface MainReservationInfoProps {
    reservation: Reservation;
    reservationOnLoad: Reservation;
    otherSettings: any;
    loggedUser: any;
    history: any;
    children: any;
    selfServiceCheckIn: SelfServiceCheckInModel;
    isPending: boolean;
}

enum ReservationInvoicedStatusEnum {
    '-',
    'Fully',
    'Partialy',
}

interface CompanyOrUserInfoProps {
    reservation: Reservation;
    customer: CustomerModel | null;
    company: CompanyModel | null;
}

function ReservationMadeBy(props: { reservation: Reservation }) {
    const { reservation } = props;
    const user = reservation.Recepcionist;

    if (user === null) {
        return null;
    }

    let reservationMadeByName = user.name ? `${user.name} ${user.surname} ${user.email}` : `${user.email}`;

    return (
        <small>
            <b>
                {translate('Made by')}
                {': '}
            </b>
            {reservationMadeByName}
        </small>
    );
}

function CompanyOrUserInfo(props: CompanyOrUserInfoProps) {
    const { reservation, customer, company } = props;
    const ctrl = new ReservationController();

    const editCustomer = (c: CustomerModel) => {
        setModalContent(
            <CustomerForm
                callback={(updatedCustomer: CustomerModel) => {
                    ctrl.setReservationHolder({ customer: updatedCustomer, company: company });
                }}
                customerId={c.id}
                reservation={reservation}
            />,
            translate('Edit customer'),
            false,
            'modal-xl'
        );
    };

    const editCompany = (c: CompanyModel) => {
        setModalContent(
            <CompanyForm
                callback={(updatedCompany: CompanyModel) => {
                    ctrl.setReservationHolder({ customer: customer, company: updatedCompany });
                }}
                companyId={c.id}
            />,
            translate('Edit company'),
            false,
            'modal-xl'
        );
    };

    const showDeleteModal = (cusOrComp: string) => {
        setModalContent(
            <Delete
                actionForYes={() => {
                    if (cusOrComp === 'customer') {
                        ctrl.setReservationHolder({ customer: null, company: company });
                    } else {
                        ctrl.setReservationHolder({ customer: customer, company: null });
                    }
                }}
            />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const guestName =
        reservation.customerId && reservation.Customer
            ? `${reservation.Customer.firstName} ${reservation.Customer.lastName}`
            : '';
    const companyName = reservation.companyId && reservation.Company ? `${reservation.Company.name}` : '';
    const { phoneOrMobile = '', email = '' } = reservation.customerId && customer ? customer : {};

    const noReservationHolder = !companyName && !guestName;

    return (
        <React.Fragment>
            <div className="row">
                <div className="col-md-12">
                    <b className="text-primary">{` ${guestName} `}</b>
                    <small>
                        {' '}
                        {email} {phoneOrMobile}
                    </small>
                    {customer && (customer.preferredLanguage || customer.otherLanguage) && (
                        <small>
                            {' ('}
                            <i className="fa fa-commenting-o"></i>{' '}
                            {customer && customer.preferredLanguage && (
                                <Tooltip
                                    message={
                                        translate('Preferred language') +
                                        ': ' +
                                        WorldLanguages[customer.preferredLanguage]?.name
                                    }
                                    style={{ bottom: '100%', marginLeft: '-60px' }}
                                >
                                    <small className="pointer">
                                        <strong>{customer.preferredLanguage}</strong>
                                    </small>
                                </Tooltip>
                            )}
                            {customer && customer.preferredLanguage && customer.otherLanguage && ' - '}
                            {customer && customer.otherLanguage && (
                                <Tooltip
                                    message={
                                        translate('Other language') +
                                        ': ' +
                                        WorldLanguages[customer.otherLanguage]?.name
                                    }
                                    style={{ bottom: '100%', marginLeft: '-60px' }}
                                >
                                    {' '}
                                    <small className="pointer">{customer.otherLanguage}</small>
                                </Tooltip>
                            )}
                            {')'}
                        </small>
                    )}
                    <small>{noReservationHolder ? translate('ADD RESERVATION HOLDER') : null}</small>

                    {customer && (
                        <button
                            onClick={() => editCustomer(customer)}
                            className=" ml-2 btn btn-sm btn-outline-secondary "
                        >
                            <i className="fa fa-edit" />
                        </button>
                    )}

                    {customer && (
                        <button
                            disabled={reservation.invoicedStatus !== 0}
                            onClick={() => showDeleteModal('customer')}
                            className="btn btn-sm btn-outline-secondary  ml-1"
                        >
                            <i className="fa fa-close" />
                        </button>
                    )}
                </div>
            </div>

            <div className="row mt-2">
                <div className={noReservationHolder ? 'col-12' : 'col-8'}>
                    <b className="text-primary">{`${companyName}`}</b>
                </div>
                {company && (
                    <button onClick={() => editCompany(company)} className="btn btn-sm btn-outline-secondary ">
                        <i className="fa fa-edit" />
                    </button>
                )}

                {company && (
                    <button
                        disabled={reservation.invoicedStatus !== 0}
                        onClick={() => showDeleteModal('company')}
                        className="btn btn-sm btn-outline-secondary  ml-1"
                    >
                        <i className="fa fa-close" />
                    </button>
                )}
            </div>
        </React.Fragment>
    );
}

const MainReservationInfo = (props: MainReservationInfoProps) => {
    const { reservation, reservationOnLoad, otherSettings, loggedUser, history /* selfServiceCheckIn */, isPending } =
        props;

    const ctrl = new ReservationController();

    if (!reservation.RoomInfo) {
        return <MiniLoader />;
    }
    const customer = reservation.Customer;
    const company = reservation.Company;

    const isEditForm = reservation.id ? true : false;
    const showExtendReservationButton = reservation.statusEnum === ReservationStatus.checkedIn && reservation.id;
    const { invoicedStatus, groupUuid } = reservation; // Ovdje sam puno vremena izgubio da prikazem payment status grupneRezervacije kojoj pripada rezervacija, pa sam dohvatio grupnu rezervaciju, preko akcija, seto u baobab, ali je to na kraju usporilo performanse pa ne znam hoće li se prikazivat uopće ako pripada grupi

    const onReservationInputChange = (e: any) => {
        const res = { ...reservation };
        if (e.target.name === 'statusEnum') {
            (res as any)[e.target.name] = Number(e.target.value);
            if (Number(e.target.value) === 2) {
                (res as any)['checkInTimestamp'] = new Date().getTime();
            }
        } else {
            (res as any)[e.target.name] = e.target.value;
        }
        ctrl.updateActiveReservation(res as ReservationModel);
    };

    const onFolderUpdated = async (documents: any) => {
        const res = { ...reservation };
        res.documents = documents;
        ctrl.updateActiveReservation(res as ReservationModel);
    };

    const dateTimePickHandler = (timestamp: number) => {
        const res = { ...reservation };
        res.earlyBirdValidTo = timestamp;
        ctrl.updateActiveReservation(res as ReservationModel);
    };

    let invalidateEarlyBirdsHoursBeforeCheckIn = 72;
    if (otherSettings && otherSettings.invalidateEarlyBirdsHoursBeforeCheckIn) {
        invalidateEarlyBirdsHoursBeforeCheckIn = Number(otherSettings.invalidateEarlyBirdsHoursBeforeCheckIn);
    }
    if (isNaN(invalidateEarlyBirdsHoursBeforeCheckIn)) {
        invalidateEarlyBirdsHoursBeforeCheckIn = 0;
    }

    const earlyBirdValidTo = reservation.earlyBirdValidTo
        ? reservation.earlyBirdValidTo
        : reservation.checkInTimestamp - 1000 * 60 * 60 * invalidateEarlyBirdsHoursBeforeCheckIn;

    const isCheckedInOnLoad = reservation.statusEnum === ReservationStatus.checkedIn;
    const isAutoBlocked = reservation.statusEnum === ReservationStatus.autoBlocked;

    const isAdmin = loggedUser ? ['admin', 'managerFull', 'managerBussiness'].includes(loggedUser.role) : false;

    return (
        <>
            <div className="row ">
                <div className="col-md-6">
                    <div className="mt-1">
                        <CompanyOrUserInfo reservation={reservation} customer={customer} company={company} />
                    </div>
                    <ReservationDateTimeSelector
                        reservation={reservation}
                        reservationOnLoad={reservationOnLoad}
                        onReservationUpdate={(reservation: Reservation) => {
                            const res = { ...reservation };
                            ctrl.updateActiveReservation(res);
                        }}
                    />
                    <div className="text-center mt-3">
                        {isEditForm ? (
                            <div>
                                <StatusButtons
                                    statusButtonActionHandler={(action: string) => {
                                        const res = { ...reservation };
                                        if (action === 'confirm') {
                                            res.statusEnum = ReservationStatus.confirmed;
                                            res.canceledReservationInvoicingStatus = 0;
                                        } else if (action === 'checkin') {
                                            res.statusEnum = ReservationStatus.checkedIn;
                                            res.checkInTimestamp = Date.now();
                                        } else if (action === 'cancel') {
                                            res.statusEnum = ReservationStatus.canceled;
                                            if (reservation.previousGroupUuid) {
                                                reservation.previousGroupUuid = null; //Ako je izbačena iz grupe, pa nakon tog i otkazana, izbaci je s računa
                                            }
                                        } else if (action === 'checkout') {
                                            //checkout only throught checkout form (payment)
                                        }
                                        ctrl.updateActiveReservation(res as ReservationModel);
                                    }}
                                    history={history}
                                    reservation={reservation}
                                    isPending={isPending}
                                />
                                <h5 className={' mt-2 ' + reservationStatusColors[reservation.statusEnum]}>
                                    {reservationStatusEnumsDesc[reservation.statusEnum]}{' '}
                                    {!groupUuid && (
                                        <small className="text-dark">
                                            ( <i className="fa fa-money mr-1" aria-hidden="true"></i>
                                            {translate(ReservationInvoicedStatusEnum[invoicedStatus])} )
                                        </small>
                                    )}
                                </h5>
                                {reservation.statusEnum === ReservationStatus.canceled ? (
                                    <div className="container w-75">
                                        <div className="form-group row justify-content-center align-items-center">
                                        <div className="col-md-6 text-right">
                                            <small>
                                                <span>
                                                    <label className="form-label">
                                                        {translate('Invoicing method')}
                                                    </label>
                                                </span>
                                            </small>
                                            </div>
                                            <div className="col-md-6">
                                                <select
                                                    onChange={async (e) => {
                                                        const res = { ...reservation };
                                                        res.canceledReservationInvoicingStatus = Number(e.target.value);
                                                        if (Number(e.target.value) !== 0) {
                                                            res.accommodationPriceListId = null;
                                                            res.openPrice = null;
                                                        }
                                                        ctrl.updateActiveReservation(res);
                                                    }}
                                                    name="canceledReservationInvoicingStatus"
                                                    value={reservation?.canceledReservationInvoicingStatus}
                                                    className="form-control form-control-sm"
                                                >
                                                    <React.Fragment>
                                                        {['Full price', 'Partial fee', 'No charge'].map(
                                                            (option: string, key: number) => {
                                                                return <option value={key}>{translate(option)}</option>;
                                                            }
                                                        )}
                                                    </React.Fragment>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                                {reservation.canceledReservationInvoicingStatus === 1 ? (
                                    <div className="container w-75">
                                        <div className="form-group row justify-content-center align-items-center">
                                        <div className="col-md-6 text-right">
                                            <small>
                                                <span>
                                                    <label className="form-label">
                                                        {translate('The cost of canceling a reservation')}
                                                    </label>
                                                </span>
                                            </small>
                                            </div>
                                            <div className="col-md-6 text-left">
                                                <OpenPrice reservation={reservation} />
                                            </div>
                                        </div>
                                    </div>
                                ) : null}
                            </div>
                        ) : (
                            <div className="mt-2">
                                <div className="form-inline">
                                    <div>
                                        <b>{translate('Reservation status')}:</b>&ensp;
                                    </div>
                                    <select
                                        onChange={onReservationInputChange}
                                        name="statusEnum"
                                        value={reservation.statusEnum}
                                        className="form-control form-control-sm"
                                    >
                                        <option disabled={isAutoBlocked || isCheckedInOnLoad} value={0}>
                                            {translate('Early bird')}
                                        </option>
                                        <option disabled={isCheckedInOnLoad} value={1}>
                                            {translate('Confirmed')}
                                        </option>
                                        <option disabled={reservation?.id ? false : true} value={2}>
                                            {translate('Checked in')}
                                        </option>
                                        <option disabled={(reservation?.id ? false : true) && !isAdmin} value={3}>
                                            {translate('Canceled')}
                                        </option>
                                        <option
                                            disabled={reservation.statusEnum !== ReservationStatus.closed}
                                            value={4}
                                        >
                                            {translate('Close')}
                                        </option>
                                        <option disabled={true} value={5}>
                                            {translate('Auto blocked')}
                                        </option>
                                    </select>
                                </div>

                                {showExtendReservationButton ? (
                                    <button
                                        onClick={() => {
                                            history.push('/reservationDetails/' + reservation.id);
                                        }}
                                        className="mr-2 mt-2 btn btn-sm btn-warning text-dark"
                                    >
                                        {translate('SHOW DETAILS')}
                                    </button>
                                ) : null}
                            </div>
                        )}
                    </div>

                    {reservation.statusEnum === ReservationStatus.earlyBird ? (
                        <div className="mt-2">
                            <span className="text-danger">
                                <b>{translate('Auto block early bird after')}:</b>{' '}
                            </span>
                            <DateTimeSelector
                                disabledDays={{
                                    before: new Date(),
                                    after: new Date(reservation.checkInTimestamp),
                                }}
                                dateTimePickHandler={dateTimePickHandler}
                                value={earlyBirdValidTo}
                            />
                        </div>
                    ) : null}
                </div>

                <div className="col-md-6">
                    {props.children}

                    <textarea
                        rows={3}
                        className="form-control mt-1"
                        placeholder={translate('Reservation notes')}
                        name="notes"
                        onChange={onReservationInputChange}
                        value={reservation.notes ? reservation.notes : ''}
                    ></textarea>

                    <FolderBox onFolderUpdated={onFolderUpdated} {...reservation} />
                    <ReservationMadeBy reservation={reservation} />
                    <br />

                    <small>
                        <b>
                            {translate('Date')}
                            {': '}
                        </b>
                        {moment((reservation as any).createdAt).format('LLL')}
                    </small>
                </div>
            </div>
        </>
    );
};

export default branch(
    {
        otherSettings: ['otherSettings'],
        loggedUser: ['authTokenInfo', 'user'],
        isPending: ['state', 'reservationForm2', 'isPending'],
    },
    MainReservationInfo
);
