import React from 'react';
import PageTitle from '../../components/pageTitle';
import FeatureFlag from '../../components/FeatureFlag';
import PermissionCheck from '../../components/PermissionCheck';

const MenuItem = (props) => {
    if (props.hide) {
        return null;
    }
    const { classNameSufix = '' } = props;
    return (
        <div className="col-12 col-md-6 p-0 m-0 mb-2 pull-left ">
            <div className={'m-1 rounded-sm bg-white shadow-sm h-100 ' + classNameSufix}>
                <div className="card-body">
                    <h5 className="card-title">{props.title}</h5>
                    <h6 className="card-subtitle mb-2 text-muted">{props.subtitle}</h6>
                    <p className="card-text">
                        <small>{props.body}</small>
                    </p>
                    {props.actions}
                </div>
            </div>
        </div>
    );
};

const ReportMenu = (props) => {
    return (
        <div className="container-fluid">
            <PageTitle />
            <div className="row">
                <PermissionCheck name="reservationsPanel">
                    <MenuItem
                        title={window.translate('Reservations')}
                        subtitle=""
                        body={window.translate(
                            "Get ghant view of reservations by month. Find out your 'reservation index'."
                        )}
                        actions={
                            <div>
                                <PermissionCheck name="reservationsPanel_general">
                                    <a href="#/report/reservations" className="card-link">
                                        {window.translate('Reservations report')}
                                    </a>
                                </PermissionCheck>

                                <PermissionCheck name="reservationsPanel_customerReports">
                                    <a href="#/report/reservationCustomerData" className="card-link">
                                        {window.translate('View customers data reports')}
                                    </a>
                                </PermissionCheck>

                                <PermissionCheck name="reservationsPanel_selfCheckInReports">
                                    <a href="#/report/selfCheckIn" className="card-link">
                                        {window.translate('Self check in logs')}
                                    </a>
                                </PermissionCheck>
                            </div>
                        }
                    />
                </PermissionCheck>

                <PermissionCheck name="invoicesPanel">
                    <MenuItem
                        title={window.translate('Invoices')}
                        subtitle=""
                        body={window.translate('Select and explore invoices.')}
                        actions={
                            <div>
                                <PermissionCheck name="invoicesPanel_general">
                                    <a href="#/report/invoices" className="card-link">
                                        {window.translate('Invoices report')}
                                    </a>
                                </PermissionCheck>
                                <PermissionCheck name="invoicesPanel_financialReport">
                                    {/* <a href="#/report/invoices/financialReport" className="card-link">
                                        {window.translate('Financial report')}
                                    </a> */}
                                </PermissionCheck>
                                <PermissionCheck name="invoicesPanel_cashRegistarsReport">
                                    <a href="#/report/invoices/cashRegistars" className="card-link">
                                        {window.translate('Cash registars report')}
                                    </a>
                                </PermissionCheck>
                                <PermissionCheck name="invoicesPanel_cashRegistarPaymentsReport">
                                    <a href="#/report/invoices/cashRegistarPayments" className="card-link">
                                        {window.translate('Cash registar payments')}
                                    </a>
                                </PermissionCheck>
                            </div>
                        }
                    />
                </PermissionCheck>
            </div>
            <div className="row">
                <PermissionCheck name="roomEventsPanel">
                    <MenuItem
                        title={window.translate('Room events')}
                        subtitle=""
                        body={window.translate(
                            'Explore all events for every room. Filter them by date or get CSV export.'
                        )}
                        actions={
                            <>
                                <FeatureFlag flag="hardware">
                                    <PermissionCheck name="roomEventsPanel_general">
                                        <a href="#/report/roomsEvents" className="card-link">
                                            {window.translate('Room events')}
                                        </a>
                                    </PermissionCheck>

                                    <FeatureFlag flag="sosGroupsAndLogs">
                                        <PermissionCheck name="roomEventsPanel_SOSLog">
                                            <a href="#/report/sosResponseLog" className="card-link">
                                                {window.translate('SOS log')}
                                            </a>
                                        </PermissionCheck>
                                    </FeatureFlag>
                                </FeatureFlag>

                                <FeatureFlag flag="minibar">
                                    <PermissionCheck name="roomEventsPanel_minibarReport">
                                        <a href="#/report/minibarlogBook" className="card-link">
                                            {window.translate('Minibar report')}
                                        </a>
                                    </PermissionCheck>
                                </FeatureFlag>

                                <PermissionCheck name="roomEventsPanel_householdReport">
                                    <a href="#/report/household" className="card-link">
                                        {window.translate('Household report')}
                                    </a>
                                </PermissionCheck>
                            </>
                        }
                    />
                </PermissionCheck>

                <PermissionCheck name="accessControlReportPanel">
                    <MenuItem
                        title={window.translate('Access control report')}
                        subtitle=""
                        body={window.translate('Explore door access events. Hotel rooms are not included, only doors.')}
                        actions={
                            <span>
                                <FeatureFlag flag="hardware">
                                    <FeatureFlag flag="accessControl">
                                        <PermissionCheck name="accessControlReportPanel_general">
                                            <a href="#/report/accessControl" className="card-link">
                                                {window.translate('Access contol')}
                                            </a>
                                        </PermissionCheck>
                                    </FeatureFlag>
                                </FeatureFlag>

                                <a href="#/report/audit" className="card-link">
                                    <PermissionCheck name="accessControlReportPanel_systemAudit">
                                        {window.translate('System audit')}
                                    </PermissionCheck>
                                </a>
                            </span>
                        }
                    />
                </PermissionCheck>
            </div>
        </div>
    );
};

export default ReportMenu;
