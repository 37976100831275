import React from 'react';
import { loadPendingInvoice } from './terminalViewActions';
import ItemsGridView from './parts/ItemsGrid';
import Categories from './parts/Categories';
import HeaderToolbar from './parts/HeaderToolbar';
import Sidebar from './parts/Sidebar';
import './posView.css';

const PosTerminalView = (props) => {
    const posTerminalId = props?.match?.params?.posTerminalId;

    React.useEffect(() => {
        const loadData = async () => {
            if (posTerminalId) {
                loadPendingInvoice(posTerminalId);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, [posTerminalId]);

    return (
        <div className="unselectable">
            <div className="posTerminalHeader bg-light">
                <HeaderToolbar {...props} />
            </div>
            <div className="row">
                <div className="col-8 pr-0">
                    <div className="posTerminalCategories">
                        <Categories />
                    </div>

                    <ItemsGridView {...props} />
                </div>
                <div className="col-4 pl-0">
                    <div className="posTerminalSidebar bg-light">
                        <Sidebar posTerminalId={posTerminalId} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PosTerminalView;