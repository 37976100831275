import React from 'react';
import {
    setInvoiceProperty,
    stornoInvoiceByIdAndWithReclamationNote,
    fiscalizeConcludedInvoice,
    // loadInvoicePreview,
    getIssuedStornoInvoice,
} from './controler';
import { CurrencySelection, SelectLocale } from '../checkout/CheckoutOptions';
import InlineModal from '@components/modalInline';
import ConfirmButtons from '@components/buttons/confirmButtons';
import { openInNewTab } from '../checkout/controler';
import PermissionCheck from '@components/PermissionCheck';
import { FiscalGeneralSettings, InvoiceModelWithInvoiceItems, InvoiceType } from '@common/modelDefinition';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
enum InvoiceTypeEnum {
    geniue,
    advance,
    proforma,
}

interface PrintViewToolbarProps {
    invoice: InvoiceModelWithInvoiceItems;
    refInvoice: InvoiceModelWithInvoiceItems;
    location: any;
    setShowGuestNumber: Function;
    showGuestsNumber: boolean;
    history: any;
    systemLocale: string;
    translate: any;
    fiscalGeneralSettings: FiscalGeneralSettings;
}
const PrintViewToolbar = (props: PrintViewToolbarProps) => {
    const {
        invoice,
        location,
        setShowGuestNumber,
        showGuestsNumber,
        history,
        systemLocale,
        translate,
        fiscalGeneralSettings,
        refInvoice,
    } = props;
    const { invoiceType, terminalType /* reservationGroupUuid */ } = invoice;
    const hideActions: boolean = location && location.search.lastIndexOf('noActions') !== -1 ? true : false;
    const reservationConsumption: boolean =
        invoiceType === InvoiceType.proforma && (terminalType === 'posTerminal' || terminalType === 'minibar')
            ? true
            : false;
    const showStorno: boolean =
        !hideActions && (invoiceType === InvoiceType.geniue || reservationConsumption) ? true : false;
    //Fiscal
    return (
        <React.Fragment>
            <div className="d-flex d-print-none">
                <div className="pt-2 mr-2">
                    <CurrencySelection setCurrencyViaPropsAction={setInvoiceProperty} invoice={invoice} />
                </div>
                <div className="pt-2 mr-2">
                    <SelectLocale setLocaleViaPropsAction={setInvoiceProperty} invoice={invoice} systemLocale={systemLocale} />
                </div>
                <div className="pt-2 mr-2">
                    <ShowGuestsNumber
                        setShowGuestNumber={setShowGuestNumber}
                        showGuestsNumber={showGuestsNumber}
                        translate={translate}
                    />
                </div>

                <div className="btn-group ml-auto pt-2">
                    {showStorno ? (
                        <PermissionCheck name="invoice_stornoInvoice">
                            <StornoInvoice
                                invoice={
                                    invoice //prikaži zamo za obične račune. Avanse rješi na checkout-u isključivo
                                }
                                history={history}
                                translate={translate}
                            />
                        </PermissionCheck>
                    ) : null}

                    {!hideActions && !reservationConsumption ? (
                        <FiscalizeInvoice
                            invoice={invoice}
                            translate={translate}
                            fiscalGeneralSettings={fiscalGeneralSettings}
                        />
                    ) : null}
                    <RelatedInvoice refInvoice={refInvoice} />
                </div>
            </div>
        </React.Fragment>
    );
};

interface StornoInvoiceProps {
    invoice: InvoiceModelWithInvoiceItems;
    history: any;
    translate: any;
}

const StornoInvoice = (props: StornoInvoiceProps) => {
    const { invoice, history, translate } = props;
    const { id, terminalType, reclamation } = invoice;
    const [reclamationReason, setReclamationReason] = React.useState<string | null>(null);
    const [modalVisible, setModalVisible] = React.useState<boolean>(false);
    if (!id) {
        return null;
    }
    return (
        <React.Fragment>
            <InlineModal show={modalVisible} header={translate('Storno invoice')}>
                <InlineModalStornoContent
                    id={id}
                    reclamationReason={reclamationReason}
                    terminalType={terminalType}
                    setReclamationReason={setReclamationReason}
                    setModalVisible={setModalVisible}
                    history={history}
                />
            </InlineModal>
            <button
                className="btn btn-sm btn-warning mr-1"
                disabled={reclamation !== 0 ? true : false}
                onClick={() => {
                    setModalVisible(true);
                }}
            >
                {translate('Storno invoice')}
            </button>
        </React.Fragment>
    );
};

interface FiscalizeInvoiceProps {
    invoice: InvoiceModelWithInvoiceItems;
    fiscalGeneralSettings: FiscalGeneralSettings;
    translate: any;
}

const FiscalizeInvoice = (props: FiscalizeInvoiceProps) => {
    const { invoice, fiscalGeneralSettings, translate } = props;
    const { id, invoiceType, /* reclamation,*/ fiscalId, sfJIR } = invoice;

    const [modalVisible, setModalVisible] = React.useState<boolean>(false);

    const { enableFiscalisation = false, country = '' } = fiscalGeneralSettings
        ? fiscalGeneralSettings
        : { enableFiscalisation: false, country: '' };

    if (!enableFiscalisation || !id) {
        return null;
    }

    let disabled: boolean = false;
    if (country === 'BIH') {
        if (fiscalId || invoiceType !== InvoiceTypeEnum.geniue) {
            disabled = true;
        }
    } else if (country === 'CRO') {
        if (sfJIR) {
            disabled = true;
        }
    }

    return (
        <React.Fragment>
            <InlineModal show={modalVisible} header={translate('Fiscalize invoice')}>
                <InlineModalFiscalizeContent id={id} setModalVisible={setModalVisible} translate={translate} />
            </InlineModal>
            <button
                className="btn btn-sm btn-primary mr-1"
                disabled={disabled}
                onClick={() => {
                    setModalVisible(true);
                }}
            >
                {translate('Fiscalize invoice')}
            </button>
        </React.Fragment>
    );
};

interface InlineModalStornoContentProps {
    setReclamationReason: Function;
    setModalVisible: Function;
    id: number;
    reclamationReason: string | null;
    terminalType: string;
    history: any;
}

const InlineModalStornoContent = (props: InlineModalStornoContentProps) => {
    const { id, reclamationReason, terminalType, setReclamationReason, setModalVisible, history } = props;
    return (
        <div className="container-fluid">
            <div className="d-flex justify-content-center mb-2 ">
                <textarea
                    onChange={(e) => {
                        setReclamationReason(e.target.value);
                    }}
                    // onBlur={() => setReclamationReason(localReclamationReason)}
                    value={reclamationReason ? reclamationReason : ''}
                    className="form-control"
                    placeholder="Reclamation reason"
                ></textarea>
            </div>

            <div className="text-center">
                <ConfirmButtons
                    onConfirm={async () => {
                        if (id) {
                            await stornoInvoiceByIdAndWithReclamationNote(id, reclamationReason, terminalType);
                            setModalVisible(false);
                            const issuedStornoInvoice = getIssuedStornoInvoice(id);
                            if (issuedStornoInvoice) {
                                openInNewTab(
                                    window.location.origin + `/#/invoiceView/${issuedStornoInvoice.id}?noActions`
                                );
                            }
                            history.push('/invoiceView/' + id);
                        }
                    }}
                    onCancel={() => {
                        setModalVisible(false);
                    }}
                />
            </div>
        </div>
    );
};

interface InlineModalFiscalizeContentProps {
    id: number;
    setModalVisible: Function;
    translate: any;
}
const InlineModalFiscalizeContent = (props: InlineModalFiscalizeContentProps) => {
    const { id, setModalVisible, translate } = props;
    return (
        <div className="container-fluid">
            <div className="text-center">{translate('Please confirm')}</div>
            <hr />
            <div className="text-center">
                <ConfirmButtons
                    onConfirm={async () => {
                        await fiscalizeConcludedInvoice(id);
                        setModalVisible(false);
                    }}
                    onCancel={() => {
                        setModalVisible(false);
                    }}
                />
            </div>
        </div>
    );
};

interface RelatedInvoiceProps {
    refInvoice: InvoiceModelWithInvoiceItems;
}

const RelatedInvoice = (props: RelatedInvoiceProps) => {
    const { refInvoice } = props;

    if (!refInvoice) {
        return null;
    }
    return (
        <button
            className="btn btn-sm btn-outline-primary"
            onClick={() => {
                openInNewTab(window.location.origin + `/#/invoiceView/${refInvoice.id}?noActions`);
            }}
        >
            {refInvoice.id}
        </button>
    );
};

interface ShowGuestsNumberProps {
    setShowGuestNumber: Function;
    showGuestsNumber: boolean;
    translate: any;
}

const ShowGuestsNumber = (props: ShowGuestsNumberProps) => {
    const { setShowGuestNumber, showGuestsNumber, translate } = props;
    return (
        <button
            className={showGuestsNumber ? 'btn btn-sm btn-info' : 'btn btn-sm btn-outline-info'}
            onClick={() => {
                setShowGuestNumber(!showGuestsNumber);
            }}
        >
            {translate('Print number of guests')}
        </button>
    );
};

export default branch(
    {
        systemLocale: ['locale'],
        fiscalGeneralSettings: ['fiscal', 'fiscalGeneralSettings'],
        refInvoice: ['state', 'invoicePresenter', 'refInvoice'],
    },
    PrintViewToolbar
);
