import React from 'react';
import numbro from 'numbro';
import rest from '@data/restWrapper';
import { setModalContent, closeModal } from '@components/modal';
import { loadCheckoutInvoice, applyCurrentOffer, undoLastOffer } from './controler';
import ConfirmButtons from '@components/buttons/confirmButtons';
import { condoStatusCheck, checkOfferMismatch } from '@pages/reservations2/utils';
import moment from 'moment';
import 'moment/min/locales.min';
//@ts-ignore
import _ from 'lodash';
import {
    CheckoutOptionsProps,
    ExchangeRates,
    GroupedInvoiceItems,
    CurrentOfferProps,
    InvoiceTypeEnum,
} from './interfaces';
import {
    InvoiceItemsModel,
    PriceOfferIntervalsGroupByReservationId,
    ReservationEditModel,
    ReservationModelWithModelsIncluded,
} from '@common/modelDefinition';

const CurrentOffer = (props: CheckoutOptionsProps) => {
    const { invoice, translate, invoiceItems: proformaInvoiceItems, reservation, groupReservationsArray } = props;

    const { pendingInvoice, invoiceType, terminalType, reservationId, reservationGroupUuid, id } = invoice;
    const [invoiceItems, setInvoiceItems] = React.useState<InvoiceItemsModel[]>([]);
    const [priceOfferIntervals, setPriceOfferIntervals] = React.useState<PriceOfferIntervalsGroupByReservationId>({});
    React.useEffect(() => {
        const loadOffer = async () => {
            const { invoiceItems: items, priceOfferByLinkedIntervals } = await rest('/getAccommodationOffer', 'POST', {
                reservationId,
                reservationGroupUuid,
            });
            setInvoiceItems(items);
            setPriceOfferIntervals(priceOfferByLinkedIntervals);
        };
        if (reservationId || reservationGroupUuid) {
            loadOffer().catch((err) => {
                console.warn(err);
            });
        }
    }, [reservationId, reservationGroupUuid]); //ako ikada djeca postanu faktor u cijeni, ovdjde ubaci childrenNumber

    const targetReservations: ReservationEditModel[] | ReservationModelWithModelsIncluded[] = reservation
        ? [reservation]
        : groupReservationsArray;

    let showPreviousOffer: boolean = false;
    for (const reservation of targetReservations) {
        const { rememberedAccommodationPriceInfo } = reservation;
        if (rememberedAccommodationPriceInfo && rememberedAccommodationPriceInfo.length > 1) {
            showPreviousOffer = true;
        }
    }
   
    if (terminalType !== 'reservation' || !pendingInvoice || invoiceType === InvoiceTypeEnum.advance || id === undefined) {
        // Izdan je geniue accommodation račun
        return null;
    }
    let nonCondoReservations: ReservationEditModel[] = [];
    for (const targetReservation of targetReservations) {
        const { isCondoReservation } = condoStatusCheck(targetReservation);
        if (!isCondoReservation) {
            nonCondoReservations.push(targetReservation);
        }
    }

    const offerMismatch: string[] =
        nonCondoReservations.length > 0
            ? checkOfferMismatch({
                  invoiceItems,
                  proformaInvoiceItems,
                  reservations: nonCondoReservations,
              })
            : [];

    const currentOfferProps: CurrentOfferProps = {
        invoiceItems: invoiceItems,
        invoice: invoice,
        translate: translate,
        priceOfferIntervals: priceOfferIntervals,
        reservationId,
        reservationGroupUuid,
    };
    return (
        <div className="mt-2">
            {offerMismatch.length > 0 ? (
                <div>
                    <AccommodationOffer {...currentOfferProps} />
                    <div className="text-center mt-2">
                        <PriceOfferButton {...currentOfferProps} />
                    </div>
                </div>
            ) : showPreviousOffer ? (
                <div className="text-center mt-2">
                    <PriceOfferButton {...currentOfferProps} undo={true} />
                </div>
            ) : null}
        </div>
    );
};

const AccommodationOffer = (props: CurrentOfferProps) => {
    const { translate } = props;
    return (
        <div className="">
            <b>{translate('Given price offer change detected')}:</b>
            <InvoiceItemsWithHeader {...props} />
        </div>
    );
};

const PriceOfferButton = (props: CurrentOfferProps) => {
    const { priceOfferIntervals, undo = false, translate, reservationId, reservationGroupUuid } = props;
    return (
        <button
            onClick={() =>
                setModalContent(
                    <div className="container-fluid">
                        <div className="text-center">
                            {translate('Please confirm?')}
                            <hr />
                        </div>

                        <div className="text-center">
                            <ConfirmButtons
                                onConfirm={async () => {
                                    if (!undo && priceOfferIntervals) {
                                        await applyCurrentOffer({
                                            reservationId,
                                            reservationGroupUuid,
                                            priceOfferIntervals,
                                        });
                                    } else {
                                        await undoLastOffer({
                                            reservationId,
                                            reservationGroupUuid,
                                        });
                                    }

                                    await loadCheckoutInvoice({ reservationId, reservationGroupUuid });
                                    closeModal();
                                }}
                                onCancel={() => {
                                    closeModal();
                                }}
                            />
                        </div>
                    </div>,

                    translate(!undo ? 'Apply new price' : 'Undo last offer'), // header
                    false, // showCloseButton
                    'modal-md'
                )
            }
            className={!undo ? 'btn btn-sm btn-warning' : 'btn btn-sm btn-warning'}
        >
            {translate(!undo ? 'Apply new price' : 'Undo last offer')}
        </button>
    );
};

const InvoiceItemsWithHeader = (props: CurrentOfferProps) => {
    const { invoiceItems = [], invoice, translate } = props;
    const { exchangeRates, currency, locale } = invoice;

    const exchangeRatesWhenInvoiceIssued: ExchangeRates = exchangeRates ? JSON.parse(exchangeRates) : {};
    const currencyExchangeRate: number = exchangeRatesWhenInvoiceIssued[currency]
        ? Number(exchangeRatesWhenInvoiceIssued[currency])
        : 1;
    const groupedInvoiceItems: GroupedInvoiceItems = _.groupBy(invoiceItems, 'group');
    if (!groupedInvoiceItems) {
        return null;
    }
    return (
        <div className="">
            <InvoiceItemsHeader {...props} />

            {Object.keys(groupedInvoiceItems).map((group: string, key: number) => {
                return (
                    <ul key={key} className="list-group list-group-flush mt-0 border-bottom border-top">
                        {group !== 'undefined' && group !== 'null' && (
                            <li className="list-group-item p-1 bg-light">
                                <small>
                                    <div className="text-left">{group}</div>
                                </small>
                            </li>
                        )}
                        {groupedInvoiceItems[group].map((i: InvoiceItemsModel, index: number) => {
                            return (
                                <li key={index} className="list-group-item p-1 ">
                                    <small>
                                        <div className="row d-flex justify-content-between">
                                            <div className="col-4 col-sm-4">{i.itemName.split(':')
                                                .map((text: string, index: number) => {
                                                    if (i.type === 'accommodation') {
                                                        if (index === 0) {
                                                            return translate(text)
                                                        } else if (index === 1) {
                                                            return `: ${moment(text).locale(locale).format('ll')}`
                                                        } else if (index === 2) {
                                                            return ` - ${moment(text).locale(locale).format('ll')}`
                                                        } else {
                                                            return null
                                                        }
                                                    } else {
                                                        return null
                                                    }
                                                })}</div>
                                            <div className="col-4 col-sm-2">
                                                {numbro(i.quantity).format({ mantissa: 2, trimMantissa: true })}
                                            </div>

                                            <div className="col-4 col-sm-2 text-center">
                                                {i.bruttoUnitPrice
                                                    ? numbro(i.bruttoUnitPrice * Number(currencyExchangeRate)).format({
                                                          mantissa: 2,
                                                          trimMantissa: false,
                                                          thousandSeparated: true,
                                                          // negative: 'parenthesis',
                                                      })
                                                    : ''}
                                            </div>
                                            {/*trenutnno podržavamo samo jednu poreznu grupu, ali ako bude trebalo više, spremni smo*/}

                                            <div className="col-4 col-sm-2 text-right">
                                                {i.tax1ValueWithDiscount
                                                    ? numbro(
                                                          i.tax1ValueWithDiscount * Number(currencyExchangeRate)
                                                      ).format({
                                                          mantissa: 2,
                                                          trimMantissa: false,
                                                          thousandSeparated: true,
                                                          //  negative: 'parenthesis',
                                                      })
                                                    : ''}
                                            </div>

                                            <div className="col-4 col-sm-2 text-right">
                                                {i.bruttoValueWithDiscount
                                                    ? numbro(
                                                          i.bruttoValueWithDiscount * Number(currencyExchangeRate)
                                                      ).format({
                                                          mantissa: 2,
                                                          trimMantissa: false,
                                                          thousandSeparated: true,
                                                      })
                                                    : ''}
                                            </div>
                                        </div>
                                    </small>
                                </li>
                            );
                        })}
                    </ul>
                );
            })}
        </div>
    );
};

const InvoiceItemsHeader = (props: CurrentOfferProps) => {
    const { translate } = props;
    return (
        <ul className="list-group list-group-flush mt-2 border-top">
            <li className="list-group-item p-1">
                <div className="row d-flex justify-content-between">
                    <div className="col-4 col-sm-4">
                        <b> {translate('Name')} </b>
                    </div>
                    <div className="col-4 col-sm-2">
                        <b> {translate('Quantity')} </b>
                    </div>

                    <div className="col-4 col-sm-2 text-center">
                        <b> {translate('Retail price')} </b>
                    </div>

                    <div className="col-4 col-sm-2 text-right">
                        <b> {translate('Tax')} </b>
                    </div>

                    <div className="col-4 col-sm-2 text-right">
                        <b> {translate('Amount')} </b>
                    </div>
                </div>
            </li>
        </ul>
    );
};

export default CurrentOffer;
