import React from 'react';
import { branch } from 'baobab-react/higher-order';
import PageTitle from '../../components/pageTitle';
import _ from 'lodash';
import { setModalContent } from '@components/modal';
import SetResetPasswordForm from '../users/setResetPasswordForm';


const Users = (props) => {
    const showSetResetPassword = async(userObject) => {
        setModalContent(<SetResetPasswordForm user={userObject} type="userProfile" />, window.translate("Confirm"), false, 'modal-md');
    }

    return (
        <div className="">
            <PageTitle />
            <div className="row mt-5">
                <div className="col-md-6 mx-auto text-center">
                    <h3>{props.loggedUser.email}</h3>
                    <h4>
                        {props.loggedUser.name} {props.loggedUser.surname}
                    </h4>

                    <h5>
                        {window.translate("Role")}: {props.loggedUser.role}
                    </h5>

                    <button
                        onClick={showSetResetPassword.bind(this,_.clone(props.loggedUser))}
                        className="btn btn-sm btn-info"
                    >
                        {window.translate("CHANGE PASSWORD")}
                    </button>
                </div>
            </div>
        </div>
    );
}

export default branch(
    {
        loggedUser: ['authTokenInfo', 'user'],
    },
    Users
);
