import React from 'react';
import PageTitle from '../../components/pageTitle';
import { useBranch } from 'baobab-react/hooks';
import rest from '../../data/restWrapper';

function SelfCheckInAdministrative(props) {
    const [notificationTemplates, setNotificationTemplates] = React.useState(null);
    const [hardwareTemplate, setHardwareTemplate] = React.useState(null);
    const [softwareTemplate, setSoftwareTemplate] = React.useState(null);

    const { doorAccessPoints, docScannDevices } = useBranch({
        doorAccessPoints: ['model', 'DoorAccessPoint'],
        docScannDevices: ['model', 'DocumentScannerDevice'],
    });

    React.useEffect(() => {
        const loadData = async () => {
            try {
                const emailTemplate = await rest('/getGeneralSettingByKey/emailTemplate');
                const smsTemplate = await rest('/getGeneralSettingByKey/smsTemplate');
                if (emailTemplate || smsTemplate) {
                    setNotificationTemplates(true);
                } else {
                    setNotificationTemplates(false);
                }
                const _templates = await rest('/NotificationTemplate');
                if (_templates.length > 0) {
                    const _hardwareTemplate = _templates.find((t) => t.uniqueName === 'selfServiceCheckInHardware');
                    setHardwareTemplate(_hardwareTemplate);
                    const _softwareTemplate = _templates.find((t) => t.uniqueName === 'selfServiceCheckInSoftware');
                    setSoftwareTemplate(_softwareTemplate);
                }
            } catch (err) {
                console.warn('err', err);
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, []);

    const connectedDoors = doorAccessPoints && doorAccessPoints.find((d) => d.documentScannerDeviceReference !== null);

    return (
        <div>
            <PageTitle />
            <div className="row ">
                <div className="col-12 ">
                    <button
                        onClick={() => {
                            props.history.goBack();
                        }}
                        className="btn btn-sm btn-secondary mr-1 mb-2 "
                    >
                        {window.translate('GO BACK')}
                    </button>
                </div>
            </div>
            <div className="row ">
                <div className="col-4 m-auto bg-white border rounded shadow-sm mt-2 p-3 text-center">
                    <h5>{window.translate('NOTIFICATION SETTINGS')}</h5>
                    <br />
                    <h6>
                        <a
                            href="#/notificationTemplateSettings?admin"
                            className={notificationTemplates ? 'card-link ' : 'text-danger'}
                        >
                            {window.translate('Set email and sms service settings')}.
                        </a>{' '}
                    </h6>
                    <h6>
                        {window.translate('This is ')}
                        <b>{window.translate('primary step')}</b> {window.translate(' for any of self check in types')}.
                    </h6>
                </div>
            </div>
            <br />
            <div className="row justify-content-center mt-5">
                <div className="col-3 mr-5 bg-white border rounded shadow-sm p-3 text-center">
                    <h6>{window.translate('CARD SELF CHECK IN')}</h6>
                    <br />
                    <h6>
                        {window.translate('Set')}{' '}
                        <a
                            href="#/notificationTemplates?hardware"
                            className={hardwareTemplate ? 'card-link' : 'text-danger'}
                        >
                            {window.translate('hardware notification template')}
                        </a>{' '}
                        {window.translate('for card self check in')}.
                    </h6>
                </div>
                <div className="col-3 mx-5 bg-white border rounded shadow-sm p-3 text-center">
                    <h6>{window.translate('DOCUMENT SCANNER SELF CHECK IN')}</h6>
                    <br />
                    <h6>
                        {window.translate('Initialize')}{' '}
                        <a
                            href="#/docScannerDevices?selfCheckIn"
                            className={docScannDevices && docScannDevices.length > 0 ? 'card-link' : 'text-danger'}
                        >
                            {window.translate('document scanner device')}
                        </a>{' '}
                        {window.translate('for self check in')}.
                    </h6>
                    <h6>
                        {window.translate('Connect document scanner device to the some')}{' '}
                        <a href="#/accessControl?selfCheckIn" className={connectedDoors ? 'card-link' : 'text-danger'}>
                            {window.translate('access point')}
                        </a>
                        .
                    </h6>
                </div>
                <div className="col-3 ml-5 bg-white border rounded shadow-sm p-3 text-center">
                    <h6>{window.translate('QR CODE SELF CHECK IN')}</h6>
                    <br />
                    <h6>
                        {window.translate('Set')}{' '}
                        <a
                            href="#/notificationTemplates?software"
                            className={softwareTemplate ? 'card-link' : 'text-danger'}
                        >
                            {window.translate('software notification template')}
                        </a>{' '}
                        {window.translate('for qr self check in')}.
                    </h6>
                </div>
            </div>
        </div>
    );
}

export default SelfCheckInAdministrative;
