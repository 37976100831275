import React, { Component, useEffect, useState } from 'react';
import { branch } from 'baobab-react/higher-order';
import PropTypes from 'prop-types';
import _ from "lodash"
import CardTabComponent from "@components/cardTabComponent"
import serverSideInputs from "@components/serverSideInputs/"
import rest from '../../data/restWrapper';

function Settings(props) {
    const [selectedTabIndex, setSelectedTabIndex] = useState({})
    const [proxySettings, setProxySettings] = useState({})

    useEffect(() => {
        if(props.srcProxyUuid){
            getSRCProxySettings()
        }
        // eslint-disable-next-line
    }, [props.srcProxyUuid])
    


    const getSRCProxySettings = async() => {
      try {
        const sett = await rest("/getSRCProxySettings", "POST", { srcProxyUuid: props.srcProxyUuid })
        setProxySettings(sett)
      } catch (error) {
        console.log(error);
      }
    }

    const updateSRCProxySettings = async(payload) => {
        try {
          await rest("/updateSRCProxySettings", "POST", { srcProxyUuid: props.srcProxyUuid, payload:payload })
          await getSRCProxySettings()
        } catch (error) {
            await getSRCProxySettings()
          console.log(error);
        }
      }

    const setSettingSelectedTabIndex = (type, index)=> {
        let currentTabIndex = {...selectedTabIndex} 
        currentTabIndex[type] = index
        setSelectedTabIndex(currentTabIndex)
    }

    const handleUserSettings = async(inputs, tabName) => {
        await updateSRCProxySettings(inputs)
    }

    const resetUserSettings = (tabName) => {
        // actions.resetUserSettingsTab(tabName);
    }

    const onSelectedTabIndexChange =(type, index) => {
        setSettingSelectedTabIndex(type, index);
    }

    if(!props.srcProxyUuid){
        return <div>Cannot read proxy uuid</div>
    }else{
        return <TabbedUserSettings
        {...props}
        selectedTabIndex={selectedTabIndex.userSettingsTabIndex}
        onSelectedTabIndexChange={() => {onSelectedTabIndexChange("userSettingsTabIndex")}}
        key="settings" saveButtonHandler={handleUserSettings} resetButtonHandler={resetUserSettings.bind(this)} settings={proxySettings} />
    }
        

}
class TabbedUserSettings extends Component {
    onSelectedTabIndexChange(index) {
        this.props.onSelectedTabIndexChange(index);
    }
   
    render() {
        let tabLabels = [];
        let tabContent = [];
        let tabIcons = [];

        let settingsTabs = [];
        _.forIn(this.props.settings, (tabValue, tabName) => {
            let tabSettings = _.clone(tabValue);
            if (_.isObject(tabSettings)) {
                tabSettings.tabName = tabName;
                settingsTabs.push(tabSettings);
            } else {
                console.warn("Check settings for tabName", tabName)
            }
        })

        _.forEach(_.orderBy(settingsTabs, i => i.displayOrder, ["asc"]), (tabContentDescription) => {

            tabLabels.push(tabContentDescription.label);
            tabIcons.push(tabContentDescription.faIcon);
            tabContent.push(<SettingsTabContent
                {...this.props}
                saveButtonHandler={this.props.saveButtonHandler}
                resetButtonHandler={this.props.resetButtonHandler}
                tabName={tabContentDescription.tabName} tabInputsDescription={tabContentDescription} key={tabContentDescription.tabName} />);

        });

        return (<div className="settings-content">
            <CardTabComponent
                {...this.props}
                selectedIndex={this.props.selectedTabIndex}
                onSelectedTabIndexChange={this.onSelectedTabIndexChange.bind(this)}
                tabNames={tabLabels}
                tabIcons={tabIcons}
                tabContentComponents={tabContent}
            />
        </div>);
    }
}

TabbedUserSettings.defaultProps = {
    showPluginManagementTab: PropTypes.bool,
    saveButtonHandler: PropTypes.func.isRequired,
    resetButtonHandler: PropTypes.func.isRequired
}

class SettingsTabContent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            inputs: props.tabInputsDescription
        }
    }
    onValueChanged(newValue, inputName) {
        let inputs = _.cloneDeep(this.state.inputs);
        inputs[inputName].value = newValue;
        this.setState({ inputs: inputs });
    }
    saveChanges() {
        this.props.saveButtonHandler(this.state.inputs, this.props.tabName)
    }

    // resetSettingsTab() {
    //     this.props.resetButtonHandler(this.props.tabName);
    // }
    render() {
        let contentControlls = [];
        _.forIn(this.state.inputs, (inputDesc, inputName) => {
                serverSideInputs(inputDesc, contentControlls, inputName, this.onValueChanged.bind(this), inputDesc.disabled);
        });

        return (<div>

            {contentControlls}
            <div className="d-flex justify-content-center pt-2">
                {/* <div className="col-sm-2"></div> */}
                <div className="row text-center">
                    <button onClick={this.saveChanges.bind(this)} className="btn btn-info mx-1 btn-sm"> <i className="fa fa-save" /> Save</button>
                    {/* <button onClick={this.resetSettingsTab.bind(this)} className="btn btn-info mx-1 btn-sm"> <i className="fa fa-save" /> Reset to default</button> */}
                </div>
            </div>
            <div className="bottom-with-dblmargin"> </div>
            <div className="bottom-with-dblmargin"> </div>
            <div className="bottom-with-dblmargin"> </div>
        </div>);
    }
}

SettingsTabContent.propTypes = {
    tabInputsDescription: PropTypes.object.isRequired
}
export default branch({
    // settings: ["readOnlyModel", "settings"],
    // route: ["route"]
}, Settings);

export { TabbedUserSettings }