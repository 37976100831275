import React, { useEffect, useState } from 'react';
import ReportTable from '../../components/ReportTable';
import PageTitle from '../../components/pageTitle';
import { getReportData, getExcelExport } from '../../data/reports';

function CashRegistarsReport(props) {
    const [yearlyCashRegistars, setYearlyCashRegistars] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(0);
    const [_page, setPage] = React.useState(0);

    useEffect(() => {
        const loadData = async () => {
            reloadData(year);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const reloadData = async (year, page = _page) => {
        const _yearlyCashRegistars = await getReportData('cashRegistars', { year, page });
        setYearlyCashRegistars([..._yearlyCashRegistars]);
        setPage(page)
    };

    const _setYear = async (e) => {
        setYear(Number(e.target.value));
        await reloadData(Number(e.target.value));
    };

    const headers = [
        {
            filterKey: 'reportNumber',
            headerTitle: window.translate('Report Num.'),
            label: 'reportNumber',
            type: 'num',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '90px',
        },
        {
            filterKey: 'fromTimestamp',
            headerTitle: window.translate('From'),
            label: 'fromTimestamp',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'toTimestamp',
            headerTitle: window.translate('To'),
            label: 'toTimestamp',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },

        {
            filterKey: 'previousState',
            headerTitle: window.translate('Prev. state'),
            label: 'previousState',
            type: 'price',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'payIn',
            headerTitle: window.translate('Pay IN'),
            label: 'payIn',
            type: 'price',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'payOut',
            headerTitle: window.translate('Pay OUT'),
            label: 'payOut',
            type: 'price',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'newState',
            headerTitle: window.translate('New state'),
            label: 'newState',
            type: 'price',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'total',
            headerTitle: window.translate('Total'),
            label: 'total',
            type: 'price',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '120px',
        },
        {
            filterKey: 'createdAt',
            headerTitle: window.translate('Created At'),
            label: 'createdAt',
            type: 'date',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'name',
            headerTitle: window.translate('Cash registar'),
            label: 'name',
            type: '',
            filterType: 'select',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '150px',
        },
        {
            filterKey: 'cashMax',
            headerTitle: window.translate('Registar max.'),
            label: 'cashMax',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '150px',
        },
        {
            headerTitle: window.translate('Action'),
            type: 'link',
            openInNewTab: true,
            linkText: window.translate('View'),
            linkSchema: ['cashRegReportView', '*'],
            linkSubstitutions: ['id'],
            filterType: '',
            bootstrapColumnClass: 'col-md-2 d-none d-md-block',
            cellWidth: '100px',
            label: "Action"
        },
    ];

    return (
        <div className="">
            <PageTitle />

            <ReportTable
                year={year}
                month={month}
                setYear={_setYear.bind(this)}
                onYearMonthChange={async (filter) => {
                    setYear(filter.year);
                    setMonth(filter.month);
                    await reloadData(filter.year);
                }}
                itemsArray={yearlyCashRegistars}
                headers={headers}
                monthFilter={true}
                history={props.history}
                targetedColumnForMonthFilter={'createdAt'}
                targetedColumnForDateFilter={'createdAt'}
                showDateFilter={true}
                // onRowClickHandler={(row) => {
                //     props.history.push(`/cashRegReportView/${row.id}`);
                // }}
                xlsExportHandler={(params) => {
                    getExcelExport('cashRegistars', params);
                }}
                loadData={(page) => {
                    reloadData(year, page);
                }}
                page={_page}
                listOfItems={yearlyCashRegistars}
            ></ReportTable>
        </div>
    );
}

export default CashRegistarsReport;
