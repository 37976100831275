import * as React from 'react';
import { translate } from '@data/translations';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import { ReservationModel } from '@common/modelDefinition';
import { Reservation, ReservationController } from '../resController';

interface OverlappingReservationsProps {
    reservation: Reservation;
}

const OverlappingReservations = (props: OverlappingReservationsProps) => {
    const { reservation } = props;
    const ctrl = new ReservationController();
    const { overlaps } = ctrl.getReservationValidationReport(reservation);

    if (overlaps.length === 0) {
        return null;
    }

    return (
        <ul className="list-group text-center mt-2">
            <li className="list-group-item list-group-item-warning  p-1">
                <b>{translate('POSSIBLE CONFLICTS')}</b>
            </li>
            {overlaps.map((r: ReservationModel, index: number) => {
                return (
                    <li className="list-group-item list-group-item-warning p-1" key={index}>
                        <div className="row">
                            <div className="col-1">#{r.id} </div>
                            <div className="col-5">
                                <b>{translate('Check in')}:</b>&ensp;
                                {moment(r.checkInTimestamp).format('LLL')}{' '}
                            </div>
                            <div className="col-6">
                                <b>{translate('Check out')}:</b> &ensp;
                                {moment(r.checkOutTimestamp).format('LLL')}
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

export default OverlappingReservations;