import React, { Component } from 'react';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import {
    reservationEnums,
    reservationStatusEnumsDesc,
    reservationStatusColors,
} from '../../../data/reservationStatusEnums';

class Reservations extends Component {
    render() {
        let pastReservations = [];
        let futureReservations = [];
        _.forEach(this.props.roomReservations, (r) => {
            let isReservationClosed = Number(r.statusEnum) === reservationEnums.closed;
            let isReservationCheckId = Number(r.statusEnum) === reservationEnums.checkedIn;

            let temp = (
                <li
                    key={r.id}
                    name={r.checkInTimestamp}
                    className="list-group-item p-2 pimatico-card-link"
                    onClick={() => {
                        if (isReservationClosed || isReservationCheckId) {
                            this.props.history.push('/reservationDetails/' + r.id);
                        } else {
                            this.props.history.push('/reservations/' + r.id + '/edit');
                        }
                    }}
                >
                    <div className="row">
                        <div className="col-12 col-sm-12 col-md-6">
                            <span className={reservationStatusColors[r.statusEnum]}>
                                {reservationStatusEnumsDesc[r.statusEnum]}
                            </span>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3">
                            <small className="mr-2">
                                {' '}
                                <i className="fa fa-calendar-check-o" aria-hidden="true"></i>{' '}
                                {moment(r.checkInTimestamp).format('ddd, DD. MMM')}
                            </small>
                        </div>
                        <div className="col-6 col-sm-6 col-md-3">
                            <small>
                                <i className="fa fa-sign-out" aria-hidden="true"></i>{' '}
                                {moment(r.checkOutTimestamp).format('ddd, DD. MMM')}
                            </small>
                        </div>

                        {!r.Customer && !r.Company && r.checkInTimestamp && r.checkOutTimestamp ? (
                            <span className="text-danger ml-3">{window.translate("Customer/Company was deleted")}</span>
                        ) : r.Customer ? (
                            <div className="col-6 col-sm-6 col-md-6">
                                <small>
                                    {' '}
                                    {r.Customer.firstName} {r.Customer.lastName}
                                </small>
                            </div>
                        ) : (
                            <div className="col-6 col-sm-6 col-md-6">
                                <small>
                                    <i className="fa fa-building ml-0 " aria-hidden="true"></i> {r.Company.name}{' '}
                                </small>
                            </div>
                        )}

                        {!r.Customer &&
                        !r.Company &&
                        r.checkInTimestamp &&
                        r.checkOutTimestamp ? null :r.Customer && r.Customer.firstName ? (
                            <div className="col-6 col-sm-6 col-md-6">
                                <span className="">
                                    {r.email ? (
                                        <div>
                                            <small>{r.Customer.email}</small>
                                        </div>
                                    ) : null}
                                    {r.Customer.phoneOrMobile ? (
                                        <div>
                                            <small>{r.Customer.phoneOrMobile}</small>
                                        </div>
                                    ) : null}
                                    {r.enableSelfCheckIn === true || r.enableSelfCheckIn === 1 ? '*****' : null}
                                </span>
                            </div>
                        ) : (
                            <div className="col-6 col-sm-6 col-md-6">
                                {r.town ? (
                                    <div>
                                        <small>
                                            <i className="fa fa-map-marker mr-1" aria-hidden="true"></i>
                                            {r.town}
                                        </small>
                                    </div>
                                ) : null}
                            </div>
                        )}
                    </div>
                </li>
            );

            if (r.checkOutTimestamp < Date.now()) {
                pastReservations.push({ checkInTimestamp: Number(r.checkInTimestamp), temp });
            } else {
                futureReservations.push({ checkInTimestamp: Number(r.checkInTimestamp), temp });
            }
        });

        futureReservations = _.map(_.orderBy(futureReservations, ['checkInTimestamp'], ['asc']), 'temp');
        pastReservations = _.map(_.orderBy(pastReservations, ['checkInTimestamp'], ['desc']), 'temp');

        return (
            <div className="mb-5">
                <h5 className="text-secondary text-center pt-2">{window.translate("Inbound reservations")}</h5>
                <ul className="list-group list-group-flush mb-3 bg-white ">{futureReservations}</ul>

                <h5 className="mt-2 text-secondary text-center">{window.translate("Past 30 days")}</h5>
                <ul className="list-group list-group-flush bg-white ">{pastReservations}</ul>
            </div>
        );
    }
}

export default Reservations;
