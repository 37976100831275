import React, { useState, useEffect } from 'react';
import rest from '../../data/restWrapper';
import PageLoader from '../../components/loaders/loader1';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import { branch } from 'baobab-react/higher-order';
import ReportingToolbar from '../../reports/reportingToolbar';
import { alarmAndEventCSSStyling, userFrendlyAlarmAndEventsNames } from '../../data/SRCAlarmAndAlertController';

const RoomEventHistory = (props) => {
    const [viewType, setViewType] = useState('tiles');
    const [roomEvents, setRoomEvents] = useState(null);
    const [from, setFrom] = useState(
        new Date(new Date().getFullYear(), new Date().getMonth(), new Date().getDate(), 0, 0, 0)
    );
    const [to, setTo] = useState(new Date());

    useEffect(() => {
        const loadData = async () => {
            const _roomEvents = await rest(
                '/getTargetRoomEvents/' +
                    props.match.params.roomInfoId +
                    '?from=' +
                    from.getTime() +
                    '&to=' +
                    to.getTime()
            );
            setRoomEvents(_roomEvents);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.match.params.roomInfoId, from, to]);

    const handleDateSelection = (type, date) => {
        //type is "from", "to"
        if (type === 'from') {
            setFrom(date);
        } else if (type === 'to') {
            setTo(date);
        }
    };

    const controllButtons = (
        <div>
            <button
                className="btn btn-sm btn-secondary  mr-1 "
                onClick={() => {
                    props.history.goBack();
                }}
            >
                {window.translate('GO BACK')}
            </button>

            <button onClick={() => setViewType('tiles')} className="btn btn-sm btn-outline-primary mr-1">
                <i className="fa fa-th" />
            </button>
            <button onClick={() => setViewType('list')} className="btn btn-sm btn-outline-primary ">
                <i className="fa fa-list" />
            </button>
        </div>
    );

    if (roomEvents === null) {
        return <PageLoader heading={''} header={window.translate('Loading room events')} />;
    }

    const targetRoom = props.roomInfo.find((r) => r.id === parseInt(props.match.params.roomInfoId, 10));

    let data = [];
    if (viewType === 'tiles') {
        data = _.orderBy(roomEvents, ['openingTimestamp'], ['desc']).map((ev) => {
            const classNameFormat = alarmAndEventCSSStyling[ev.ruleName]
                ? alarmAndEventCSSStyling[ev.ruleName] + ' row'
                : 'p-2 row';
            return (
                <div key={ev.id} className={classNameFormat}>
                    <div className="col-12 col-sm-6 col-md-3 col-xl-3">
                        <small>
                            {moment(ev.openingTimestamp).format('L')} {moment(ev.openingTimestamp).format('H:m')}
                        </small>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3 col-xl-5">
                        <small> {userFrendlyAlarmAndEventsNames[ev.ruleName]}</small>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3 col-xl-2">
                        <small>{moment(ev.openingTimestamp).fromNow()}</small>
                    </div>
                    <div className="col-12 col-sm-6 col-md-3 col-xl-2">
                        <small>{moment.duration(ev.duration).humanize()}</small>
                    </div>
                </div>
            );
        });
    } else if (viewType === 'list') {
        data = _.orderBy(roomEvents, ['openingTimestamp'], ['desc']).map((ev) => {
            return (
                <div key={ev.id} className={'p-1'}>
                    <small>
                        {moment(ev.openingTimestamp).format('L')};{moment(ev.openingTimestamp).format('H:m')};
                        {ev.ruleName};{userFrendlyAlarmAndEventsNames[ev.ruleName]};
                        {moment(ev.openingTimestamp).fromNow()};{moment.duration(ev.duration).humanize()}
                    </small>
                </div>
            );
        });
    }
    return (
        <div className="mb-5">
            <ReportingToolbar
                classNameSufix="bg-white border shadow-sm rounded p-2"
                from={from}
                to={to}
                actionsComponent={controllButtons}
                handleDateSelection={handleDateSelection.bind(this)}
            />
            <h2 className="text-secondary">
                {window.translate('Room events')}: {targetRoom ? targetRoom.name : ''}
            </h2>

            <div className="animated faster fadeIn">
                <span className="text-secondary">{data.length === 0 ? window.translate('No data') : null}</span>
                {data}
            </div>
        </div>
    );
};

export default branch(
    {
        roomInfo: ['model', 'RoomInfo'],
    },
    RoomEventHistory
);
