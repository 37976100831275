import React, { Component } from 'react';
import _ from 'lodash';
//import rest from '../../../data/restWrapper';
import FormHeader from '../../../components/formHeader';
import Keywords from "../keyWords";
import translate from '../../../data/translations';

class NotificationsTemplateForm extends Component {
    saveData() {
        this.props.saveFormData('locale');
        this.props.saveFormData('defaultCurrency');
        if (this.props.nextStepCallback) {
            this.props.nextStepCallback();
        }
    }

    render() {

        return (
            <div className=" bg-white p-2 ">
                <FormHeader classNameSufix="">{translate(this.props.notificationPageTitle.toUpperCase())}</FormHeader>

                <div className="row">
                    <div className="col-md-6 pt-4 p-2">
                        {_.map(this.props.template, (val, key) => {
                            if(key !== "params"){
                                const translatedKey = translate(key[0].toUpperCase() + key.substring(1))

                                if (_.isBoolean(val)) {
                                    return (
                                        <div className="row p-2" key={key}>
                                            <div className="col-md-5">
                                                {' '}
                                                <label className="my-1 mr-2 " htmlFor="defaultCurrency">
                                                    {' '}
                                                    <label>{translatedKey}</label>:
                                                </label>{' '}
                                            </div>
                                            <div className="col-md-6">
                                                {key.toLowerCase() === 'body' ? (
                                                    <textarea
                                                        type="textarea"
                                                        rows="5"
                                                        name={key}
                                                        className="form-control"
                                                        value={val}
                                                        onChange={this.props.onFieldChange.bind(this)}
                                                    ></textarea>
                                                ) : (
                                                    <input
                                                        type="checkbox"
                                                        name={key}
                                                        className="form-control"
                                                        checked={val}
                                                        onChange={this.props.onFieldChange.bind(this)}
                                                    />
                                                )}
                                            </div>
                                        </div>
                                    );
                                } else {
                                    return (
                                        <div className="row p-2" key={key}>
                                            <div className="col-md-5">
                                                {' '}
                                                <label className="my-1 mr-2 " htmlFor="">
                                                    {' '}
                                                    <label>{translatedKey}</label>:
                                                </label>{' '}
                                            </div>
                                            <div className="col-md-6">
                                                {key.toLowerCase() === 'body' || key.toLowerCase() === 'apiobject' ? (
                                                    <textarea
                                                        type="textarea"
                                                        rows="5"
                                                        name={key}
                                                        className="form-control"
                                                        value={val}
                                                        onChange={this.props.onFieldChange.bind(this)}
                                                    ></textarea>
                                                ) : (
                                                    <input
                                                        type="text"
                                                        name={key}
                                                        className="form-control"
                                                        value={val}
                                                        onChange={this.props.onFieldChange.bind(this)}
                                                    />
                                                )}
                                                {key.toLowerCase() === 'apiobject' ? (
                                                    <span className="text-danger">{this.props.validationErrorMessage}</span>
                                                ) : null}
                                            </div>
                                        </div>
                                    );
                                }
                            }
                        })}
                    </div>

                    <Keywords keyWords={this.props.keyWords}/>
                </div>
                <div>{this.props.children}</div>
                <div className="text-center mt-3">
                    {' '}
                    <div onClick={this.props.saveFormData.bind(this)} className="btn btn-outline-primary mb-4 pointer">
                        {this.props.saveButtonText ? this.props.saveButtonText : window.translate("Save")}
                    </div>{' '}
                </div>
            </div>
        );
    }
}

export default NotificationsTemplateForm;
