import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from "lodash"

class CheckBox extends Component {
	onChangeHandler(e){
		this.props.onValueChanged(!this.props.value, this.props.inputName);
	}
	render(){
		let isChecked = false;
		if(_.isBoolean(this.props.value)){
			isChecked = this.props.value;
		} 
	
		return(<div className="row">
				<label className="control-label col-sm-4">{this.props.label}</label>
				<div className="col-sm-8 text-left">
				<input  onChange={()=>{}}  type="checkbox" value={isChecked} checked={isChecked} className="regular-checkbox" /><label className="margin-t10" onClick={this.onChangeHandler.bind(this)}></label>
				</div>			
				<div className=" clear"></div>
		</div>);
	}
}

CheckBox.propTypes = {
	//value: PropTypes.bool.isRequired,
	onValueChanged: PropTypes.func.isRequired,
	label: PropTypes.string.isRequired,
	inputName: PropTypes.string.isRequired,
}

export default CheckBox