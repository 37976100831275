import React, { useEffect, useState } from 'react';
import ReportTable from '../components/ReportTable';
import PageTitle from '../components/pageTitle';
import { getReportData, getExcelExport } from '../data/reports';

function SelfCheckInLogs(props) {
    const [yearlySelfCheckInLogs, setYearlySelfCheckInLogs] = useState([]);
    const [year, setYear] = useState(new Date().getFullYear());
    const [month, setMonth] = useState(0);
    const [_page, setPage] = React.useState(0);

    useEffect(() => {
        const loadData = async () => {
            reloadData(year);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const reloadData = async (year, page=_page) => {
        const _yearlySelfCheckInLogs = await getReportData('selfCheckInLogs', { year, page });
        setYearlySelfCheckInLogs([..._yearlySelfCheckInLogs]);
        setPage(page)
    };

    const _setYear = async (e) => {
        setYear(Number(e.target.value));
        await reloadData(Number(e.target.value));
    };

    const headers = [
        {
            filterKey: 'id',
            headerTitle: window.translate('Id'),
            label: 'id',
            type: 'num',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '50px',
        },
        {
            filterKey: 'timestamp',
            headerTitle: window.translate('Date'),
            label: 'timestamp',
            type: 'datetime',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '150px',
        },
        {
            filterKey: 'reservationId',
            headerTitle: window.translate('Res.id'),
            label: 'reservationId',
            type: 'num',
            filterType: 'sort',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '60px',
        },

        {
            filterKey: 'methodName',
            headerTitle: window.translate('Method'),
            label: 'methodName',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '200px',
        },
        {
            filterKey: 'description',
            headerTitle: window.translate('Description'),
            label: 'description',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '500px',
        },
        {
            filterKey: 'methodSuccessfullyPassed',
            headerTitle: window.translate('Method successfully passed'),
            label: 'methodSuccessfullyPassed',
            type: 'num',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '100px',
        },
        {
            filterKey: 'errorMessage',
            headerTitle: window.translate('Error message'),
            label: 'errorMessage',
            type: '',
            filterType: '',
            bootstrapColumnClass: 'col-md-1',
            cellWidth: '400px',
        },
    ];

    return (
        <div className="">
            <PageTitle />

            <ReportTable
                year={year}
                month={month}
                setYear={_setYear.bind(this)}
                onYearMonthChange={async (filter) => {
                    setYear(filter.year);
                    setMonth(filter.month);
                    await reloadData(filter.year);
                }}
                itemsArray={yearlySelfCheckInLogs}
                headers={headers}
                monthFilter={true}
                history={props.history}
                targetedColumnForMonthFilter={'timestamp'}
                targetedColumnForDateFilter={'timestamp'}
                showDateFilter={true}
                xlsExportHandler={(params) => {
                    getExcelExport('selfCheckInLogs', params);
                }}
                loadData={(page) => {
                    reloadData(year, page);
                }}
                page={_page}
                listOfItems={yearlySelfCheckInLogs}
            ></ReportTable>
        </div>
    );
}

export default SelfCheckInLogs;
