import React from 'react';
import moment from 'moment';

import { addConnectedInvoice, createOrJoinNotConnectedConsumptionInvoices, setInvoiceStornoPreview, removeReservationReferenceFromInvoice } from './controler';
import numbro from 'numbro';
import { CheckoutOptionsProps } from './interfaces';
//@ts-ignore
import { Link } from 'react-router-dom';
import { InvoiceModel, InvoiceModelWithInvoiceItems } from '@common/modelDefinition';

export enum InvoiceTypeEnum {
    geniue,
    advance,
    proforma,
}

const IssuedInvoicesList = (props: CheckoutOptionsProps) => {
    const {
        advanceInvoices,
        proformaInvoices,
        stornoInvoices,
        posTerminalInvoices = [],
        minibarInvoices = [],
        translate,
    } = props;
    const [expanded, setExpanded] = React.useState(true);
    const unfoldCount: number =
        advanceInvoices.length +
        proformaInvoices.length +
        stornoInvoices.length +
        posTerminalInvoices.length +
        minibarInvoices.length;

    return (
        <div className="mt-2">
            {unfoldCount > 0 ? (
                <div
                    className="pointer text-center unselectable"
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    <h5>
                        {translate('Povezani računi')} ({unfoldCount})
                    </h5>
                    {!expanded && (
                        <small>
                            <small>{translate('click to expand')}</small>
                        </small>
                    )}
                </div>
            ) : null}
            {expanded && (
                <div>
                    <GenuineInvoiceList {...props} />
                    <AdvanceInvoiceList {...props} />
                    <ProformaInvoiceList {...props} />
                    <ConsumptionInvoiceList
                        consumptionInvoices={posTerminalInvoices}
                        consumptionType={'POS terminal invoices'}
                        {...props}
                    />
                    <ConsumptionInvoiceList
                        consumptionInvoices={minibarInvoices}
                        consumptionType={'Minibar invoices'}
                        {...props}
                    />
                    <StornoInvoiceList {...props} />
                </div>
            )}
        </div>
    );
};

//ne smiješ dozvoliti storniranje avansnih računa ako je račun izdan i zaključen
const AdvanceInvoiceList = (props: CheckoutOptionsProps) => {
    const { advanceInvoices = [], invoice: activeInvoice, translate, genuineInvoices } = props;
    if (advanceInvoices.length === 0) {
        return null;
    }
    const accommodationInvoiceIssued: InvoiceModelWithInvoiceItems | undefined = genuineInvoices.find(
        (i: InvoiceModelWithInvoiceItems) => i.terminalType === 'reservation'
    );
    return (
        <ul className="list-group mt-2">
            <li className="list-group-item p-1 text-center">
                <b>{translate('Advance invoices')}</b>
            </li>
            {advanceInvoices &&
                advanceInvoices.map((i: InvoiceModelWithInvoiceItems, index) => {
                    return (
                        <li key={index} className="list-group-item p-2">
                            <div className="row">
                                <div className="col-2">
                                    <Link
                                        key={index}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        to={'/invoiceView/' + i.id + '?noActions'}
                                    >
                                        #{i.id}
                                    </Link>
                                </div>
                                <div className="col-4">
                                    {i.timestamp ? moment(i.timestamp).format('LL') : 'unknown'}
                                </div>

                                <div className="col-3">
                                    {activeInvoice.pendingInvoice && !accommodationInvoiceIssued ? (
                                        <StornoButton {...props} invoiceForStorno={i} />
                                    ) : null}
                                </div>
                                <div className="col-3 text-right">
                                    {numbro(Number(i.totalValue) * Number(i.currencyExchangeRate)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {i.currency}
                                </div>
                            </div>
                        </li>
                    );
                })}
        </ul>
    );
};

const ProformaInvoiceList = (props: CheckoutOptionsProps) => {
    const { proformaInvoices = [], invoice: activeInvoice, translate } = props;

    if (proformaInvoices.length === 0) {
        return null;
    }
    return (
        <ul className="list-group mt-2">
            <li className="list-group-item p-1 text-center">
                <b>{translate('Proforma invoices')}</b>
            </li>
            {proformaInvoices &&
                proformaInvoices.map((i, index) => {
                    return (
                        <li key={index} className="list-group-item p-2">
                            <div className="row">
                                <div className="col-2">
                                    <Link
                                        key={index}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        to={'/invoiceView/' + i.id + '?noActions'}
                                    >
                                        #{i.id}
                                    </Link>
                                </div>
                                <div className="col-4">
                                    {i.timestamp ? moment(i.timestamp).format('LL') : 'unknown'}
                                </div>
                                <div className="col-3">
                                    {activeInvoice.pendingInvoice ? (
                                        <StornoButton invoiceForStorno={i} {...props} />
                                    ) : null}
                                </div>
                                <div className="col-3 text-right">
                                    {numbro(Number(i.totalValue) * Number(i.currencyExchangeRate)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {i.currency}
                                </div>
                            </div>
                        </li>
                    );
                })}
        </ul>
    );
};

interface ConsumptionListProps extends CheckoutOptionsProps {
    consumptionType: string;
    consumptionInvoices: InvoiceModel[];
}

const ConsumptionInvoiceList = (props: ConsumptionListProps) => {
    const {
        invoice: activeInvoice,
        translate,
        groupReservationsArray,
        consumptionInvoices,
        consumptionType,
        reservationId,
        reservationGroupUuid,
    } = props;
    const filteredConsumptionInvoices = consumptionInvoices.filter(
        (p) => p.reclamation === 0 && !p.connectedInvoiceProcessed
    );
    if (filteredConsumptionInvoices.length === 0) {
        return null;
    }
    const { terminalType } = activeInvoice;
    return (
        <ul className="list-group mt-2">
            <li className="list-group-item p-1 text-center">
                <b>{translate(consumptionType)}</b>
            </li>
            {filteredConsumptionInvoices.map((i: InvoiceModel, index: number) => {
                const reservation = i.reservationGroupUuid
                    ? groupReservationsArray.find((r) => r.id === i.reservationId)
                    : null;
                const roomName: string | undefined = reservation?.RoomInfo?.name;

                const addOrRemoveFromConnectedInvoices: boolean =
                    i.id && activeInvoice.connectedInvoices && activeInvoice.connectedInvoices.includes(i.id)
                        ? false
                        : true; //false = add, true = remove
                const dontShowButton: boolean =
                    i.connectedInvoiceProcessed === 1 || !activeInvoice.pendingInvoice || !activeInvoice.id
                        ? true
                        : false;
                return (
                    <li key={index} className="list-group-item p-2">
                        <div className="row align-items-center">
                            <div className="col-2">
                                <Link
                                    key={index}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={'/invoiceView/' + i.id + '?noActions'}
                                >
                                    #{roomName ? i.id + ' - ' + roomName : i.id}
                                </Link>
                            </div>
                            <div className="col-4"> {i.timestamp ? moment(i.timestamp).format('LL') : 'unknown'}</div>
                            <div className="col-3">
                                {!dontShowButton ? (
                                    <button
                                        disabled={
                                            i.connectedInvoiceProcessed === 1 || !activeInvoice.pendingInvoice
                                                ? true
                                                : false
                                        }
                                        onClick={() => {
                                            if (i.id) {
                                                addConnectedInvoice(i.id, reservationId, reservationGroupUuid);
                                            }
                                        }}
                                        className={
                                            addOrRemoveFromConnectedInvoices && i.connectedInvoiceProcessed !== 1
                                                ? 'btn btn-sm btn-primary mr-1'
                                                : 'btn btn-sm btn-danger mr-1'
                                        }
                                    >
                                        {translate(
                                            addOrRemoveFromConnectedInvoices && i.connectedInvoiceProcessed !== 1
                                                ? 'Add'
                                                : terminalType === 'reservation'
                                                ? 'Remove'
                                                : activeInvoice.connectedInvoices.length > 1
                                                ? 'Remove'
                                                : 'Cancel'
                                        )}
                                    </button>
                                ) : null}
                                {i.connectedInvoiceProcessed !== 1 &&
                                addOrRemoveFromConnectedInvoices &&
                                terminalType === 'reservation' &&
                                activeInvoice.id === undefined ? (
                                    <React.Fragment>
                                        <button
                                            onClick={async () => {
                                                await removeReservationReferenceFromInvoice(i);
                                            }}
                                            className="btn btn-sm btn-outline-secondary mr-1"
                                        >
                                            <i className="fa fa-trash"></i>
                                        </button>
                                        <button
                                            onClick={async () => {
                                                await createOrJoinNotConnectedConsumptionInvoices(
                                                    i,
                                                    reservationId,
                                                    reservationGroupUuid
                                                );
                                            }}
                                            className="btn btn-sm btn-outline-primary mr-1"
                                        >
                                            <i className="fa fa-print"></i>
                                        </button>
                                    </React.Fragment>
                                ) : null}
                            </div>

                            <div className="col-3 text-right">
                                {numbro(Number(i.totalValue) * Number(i.currencyExchangeRate)).format({
                                    mantissa: 2,
                                    trimMantissa: false,
                                    thousandSeparated: true,
                                })}{' '}
                                {i.currency}
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

const StornoButton = (props: CheckoutOptionsProps) => {
    const { invoiceForStorno, translate, invoiceStornoPreview } = props;
    if (!invoiceForStorno || invoiceStornoPreview) {
        return null;
    }

    return (
        <button
            onClick={() => {
                setInvoiceStornoPreview(invoiceForStorno);
            }}
            className="btn btn-sm btn-warning"
        >
            {translate('STORNO')}
        </button>
    );
};
const StornoInvoiceList = (props: CheckoutOptionsProps) => {
    const { stornoInvoices, translate } = props;
    if (stornoInvoices.length === 0) {
        return null;
    }
    return (
        <ul className="list-group mt-2">
            <li className="list-group-item p-1 text-center">
                <b>{translate('Storno invoices')}</b>
            </li>
            {stornoInvoices.map((i, index) => {
                return (
                    <li key={index} className="list-group-item p-2">
                        <div className="row">
                            <div className="col-2">
                                <Link
                                    key={index}
                                    target="_blank"
                                    rel="noopener noreferrer"
                                    to={'/invoiceView/' + i.id + '?noActions'}
                                >
                                    #{i.id}
                                </Link>
                            </div>
                            <div className="col-4">{i.timestamp ? moment(i.timestamp).format('LL') : 'unknown'}</div>
                            <div className="col-3">
                                {i.invoiceType === InvoiceTypeEnum.geniue && translate('(invoice)')}
                                {i.invoiceType === InvoiceTypeEnum.advance && translate('(advance)')}
                                {i.invoiceType === InvoiceTypeEnum.proforma && translate('(proforma)')}
                            </div>
                            <div className="col-3 text-right">
                                {numbro(Number(i.totalValue) * Number(i.currencyExchangeRate)).format({
                                    mantissa: 2,
                                    trimMantissa: false,
                                    thousandSeparated: true,
                                })}{' '}
                                {i.currency}
                            </div>
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};

const GenuineInvoiceList = (props: CheckoutOptionsProps) => {
    const { genuineInvoices, translate } = props;
    if (genuineInvoices.length === 0) {
        return null;
    }
    return (
        <ul className="list-group mt-2">
            <li className="list-group-item p-1 text-center">
                <b>{translate('Genuine invoices')}</b>
            </li>
            {genuineInvoices &&
                genuineInvoices.map((i, index) => {
                    return (
                        <li key={index} className="list-group-item p-2">
                            <div className="row">
                                <div className="col-2">
                                    <Link
                                        key={index}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                        to={'/invoiceView/' + i.id + '?noActions'}
                                    >
                                        #{i.id}
                                    </Link>
                                </div>
                                <div className="col-4">
                                    {i.timestamp ? moment(i.timestamp).format('LL') : 'unknown'}
                                </div>

                                <div className="col-3">
                                    <StornoButton invoiceForStorno={i} {...props} />
                                </div>
                                <div className="col-3 text-right">
                                    {numbro(Number(i.totalValue) * Number(i.currencyExchangeRate)).format({
                                        mantissa: 2,
                                        trimMantissa: false,
                                        thousandSeparated: true,
                                    })}{' '}
                                    {i.currency}
                                </div>
                            </div>
                        </li>
                    );
                })}
        </ul>
    );
};

export default IssuedInvoicesList;
