import React, { Component } from 'react';
import _ from 'lodash';

import DayPickerInput from 'react-day-picker/DayPickerInput';
//import 'react-day-picker/lib/style.css';

import 'rc-time-picker/assets/index.css';
import TimePicker from 'rc-time-picker';
import rest from '../../data/restWrapper';
import moment from 'moment';

//import FeatureFlag from '@components/FeatureFlag';
import PermissionCheck from '@components/PermissionCheck';

class AccessCodes extends Component {
    constructor(props) {
        super(props);
        this.state = {
            remoteDeviceId: props.remoteDeviceId || this.props.match.params.remoteDeviceId,
            accessCodes: props.roomSettings.accessCodes,
            showBody: true,
            modified: {
                user1: false,
                user2: false,
                user3: false,
                manager: false,
                security: false,
                maid1: false,
                maid2: false,
                maid3: false,
            },
        };
    }

    onCodeChange(type, e) {
        let accessCodes = _.cloneDeep(this.state.accessCodes);
        let modified = this.state.modified;
        modified[type] = true;

        accessCodes[type].code = e.target.value ? parseInt(e.target.value, 10) : 0;
        this.setState({ accessCodes, modified });
    }

    handleTimeChange(type, e) {
        let accessCodes = _.cloneDeep(this.state.accessCodes);
        let modified = this.state.modified;
        modified[type] = true;

        accessCodes[type].validToTimestamp = e.valueOf();
        this.setState({ accessCodes, modified });
    }

    handleDateChange(type, e) {
        let accessCodes = _.cloneDeep(this.state.accessCodes);
        let modified = this.state.modified;
        modified[type] = true;

        accessCodes[type].validToTimestamp = e.getTime();
        this.setState({ accessCodes, modified });
    }

    handleDateTimeSet(type, targetTimestamp) {
        let accessCodes = _.cloneDeep(this.state.accessCodes);
        let modified = this.state.modified;
        modified[type] = true;

        accessCodes[type].validToTimestamp = targetTimestamp;
        this.setState({ accessCodes, modified });
    }
    async onCardCodeSave(cardCodeType) {
        try {
            let cardCodeToSave = this.state.accessCodes[cardCodeType];
            let payload = {
                code: cardCodeToSave.code,
                validToTimestamp: cardCodeToSave.validToTimestamp,
                cardCodeType: cardCodeType,
                reservationId: this.props.reservation ? this.props.reservation.id : null,
            };

            // console.log("PAYLOAD",payload );

            if (this.props.onSaveHandler) {
                let accessCodes = this.props.SRCTemplateData.accessCodes;
                accessCodes[cardCodeType] = cardCodeToSave;
                this.props.onSaveHandler('accessCodes', accessCodes);
            } else {
                await rest('/src/setCardCodeWithValidToTime/' + this.state.remoteDeviceId, 'POST', payload);

                let modified = this.state.modified;
                modified[cardCodeType] = false;
                this.setState({ modified });
            }
            // console.log('RES', res);
        } catch (err) {
            console.warn(err);
        }
    }
    getAcceptReservationExpButton(userType) {
        if (this.props.reservation) {
            let acceptReservationExpirationButton = null;
            let reservationTime = moment(this.props.reservation.checkOutTimestamp);
            let userTime = moment(this.state.accessCodes[userType].validToTimestamp);

            if (this.state.accessCodes[userType].code !== 0 && Math.abs(userTime.diff(reservationTime, 'hours')) >= 1) {
                acceptReservationExpirationButton = (
                    <button
                        onClick={() => {
                            this.handleDateTimeSet(userType, reservationTime);
                        }}
                        className="btn btn-sm btn-warning mt-2 mr-2"
                    >
                        <small>{window.translate('ACCEPT CURRENT RESERVATION EXP.')}</small>
                    </button>
                );
            }
            return acceptReservationExpirationButton;
        } else {
            return null;
        }
    }
    async discardCode(cardCodeType, e) {
        try {
            let payload = {
                code: 0,
                validToTimestamp: 0,
                cardCodeType,
                reservationId: this.props.reservation ? Number(this.props.reservation.id) : null,
            };
            await rest('/src/setCardCodeWithValidToTime/' + this.state.remoteDeviceId, 'POST', payload);

            let accessCodes = _.cloneDeep(this.state.accessCodes);
            accessCodes[cardCodeType].code = 0;
            this.setState({ accessCodes });
        } catch (err) {
            console.log(err);
        }
    }
    render() {
        let controlls = [];
        _.forIn(this.state.accessCodes, (value, key) => {
            let acceptReservationExpirationButton = this.getAcceptReservationExpButton(key);

            let template = (
                <div key={key} className="col-md-4 pull-left">
                    <div className="mt-2 bg-light p-2 border border-info">
                        <h3 className="text-center">
                            {key === 'manager' || key === 'security' ? window.translate('Access only') : key}
                        </h3>
                        {window.translate('Access code:')}&ensp;
                        <div className="row mb-1">
                            <div className="col-8">
                                <input
                                    onChange={this.onCodeChange.bind(this, key)}
                                    type="text"
                                    className="input-text form-control form-control-sm"
                                    value={value.code}
                                ></input>
                            </div>
                            <div className="col-4 text-left">
                                {this.state.accessCodes[key].code !== 0 ? (
                                    <button
                                        onClick={this.discardCode.bind(this, key)}
                                        className="btn btn-sm btn-danger pull-left"
                                    >
                                        <i className="fa fa-eraser" />
                                    </button>
                                ) : null}
                            </div>
                        </div>
                        {window.translate('Expires:')}&ensp; <small>{new Date(value.validToTimestamp).toLocaleDateString()}</small>
                        <div className="row">
                            <div className="col-6">
                                <PermissionCheck name="basic_src_update_access_code_settings">
                                    <DayPickerInput
                                        placeholder="DD/MM/YYYY"
                                        inputProps={{
                                            className: 'form-control form-control-sm mt-1 mt-md-0',
                                            readOnly: true,
                                        }}
                                        value={new Date(value.validToTimestamp).toLocaleDateString()}
                                        format="DD/MM/YYYY"
                                        className="input-text "
                                        onDayChange={this.handleDateChange.bind(this, key)}
                                    />
                                </PermissionCheck>
                            </div>
                            <div className="col-6">
                                <TimePicker
                                    id={key}
                                    allowEmpty={false}
                                    style={{ background: 'transparent', textAlign: 'center' }}
                                    minuteStep={15}
                                    showSecond={false}
                                    value={moment(value.validToTimestamp).clone()}
                                    className="ml-2 "
                                    onChange={this.handleTimeChange.bind(this, key)}
                                />
                            </div>
                        </div>
                        <hr />
                        <div className="text-center">
                            {key === 'guest1' || key === 'guest2' || key === 'guest3'
                                ? acceptReservationExpirationButton
                                : null}


                            <button
                                    disabled={!this.state.modified[key]}
                                    onClick={this.onCardCodeSave.bind(this, key)}
                                    className="btn btn-sm btn-info mt-2"
                                >
                                    {window.translate('Update')}
                                </button>

                        </div>
                    </div>
                </div>
            );

            if (this.props.template) {
                if (key === 'maid1' || key === 'maid2' || key === 'manager' || key === 'security') {
                    controlls.push(template);
                } else {
                }
            } else {
                if (
                    key === 'guest1' ||
                    key === 'guest2' ||
                    key === 'guest3' ||
                    key === 'maid1' ||
                    key === 'maid2' ||
                    key === 'maid3' ||
                    key === 'manager' ||
                    key === 'security'
                ) {
                    controlls.push(template);
                } else {
                }
            }
        });
        return (
            <div className="card mt-2">
                <div
                    onClick={() => {
                        this.setState({
                            showBody: !this.state.showBody,
                        });
                    }}
                    className="card-header text-light bg-dark text-center pointer"
                >
                    {window.translate('ACCESS CODES')}
                </div>

                <div className="clear mb-2">{controlls}</div>
            </div>
        );
    }
}

export default AccessCodes;