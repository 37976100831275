import React from 'react';
import InvoiceHeaderView from './InvoiceHeaderView';
import InvoiceItems from './InvoiceItems';
import InvoiceItemsSummary from './InvoiceItemsSummary';
import InvoiceTaxSummary from './InvoiceTaxSummary';
import { translations, dictionaryTranslate } from '@data/translations';
import { NoteAndReclamation } from './InvoiceItemsSummary';
import { InvoicePresenter } from '@common/modelDefinition';

const PlainInvoiceView = (props: InvoicePresenter) => {
    const { invoice } = props;
    const { locale } = invoice;

    const translate = (text: any) => {
        return dictionaryTranslate(text, translations[locale as keyof typeof translations]);
    };

    return (
        <div className="bg-white p-3" style={invoice.id === undefined ? { color:'rgba(0, 0, 0, 0.4)'} : {}}>
            <InvoiceHeaderView translate={translate} {...props} />
            <InvoiceItems translate={translate} {...props} />
            <InvoiceItemsSummary translate={translate} {...props} />
            <InvoiceTaxSummary translate={translate} {...props} />
            <NoteAndReclamation translate={translate} {...props} />
        </div>
    );
};

export default PlainInvoiceView;
