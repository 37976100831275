import React, { Component } from 'react';
import moment from 'moment/min/moment-with-locales';
import { getSpecificModelRow } from '../../../data/actions/modelActions';
import {
    reservationEnums,
    reservationStatusEnumsDesc,
    reservationStatusColors /*, reservationStatusBackgrounds*/,
} from '../../../data/reservationStatusEnums';
//import _ from "lodash"
import rest from '../../../data/restWrapper';

class Present extends Component {
    constructor() {
        super();
        this.state = {
            reservationNotesOverride: '',
            reservationNotesSaved: true,
            customer: null,
            company: null,
        };
    }
    async componentDidMount() {
        let customerId = this.props.reservation ? this.props.reservation.customerId : null;
        let companyId = this.props.reservation ? this.props.reservation.companyId : null;
        let customer = null;
        let company = null;
        if (customerId) {
            customer = await getSpecificModelRow('Customer', customerId);
        }
        if (companyId) {
            company = await getSpecificModelRow('Company', companyId);
        }

        this.setState({ customer, company });
    }
    reservationNotesChangeHandler(e) {
        this.setState({ reservationNotesOverride: e.target.value, reservationNotesSaved: false });
    }
    async saveNotesUpdate() {
        let reservation = {
            id: this.props.reservation.id,
            notes: this.state.reservationNotesOverride,
            roomInfoId: this.props.reservation.roomInfoId,
            checkInTimestamp: this.props.reservation.checkInTimestamp,
            checkOutTimestamp: this.props.reservation.checkOutTimestamp,
            statusEnum: this.props.reservation.statusEnum,
            adultsNumber: this.props.reservation.adultsNumber,
            childrenNumber: this.props.reservation.childrenNumber,
            accommodationPriceListId: this.props.reservation.accommodationPriceListId
        };
        this.setState({ reservationNotesSaved: true });
        try {
            await rest('/safeSaveOrUpdateReservationModel', 'POST', reservation);
        } catch (err) {
            console.log(err);
            this.setState({ reservationNotesSaved: false });
        }
    }

    handleActionLink(e) {
        e.preventDefault();
        if (e.target.target) {
            this.props.history.push(e.target.target);
        }
    }

    render() {
        if (this.props.reservation) {
            let checkoutOverdueWarning = null;
            if (this.props.reservation.checkOutTimestamp < Date.now()) {
                checkoutOverdueWarning = (
                    <h3 className="text-center bg-white  rounded text-secondary uppercase">
                        {window.translate("Checkout overdue")} {moment(this.props.reservation.checkOutTimestamp).fromNow()}
                    </h3>
                );
            }
            //console.log(this.props.reservation, this.props.reservation.statusEnum === reservationEnums.confirmed || this.props.reservation.statusEnum === reservationEnums.checkedIn);
            let guestName = this.state.customer ? (
                <h5 className="card-title mb-0 elipsis">
                    <i className="fa fa-hotel mr-1" aria-hidden="true"></i>
                    {this.state.customer.firstName + ' ' + this.state.customer.lastName}
                </h5>
            ) : (
                'JOHN DOE'
            );
            if (this.state.company) {
                guestName = (
                    <h5 className="card-title mb-0 elipsis">
                        <i className="fa fa-building mr-1" aria-hidden="true"></i>
                        {this.state.company.name}{' '}
                    </h5>
                );
            }
            let email = this.state.customer ? (
                <div className="elipsis">
                    <i className="fa fa-envelope-o mr-1" aria-hidden="true"></i>
                    {this.state.customer.email}
                </div>
            ) : (
                ''
            );
            let phoneOrMobile = this.state.customer ? (
                <div className="elipsis">
                    <i className="fa fa-phone mr-1" aria-hidden="true"></i>
                    {this.state.customer.phoneOrMobile}
                </div>
            ) : (
                ''
            );
            return (
                <div className="card bg-white mb-2 ">
                    {!this.props.roomInfo.availableForRent ? (
                        <h3 className="text-warning elipsis text-center bg-white rounded">
                            {window.translate("OUT OF SERVICE")}
                        </h3>
                    ) : null}

                    <div className="p-3">
                        {checkoutOverdueWarning}

                        <div className="row">
                            <div className="col-6">{guestName}</div>

                            <div className="col-6 ">#{this.props.reservation.id}</div>

                            <div className="col-6 ">
                                <small>
                                    <i className="fa fa-calendar-check-o" aria-hidden="true"></i>{' '}
                                    {moment(this.props.reservation.checkInTimestamp).format('ddd, DD. MMM')}
                                </small>
                            </div>

                            <div className="col-6">
                                <small>
                                    <i className="fa fa-sign-out" aria-hidden="true"></i>{' '}
                                    {moment(this.props.reservation.checkOutTimestamp).format('ddd, DD. MMM')}
                                </small>
                            </div>

                            <div className="col-6 ">
                                <small> {email}</small>
                            </div>

                            <div className="col-6 ">
                                <small>{phoneOrMobile}</small>
                            </div>

                            <div className="col-md-12 text-right">
                                <button
                                    onClick={() => {
                                        this.props.history.push('/reservationDetails/' + this.props.reservation.id);
                                    }}
                                    className="btn btn-sm btn-primary ml-2"
                                >
                                    {window.translate("Edit reservation")}
                                </button>
                            </div>
                        </div>

                        <div className="row mt-0">
                            <div className="col-md-12">
                                <small>
                                    <b>{window.translate("Reservation note")}:</b>
                                </small>
                                <textarea
                                    onChange={this.reservationNotesChangeHandler.bind(this)}
                                    className="form-control"
                                    value={
                                        this.state.reservationNotesOverride !== ''
                                            ? this.state.reservationNotesOverride
                                            : this.props.reservation.notes
                                            ? this.props.reservation.notes
                                            : ''
                                    }
                                ></textarea>
                                {!this.state.reservationNotesSaved ? (
                                    <button
                                        onClick={this.saveNotesUpdate.bind(this)}
                                        className="btn btn-sm btn-success pull-right mt-3"
                                    >
                                        {window.translate("Save note update")}
                                    </button>
                                ) : null}
                            </div>
                        </div>

                        <div className="row mt-3">
                            <div className={'col-md-6 ' + reservationStatusColors[this.props.reservation.statusEnum]}>
                                {window.translate("Reservation status")}:{' '}
                                <b>{reservationStatusEnumsDesc[this.props.reservation.statusEnum]}</b>
                            </div>
                            <div className="col-md-6 text-right">
                                {this.props.reservation.statusEnum === reservationEnums.confirmed ||
                                this.props.reservation.statusEnum === reservationEnums.checkedIn ? (
                                    <button
                                        onClick={() => {
                                            this.props.history.push('/checkoutAndInvoice/' + this.props.reservation.id);
                                        }}
                                        className="btn btn-sm btn-secondary ml-2"
                                    >
                                        {window.translate("Check out")}
                                    </button>
                                ) : null}

                                {this.props.reservation.statusEnum === reservationEnums.confirmed ||
                                this.props.reservation.statusEnum === reservationEnums.checkedIn ? null : this.props
                                      .reservation.statusEnum === reservationEnums.closed ? null : (
                                    <button
                                        onClick={() => {
                                            this.props.history.push(
                                                '/reservations/' +
                                                    this.props.reservation.id +
                                                    '/edit?changeStatus'
                                            );
                                        }}
                                        className="btn btn-sm btn-secondary ml-2"
                                    >
                                        {window.translate("Change")}
                                    </button>
                                )}
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    {!this.props.roomInfo.availableForRent ? (
                        <h3 className="text-warning text-center bg-white rounded p-2 mb-1">
                            {window.translate("OUT OF SERVICE")}
                        </h3>
                    ) : null}
                    <h3 className="text-center bg-white  rounded text-primary p-2 mb-1">
                        {window.translate("Room is available")}
                    </h3>
                </div>
            );
        }
    }
}

export default Present;
