import React from 'react';
import moment from 'moment';
import 'moment/min/locales.min';
import TerminalHeader from './terminalHeader';
import { loadInvoicePreview, loadGroupInvoiceDetails } from '../printView/controler';
//@ts-ignore
import Helmet from 'react-helmet';

//@ts-ignore
import QRCode from 'qrcode.react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';

import { InvoiceTypeEnum } from '../checkout/interfaces';
import {
    CompanyInfoModel,
    CustomerInfoModel,
    RoomInfoMap,
    InvoicePresenter,
    IssuerCompanyInfo,
    ReservationModelWithModelsIncluded,
} from '@common/modelDefinition';
import translate from '@data/translations';

export enum MethodOfPaymentEnum {
    G = 'Novčanice',
    K = 'Kartica',
    C = 'Ček',
    T = 'Transakcija',
    O = 'Ostalo',
    V = 'Virman',
}

export enum InvoiceTypeLabel {
    'R',
    'A',
    'P',
}

export interface InvoiceHeaderViewProps extends InvoicePresenter {
    setHTMLTitle?: boolean;
    showGuestsNumber?: boolean;
    translate: any;
    roomInfoMap?: RoomInfoMap;
}

const InvoiceHeaderView = (props: InvoiceHeaderViewProps) => {
    const { invoice, translate, setHTMLTitle = false } = props;

    const { id, invoiceType, reservationId, reservationGroupUuid, reclamation, terminalType } = invoice;

    if (!invoice) {
        return null;
    }

    let invoiceHeader: string | null = null;
    if (reclamation !== 2) {
        // 0 - nije reklamiran, 1 - reklamirani račun, 2 - storno računa (kopija sa negativnim vrijednostima)
        if (invoiceType === InvoiceTypeEnum.geniue) {
            invoiceHeader = translate('INVOICE');
        } else if (invoiceType === InvoiceTypeEnum.advance) {
            invoiceHeader = translate('ADVANCE INVOICE');
        } else if (invoiceType === InvoiceTypeEnum.proforma) {
            invoiceHeader = translate('PROFORMA INVOICE');
        }
    } else {
        if (invoiceType === InvoiceTypeEnum.geniue) {
            invoiceHeader = translate('STORNO INVOICE');
        } else if (invoiceType === InvoiceTypeEnum.advance) {
            invoiceHeader = translate('STORNO ADVANCE INVOICE');
        } else if (invoiceType === InvoiceTypeEnum.proforma) {
            invoiceHeader = translate('STORNO PROFORMA INVOICE');
        }
    }

    return (
        <div className="">
            <div className="row text-center ">
                <div className="col-md-12">
                    <InvoiceIssuer {...props} />
                </div>
            </div>
            <div className="row text-center mb-2">
                <div className="col-md-6">
                    <InvoiceInfo {...props} />
                </div>
                <div className="col-md-6 border">
                    <InvoiceTo {...props} />
                </div>
            </div>

            {terminalType === 'reservation' || terminalType === 'reservationConsumption' ? (
                reservationGroupUuid ? (
                    <GroupReservationHeader {...props} />
                ) : reservationId ? (
                    <ReservationHeader {...props} />
                ) : null
            ) : terminalType === 'posTerminal' || terminalType === 'minibar' ? (
                <TerminalHeader {...props} />
            ) : null}

            <div className=" text-center mt-4 mb-4">
                <h5>
                    {translate(invoiceHeader)} #{id}
                </h5>
            </div>
            {setHTMLTitle ? (
                <Helmet>
                    <title>{`${invoiceHeader}_${id}_res_${reservationId}`}</title>
                </Helmet>
            ) : null}
        </div>
    );
};

const InvoiceInfo = (props: InvoiceHeaderViewProps) => {
    const { invoice, translate } = props;
    const {
        id,
        sfJIR,
        invoiceType,
        operatorName,
        timestamp,
        fiscalId,
        reclamationFiscalId,
        reclamation,
        locale = 'eng',
        totalValue,
        invoiceNumber,
        sfBusinessSpaceLabel,
        sfTollDeviceLabel,
        typeOfPayment,
        fiscalSRCProxy,
        issuerSecurityCode,
        invoiceNumberFull,
    } = invoice;

    const fiscalNumber = reclamation === 2 ? reclamationFiscalId : fiscalId;
    const issueDateTimestamp = timestamp ? timestamp : Date.now();
    const qrDate: string = timestamp ? moment(timestamp).format('YYYYMMDD') : '';
    const qrTime: string = timestamp ? moment(timestamp).format('HHMM') : '';
    const qrTotal: number = totalValue ? Number(totalValue.toFixed(2)) * 100 : 0;

    const invoiceNumberFullPreview = invoiceNumberFull
        ? invoiceNumberFull
        : `${InvoiceTypeLabel[invoiceType]} - ${invoiceNumber ? invoiceNumber:''} / ${moment(issueDateTimestamp).format('YYYY')}`;

    return (
        <div className="row">
            <div className="col-md-6 text-left">
                <small>
                    <div>#{id}</div>
                    <div>
                        <b>{translate('Date')}</b>: {moment(issueDateTimestamp).locale(locale).format('LL')}
                    </div>
                    <div>
                        <b>{translate('Invoicing time')}</b>: {moment(issueDateTimestamp).locale(locale).format('LLL')}
                    </div>
                    <div>
                        <b>{translate('Invoice nr')}</b>: {invoiceNumberFullPreview}
                    </div>
                    {sfBusinessSpaceLabel && sfTollDeviceLabel && fiscalId ? (
                        <div>
                            <b>{translate('Invoice number')}</b>:{' '}
                            {`${fiscalId}/${sfBusinessSpaceLabel}/${sfTollDeviceLabel}`}
                        </div>
                    ) : null}
                    {invoiceType === 0 && fiscalId && fiscalSRCProxy ? (
                        <div>
                            <b>F.BR</b>: {fiscalId}
                        </div>
                    ) : null}
                    {invoiceType === 0 && reclamationFiscalId && fiscalSRCProxy ? (
                        <div>
                            <b>R.F.BR</b>: {reclamationFiscalId}
                        </div>
                    ) : null}
                    <div>
                        <b>{translate('Invoice issued by')} </b>: {operatorName}
                    </div>
                    <div>
                        <b>{translate('Method of payment')} </b>:{' '}
                        {MethodOfPaymentEnum[typeOfPayment as keyof typeof MethodOfPaymentEnum]}
                    </div>
                </small>
            </div>
            {sfJIR ? ( //HR - ima sfJIR i fiscalNumber
                <React.Fragment>
                    <div className="col-md-6 text-left mt-2">
                        <QRCode
                            value={`https://porezna.gov.hr/rn?jir=${sfJIR}&datv=${qrDate}_${qrTime}&izn=${qrTotal}`}
                            size={100}
                        />
                    </div>
                    {invoiceType !== 2 ? (
                        <div className="col-md-12 text-left">
                            <b>JIR:</b> {sfJIR}
                        </div>
                    ) : invoice.id && !invoice.pendingInvoice ? (
                        <div className="col-md-12 text-left">OVO NIJE FISKALIZIRANI RAČUN</div>
                    ) : null}
                    {issuerSecurityCode ? (
                        <div className="col-md-12 text-left">
                            <b>ZKI:</b> {issuerSecurityCode}
                        </div>
                    ) : null}
                </React.Fragment>
            ) : fiscalNumber ? ( //BiH - ima samo fiscalNumber
                <div className="col-md-12 text-left"></div>
            ) : invoice.id && !invoice.pendingInvoice ? (
                <div className="col-md-12 text-left">OVO NIJE FISKALIZIRANI RAČUN</div>
            ) : null}
        </div>
    );
};

interface groupReservationParams {
    reservationMadeBy: string | null;
    receptionistOIB: string | null;
    adultsNumberSum: number | null;
    roomInfo: any | null;
    adultsNumber: any | null;
}

const GroupReservationHeader = (props: InvoiceHeaderViewProps) => {
    const { invoice, translate, showGuestsNumber = true } = props;
    const { reservationGroupUuid, params, checkInTimestamp, checkOutTimestamp, locale } = invoice;

    const parsedParams: groupReservationParams = params ? JSON.parse(params) : {};
    const { reservationMadeBy = null, adultsNumberSum = null } = parsedParams;

    return (
        <div className="d-flex justify-content-between mt-3">
            <div className="col-4">
                <small>
                    <div>
                        <b>{translate('UUID')}</b>: {reservationGroupUuid}
                    </div>

                    <div>
                        <b>{translate('Reservation made by')}</b>: {reservationMadeBy}
                    </div>
                </small>
            </div>
            <div className="col-4">
                <small>
                    <div>
                        <b>{translate('Check in')}</b>: {moment(checkInTimestamp).locale(locale).format('LL')}
                    </div>
                    <div>
                        <b>{translate('Check out')}</b>: {moment(checkOutTimestamp).locale(locale).format('LL')}
                    </div>
                </small>
            </div>
            <div className="col-4 ">
                <small>
                    <div>
                        <b>Rooms: </b>
                        <RelatedReservationInvoicePreview reservationGroupUuid={reservationGroupUuid} {...props} />
                    </div>
                    {showGuestsNumber && (
                        <div>
                            <b>{translate('Number of guests')}:</b>
                            {` ${adultsNumberSum}`}
                        </div>
                    )}
                </small>
            </div>
        </div>
    );
};

const ReservationHeader = (props: InvoiceHeaderViewProps) => {
    const { invoice, translate, showGuestsNumber } = props;
    const {
        checkInTimestamp,
        checkOutTimestamp,
        //  timestamp,
        reservationId,
        params,
        // detailsLevel = 0,
        locale = 'eng',
    } = invoice;

    const parsedParams = params ? JSON.parse(params) : {};
    //  const issueDateTimestamp = timestamp ? timestamp : Date.now();
    const {
        reservationMadeBy: reservationMakerName = null,
        roomName = null,
        adultsNumber = null,
        childrenNumber = null,
    } = parsedParams;

    return (
        <div className="d-flex justify-content-between mt-3 p-2">
            <div className="p-2">
                <small>
                    <div>
                        <b>{translate('Reservation id')}</b>: {reservationId}
                    </div>

                    <div>
                        <b>{translate('Reservation made by')}</b>: {reservationMakerName}
                    </div>
                </small>
            </div>
            <div className="p-2">
                <small>
                    <div>
                        <b>{translate('Check in')}</b>: {moment(checkInTimestamp).locale(locale).format('LL')}
                    </div>
                    <div>
                        <b>{translate('Check out')}</b>: {moment(checkOutTimestamp).locale(locale).format('LL')}
                    </div>
                </small>
            </div>
            <div className="p-2 ">
                <small>
                    {roomName && (
                        <div>
                            <b>{translate('ROOM NUMBER')}</b>: {roomName}{' '}
                        </div>
                    )}

                    {adultsNumber && showGuestsNumber && (
                        <div>
                            <b>{translate('Adults')}</b>: {adultsNumber}{' '}
                        </div>
                    )}
                    {childrenNumber && showGuestsNumber ? (
                        <div>
                            <b>{translate('Children')}</b>: {childrenNumber}{' '}
                        </div>
                    ) : (
                        ''
                    )}
                </small>
            </div>
        </div>
    );
};

const InvoiceIssuer = (props: InvoiceHeaderViewProps) => {
    const { invoice, translate } = props;

    const { issuerCompanyInfo } = invoice;
    if (!issuerCompanyInfo) {
        return translate('Add your company info');
    }
    const p: IssuerCompanyInfo = JSON.parse(issuerCompanyInfo);
    return (
        <div className="border-bottom mb-1 p-2">
            <h5>{p.hotelName}</h5>
            <b>{p.name}</b>
            <div>{p.idNumber}</div>
            <div>{p.address}</div>
            <div>
                {p.postOfficeNumber} {p.town}
            </div>
        </div>
    );
};

const InvoiceTo = (props: InvoiceHeaderViewProps) => {
    const { invoice, translate } = props;
    const { customerInfo, companyInfo } = invoice;

    if (!customerInfo && !companyInfo) {
        return <div>{translate('Guest')}:</div>;
    }
    const customer: CustomerInfoModel = customerInfo ? JSON.parse(customerInfo) : null;
    const company: CompanyInfoModel = companyInfo ? JSON.parse(companyInfo) : null;
    const customerName: string = customer && customer.firstName ? `${customer.firstName} ${customer.lastName}` : ''; //treba viditi ima li ovo kada je kompanija u pitanju, ako nema, treba ubaciti na backend kontroleru
    if (companyInfo) {
        return (
            <div>
                <h5>{company.name}</h5>
                <div>{company.idNumber}</div>
                {company.oib ? <div>{company.oib}</div> : null}
                <div>
                    {company.poNumber} {company.town}
                </div>
                <div>{customerName}</div>
            </div>
        );
    } else {
        return (
            <div>
                <h5>{customerName}</h5>
                <div>{customer.address}</div>
                <div>
                    {customer.poNumber} {customer.town}
                </div>
            </div>
        );
    }
};

interface RelatedReservationInvoicePreviewProps extends InvoiceHeaderViewProps {
    reservationGroupUuid: string | null;
}

const RelatedReservationInvoicePreview = (props: RelatedReservationInvoicePreviewProps) => {
    const { reservationGroupUuid, roomInfoMap, groupReservationsArray, groupInvoiceDetails, invoice, location } = props;
    // Sobe sa linkovima na pojedinačni invoice

    if (!groupReservationsArray) {
        return null;
    }

    const invoiceView = location && location.pathname.lastIndexOf('invoiceView') !== -1 ? true : false;

    return (
        <div className="btn-group mb-2 mb-md-0" role="group" aria-label="Select reservation">
            {groupReservationsArray.map((r: ReservationModelWithModelsIncluded) => {
                const room = roomInfoMap ? roomInfoMap[r.roomInfoId] : null;
                const roomName = room ? room.name : '';
                return invoiceView ? (
                    <React.Fragment key={r.id}>
                        <button
                            key={r.id}
                            onClick={() => {
                                loadInvoicePreview(r.id);
                            }}
                            className={
                                invoice && Number(r.id) === invoice.reservationId
                                    ? 'btn btn-sm btn-secondary d-print-none'
                                    : 'btn btn-sm btn-outline-secondary d-print-none'
                            }
                        >
                            {roomName ? roomName : r.roomInfoId}
                        </button>
                        <span key={'non-print ' + r.id} className="d-none d-print-block">
                            {roomName ? roomName : r.roomInfoId}
                            {', '}
                        </span>
                    </React.Fragment>
                ) : (
                    <span key={'preview' + r.id} className="">
                        {roomName ? roomName : r.roomInfoId}
                        {', '}
                    </span>
                );
            })}
            {groupReservationsArray.length > 0 && invoiceView ? (
                <React.Fragment>
                    <button
                        onClick={() => {
                            loadGroupInvoiceDetails(reservationGroupUuid);
                        }}
                        className={
                            groupInvoiceDetails && // 👈 null and undefined check
                            Object.keys(groupInvoiceDetails).length > 0
                                ? 'btn btn-sm btn-secondary d-print-none'
                                : 'btn btn-sm btn-outline-secondary d-print-none'
                        }
                    >
                        {translate('All')}
                    </button>
                </React.Fragment>
            ) : null}
        </div>
    );
};

const SingleReservationInvoiceHeader = (props: InvoiceHeaderViewProps) => {
    return (
        <div className="">
            <div className="row text-center ">
                <div className="col-md-6">
                    <InvoiceIssuer {...props} />
                </div>
                <div className="col-md-6">
                    <div className="border">
                        <InvoiceTo {...props} />
                    </div>
                </div>
            </div>

            <ReservationHeader {...props} />
        </div>
    );
};

export default branch(
    {
        roomInfoMap: ['map', 'RoomInfo'],
        groupInvoiceDetails: ['state', 'invoicePresenter', 'groupInvoiceDetails'],
    },
    InvoiceHeaderView
);

export { InvoiceIssuer, SingleReservationInvoiceHeader };
