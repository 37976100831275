import React, { Component } from 'react';
import _ from 'lodash';
import { branch } from 'baobab-react/higher-order';
import rest from '../../data/restWrapper';

class MigrateSRCProxy extends Component {
    constructor() {
        super();
        this.state = {
            selection: -1,
            waitMessage: false,
        };
    }

    updateSelection(e) {
        if (Number(e.target.value) > 0) {
            this.setState({ selection: Number(e.target.value) });
        }
    }

    async commitMigration() {
        try {
            await rest('/dispatchProxyMigration', 'POST', {
                originSrcProxyId: this.props.originSRCProxy.id,
                targetSrcProxyId: this.state.selection,
            });
            this.setState({ waitMessage: false, selection: -1 });
            if (this.props.migrationCompletedCallback) {
                this.props.migrationCompletedCallback();
            }
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        if (this.state.waitMessage) {
            return (
                <div className="mt-4 text-center">
                    <b>{window.translate("PLEASE WAIT")}</b>
                </div>
            );
        }

        let migrateToProxies = this.props.srcProxies;
        return (
            <div>
                {window.translate("Migrate devices and rooms from ")} <b>{this.props.originSRCProxy.name}</b> {window.translate(" to")}
                :
                <select
                    onChange={this.updateSelection.bind(this)}
                    value={this.state.selection}
                    className="form-control"
                >
                    <option disabled={true} key={-1} value={-1}>
                        {window.translate("SELECT ONE")}
                    </option>
                    {_.map(migrateToProxies, (proxy) => {
                        let disabled = proxy.id === this.props.originSRCProxy.id;
                        return (
                            <option disabled={disabled} key={proxy.id} value={proxy.id}>
                                {proxy.name}
                            </option>
                        );
                    })}
                </select>
                <div className="text-center mt-4">
                    <button
                        onClick={this.commitMigration.bind(this)}
                        disabled={this.state.selection === -1}
                        className="btn btn-sm btn-outline-primary"
                    >
                        {window.translate("Confirm")}
                    </button>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        srcProxies: ['model', 'SRCProxy'],
    },
    MigrateSRCProxy
);
