import * as React from 'react';
import SelfCheckIn from './SelfCheckIn';
import SelfCheckInLogs from './SelfCheckInLogs';
// import SelfCheckInRepeatNotification from './SelfCheckInRepeatNotification';
import { ReservationController } from '../resController';

const ctrl = new ReservationController();

function SelfCheckInContainer() {
    const reservation = ctrl.getActiveReservation();
    if (!reservation.id) {
        return null;
    }

    return (
        <>
            <hr className="m-0 p-0" />
            <div className="row">
                <div className="col-md-6">
                    <SelfCheckIn />
                    {/* <SelfCheckInRepeatNotification reservation={reservation} /> */}
                </div>
                <div className="col-md-6">{reservation.enableSelfCheckIn && <SelfCheckInLogs />}</div>
            </div>
        </>
    );
}

export default SelfCheckInContainer;
