import React from 'react';
import { translate } from '@data/translations';
import { toast } from 'react-toastify';
import { createCashRegPayment, getPaymentPurposes, setPartialPayment } from './api';
import {
    CashRegistarModel,
    CashRegistarPaymentPurposeModel,
    CashRegistarPaymentsModel,
    InvoiceModel,
} from '@common/modelDefinition';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import moment from 'moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
//@ts-ignore
import { formatDate, parseDate } from 'react-day-picker/moment';
import { closeModal } from '@components/modal';

interface CashRegPaymentProps {
    cashRegistarId: number | null;
    cashRegistars: CashRegistarModel[];
    loggedUser: any;
    setReload: any;
    reload: boolean;
    history: any;
    invoice?: InvoiceModel; // ovo saljem kad pravim parcijalnu uplatu
}

const paymentTemplate = {
    amount: null,
    currency: null,
    OIB: null,
    description: null,
    customerId: null,
    companyId: null,
    paymentPurposeId: null,
    date: new Date(),
    invoiceId: null,
};

function CashRegistarPaymentForm(props: CashRegPaymentProps) {
    const { cashRegistars, cashRegistarId, loggedUser, setReload, reload, history, invoice } = props;
    const [cashRegistar, setCashRegistar] = React.useState({} as CashRegistarModel | null);
    const [payment, setPayment] = React.useState({ ...paymentTemplate } as any);
    const [paymentPurposes, setPaymentPurposes] = React.useState([] as CashRegistarPaymentPurposeModel[]);
    const [paymentType, setPaymentType] = React.useState('2');
    const [maxAmount, setMaxAmount] = React.useState(null as number | null); // za parcijalne uplate

    React.useEffect(() => {
        const loadData = async () => {
            let _cashRegistar = null;
            if (invoice) {
                //parcijalna uplata s virmana
                _cashRegistar = cashRegistars.find((cr: CashRegistarModel) => cr.name === 'virman_cashRegistar');
            } else {
                _cashRegistar =
                    cashRegistarId && cashRegistars
                        ? cashRegistars.find((cr: CashRegistarModel) => cr.id === Number(cashRegistarId))
                        : null;
            }
            setCashRegistar(_cashRegistar ? _cashRegistar : null);

            const purposes = await getPaymentPurposes();
            setPaymentPurposes(purposes);

            if (invoice?.totalValue) {
                const _max = invoice.totalValue - (invoice?.receivedPayment ? invoice.receivedPayment : 0.0);
                setMaxAmount(_max);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const handleInputChange = (e: any) => {
        const cashPayment: any = { ...payment };
        if (e.target.name === 'paymentPurposeId') {
            const _purpose = paymentPurposes.find((p: CashRegistarPaymentPurposeModel) => p.name === e.target.value);
            cashPayment[e.target.name] = _purpose ? _purpose.id : null;
        } else if (maxAmount && e.target.name === 'amount') {
            //parcijalna uplata
            if (e.target.value > maxAmount) {
                cashPayment[e.target.name] = maxAmount;
                toast(translate('Max value is invoice amount'), {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.WARNING,
                });
            } else {
                cashPayment[e.target.name] = e.target.value;
            }
        } else {
            cashPayment[e.target.name] = e.target.value;
        }
        setPayment(cashPayment);
    };

    const saveFormData = async () => {
        try {
            if (payment && payment.amount && payment.paymentPurposeId) {
                const newPayment = { ...payment };
                newPayment.date = moment(payment.date).valueOf();
                newPayment.currency = cashRegistar?.currency ? cashRegistar.currency : null;
                newPayment.customerId = loggedUser.customerId;
                // newPayment.cashRegistarId = cashRegistarId;

                if (invoice) {
                    //ako je parcijalna uplata
                    newPayment.currency = invoice.currency;
                    newPayment.invoiceId = invoice.id;
                    if (maxAmount && payment.amount > maxAmount) {
                        newPayment.amount = maxAmount;
                    }
                }

                await createCashRegPayment(newPayment, Number(cashRegistar?.id));
                if (uncollectedBillsPath && invoice?.id) {
                    const partialPayment =
                        (invoice?.receivedPayment ? Number(invoice.receivedPayment) : Number(0.0)) +
                        Number(parseFloat(newPayment.amount).toFixed(4));
                    await setPartialPayment(invoice?.id, partialPayment);
                }
                setPayment({} as CashRegistarPaymentsModel);
                setPaymentType('2');
                setReload(!reload);
                toast('Payment added!', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.INFO,
                });
            }
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
        closeModal();
    };

    const purpose =
        payment?.paymentPurposeId && paymentPurposes
            ? paymentPurposes.find((p: CashRegistarPaymentPurposeModel) => p.id === payment.paymentPurposeId)
            : null;

    const filterPurposes = paymentPurposes.filter((o: any) =>
        Number(paymentType) === 1 ? o.type === 1 : Number(paymentType) === 0 ? o.type === 0 : o
    );

    const uncollectedBillsPath = history.location.pathname.indexOf('uncollectedBills') !== -1;

    const purposesOptions =
        filterPurposes &&
        filterPurposes.map((c: any) => {
            if (!uncollectedBillsPath && (c.name === 'invoiceIssued' || c.name === 'invoiceStorno')) {
                return null;
            }
            return (
                <option value={c['name']} key={c['id']}>
                    {translate(c.name)}
                </option>
            );
        });

    return (
        <div>
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    * {translate('Amount')}:
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        placeholder={translate('Amount')}
                        name="amount"
                        value={payment?.amount ? payment.amount : ''}
                        onChange={(e) => {
                            handleInputChange(e);
                        }}
                        type="number"
                        className="form-control"
                        max={invoice ? Number(maxAmount) : undefined}
                        min={0}
                    />
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Payment type')}:
                </label>
                <div className="col-md-8">
                    <select
                        className="form-control"
                        value={paymentType ? paymentType : ''}
                        onChange={(e) => {
                            setPaymentType(e.target.value);
                        }}
                    >
                        <option value={2}>{translate('Nothing selected')}</option>
                        <option value={0}>{translate('Pay IN')}</option>
                        <option value={1}>{translate('Pay OUT')}</option>
                    </select>
                </div>
            </div>

            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    * {translate('Payment purpose')}:
                </label>
                <div className="col-md-8">
                    <select
                        name="paymentPurposeId"
                        className="form-control"
                        value={purpose ? purpose.name : ''}
                        onChange={(e) => {
                            handleInputChange(e);
                        }}
                    >
                        <option value={'no select'}>{translate('Nothing selected')}</option>
                        {purposesOptions}
                    </select>
                </div>
            </div>

            {uncollectedBillsPath ? (
                <div className="form-group row justify-content-center align-items-center">
                    <label className="col-md-4 col-form-label" htmlFor="">
                        {translate('Date')}:
                    </label>
                    <div className="col-md-8">
                        <DayPickerInput
                            inputProps={{
                                className: 'd-none d-sm-block form-control form-control-sm mt-1 mt-md-0',
                                readOnly: true,
                            }}
                            format={'ll'}
                            formatDate={formatDate}
                            parseDate={parseDate}
                            value={payment?.date ? payment.date : ''}
                            onDayChange={(_date) => {
                                const cashPayment: any = { ...payment };
                                cashPayment['date'] = new Date(_date);
                                setPayment(cashPayment);
                            }}
                        />
                    </div>
                </div>
            ) : null}

            <div className="form-group row justify-content-center align-items-center mb-4">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Description')}:
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        placeholder={translate('Description')}
                        name="description"
                        value={payment?.description ? payment.description : ''}
                        onChange={(e) => {
                            handleInputChange(e);
                        }}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>

            <div className="text-center mt-4">
                {payment && payment.amount && payment.paymentPurposeId ? (
                    <div
                        onClick={async () => {
                            await saveFormData();
                        }}
                        className="btn btn-outline-primary pointer"
                    >
                        {translate('Save')}
                    </div>
                ) : null}

                <div onClick={() => closeModal()} className="btn btn-outline-secondary ml-2 pointer">
                    {translate('Cancel')}
                </div>
            </div>
        </div>
    );
}

export default branch(
    {
        loggedUser: ['authTokenInfo', 'user'],
        cashRegistars: ['model', 'CashRegistar'],
    },
    CashRegistarPaymentForm
);
