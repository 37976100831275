import React from 'react';
import { useBranch } from 'baobab-react/hooks';

//OVAJ FILE USKORO NEĆE BITI POTREBAN ; NEKA GA OVDJE JOŠ MALO; NAMJERNO U JAVASCRIPTU

const PointOfSaleInputs = ({ invoice, translate, setPointOfSaleViaProps }) => {
    const { fiscalGeneralSettings } = useBranch({
        fiscalGeneralSettings: ['fiscal', 'fiscalGeneralSettings'],
    });
    const { enableFiscalisation = false, country = '' } = fiscalGeneralSettings
        ? fiscalGeneralSettings
        : { enableFiscalisation: false, country: '' };

    if (!enableFiscalisation) {
        return null;
    }

    const props = { invoice, translate, setPointOfSaleViaProps };

    return (
        <div>
            <TypeOfPayment country={country} {...props} />
            {/* {country === 'CRO' ? <SoftwareFiscalBusinessSpaceList {...props} /> : null}
            {country === 'CRO' ? <SoftwareFiscalTollDeviceList {...props} /> : null}
            {country === 'BIH' ? <SRCProxiesList {...props} /> : null} */}
        </div>
    );
};

// const SoftwareFiscalBusinessSpaceList = ({ invoice, translate, setPointOfSaleViaProps = setPointOfSaleInputs }) => {
//     const { softwareFiscal } = useBranch({
//         softwareFiscal: ['fiscal', 'softwareFiscal'],
//     });
//     const { sfBusinessSpaceLabels } = softwareFiscal;

//     if (!sfBusinessSpaceLabels || sfBusinessSpaceLabels.length === 0) {
//         return <div className="text-danger text-center">{translate('Business space sellection list is empty!')}</div>;
//     }
//     const { pendingInvoice, sfBusinessSpaceLabel } = invoice;
//     const buttons = sfBusinessSpaceLabels.map((bs, index) => {
//         return (
//             <button
//                 disabled={!pendingInvoice}
//                 key={index}
//                 onClick={() => {
//                     setPointOfSaleViaProps(bs, 'sfBusinessSpaceLabel');
//                 }}
//                 className={
//                     sfBusinessSpaceLabel && sfBusinessSpaceLabel === bs
//                         ? 'btn btn-sm btn-secondary mb-1 mb-md-0 mr-2 '
//                         : 'btn btn-sm btn-light border mb-1 mb-md-0 mr-2'
//                 }
//             >
//                 {' '}
//                 <i className="fa fa-print mr-1" />
//                 {bs}
//             </button>
//         );
//     });

//     return (
//         <div className="form-group row justify-content-center align-items-center">
//             <label className="col-4 col-form-label" htmlFor="">
//                 {translate('Business space label')}:
//             </label>
//             <div className="col-8 text-left">{buttons}</div>
//         </div>
//     );
// };

// const SoftwareFiscalTollDeviceList = ({ invoice, translate, setPointOfSaleViaProps = setPointOfSaleInputs }) => {
//     const { softwareFiscal } = useBranch({
//         softwareFiscal: ['fiscal', 'softwareFiscal'],
//     });
//     const { sfTollDeviceLabels } = softwareFiscal;
//     if (!sfTollDeviceLabels || sfTollDeviceLabels.length === 0) {
//         return <div className="text-danger text-center">{translate('Toll device sellection list is empty!')}</div>;
//     }
//     const { pendingInvoice, sfTollDeviceLabel } = invoice;

//     const buttons = sfTollDeviceLabels.map((td, index) => {
//         return (
//             <button
//                  disabled={!pendingInvoice}
//                 key={index}
//                 onClick={() => {
//                     setPointOfSaleViaProps(td, 'sfTollDeviceLabel');
//                 }}
//                 className={
//                     sfTollDeviceLabel && sfTollDeviceLabel === td
//                         ? 'btn btn-sm btn-secondary mb-1  mr-2 '
//                         : 'btn btn-sm btn-light border mb-1  mr-2'
//                 }
//             >
//                 {' '}
//                 <i className="fa fa-print mr-1" />
//                 {td}
//             </button>
//         );
//     });
//     return (
//         <div className="form-group row justify-content-center align-items-center">
//             <label className="col-4 col-form-label" htmlFor="">
//                 {translate('Toll device label')}:
//             </label>
//             <div className="col-8 text-left">{buttons}</div>
//         </div>
//     );
// };

// const SRCProxiesList = ({ invoice, translate, setPointOfSaleViaProps = setPointOfSaleInputs }) => {
//     const { SRCProxies } = useBranch({
//         SRCProxies: ['model', 'SRCProxy'],
//     });
//     const fiscalSRCProxies = SRCProxies.filter((s) => s.fiscalProxy);
//     if (fiscalSRCProxies.length === 0) {
//         return <div className="text-danger text-center">{translate('Fiscal printer list is empty!')}</div>;
//     }
//     const { pendingInvoice, fiscalSRCProxy } = invoice;
//     const buttons = fiscalSRCProxies.map((SRC) => {
//         return (
//             <button
//                 disabled={!pendingInvoice}
//                 key={SRC.id}
//                 onClick={() => {
//                     setPointOfSaleViaProps(SRC.SRCProxyUUID, 'fiscalSRCProxy');
//                 }}
//                 className={
//                     fiscalSRCProxy && fiscalSRCProxy === SRC.SRCProxyUUID
//                         ? 'btn btn-sm btn-secondary mb-1 mb-md-0 mr-2 '
//                         : 'btn btn-sm btn-light border mb-1 mb-md-0 mr-2'
//                 }
//             >
//                 {' '}
//                 <i className="fa fa-print mr-1" />
//                 {SRC.name}
//             </button>
//         );
//     });
//     return (
//         <div className="form-group row justify-content-center align-items-center">
//             <label className="col-4 col-form-label" htmlFor="">
//                 {translate('Fiscal printer')}:
//             </label>
//             <div className="col-8 text-left">{buttons}</div>
//         </div>
//     );
// };

const TypeOfPayment = ({ invoice, translate, country, setPointOfSaleViaProps }) => {
    let paymentTypes =
        country === 'BIH'
            ? [
                  { name: 'Gotovina', icon: 'fa fa-money mr-2', value: 'G' },
                  { name: 'Kartica', icon: 'fa fa-credit-card mr-2', value: 'K' },
                  { name: 'Ček', icon: 'fa fa-pencil mr-2', value: 'C' },
                  { name: 'Virman', icon: 'fa fa-google-wallet mr-2', value: 'V' },
              ]
            : country === 'CRO'
            ? [
                  { name: 'Gotovina', icon: 'fa fa-money mr-2', value: 'G' },
                  { name: 'Kartica', icon: 'fa fa-credit-card mr-2', value: 'K' },
                  { name: 'Ček', icon: 'fa fa-pencil mr-2', value: 'C' },
                  { name: 'Transakcija', icon: 'fa fa-google-wallet mr-2', value: 'T' },
                  { name: 'Ostalo', icon: 'fa fa-money mr-2', value: 'O' },
              ]
            : [
                  { name: 'G', icon: 'fa fa-money mr-2', value: 'G' },
                  { name: 'K', icon: 'fa fa-credit-card mr-2', value: 'K' },
                  { name: 'C', icon: 'fa fa-pencil mr-2', value: 'C' },
                  { name: 'T', icon: 'fa fa-google-wallet mr-2', value: 'T' },
                  { name: 'O', icon: 'fa fa-money mr-2', value: 'O' },
              ];

    const { pendingInvoice, typeOfPayment } = invoice;

    return (
        <div className="form-group row justify-content-center align-items-center">
            <label className="col-3 col-form-label" htmlFor="">
                <small>{translate('Type of payment')}:</small>
            </label>
            <div className="col-9 text-center">
                {paymentTypes.map((type, key) => {
                    return (
                        <button
                            disabled={!pendingInvoice}
                            key={key}
                            onClick={() => {
                                const { value } = type;
                                setPointOfSaleViaProps(value, 'typeOfPayment');
                            }}
                            className={
                                typeOfPayment === type.value
                                    ? 'btn btn-sm btn-secondary m-1 '
                                    : 'btn btn-sm btn-light border m-1'
                            }
                        >
                            <i className={type.icon}></i>
                            {type.name}
                        </button>
                    );
                })}
            </div>
        </div>
    );
};

export default PointOfSaleInputs;
