import React from 'react';
import rest from '@data/restWrapper';
import FeatureFlag from '@components/FeatureFlag';
import { translate } from '@data/translations';
import { setModalContent, closeModal } from '@components/modal';
import { RoomInfoEditModel } from '@common/modelDefinition';

interface GuestInterface {
	code: number;
	validToTimestamp: number;
	cardCodeType: string;
}

interface ConfirmationModalProps {
	title?: string;
	callback?: any;
}

async function setCardCodeWithValidToTime(remoteDeviceId: string, guest: GuestInterface) {
	try {
		await rest('/src/setCardCodeWithValidToTime/' + remoteDeviceId, 'POST', guest);
	} catch (err: any) {
		console.warn(err);
	}
}

const hardwareClearRoomCodes = async (room: RoomInfoEditModel) => {
	if (room && room.remoteDeviceId) {
		const guest1: GuestInterface = {
			code: 0,
			validToTimestamp: Date.now(),
			cardCodeType: 'guest1',
		};
		const guest2: GuestInterface = {
			code: 0,
			validToTimestamp: Date.now(),
			cardCodeType: 'guest2',
		};
		const guest3: GuestInterface = {
			code: 0,
			validToTimestamp: Date.now(),
			cardCodeType: 'guest3',
		};

		const { remoteDeviceId } = room;
		await setCardCodeWithValidToTime(remoteDeviceId, guest1);
		await setCardCodeWithValidToTime(remoteDeviceId, guest2);
		await setCardCodeWithValidToTime(remoteDeviceId, guest3);
	}
};

const showConfirmationModal = (props: ConfirmationModalProps) => {
	const { title, callback } = props;
	setModalContent(
		<div className="container-fluid">
			<div className="text-center">
				{translate('Please confirm?')}
				<hr />
			</div>

			<div className="text-center">
				<button
					onClick={async () => {
						callback();
						closeModal();
					}}
					className="btn btn-sm btn-outline-primary mr-2"
				>
					{translate('Confirm')}
				</button>

				<button
					onClick={() => {
						closeModal();
					}}
					className="btn btn-sm btn-outline-secondary"
				>
					{translate('Cancel')}
				</button>
			</div>
		</div>,

		title, // header
		false, // showCloseButton
		'modal-md'
	);
};

function SRCClearRoomCodes(props: { roomInfo: RoomInfoEditModel }) {
	const { roomInfo } = props;

	if(!roomInfo.remoteDeviceId){
		return <div>Clear access codes (no HW binding)</div>;
	}

	return <FeatureFlag flag={'hardware'}>
		<button
			className="btn btn-outline-danger p-3 uppercase"
			onClick={() => {
				const confirmationModalProps: ConfirmationModalProps = {
					title: 'Clear room access codes',
					callback: () => {
						hardwareClearRoomCodes(roomInfo);
					},
				};
				showConfirmationModal(confirmationModalProps);
			}}
		>
			{translate('CLEAR ROOM CODES')}
		</button>
	</FeatureFlag>;
}

export  {SRCClearRoomCodes};