import React from 'react';
import { getCondoRoomInfoAndReservationsInfo } from './condoApi';
//@ts-ignore
import moment from 'moment/min/moment-with-locales';
import { translate } from '@data/translations';
import { userFrendlyAlarmAndEventsNames } from '@data/SRCAlarmAndAlertController';
import { Reservation } from '../resController';

interface RoomInfoAndReservationsInfoProps {
	roomInfoId: number;
}

function RoomInfoAndReservationsInfo(props: RoomInfoAndReservationsInfoProps) {
	const { roomInfoId } = props;

	const [reservations, setReservations] = React.useState([]);
	const [eventTickets, setEventTickets] = React.useState([]);

	React.useEffect(() => {
		const loadData = async () => {
			const data = await getCondoRoomInfoAndReservationsInfo(roomInfoId);
			setReservations(data.reservations);
			setEventTickets(data.eventTickets);
		};

		loadData();
	}, [roomInfoId]);

	return (
		<div className="row">
			<div className="col-md-6">
				<ul className="list-group">
				<li className="d-flex justify-content-center" ><h4>{translate('Reservations')}</h4></li>
					{reservations.map((r: Reservation) => {
						return (
							<li className="d-flex justify-content-between" key={r.id}>
								<div>
									<small>
										<b>{translate('Check in')}:</b> {moment(r.checkInTimestamp).format('ll')}
									</small>
								</div>
								<div>
									<small>
										<b>{translate('Check out')}:</b> {moment(r.checkOutTimestamp).format('ll')}
									</small>
								</div>
								<div>
									<small>
										<b>{translate('Adults')}:</b> {r.adultsNumber}
									</small>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
			<div className="col-md-6">
				<ul className="list-group">
				<li className="d-flex justify-content-center" ><h4>{translate('Events')}</h4></li>
					{eventTickets.map((ev: any) => {
						return (
							<li className={ev.reservationId ? "d-flex justify-content-between" : "d-flex justify-content-between text-danger"} key={ev.id}>
								<div>
									<small>{moment(ev.openingTimestamp).format('L')}</small>
								</div>
								<div>
									<small>{(userFrendlyAlarmAndEventsNames as any)[ev.ruleName]}</small>
								</div>
								<div>
									<small>
										{moment.duration(ev.openingTimestamp - ev.closingTimestamp).humanize()}
									</small>
								</div>
							</li>
						);
					})}
				</ul>
			</div>
		</div>
	);
}

export default RoomInfoAndReservationsInfo;