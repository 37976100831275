import React, { Component } from 'react';
import _ from 'lodash';
import { formatDate, parseDate } from 'react-day-picker/moment';
import DayPickerInput from 'react-day-picker/DayPickerInput';
import { branch } from 'baobab-react/higher-order';
import { reservationEnums } from '../../data/reservationStatusEnums';
import FeatureFlag from '../../components/FeatureFlag';
import moment from 'moment/min/moment-with-locales';
import baobab from '../../data/state/index';

class FromToFilter extends Component {
    constructor(props) {
        super(props);
        this.state = {
            stayInDays: '',
            enableFilteringByDate: false,
            availableFrom: Date.now(),
            availableFrom2: '',
            availableTo: null, //Date.now() + 24*60*60*1000
        };
    }

    componentDidMount() {
        if (this.state.availableTo !== null) {
            this.calculateAvailableRoomsInRange();
        } else {
            if (this.props.filterOutRoomsById.length > 0) {
                this.props.setHideRoomByIdFilter([]);
            }
            //
        }
    }

    componentDidUpdate() {
        if (this.state.availableTo !== null) {
            this.calculateAvailableRoomsInRange();
        } else {
            if (this.props.filterOutRoomsById.length > 0) {
                this.props.setHideRoomByIdFilter([]);
            }
        }
    }

    calculateAvailableRoomsInRange() {
        if (this.props.reservations) {
            let roomIdWithReservationOverlapping = [];
            _.forEach(this.props.reservations, (r) => {
                let b = moment(this.state.availableFrom).toDate();
                b.setHours(22, 59, 59);
                const availableFrom_adjusted = b.getTime();

                let a = moment(this.state.availableTo).add(-1, 'd').toDate();
                a.setHours(22, 59, 59);
                const availableTo_adjusted = a.getTime();

                if (r.checkInTimestamp > availableFrom_adjusted && r.checkInTimestamp < availableTo_adjusted) {
                    //test checkin overlap
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }
                if (r.checkOutTimestamp > availableFrom_adjusted && r.checkOutTimestamp < availableTo_adjusted) {
                    //test checkout overlap
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }
                if (r.checkInTimestamp === availableFrom_adjusted && r.checkOutTimestamp === availableTo_adjusted) {
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }

                if (r.checkInTimestamp < availableFrom_adjusted && r.checkOutTimestamp > availableFrom_adjusted) {
                    if (r.statusEnum !== reservationEnums.closed && r.statusEnum !== reservationEnums.canceled) {
                        roomIdWithReservationOverlapping.push(r.roomInfoId);
                    }
                }
            });
            roomIdWithReservationOverlapping = _.uniq(roomIdWithReservationOverlapping);
            let hasChangedFromLastRender =
                this.props.filterOutRoomsById.join(',') !== roomIdWithReservationOverlapping.join(',');
            if (hasChangedFromLastRender) {
                if (this.props.setHideRoomByIdFilter) {
                    this.props.setHideRoomByIdFilter(roomIdWithReservationOverlapping);
                }
            }
        }
    }

    getHourAndMinuteObj(time) {
        let newTime = time ? time.split(':') : [12, 0]; //add default time if setting does not exist
        let hour = Number(newTime[0]);
        let minute = Number(newTime[1]);
        return { hour, minute };
    }

    setInputs(availableFrom, availableTo, stayInDays) {
        const { checkInTime = '14:00', checkOutTime = '10:00' } = this.props.otherSettings;
        let checkInHourAndMinute = this.getHourAndMinuteObj(checkInTime);
        let checkOutHourAndMinute = this.getHourAndMinuteObj(checkOutTime);
        const availableFromAdjusted = new Date(
            new Date(availableFrom).setHours(checkInHourAndMinute.hour, checkInHourAndMinute.minute, 0, 0)
        ).getTime();

        if (stayInDays === 0) {
            this.setState({ stayInDays: null, availableTo: null });
            this.props.setAvailableFromAndToTimestamps(availableFromAdjusted, null, null);
        } else {
            const availableToAdjusted = new Date(
                new Date(availableTo).setHours(checkOutHourAndMinute.hour, checkOutHourAndMinute.minute, 0, 0)
            ).getTime();

            this.setState({ availableFrom: availableFromAdjusted, stayInDays, availableTo: availableToAdjusted });
            this.props.setAvailableFromAndToTimestamps(availableFromAdjusted, availableToAdjusted, stayInDays);
        }
    }

    handleAvailableFromDatePick(pick) {
        const { stayInDays } = this.state;
        try {
            if (stayInDays === '') {
                let availableTo = pick.getTime() + 24 * 60 * 60 * 1000;
                this.setInputs(pick.getTime(), availableTo, 1);
            } else {
                let availableTo = pick.getTime() + 24 * 60 * 60 * 1000 * Number(stayInDays);
                this.setInputs(pick.getTime(), availableTo, this.state.stayInDays);
            }
        } catch (err) {
            console.warn(err);
        }
    }
    handleAvailableToDatePick(pick) {
        try {
            let stayInDays = Math.ceil((pick.getTime() - this.state.availableFrom) / (24 * 60 * 60 * 1000));
            this.setInputs(this.state.availableFrom, pick.getTime(), stayInDays);
        } catch (err) {
            console.warn(err);
        }
    }

    changeStayInDays(e) {
        let stayInDays = e.target.value;
        let availableTo = null;
        if (e.target.value !== '') {
            stayInDays = Number(e.target.value);
            availableTo = this.state.availableFrom + stayInDays * 24 * 60 * 60 * 1000;
        }
        this.setInputs(this.state.availableFrom, availableTo, stayInDays);
    }

    clearHandler() {
        this.setInputs(Date.now(), null, 0);
        this.props.toggleGroupReservations(false);
        this.props.setNumberOfBeds('');
        this.props.setNumberOfSpareBeds('')
    }

    toggleGroupReservations() {
        //this.props.toggleShowComponent("group_reservations")
        this.props.toggleGroupReservations();
        this.props.toggleSelectRoomForReservation(null);
    }

    makeGroupReservation() {
        const { availableFrom, availableTo, stayInDays } = this.state;
        const { selectedRoomsForReservations } = this.props;
        baobab.root.select('state', 'groupRes', 'groupReservation').set({});
        baobab.root.select('state', 'groupRes', 'reservations').set([]);

        this.props.history.push(
            `/reservations/${selectedRoomsForReservations[0]}/new?roomIds=${selectedRoomsForReservations.join(
                ','
            )}&from=${availableFrom}&to=${availableTo}&stayInDays=${stayInDays}`
        );
    }

    render() {
        let dayPicker_availableToStyles = { width: '110px' };
        let dayPicker_availableToStyles_wide = { width: '300px' };
        if (this.props.groupReservations && this.state.availableTo === null) {
            dayPicker_availableToStyles = { width: '110px', borderColor: 'rgb(220, 53, 69)' };
            dayPicker_availableToStyles_wide = { width: '300px', borderColor: 'rgb(220, 53, 69)' };
        }

        return (
            <div className="bg-white p-2 mb-1 rounded-sm shadow-sm">
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-inline">
                            <button
                                onClick={this.clearHandler.bind(this)}
                                className="btn btn-outline-light text-info btn-sm mt-1 mt-md-0 mr-2"
                            >
                                <i className="fa fa-eraser" />
                            </button>

                            <small>{window.translate('AVAILABLE FROM')}:&ensp;</small>
                            <DayPickerInput /*display depending screen width*/
                                inputProps={{
                                    style: { width: '110px' },
                                    className: 'd-none d-sm-block form-control form-control-sm mt-1 mt-md-0',
                                    readOnly: true,
                                }}
                                format={'ll'}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                value={this.state.availableFrom ? new Date(this.state.availableFrom) : ''}
                                onDayChange={this.handleAvailableFromDatePick.bind(this)}
                                dayPickerProps={{
                                    numberOfMonths: 1,
                                    showWeekNumbers: true,
                                    disabledDays: {
                                        before: new Date(),
                                    },
                                }}
                            />
                            <DayPickerInput /*display depending screen width*/
                                inputProps={{
                                    style: { width: '300px' },
                                    className: 'd-block d-sm-none form-control form-control-sm mt-1 mt-md-0',
                                    readOnly: true,
                                }}
                                format={'ll'}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                value={this.state.availableFrom ? new Date(this.state.availableFrom) : ''}
                                onDayChange={this.handleAvailableFromDatePick.bind(this)}
                                dayPickerProps={{
                                    numberOfMonths: 1,
                                    showWeekNumbers: true,
                                    disabledDays: {
                                        before: new Date(),
                                    },
                                }}
                            />
                            <small>&ensp;{window.translate('DAYS')}:&ensp;</small>
                            <input
                                style={{ width: '50px' }}
                                min={0}
                                value={this.state.stayInDays === null ? '' : this.state.stayInDays}
                                onChange={this.changeStayInDays.bind(this)}
                                className="mt-1 mt-md-0 form-control form-control-sm"
                                type="number"
                                placeholder={0}
                            />

                            <small>&ensp;{window.translate('TO')}:&ensp;</small>
                            <DayPickerInput /*display depending screen width*/
                                inputProps={{
                                    style: dayPicker_availableToStyles,
                                    className: 'd-none d-sm-block form-control form-control-sm mt-1 mt-md-0 mr-2',
                                    readOnly: true,
                                }}
                                format={'ll'}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                value={
                                    this.state.availableTo
                                        ? new Date(this.state.availableTo)
                                        : this.state.availableFrom2 === ''
                                        ? ''
                                        : new Date(this.state.availableFrom2)
                                }
                                onDayChange={this.handleAvailableToDatePick.bind(this)}
                                dayPickerProps={{
                                    showWeekNumbers: true,
                                    numberOfMonths: 1,
                                    disabledDays: {
                                        before: this.state.availableFrom
                                            ? new Date(this.state.availableFrom)
                                            : Date.now(),
                                        after: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
                                    },
                                }}
                            />

                            <DayPickerInput /*display depending screen width*/
                                inputProps={{
                                    style: dayPicker_availableToStyles_wide,
                                    className: 'd-block d-sm-none form-control form-control-sm mt-1 mt-md-0 mr-2',
                                    readOnly: true,
                                }}
                                format={'ll'}
                                formatDate={formatDate}
                                parseDate={parseDate}
                                value={
                                    this.state.availableTo
                                        ? new Date(this.state.availableTo)
                                        : this.state.availableFrom2 === ''
                                        ? ''
                                        : new Date(this.state.availableFrom2)
                                }
                                onDayChange={this.handleAvailableToDatePick.bind(this)}
                                dayPickerProps={{
                                    showWeekNumbers: true,
                                    numberOfMonths: 1,
                                    disabledDays: {
                                        before: this.state.availableFrom
                                            ? new Date(this.state.availableFrom)
                                            : Date.now(),
                                        after: new Date(Date.now() + 365 * 24 * 60 * 60 * 1000),
                                    },
                                }}
                            />
                            <input
                                onChange={this.props.setNumberOfBeds.bind(this)}
                                style={{ width: '70px' }}
                                className="form-control form-control-sm mt-1 mt-md-0 mr-2 "
                                type="number"
                                title={window.translate('Beds')}
                                placeholder={window.translate('Beds')}
                                value={this.props.numberOfBeds}
                                min={0}
                            />

                            <input
                                onChange={this.props.setNumberOfSpareBeds.bind(this)}
                                style={{ width: '70px' }}
                                className="form-control form-control-sm mt-1 mt-md-0 mr-2 "
                                type="number"
                                title={window.translate('Spare beds')}
                                placeholder={window.translate('Spare beds')}
                                value={this.props.numberOfSpareBeds}
                                min={0}
                            />

                            <FeatureFlag flag="groupReservations">
                                <button
                                    onClick={this.toggleGroupReservations.bind(this)}
                                    className={
                                        this.props.groupReservations
                                            ? 'btn  btn-sm btn-info mt-1 mt-md-0 mr-2'
                                            : 'btn  btn-sm btn-outline-info mt-1 mt-md-0 mr-2'
                                    }
                                >
                                    <i className="fa fa-plus" /> {window.translate('GROUP')}
                                </button>

                                {this.props.groupReservations ? (
                                    <button
                                        onClick={this.makeGroupReservation.bind(this)}
                                        disabled={this.props.selectedRoomsForReservations.length <= 1}
                                        className="btn btn-info btn-sm mt-1 mt-md-0 mr-2"
                                    >
                                        {window.translate('MAKE')}
                                    </button>
                                ) : null}

                                <button
                                    onClick={() => this.props.history.push('/groupReservations')}
                                    className="btn btn-outline-primary btn-sm mt-1 mt-md-0 mr-2"
                                >
                                    <i className="fa fa-list" /> {window.translate('GROUP VIEW')}
                                </button>
                            </FeatureFlag>
                            {this.state.availableTo ? <div  onClick={this.props.toogleOccupiedRooms.bind(this)} 
                                    className="form-control form-control-sm mt-1 mt-md-0 mr-2 pointer">
                                <i
                                    value={this.props.showOccupiedRooms}
                                    className={
                                    this.props.showOccupiedRooms
                                        ? 'fa fa-check-square-o mt-md-0 mr-2 '
                                        : 'fa fa-square-o mt-md-0 mr-2 '
                                    }
                                    >
                                </i>
                                {window.translate("Include occupied rooms")}
                            </div> : null}
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        otherSettings: ['otherSettings'],
    },
    FromToFilter
);
