import React, { useState } from 'react';
import PageTitle from '@components/pageTitle';

import { translate } from '@data/translations';
import { setModalContent } from '@components/modal';
import RoomInfoStatus from './roomInfoStatus';
import { UpdateCleaningStatus } from './updateCleaningStatus';
import UserProfile from '../userProfile';
import FeatureFlag, { isFeatureEnabled } from '@components/FeatureFlag';
import Tooltip from '@components/tooltip/tooltip';
import {
    CleaningStatusEnum,
    HouseholdDashboardProps,
    StatusFilterToolbarProps,
    CleaningStatusColors,
    CleaningStatusIcons,
    CleaningInspectionEnum,
    InspectionStatusColors,
    CleaningInspectionIcons,
    CleaningStatusTexts,
} from './interfaces';

import checkRoleAccess from '@data/checkRoleAccess';

import { CleaningStatusExtendedWithIncludedModels } from '@common/modelDefinition';

//@ts-ignore
import { branch } from 'baobab-react/higher-order';
//@ts-ignore

import PermissionCheck from '@components/PermissionCheck';

const Household = (props: HouseholdDashboardProps) => {
    const { loggedUser, history, cleaningStatusHeaders } = props;
    const [activeFilters, setActiveFilters] = useState<string[]>([]);
    const [changeMaidPassword, setChangeMaidPassword] = useState<boolean>(false);
    const presentDayCleaningStatusHeader = cleaningStatusHeaders[0];
    const cleaningStatuses: CleaningStatusExtendedWithIncludedModels[] = presentDayCleaningStatusHeader
        ? presentDayCleaningStatusHeader.CleaningStatuses
        : [];
    const showCleaningOptions = (cleaningStatus: CleaningStatusExtendedWithIncludedModels) => {
        const roomInfoId = cleaningStatus.roomInfoId;
        setModalContent(
            <UpdateCleaningStatus
                cleaningStatus={cleaningStatus}
                onMinibarManagementClick={() => {
                    history.push('/roomsAdministration/minibar/' + roomInfoId); //modal will break the router in production. this is work around
                }}
                onMinibarClick={() => {
                    history.push('/household/minibar/' + roomInfoId); //modal will break the router in production. this is work around
                }}
                loggedUser={loggedUser}
            />,
            translate('Change cleaning status'),
            true
            // 'modal-md'
        );
    };

    if (!isFeatureEnabled('household')) {
        return 'Household disabled';
    }
    if (changeMaidPassword) {
        return (
            <div className="text-center">
                <UserProfile />
                <button
                    onClick={() => setChangeMaidPassword(!changeMaidPassword)}
                    className="btn btn-secondary btn-sm mt-2"
                >
                    {translate('GO BACK')}
                </button>
            </div>
        );
    }

    const role = loggedUser ? loggedUser.role : null;
    const userUuid = loggedUser ? loggedUser.uuid : null;

    const filteredRoomCleaningStatusesByActiveFilters: CleaningStatusExtendedWithIncludedModels[] =
        activeFilters.length > 0
            ? cleaningStatuses.filter(
                  (cs: CleaningStatusExtendedWithIncludedModels) =>
                      activeFilters.includes(CleaningStatusEnum[cs.cleaningStatus]) ||
                      (cs.inspectionStatus !== null &&
                          activeFilters.includes(CleaningInspectionEnum[cs.inspectionStatus]))
              )
            : cleaningStatuses;

    const filteredRoomCleaningStatuses =
        role === 'maid'
            ? filteredRoomCleaningStatusesByActiveFilters.filter(
                  (cs: CleaningStatusExtendedWithIncludedModels) =>
                      cs.cleanedByUserUuid === userUuid || cs.cleanedByUserUuid2 === userUuid
              )
            : filteredRoomCleaningStatusesByActiveFilters;

    if (!presentDayCleaningStatusHeader) {
        return null;
    }
    return (
        <div>
            <PageTitle />

            <div className="row">
                <div className="col-md-12">
                    <div className="text-center mt-2 ">
                        <StatusFilterToolbar
                            selectedHeaderId={presentDayCleaningStatusHeader.id}
                            setChangeMaidPassword={setChangeMaidPassword}
                            activeFilters={activeFilters}
                            setActiveFilters={setActiveFilters}
                            changeMaidPassword={changeMaidPassword}
                            cleaningStatuses={cleaningStatuses}
                            {...props}
                        />
                    </div>

                    <div className=" mt-1 ">
                        {filteredRoomCleaningStatuses.map((cs) => {
                            return (
                                <div className="p-1 col-sm-12 col-md-6 col-xl-3 pull-left" key={cs.id}>
                                    <RoomInfoStatus
                                        validOnTimestamp={presentDayCleaningStatusHeader.validOnTimestamp}
                                        cleaningStatus={cs}
                                        showCleaningOptions={showCleaningOptions}
                                    />
                                </div>
                            );
                        })}
                    </div>
                </div>
            </div>
        </div>
    );
};

const StatusFilterToolbar = (props: StatusFilterToolbarProps) => {
    const {
        activeFilters,
        setActiveFilters,
        // setChangeMaidPassword,
        selectedHeaderId,
        // changeMaidPassword,
        cleaningStatuses,
    } = props;

    const filterButtonClickHandler = (cleaningStatusString: string) => {
        let _activeFilters = [...activeFilters];
        if (!_activeFilters.includes(cleaningStatusString)) {
            _activeFilters.push(cleaningStatusString);
        } else {
            _activeFilters = _activeFilters.filter((f) => f !== cleaningStatusString);
        }

        setActiveFilters(_activeFilters);
    };

    const openInNewTab = (url: string) => {
        const newWindow = window.open(url, '_blank', 'noopener,noreferrer');
        if (newWindow) newWindow.opener = null;
    };

    return (
        <div>
            <div className="btn-group" role="group" aria-label="Filter buttons">
                <button
                    key="clear"
                    onClick={() => {
                        setActiveFilters([]);
                    }}
                    type="button"
                    className="btn btn-white border"
                >
                    <i className="fa fa-filter" />
                </button>
                {(Object.keys(CleaningStatusEnum) as Array<string>).map((cleaningStatusString) => {
                    if (isNaN(Number(cleaningStatusString))) {
                        const info: number = cleaningStatuses.filter(
                            (cs) =>
                                cs.cleaningStatus ===
                                CleaningStatusEnum[cleaningStatusString as keyof typeof CleaningStatusEnum]
                        ).length;

                        const tileStyle = activeFilters.includes(cleaningStatusString)
                            ? CleaningStatusColors[cleaningStatusString as keyof typeof CleaningStatusColors]
                            : { color: '#6c757d', backgroundColor: '#f2f7f8' };
                        return (
                            <Tooltip
                                key={cleaningStatusString}
                                message={translate(
                                    CleaningStatusTexts[cleaningStatusString as keyof typeof CleaningStatusTexts]
                                )}
                                style={{ width: '140px' }}
                            >
                                <button
                                    style={tileStyle}
                                    onClick={() => {
                                        filterButtonClickHandler(cleaningStatusString);
                                    }}
                                    type="button"
                                    className="btn border"
                                >
                                    <i
                                        className={
                                            CleaningStatusIcons[
                                                cleaningStatusString as keyof typeof CleaningStatusIcons
                                            ]
                                        }
                                    />
                                    <span className="badge" style={tileStyle}>
                                        {info}
                                    </span>
                                </button>
                            </Tooltip>
                        );
                    } else {
                        return null;
                    }
                })}
                {(Object.keys(CleaningInspectionEnum) as Array<string>).map((inspectionStatusString) => {
                    if (isNaN(Number(inspectionStatusString))) {
                        if (inspectionStatusString === 'noInspectionTaken') {
                            return null;
                        }

                        const info: number = cleaningStatuses.filter(
                            (cs) =>
                                cs.inspectionStatus ===
                                CleaningInspectionEnum[inspectionStatusString as keyof typeof CleaningInspectionEnum]
                        ).length;
                        const tileStyle = activeFilters.includes(inspectionStatusString)
                            ? InspectionStatusColors[inspectionStatusString as keyof typeof InspectionStatusColors]
                            : { color: '#6c757d', backgroundColor: '#e1eff2' };
                        return (
                            <Tooltip
                                message={translate(inspectionStatusString)}
                                style={{ width: '160px' }}
                                key={inspectionStatusString}
                            >
                                <button
                                    style={tileStyle}
                                    onClick={() => {
                                        filterButtonClickHandler(inspectionStatusString);
                                    }}
                                    type="button"
                                    className="btn border"
                                >
                                    <i
                                        className={
                                            CleaningInspectionIcons[
                                                inspectionStatusString as keyof typeof CleaningInspectionIcons
                                            ]
                                        }
                                    />
                                    <span className="badge" style={tileStyle}>
                                        {info}
                                    </span>
                                </button>
                            </Tooltip>
                        );
                    } else {
                        return null;
                    }
                })}

                {checkRoleAccess('managerHousehold', 'managerFull', 'admin') ? (
                    <FeatureFlag flag="householdManagement">
                        <PermissionCheck name="household_schedule">
                            <button
                                className="btn btn-info"
                                onClick={() => {
                                    openInNewTab(
                                        window.location.origin + `/#/household/manage/printPreview/${selectedHeaderId}`
                                    );
                                }}
                            >
                                <i className="fa fa-print" />
                            </button>
                            <button
                                onClick={() => {
                                    props.history.push('/household/manage/planner');
                                }}
                                className="btn btn-sm btn-primary uppercase"
                            >
                                {translate('Manage')}
                            </button>
                        </PermissionCheck>
                    </FeatureFlag>
                ) : null}
                {/* {checkRoleAccess('maid') ? (
                    <React.Fragment>
                        <button
                            onClick={() => {
                                setChangeMaidPassword(!changeMaidPassword);
                            }}
                            className="btn btn-info ml-2"
                        >
                            <i className="fa fa-key" />
                        </button>
                    </React.Fragment>
                ) : null} */}
            </div>
        </div>
    );
};

export default branch(
    {
        cleaningStatusHeaders: ['monkeys', 'cleaningStatusHeadersWithCleaningStatuses'],
        loggedUser: ['authTokenInfo', 'user'],
    },
    Household
);
