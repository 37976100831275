import React from 'react';
//@ts-ignore
import _ from 'lodash';
import rest from '../../data/restWrapper';
import FormHeader from '../../components/formHeader';
import PageTitle from '../../components/pageTitle';
//@ts-ignore
import { toast } from 'react-toastify';
import { closeModal } from '@components/modal';
import { CompanyModel, CustomerModel, ReservationModel } from '@common/modelDefinition';
import translate from '@data/translations';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { DocumentScanner, CustomersListAfterScann } from './DocumentScanner';
import FormInputs from './formInputs';

interface CustomerFormProps {
    showPageTitle?: boolean;
    customHeader?: any;
    cancelHandler?: any;
    history?: any;
    swapCustomer?: any;
    customer?: CustomerModel;
    match?: any;
    customerId?: number;
    callback?: any;
    docScan: any[]; //napraviti docscanner model
    reservation: ReservationModel;
}

const CustomerForm = (props: CustomerFormProps) => {
    const {
        showPageTitle = false,
        customHeader = null,
        cancelHandler,
        history,
        reservation,
        //sanja dodala customer da može preko propsa. trebalo biti biti drugo ime, ali pravilo je problem u useEffect-u i sada se direktno seta u state ako je prosljeđeno tako.
        //callback = undefined, //optional callback handler
    } = props;

    var { swapCustomer = undefined } = props; //optional swap override for reservations swap

    const [customer, setCustomer] = React.useState(props.customer ? props.customer : ({ params: {} } as CustomerModel));
    const [company, setCompany] = React.useState(null as CompanyModel | null);
    const [loading, setLoading] = React.useState(false);
    const [conflictingCustomerId, setConflictingCustomerId] = React.useState(null); //by same document id
    const [showDocumentScannerData, setShowDocumentScannerData] = React.useState(false);
    const [customersAfterScan, setCustomersAfterScan] = React.useState({
        data: [],
        typeOfScan: 0,
        visaInfo: null,
        customer: {} as CustomerModel,
    });

    const customerId = props.customerId ? props.customerId : props.match?.params.customerId;

    //validation refs
    const firstNameRef = React.useRef(null);
    const lastNameRef = React.useRef(null);
    const emailRef = React.useRef(null);
    const documentIdRef = React.useRef(null);

    var checkIfDocumentIdIsUniqueDebouncer: any = null; //debouncer holder

    React.useEffect(() => {
        const loadFormData = async (cid: number) => {
            setLoading(true);
            let cmp = null;
            const c = await rest('/Customer/' + cid);
            if (c.params) {
                c.params = JSON.parse(c.params);
            } else {
                c.params = {};
            }
            if (c.companyId) {
                cmp = await rest('/Company/' + c.companyId);
            }
            setCustomer(c);
            setCompany(cmp);
            setConflictingCustomerId(null);
            setLoading(false);
            checkValidationInputs(c);
        };
        if (customerId) {
            loadFormData(customerId);
        }
        // eslint-disable-next-line
    }, [customerId]);

    React.useEffect(() => {
        const loadDocScann = async () => {};

        loadDocScann().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.docScan.length, showDocumentScannerData]);

    React.useEffect(() => {
        const loadData = async () => {
            if (customersAfterScan.data.length === 1 && customersAfterScan.typeOfScan === 1) {
                //nadjen gost po documentId-u
                setCustomer(customersAfterScan.data[0]);
                checkValidationInputs(customersAfterScan.data[0]);
            } else if (customersAfterScan.typeOfScan === 3) {
                //ne postoji gost znaci da je novi
                setCustomer(customersAfterScan.customer);
                checkValidationInputs(customersAfterScan.customer);
            }
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [customersAfterScan]);

    //// - override on props for custom swap logic....
    if (swapCustomer === undefined) {
        swapCustomer = async (newCustomerId: number, oldCustomerId: number) => {
            if (props.callback) {
                //fetch customer
                const newCustomer = await rest(`/Customer/${newCustomerId}`);
                props.callback(newCustomer);
            } else {
                if (history) {
                    history.push(`/customers/${newCustomerId}/edit`);
                }
            }
            closeModal();
        };
    }

    const handleInputChange = (e: any) => {
        const _customer: any = { ...customer };
        _customer[e.target.name] = e.target.value;

        if (e.target.name === 'documentId') {
            clearTimeout(checkIfDocumentIdIsUniqueDebouncer);
            checkIfDocumentIdIsUniqueDebouncer = setTimeout(() => {
                checkIfDocumentIdIsUnique();
            }, 1000);
        }

        setCustomer({ ..._customer }); //spread to rerender
        if (e.target.name === 'firstName' || e.target.name === 'lastName' || e.target.name === 'email') {
            checkValidationInputs(_customer);
        }
    };

    const handleLanguageChange = (e: any, type: any) => {
        const _customer: any = { ...customer };
        if(type.name === "preferredLanguageSelect"){
            _customer["preferredLanguage"] = e.value;
        }else{
            _customer["otherLanguage"] = e.value;
        }
        setCustomer({ ..._customer }); //spread to rerender
    };
    const checkIfDocumentIdIsUnique = async () => {
        if (customer.documentId) {
            const existingCustomersWitSameDocumentId = await rest('/checkIfCustomerDocumentIdIsUnique', 'POST', {
                documentId: customer.documentId,
            });

            if (existingCustomersWitSameDocumentId.length > 0) {
                //ako je edit customera te se rezultat ove funkcije poklapa sa editiranim customerom, ignoriraj
                const conflictingCId = existingCustomersWitSameDocumentId[0].id;
                if (conflictingCId !== Number(customerId)) {
                    setConflictingCustomerId(conflictingCId);
                } else {
                    setConflictingCustomerId(null);
                }
            } else {
                setConflictingCustomerId(null);
            }
        } else {
            setConflictingCustomerId(null);
        }
    };

    const checkValidationInputs = (objToValidate: any) => {
        let validationResults = getValidationInputs(objToValidate);
        markInvalidInputRefs(validationResults);
    };

    const getValidationInputs = (data: any) => {
        let invalidInputs = [];
        let validInputs = [];
        if (!data.firstName || data.firstName === '') {
            invalidInputs.push(firstNameRef);
        } else {
            validInputs.push(firstNameRef);
        }
        if (!data.lastName || data.lastName === '') {
            invalidInputs.push(lastNameRef);
        } else {
            validInputs.push(lastNameRef);
        }

        if (data.email && data.email !== null && data.email.trim() !== '') {
            /* eslint-disable-next-line */
            const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
            if (!re.test(String(data.email).toLowerCase())) {
                invalidInputs.push(emailRef);
            } else {
                validInputs.push(emailRef);
            }
        } else {
            validInputs.push(emailRef);
        }

        return { invalidInputs, validInputs };
    };

    const markInvalidInputRefs = (validationResults: any) => {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        invalidInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push(' is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        validInputs.forEach((input: any) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i: any) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    };

    const saveFormData = async () => {
        const validationRefs = getValidationInputs(customer);
        markInvalidInputRefs(validationRefs);
        await checkIfDocumentIdIsUnique();

        try {
            if (conflictingCustomerId === null && validationRefs.invalidInputs.length === 0) {
                //only if document id is unique....
                customer.params = JSON.stringify(customer.params);
                customer.companyId = company ? company.id : null;
                const res = await rest('/createOrUpdateCustomer', 'POST', customer);
                if(!customer.id){
                    toast(translate("Customer created!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
                }
                // await saveModelData('Customer', customer);
                if (props.callback) {
                    props.callback(res);
                } else {
                    if (history) {
                        history.push('/customers');
                    }
                }
                closeModal();
            }
        } catch (err: any) {
            console.warn(err);
            toast(err.message, {
                position: toast.POSITION.BOTTOM_RIGHT,
                type: toast.TYPE.ERROR,
            });
            closeModal();
        }
    };

    const cancelFormHandler = async () => {
        if (cancelHandler) {
            cancelHandler();
        }
        if (history) {
            history.push('/customers');
        }
        closeModal();
    };

    if (loading) {
        return translate('Loading ...');
    }

    const customerFormInputs = (
        <div>
            <FormInputs
                customer={customer}
                company={company}
                setCustomer={setCustomer}
                handleInputChange={handleInputChange}
                handleLanguageChange={handleLanguageChange}
                setCompany={setCompany}
                firstNameRef={firstNameRef}
                lastNameRef={lastNameRef}
                emailRef={emailRef}
                checkValidationInputs={checkValidationInputs}
            />

            <div className="text-center">
                <div onClick={saveFormData} className="btn btn-outline-primary pointer">
                    {translate('Save')}
                </div>
                <div onClick={cancelFormHandler} className="btn btn-outline-secondary ml-2 pointer">
                    {translate('Cancel')}
                </div>
            </div>
        </div>
    );

    if (customer.gdprDecoded === false) {
        return (
            <div className="container-fluid">
                {showPageTitle === undefined || showPageTitle === true ? <PageTitle /> : null}

                <div>
                    <button
                        onClick={() => {
                            history.goBack();
                        }}
                        className="btn btn-sm btn-secondary"
                    >
                        {translate('GO BACK')}
                    </button>
                </div>

                <div>User data encrypted due the GDPR compliance</div>
            </div>
        );
    }

    return (
        <div className="container-fluid">
            {showPageTitle === true ? <PageTitle /> : null}

            <div className="container bg-white">
                <div className="row justify-content-center">
                    <div className="col-md-10 pl-0 pr-0 ">
                        {customHeader ? (
                            customHeader
                        ) : (
                            <FormHeader>
                                {customerId === undefined ? translate('Add Customer') : translate('Edit customer')}
                            </FormHeader>
                        )}

                        <div className="mt-2 p-0">
                            <button
                                className="ml-3 btn btn-sm btn-info pointer"
                                onClick={(e) => {
                                    e.preventDefault();
                                    setShowDocumentScannerData(!showDocumentScannerData);
                                }}
                            >
                                <small>{translate('READ FROM SCANNER')}</small>
                            </button>
                        </div>

                        <div className="pt-4 pb-4 pr-2 pl-2 bg-white text-md-right">
                            {showDocumentScannerData ||
                            (customersAfterScan?.data?.length > 0 && customersAfterScan.typeOfScan === 2) ? null : (
                                <div className="form-group row justify-content-center align-items-center">
                                    <label className="col-md-4 col-form-label " htmlFor="">
                                        {translate('Personal document id')}
                                    </label>
                                    <div className="col-md-8">
                                        <input
                                            ref={documentIdRef}
                                            autoComplete="off"
                                            placeholder={translate('Personal document id')}
                                            name="documentId"
                                            value={customer.documentId ? customer.documentId : ''}
                                            onChange={handleInputChange}
                                            type="text"
                                            className="form-control"
                                            onBlur={checkIfDocumentIdIsUnique}
                                        />
                                    </div>
                                </div>
                            )}

                            {conflictingCustomerId ? (
                                <ConflictingCustomerAction
                                    swapCustomer={swapCustomer}
                                    customer={customer}
                                    conflictingCustomerId={conflictingCustomerId}
                                />
                            ) : showDocumentScannerData ? (
                                <DocumentScanner
                                    docScan={props.docScan}
                                    customer={null}
                                    reservation={reservation}
                                    setCustomersAfterScan={setCustomersAfterScan}
                                    setShowDocumentScannerData={setShowDocumentScannerData}
                                />
                            ) : customersAfterScan.data.length > 0 && customersAfterScan.typeOfScan === 2 ? (
                                <CustomersListAfterScann
                                    customersAfterScan={customersAfterScan}
                                    setCustomer={setCustomer}
                                    setCustomersAfterScan={setCustomersAfterScan}
                                />
                            ) : (
                                customerFormInputs
                            )}
                        </div>
                        {customersAfterScan?.data?.length > 0 && customersAfterScan.typeOfScan === 2 ? (
                            <div className="row justify-content-center">
                                <div
                                    className="btn btn-sm btn-primary p-1 m-2 pointer"
                                    onClick={() => {
                                        setCustomer(customersAfterScan.customer);
                                        setCustomersAfterScan({ ...customersAfterScan, data: [] });
                                    }}
                                >
                                    {translate('ADD NEW')}
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </div>
        </div>
    );
};

interface ConflictingCustomerActionProps {
    swapCustomer: any;
    customer: CustomerModel;
    conflictingCustomerId: number | null;
}

const ConflictingCustomerAction = (props: ConflictingCustomerActionProps) => {
    const { swapCustomer, customer, conflictingCustomerId } = props;
    return (
        <div className="text-left">
            U bazi već postoji gost sa ovim brojem dokumenta, molim da promjenite unos ili učitajte postojećeg gosta!
            {translate('customerDocumentIdConflictMessage')}
            <hr />
            {swapCustomer ? (
                <div>
                    <button
                        onClick={(e) => {
                            e.preventDefault();
                            swapCustomer(conflictingCustomerId, customer.id);
                        }}
                        className="btn btn-warning"
                    >
                        {translate('LOAD EXISTING DATA')}
                    </button>
                </div>
            ) : null}
        </div>
    );
};

export default branch(
    {
        docScan: ['model', 'DocumentScan'],
    },
    CustomerForm
);
