import React from 'react';
import FeatureFlag from '../../components/FeatureFlag';
import LocaleAndCurrency from './forms/localeAndCurrency';
import CompanyInfo from './forms/companyInfo';
import OtherSettings from './forms/otherSettings';
import IOTGatewayIntegration from './forms/IOTGatewayIntegration';
import SMTP from './forms/SMTP';
import FiscalPOS from './forms/fiscalPOS';
import FiscalGeneralSettings from './forms/fiscalGeneralSettings';
import FiscalCertificate from './forms/fiscalCertificate';
// import ENURegister from "./forms/ENURegister/ENURegister"
import { branch } from 'baobab-react/higher-order';
import PermissionCheck from '@components/PermissionCheck';

const NavLinksSettings = (props) => {
    const { settings } = props;
    const { fiscalGeneralSettings } = settings;

    return (
        <div className="mt-2">
            <div className=" pl-0 pr-0">
                <nav className="bg-light">
                    <div className="nav nav-tabs" id="nav-tab" role="tablist">
                            <a
                                className="nav-item nav-link active"
                                id="nav-home-tab"
                                data-toggle="tab"
                                href="#nav-home"
                                role="tab"
                                aria-controls="nav-home"
                                aria-selected="true"
                            >
                                {window.translate('Locale and currency')}
                            </a>

                        <PermissionCheck name="settings_companyInfo">
                            <a
                                className="nav-item nav-link"
                                id="nav-company-tab"
                                data-toggle="tab"
                                href="#nav-company"
                                role="tab"
                                aria-controls="nav-company"
                                aria-selected="false"
                            >
                                {window.translate('Company info')}
                            </a>
                        </PermissionCheck>
                        <PermissionCheck name="settings_otherSettings">
                            <a
                                className="nav-item nav-link"
                                id="nav-other-tab"
                                data-toggle="tab"
                                href="#nav-other"
                                role="tab"
                                aria-controls="nav-other"
                                aria-selected="false"
                            >
                                {window.translate('Other settings')}
                            </a>
                        </PermissionCheck>
                        <FeatureFlag flag="BMS">
                            <PermissionCheck name="settings_gatewayIntegration">
                                <a
                                    className="nav-item nav-link"
                                    id="nav-iotgateway-tab"
                                    data-toggle="tab"
                                    href="#nav-iotgateway"
                                    role="tab"
                                    aria-controls="nav-iotgateway"
                                    aria-selected="false"
                                >
                                    {window.translate('Gateway integration')}
                                </a>
                            </PermissionCheck>
                        </FeatureFlag>

                        <FeatureFlag flag="outgoingNotificationChannel">
                            <FeatureFlag flag="smtp">
                                <a
                                    className="nav-item nav-link"
                                    id="nav-smtp-tab"
                                    data-toggle="tab"
                                    href="#nav-smtp"
                                    role="tab"
                                    aria-controls="nav-smtp"
                                    aria-selected="false"
                                >
                                    SMTP
                                </a>
                            </FeatureFlag>
                        </FeatureFlag>
                        <PermissionCheck name="settings_fiscalSettings">
                            <a
                                className="nav-item nav-link"
                                id="nav-fiscalGeneralSettings-tab"
                                data-toggle="tab"
                                href="#nav-fiscalGeneralSettings"
                                role="tab"
                                aria-controls="nav-fiscalGeneralSettings"
                                aria-selected="false"
                            >
                                {window.translate('Fiscal General Settings')}
                            </a>
                        </PermissionCheck>
                        {fiscalGeneralSettings &&
                        fiscalGeneralSettings.country === 'BIH' &&
                        fiscalGeneralSettings.enableFiscalisation === true ? (
                            <PermissionCheck name="settings_fiscalSettings">
                                <a
                                    className="nav-item nav-link"
                                    id="nav-fiscal-tab"
                                    data-toggle="tab"
                                    href="#nav-fiscal"
                                    role="tab"
                                    aria-controls="nav-fiscal"
                                    aria-selected="false"
                                >
                                    {window.translate('Fiscal POS')}
                                </a>
                            </PermissionCheck>
                        ) : null}

                        {fiscalGeneralSettings &&
                        (fiscalGeneralSettings.country === 'CRO' || fiscalGeneralSettings.country === 'MNE') &&
                        fiscalGeneralSettings.enableFiscalisation === true ? (
                            <PermissionCheck name="settings_fiscalSettings">
                                <a
                                    className="nav-item nav-link"
                                    id="nav-fiscal-certificate-tab"
                                    data-toggle="tab"
                                    href="#nav-fiscal-certificate"
                                    role="tab"
                                    aria-controls="nav-fiscal-certificate"
                                    aria-selected="false"
                                >
                                    {window.translate('Fiscal certificate')}
                                </a>
                            </PermissionCheck>
                        ) : null}
                    </div>
                </nav>
            </div>

            <div className="tab-content " id="nav-tabContent">
                <div className="tab-pane fade show active" id="nav-home" role="tabpanel" aria-labelledby="nav-home-tab">
                    <LocaleAndCurrency {...settings} />
                </div>
                <div className="tab-pane fade" id="nav-company" role="tabpanel" aria-labelledby="nav-company-tab">
                    <CompanyInfo {...settings} />
                </div>

                <div className="tab-pane fade" id="nav-other" role="tabpanel" aria-labelledby="nav-other-tab">
                    <OtherSettings {...settings} />
                </div>

                <div className="tab-pane fade" id="nav-iotgateway" role="tabpanel" aria-labelledby="nav-iotgateway-tab">
                    <IOTGatewayIntegration {...settings} />
                </div>

                <FeatureFlag flag="outgoingNotificationChannel">
                    <FeatureFlag flag="smtp">
                        <div className="tab-pane fade" id="nav-smtp" role="tabpanel" aria-labelledby="nav-smtp-tab">
                            <SMTP {...settings} />
                        </div>
                    </FeatureFlag>
                </FeatureFlag>

                <div
                    className="tab-pane fade"
                    id="nav-fiscalGeneralSettings"
                    role="tabpanel"
                    aria-labelledby="nav-fiscal-tab"
                >
                    <FiscalGeneralSettings {...settings} />
                </div>

                <div className="tab-pane fade" id="nav-fiscal" role="tabpanel" aria-labelledby="nav-fiscal-tab">
                    <FiscalPOS {...settings} />
                </div>

                {/* <FeatureFlag flag="softFiscal"> */}
                <div
                    className="tab-pane fade"
                    id="nav-enu-register"
                    role="tabpanel"
                    aria-labelledby="nav-enu-register-tab"
                >
                    {/* <ENURegister {...settings} /> */}
                </div>
                <div
                    className="tab-pane fade"
                    id="nav-fiscal-certificate"
                    role="tabpanel"
                    aria-labelledby="nav-fiscal-certificate-tab"
                >
                    <FiscalCertificate {...settings} />
                </div>
            </div>
        </div>
    );
};
export default branch(
    {
        settings: ['state', 'generalSettings'],
    },
    NavLinksSettings
);
