export enum RoleName {
    admin = 'Admin',
    condoUser = 'Condo user',
    documentScanner = 'Document scanner',
    gdprOfficer = 'GDPR officer',
    hwAdmin = 'HW Admin',
    managerFull = 'Full manager',
    managerBussiness = 'Bussiness manager',
    managerHousehold = 'Household manager',
    managerSecurity = 'Security manager',
    receptionist = 'Receptionist',
    maid = 'Maid',
    janitor = 'Janitor',
    security = 'Security',
    reporting = 'Reporting',
    default = 'Default',
    sos = 'SOS',
}

export enum SystemMenuName {
    roomsPanel = 'Rooms and hardware',
    pricePanel = 'Price lists',
    accessControlPanel = 'Access control',
    foldersPanel = 'Data folders',
    advancedSettingsPanel = 'Advanced settings',
    licencePanel = 'Software updates and licence',
    roomsPanel_rooms = 'Rooms',
    roomsPanel_sosGroups = 'SOS groups',
    roomsPanel_controllers = 'Controlers',
    roomsPanel_posTerminal = 'POS terminal',
    roomsPanel_cashRegistar = 'Blagajna',
    roomsPanel_SRCTemplates = 'SRC templates',
    pricePanel_taxes = 'Taxes',
    pricePanel_accomodation = 'Accommodation',
    pricePanel_currencies = 'Currencies',
    pricePanel_pricelistLookup = 'Pricelist lookup',
    pricePanel_posGoods = 'POS goods',
    pricePanel_fiscalPrinters = 'Fiscal printers',
    foldersPanel_customers = 'Customers',
    foldersPanel_companies = 'Companies',
    foldersPanel_reservations = 'Reservations',
    advancedSettingsPanel_settings = 'Settings',
    advancedSettingsPanel_users = 'Users',
    advancedSettingsPanel_notificationServiceSettings = 'Notification service settings',
    advancedSettingsPanel_selfCheckInAdministration = 'Self check in administration',
    advancedSettingsPanel_visaInfo = 'Visa Info',
    licencePanel_licence = 'Licence',
    accessControlPanel_doorsAndRules = 'Doors & rules',
    accessControlPanel_whitelists = 'Whitelists',
    accessControlPanel_documentScannerDevices = 'Document Scanners',
    pricePanel_depositRegister = 'Deposit register',
}
export enum MainMenuName {
    dashboard = 'Dashboard',
    gatewayIntegration = 'Gateway integration',
    household = 'Household',
    situationCenter = 'Situation center',
    reports = 'Reports',
    systemMenu = 'System menu',
}

export enum ReportMenuName {
    reservationsPanel = 'Reservations',
    invoicesPanel = 'Invoices',
    roomEventsPanel = 'Room events',
    accessControlReportPanel = 'Access control report',
    reservationsPanel_general = 'Reservations report',
    reservationsPanel_customerReports = 'View customers data reports',
    reservationsPanel_selfCheckInReports = 'Self check in logs',
    invoicesPanel_general = 'Invoices report',
    invoicesPanel_financialReport = 'Financial report',
    invoicesPanel_cashRegistarsReport = 'Cash registars',
    invoicesPanel_cashRegistarPaymentsReport = 'Cash registar payments',
    roomEventsPanel_posTerminalReport = 'POS terminal report',
    roomEventsPanel_householdReport = 'Household report',
    roomEventsPanel_general = 'Room events',
    roomEventsPanel_SOSLog = 'SOS log',
    roomEventsPanel_minibarReport = 'Minibar report',
    accessControlReportPanel_systemAudit = 'System audit',
    accessControlReportPanel_general = 'Passage access control',
}

export enum ButtonsName {
    household_generateNewLog = 'Generate new log',
    roomInfo_extendedRoomSettings = 'Extended room settings',
    roomInfo_roomSettings = 'Room settings',
    roomRoomTicket_HWEventDismiss = 'HW MUTE ALARM',
    roomRoomTicket_SWEventDismiss = 'SW MUTE ALARM',
    household_schedule = 'Manage household',
    invoice_stornoInvoice = 'Storno invoice',
    minibar_addRemove = 'ADD/REMOVE ITEM',
    dashboard_reservation = 'Dashboard reservation timeline',
    dashboard_activity = 'Dashboard reservation activity',
    settings_fiscalSettings = 'Fiscal settings',
    settings_gatewayIntegration = 'GatewayIntegration settings',
    settings_otherSettings = 'Other settings',
    settings_companyInfo = 'Company info settings',
    basic_src_update_access_code_settings = 'Update SRC acccess codes'
}

export enum PermissionGroup {
    models = 'Models',
    systemMenu = 'System menu',
    mainMenu = 'Main menu',
    reportMenu = 'Report menu',
    buttons = 'Buttons',
    routes = 'Routes',
}
