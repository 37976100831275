import React from 'react';
import { branch } from 'baobab-react/higher-order';
import PageTitle from '../../components/pageTitle';
import rest from '../../data/restWrapper';
import { toast } from 'react-toastify';
import JSONPretty from 'react-json-pretty';

const forceBase64Request = true;

const defaultValidTo = Date.now() * 60 * 24 * 60 * 60 * 1000;

function RequestLicence(props) {

    const [showFinalStep, setShowFinalStep] = React.useState(false);
    const [licenceData, setLicenceData] = React.useState({
        version: props.codeVersion ? props.codeVersion.version : null,
        pack: {},
        usersByRoleCount: {
            admin: 1,
            hwAdmin: 0,
            managerFull: 0,
            managerBussiness: 0,
            managerHousehold: 0,
            managerSecurity: 0,
            receptionist: 1,
            maid: 0,
            janitor: 0,
            security: 0,
            reporting: 1,
            gdprOfficer: 0,
            condoUser: 0,
        },
        enableApiIntegration: false,
        showFinalStep: false,
        administrativeEmail: '',
        companyName: '',
        uniqueInstallationId: '',
        hwSupport: false,
        usersCount: 10,
        devicesCount: 20,
        roomsCount: 50,
        validTo: defaultValidTo, //60 days by default
    });

    React.useEffect(() => {
        const loadLicenceData = async () => {
            const licence = await rest('/licence');
            const companyInfoDb = await rest('/getGeneralSettingByKey/companyInfo');

            let companyInfo = {};
            try {
                companyInfo = JSON.parse(companyInfoDb.params);
            } catch (err) {}

            const tmp = { ...licenceData };
            tmp.uniqueInstallationId = licence.uniqueInstallationId;
            tmp.companyName = companyInfo.name ? companyInfo.name : '';
            tmp.administrativeEmail = companyInfo.contactEmail ? companyInfo.contactEmail : '';
            setLicenceData(tmp);
        };

        loadLicenceData();
        // eslint-disable-next-line
    }, []);

    const inputHandler = (e) => {
        const tmp = { ...licenceData };
        if (e.target.type === 'number') {
            tmp[e.target.name] = parseInt(e.target.value, 10);
        } else {
            tmp[e.target.name] = e.target.value;
        }
        setLicenceData(tmp);
    };

    const copyToClipboard = (str) => {
        const el = document.createElement('textarea');
        el.value = str;
        document.body.appendChild(el);
        el.select();
        document.execCommand('copy');
        document.body.removeChild(el);
    };

    const roleNames = Object.keys(licenceData.usersByRoleCount);

    if (showFinalStep) {
        delete licenceData.showFinalStep;

        const licenceClipboardSource =
            process.env.NODE_ENV === 'production' || forceBase64Request
                ? btoa(JSON.stringify(licenceData))
                : JSON.stringify(licenceData);

        const licenceDisplay =
            process.env.NODE_ENV === 'production' || forceBase64Request ? (
                <textarea style={{ width: '100%', height: '250px' }} defaultValue={btoa(JSON.stringify(licenceData))} />
            ) : (
                <JSONPretty id="json-pretty" data={licenceData}></JSONPretty>
            );

        return (
            <div className="container-fluid bg-light rounded pb-3">
                <b>{window.translate('Licence request data')}</b>

                <div className="bg-light rounded mt-2">{licenceDisplay}</div>

                <button onClick={copyToClipboard(licenceClipboardSource)} className="btn btn-info btn-sm mr-2">
                    <i className="fa fa-clipboard" /> {window.translate('COPY TO CLIPBOARD')}{' '}
                </button>

                <a
                    className="btn btn-info btn-sm"
                    href={`mailto:licence@mandeks.ba?cc=info@pimatico.com&subject=HOTEL LICENCE REQUEST ${licenceData.companyName.replace(
                        '&',
                        ''
                    )}&body=${licenceClipboardSource}`}
                >
                    {window.translate('SEND REQUEST')}
                </a>

                <div className="pt-3">
                    <b>
                        <br />
                    </b>
                </div>
            </div>
        );
    } else {
        return (
            <div className="container-fluid bg-light rounded pb-3">
                <PageTitle />

                <div className="container animated faster fadeIn">
                    {window.translate('Company name which will be used system wide (invoices, displays etc.):')}
                    <input
                        onChange={inputHandler}
                        name="companyName"
                        value={licenceData.companyName}
                        placeholder={window.translate('Company name')}
                        type="text"
                        className="form-control"
                    />

                    <div className=" mt-3 ">
                        {window.translate('Please enter email, where the licence should be sent:')}
                        <input
                            onChange={inputHandler}
                            name="administrativeEmail"
                            value={licenceData.administrativeEmail}
                            placeholder={window.translate('Administrative email')}
                            type="email"
                            className="form-control"
                        />
                    </div>

                    <div className="row mt-3">
                        <div className="col-md-3">
                            {window.translate('Number of rooms')}
                            <input
                                onChange={inputHandler}
                                value={licenceData.roomsCount}
                                name="roomsCount"
                                type="number"
                                min={0}
                                max={1999}
                                className="form-control"
                            />
                        </div>

                        <div className="col-md-3">
                            {window.translate('Include hardware licences')}
                            <select
                                value={licenceData.hwSupport}
                                onChange={(e) => {
                                    const tmp = { ...licenceData };
                                    tmp.hwSupport = e.target.value === 'true';
                                    setLicenceData(tmp);
                                }}
                                name="hwSupport"
                                className="form-control"
                            >
                                <option value={'false'}>{window.translate('DO NOT INCLUDE')}</option>
                                <option value={'true'}>{window.translate('INCLUDE HW SUPPORT')}</option>
                            </select>
                        </div>

                        {licenceData.hwSupport ? (
                            <div className="col-md-3">
                                {window.translate('Devices count (SRC/SOS/GATES)')}
                                <input
                                    className="form-control"
                                    onChange={(e) => {
                                        const tmp = { ...licenceData };
                                        if (e.target.value) {
                                            tmp.devicesCount = Number(e.target.value);
                                        } else {
                                            tmp.devicesCount = '';
                                        }
                                        setLicenceData(tmp);
                                    }}
                                    value={licenceData.devicesCount}
                                    type="number"
                                    min={0}
                                    max={9999}
                                    name="devicesCount"
                                />
                            </div>
                        ) : null}

                        <div className="col-md-3">
                            {window.translate('Enable API integration')}
                            <select
                                value={licenceData.enableApiIntegration}
                                onChange={(e) => {
                                    const tmp = { ...licenceData };
                                    tmp.enableApiIntegration = e.target.value === 'true';
                                    setLicenceData(tmp);
                                }}
                                name="hwSupport"
                                className="form-control"
                            >
                                <option value={'false'}>{window.translate('DISABLE')}</option>
                                <option value={'true'}>{window.translate('ENABLE')}</option>
                            </select>
                        </div>
                    </div>
                    <hr />
                    <div className=" mt-3">
                        <ul className="list-group">
                            {roleNames.map((roleName) => {
                                return (
                                    <li className="list-group-item" key={roleName}>
                                        <span style={{ width: '200px', display: 'inline-block' }}> {roleName}</span>
                                        <input
                                            onChange={(e) => {
                                                const tmp = { ...licenceData };
                                                tmp.usersByRoleCount[roleName] = e.target.value
                                                    ? Number(e.target.value)
                                                    : 0;
                                                setLicenceData(tmp);
                                            }}
                                            value={licenceData.usersByRoleCount[roleName]}
                                            type="number"
                                        />
                                    </li>
                                );
                            })}
                        </ul>
                    </div>

                    <hr />

                    <div className="text-center mt-5">
                        <button
                            onClick={(e) => {
                                if (licenceData.administrativeEmail === '' || licenceData.companyName === '') {
                                    toast('Enter all fields to continue!', {
                                        position: toast.POSITION.BOTTOM_RIGHT,
                                        type: toast.TYPE.WARNING,
                                    });
                                } else {
                                    setShowFinalStep(true);
                                }
                            }}
                            className="btn btn-primary"
                        >
                            {window.translate('NEXT STEP')}
                        </button>
                        <button
                            onClick={() => {
                                props.history.goBack();
                            }}
                            className="btn btn-secondary ml-2"
                        >
                            {window.translate('Cancel')}
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default branch(
    {
        codeVersion: ['version'],
    },
    RequestLicence
);