import React from 'react';
import PageTitle from '@components/pageTitle';
import { translate } from '@data/translations';
import { setModalContent } from '@components/modal';
import { getUncollectedBillsList, changeInvoicePaymentStatus } from './api';
import moment from 'moment';
import ConfirmModal from '@components/confirmModal';
import { toast } from 'react-toastify';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { PosTerminalModel } from '@common/modelDefinition';
import CashRegistarPayment from './paymentsForm';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';

interface UncollectedBillsProps {
    history: any;
    posTerminal: PosTerminalModel[];
}

const PaymentStatus: any = {
    0: translate('not paid'),
    1: translate('Paid'),
    2: translate('Partial payment'),
    false: translate('not paid'),
    true: translate('Paid'),
};

function UncollectedBills(props: UncollectedBillsProps) {
    const { history, posTerminal } = props;
    const [uncollectedBills, setUncollectedBills] = React.useState([] as any[]);
    const [searchTerm, setSearchTerm] = React.useState('');
    const [invoicesToSetPaymentStatus, setInvoicesToSetPaymentStatus] = React.useState([] as any[]);
    const [reload, setReload] = React.useState(false);

    React.useEffect(() => {
        const loadData = async () => {
            const _uncollectedBills: any = await getUncollectedBillsList();
            _uncollectedBills.sort((a: any, b: any) => (a.timestamp > b.timestamp ? 1 : -1));
            setUncollectedBills(_uncollectedBills);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [reload, posTerminal]);

    const filteredUnCollectedBills: any[] = [];

    uncollectedBills &&
        uncollectedBills.forEach((bill) => {
            if (bill.id.toString().includes(searchTerm)) {
                filteredUnCollectedBills.push(bill);
            }
        });

    return (
        <div>
            <PageTitle />
            <div className="row ">
                <div className="d-flex col-12">
                    <button
                        onClick={() => {
                            history.goBack();
                        }}
                        className="btn btn-sm btn-secondary "
                    >
                        {translate('GO BACK')}
                    </button>

                    <div className="ml-auto justify-content-end">
                        <input
                            type="text"
                            style={{ width: '300px' }}
                            className="form-control form-control-sm"
                            onChange={(e) => setSearchTerm(e.target.value)}
                            placeholder={translate('Search by invoice id')}
                        ></input>
                    </div>
                </div>
            </div>

            <ul className="list-group mt-3 mx-3">
                <small>
                    <li className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                        <div className="col-6 col-sm-1  elipsis">{translate('Inv. id')}</div>
                        <div className="col-6 col-sm-2 elipsis">{translate('Date')}</div>
                        <div className="col-6 col-sm-1 elipsis">{translate('Type')}</div>
                        <div className="col-6 col-sm-2">{translate('Total value')}</div>
                        <div className="col-6 col-sm-2">{translate('Terminal')}</div>
                        <div className="col-6 col-sm-1">{translate('Guest/Res')}</div>
                        <div className="col-6 col-sm-1">{translate('Payment status')}</div>
                        <div className="col-6 col-sm-1 text-center">{translate('Partial payment')}</div>
                        <div className="col-6 col-sm-1 text-center">
                            <small>{translate('Check to change status')}</small>
                        </div>
                    </li>
                </small>
            </ul>

            <ul className="list-group mt-2 mx-3">
                {filteredUnCollectedBills.map((bill: any, key: number) => {
                    const terminal =
                        posTerminal && bill.posTerminalId
                            ? posTerminal.find((t: PosTerminalModel) => t.id === Number(bill.posTerminalId))
                            : null;
                    const customerOrCompany = bill.Customer
                        ? bill.Customer.firstName + ' ' + bill.Customer.lastName
                        : bill.Company
                        ? bill.Company.name
                        : '';

                    return (
                        <li key={key} className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                            <div className="col-4 col-sm-1  elipsis">{bill.id}</div>
                            <div className="col-4 col-sm-2 elipsis">{moment(bill.timestamp).format('YYYY/MM/DD')}</div>
                            <div className="col-4 col-sm-1 elipsis">{bill.typeOfPayment}</div>
                            <div className="col-4 col-sm-2 elipsis">
                                {bill.totalValue ? parseFloat(bill.totalValue).toFixed(2) : ''}
                            </div>
                            <div className="col-4 col-sm-2 elipsis">{terminal ? terminal.name : ''}</div>
                            <div className="col-4 col-sm-1 elipsis">
                                {customerOrCompany}
                                <br />
                                <small className="text-dark">
                                    {bill.reservationId ? bill.reservationId : bill.reservationGroupUuid}
                                </small>
                            </div>
                            <div
                                className={
                                    'col-4 col-sm-1  ' +
                                    (bill.paymentStatus === 1 || bill.paymentStatus === true
                                        ? 'text-success'
                                        : 'text-danger')
                                }
                            >
                                {PaymentStatus[bill.paymentStatus]}{' '}
                                {bill.paymentStatus === 2 ? (
                                    <small className="text-dark">({parseFloat(bill.receivedPayment).toFixed(2)})</small>
                                ) : null}
                            </div>
                            <div className="col-6 col-sm-1 text-center pointer">
                                <div
                                    className="btn btn-sm btn-outline-secondary"
                                    onClick={() => {
                                        setModalContent(
                                            <CashRegistarPayment
                                                cashRegistarId={null}
                                                setReload={setReload}
                                                reload={reload}
                                                history={history}
                                                invoice={bill}
                                            />,
                                            translate('New payment'),
                                            false,
                                            'modal-lg'
                                        );
                                    }}
                                >
                                    {translate('PAY')}
                                </div>
                            </div>
                            <div className="col-6 col-sm-1 text-center pointer">
                                <i
                                    onClick={() => {
                                        let inv = [...invoicesToSetPaymentStatus];
                                        if (inv.includes(bill.id)) {
                                            inv = inv.filter((i: any) => i !== bill.id);
                                        } else {
                                            inv.push(bill.id);
                                        }
                                        setInvoicesToSetPaymentStatus([...inv]);
                                    }}
                                    className={
                                        invoicesToSetPaymentStatus.includes(bill.id)
                                            ? 'fa fa-check-square-o fa-2x'
                                            : 'fa fa-square-o fa-2x'
                                    }
                                ></i>{' '}
                            </div>
                        </li>
                    );
                })}
            </ul>
            {invoicesToSetPaymentStatus.length > 0 ? (
                <div className="row  mt-3">
                    <div
                        className="btn btn-sm m-auto btn-info pointer"
                        onClick={() =>
                            setModalContent(
                                <ConfirmModal
                                    actionForYes={async () => {
                                        try {
                                            startGlobalLoading();
                                            await changeInvoicePaymentStatus(invoicesToSetPaymentStatus);
                                            setReload(!reload);
                                            setInvoicesToSetPaymentStatus([]);
                                            stopGlobalLoading();
                                            toast('Payments created!', {
                                                position: toast.POSITION.BOTTOM_RIGHT,
                                                type: toast.TYPE.SUCCESS,
                                            });
                                        } catch (err: any) {
                                            setInvoicesToSetPaymentStatus([]);
                                            toast(err.message, {
                                                position: toast.POSITION.BOTTOM_RIGHT,
                                                type: toast.TYPE.ERROR,
                                            });
                                            console.warn(err);
                                        }
                                    }}
                                    content={translate(
                                        'Are you sure you want to set payment status of selected invoices to PAID and create payments?'
                                    )}
                                />,
                                translate('Confirm'),
                                false,
                                'modal-md'
                            )
                        }
                    >
                        {translate('Change payment status')}
                    </div>
                </div>
            ) : null}
        </div>
    );
}

export default branch(
    {
        posTerminal: ['model', 'PosTerminal'],
    },
    UncollectedBills
);
