import * as React from 'react';
import { translate } from '@data/translations';
import { ReservationController, ValidationParams } from '../resController';
import { CustomerModel, CompanyModel, ReservationModel } from '@common/modelDefinition';
import ActionsToolbar from './ActionsToolbar';
import { CustomersAndCompanies } from './CustomersAndCompanies';
import RoomReservationsTimeline from './RoomReservationsTimeline';
import ReservationRoomAttributes from './ReservationRoomAttributes';
import MainReservationInfo from './MainReservationInfo';
import OverlappingReservations from './OverlappingReservations';
import AccommodationPriceOffer from './AccommodationPriceOffer';
import ReservationValidationMessageList from './ReservationValidationMessageList';
import MiniLoader from '@components/loaders/miniLoader';
import ReservationGuestReference from './ReservationGuestReference';
import MinibarConsumption from './MinibarConsumption';
import PosTerminalConsumption from './PosTerminalConsumption';
import SelfCheckInContainer from './SelfCheckInContainer';
import { ReservationStatus } from '@common/modelDefinition';
import CondoOwnerRestrictionWrapper from './CondoOwnerRestrictionWrapper';
import RouteLeavingGuard from './RouteLeavingGuard';
import ReservationNotifications from './ReservationNotifications';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { getUrlParameterByName } from '@data/utils';
import LoadingButton from '@components/buttons/loadingButton';

interface TabsBodyProps {
    history: any;
    isPending: boolean;
}

const reservationValidationParams: ValidationParams = {
    checkInTimestamp: true,
    accommodationPriceListIdOrOpenPrice: true,
    roomInfoId: true,
    groupReservationHolder: true,
    reservationHolder: true,
};

function TabsBody(props: TabsBodyProps) {
    const { history, isPending } = props;
    const ctrl = new ReservationController();

    const reservation = ctrl.getActiveReservation();
    const uuid = getUrlParameterByName('uuid');
    const roomIds = getUrlParameterByName('roomIds');

    if (!reservation || !reservation.RoomInfo) {
        return <MiniLoader />;
    }

    return (
        <>
            <CondoOwnerRestrictionWrapper history={history} reservation={reservation}>
                <div className="unselectable border-left border-right border-bottom rounded-sm shadow-sm bg-white p-2">
                    <RouteLeavingGuard history={history} />

                    <div className="bg-white pt-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <ActionsToolbar history={history} reservation={reservation} />
                        <ReservationRoomAttributes room={reservation.RoomInfo} />
                    </div>

                    <div className="bg-white pb-1">
                        {reservation.customerId === null && reservation.companyId === null ? (
                            <CustomersAndCompanies
                                company={reservation.Company}
                                customer={reservation.Customer}
                                history={history}
                                reservation={reservation}
                                //hideOnSelected={false}
                                customerSelect={(c: CustomerModel) => {
                                    const res = { ...reservation };
                                    res.Customer = c;
                                    res.customerId = c.id;
                                    ctrl.updateActiveReservation(res as ReservationModel);
                                }}
                                companySelect={(c: CompanyModel) => {
                                    const res = { ...reservation };
                                    res.Company = c;
                                    res.companyId = c.id;
                                    ctrl.updateActiveReservation(res as ReservationModel);
                                }}
                            />
                        ) : (
                            <RoomReservationsTimeline reservation={reservation} />
                        )}
                    </div>

                    <MainReservationInfo history={history} reservation={reservation} />

                    <AccommodationPriceOffer history={history} room={reservation.RoomInfo} reservation={reservation} />
                    <ReservationGuestReference reservation={reservation} history={history} />
                    <MinibarConsumption reservation={reservation} history={history} />

                    {!reservation.id || reservation.statusEnum !== ReservationStatus.checkedIn ? null : (
                        <PosTerminalConsumption reservation={reservation} history={history} />
                    )}

                    <SelfCheckInContainer />
                    {!reservation.id ? null : <ReservationNotifications reservation={reservation} history={history} />}
                </div>

                <div className="mt-3 mb-3">
                    <ReservationValidationMessageList validationParams={reservationValidationParams}>
                        <div className="text-center ">
                            <LoadingButton
                                className="btn btn-primary mr-2"
                                disabled={!isPending}
                                onClick={async () => {
                                    const redirectTo = await ctrl.save(
                                        uuid || roomIds || reservation.groupUuid ? true : false //isGroupReservation
                                    );
                                    if (redirectTo) {
                                        history.push(redirectTo);
                                    }
                                }}
                            >
                                {reservation.id ? translate('Update') : translate('Save')}
                            </LoadingButton>
                        </div>
                    </ReservationValidationMessageList>
                    <OverlappingReservations reservation={reservation} />
                </div>
            </CondoOwnerRestrictionWrapper>
        </>
    );
}
export default branch(
    {
        reservations: ['state', 'reservationForm2', 'reservations'],
        isPending: ['state', 'reservationForm2', 'isPending'],
    },
    TabsBody
);
