import React from 'react';
import { translate } from '@data/translations';
import { toast } from 'react-toastify';
import { createOrEditCashRegistar } from './parts/api';
import { CashRegistarModel, CurrencyModel, PosTerminalModel } from '@common/modelDefinition';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { closeModal } from '@components/modal';

interface CashRegistarsFormProps {
    history: any;
    cashRegistar: CashRegistarModel | null;
    currency: CurrencyModel[];
    posTerminal: PosTerminalModel[];
    cashRegistars: CashRegistarModel[];
}

function CashRegistarsForm(props: CashRegistarsFormProps) {
    const [_cashRegistar, setCashRegistar] = React.useState(props.cashRegistar);
    const [takenTerminals, setTakenTerminals] = React.useState([] as number[]);

    React.useEffect(() => {
        const loadData = async () => {
            setCashRegistar(props.cashRegistar);
            const _takenTerminals: number[] = [];
            props.cashRegistars.forEach(
                (r: CashRegistarModel) => r.posTerminalId && _takenTerminals.push(r.posTerminalId)
            );
            setTakenTerminals(_takenTerminals);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [props.cashRegistar]);

    const handleInputChange = (e: any) => {
        const cashReg: any = { ..._cashRegistar };
        if (e.target.name === 'posTerminalId' || e.target.name === 'cashMax') {
            cashReg[e.target.name] = Number(e.target.value);
        } else {
            cashReg[e.target.name] = e.target.value;
        }
        setCashRegistar(cashReg);
    };

    const saveFormData = async () => {
        try {
            if (_cashRegistar && _cashRegistar.name && _cashRegistar.currency) {
                await createOrEditCashRegistar(_cashRegistar);
                toast(_cashRegistar.id ? 'Cash registar edited' : 'Cash registar created', {
                    position: toast.POSITION.BOTTOM_RIGHT,
                    type: toast.TYPE.INFO,
                });
            }
        } catch (err: any) {
            toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
        }
        closeModal();
        setCashRegistar(null);
    };

    const currencyOptions =
        props.currency &&
        props.currency.map((c: any) => {
            return (
                <option value={c['shortName']} key={c['id']}>
                    {c.name}
                </option>
            );
        });

    const posTerminalOptions =
        props.posTerminal &&
        props.posTerminal.map((t: any) => {
            return (
                <option disabled={takenTerminals.includes(t.id)} value={t['id']} key={t['id']}>
                    {t.name}
                </option>
            );
        });

    return (
        <div>
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    * {translate('Name')}:
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        placeholder={translate('Name')}
                        name="name"
                        value={_cashRegistar?.name ? translate(_cashRegistar.name) : ''}
                        onChange={(e) => {
                            handleInputChange(e);
                        }}
                        disabled={_cashRegistar?.name==="virman_cashRegistar" ? true : false}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    * {translate('Currency')}:
                </label>
                <div className="col-md-8">
                    <select
                        name="currency"
                        className="form-control"
                        value={_cashRegistar?.currency ? _cashRegistar.currency : ''}
                        onChange={(e) => {
                            handleInputChange(e);
                        }}
                    >
                        <option value={'no select'}>{translate('Nothing selected')}</option>
                        {currencyOptions}
                    </select>
                </div>
            </div>
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Pos terminal')}:
                </label>
                <div className="col-md-8">
                    <select
                        name="posTerminalId"
                        className="form-control"
                        value={_cashRegistar?.posTerminalId ? _cashRegistar.posTerminalId : ''}
                        onChange={(e) => {
                            handleInputChange(e);
                        }}
                        disabled={_cashRegistar?.name==="virman_cashRegistar" ? true : false}
                    >
                        <option value={'no select'}>{translate('Nothing selected')}</option>
                        {posTerminalOptions}
                    </select>
                </div>
            </div>
            <div className="form-group row justify-content-center align-items-center">
                <label className="col-md-4 col-form-label" htmlFor="">
                    {translate('Cash maximum')}:
                </label>
                <div className="col-md-8">
                <input
                    autoComplete="off"
                    onChange={(e) => {
                        handleInputChange(e);
                    }}
                    type="number"
                    name="cashMax"
                    placeholder={translate('Cash maximum')}
                    value={_cashRegistar?.cashMax ? _cashRegistar.cashMax : ''}
                    className="form-control"
                />
                </div>
            </div>

            <div className="text-center">
                {_cashRegistar?.name && _cashRegistar?.currency && _cashRegistar.currency !== 'no select' ? (
                    <div
                        onClick={async () => {
                            await saveFormData();
                        }}
                        className="btn btn-outline-primary pointer"
                    >
                        {translate('Save')}
                    </div>
                ) : null}

                <div onClick={() => closeModal()} className="btn btn-outline-secondary ml-2 pointer">
                    {translate('Cancel')}
                </div>
            </div>
        </div>
    );
}

export default branch(
    {
        posTerminal: ['model', 'PosTerminal'],
    },
    CashRegistarsForm
);
