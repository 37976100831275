import React, { Component } from 'react';
//import _ from "lodash"
import rest from '../../data/restWrapper';
import { branch } from 'baobab-react/higher-order';

async function registerUser(email, password) {
    return await rest('/createUser', 'POST', { email, password });
}

class Landing extends Component {
    constructor(props) {
        super(props);
        this.state = {
            validationOrErrorNote: '',
            inputsReady: false,
            formData: {
                email: '',
                password: '',
                passwordRepeat: '',
            },
        };
    }
    onChangeHandler(e) {
        let formData = this.state.formData;
        formData[e.target.name] = e.target.value;
        this.setState({ formData });
    }
    validateEmail() {
        let isValid = true;
        // eslint-disable-next-line
        var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!re.test(this.state.formData.email)) {
            isValid = false;
            this.setState({
                validationOrErrorNote: 'Email not valid.',
                //inputsReady: false
            });
        }
        return isValid;
    }
    validatePasswords() {
        let isValid = true;
        if (this.state.formData.password !== this.state.formData.passwordRepeat) {
            isValid = false;
            this.setState({
                validationOrErrorNote: 'Password input are not matched.',
                //inputsReady: false
            });
        } else if (this.state.formData.password.length < 6) {
            isValid = false;
            this.setState({
                validationOrErrorNote: 'Password must be at least 6 characters long.',
                //inputsReady: false
            });
        }
        return isValid;
    }
    async submitForm(e) {
        e.preventDefault();
        if (this.validatePasswords() && this.validateEmail()) {
            try {
                /*let reporter =*/ await registerUser(this.state.formData.email, this.state.formData.password);
                window.location = '/';
            } catch (err) {
                this.setState({ validationOrErrorNote: err.message });
            }
        }
    }
    render() {
        return (
            <div className="keys-background text-center" style={{ top: '0px' }}>
                <div
                    className="bg-light rounded  p-4"
                    style={{ maxWidth: '30rem', margin: '6rem auto', opacity: '0.9' }}
                >
                    <h1 className="text-center">{window.translate("REGISTRATION")}</h1>
                    <form onSubmit={this.submitForm.bind(this)}>
                        <input type="hidden" value="chrome autocomplete hack" />
                        <div className="form-group">
                            <input
                                value={this.state.formData.email}
                                type="email"
                                autoComplete="off"
                                name="email"
                                placeholder={window.translate("Email")}
                                className="form-control"
                                onChange={this.onChangeHandler.bind(this)}
                                onBlur={this.validateEmail.bind(this)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                value={this.state.formData.password}
                                type="password"
                                autoComplete="off"
                                name="password"
                                placeholder={window.translate("Password")}
                                className="form-control"
                                onChange={this.onChangeHandler.bind(this)}
                            />
                        </div>
                        <div className="form-group">
                            <input
                                value={this.state.formData.passwordRepeat}
                                type="password"
                                autoComplete="off"
                                name="passwordRepeat"
                                placeholder={window.translate("Repeat password")}
                                className="form-control"
                                onChange={this.onChangeHandler.bind(this)}
                                onBlur={this.validatePasswords.bind(this)}
                            />
                        </div>
                        <div className="m-t-1 text-center">
                            <div onClick={this.submitForm.bind(this)} className="btn btn-primary mr-2 pointer">
                                {window.translate("Register")}
                            </div>
                            <div
                                onClick={() => {
                                    if (this.props.onCancel) {
                                        this.props.onCancel();
                                    }
                                }}
                                className="btn btn-primary pointer"
                            >
                                {window.translate("Cancel")}
                            </div>
                        </div>
                        {this.state.validationOrErrorNote !== '' ? (
                            <div className="alert alert-danger mt-2" role="alert">
                                {this.state.validationOrErrorNote}
                            </div>
                        ) : null}
                    </form>
                </div>
            </div>
        );
    }
}

export default branch({}, Landing);
