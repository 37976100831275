import React from 'react';
import { handleChange, saveFormData } from '../settingsActions';

const FiscalGeneralSettings = (settings) => {
    const { fiscalGeneralSettings } = settings;
    const countryOptions = ['CRO', 'BIH', 'MNE'].map((c) => {
        return (
            <option value={c} key={c}>
                {c}
            </option>
        );
    });
    countryOptions.unshift(
        <option key="none" value="alpha-3">
            {''}-{' '}
        </option>
    );

    return (
        <div className=" bg-white p-3 ">
            <b>{window.translate('FISCAL GENERAL SETTINGS')}</b>
            <hr />

            <div className="row mt-2 align-items-center">
                <div className="col-md-4">
                    {' '}
                    <label htmlFor="enableFiscalisation">{window.translate('Enable fiscalisation')}:</label>{' '}
                </div>
                <div className="col-md-2">
                    <select
                        name="enableFiscalisation"
                        value={fiscalGeneralSettings?.enableFiscalisation}
                        onChange={handleChange.bind(this, 'fiscalGeneralSettings', 'boolean')}
                        className="custom-select my-1 mr-sm-2 "
                        id="enableFiscalisation"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        <option value={true}>{window.translate('Yes')}</option>
                        <option value={false}>{window.translate('No')}</option>
                    </select>
                </div>
            </div>

            {fiscalGeneralSettings?.enableFiscalisation ? (
                <div className="row mt-2 align-items-center">
                    <div className="col-md-4">
                        {' '}
                        <label htmlFor="country">{window.translate('Country')}:</label>{' '}
                    </div>
                    <div className="col-md-2">
                        <select
                            name="country"
                            className="form-control"
                            value={fiscalGeneralSettings?.country ? fiscalGeneralSettings?.country : ''}
                            onChange={handleChange.bind(this, 'fiscalGeneralSettings', 'object')}
                        >
                            {countryOptions}
                        </select>
                    </div>
                </div>
            ) : null}
      
                
    
            <div className="text-center p-5">
                <div
                    onClick={() => saveFormData('fiscalGeneralSettings', fiscalGeneralSettings)}
                    className="btn btn-outline-primary pointer"
                >
                    {window.translate('Save')}
                </div>
            </div>
        </div>
    );
};

export default FiscalGeneralSettings;
