import React, { Component } from 'react';
import { saveModelData } from '../../data/actions/modelActions';
import _ from 'lodash';
import { closeModal } from '@components/modal';

class DAPForm extends Component {
    constructor() {
        super();
        this.name = React.createRef();
        this.state = {
            name: '',
            description: '',
        };
    }
    componentDidMount() {
        let name = this.props.ap.name;
        let description = this.props.ap.description;
        this.setState({ name, description });
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.ap.id !== this.props.ap.id) {
            let name = this.props.ap.name;
            let description = this.props.ap.description;
            this.setState({ name, description });
        }
    }

    handleInputChange(e) {
        this.setState({ [e.target.name]: e.target.value });
    }

    clearValidationRefs() {
        if (this.name && this.name.current) {
            this.name.current.className = this.name.current.className.replace('is-invalid', '');
        }
    }

    markInvalidInputRefs(validationResults) {
        //generic
        const { invalidInputs, validInputs } = validationResults;
        _.forEach(invalidInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (!_.includes(classes, 'is-invalid')) {
                    classes.push('is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
        _.forEach(validInputs, (input) => {
            if (input && input.current) {
                let classes = input.current.className.split(' ');
                if (_.includes(classes, 'is-invalid')) {
                    _.remove(classes, (i) => i === 'is-invalid');
                }
                input.current.className = classes.join(' ');
            }
        });
    }
    getValidationInputs(data) {
        let invalidInputs = [];
        let validInputs = [];
        // this.clearValidationRefs();
        if (data.name === null || data.name.trim() === '') {
            invalidInputs.push(this.name);
        } else {
            validInputs.push(this.name);
        }
        return { invalidInputs, validInputs };
    }

    handleSubmit() {
        let payload = {
            id: this.props.ap.id,
            name: this.state.name,
            description: this.state.description,
        };
        let validationResults = this.getValidationInputs(payload);
        this.markInvalidInputRefs(validationResults);
        if (validationResults.invalidInputs.length === 0) {
            saveModelData('DoorAccessPoint', payload).catch((err) => {
                console.warn(err);
            });
            this.clearValidationRefs();
            closeModal();
        }
    }

    render() {
        return (
            <div>
                <div className="form-group">
                    <label htmlFor="APName">{window.translate('Access point name')}</label>
                    <input
                        ref={this.name}
                        id="APName"
                        autoComplete="off"
                        name="name"
                        value={this.state.name ? this.state.name : ''}
                        onChange={this.handleInputChange.bind(this)}
                        type="text"
                        className="form-control"
                    />
                </div>

                <div className="form-group">
                    <label htmlFor="description">{window.translate('Description')}</label>
                    <textarea
                        id="description"
                        autoComplete="off"
                        name="description"
                        value={this.state.description ? this.state.description : ''}
                        onChange={this.handleInputChange.bind(this)}
                        className="form-control"
                    ></textarea>
                </div>

                <button onClick={closeModal.bind(this)} className="btn btn-sm btn-outline-secondary pull-right">
                    {window.translate('Cancel')}
                </button>
                <button
                    onClick={this.handleSubmit.bind(this)}
                    className="btn btn-sm btn-outline-primary pull-right mr-1"
                >
                    {window.translate('Save')}
                </button>
            </div>
        );
    }
}

export default DAPForm;
