import React from 'react';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';

const invoicedStatusEnum = {
    0: 'not paid',
    1: 'fully paid',
    2: 'Partial payment',
};

const GroupHolderResResults = (props) => {
    const { customers, companies, groupReservations, history } = props;

    if (groupReservations.length === 0) {
        return null;
    }

    return (
        <div>
            <h5>{window.translate('Group reservations holder')}</h5>

            <ul className="list-group mt-3 mx-3">
                <li className="px-1 py-1 row text-white small navbar-dark navbar-gradient">
                    <div className="col-2">{window.translate('Group uuid')}</div>
                    <div className="col-2">{window.translate('Check in')}</div>
                    <div className="col-2">{window.translate('Check out')}</div>
                    <div className="col-2">{window.translate('Holder')}</div>
                    <div className="col-2">{window.translate('Group status')}</div>
                    <div className="col-2">{window.translate('Payment status')}</div>
                </li>
                {_.orderBy(groupReservations, 'createdAt', 'desc').map((r, index) => {
                    const holderCompany = r.companyId ? companies.find((c) => c.id === r.companyId) : null;
                    const holderCustomer = r.customerId ? customers.find((c) => c.id === r.customerId) : null;

                    return (
                        <li
                            onClick={() => {
                                history.push(`reservations/${r.resId}/edit?uuid=${r.uuid}`);
                            }}
                            className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}
                            key={index}
                        >
                            <div className="col-2">{r.uuid}</div>

                            <div className="col-2 elipsis">{moment(r.checkInTimestamp).format('DD.MM.YYYY')}</div>
                            <div className="col-2 elipsis">{moment(r.checkOutTimestamp).format('DD.MM.YYYY')}</div>
                            <div className="col-2 elipsis">
                                {holderCustomer
                                    ? holderCustomer.firstName + ' ' + holderCustomer.lastName
                                    : holderCompany
                                    ? holderCompany.name
                                    : ''}
                            </div>
                            <div className={`col-2 elipsis ` + (r.status === 1 ? 'text-success' : 'text-danger')}>
                                {r.status === 1 ? window.translate('Active') : window.translate('Closed')}
                            </div>
                            <div className={`col-2 elipsis `}>{invoicedStatusEnum[r.invoicedStatus]}</div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default GroupHolderResResults;
