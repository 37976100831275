import * as React from 'react';
import ReservationsTimeline from '@pages/dashboard/reservationsTimeline';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { Reservation } from '../resController';
import MiniLoader from '@components/loaders/miniLoader';

interface RoomReservationsTimelineProps {
    reservation: Reservation;
    reservations: Reservation[];
}

function RoomReservationsTimeline(props: RoomReservationsTimelineProps) {
    const { reservation, reservations = [] } = props;
    const { roomInfoId } = reservation;
    const [checkInTimestampTimeline, setCheckInTimestampTimeline] = React.useState(reservation.checkInTimestamp);

    //nisam sigurna za ovo treba li
    React.useEffect(() => {
        const loadData = async () => {
            if (checkInTimestampTimeline !== reservation.checkInTimestamp) {
                (window as any).dispatchEvent(new Event('timelineScrollToNow'));
            }
            setCheckInTimestampTimeline(reservation.checkInTimestamp);
        };
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [reservation.checkInTimestamp]);

    const roomReservations = reservations.filter((r) => r.roomInfoId === roomInfoId);
    const { checkInTimestamp, checkOutTimestamp } = reservation;
    const nowMarker = checkInTimestamp ? new Date(checkInTimestamp) : null;

    if (!reservation.RoomInfo) {
        return <MiniLoader />;
    }

    const ghostTrack = {
        start: new Date(checkInTimestamp),
        end: new Date(checkOutTimestamp),
        title: '',
    };

    return (
            <ReservationsTimeline
                style={{}} //empty on purpose
                ghostTrack={ghostTrack} //showMarker={false}
                rooms={[reservation.RoomInfo]}
                reservations={roomReservations}
                nowMarker={nowMarker}
                basic={true}
                showLegend={false}
                zoom={30}
            />
    );
}

export default React.memo(
    branch(
        {
            reservations: ['model', 'Reservation'],
        },
        RoomReservationsTimeline
    )
);
