import React from 'react';

import PosTerminalAdmin from '../../posTerminal/admin/admin';
import baobab from '../../../data/state';
import { PosTerminalModel } from '@common/modelDefinition';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';

var timer: any | null = null;

interface SelectTerminalWrapper {
    posTerminals: PosTerminalModel[];
    selectedPosTerminalId: number | null;
	children:any
}

function selectPosTerminal(posTerminal: PosTerminalModel) {
    baobab.root.select(['state', 'selectedPosTerminalId']).set(posTerminal.id);
}

export enum TerminalType {
    checkoutTerminal,
    posTerminal,
}

export function SelectPosTerminalWrapper(props: SelectTerminalWrapper) {
    const { posTerminals = [], selectedPosTerminalId } = props;
    const [delay, setDelay] = React.useState<number>(5000);

    const checkoutPosTerminals: PosTerminalModel[] = posTerminals.filter(
        (p: PosTerminalModel) => p.terminalType === TerminalType.checkoutTerminal
    );

    clearTimeout(timer);
    timer = setTimeout(() => {
        setDelay(delay - 1000);
    }, 1000);

    React.useEffect(() => {
        //autoselect if only one terminal is present
        const _checkoutPosTerminals: PosTerminalModel[] = posTerminals.filter(
            (p: PosTerminalModel) => p.terminalType === TerminalType.checkoutTerminal
        );
        if (_checkoutPosTerminals.length === 1) {
            selectPosTerminal(_checkoutPosTerminals[0]);
        }
    }, [posTerminals]);

    if (selectedPosTerminalId) {
        return props.children;
    }

    if (checkoutPosTerminals.length === 0 && delay === 0) {
        return (
            <div>
                <h1>Please register at least one checkout terminal to continue.</h1>
                <PosTerminalAdmin />
            </div>
        );
    } else if (checkoutPosTerminals.length !== 0) {
        return (
            <div className="container">
                <h2 className="mb-3 mt-3">Please select your terminal:</h2>
                <ul className="list-group col-md-6">
                    {checkoutPosTerminals.map((t:PosTerminalModel) => {
                        return (
                            <li className={'list-group-item d-flex justify-content-between'} key={t.id}>
                                {t.name}{' '}
                                <button
                                    onClick={() => {
                                        selectPosTerminal(t);
                                    }}
                                    className="btn btn-primary"
                                >
                                    SELECT
                                </button>
                            </li>
                        );
                    })}
                </ul>
            </div>
        );
    } else {
        return props.children;
    }
}

export default branch(
    {
        posTerminals: ['model', 'PosTerminal'],
        selectedPosTerminalId: ['state', 'selectedPosTerminalId'],
    },
    SelectPosTerminalWrapper
);
