import React, { useState, useEffect } from 'react';
import { branch } from 'baobab-react/higher-order';
import rest from '../../../data/restWrapper';
import _ from 'lodash';
import moment from 'moment/min/moment-with-locales';
import { ReservationStatusForCleaning } from '@common/modelDefinition';

const HouseholdPrintPreview = (props) => {
    const [selectedHeader, setSelectedHeader] = useState({});
    const [cleaningStatuses, setCleaningStatuses] = useState([]);
    const [maids, setMaids] = useState([]);
    const [isExpanded, setIsExpanded] = useState(false);

    useEffect(() => {
        const loadData = async () => {
            const _selectedHeaderId = props.match.params.cleaningStatusHeaderId;
            const _selectedHeader = await rest('/CleaningStatusHeader/' + _selectedHeaderId);
            const _cleaningStatuses = await rest('/getRoomCleaningStatuses/' + _selectedHeaderId);
            const _maids = await rest('/getEmployeesByRole/maid');
            setSelectedHeader(_selectedHeader);
            setCleaningStatuses(_cleaningStatuses);
            setMaids(_maids);
        };

        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, []);

    const roomByMaids = [],
        idsForCheckOtherRooms = [];

    const roomsWithTask = cleaningStatuses.filter((s) => s.cleaningStatus !== 0);
    const otherRooms = cleaningStatuses.filter((s) => s.cleaningStatus === 0);

    maids.forEach((m) => {
        const firstPosition = cleaningStatuses.filter((c) => c.cleanedByUserUuid === m.uuid);
        const secondPosition = cleaningStatuses.filter((c) => c.cleanedByUserUuid2 === m.uuid);
        let statuses = firstPosition.concat(secondPosition);
        statuses = statuses.map((s) => {
            s.roomInfo = props.rooms.find((r) => r.id === s.roomInfoId);
            if (!_.isUndefined(s.roomInfo)) {
                idsForCheckOtherRooms.push(s.roomInfo.id);
            }
            return s;
        });

        _.sortBy(statuses, ['updatedAt']);

        roomByMaids.push({
            user: m,
            statuses,
        });
    });

    const { validOnTimestamp = Date.now() } = selectedHeader;
    const incommingReservations =
        props.reservations && props.reservations.filter((r) => r.statusEnum !== 2 && r.checkInTimestamp > Date.now());
    const orderedReservations = _.orderBy(incommingReservations, 'checkInTimestamp', 'ASC');

    const headerClass = isExpanded
        ? 'pointer text-center border-bottom uppercase p-2'
        : 'pointer text-center  uppercase p-2'; //no undeline

    const headerItems = (
        <div className="row border-bottom text-center mb-2">
            <h3 className="col-12 text-center mb-5 mt-4">
                {window.translate('CLEANING SCHEDULE FOR ')} {moment(validOnTimestamp).format('LL')}
            </h3>
            <div className="col-2 border-right"> {window.translate('ROOM/STATUS')} </div>
            <div className="col-2 border-right"> {window.translate('NEXT CHECKIN/CHECKOUT')} </div>
            <div className="col-2 border-right"> {window.translate('RES. STATUS')} </div>
            <div className="col-2 border-right"> {window.translate('MISSING')} </div>
            <div className="col-2 border-right"> {window.translate('LOST/FOUND')} </div>
            <div className="col-2 border-right"> {window.translate('MALFUNCTIONS')} </div>
        </div>
    );

    return (
        <div className="container">
            <div className="pageHeaderScreen">{headerItems}</div>

            {roomByMaids.map((info) => {
                let userInfo = `${info.user.name} ${info.user.surname} (${info.user.email})`;
                if (info.statuses.length === 0) {
                    return null;
                }

                return (<>
                <div className="pageHeader">{headerItems}</div>
                    <div className="mt-3 pageBr" key={info.user.uuid}>
                         
                        <h5>{userInfo}</h5>
                        <ul className="list-group list-group-flush">
                            {info.statuses.map((status) => {
                                let roomName = status.roomInfo ? status.roomInfo.name : 'UNKNOWN';
                                const nextReservation = orderedReservations.find(
                                    (r) => r.roomInfoId === status.roomInfoId
                                );
                                let checkIn = '',
                                    checkOut = '';
                                if (nextReservation) {
                                    checkIn = moment(nextReservation.checkInTimestamp).format('MM-DD-YY hh:mm');
                                    checkOut = moment(nextReservation.checkOutTimestamp).format('MM-DD-YY hh:mm');
                                }

                                return (
                                    <li key={status.id} className="list-group-item border-bottom">
                                        <div className="row">
                                            <div className="col-2 border-right"> {roomName} </div>
                                            <div className="col-1 border-right">
                                                <small>{checkIn}</small>
                                            </div>
                                            <div className="col-1 border-right">
                                                <small>{checkOut}</small>
                                            </div>
                                            <div className="col-2 border-right">
                                                {window.translate(
                                                    ReservationStatusForCleaning[status.reservationStatus]
                                                )}
                                            </div>
                                            <div className="col-2 border-right"></div>
                                            <div className="col-2 border-right"></div>
                                            <div className="col-2 border-right"></div>
                                        </div>
                                    </li>
                                );
                            })}
                        </ul>
                    </div></>
                );
            })}

            <div className="pageHeader">{headerItems}</div> 
            <div className="mt-3">
                <h5>{window.translate('Rooms with requiring attention')}</h5>
                <ul className="list-group list-group-flush mb-3 pageBr">
                    {props.rooms.map((room) => {
                        const _roomFromCleaningStatuses = roomsWithTask.find((r) => r.roomInfoId === room.id)
                        if (_roomFromCleaningStatuses) {
                            if (!idsForCheckOtherRooms.includes(room.id)) {
                                const nextReservation = orderedReservations.find((r) => r.roomInfoId === room.id);
                                let checkIn = '',
                                    checkOut = '';
                                if (nextReservation) {
                                    checkIn = moment(nextReservation.checkInTimestamp).format('MM-DD-YY hh:mm');
                                    checkOut = moment(nextReservation.checkOutTimestamp).format('MM-DD-YY hh:mm');
                                }

                                return (
                                    <li key={room.id} className="list-group-item  border-bottom">
                                        <div className="row">
                                            <div className="col-2 border-right"> {room.name} </div>
                                            <div className="col-1 border-right">
                                                <small>{checkIn}</small>
                                            </div>
                                            <div className="col-1 border-right">
                                                <small>{checkOut}</small>
                                            </div>
                                            <div className="col-2 border-right">
                                                {window.translate(
                                                    ReservationStatusForCleaning[_roomFromCleaningStatuses.reservationStatus]
                                                )}
                                            </div>
                                            <div className="col-2 border-right"></div>
                                            <div className="col-2 border-right"></div>
                                            <div className="col-2 border-right"></div>
                                        </div>
                                    </li>
                                );
                            }
                        }

                        return null;
                    })}
                </ul>
            </div>

            <div className="pageHeader">{headerItems}</div>
            <div className="mt-3">
                <h5
                    onClick={() => {
                        setIsExpanded(!isExpanded);
                    }}
                    className={headerClass}
                >
                    <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                    &ensp;{window.translate('Other rooms')}&ensp;
                    <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                </h5>
                {isExpanded && idsForCheckOtherRooms.length !== props.rooms.length ? (
                    <ul className="list-group list-group-flush mb-3">
                        {props.rooms.map((room) => {
                            const _roomFromCleaningStatuses = otherRooms.find((r) => r.roomInfoId === room.id)
                            if (_roomFromCleaningStatuses) {
                                if (!idsForCheckOtherRooms.includes(room.id)) {
                                    const nextReservation = orderedReservations.find((r) => r.roomInfoId === room.id);
                                    let checkIn = '',
                                        checkOut = '';
                                    if (nextReservation) {
                                        checkIn = moment(nextReservation.checkInTimestamp).format('MM-DD-YY hh:mm');
                                        checkOut = moment(nextReservation.checkOutTimestamp).format('MM-DD-YY hh:mm');
                                    }

                                    return (
                                        <li key={room.id} className="list-group-item  border-bottom">
                                            <div className="row">
                                                <div className="col-2 border-right"> {room.name} </div>
                                                <div className="col-1 border-right">
                                                    <small>{checkIn}</small>
                                                </div>
                                                <div className="col-1 border-right">
                                                    <small>{checkOut}</small>
                                                </div>
                                                <div className="col-2 border-right">
                                                    {window.translate(
                                                        ReservationStatusForCleaning[_roomFromCleaningStatuses.reservationStatus]
                                                    )}
                                                </div>
                                                <div className="col-2 border-right"></div>
                                                <div className="col-2 border-right"></div>
                                                <div className="col-2 border-right"></div>
                                            </div>
                                        </li>
                                    );
                                }
                            }

                            return null;
                        })}
                    </ul>
                ) : null}{' '}
            </div>
        </div>
    );
};

export default branch(
    {
        rooms: ['model', 'RoomInfo'],
        reservations: ['model', 'Reservation'],
    },
    HouseholdPrintPreview
);
