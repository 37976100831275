import React, { useState, useEffect } from 'react';
import PageTitle from '@components/pageTitle';
import rest from '@data/restWrapper';
import { setModalContent } from '@components/modal';
import Form from './form';
import baobab from '@data/state';
import Delete from '@components/confirmModal';
import { toast } from 'react-toastify';

const DocScannerDevices = (props) => {
    const [loading, setLoading] = useState(true);
    const [docScannerDevice, setDocScannerDevice] = useState([]);

    useEffect(() => {
        const fetchData = async () => {
            try {
                let _docScannerDevice = await rest('/DocumentScannerDevice?by=uuid', 'GET');
                setDocScannerDevice(_docScannerDevice);
                setLoading(true);
            } catch (err) {
                console.log(err);
            }
        };

        fetchData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [loading, docScannerDevice.length]);

    const showFormModal = (docScannerDev) => {
        let header = docScannerDev && docScannerDev.uuid ? window.translate('Edit') : window.translate('ADD NEW');
        setModalContent(
            <Form docScannerDevice={docScannerDev} cancelHandler={cancelHandler} />,
            header,
            false,
            'modal-lg'
        );
    };

    const cancelHandler = (e) => {
        setLoading(false);
        baobab.root.select(['modalData', 'display']).set(false);
        baobab.root.select(['modalData', 'content']).set(null);
    };

    const delDocScannDevice = async (docScannDeviceUuid) => {
        try {
            await rest('/DocumentScannerDevice/' + docScannDeviceUuid, 'DELETE');
            toast(window.translate("Document scanner device is deleted!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.INFO });
            setLoading(false);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = (docScannDeviceUuid) => {
        setModalContent(
            <Delete actionForYes={delDocScannDevice.bind(this, docScannDeviceUuid)} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };

    return (
        <div className="">
            {props.history.location.pathname.indexOf('adscreen') !== -1 ? null : <PageTitle />}

            <div className="row ">
                <div className="d-flex col-12 ">
                    {props.history.location.pathname.indexOf('adscreen') !== -1 ? null : (
                        <button
                            onClick={() => {
                                props.location.search
                                    ? props.history.push('/selfCheckInAdministrative')
                                    : props.history.push('/menu/system');
                            }}
                            className="btn btn-sm btn-secondary mr-2"
                        >
                            {window.translate('GO BACK')}
                        </button>
                    )}
                    <button onClick={showFormModal.bind(this, {})} className="btn btn-sm btn-primary">
                        {window.translate('ADD NEW')}
                    </button>
                </div>
            </div>

            <ul className="list-group mt-3 mx-3">
                <small>
                    <li className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                        <div className="col-3 col-sm-3  elipsis">{window.translate("Uuid")}</div>
                        <div className="col-3 col-sm-3 elipsis">{window.translate("Name")}</div>
                        <div className="col-3 col-sm-3 ">{window.translate("Description")}</div>
                        <div className="col-3 col-sm-3"></div>
                    </li>
                </small>
            </ul>

            <ul className="list-group mt-2 mx-3">
                {docScannerDevice.map((p, key) => {
                    return (
                        <li key={key} className={'px-1 py-1 row shadow-sm bg-white my-1 rounded'}>
                            <div className="col-3 col-sm-3 elipsis" style={{ userSelect: "text" }}>{p.uuid}</div>
                            <div className="col-3 col-sm-3 elipsis">{p.name}</div>
                            <div className="col-3 col-sm-3 elipsis">{p.description}</div>
                            <div className="col-3 col-sm-3">
                                <div className="pt-2 pr-2">
                                    <button
                                        onClick={showFormModal.bind(this, p)}
                                        className="btn btn-sm btn-outline-dark pull-right ml-1 mb-1"
                                    >
                                        <i className="fa fa-edit"></i>
                                    </button>
                                    <button
                                        onClick={() => {
                                            showDeleteModal(p.uuid);
                                        }}
                                        className="btn btn-sm btn-outline-danger pull-right mb-1"
                                    >
                                        <i className="fa fa-trash"></i>
                                    </button>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
        </div>
    );
};

export default DocScannerDevices;
