import React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { CompanyInfo, CashRegReport, CashRegPayments } from '../cashRegistar';
import { CashRegistarModel, CashRegistarPaymentsModel, CashRegistarReportModel } from '@common/modelDefinition';
import { getByIdCashRegReportAndPayments } from './api';
import translate from '@data/translations';
import DocumentFooter from '../../checkoutAndInvoice/printView/DocumentFooter';
import DocumentHeader from '../../checkoutAndInvoice/printView/DocumentHeader';
import { startGlobalLoading, stopGlobalLoading } from '@components/loaders/globalLoading';

export interface CashRegPaymentPrintViewProps {
    match?: any;
    location?: any;
    history?: any;
    companyInfo: any;
    cashRegistars: CashRegistarModel[];
}

const CashRegPaymentPrintView = (props: CashRegPaymentPrintViewProps) => {
    const reportId = props?.match?.params?.reportId;
    const [cashRegReport, setCashRegReport] = React.useState({} as CashRegistarReportModel);
    const [cashRegPayments, setCashRegPayments] = React.useState([] as CashRegistarPaymentsModel[]);
    const [cashRegistar, setCashRegistar] = React.useState({} as CashRegistarModel);
    const [isExpanded, setIsExpanded] = React.useState(false);

    React.useEffect(() => {
        loadData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line
    }, [reportId]);

    React.useEffect(() => {
        if (cashRegReport.cashRegistarId) {
            const cashReg =
                props.cashRegistars &&
                props.cashRegistars.find((r: CashRegistarModel) => r.id === cashRegReport.cashRegistarId);
            if (cashReg) {
                setCashRegistar(cashReg);
            }
        }
        // eslint-disable-next-line
    }, [props.cashRegistars]);

    const loadData = async () => {
        if (reportId) {
            startGlobalLoading();
            const report: any = await getByIdCashRegReportAndPayments(reportId);
            setCashRegReport(report.report);
            setCashRegPayments(report.payments);
            stopGlobalLoading();
        }
    };

    return (
        <div className="bg-light">
            <div style={{ maxWidth: '900px', margin: 'auto' }} className="bg-white">
                <DocumentHeader>
                    {props.companyInfo ? <CompanyInfo companyInfo={props.companyInfo} /> : null}
                    {cashRegistar?.id ? (
                        <div className="col-12 border-bottom mt-2 text-left">
                            <div className="col-12">
                                <small>
                                    {translate('Cash registar: ')} <b>{cashRegistar.name}</b>
                                </small>
                            </div>
                            <div className="col-12 mb-2">
                                <small>
                                    {translate('Cash registar maximum: ')} <b>{cashRegistar.cashMax}</b>
                                </small>
                            </div>
                        </div>
                    ) : null}
                </DocumentHeader>

                <CashRegReport report={cashRegReport} print={true} />
                <div className="col-12 mt-3">
                    <h5
                        onClick={() => {
                            setIsExpanded(!isExpanded);
                        }}
                        className="mt-3 pointer text-center uppercase p-2"
                    >
                        <i className={isExpanded ? 'fa fa-compress' : 'fa fa-expand '} />{' '}
                        {<b>{translate('Pay ins and pay outs')}</b>}{' '}
                        <i className={isExpanded ? 'fa fa-compress' : 'fa fa-expand '} />
                    </h5>
                </div>

                {isExpanded ? (
                    <CashRegPayments payments={cashRegPayments} loadData={loadData} history={props.history} />
                ) : null}
                <DocumentFooter />
            </div>
        </div>
    );
};

export default branch(
    {
        cashRegistars: ['model', 'CashRegistar'],
        companyInfo: ['companyInfo'],
    },
    CashRegPaymentPrintView
);
