import React from 'react';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { DocumentProps } from './DocumentFooter';

const DocumentHeader = (props:DocumentProps) => {
    let documentHeaderInfo =
        props.otherSettings && props.otherSettings.documentHeader ? props.otherSettings.documentHeader : '';
    return (
        <div className="text-center border-bottom mb-2">
            <small>
                <small>
                    <small dangerouslySetInnerHTML={{ __html: documentHeaderInfo }}></small>
                </small>
            </small>
            {props.children}
        </div>
    );
};

export default branch(
    {
        otherSettings: ['otherSettings'],
    },
    DocumentHeader
);
