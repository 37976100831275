import React, { Component } from 'react';
import moment from 'moment/min/moment-with-locales';
import _ from 'lodash';
import EventResolutionModal from '../../allRoomEvents/eventRespondModal';
import { setModalContent } from '@components/modal';
import { userFrendlyAlarmAndEventsNames, alarmAndEventCSSStyling } from '../../../data/SRCAlarmAndAlertController';
import baobabTree from '../../../data/state';
import rest from '../../../data/restWrapper';
import pubsub from '../../../data/pubsub';

import { FixedSizeList as List } from 'react-window';

function getPos(el) {
    // yay readability
    for (var lx = 0, ly = 0; el != null; lx += el.offsetLeft, ly += el.offsetTop, el = el.offsetParent);
    return { x: lx, y: ly };
}

//pokušaj dohvatiti događaj koji se briše i stavi ga na stog događaja koji su prošli!!!!

const RowTemplate = React.memo((props) => {
    const { data, index, style } = props;
    if (data[index] === null) {
        return null
    }
    const { item: ev = {} } = data ? data[index] : {};

    let liClassName = 'list-group-item p-1 ' + alarmAndEventCSSStyling[ev.ruleName]; //"list-group-item p-1 ";

    return (
        <li style={style} key={ev.id} className={liClassName}>
            <div className="row">
                <div className="col-6 col-md-6 col-xl-3 elipsis">
                    <small>{moment(ev.openingTimestamp).format('LLL')}</small>
                </div>
                <div className="col-6 col-md-6 col-xl-5 elipsis">
                    <small> {userFrendlyAlarmAndEventsNames[ev.ruleName]}</small>
                </div>
                <div className="col-6 col-md-6 col-xl-2 elipsis">
                    <small>{moment(ev.openingTimestamp).fromNow()}</small>
                </div>
                <div className="col-6 col-md-6 col-xl-2 elipsis">
                    <small>{moment.duration(ev.closingTimestamp - ev.openingTimestamp).humanize()}</small>
                </div>
            </div>
        </li>
    );
});

class RoomEventHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            roomEvents: [],
            tableContentHeight: 300,
        };
    }

    componentDidMount = async () => {
        pubsub.on('socketPayload', this.socketUIHandlers);

        //data fetching
        let roomEvents = await rest(`/getTargetRoomEvents/${this.props.roomInfo.id}`);
        roomEvents = roomEvents.filter((ev) => ev.closingTimestamp !== null);

        //height

        let el = document.getElementById('event-history-list');
        let footer = document.getElementById('main-footer');

        let positionOfTableHeaderElement = getPos(el);
        let positionOfDocumentFooterElement = getPos(footer);
        let heightOfTableHeaderElement = el.offsetHeight;

        const heightOfTheSpaceFromTableHeaderToDocumentFooter =
            positionOfDocumentFooterElement.y - (positionOfTableHeaderElement.y + heightOfTableHeaderElement);
        const tableContentHeight = heightOfTheSpaceFromTableHeaderToDocumentFooter;
        this.setState({ tableContentHeight, roomEvents });
    };

    componentWillUnmount = () => {
        pubsub.removeListener('socketPayload', this.socketUIHandlers);
    };

    socketUIHandlers = (payload) => {
        if (payload.model === 'RoomEventTicket' && payload.method === 'DELETE') {
            const { roomInfo } = this.props;
            if (roomInfo.id === payload.entity.roomInfoId) {
                const roomEvents = this.state.roomEvents;
                roomEvents.push(payload.entity);
                this.setState({ roomEvents });
            }
        }
    };

    async showEventResolutionModal(ev, e) {
        //šta sa ovim????
        try {
            let activeSosResponses = await rest('/getActiveSosResponses');
            baobabTree.root.select(['model', 'SosResponse']).set(activeSosResponses);
            setModalContent(
                <EventResolutionModal reservation={this.props.reservation} event={ev} />,
                window.translate('RESPOND TO EVENT'),
                true,
                'modal-lg'
            );
        } catch (err) {
            console.log(err);
        }
    }

    render() {
        const {
            roomInfo,
            activeEventTickets = [],
            showActiveRoomEvents = true,
            filterEventsByReservation = false,
        } = this.props;

        const { tableContentHeight } = this.state;

        const unfinishedEvents = activeEventTickets
            .filter((ev) => ev.roomInfoId === roomInfo.id)
            .map((ev) => {
                let cssSufix = alarmAndEventCSSStyling[ev.ruleName] ? alarmAndEventCSSStyling[ev.ruleName] : 'p-2';
                let liClassName = 'list-group-item p-1 animated faster slideInDown ' + cssSufix; //"list-group-item p-1 ";
                return (
                    <li onClick={this.showEventResolutionModal.bind(this, ev)} key={ev.id} className={liClassName}>
                        <div className="row">
                            <div className="col-6 col-md-6">
                                <small>{userFrendlyAlarmAndEventsNames[ev.ruleName]}</small>
                            </div>
                            <div className="col-6 col-md-6">
                                <small>{moment(ev.openingTimestamp).fromNow()}</small>
                            </div>
                        </div>
                    </li>
                );
            });

        const finishedEvents = _.map(_.orderBy(this.state.roomEvents, ['openingTimestamp'], ['desc']), (ev) => {
            if (filterEventsByReservation && this.props.reservation.id !== ev.reservationId) {
                return null;
            }

            return {
                item: ev,
            };
        });

        return (
            <div>
                {showActiveRoomEvents ? (
                    <div>
                        <h3 className="mt-3 text-secondary">
                            <i className="fa fa-bell" aria-hidden="true"></i>
                            {window.translate('Active room events')}
                        </h3>
                        <ul className="list-group list-group-flush bg-white ">
                            {unfinishedEvents.length > 0 ? (
                                unfinishedEvents
                            ) : (
                                    <li className="list-group-item p-1 ">{window.translate('Nothing to show')}</li>
                                )}
                        </ul>
                    </div>
                ) : null}

                <h3 className="mt-3 text-secondary">
                    <i className="fa fa-bell" aria-hidden="true"></i>
                    {window.translate('Room events history')}
                </h3>
                <ul id="event-history-list" className="list-group list-group-flush bg-white ">
                    {finishedEvents.length > 0 ? (
                        <List
                            overscanCount={200}
                            height={tableContentHeight}
                            itemData={finishedEvents}
                            itemCount={finishedEvents.length}
                            itemSize={40}
                        >
                            {RowTemplate}
                        </List>
                    ) : (
                            <li className="list-group-item p-1 ">{window.translate('Nothing to show')}</li>
                        )}
                </ul>
            </div>
        );
    }
}
export default RoomEventHistory;
