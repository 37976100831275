
import React, { Component } from 'react';
import { branch } from 'baobab-react/higher-order';
import PropTypes from 'prop-types';
import _ from "lodash"
import './style.css';

class CardTabPlaceholder extends Component {
	constructor(props){
		super(props)
		this.state = {
			selectedIndex:props.selectedIndex ? props.selectedIndex : 0
		}
	}

	onSelectedTabIndexChange(index, e){
		e.preventDefault();
		if(this.props.onSelectedTabIndexChange){
			this.props.onSelectedTabIndexChange(index);
		}
		this.setState({selectedIndex:index})
	}
	render(){
		let counter = -1;
		let tabNames = _.map(this.props.tabNames, name=>{
			counter++;
			return (<li key={_.uniqueId("k")} 
				onClick={this.onSelectedTabIndexChange.bind(this, counter)} 
				className={this.state.selectedIndex===counter ? "active" : null}>
				<a href="/#">
					<i className={this.props.tabIcons[counter]} />
					{name}
					<div className="ripple-container"></div>

				</a>

				</li>)
		})
		counter = -1;

		if(this.props.tabContentComponents.length===0){
			return "No available settings for current user level."
		}


		let tabContent = _.map(this.props.tabContentComponents, tabControl=>{
			counter++;
			return (<div key={_.uniqueId("k2")}  className={ ["tab-pane", this.state.selectedIndex===counter ? "active" : null].join(" ")}>
				{tabControl}
				</div>)
			})
		return (<div className="transparent-border card-tab">
			<div className="card card-nav-tabs">
				<div className="header header-info">
				<div className="nav-tabs-navigation">
					<div className="nav-tabs-wrapper">
						<ul className="nav nav-tabs">
							{tabNames}
						</ul>
					</div>
				</div>
			 	</div>

				<div className={"content"}>
					<div className="tab-content">
						{tabContent}
					</div>
				</div>
			</div>
		 </div>)
	}
}

CardTabPlaceholder.defaultProps = {
	tabNames:["references", "help","console output"],
	tabIcons:["fa fa-info-circle", "fa fa-question-circle-o", "fa fa-terminal"],
	tabContentComponents:["content 1","content 2", "content 3"],
	//selectedIndex:0,
}

CardTabPlaceholder.propTypes = {
	onSelectedTabIndexChange: PropTypes.func,
	tabNames: PropTypes.array.isRequired,
	tabIcons: PropTypes.array.isRequired,
	tabContentComponents: PropTypes.array.isRequired,
	selectedIndex: PropTypes.number

}

export default branch({
}, CardTabPlaceholder);