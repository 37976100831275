import React from 'react';
import { handleAlarmInterpreterChange, saveFormData, loadAlarmInterpreter } from '../settingsActions';
import { toast } from 'react-toastify';
import { branch } from 'baobab-react/higher-order';

const AlarmAndRoomModeInterpreter = (props) => {
    const { settings } = props;
    const { alarmInterpreter } = settings;
    const [isExpanded, setIsExpanded] = React.useState(false);
    const [disabledAlarmsBeforeSave, setDisabled] = React.useState([]);

    React.useEffect(() => {
        const loadData = async () => {
            try {
                const alarms = await loadAlarmInterpreter('alarmInterpreter');
                if (alarms && alarms.inputInterpreter) {
                    const disabled = [];
                    alarms.inputInterpreter.forEach((i, index) => (!i.enabled ? disabled.push(index) : null));
                    setDisabled(disabled);
                }
            } catch (err) {
                toast(err.message, { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.ERROR });
            }
        };

        loadData().catch((err) => {
            console.warn(err);
        });
    }, []);

    let inputInterpreter = [],
        roomModeInterpreter = {};
    if (alarmInterpreter) {
        inputInterpreter = alarmInterpreter.inputInterpreter;
        roomModeInterpreter = alarmInterpreter.roomModeInterpreter;
    }

    const headerClass = isExpanded
        ? 'pointer text-center border-bottom uppercase p-2'
        : 'pointer text-center  uppercase p-2'; //no undeline

    return (
        <div className=" bg-white p-3 text-center">
            <b>{window.translate('ALARM INTERPRETER')}</b>
            <hr />

            {inputInterpreter.map((ai, index) => {
                if (!disabledAlarmsBeforeSave.includes(index)) {
                    const checkboxIcon = ai.enabled ? (
                        <i
                            className="fa fa-2x fa-check-square-o pointer"
                            onClick={handleAlarmInterpreterChange.bind(
                                this,
                                'alarmInterpreter',
                                'boolean',
                                'inputInterpreter',
                                index,
                                true
                            )}
                        />
                    ) : (
                        <i
                            className="fa fa-2x fa-square-o pointer"
                            onClick={handleAlarmInterpreterChange.bind(
                                this,
                                'alarmInterpreter',
                                'boolean',
                                'inputInterpreter',
                                index,
                                true
                            )}
                        />
                    );

                    const rows = returnedRows(ai, index, checkboxIcon);
                    return rows;
                }
                return null;
            })}

            <p
                onClick={() => {
                    setIsExpanded(!isExpanded);
                }}
                className={headerClass}
            >
                <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
                &ensp;{window.translate('Disabled alarms')}&ensp;
                <i className={isExpanded ? 'fa fa-compress ' : 'fa fa-expand '} />
            </p>
            {isExpanded ? (
                inputInterpreter.map((ai, index) => {
                    if (disabledAlarmsBeforeSave.includes(index)) {
                        const checkboxIcon = !ai.enabled ? (
                            <i
                                className="fa fa-2x fa-square-o pointer"
                                onClick={handleAlarmInterpreterChange.bind(
                                    this,
                                    'alarmInterpreter',
                                    'boolean',
                                    'inputInterpreter',
                                    index,
                                    true
                                )}
                            />
                        ) : (
                            <i
                                className="fa fa-2x fa-check-square-o pointer"
                                onClick={handleAlarmInterpreterChange.bind(
                                    this,
                                    'alarmInterpreter',
                                    'boolean',
                                    'inputInterpreter',
                                    index,
                                    true
                                )}
                            />
                        );
                        const rows = returnedRows(ai, index, checkboxIcon);
                        return rows;
                    }
                    return null;
                })
            ) : (
                <>
                    <br />
                    <hr />
                    <br />
                </>
            )}

            <b>{window.translate('ROOM MODE INTERPRETER')}</b>
            <hr />
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'ECO'}>{window.translate('ECO')}:</label>{' '}
                </div>
                <div className="col-md-3">
                    <input
                        className="form-control"
                        name="ECO"
                        onChange={handleAlarmInterpreterChange.bind(
                            this,
                            'alarmInterpreter',
                            'object',
                            'roomModeInterpreter',
                            null
                        )}
                        type="text"
                        value={roomModeInterpreter.ECO}
                    ></input>
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'PRECOMFORT'}>{window.translate('PRECOMFORT')}:</label>{' '}
                </div>
                <div className="col-md-3">
                    <input
                        className="form-control"
                        name="PRECOMFORT"
                        onChange={handleAlarmInterpreterChange.bind(
                            this,
                            'alarmInterpreter',
                            'object',
                            'roomModeInterpreter',
                            null
                        )}
                        type="text"
                        value={roomModeInterpreter.PRECOMFORT}
                    ></input>
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'COMFORT'}>{window.translate('COMFORT')}:</label>{' '}
                </div>
                <div className="col-md-3">
                    <input
                        className="form-control"
                        name="COMFORT"
                        onChange={handleAlarmInterpreterChange.bind(
                            this,
                            'alarmInterpreter',
                            'object',
                            'roomModeInterpreter',
                            null
                        )}
                        type="text"
                        value={roomModeInterpreter.COMFORT}
                    ></input>
                </div>
            </div>

            <div className="text-center p-5">
                <div
                    onClick={() => saveFormData('alarmInterpreter', alarmInterpreter)}
                    className="btn btn-outline-primary pointer"
                >
                    {window.translate('Save')}
                </div>
            </div>
        </div>
    );
};

const returnedRows = (ai, index, checkboxIcon) => {
    return (
        <div key={index}>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'icon'}>{window.translate('Enabled')}:</label>{' '}
                </div>
                <div className="col-md-3">{checkboxIcon}</div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'icon'}>{window.translate('Icon')}:</label>{' '}
                </div>
                <div className="col-md-3">
                    <i className={ai.icon + ' fa-2x'} />
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'nameToDisplay'}>{window.translate('Name to display')}:</label>{' '}
                </div>
                <div className="col-md-3">
                    <input
                        className="form-control"
                        placeholder={'Name to display'}
                        value={window.translate(ai.nameToDisplay)}
                        type="text"
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'name'}>{window.translate('Name')}:</label>{' '}
                </div>
                <div className="col-md-3">
                    <input className="form-control" placeholder={'Name'} value={ai.name} type="text" readOnly={true} />
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'shortName'}>{window.translate('Short name')}:</label>{' '}
                </div>
                <div className="col-md-3">
                    <input
                        className="form-control"
                        placeholder={'Short name'}
                        value={ai.shortName}
                        type="text"
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'falseStateLabel'}>{window.translate('False state label')}:</label>{' '}
                </div>
                <div className="col-md-3">
                    <input
                        className="form-control"
                        placeholder={'False state label'}
                        value={ai.falseStateLabel}
                        type="text"
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'trueStateLabel'}>{window.translate('True state label')}:</label>{' '}
                </div>
                <div className="col-md-3">
                    <input
                        className="form-control"
                        placeholder={'True state label'}
                        value={ai.trueStateLabel}
                        type="text"
                        readOnly={true}
                    />
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'falseStateIconColor'}>{window.translate('False state icon color')}:</label>{' '}
                </div>
                <div className="col-md-2 d-flex">
                    <input
                        className="form-control"
                        name="falseStateIconColor"
                        onChange={handleAlarmInterpreterChange.bind(
                            this,
                            'alarmInterpreter',
                            'object',
                            'inputInterpreter',
                            index
                        )}
                        type="color"
                        value={ai.falseStateIconColor}
                    ></input>
                    <div className="col-1"></div>
                    <i className={ai.icon + ' fa-2x'} style={{ color: ai.falseStateIconColor }} />
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'trueStateIconColor'}>{window.translate('True state icon color')}:</label>{' '}
                </div>
                <div className="col-md-2 d-flex">
                    <input
                        className="form-control"
                        name="trueStateIconColor"
                        onChange={handleAlarmInterpreterChange.bind(
                            this,
                            'alarmInterpreter',
                            'object',
                            'inputInterpreter',
                            index
                        )}
                        type="color"
                        value={ai.trueStateIconColor}
                    ></input>
                    <div className="col-1"></div>
                    <i className={ai.icon + ' fa-2x'} style={{ color: ai.trueStateIconColor }} />
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'falseStateAnimationBlink'}>
                        {window.translate('False state animation blink')}:
                    </label>{' '}
                </div>
                <div className="col-md-3">
                    <select
                        name="falseStateAnimationBlink"
                        value={ai.falseStateAnimationBlink}
                        onChange={handleAlarmInterpreterChange.bind(
                            this,
                            'alarmInterpreter',
                            'boolean',
                            'inputInterpreter',
                            index
                        )}
                        className="custom-select my-1 mr-sm-2 "
                        id="falseStateAnimationBlink"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        <option value={'true'}>{window.translate('True')}</option>
                        <option value={'false'}>{window.translate('False')}</option>
                    </select>
                </div>
            </div>
            <div className="row mt-2 align-items-center">
                <div className="col-md-2">
                    {' '}
                    <label htmlFor={'trueStateAnimationBlink'}>
                        {window.translate('True state animation blink')}:
                    </label>{' '}
                </div>
                <div className="col-md-3">
                    <select
                        name="trueStateAnimationBlink"
                        value={ai.trueStateAnimationBlink}
                        onChange={handleAlarmInterpreterChange.bind(
                            this,
                            'alarmInterpreter',
                            'boolean',
                            'inputInterpreter',
                            index
                        )}
                        className="custom-select my-1 mr-sm-2 "
                        id="trueStateAnimationBlink"
                    >
                        <option value="">{window.translate('Nothing selected')}</option>
                        <option value={'true'}>{window.translate('True')}</option>
                        <option value={'false'}>{window.translate('False')}</option>
                    </select>
                </div>
            </div>
            <br />
            <hr />
            <br />
        </div>
    );
};

export default branch(
    {
        settings: ['state', 'generalSettings'],
    },
    AlarmAndRoomModeInterpreter
);
