import React, { useState, useEffect } from 'react';
import rest from '../../data/restWrapper';
import pubsub from '../../data/pubsub';
import { v4 as uuidv4 } from 'uuid';
import { toast } from 'react-toastify';

const DocScannerForm = (props) => {
    const [docScannerDevice, setDocScannerDevice] = useState({});

    useEffect(() => {
        const fetchData = async () => {
            try {
                let _docScannerDevice = props.docScannerDevice;
                setDocScannerDevice(_docScannerDevice);
            } catch (err) {
                console.log(err);
            }
        };

        fetchData().catch((err) => {
            console.log(err);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.docScannerDevice]);

    const handleInputChange = (e) => {
        let _docScannerDevice = { ...docScannerDevice };
        _docScannerDevice[e.target.name] = e.target.value;
        setDocScannerDevice(_docScannerDevice);
    };

    const saveFormData = async (e) => {
        try {
            let _docScannerDevice = docScannerDevice;
            if (_docScannerDevice.uuid) {
                await rest('/DocumentScannerDevice/' + _docScannerDevice.uuid, 'PUT', _docScannerDevice);
            } else {
                _docScannerDevice.uuid = uuidv4();
                await rest('/DocumentScannerDevice', 'POST', _docScannerDevice);
                toast(window.translate("Document scanner device added!"), { position: toast.POSITION.BOTTOM_RIGHT, type: toast.TYPE.SUCCESS });
            }
            props.cancelHandler();
        } catch (err) {
            pubsub.emit('toastMessage', err.message, 'error');
        }
    };

    return (
        <div className="container pb-2">
            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Name')}
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        name="name"
                        placeholder={window.translate('Name')}
                        type="text"
                        className="form-control"
                        value={docScannerDevice.name ? docScannerDevice.name : ""}
                        onChange={handleInputChange.bind(this)}
                    />
                </div>
            </div>
            <div className="form-group row justify-content-center align-items-center mt-2 mb-2">
                <label className="col-md-3 col-form-label" htmlFor="">
                    {window.translate('Description')}
                </label>
                <div className="col-md-8">
                    <input
                        autoComplete="off"
                        name="description"
                        placeholder={window.translate('Description')}
                        type="text"
                        className="form-control"
                        value={docScannerDevice.description ? docScannerDevice.description : ""}
                        onChange={handleInputChange.bind(this)}
                    />
                </div>
            </div>
            <br />

            <div className="text-center mb-5 pt-5">
                <button data-dismiss="modal" onClick={saveFormData.bind(this)} className="btn btn-outline-primary">
                    {window.translate('Save')}
                </button>
                <button
                    data-dismiss="modal"
                    onClick={props.cancelHandler.bind(this)}
                    className="btn btn-outline-secondary ml-2"
                >
                    {window.translate('Cancel')}
                </button>
            </div>
        </div>
    );
};

export default DocScannerForm;
