import React, { Component } from 'react';
import _ from 'lodash';
import FormHeader from '../../components/formHeader';
import moment from 'moment';

import { setModalContent, closeModal } from '@components/modal';

import MigrateSRCProxy from './migrateSRCProxy';


class SRCProxyDevices extends Component {
    constructor() {
        super();
        this.state = {
            bindingSRC: null,
            attachRoom: false,
        };
    }
    bindSRCToRoomInfo(roomInfo) {
        if (this.props.bindSRCToRoomInfo) {
            this.props.bindSRCToRoomInfo(this.state.bindingSRC.remoteDeviceId, roomInfo);
        }
        this.setState({ bindingSRC: null });
    }
    attachRoomInfo2SRCProxy(roomInfo, srcProxy) {
        if (this.props.attachRoomInfo2SRCProxy) {
            this.props.attachRoomInfo2SRCProxy(roomInfo, srcProxy);
        }
        this.setState({ attachRoom: false });
    }

    selectAndAttachRooms(srcProxy) {
        if (this.props.selectAndAttachRooms) {
            this.props.selectAndAttachRooms(srcProxy);
        }
        this.setState({ attachRoom: false });
    }

    showMigrationModel(originSRCProxy) {
        setModalContent(
            <MigrateSRCProxy
                migrationCompletedCallback={this.migrationCompletedCallback.bind(this)}
                originSRCProxy={originSRCProxy}
            />,
            'Migration tool',
            false,
            'modal-md'
        );
    }

    migrationCompletedCallback() {
        closeModal();
    }

    render() {
        const {licenceInfo} = this.props;
        if (this.props.srcProxy.id === undefined) {
            return null;
        }

        let unbindedSRCs = [];
        let bindedRemoteDeviceIds = _.map(this.props.srcProxy.RoomInfo, 'remoteDeviceId');
        _.forEach(this.props.remoteSRC, (src) => {
            if (!_.includes(bindedRemoteDeviceIds, src.remoteDeviceId)) {
                if (Number(src.srcProxyId) === Number(this.props.srcProxy.id)) {
                    unbindedSRCs.push(src);
                }
            }
        });

        const licenceLimitText = `LICENCE LIMIT ${licenceInfo.currentDevicesCount} of ${licenceInfo.licence?.decodedLicence?.devicesCount}`

        if (this.state.attachRoom) {
            return (
                <div>
                    <button
                        onClick={() => {
                            this.setState({ attachRoom: false });
                        }}
                        className="btn btn-sm btn-secondary mb-2 mr-2"
                    >
                        {window.translate("GO BACK")}
                    </button>

                    <FormHeader>{this.props.srcProxy.name}</FormHeader>
                    <div className="text-center">
                        <small>{this.props.srcProxy.description}</small>
                    </div>

                    <div className="">
                        <h5 className="text-center mt-2">{window.translate("SELECT UNATACHED ROOM")}</h5>
                        <div className="d-flex justify-content-end pb-2 pr-3">
                            <div className="mr-1" onClick={this.props.selectAllRooms.bind(this)}>
                                {window.translate("Select All")}
                                <i
                                    className={
                                        this.props.selectedAll
                                            ? 'ml-2 fa fa-check-square fa-lg text-primary'
                                            : 'ml-2 fa fa-square fa-lg text-primary'
                                    }
                                    value={this.props.selectedAll ? this.props.selectedAll : false}
                                ></i>
                            </div>
                        </div>
                        <ul className="list-group ">
                            {_.map(this.props.allRoomInfos, (roomInfo) => {
                                let checkClassName = 'ml-2 fa fa-square fa-lg text-primary';
                                if (this.props.selectedAll === true) {
                                    checkClassName = 'ml-2 fa fa-check-square fa-lg text-primary';
                                } else {
                                    if (this.props.selectedRooms[roomInfo.id]) {
                                        checkClassName = 'ml-2 fa fa-check-square fa-lg text-primary';
                                    } else {
                                        checkClassName = 'ml-2 fa fa-square fa-lg text-primary';
                                    }
                                }
                                if (roomInfo.srcProxyId === null || roomInfo.srcProxyId === undefined) {
                                    return (
                                        <li key={roomInfo.id} className="list-group-item">
                                            <div className="d-block">
                                                <div className="d-inline"> {roomInfo.name}</div>
                                                <div className="d-inline pull-right">
                                                    <div className="d-inline">
                                                        <button
                                                            onClick={this.attachRoomInfo2SRCProxy.bind(
                                                                this,
                                                                roomInfo,
                                                                this.props.srcProxy
                                                            )}
                                                            className="btn btn-sm btn-outline-primary mr-3"
                                                        >
                                                            {window.translate("ATTACH ROOM")}
                                                        </button>
                                                    </div>
                                                    <div
                                                        className="d-inline"
                                                        onClick={this.props.selectRooms.bind(this, roomInfo.id)}
                                                    >
                                                        {window.translate("Select room")}
                                                        <i
                                                            className={checkClassName}
                                                            value={
                                                                this.props.selectedRooms[roomInfo.id]
                                                                    ? this.props.selectedRooms[roomInfo.id]
                                                                    : false
                                                            }
                                                        ></i>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    );
                                } else {
                                    return null;
                                }
                            })}
                        </ul>
                        <div className="row justify-content-center">
                            <div className="text-center">
                                <button
                                    onClick={this.selectAndAttachRooms.bind(this, this.props.srcProxy)}
                                    className="btn btn-sm te btn-primary mt-2 mb-5 mr-2"
                                >
                                    {window.translate("Attach")}
                                </button>
                            </div>
                            <div className="text-center">
                                <button
                                    onClick={() => {
                                        this.setState({ attachRoom: false });
                                    }}
                                    className="btn btn-sm te btn-secondary mt-2 mb-5"
                                >
                                    {window.translate("GO BACK")}
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
            );
        } else {
            return (
                <div>
                    <button
                        onClick={() => {
                            this.props.history.push('/srcProxies');
                        }}
                        className="btn btn-sm btn-secondary mb-2 mr-2"
                    >
                        {window.translate("GO BACK")}
                    </button>
                    <button
                        onClick={() => {
                            this.setState({ attachRoom: true });
                        }}
                        className="btn btn-sm btn-primary mb-2 mr-2"
                    >
                        {window.translate("ATTACH HOTEL ROOM")}
                    </button>
                    <button
                        onClick={() => {
                            this.showMigrationModel(this.props.srcProxy);
                        }}
                        className="btn btn-sm btn-warning  mb-2 mr-2"
                    >
                        {window.translate("MIGRATE PROXY")}
                    </button>

                    <FormHeader>{this.props.srcProxy.name}</FormHeader>
                    <div className="text-center">
                        <small>{this.props.srcProxy.description}</small>
                    </div>

                    <div className="row">
                        <div className="col-md-6 ">
                            <h5 className="text-center mt-2">{window.translate("HOTEL ROOMS")}</h5>
                            <ul className="list-group ">
                                {_.map(this.props.srcProxy.RoomInfo, (roomInfo) => {
                                    return (
                                        <li key={roomInfo.id} className="list-group-item">
                                            {roomInfo.name}
                                            <span className="pull-right">
                                                {roomInfo.remoteDeviceId}
                                                {roomInfo.remoteDeviceId ? (
                                                    <button
                                                        onClick={() => {
                                                            this.props.unbindSRCFromRoomInfo(roomInfo);
                                                        }}
                                                        className="ml-2 btn btn-sm btn-outline-secondary"
                                                    >
                                                        {window.translate("UNBIND")}
                                                    </button>
                                                ) : null}
                                            </span>
                                            {this.state.bindingSRC && !roomInfo.remoteDeviceId ? (
                                                <button
                                                    onClick={this.bindSRCToRoomInfo.bind(this, roomInfo)}
                                                    className="btn btn-sm btn-outline-secondary pull-right"
                                                >
                                                    {window.translate("SELECT")}
                                                </button>
                                            ) : !roomInfo.remoteDeviceId ? (
                                                <button
                                                    onClick={this.props.detachRoomInfo.bind(this, roomInfo)}
                                                    className="btn btn-sm btn-outline-danger pull-right"
                                                >
                                                    {window.translate("Detach")}
                                                </button>
                                            ) : null}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                        <div className="col-md-6 ">
                            <h5 className="text-center mt-2">{window.translate("UNBINDED SRC CONTROLLERS")}</h5>
                            <ul className="list-group list-group-flush mt-2">
                                {_.map(unbindedSRCs, (src) => {
                                    let srcProxy = _.find(this.props.srcProxies, (p) => {
                                        return p.id === src.srcProxyId;
                                    });
                                    return (
                                        <li key={src.id} className="list-group-item">
                                            {srcProxy ? srcProxy.name : null}/{src.name}
                                            <br />{' '}
                                            <small>
                                                <b>{moment(src.createdAt).format('lll')}</b>&ensp;
                                                <i>{moment(src.createdAt).fromNow()}</i>
                                            </small>
                                            {this.state.bindingSRC ? (
                                                this.state.bindingSRC.id === src.id ? (
                                                    <div className="pull-right">
                                                        <button
                                                            onClick={() => {
                                                                this.setState({ bindingSRC: null });
                                                            }}
                                                            className="btn btn-sm btn-outline-info"
                                                        >
                                                            {window.translate("Cancel")}
                                                        </button>
                                                    </div>
                                                ) : null
                                            ) : (
                                                <div>
                                                    <button
                                                        //disabled={licenceInfo.devicesCountExceeded}
                                                        onClick={() => {
                                                            this.setState({ bindingSRC: src });
                                                        }}
                                                        className="btn btn-sm btn-outline-secondary pull-right"
                                                    >
                                                        {licenceInfo.devicesCountExceeded ? licenceLimitText  : window.translate("BIND")}
                                                    </button>
                                                    <button
                                                        onClick={this.props.showDeleteModal.bind(this, src.id)}
                                                        className="btn btn-sm btn-outline-danger pull-right mr-1"
                                                    >
                                                        <i className="fa fa-trash"></i>
                                                    </button>
                                                </div>
                                            )}
                                        </li>
                                    );
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
            );
        }
    }
}

export default SRCProxyDevices;
