import React from 'react';
import { RoleModel, PermissionModel, RoleWithPermissions } from '@common/modelDefinition';
//@ts-ignore
import { branch } from 'baobab-react/higher-order';
import { RoleName, PermissionGroup, SystemMenuName, MainMenuName, ReportMenuName, ButtonsName } from './enums';
import PageTitle from '@components/pageTitle';
//@ts-ignore
import { RouteComponentProps } from 'react-router-dom';
import { translate } from '@data/translations';
import { GlobalLoading } from '@components/loaders/globalLoading';
import LoadingButton from '@components/buttons/loadingButton';
import Delete from '@components/confirmModal';

import {
    getPermissions,
    getRolePermissions,
    updateRolePermission,
    getRolePermissionIds,
    getChildesOfPanel,
    saveOrUpdateRole,
    deleteRole,
} from './controler';

import { restoreDefaultRolesPermissions } from './api';

import RoleForm from './roleForm';
import { setModalContent } from '@components/modal';

//@ts-ignore
import _ from 'lodash';
const verticalHeightInner = '76vh';
interface RolePermissionManagementProps {
    roles: RoleModel[];
    history: RouteComponentProps['history'];
    permissions: PermissionModel[];
    rolePermissions: RoleWithPermissions;
    permissionGroup: string;
    setPermissionGroup: Function;
}

interface RolePermissionSidebarProps {
    roles: RoleModel[];
    selectedRole: string;
    setSelectedRole: Function;
    showDeleteModal: Function;
}
interface RolePermissionBodyProps {
    permissions: PermissionModel[];
    roleWithPermissions: RoleWithPermissions;
    permissionGroup: string;
}

const RolePermissionManagement = (props: RolePermissionManagementProps) => {
    const { history, roles, permissions, rolePermissions: roleWithPermissions } = props;
    const [selectedRole, setSelectedRole] = React.useState<string>('managerFull');
    const [permissionGroup, setPermissionGroup] = React.useState<string>('models');

    React.useEffect(() => {
        const loadData = async () => {
            try {
                if (permissions.length === 0) {
                    await getPermissions();
                }
            } catch (err) {
                console.warn(err);
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });
    }, [permissions.length]);

    React.useEffect(() => {
        const loadData = async () => {
            try {
                if (selectedRole) {
                    await getRolePermissions(selectedRole);
                }
            } catch (err) {
                console.warn(err);
            }
        };
        loadData().catch((err) => {
            console.log(err);
        });
    }, [selectedRole]);

    if (roles.length === 0 || permissions.length === 0 || !roleWithPermissions) {
        return <GlobalLoading />;
    }

    const showFormModal = (roleKey?: string | undefined) => {
        setModalContent(
            <RoleForm roleKey={roleKey} setSelectedRole={setSelectedRole} />,
            translate(!roleKey ? 'New role' : 'Edit role'),
            false,
            'modal-md'
        );
        if (roleKey) {
            setSelectedRole(roleKey);
        }
    };

    const deleteItem = async (roleKey: string) => {
        try {
            await deleteRole(roleKey);
        } catch (error) {
            console.log(error);
        }
    };

    const showDeleteModal = (roleKey: string) => {
        setModalContent(
            <Delete
                actionForYes={() => {
                    deleteItem(roleKey);
                    setSelectedRole('managerFull');
                }}
            />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };

    const showRestoreDefaultsModal = () => {
        setModalContent(
            <Delete
                content={translate("Restore default permissions")+ "?"}
                actionForYes={async () => {
                    await restoreDefaultRolesPermissions();
                    await getRolePermissions(selectedRole);
                }}
            />,
            translate('Confirm'),
            false,
            'modal-md'
        );
    };

    return (
        <div>
            <PageTitle />
            <div className="bg-white">
                <div className="d-flex mt-1">
                    <div className="col-md-2 text-center border-right">
                        <div className="btn-group-vertical p-2">
                            <button
                                onClick={() => {
                                    history.goBack();
                                }}
                                className="btn btn-sm btn-secondary m-1"
                            >
                                {translate('GO BACK')}
                            </button>
                            <button
                                onClick={() => {
                                    showFormModal();
                                }}
                                className="btn btn-sm btn-primary m-1"
                                type="submit"
                            >
                                {translate('ADD NEW')}
                            </button>
                            <button
                                onClick={() => {
                                    showRestoreDefaultsModal();
                                }}
                                className="btn btn-sm btn-warning m-1"
                                type="submit"
                            >
                                {translate('Restore default permissions')}
                            </button>
                        </div>
                        <RolePermissionSidebar
                            roles={roles}
                            selectedRole={selectedRole}
                            setSelectedRole={setSelectedRole}
                            showDeleteModal={showDeleteModal}
                        />
                    </div>
                    <div className="col-md-10">
                        <div className="d-flex btn-group p-2">
                            {(Object.keys(PermissionGroup) as Array<string>).map((groupName) => {
                                return (
                                    <button
                                        key={groupName}
                                        onClick={() => {
                                            setPermissionGroup(groupName);
                                        }}
                                        className={`btn btn-sm btn-white border ${
                                            permissionGroup === groupName ? 'active' : ''
                                        }`}
                                    >
                                        {PermissionGroup[groupName as keyof typeof PermissionGroup]}
                                    </button>
                                );
                            })}
                        </div>
                        <RolePermissionBody
                            permissions={permissions}
                            roleWithPermissions={roleWithPermissions}
                            permissionGroup={permissionGroup}
                        />
                    </div>
                </div>
            </div>
        </div>
    );
};

const RolePermissionSidebar = (props: RolePermissionSidebarProps) => {
    const { roles, setSelectedRole, selectedRole, showDeleteModal } = props;
    const filteredRoles = roles.filter((r) => r.key !== 'default');

    return (
        <div className="sticky-top">
            <div className="m-1 p-1 border-bottom text-center">
                <b>{translate('Role selection')}</b>
            </div>

            {filteredRoles.map((role: RoleModel) => {
                const buttonActive = selectedRole === role.key ? 'active' : '';
                return (
                    <div className="d-flex justify-content-center align-items-center" key={role.key}>
                        <div className="col-10">
                            <button
                                onClick={() => {
                                    setSelectedRole(role.key);
                                }}
                                className={`btn btn-block btn-sm border p-2 m-1 btn-white ${buttonActive}`}
                            >
                                {translate(
                                    RoleName[role.key as keyof typeof RoleName]
                                        ? RoleName[role.key as keyof typeof RoleName]
                                        : role.key
                                )}
                            </button>
                        </div>
                        {!RoleName[role.key as keyof typeof RoleName] ? (
                            <div className="col-2 pl-0">
                                <button
                                    onClick={() => {
                                        showDeleteModal(role.key);
                                    }}
                                    className="btn btn-sm btn-outline-danger mr-1"
                                >
                                    <i className="fa fa-trash"></i>
                                </button>
                            </div>
                        ) : (
                            <div className="col-2 pl-0"></div>
                        )}
                    </div>
                );
            })}
        </div>
    );
};

const RolePermissionBody = (props: RolePermissionBodyProps) => {
    const { permissions, roleWithPermissions, permissionGroup } = props;
    return (
        <div>
            {permissionGroup === 'models' ? (
                <Models
                    permissions={permissions}
                    roleWithPermissions={roleWithPermissions}
                    permissionGroup={permissionGroup}
                />
            ) : null}
            {permissionGroup === 'systemMenu' ? (
                <SystemMenu
                    permissions={permissions}
                    roleWithPermissions={roleWithPermissions}
                    permissionGroup={permissionGroup}
                />
            ) : null}
            {permissionGroup === 'mainMenu' ? (
                <MainMenu
                    permissions={permissions}
                    roleWithPermissions={roleWithPermissions}
                    permissionGroup={permissionGroup}
                />
            ) : null}
            {permissionGroup === 'reportMenu' ? (
                <ReportMenu
                    permissions={permissions}
                    roleWithPermissions={roleWithPermissions}
                    permissionGroup={permissionGroup}
                />
            ) : null}
            {permissionGroup === 'buttons' ? (
                <Buttons
                    permissions={permissions}
                    roleWithPermissions={roleWithPermissions}
                    permissionGroup={permissionGroup}
                />
            ) : null}
            {permissionGroup === 'routes' ? (
                <Routes
                    permissions={permissions}
                    roleWithPermissions={roleWithPermissions}
                    permissionGroup={permissionGroup}
                />
            ) : null}
        </div>
    );
};

const Models = (props: RolePermissionBodyProps) => {
    const { permissions, roleWithPermissions } = props;

    const modelPermissions = permissions.filter((perm) => perm.name.includes('model'));
    if (!roleWithPermissions || !roleWithPermissions.Permissions) {
        return <GlobalLoading />;
    }

    const rolePermitedIds: number[] = getRolePermissionIds(roleWithPermissions);

    const groupByModelName = _.groupBy(modelPermissions, (mp: PermissionModel) => {
        const modelName = mp.name.split(/[.]/)[1];
        return modelName;
    });
    const roleName: string = roleWithPermissions.key;
    return (
        <div style={{ overflowY: 'auto', height: verticalHeightInner }}>
            <div className="d-flex m-1 p-1 border-bottom">
                <div className="col-md-3">
                    <b>{translate('Model name')}</b>
                </div>
                <div className="col-md-3 text-center">
                    <b>{translate('Read')}</b>
                </div>
                <div className="col-md-3 text-center">
                    <b>{translate('Update')}</b>
                </div>
                <div className="col-md-3 text-center">
                    <b>{translate('Delete')}</b>
                </div>
            </div>
            {(Object.keys(groupByModelName) as Array<string>).map((modelName) => {
                return (
                    <div className="d-flex m-1 p-1 border-bottom" key={modelName}>
                        <div className="col-md-3">{modelName}</div>
                        {groupByModelName[modelName].map((crud: PermissionModel) => {
                            //  const crudOption = crud.name.split(/[.]/)[2];
                            const isChecked = crud.id && rolePermitedIds.includes(crud.id) ? true : false;
                            return (
                                <div className="col-md-3 text-center" key={crud.id}>
                                    <div
                                        className="custom-control custom-switch"
                                        onClick={async () => {
                                            if (crud.id) {
                                                await updateRolePermission(roleName, crud.id);
                                            }
                                        }}
                                    >
                                        <input
                                            type="checkbox"
                                            className="custom-control-input"
                                            // id="customSwitch1"
                                            checked={isChecked}
                                            onChange={() => {
                                                return null;
                                            }}
                                        />
                                        <label className="custom-control-label" htmlFor=""></label>
                                    </div>
                                </div>
                            );
                        })}
                    </div>
                );
            })}
        </div>
    );
};

const SystemMenu = (props: RolePermissionBodyProps) => {
    const { permissions, roleWithPermissions } = props;
    const systemMenuPermissions = permissions.filter(
        (perm) => perm.name.includes('systemMenu') && !perm.name.includes('mainMenu')
    );
    const rolePermitedIds: number[] = getRolePermissionIds(roleWithPermissions);
    const roleName: string = roleWithPermissions.key;
    const availableChildNames = getChildesOfPanel(systemMenuPermissions, rolePermitedIds);
    return (
        <div className="d-flex flex-wrap m-1 p-1 ">
            {systemMenuPermissions.map((smp: PermissionModel) => {
                const systemMenuName = smp.name.split(/[.]/)[2];
                const isChecked = smp.id && rolePermitedIds.includes(smp.id) ? true : false;
                const panel = !systemMenuName.includes('_') ? systemMenuName : null;

                const disabled = !panel && !availableChildNames.includes(systemMenuName);

                const border = panel ? 'border' : '';
                return (
                    <div className={'col-md-4 p-3 ' + border} key={smp.id}>
                        <div
                            className="custom-control custom-switch"
                            onClick={async () => {
                                if (smp.id && !disabled) {
                                    await updateRolePermission(roleName, smp.id);
                                    if (systemMenuName === panel && isChecked) {
                                        const removeChilds = systemMenuPermissions.filter(
                                            (sm) => sm.name.includes(panel) && sm.name !== `feature.systemMenu.${panel}`
                                        );
                                        for (const removeChild of removeChilds) {
                                            if (removeChild.id && rolePermitedIds.includes(removeChild.id)) {
                                                await updateRolePermission(roleName, removeChild.id);
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            <input
                                disabled={disabled}
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch1"
                                checked={isChecked}
                                onChange={async () => {
                                    return null;
                                }}
                            />
                            <label className="custom-control-label" htmlFor="">
                                {SystemMenuName[systemMenuName as keyof typeof SystemMenuName]}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const MainMenu = (props: RolePermissionBodyProps) => {
    const { permissions, roleWithPermissions } = props;
    const mainMenuPermissions = permissions.filter((perm) => perm.name.includes('mainMenu'));
    const rolePermitedIds: number[] = getRolePermissionIds(roleWithPermissions);
    const roleName: string = roleWithPermissions.key;
    return (
        <div className="d-flex flex-wrap m-1 p-1 ">
            {mainMenuPermissions.map((smp: PermissionModel) => {
                const mainMenuName = smp.name.split(/[.]/)[2];
                const isChecked = smp.id && rolePermitedIds.includes(smp.id) ? true : false;
                return (
                    <div className="col-md-4 p-3" key={smp.id}>
                        <div
                            className="custom-control custom-switch"
                            onClick={async () => {
                                if (smp.id) {
                                    await updateRolePermission(roleName, smp.id);
                                }
                            }}
                        >
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch1"
                                checked={isChecked}
                                onChange={async () => {
                                    return null;
                                }}
                            />
                            <label className="custom-control-label" htmlFor="">
                                {MainMenuName[mainMenuName as keyof typeof MainMenuName]}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const ReportMenu = (props: RolePermissionBodyProps) => {
    const { permissions, roleWithPermissions } = props;
    const reportMenuPermissions = permissions.filter((perm) => perm.name.includes('reportMenu'));
    const rolePermitedIds: number[] = getRolePermissionIds(roleWithPermissions);
    const roleName: string = roleWithPermissions.key;
    const availableChildNames = getChildesOfPanel(reportMenuPermissions, rolePermitedIds);
    return (
        <div className="d-flex flex-wrap m-1 p-1 ">
            {reportMenuPermissions.map((smp: PermissionModel) => {
                const reportMenuName = smp.name.split(/[.]/)[2];
                const isChecked = smp.id && rolePermitedIds.includes(smp.id) ? true : false;
                const panel = !reportMenuName.includes('_') ? reportMenuName : null;
                const disabled = !panel && !availableChildNames.includes(reportMenuName);
                const border = panel ? 'border' : '';
                return (
                    <div className={'col-md-4 p-3 ' + border} key={smp.id}>
                        <div
                            className="custom-control custom-switch"
                            onClick={async () => {
                                if (smp.id && !disabled) {
                                    await updateRolePermission(roleName, smp.id);
                                    if (reportMenuName === panel && isChecked) {
                                        const removeChilds = reportMenuPermissions.filter(
                                            (sm) => sm.name.includes(panel) && sm.name !== `feature.reportMenu.${panel}`
                                        );
                                        for (const removeChild of removeChilds) {
                                            if (removeChild.id && rolePermitedIds.includes(removeChild.id)) {
                                                await updateRolePermission(roleName, removeChild.id);
                                            }
                                        }
                                    }
                                }
                            }}
                        >
                            <input
                                disabled={disabled}
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch1"
                                checked={isChecked}
                                onChange={async () => {
                                    return null;
                                }}
                            />
                            <label className="custom-control-label" htmlFor="">
                                {ReportMenuName[reportMenuName as keyof typeof ReportMenuName]}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const Buttons = (props: RolePermissionBodyProps) => {
    const { permissions, roleWithPermissions } = props;
    const buttonsPermissions = permissions.filter((perm) => perm.name.includes('buttons'));
    const rolePermitedIds: number[] = getRolePermissionIds(roleWithPermissions);
    const roleName: string = roleWithPermissions.key;
    return (
        <div className="d-flex flex-wrap m-1 p-1 ">
            {buttonsPermissions.map((smp: PermissionModel) => {
                const buttonsName = smp.name.split(/[.]/)[2];
                const isChecked = smp.id && rolePermitedIds.includes(smp.id) ? true : false;
                return (
                    <div className="col-md-4 p-3" key={smp.id}>
                        <div
                            className="custom-control custom-switch"
                            onClick={async () => {
                                if (smp.id) {
                                    await updateRolePermission(roleName, smp.id);
                                }
                            }}
                        >
                            <input
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch1"
                                checked={isChecked}
                                onChange={async () => {
                                    return null;
                                }}
                            />
                            <label className="custom-control-label" htmlFor="">
                                {ButtonsName[buttonsName as keyof typeof ButtonsName]}
                            </label>
                        </div>
                    </div>
                );
            })}
        </div>
    );
};

const Routes = (props: RolePermissionBodyProps) => {
    const { roleWithPermissions } = props;

    const { sessionDurationInMinutes, dashboardRoute, fixedRoute } = roleWithPermissions;
    const [localDashboardRoute, setLocalDashboardRoute] = React.useState<string | null>(null);
    const [localFixedRoute, setLocalFixedRoute] = React.useState<string | null>(null);
    const [localSessionDurationInMinutes, setLocalSessionDurationInMinutes] = React.useState<number | null>(null);

    React.useEffect(() => {
        const loadData = () => {
            setLocalSessionDurationInMinutes(sessionDurationInMinutes);
            setLocalDashboardRoute(dashboardRoute);
            setLocalFixedRoute(fixedRoute);
        };
        loadData();
    }, [sessionDurationInMinutes, fixedRoute, dashboardRoute]);

    return (
        <div className="form form-group m-1 p-1">
            <div className="d-flex align-items-center m-2">
                <div className="col-md-3">
                    <label className="mb-0" htmlFor="">
                        Dashboard route
                    </label>
                </div>
                <div className="col-md-4">
                    <input
                        autoComplete="off"
                        name="dashboardRoute"
                        placeholder=""
                        value={localDashboardRoute ? localDashboardRoute : ''}
                        onChange={(e) => {
                            setLocalDashboardRoute(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="d-flex align-items-center m-2">
                <div className="col-md-3">
                    <label className="mb-0" htmlFor="">
                        Fixed route
                    </label>
                </div>
                <div className="col-md-4">
                    <input
                        autoComplete="off"
                        name="fixedRoute"
                        placeholder=""
                        value={localFixedRoute ? localFixedRoute : ''}
                        onChange={(e) => {
                            setLocalFixedRoute(e.target.value);
                        }}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="d-flex align-items-center m-2">
                <div className="col-md-3">
                    <label className="mb-0" htmlFor="">
                        Session duration in Minutes
                    </label>
                </div>
                <div className="col-md-4">
                    <input
                        autoComplete="off"
                        name="sessionDurationInMinutes"
                        placeholder=""
                        value={localSessionDurationInMinutes ? localSessionDurationInMinutes : 0}
                        onChange={(e) => {
                            if (!isNaN(Number(e.target.value))) {
                                setLocalSessionDurationInMinutes(Number(e.target.value));
                            }
                        }}
                        type="text"
                        className="form-control"
                    />
                </div>
            </div>
            <div className="text-center mt-3">
                <LoadingButton
                    disabled={
                        sessionDurationInMinutes === localSessionDurationInMinutes &&
                        fixedRoute === localFixedRoute &&
                        dashboardRoute === localDashboardRoute
                    }
                    onClick={async () => {
                        const updatedRoleWithPermissions = { ...roleWithPermissions };
                        updatedRoleWithPermissions.sessionDurationInMinutes = localSessionDurationInMinutes;
                        updatedRoleWithPermissions.dashboardRoute = localDashboardRoute;
                        updatedRoleWithPermissions.fixedRoute = localFixedRoute;
                        await saveOrUpdateRole(updatedRoleWithPermissions);
                    }}
                    className="btn btn-sm btn-primary"
                >
                    Apply
                </LoadingButton>
            </div>
        </div>
    );
};

export default branch(
    {
        roles: ['model', 'Role'],
        permissions: ['state', 'permissions'],
        rolePermissions: ['state', 'rolePermissions'],
    },
    RolePermissionManagement
);
