import React from 'react';
import { useBranch } from 'baobab-react/hooks';
import _ from 'lodash';
import { setCategory } from '../terminalViewActions';

const Categories = () => {
    const { posItems } = useBranch({
        posItems: ['model', 'PosItem'],
    });

    const [selected, setSelected] = React.useState(null);

    const categories = [];
    posItems.forEach((p) => {
        const tag = p.category ? p.category.split(';') : [];
        tag.forEach((t) => {
            categories.push(t);
        });
    });
    const uniqCategories = _.uniq(categories);

    const selectCategory = (cat) => {
        setCategory(cat);
        setSelected(cat);
    };

    return (
        <div className="flex-row pt-2 pb-0" style={{backgroundColor:"#1ca2ef"}}>
            <div
                className={
                    selected === null
                        ? 'ml-1 category-filter category-selected'
                        : 'ml-1 category-filter category-not-selected'
                }
                onClick={selectCategory.bind(this, null)}
            >
                {window.translate("All")}{' '}
            </div>
            {uniqCategories.map((c, i) => {
                return (
                    <div
                        className={selected === c ? "category-filter category-selected pointer mr-1 ml-1   " : "category-filter category-not-selected pointer mr-1 ml-1"}
                        onClick={selectCategory.bind(this, c)}
                        key={i}
                    >
                        {c}
                    </div>
                );
            })}
        </div>
    );
};

export default Categories;