import React from 'react';
import { branch } from 'baobab-react/higher-order';
import { setModalContent } from '@components/modal';
import { deleteModelData } from '@data/actions/modelActions';
import DPAForm from './form';
import ApSelfCheckInSettings from './apSelfCheckInSettings';
import DeleteDialog from '@components/confirmModal';
import TagPicker from './tagPicker';
import PageTitle from '@components/pageTitle';

const AccessControl = (props) => {
    const showTagPicker = (tagSourceTable, ap) => {
        setModalContent(
            <TagPicker ap={ap} tagSourceTable={tagSourceTable} />,
            window.translate('Tag picker'),
            true,
            'modal-md'
        );
    };

    const editAP = (ap) => {
        setModalContent(<DPAForm ap={ap} />, window.translate('Confirm'), false, 'modal-md');
    };

    const apSelfCheckInSettings = (ap) => {
        setModalContent(
            <ApSelfCheckInSettings ap={ap} doorAccessPoints={props.doorAccessPoints} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };
    const deleteAP = (ap) => {
        setModalContent(
            <DeleteDialog actionForYes={async () => await deleteModelData('DoorAccessPoint', ap.id)} name={ap.name} />,
            window.translate('Confirm'),
            false,
            'modal-md'
        );
    };

    return (
        <div>
            <PageTitle />
            <button
                onClick={() => {
                    props.location.search
                        ? props.history.push('/selfCheckInAdministrative')
                        : props.history.push('/menu/system');
                }}
                className="btn btn-sm btn-secondary"
            >
                {window.translate('GO BACK')}
            </button>
            <button
                onClick={() => {
                    props.history.push('/accessControlQRGenerator');
                }}
                className="btn btn-sm btn-primary ml-2"
            >
                {' '}
                <i className="fa fa-qrcode pr-1"></i>
                {'QR generator'}
            </button>
            <ul className="list-group mt-2">
                {props.doorAccessPoints.map((ap) => {
                    let roomTagWhitelistCSV = ap.roomTagWhitelistCSV ? ap.roomTagWhitelistCSV.split(';') : null;
                    let whitelist = ap.whitelistTagCSV ? ap.whitelistTagCSV.split(';') : null;

                    if (roomTagWhitelistCSV) {
                        roomTagWhitelistCSV = roomTagWhitelistCSV.map((r) => {
                            return (
                                <span key={r.replace(' ', '')} className="badge badge-primary mr-1">
                                    {r}
                                </span>
                            );
                        });
                    }

                    if (whitelist) {
                        whitelist = whitelist.map((tag) => {
                            if (tag === 'guests' || tag === 'self check in') {
                                return (
                                    <span key={tag.replace(' ', '')} className="badge badge-warning mr-1">
                                        {tag}
                                    </span>
                                );
                            } else {
                                return (
                                    <span key={tag.replace(' ', '')} className="badge badge-primary mr-1">
                                        {tag}
                                    </span>
                                );
                            }
                        });
                    }

                    return (
                        <li className="list-group-item" key={ap.id}>
                            <button
                                onClick={editAP.bind(this, ap)}
                                className="btn btn-sm btn-outline-secondary pull-right mr-1"
                            >
                                <i className="fa fa-edit" />
                            </button>

                            <button
                                onClick={apSelfCheckInSettings.bind(this, ap)}
                                className="btn btn-sm btn-outline-secondary pull-right mr-1"
                            >
                                <i className="fa fa-cogs" />
                            </button>

                            <button
                                onClick={deleteAP.bind(this, ap)}
                                className="btn btn-sm btn-outline-secondary pull-right mr-1"
                            >
                                <i className="fa fa-trash" />
                            </button>

                            <h5>
                                #{ap.id} - {ap.name}
                            </h5>
                            <small>
                                <i>{ap.description}</i>
                            </small>

                            <hr />
                            <div className="row text-center">
                                <div className="col-md-6">
                                    {window.translate('Allow guest access, only from rooms with tags')}:{' '}
                                    {roomTagWhitelistCSV ? roomTagWhitelistCSV : <b>{window.translate('no filter')}</b>}
                                    <button
                                        onClick={showTagPicker.bind(this, 'RoomInfo', ap)}
                                        className="btn btn-sm btn-outline-primary ml-2"
                                    >
                                        {window.translate('Change')}
                                    </button>
                                </div>
                                <div className="col-md-6">
                                    {window.translate('whitelist')}:{' '}
                                    {whitelist ? whitelist : <b>{window.translate('none')}</b>}
                                    <button
                                        onClick={showTagPicker.bind(this, 'WhiteList', ap)}
                                        className="btn btn-sm btn-outline-primary ml-2"
                                    >
                                        {window.translate('Change')}
                                    </button>
                                    <div></div>
                                </div>
                            </div>
                        </li>
                    );
                })}
            </ul>
            <b className="badge badge-warning mr-1 mt-4">guests</b>{' '}
            <span className="text-secondary">-</span>{' '}
            <small className="text-secondary">
                {window.translate('reserved1')}.
            </small>
            <br/>
            <b className="badge badge-warning mr-1 mt-4">self check in</b>{' '}
            <span className="text-secondary">-</span>{' '}
            <small className="text-secondary">
                {window.translate('reserved2')}.
            </small>
        </div>
    );
};

export default branch(
    {
        doorAccessPoints: ['model', 'DoorAccessPoint'],
    },
    AccessControl
);
